import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMyFormsInfo, getMyTrashedFormsInfo, getPreviewForm, getResponseLimitValue } from '../features/dataApi';

const initialState = {
	row: [],
	rowLoading: false,
	formChangeName: {},
	formChangeSuccessStatus: {},
	responseLimit: false,
	responseLimitLoading: false,
	trashedForms: [],
	trashedFormsLoading: false,
	previewForm: [],
	previewFormLoading: false,
}

export const getFormPreview = createAsyncThunk(
	'/api/getForm',
	async (formId) => {
		const response = await getPreviewForm(formId)
		return response.data
	}
)

export const getResponseLimit = createAsyncThunk(
	'/api/getResponseLimitValue',
	async (formId) => {
		const response = await getResponseLimitValue(formId)
		return response.data
	}
)

export const getMyForms = createAsyncThunk(
	'/api/getForms',
	async () => {
		const response = await getMyFormsInfo()
		return response.data
	}
)

export const getMyTrashedForms = createAsyncThunk(
	'/api/getTrashedForms',
	async () => {
		const response = await getMyTrashedFormsInfo()
		return response.data
	}
)

export const formSlice = createSlice({
	name: 'formReducer',
	initialState,
	reducers: {
		setMyForm: (state, action) => {
			state.row = action.payload
		},
		setChangeSuccessStatus: (state, action) => {
			state.formChangeSuccessStatus = action.payload
		},
		setResponseLimit: (state, action) => {
			state.responseLimit = action.payload
		},
		setMyFormLoading: (state, action) => {
			state.rowLoading = action.payload
		},
		setMyTrashedForm: (state, action) => {
			state.trashedForms = action.payload
		},
		setPreviewForm: (state, action) => {
			state.previewForm = action.payload
		},
		setFormChangeName: (state, action) => {
			state.formChangeName = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMyForms.fulfilled, (state, action) => {
				state.rowLoading = false
				formSlice.caseReducers.setMyForm(state, action)
			})
			.addCase(getMyForms.pending, (state, action) => {
				state.rowLoading = true
			})
			.addCase(getMyTrashedForms.fulfilled, (state, action) => {
				state.trashedFormsLoading = false
				formSlice.caseReducers.setMyTrashedForm(state, action)
			})
			.addCase(getMyTrashedForms.pending, (state, action) => {
				state.trashedFormsLoading = true
			})
			.addCase(getFormPreview.fulfilled, (state, action) => {
				state.previewFormLoading = false
				formSlice.caseReducers.setPreviewForm(state, action)
			})
			.addCase(getFormPreview.pending, (state, action) => {
				state.previewFormLoading = true
			})
			.addCase(getResponseLimit.fulfilled, (state, action) => {
				state.responseLimitLoading = false
				formSlice.caseReducers.setResponseLimit(state, action)
			})
			.addCase(getResponseLimit.pending, (state, action) => {
				state.responseLimitLoading = true
			})
	},
});

export const {setChangeSuccessStatus, setMyForm, setMyTrashedForm, setPreviewForm,setMyFormLoading, setResponseLimit, setFormChangeName } = formSlice.actions;

export const getPreviewFormData = (state) => state.formReducer.previewForm;
export const getPreviewFormDataLoading = (state) => state.formReducer.previewFormLoading;
export const getMyFormsData = (state) => state.formReducer.row;
export const getResponseLimitData = (state) => state.formReducer.responseLimit;
export const getMyFormsDataLoading = (state) => state.formReducer.rowLoading;
export const getFormChangeSuccessStatus = (state) => state.formReducer.formChangeSuccessStatus;
export const getFormChangeNameData = (state) => state.formReducer.formChangeName;
export const getMyTrashedFormsData = (state) => state.formReducer.trashedForms;
export const getMyTrashedFormsDataLoading = (state) => state.formReducer.trashedFormsLoading;

export default formSlice.reducer;
