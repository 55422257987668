import moment from 'moment';
import React from 'react';

const Footer = () => {
	return <></>
	// return (
	// 	<footer className="px-3 px-xxl-5 py-3 py-lg-4 footer-style">
	// 		<div className="container-fluid px-0 border-top border-gray-200 pt-2 pt-lg-3">
	// 			<div className="row align-items-center">
	// 				<div className="col-md-6">
	// 					<p className="fs-16 text-gray-600 my-2">{moment(new Date()).format("Y")} © Formbuilder.ai - All rights reserved.</p>
	// 				</div>
	// 				<div className="col-md-6">
	// 					<ul className="nav navbar">
	// 						<li><a href="https://www.webwork-tracker.com/" target="_blank">WebWork Time Tracker</a></li>
	// 						<li><a href="https://evistep.com/" target="_blank">Evistep LLC</a></li>
	// 					</ul>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</footer>
	// );
};

export default Footer;