import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import QRCode from 'qrcode';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { APP_BASE_URL } from '../../../config';
import DownloadQr from './DownloadQr';

const ShareIframe = (props) => {
	const { formLink, formTitle } = props
	const [show, setShow] = useState(false);
	const [src, setSrc] = useState('');
	const copyLinkIframe = useRef(null);
	let lines = '';

	let text = APP_BASE_URL + '/f-' + formLink;

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		QRCode.toDataURL(text).then((data) => {
			setSrc(data)
		})
	}, [formLink])

	const copyIframeLink = (e, copyLink) => {
		navigator.clipboard.writeText(copyLink)
		if(copyLinkIframe.current){
			copyLinkIframe.current.innerText = "Copied"
			setTimeout(() => {
				copyLinkIframe.current.innerText = "Copy"
			}, 500);
		}
	}

	return (
		<>
			<button type='button' onClick={handleShow} className={'btn form-link-button share-button-styles'}><span><img src="/svg/icons/code-solid.svg" alt="" /> </span></button>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Embed your form in your website</Modal.Title>
				</Modal.Header>
				<Modal.Body className='d-flex justify-content-center'>
					{lines = '<iframe src='+'"'+APP_BASE_URL+'/f-'+formLink+'"'+' width="650" height="500"></iframe>' }
				</Modal.Body>
				<Modal.Footer>
				
					<Button className='btn btn-lg btn-secondary text-white' ref = {copyLinkIframe} onClick={(e) => {copyIframeLink(e, lines)}}>
						Copy
					</Button>
					{/* <Button className='btn btn-lg btn-secondary text-white' onClick={handleClose}>
						Close
					</Button> */}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ShareIframe;