import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_BASE_URL, APP_BASE_URL, AxiosConfigs} from "../config";


var Suggestions = () => {
    document.title = "FormBuilder - Suggestions";
    const [suggestionsData, setSuggestionsData] = useState(
        {
            title: '',
            message: ''
        }
    );
    const [loading, setLoading] = useState(false)
    const messageEdit = useRef();
    const form = useRef();
    const [editedMessage, setEditedMessage] = useState('')

    const [errors, setErrors] = useState([]);

    function showMessageSuccess(message) {
        try {
            if (messageEdit.current != null) {
                messageEdit.current.classList.remove('form-changes-error')
                messageEdit.current.classList.add('active', 'form-changes-success')
                setTimeout(() => {
                    if (messageEdit.current != null) {
                        messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
                    }
                }, 1000)
            }
            setEditedMessage(message)
        } catch (error) {
            throw error
        }
    }

    const submit = (e) => {
        e.preventDefault()
        let data = {}
        data.title = suggestionsData.title
        data.description = suggestionsData.message
        axios.post(API_BASE_URL + '/api/suggestion', data, AxiosConfigs)
            .then(response => {
                setLoading(true)
                showMessageSuccess('Your suggestions is successfully sent.')
                setSuggestionsData({})
                setErrors([])
                form.current.reset()
            })
            .catch(error => {
                setErrors(error.response.data.error)
            })
    }

    return (
        <div className="pt-xxl-5 mt-4 col-12">
            <div className="form-changes" ref={messageEdit}>
                <div className='img-div'>
                    <svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
                            stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2}
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className='succes-div'>
                    <p className='paragraph'>{editedMessage}</p>
                </div>
            </div>
            <div className="text-center pt-3 mt-2 px-2">
                <h2 className="h1">Suggest a feature, make us better</h2>
                {/*<p className="big lh-lg text-black-600">Reach out to our support team and we’ll get back to you within*/}
                {/*    24 <br className="d-none d-md-block" />hours for any issue you might have!</p>*/}
            </div>
            <div
                className="bg-white p-4 p-md-5 rounded-12 shadow-dark-80 my-4 my-xxl-5 col-12 d-flex justify-content-center">
                <form onSubmit={submit} ref={form} className="col-lg-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4 mb-md-5">
                                <label className="form-label form-label-lg" htmlFor="EmailAddress">Title</label>
                                <input type="text"
                                       placeholder="Title"
                                       id="EmailAddress"
                                       className={errors && errors.hasOwnProperty('title') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
                                       onChange={e => setSuggestionsData({...suggestionsData, title: e.target.value})}/>
                                {errors && errors.hasOwnProperty('title') ?
                                    <p className="Authmessage text-end text-red"> {errors.title} </p> : null}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-4 mb-md-5">
                                <label className="form-label form-label-lg" htmlFor="Message">Description</label>
                                <textarea
                                    className={errors && errors.hasOwnProperty('description') ? "form-control form-control-lg borderColorRed" : "form-control form-control-lg"}
                                    id="Message" placeholder="Start typing"
                                    rows={4}
                                    onChange={e => setSuggestionsData({...suggestionsData, message: e.target.value})}/>
                                {errors && errors.hasOwnProperty('description') ?
                                    <p className="Authmessage text-end text-red"> {errors.description} </p> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center d-flex justify-content-end">
                        <div className="col-md-5">
                            <div className="text-end">
                                <button className="btn btn-xl btn-dark" id="btnYellow">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Suggestions;
