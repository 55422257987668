import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAdminSearchData,
	getAdminUsers,
	getGlobalDateRangeInfo,
	getGlobalSearchTextInfo,
	getPayingData,
	getPayingInfo,
	setAdminLoading,
	setAdminUserTabName,
	setGlobalDateRange,
	setPayingInfo,
	setSearchData,
	setUsers
} from '../../../redux/reducers/adminReducer';
import { getAdminUsersData } from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';
import moment from 'moment';

const AdminUsers = (props) => {
	let { addData, setModal, setModalData, dataAll, setDataAll, setTableData, setPageNumber, pageNumber, userDate, setUserDate, dateRange, setDateRange } = props;
	document.title = "FormBuilder - Admin Users";
	const dispatch = useDispatch();
	const [searchInfo, setSearchInfo] = useState('');
	const dataNow = useSelector(getAdminUsersData)
	const searchData = useSelector(getAdminSearchData);
	const payingInfo = useSelector(getPayingInfo);
	const payingData = useSelector(getPayingData);
    const globalDateRange = useSelector(getGlobalDateRangeInfo);
	const globalSearchText = useSelector(getGlobalSearchTextInfo);
	const [startDate, endDate] = dateRange;
	const [tabNameShow, setTabNameShow] = useState('Active')
	const [changeTab, setChangeTab] = useState(false);
	const tabName = {
		'Fullname': 'fullName',
		'LoginDate': 'loginDate',
		'Email': 'email',
		'Verify': 'confirm',
		'Plan' : 'plan',
		'AvailableForms' : 'formCount',
		'Deleted' : 'deleted_at',
		'Forms': 'formsadmin',
		'OneTimeForm' : 'haveOneTimeForm',
		'Responses': 'responses',
		'Popup': 'popup',
		// 'Registered': 'formatedDate',
		'Registered': 'created_at',
		'Blocked': 'blocked'
	}


	
	useEffect(() => {
		setPageNumber(dataNow.current_page)
		setDataAll(dataNow)
	}, [dataNow])

	useEffect(() => {
		// document.addEventListener('ready',function() {
			let data = {};
			data.page = 1;
			data.tabName = tabNameShow
			dispatch(getAdminUsers(data))
		// })
	}, [])
	const dataRangeFunction = (data) => {
		if (data) {
			setDateRange(data)
			if (data[0] && data[1]) {
				dispatch(setGlobalDateRange(
					{
						from: moment(data[0]).toLocaleString(),
						to: moment(data[1]).toLocaleString()
					}))
				handleClose(data)
			} else if(!data[0] && !data[1]) {
				dispatch(setGlobalDateRange(
					{
						from: null,
						to: null
					}))
					deleteRangeData(data)
			}
			// if (!data[0] && !data[1]) {
			// 	if (!searchData && searchData.length === 0) {
			// 		// dispatch(getAdminUsers({ page: 1 }))
			// 	}
			// }
		}
	}


	// useEffect(() => {
		// if (dateRange[0] && dateRange[1]) {
		// 	handleClose()
		// }
		// if (!dateRange[0] && !dateRange[1]) {
		// 	if (!searchData && searchData.length === 0) {
		// 		console.log('ok')
		// 		dispatch(getAdminUsers({ page: 1 }))
		// 	}
		// }
	// }, [dateRange])

    const [show, setShow] = useState(false);

    const handleClose = (datesNow) => {
		dispatch(setAdminLoading(true))
		// if (payingInfo === true) dispatch(setPayingInfo(false))
		if (datesNow[0] && datesNow[1]) {
			var dates = {
				from: moment(datesNow[0]).toLocaleString(),
				to: moment(datesNow[1]).toLocaleString()
			};
			let data = {}
			data.globalDateRange = dates
			data.globalSearchTextInfo = globalSearchText;
			data.payingInfo = payingInfo;
			data.tabName = tabNameShow;
			setUserDate(dates)
			// dispatch(setSearchData(''))
			axios.post(API_BASE_URL + '/api/getAdminUserDatas?page=1', data)
				.then(response => {
					dispatch(setAdminLoading(false))
					setDataAll(response.data)
					// dispatch(setResponsesYear(response.data))
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
			setShow(false)
		}
	};

	const getSuspendedUserData = tabName => {
		if (tabName === "Suspended") {
			setTabNameShow('Suspended')
			dispatch(setAdminUserTabName('Suspended'))
		} else if (tabName === "Active") {
			setTabNameShow('Active')
			dispatch(setAdminUserTabName('Active'))
		}
		setChangeTab(true)
	}

	useEffect(() => {
		let data = {}
		data.page = dataNow.current_page;
		data.globalSearchTextInfo = globalSearchText;
		data.payingInfo = payingInfo;
		data.tabName = tabNameShow;
		if (changeTab) {
			axios.post(API_BASE_URL + '/api/getAdminUserDatas?page=' + data.page, data)
				.then(response => {
					dispatch(setAdminLoading(false))
					setDataAll(response.data)
					setChangeTab(false)
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
					setChangeTab(false)
				})
		}
		setShow(false)
	}, [changeTab])


	const deleteRangeData = (datesNow) => {
		dispatch(setAdminLoading(true))
		if (!datesNow[0] && !datesNow[1]) {
			let data = {}
			// data.globalDateRange = dates
			data.globalSearchTextInfo = globalSearchText;
			data.payingInfo = payingInfo;
			data.tabName = tabNameShow;
			// setUserDate(dates)
			axios.post(API_BASE_URL + '/api/getAdminUserDatas?page=1', data)
				.then(response => {
					dispatch(setAdminLoading(false))
					setDataAll(response.data)
					// dispatch(setResponsesYear(response.data))
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
			setShow(false)
		}
	}

	const getData = (data) => {
		if(dataAll.current_page !== data.page){
			dispatch(setAdminLoading(true))
			data.globalDateRange = globalDateRange
			data.globalSearchTextInfo = globalSearchText;
			data.payingInfo = payingInfo;
			data.tabName = tabNameShow;
			// data.searchInfo = searchInfo;
			// data.payingInfo = payingInfo;
			if (dateRange[0] && dateRange[1]) {
				var dates = {
					from: moment(dateRange[0]).toLocaleString(),
					to: moment(dateRange[1]).toLocaleString()
				};
				data.dateTimes = dates
				setUserDate(dates)
			}
			axios.post(API_BASE_URL + '/api/getAdminUserDatas?page=' + data.page, data)
				.then(response => {
					dispatch(setAdminLoading(false))
					dispatch(setUsers(response.data))
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
		}
	}

	return (
		<div className="home__data">
			 <ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative solved-notsolved">
                {
                        <li className="nav-item">
                            <button type="button" onClick={e=>getSuspendedUserData('Active')} className={tabNameShow=='Active'?'nav-link active':'nav-link'}>Active</button>
                        </li>
                }
                {
                        <li className="nav-item">
                            <button type="button" onClick={e=>getSuspendedUserData('Suspended')} className={tabNameShow=='Suspended'?'nav-link active':'nav-link'} >Suspended</button>
                        </li>
                }
            </ul>
			 {dataAll.data ? 
			
				<div className='admin-responses'>
					<AdminTable
						tabNameShow={tabNameShow}
						tabName={tabName}
						dataAll={dataAll}
						setDataAll={setDataAll}
						table={'User'}
						getData={getData}
						setModal={setModal}
						setModalData={setModalData}
						setTableData={setTableData}
						dataNow={dataNow}
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataRangeFunction={dataRangeFunction}
						startDate={startDate}
						endDate={endDate}
						userDate={userDate}
						setUserDate={setUserDate}
						setSearchInfo={setSearchInfo}
						searchInfo={searchInfo}
						addData={addData}
					/>
				</div>
				: 
				<div className="form-loader">
					<Loader />
				</div>}
		</div >
	);

};

export default AdminUsers;