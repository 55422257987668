import React, { useEffect, useRef } from 'react';
import { Animated } from "react-animated-css";
import { useDispatch } from 'react-redux';
import { trim, urlify } from '../../../../help';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';
import AutoLinks from 'quill-auto-links';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const modules = {
    toolbar: false,
};

const Text = (props) => {
	const { formData, setFormData, settingsData, showMessageError, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const dispatch = useDispatch()
	const editorCK = useRef();
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var responsable = formCopy[page].rows[row].content[0].cols[col].content.responsable ? formCopy[page].rows[row].content[0].cols[col].content.responsable : false;
			var uniqueId = formCopy[page].rows[row].content[0].cols[col].uniqueId;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page,
					row,
					col
				},
				details: {
					text: {
						required,
						responsable,
						uniqueId
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const toggleOverflow = e => {
		e.preventDefault()
		e.currentTarget.classList.toggle('label-overflow')
	}
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				dispatch(setIsEdited(true))
			})
		}
	}, [contentRow])

	const setUserText = (data) => {
		if (trim(data)) {
			setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: data, direction: page + '/' + row + '/' + col } })
		} else {
			setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: '' })
		}
	}
	return (
		// <Animated className='animatedDiv styleAnimated' animationIn={checkAdd ? "shake" : ""} animationOut="fadeOut" isVisible={true}  >
		<div id="textPage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-text">
						<div className="text-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label} {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Label {
										contentRow.content[0].cols[col].content.required
											? ' *'
											: ''
									}</label>
							}
						</div>
						<div className="text-inputs d-flex justify-content-start h-100">
							<div className={"text-input quill-text"}>
								{/* <ReactQuill 
									theme="snow" 
									modules={modules}
									placeholder={'Type here...'}
									onChange={setUserText}
								/> */}
								{/* <CKEditor
									ref={editorCK}
									editor={ClassicEd}
									config={editorConfiguration}
									data={editorData ? editorData : ''}
									onChange={(event, editor) => {
										setEditorData(editor.getData())
									}}
								/> */}
								<textarea
									id="text"
									type="text"
									className={rowError
										? "form-input-border required-field"
										: "form-input-border"
									}
									// placeholder="Type here..." onChange={e => trim(e.target.value) ? setUserData({ ...userData, [page + '/' + row + '/' + col]: e.target.value }) : setUserData({ ...userData, [page + '/' + row + '/' + col]: '' })}
									placeholder="Type here..."
									onChange={e => trim(e.target.value) ? setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: e.target.value, direction: page + '/' + row + '/' + col } }) : setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: '' })}
								/>
							</div>
						</div>
					</div>
				</div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
								}
							</div>
							{contentRow.content[0].cols[col].userValue
								?
								<div className="text-inputs d-flex justify-content-start h-100 text-response-quill">
									<div className="text-input">
									<div dangerouslySetInnerHTML={{ __html: contentRow.content[0].cols[col].userValue ? urlify(contentRow.content[0].cols[col].userValue) : '' }} className='paragraph-response-quill'></div>
										{/* <div className='responseText readOnly'>
											{contentRow.content[0].cols[col].userValue ? contentRow.content[0].cols[col].userValue : ''}
										</div> */}
										{/* <textarea
										id="text"
										type="text"
										tabIndex='-1'
										className="form-input-border evented text-dark"
										defaultValue={contentRow.content[0].cols[col].userValue ? contentRow.content[0].cols[col].userValue : ''
									}
									// placeholder="Type here..." onChange={e => trim(e.target.value) ? setUserData({ ...userData, [page + '/' + row + '/' + col]: e.target.value }) : setUserData({ ...userData, [page + '/' + row + '/' + col]: '' })}
									// placeholder="Type here..." 
									/> */}
									</div>
								</div>
								:
								<h3 className='d-flex justify-content-start w-100 h-100'>__</h3>
							}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content.responsable
										? <span className='form-content-filter form-content-responsable' title='In response'><i className="fas fa-check"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label}</div>
											:
											// <input type="text" defaultValue="Text" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Text</div>
										)
										:
										<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Text</div>
									// <input type="text" defaultValue="Text" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<div className="text-inputs">
								<div className="text-input">
									<textarea id="text" tabIndex='-1' type="text" className='form-input-border evented' defaultValue="Type here..." />
									{/* <input type="text" defaultValue="Sublabel" className='form_input row-sublabel' /> */}
									{/* {
									contentRow.content[0].cols[col].content.required
										?
										<label
											className='form_input row-sublabel'>This field is required</label>
										: null
									} */}
								</div>
							</div>
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
		// </Animated>
	);
};

export default Text;