import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {API_BASE_URL} from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {
    getAdminSuggestions,
    getAdminSuggestionsData,
    setAdminLoading,
    setSuggestions
} from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';


const AdminSuggestions = (props) => {
    const {setModal, setModalData, dataAll, setDataAll, setTableData, setPageNumber} = props;
    document.title = "FormBuilder - Admin Suggestions";
    const dispatch = useDispatch();
    const dataNow = useSelector(getAdminSuggestionsData)
    const [searchInfo, setSearchInfo] = useState('');
    const tabName = {
        'Name': 'name',
        'Surname': 'surname',
        'Email': 'email',
        'Title': 'title',
        'Description': 'description',
        'Date': 'date',
    }

    useEffect(() => {
        setPageNumber(dataNow.current_page)
        setDataAll(dataNow)
    }, [dataNow])
    useEffect(() => {
        dispatch(getAdminSuggestions({page: 1}))
    }, [])

    const getData = (data) => {
        if (dataAll.current_page !== data.page) {
            dispatch(setAdminLoading(true))
            data.searchInfo = searchInfo;
            axios.post(API_BASE_URL + '/api/getSuggestionsForAdmin?page=' + data.page, data)
                .then(response => {
                    dispatch(setAdminLoading(false))
                    dispatch(setSuggestions(response.data))
                })
                .catch(error => {
                    dispatch(setAdminLoading(false))
                })
        }
    }
    return (
        <div className="home__data">
            {dataAll.data ?
                <div className='admin-responses'>
                    <AdminTable
                        tabName={tabName}
                        dataAll={dataAll}
                        setDataAll={setDataAll}
                        table={'Suggestions'}
                        getData={getData}
                        setModal={setModal}
                        setModalData={setModalData}
                        setTableData={setTableData}
                        dataNow={dataNow}
                        setSearchInfo={setSearchInfo}
                        searchInfo={searchInfo}
                    />
                </div> :
                <div className="form-loader">
                    <Loader/>
                </div>}
        </div>
    );

};

export default AdminSuggestions;