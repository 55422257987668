import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL, APP_BASE_URL, AxiosConfigs } from '../../config';
import Swal from 'sweetalert2'
import Loader from '../../UI/Loader/Loader';
import { getMyForms, getMyFormsData, getMyFormsDataLoading, setMyForm, getMyTrashedFormsData, getMyTrashedFormsDataLoading, setMyTrashedForm, setMyFormLoading, getMyTrashedForms } from '../../redux/reducers/formReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getFormCountInfo, getInfoData, setFormCountInfo } from '../../redux/reducers/infoReducer';
import { randomString, trim } from '../../help';
import moment from 'moment';
import shortId from 'short-mongo-id';
import { useHistory } from 'react-router';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faCode, faHighlighter } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { faFacebook } from "@fortawesome/free-brands-svg-icons"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MyForms = (props) => {
	document.title = "FormBuilder - Forms";
	const myInfo = useSelector(getInfoData)
	// const myInfoTrashedResponse = useSelector(getMyTrashedFormsData)
	const [copyForms, setCopyForms] = useState({ filter: '', data: [] })
	const [copyFormsAll, setCopyFormsAll] = useState({ filterAll: '', dataAll: [] })
	const [tabname, setTabname] = useState('All')
	const [lastForm, setLastForm] = useState([]);
	const tabAll = useRef(null)
	const tabLast = useRef(null)
	const tabTrash = useRef(null)
	const dispatch = useDispatch()
	const myForms = useSelector(getMyFormsData)
	const myTrashedForms = useSelector(getMyTrashedFormsData)
	// const loadingTrashed = useSelector(getMyTrashedFormsDataLoading)
	var loading = useSelector(getMyFormsDataLoading)
	const messageEdit = useRef();
	const [formCount, setFormCount] = useState(0)
	const myFormCountInfo = useSelector(getFormCountInfo);
	const [duplicateLoading, setDuplicateLoading] = useState(false)
	const [showSucces, setShowSucces] = useState(false)
	const [showError, setShowError] = useState(false)
	// const [forEditTitle,setForEditTitle] = useState({})
	var forEditTitle = {}

	function showMessage(message) {
		try {
			if (messageEdit.current != null) {
				messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
				messageEdit.current.classList.add('active')
				setTimeout(() => {
					if (!messageEdit.current != null) {
						messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
					}
				}, 1000)
			}
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			if (messageEdit.current != null) {
				messageEdit.current.classList.remove('form-changes-error')
				messageEdit.current.classList.add('active', 'form-changes-success')
				setShowSucces(true)
				setShowError(false)
				setTimeout(() => {
					if (messageEdit.current != null) {
						messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
					}
				}, 1000)
			}
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			if (messageEdit.current != null) {
				messageEdit.current.classList.remove('form-changes-success')
				messageEdit.current.classList.add('active', 'form-changes-error')
				setShowSucces(false)
				setShowError(true)
				setTimeout(() => {
					if (messageEdit.current != null) {
						messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
					}
				}, 5000)
			}
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	useEffect(() => {
		let data = {};
		data.id = myInfo._id;
		setFormCount(myInfo.formCount)
	}, [myInfo])
	useEffect(() => {
		setCopyForms({ ...copyForms, data: myTrashedForms })
	}, [myTrashedForms])
	useEffect(() => {
		if (myForms.length > 0) {
			setLastForm(myForms[0])
		}
		setCopyFormsAll({ ...copyFormsAll, dataAll: myForms })
	}, [myForms])
	const handleChangeAll = e => {
		// console.log(e.target.value);
		setCopyFormsAll({ ...copyFormsAll, filterAll: e.target.value });
	};
	const handleChange = e => {
		setCopyForms({ ...copyForms, filter: e.target.value });
	};
	const addForm = e => {
		var formcheck = false;
		var formcheckText = 'Upgrade your account or enable one more form for $2.5'
		// var ee = localStorage.setItem('navigation',false);

		// if (myInfo.plan === 'Standart' || myInfo.plan === 'Free') {
			// if (myInfo.plan === 'Free') {
				if (+myInfo.haveOneTimeForm == 1) {
					formcheck = true;
				} else {
					if (myInfo.blocked == 1) {
						localStorage.setItem('navigation',true);
						formcheckText = 'You can  <a href="/profile" class="upgrade">upgrade</a> your account or enable one more form for $2.5'
					}else {
						if (myInfo.formCount > 0) {
							formcheck = true;
						} else {
						    localStorage.setItem('navigation',true);
							formcheckText = 'You can <a href="/profile" class="upgrade">upgrade</a> your account or enable one more form for $2.5'
						}
					}
				}
				// var formCount = myInfo.formCount;
				// if (formCount > 0) formcheck = true;
				// if (+myInfo.haveOneTimeForm == 1) formcheck = true;
			// } else if (myInfo.plan === 'Standart') {

				// if (+myInfo.haveOneTimeForm == 1) {
				// 	formcheck = true;
				// } else {
				// 	if (myInfo.blocked == 1) {
				// 		formcheckText = 'You can enable one more form for $2.5'
				// 	}else {
				// 		if (myInfo.formCount > 0) {
				// 			formcheck = true;
				// 		} else {
				// 			formcheckText = 'You can enable one more form for $2.5'
				// 		}
				// 	}
				// }

				// if (+myInfo.haveOneTimeForm == 1) {
				// 	formcheck = true;
				// } else {
				// 	if (myInfo.blocked == 0) {
				// 		var formCount = myInfo.formCount;
				// 		if (formCount > 0) formcheck = true;
				// 	}
				// }
			// }

		// }
		// else {
		// 	if (+myInfo.haveOneTimeForm == 1) {
		// 		formcheck = true;
		// 	} else {
		// 		if (myInfo.blocked == 1) {
		// 			formcheckText = 'You can enable one more form for $2.5'
		// 		}else {
		// 			if (myInfo.formCount > 0) {
		// 				formcheck = true;
		// 			} else {
		// 				formcheckText = 'You can enable one more form for $2.5'
		// 			}
		// 		}
		// 	}
		// }
		if (!formcheck) {
			var email = myInfo.email
			var plan = myInfo.plan
			axios.post(API_BASE_URL + '/api/formcheck', {email,plan} ).then(r => {
			})
			Swal.fire({
				title: formcheckText,
				// icon: 'warning',
				// showCancelButton: true,
				confirmButtonColor: '#1e1e1e',
				// cancelButtonColor: '#dc3545',
				confirmButtonText: 'Pay Now',
				// cancelButtonText: 'Close',
				showClass: {
					popup: 'animate__animated animate__fadeInDown'
				},
				hideClass: {
					popup: 'animate__animated animate__fadeOutUp'
				},
				customClass: {
					input: 'newFormInput',
					title: 'titleSize'
				},
				// preConfirm: (val) => {
				// 	if (trim(val) == "") {
				// 		Swal.showValidationMessage(
				// 			`The form name is required`
				// 		)
				// 	}
				// },
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post(API_BASE_URL + '/api/generate-payment-token', {}, AxiosConfigs).then(r => {
						window.open(`https://checkout.bluesnap.com/buynow/checkout?enc=${r.data}&sku4025888=1&storeid=625371`)
					})
				}
			})
		} else {
			Swal.fire({
				title: 'Enter the name of the form',
				// icon: 'warning',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off'
				},
				// showCancelButton: true,
				confirmButtonColor: '#1e1e1e',
				// cancelButtonColor: '#dc3545',
				confirmButtonText: 'Continue',
				// cancelButtonText: 'Close',
				showClass: {
					popup: 'animate__animated animate__fadeInDown'
				},
				hideClass: {
					popup: 'animate__animated animate__fadeOutUp'
				},
				customClass: {
					input: 'newFormInput',
					title: 'titleSize'
				},
				preConfirm: (val) => {
					if (trim(val) == "") {
						Swal.showValidationMessage(
							`The form name is required`
						)
					}
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed && trim(result.value) != "") {
					var data = {};
					const newform = [
						{
							id: 1,
							title: '',
							color: 'default',
							opacity: '1',
							colors: [],
							rows: [
								{
									id: 1,
									content: [
										{
											cols: [
												{
													id: 1,
													tabname: '',
													empty: true,
													content: [],
												}
											],
										}
									]
								}
							],
							button: true
						},
					];

					newform[0].title = result.value
					if (+myInfo.haveOneTimeForm == 1) { data.isOneTime = true; }
					data.newform = JSON.stringify(newform);
					axios.post(API_BASE_URL + '/api/newForm', data, AxiosConfigs)
						.then(response => {
							var id = response.data;
							var shorten = shortId(response.data)
							var newData = {}
							newData.id = id
							newData.shorten = shorten
							axios.post(API_BASE_URL + '/api/editFormID', newData, AxiosConfigs)
								.then(response => {
									window.location.href = APP_BASE_URL + '/form/' + id;
								})
								.catch(error => {

								})

						})
				}


			})
		}
	}
	const archiveForm = (e, formId) => {
		e.preventDefault()
		try {
			var currentTarget = e.currentTarget;
			var data = {};
			data.formId = formId;
			axios.post(API_BASE_URL + '/api/archiveForm', data)
				.then(response => {
					var myFormsCopy = JSON.parse(JSON.stringify(myForms))
					var index = myFormsCopy.map(function (e) { return e._id; }).indexOf(response.data._id);
					var myTrashedFormsCopy = JSON.parse(JSON.stringify(myTrashedForms))
					myTrashedFormsCopy.unshift(response.data)
					// setMyTrashedForms(myTrashedFormsCopy)
					// dispatch(getMyTrashedForms(data))
					dispatch(setMyTrashedForm(myTrashedFormsCopy))
					const removed = myFormsCopy.splice(index, 1);
					// setMyForms([])
					dispatch(setMyForm(myFormsCopy))
					// setMyForms(myFormsCopy)
					// setTabname('Trash')
				})
				.catch(error => { })

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	// useEffect(()=>{
	// 	let data = {};
	// 	data.id = myInfo._id;
	// 	console.log(data.id,78);
	// 	console.log(data.id);
	// 	if(data.id) {
	// 		axios.post(API_BASE_URL + '/api/archiveFormCount', data)
	// 			.then(response => {
	// 				console.log(response.data, "Response");
	// 			})
	// 			.catch(error => { })
	// 	}
	// }, [myInfo])
	const deleteForm = (e, idForm) => {
		e.preventDefault()
		try {
			var currentTarget = e.currentTarget;
			Swal.fire({
				title: 'Are you sure?',
				text: 'This form will be removed and it will not be possible to restore',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				},
				confirmButtonText: 'Delete',
				cancelButtonText: 'Close'
			}).then((result) => {
				if (result.isConfirmed) {
					// var formId = idForm;
					var data = {};
					data.formId = idForm;
					axios.post(API_BASE_URL + '/api/deleteForm', data)
						.then(response => {
							const myTrashedFormsCopy = myTrashedForms.filter(item => {
								return item._id != idForm;
							});
							dispatch(setMyTrashedForm(myTrashedFormsCopy))
							// setMyTrashedForms(myTrashedFormsCopy)
							if (myTrashedForms.length - 1 === 0) {
								setTabname('All')
							}

						})
						.catch(error => { })
					Swal.fire({
						icon: 'error',
						title: 'Form deleted',
						showConfirmButton: false,
						timer: 1500
					})
				}
			})

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const unarchiveForm = (e, formId) => {
		e.preventDefault()
		try {
			var currentTarget = e.currentTarget;
			var data = {};
			data.formId = formId;
			axios.post(API_BASE_URL + '/api/unArchiveForm', data)
				.then(response => {
					var myFormsCopy = JSON.parse(JSON.stringify(myForms))
					myFormsCopy.unshift(response.data)
					dispatch(setMyForm(myFormsCopy))
					const myTrashedFormsCopy = myTrashedForms.filter(item => {
						return item._id != formId;
					});
					//setTabname('All')
					dispatch(setMyTrashedForm(myTrashedFormsCopy))
					// setMyTrashedForms(myTrashedFormsCopy)
					if (myTrashedForms.length - 1 === 0) {
						setTabname('All')
					}
				})
				.catch(error => { })

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeTab = e => {
		e.preventDefault()
		try {
			const currentTarget = e.currentTarget
			var tabname = currentTarget.getAttribute('tabname')
			if (tabname === 'All') {
				if (tabLast.current != null) { tabLast.current.classList.remove('active') }
				if (tabTrash.current != null) { tabTrash.current.classList.remove('active') }
				currentTarget.classList.add('active')
			} else if (tabname === 'Last') {
				if (tabAll.current != null) { tabAll.current.classList.remove('active') }
				if (tabTrash.current != null) { tabTrash.current.classList.remove('active') }
				currentTarget.classList.add('active')
			} else {
				if (tabAll.current != null) { tabAll.current.classList.remove('active') }
				if (tabLast.current != null) { tabLast.current.classList.remove('active') }
				currentTarget.classList.add('active')
			}
			setTabname(tabname)

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const { filter, data } = copyForms;
	const { filterAll, dataAll } = copyFormsAll;
	const lowercasedFilter = filter.toLowerCase();
	const lowercasedFilterAll = filterAll.toLowerCase();
	const filteredData = data.filter(item => {
		return item.title ? item.title.toLowerCase().includes(lowercasedFilter) : true
	});
	const filteredDataAll = dataAll.filter(item => {
		return item.title ? item.title.toLowerCase().includes(lowercasedFilterAll) : true
	});
	// const filteredDataAllRecent = dataAll.filter(item => {
	// 	return item.title.toLowerCase().includes(lowercasedFilterAll)
	// });
	const changeTitle = (e, id, index) => {
		e.preventDefault()
		try {
			const currentTarget = e.currentTarget
			// var formId = currentTarget.getAttribute('formid')
			// var formIndex = currentTarget.getAttribute('formindex')
			var title = currentTarget.nextSibling.innerText
			var data = {}
			if (title != '') {
				data.id = id
				data.title = title
				axios.post(API_BASE_URL + '/api/changeTitle', data)
					.then(response => {
						currentTarget.querySelectorAll('span')[0].classList.remove('active')
						var myFormsCopy = JSON.parse(JSON.stringify(myForms))
						myFormsCopy[index].title = title
						dispatch(setMyForm(myFormsCopy))
						showMessageSuccess('Changed successfuly!!')
					})
					.catch(error => {
						showMessageError('Something went wrong.')
					})
			} else {
				showMessageError('Please enter the form name')
			}
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	useEffect(() => {
		if (tabname === 'Last') {
			setCopyFormsAll({ filterAll: '', dataAll: myForms })
		}
		if (tabname === 'Trash') {
			setCopyFormsAll({ filterAll: '', dataAll: myForms })
		}
	}, [tabname])

	const editTitle = (e, id, index) => {
		var title = e.target.innerText
		forEditTitle = {
			id: id,
			title: title,
			index: index
		}
	}
	// useEffect(()=>{
	document.documentElement.addEventListener("click", function (e) {
		if (forEditTitle.id) {
			// if (!e.target.closest('.user-select-text')) {
			try {
				var data = {}
				var title = forEditTitle.title
				var id = forEditTitle.id
				var index = forEditTitle.index
				if (title !== '' && title.length !== 0) {
					data.id = id
					data.title = title
					axios.post(API_BASE_URL + '/api/changeTitle', data)
						.then(response => {
							var myFormsCopy = JSON.parse(JSON.stringify(myForms))
							myFormsCopy[index].title = title
							dispatch(setMyForm(myFormsCopy))
							showMessageSuccess('Changed successfuly!!')
						})
						.catch(error => {
							showMessageError('Something went wrong.')
						})
					forEditTitle = {}
				} else {
					showMessageError('Please enter the form name')
				}
			} catch (error) {
				showMessageError('Something went wrong.')
			}
			// }
		}
	})
	// },[forEditTitle.id])

	const deleteAllForms = (e) => {
		e.preventDefault()
		try {
			Swal.fire({
				title: 'Are you sure?',
				text: 'This form will be removed and it will not be possible to restore',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				},
				confirmButtonText: 'Delete',
				cancelButtonText: 'Close'
			}).then((result) => {
				if (result.isConfirmed) {
					// var formId = idForm;
					var data = {};
					data.id = myInfo._id
					axios.post(API_BASE_URL + '/api/deleteAllForms', data)
						.then(response => {
							dispatch(setMyTrashedForm([]));
							setTabname('All')
						})
						.catch(error => { })
					Swal.fire({
						icon: 'error',
						title: 'Form deleted',
						showConfirmButton: false,
						timer: 1500
					})
				}
			})

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const archiveAllForms = (e) => {
		e.preventDefault();
		try {
			Swal.fire({
				title: 'Are you sure?',
				text: 'This form will be removed and it will not be possible to restore',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				},
				confirmButtonText: 'Archive',
				cancelButtonText: 'Close'
			}).then((result) => {
				if (result.isConfirmed) {
					// var formId = idForm;
					var data = {};
					data.id = myInfo._id
					axios.post(API_BASE_URL + '/api/archiveAllForms', data)
						.then(response => {
							let myFormsCopy = JSON.parse(JSON.stringify(myForms))
							let myTrashedFormsCopy = JSON.parse(JSON.stringify(myTrashedForms))
							// dispatch(setMyTrashedForm([...myFormsCopy, ...myTrashedFormsCopy]))
							dispatch(getMyTrashedForms())
							dispatch(setMyForm([]));

						})
						.catch(error => { })
					Swal.fire({
						icon: 'error',
						title: 'Form archived',
						showConfirmButton: false,
						timer: 1500
					})
				}
			})

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const [copyButton, setCopyButton] = useState({ copied: 'Link Copied!', notCopied: 'Copy Link' })
	const copyLink = e => {
		var currentTarget = e.currentTarget
		var forThisChangeIcon = currentTarget.querySelectorAll('.forChangeIcon')[0]
		var formLinkButtonTitle = currentTarget.closest('.form-link-buttons').querySelectorAll('.form-link-button-title')[0]
		e.preventDefault()
		try {
			e.currentTarget.previousSibling.classList.toggle('copied')
			if (e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.contains('fa-link')) {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-link')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-check', 'copied')
			} else {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-check', 'copied')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-link')
			}

			setTimeout(() => {
				forThisChangeIcon.classList.add('fa-link')
				formLinkButtonTitle.classList.remove('copied')
			}, 500);

			// navigator.clipboard.writeText(e.currentTarget.getAttribute('link'))
			var textField = document.createElement('textarea')
			textField.innerText = e.currentTarget.getAttribute('link')
			document.body.appendChild(textField)
			textField.select()
			document.execCommand('copy')
			textField.remove()
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const [editedMessage, setEditedMessage] = useState('')
	const duplicateForm = formId => {
		try {
			dispatch(setMyFormLoading(true))
			var data = {}
			data.id = formId
			axios.post(API_BASE_URL + '/api/getMyFormsForm', data)
				.then(response => {
					if (response.data) {
						const formCopy = JSON.parse(JSON.stringify(response.data));
						const userFormlabels = []
						const oldLabels = []
						for (let i = 0; i < formCopy.length; i++) {
							const forJ = formCopy[i].rows;
							for (let j = 0; j < forJ.length; j++) {
								const forK = forJ[j].content[0].cols
								for (let k = 0; k < forK.length; k++) {
									if (forK[k].tabname != 'image' && forK[k].tabname != '') {
										oldLabels.push({ 'label': forK[k].uniqueId, 'type': forK[k].tabname })
										formCopy[i].rows[j].content[0].cols[k].uniqueId = randomString(50)
										userFormlabels.push({ 'label': forK[k].content['label'], 'uniqueId': forK[k].uniqueId, 'type': forK[k].tabname });
									}
								}
							}
						}
						data.labels = userFormlabels
						data.formData = formCopy
						axios.post(API_BASE_URL + '/api/duplicateForm', data, AxiosConfigs)
							.then(response => {
								var newData = {}
								newData.id = response.data._id
								newData.shorten = shortId(response.data._id)
								axios.post(API_BASE_URL + '/api/editFormID', newData, AxiosConfigs)
									.then(response => {
										if(myFormCountInfo == 1){
											setFormCount(0)
										};
										dispatch(setFormCountInfo(myFormCountInfo - 1))
										var info = {};
										info.id = newData.user_id;
										async function getDatas() {
											await dispatch(getMyForms(info))
										}
										getDatas()
									})
									.catch(error => {

									})
							})
							.catch(error => { })
					}
				})
				.catch(error => { })

		} catch (error) {
			console.log(error)
			dispatch(setMyFormLoading(false))
			showMessageError('Something went wrong.')
		}

	}

	const history = useHistory();
	const goProfile = e => {
		localStorage.setItem('navigation', true);
		history.push('/profile')
	}

	return (
		<div className='myForms-container'>

			{/* <div className="px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header">
				<div className="container-fluid px-0">
					<div className="row align-items-center">
						<div className="col">
							<span className="text-uppercase tiny text-gray-600 Montserrat-font font-weight-semibold">Account</span>
							<h1 className="h2 mb-0 lh-sm account-tabname">My forms</h1>
						</div>
						<div className="col-auto d-flex align-items-center my-2 my-sm-0">
							<a href={APP_BASE_URL + "/settings"} className="btn btn-lg btn-warning"><svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="14.036" height="14.036" viewBox="0 0 14.036 14.036">
								<g transform="translate(0.036 0.036)">
									<rect data-name="Icons/Tabler/Adjustments background" width={14} height={14} fill="none" />
									<path d="M11.2,13.458l0-.057V5.117a2.2,2.2,0,0,1,0-4.233V.6A.6.6,0,0,1,12.4.543L12.4.6V.883a2.2,2.2,0,0,1,0,4.233V13.4a.6.6,0,0,1-1.2.057ZM10.8,3a1,1,0,1,0,1-1A1,1,0,0,0,10.8,3ZM6.4,13.458l0-.057V12.317a2.2,2.2,0,0,1,0-4.233V.6A.6.6,0,0,1,7.6.543L7.6.6V8.083a2.2,2.2,0,0,1,0,4.233V13.4a.6.6,0,0,1-1.2.057ZM6,10.2a1,1,0,1,0,1-1A1,1,0,0,0,6,10.2ZM1.6,13.458l0-.057V7.516a2.2,2.2,0,0,1,0-4.234V.6A.6.6,0,0,1,2.8.543L2.8.6V3.283a2.2,2.2,0,0,1,0,4.233V13.4a.6.6,0,0,1-1.2.057ZM1.2,5.4a1,1,0,1,0,1-1A1,1,0,0,0,1.2,5.4Z" transform="translate(-0.036 -0.036)" fill="#1e1e1e" />
								</g>
							</svg><span>Account settings</span>
							</a>
						</div>
					</div>
				</div>
			</div> */}
			{/* <a target="_blank" href="https://sandbox.bluesnap.com/buynow/checkout?sku2798327=1&storeid=326101" type="button" className="btn btn-xl btn-warning text-nowrap ms-xl-2 new-form-btn">
				<svg className="me-2" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14">
					<rect data-name="Icons/Tabler/Add background" width={14} height={14} fill="none" />
					<path d="M6.329,13.414l-.006-.091V7.677H.677A.677.677,0,0,1,.585,6.329l.092-.006H6.323V.677A.677.677,0,0,1,7.671.585l.006.092V6.323h5.646a.677.677,0,0,1,.091,1.348l-.091.006H7.677v5.646a.677.677,0,0,1-1.348.091Z" fill="#000" />
				</svg> Premium</a>
			<a target="_blank" href="https://sandbox.bluesnap.com/buynow/checkout?sku2798327=1&storeid=326101" type="button" className="btn btn-xl btn-warning text-nowrap ms-xl-2 new-form-btn">
				<svg className="me-2" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14">
					<rect data-name="Icons/Tabler/Add background" width={14} height={14} fill="none" />
					<path d="M6.329,13.414l-.006-.091V7.677H.677A.677.677,0,0,1,.585,6.329l.092-.006H6.323V.677A.677.677,0,0,1,7.671.585l.006.092V6.323h5.646a.677.677,0,0,1,.091,1.348l-.091.006H7.677v5.646a.677.677,0,0,1-1.348.091Z" fill="#000" />
				</svg> Standart</a> */}
			<div className="p-3 px-3 py-1">
				<div className="form-changes" ref={messageEdit} >
				{
					showSucces?
						<>
							<div className='img-div'>
								<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
										stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
							
						</>
					:null
					}
					{
					showError?
						<>
							<div className='img-div'>
								<svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
										stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
						</>
					:null
				}
				</div>
				<div className="container-fluid px-0">
					{
						myForms.length > 0 || myTrashedForms.length > 0
							?
							<div className="mb-2 mb-md-3 mb-xl-4 pb-3 d-flex justify-content-between align-items-center">
								<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative zIndex-0">
									<li className="nav-item">
										<button type="button" ref={tabAll} onClick={changeTab} tabname="All" className={tabname == 'All' ? "nav-link active" : "nav-link"}>All{myForms.length > 0 ? '(' + myForms.length + ')' : null}</button>
									</li>
									{/* {
								myForms.length > 0
									?
									<li className="nav-item">
										<button type="button" ref={tabLast} onClick={changeTab} tabname="Last" className={tabname == 'Last' ? "nav-link active" : "nav-link"}>Recent</button>
									</li>
									: null
							} */}
									{
										myTrashedForms.length > 0
											?
											<li className="nav-item">
												<button type="button" ref={tabTrash} onClick={changeTab} tabname="Trash" className={tabname == 'Trash' ? "nav-link active nav-link-archive" : "nav-link nav-link-archive"}>Archived{myTrashedForms.length > 0 ? '(' + myTrashedForms.length + ')' : null}</button>
											</li>
											: null
									}
								</ul>
								{
									myForms.length > 0 && tabname === 'All'
										?
										<div className='all-trashed-icon'>
											<div className='form-link-button-body'>
												<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive All</div>
												<button onClick={(e) => archiveAllForms(e)} className='btn form-link-button'>
													<span>
														<i className="fas fa-trash"></i>
													</span>
												</button>
											</div>
										</div>
										: null
								}
								{
									myTrashedForms.length > 0 && tabname === 'Trash'
										?
										<div className='all-trashed-icon'>
											<div className='form-link-button-body'>
												<div className="form-link-button-title-bottom form-link-button-title-hovered">Delete All</div>

												<button onClick={(e) => deleteAllForms(e)} className='btn form-link-button'>
													<span>
														<i className="fas fa-times"></i>
													</span>
												</button>
											</div>
										</div>
										: null
								}
							</div>
							: ''
					}
					<div className="tabContent">
						{
							tabname == 'All'
								?
								<div>

									<div className="pb-2 pt-3 mb-4 mb-1">
										<form>
											<div className="row align-items-center">
												{
													myForms.length > 0
														?
														<div className="col">
															<div className="input-group input-group-xl bg-white border border-gray-300 rounded px-3 me-2 me-xl-4">
																<button type="button" className="border-0 bg-transparent p-1"><img src="/svg/icons/search@14.svg" alt="Search" /></button>
																<input type="search" className="form-control border-0" placeholder="Search form..." onChange={handleChangeAll} />
															</div>
														</div>
														: null
												}
												<div className={myForms.length > 0 ? "col-12 col-md-auto mt-3 mt-md-0 d-grid" : "col-12 col-md-auto mt-3 mt-md-0 d-grid mx-auto"}>
													{
														loading ? '' :
															<button type="button" onClick={addForm} className="btn btn-xl btn-warning text-nowrap ms-xl-2 new-form-btn">
																{/* {
															myInfo?.formCount > 0 && myInfo?.formCount !== 9999 && myInfo?.plan !== 'Premium'
															? <span title="Availabe forms count" className="my-forms-count">{myInfo.formCount}</span>
															: myInfo?.plan === 'Premium'
																? <span title="Availabe forms count" className="my-forms-count"><i className="fa fa-solid fa-infinity"></i></span>
																: null
															} */}
																<svg className="me-2" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14">
																	<rect data-name="Icons/Tabler/Add background" width={14} height={14} fill="none" />
																	<path d="M6.329,13.414l-.006-.091V7.677H.677A.677.677,0,0,1,.585,6.329l.092-.006H6.323V.677A.677.677,0,0,1,7.671.585l.006.092V6.323h5.646a.677.677,0,0,1,.091,1.348l-.091.006H7.677v5.646a.677.677,0,0,1-1.348.091Z" fill="#000" />
																</svg> New Form</button>
													}
												</div>
											</div>
										</form>
									</div>
									{!loading ? (
										myForms.length > 0
											?
											<div className="row group-cards">
												<div className="col-xl-6 mb-4 form-content">
													<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-0">
														{filteredDataAll.length > 0
															?
															<div className="formsTable">
																<div className="table-responsive">
																	<table className="table card-table table-nowrap">
																		<thead>
																			<tr>
																				<th scope="col" className="col-sm-7 col-7">Form</th>
																				<th scope="col">Responses</th>
																				<th scope="col">Statistics</th>
																				<th scope="col">Updated</th>
																				<th scope="col">Actions</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				filteredDataAll.map(function (myForm, i) {
																					return <tr key={i} className={myInfo?.blocked == 1 && !myForm.isOneTime ? 'blocked-form-tr' : ''}>
																						{myInfo?.blocked == 1 && !myForm.isOneTime ?
																							<div className="blocked-form-div">
																								Your account has been suspended. Please <button type="button" onClick={goProfile}>pay</button> to continue using this form.
																							</div>
																							: null
																						}
																						<td className='d-flex align-items-center'>
																							{
																								myInfo?.blocked == 1 && !myForm.isOneTime
																									?
																									<div className="avatar">
																										<img className="avatar-img rounded-0" src="/svg/icons/Group_2967.svg" alt="Avatars" />
																									</div>
																									:
																									<Link to={"/form/" + myForm._id} className="avatar">
																										<img className="avatar-img rounded-0" src="/svg/icons/Group_2967.svg" alt="Avatars" />
																									</Link>
																							}
																							{/* {
																									lastForm.responses !== 0 && lastForm.responses !== undefined
																										?
																										<div className='form-link-button-body form_responses-body'>
																											<div className="form-link-button-title-bottom form-link-button-title-hovered">Responses</div>
																											<Link to={'/responses/' + lastForm._id} className='btn form-link-button'><span className='form_responses'>{lastForm.responses}</span></Link>
																										</div>
																										: null
																								} */}
																							<div className="left-myForms-content">
																								<div className="left-myForms-info-top">
																									<div className="d-flex form-link-left align-items-center">
																										{/* <button type="button" onClick={(e) => changeTitle(e, lastForm._id, 0)}><span><i className="fas fa-save"></i></span></button> */}
																										{/* <input className="mb-1 font-weight-semibold" defaultValue={lastForm.title} onChange={editTitle} /> */}
																										{
																											myInfo?.blocked == 1 && !myForm.isOneTime
																												?
																												<div>
																													<div title={myForm.title} className='user-select-text-heading mx-2 mb-1 font-weight-semibold'>{myForm.title}</div>
																												</div>
																												:
																												<Link to={"/form/" + myForm._id}>
																													<div title={myForm.title} className='user-select-text-heading mx-2 mb-1 font-weight-semibold'>{myForm.title}</div>
																												</Link>
																										}
																										{/*<div title='' className='user-select-text mx-2 mb-1 font-weight-semibold' format="text" contentEditable="true" onInput={e => editTitle(e, myForm._id, i)} suppressContentEditableWarning={true}>{myForm.title}</div>*/}

																									</div>
																								</div>
																								<div className='left-myForms-info-bottom'>
																									<div className="d-flex form-link-left">
																										<p className="card-text small text-gray-600 lh-sm d-flex mb-0">
																											{/* <img className="me-2" src="/svg/icons/clock.svg" alt="Clock" />  */}
																											Created - {moment(myForm.updated_at).format('YYYY-MM-DD')}
																										</p>
																									</div>
																								</div>
																							</div>
																						</td>
																						<td>
																							{
																								myForm.responses.length > 0 && myForm.responses !== 0 && myForm.responses !== undefined
																									? myInfo?.blocked == 1 && !myForm.isOneTime
																										? <div className='btn form-link-button response-link-button'>{myForm.responses.length}</div>
																										: <Link to={'/responses/' + myForm._id} className='btn form-link-button response-link-button'>{myForm.responses.length}</Link>
																									: <div className='form-link-button-response-zero response-zero'>0</div>
																							}
																						</td>
																						<td>
																							<div className='form-link-button-body'>
																								{
																									myForm.responses.length > 0 && myForm.responses !== 0 && myForm.responses !== undefined
																										? myInfo?.blocked == 1 && !myForm.isOneTime
																											? <div className='btn form-link-button'><span><i style={{ color: "black" }} className="fas fa-chart-line"></i></span></div>
																											: <Link to={'/statistics/' + myForm._id} className='btn form-link-button'><span><i style={{ color: "black" }} className="fas fa-chart-line"></i></span></Link>
																										: <div className='btn form-link-button-response-zero'><span><i className="fas fa-chart-line"></i></span></div>
																								}
																							</div>
																						</td>
																						<td className="formsTable-td">{moment(myForm.updated_at).format('YYYY-MM-DD')}</td>
																						<td>
																							<div className="d-flex align-items-center">
																								<div className='form-link-buttons button-link'>
																									<div className='form-link-button-body'>
																										{

																											myForm.structure && Object.keys(myForm.structure).length > 0 ?
																												myInfo?.blocked == 1 && !myForm.isOneTime
																													? <button type='button' className='btn form-link-button cursor-default'>
																														<span className="slashIconLink">
																															<i className="fas fa-link"></i>
																															{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">*/}
																															{/*	<path d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4*/}
																															{/*	318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3*/}
																															{/*	281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99*/}
																															{/*	320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404*/}
																															{/*	255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741*/}
																															{/*	392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6*/}
																															{/*	193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8*/}
																															{/*	262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5*/}
																															{/*	108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5*/}
																															{/*	59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z"/>*/}
																															{/*</svg>*/}
																														</span>
																													</button>
																													:
																													<>
																														<div className="form-link-button-title form-link-button-title-hovered"></div>
																														<button type='button' className='btn form-link-button' link={APP_BASE_URL + '/f-' + myForm.link} onClick={copyLink}>
																															<span>
																																<i className=" fas fa-link forChangeIcon"></i>
																															</span>
																														</button>
																													</> :
																												<button type='button' className='btn form-link-button cursor-default'>
																													<span className="slashIconLink">
																														<i className="fas fa-link"></i>
																														{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">*/}
																														{/*	<path d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4*/}
																														{/*	318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3*/}
																														{/*	281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99*/}
																														{/*	320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404*/}
																														{/*	255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741*/}
																														{/*	392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6*/}
																														{/*	193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8*/}
																														{/*	262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5*/}
																														{/*	108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5*/}
																														{/*	59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z"/>*/}
																														{/*</svg>*/}
																													</span>
																												</button>
																										}
																									</div>
																								</div>

																								<div className='form-link-buttons button-statistics'>
																									<div className='form-link-button-body'>
																										{myForm.structure && Object.keys(myForm.structure).length > 0 ?
																											// myInfo?.blocked == 1 && !myForm.isOneTime
																											// 	?
																											// 	<>
																											// 		<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																											// 		<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																											// 	</>
																											// 	:
																											// 	myInfo?.formCount <= 0
																											// 		?
																											// <>
																											// 	<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																											// 	<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																											// </>
																											// 		:
																											// <>
																											// 	<div className="form-link-button-title-bottom form-link-button-title-hovered">Duplicate</div>
																											// 	<button onClick={() => duplicateForm(myForm._id)} className='btn form-link-button'><span><i className="fas fa-clone"></i></span></button>
																											// </> :
																											// <>
																											// 	<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																											// 	<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																											// </>
																											myInfo?.formCount > 0 || (myInfo?.haveOneTimeForm && +myInfo.haveOneTimeForm == 1)
																												? myInfo?.blocked == 1 && !myForm.isOneTime
																													? <>
																														<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																														<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																													</>
																													:
																													( myInfo?.haveOneTimeForm && +myInfo.haveOneTimeForm == 1 ) || ( myInfo?.blocked == 0 && formCount > 0)
																														? <>
																															<div className="form-link-button-title-bottom form-link-button-title-hovered">Duplicate</div>
																															<button onClick={() => duplicateForm(myForm._id)} className='btn form-link-button'><span><i className="fas fa-clone"></i></span></button>
																														</>
																														:
																														<>
																															<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																															<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																														</>
																												: <>
																													<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																													<button onClick={addForm} className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																												</>
																											:
																											<>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Can't Duplicate</div>
																												<button className='btn form-link-button slashIconLink'><span><i className="fas fa-clone"></i></span></button>
																											</>
																										}
																									</div>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className='form-link-button-body'>
																										<div className="form-link-button-title-bottom form-link-button-title-hovered">Edit</div>
																										{
																											myInfo?.blocked == 1 && !myForm.isOneTime
																												?
																												<div className="btn form-link-button">
																													<span><i className="fas fa-pen"></i></span>
																												</div>
																												:
																												<Link to={"/form/" + myForm._id} className="btn form-link-button">
																													<span><i className="fas fa-pen"></i></span>
																												</Link>
																										}
																									</div>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className='form-link-button-body'>
																										<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																										{
																											myInfo?.blocked == 1 && !myForm.isOneTime
																												?
																												<button type="button" className='btn form-link-button'><span><i className="fas fa-trash"></i></span></button>
																												:
																												<button onClick={e => archiveForm(e, myForm._id)} className='btn form-link-button'><span><i className="fas fa-trash"></i></span></button>
																										}
																									</div>
																								</div>
																							</div>
																						</td>
																					</tr>
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
															:
															<div className="form-search">
																<h4 className='mt-2'>Nothing Found</h4>
																<div className="form-search-image">
																	<img src="/img/BG/nodataphotos.svg" alt="" />
																</div>
															</div>
														}
													</div>
												</div>
											</div>
											: <div className="form-search">
												<p className='form-search'>You don't have a form, create it.</p>
												<div className="form-search-image">
													<img src="/img/BG/FormEmpty.svg" alt="" />
												</div>
											</div>
									) :
										<div className="row group-cards">
											<div className="col-xl-6 mb-4 form-content">
												<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
													<Loader />
												</div>
											</div>
										</div>
									}
								</div>
								// : (tabname == 'Last'
								// 	?
								// 	<div>
								// 		{
								// 		!loading 
								// 		? 
								// 		<div className="row group-cards">
								// 			<div className="col-xl-6 mb-4 form-content">
								// 				<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-0">
								// 					{
								// 						filteredDataAll.length > 0
								// 						?
								// 						<div className="formsTable">
								// 							<div className="table-responsive">
								// 								<table className="table card-table table-nowrap">
								// 									<thead>
								// 										<tr>
								// 											<th scope="col" className="col-sm-7 col-7">Form</th>
								// 											<th scope="col">Responses</th>
								// 											<th scope="col">Statistics</th>
								// 											<th scope="col">Updated</th>
								// 											<th scope="col">Actions</th>
								// 										</tr>
								// 									</thead>
								// 									<tbody>
								// 										{
								// 											filteredDataAll.slice(0, 3).map(function (lastForm, i) {
								// 												return <tr key={i}>
								// 													<td className='d-flex align-items-center'>
								// 														<Link to={"/form/" + lastForm._id} className="avatar">
								// 															<img className="avatar-img rounded-0" src="/svg/icons/Group_2967.svg" alt="Avatars" />
								// 														</Link>
								// 														{/* {
								// 															lastForm.responses !== 0 && lastForm.responses !== undefined
								// 																?
								// 																<div className='form-link-button-body form_responses-body'>
								// 																	<div className="form-link-button-title-bottom form-link-button-title-hovered">Responses</div>
								// 																	<Link to={'/responses/' + lastForm._id} className='btn form-link-button'><span className='form_responses'>{lastForm.responses}</span></Link>
								// 																</div>
								// 																: null
								// 														} */}
								// 														<div className="left-myForms-content">
								// 															<div className="left-myForms-info-top">
								// 																<div className="d-flex form-link-left align-items-center">
								// 																	{/* <button type="button" onClick={(e) => changeTitle(e, lastForm._id, 0)}><span><i className="fas fa-save"></i></span></button> */}
								// 																	{/* <input className="mb-1 font-weight-semibold" defaultValue={lastForm.title} onChange={editTitle} /> */}
								// 																	<div className='user-select-text mx-2 mb-1 font-weight-semibold' format="text" contentEditable="true" onInput={e => editTitle(e, lastForm._id, i)} suppressContentEditableWarning={true}>{lastForm.title}</div>
								// 																</div>
								// 															</div>
								// 															<div className='left-myForms-info-bottom'>
								// 																<div className="d-flex form-link-left">
								// 																	<p className="card-text small text-gray-600 lh-sm d-flex mb-0">
								// 																		{/* <img className="me-2" src="/svg/icons/clock.svg" alt="Clock" />  */}
								// 																		Created - {moment(lastForm.created_at).format('YYYY-MM-DD')}
								// 																	</p>
								// 																</div>
								// 															</div>
								// 														</div>
								// 													</td>
								// 													<td>
								// 														{
								// 															lastForm.responses !== 0 && lastForm.responses !== undefined
								// 																? <Link to={'/responses/' + lastForm._id} className='btn form-link-button response-link-button'>{lastForm.responses}</Link>
								// 																: <div className='form-link-button-response-zero response-zero'>0</div>
								// 														}
								// 													</td>
								// 													<td>
								// 														<div className='form-link-button-body'>
								// 															<Link to={'/statistics/' + lastForm._id} className='btn form-link-button'><span><i className="fas fa-chart-line"></i></span></Link>
								// 														</div>
								// 													</td>
								// 													<td>{moment(lastForm.updated_at).format('YYYY-MM-DD')}</td>
								// 													<td>
								// 														<div className="d-flex align-items-center">
								// 															<div className='form-link-buttons button-link'>
								// 																<div className='form-link-button-body'>
								// 																	<div className="form-link-button-title form-link-button-title-hovered"></div>
								// 																	<button type='button' className='btn form-link-button' link={APP_BASE_URL + '/f-' + lastForm.link} onClick={copyLink}><span><i className=" fas fa-link forChangeIcon"></i></span></button>
								// 																</div>
								// 															</div>

								// 															<div className='form-link-buttons button-statistics'>
								// 																<div className='form-link-button-body'>
								// 																	<div className="form-link-button-title-bottom form-link-button-title-hovered">Duplicate</div>
								// 																	<button onClick={() => duplicateForm(lastForm._id)} className='btn form-link-button'><span><i className="fas fa-clone"></i></span></button>
								// 																</div>
								// 															</div>
								// 															<div className='form-link-buttons button-statistics'>
								// 																<div className='form-link-button-body'>
								// 																	<div className="form-link-button-title-bottom form-link-button-title-hovered">Edit</div>
								// 																	<Link to={"/form/" + lastForm._id} className="btn form-link-button">
								// 																		<span><i className="fas fa-pen"></i></span>
								// 																	</Link>
								// 																</div>
								// 															</div>
								// 															<div className='form-link-buttons button-statistics'>
								// 																<div className='form-link-button-body'>
								// 																	<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
								// 																	<button onClick={e => archiveForm(e, lastForm._id)} className='btn form-link-button'><span><i className="fas fa-trash"></i></span></button>
								// 																</div>
								// 															</div>
								// 														</div>
								// 													</td>
								// 												</tr>
								// 											})
								// 										}
								// 									</tbody>
								// 								</table>
								// 							</div>
								// 						</div>
								// 						:
								// 						<div className="form-search">
								// 							<div className="form-search-image">
								// 								<img src="/img/BG/FormSearch.webp" alt="" />
								// 							</div>
								// 						</div>
								// 					}
								// 				</div>
								// 			</div>
								// 		</div>
								// 		:
								// 			<div className="row group-cards">
								// 				<div className="col-xl-6 mb-4 form-content">
								// 					<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
								// 						<Loader />
								// 					</div>
								// 				</div>
								// 			</div>
								// 		}
								// 	</div>
								:
								(
									tabname == 'Trash'
										?
										<div>

											{/* <div className="pb-2 pt-3 mb-4 mb-1">
													<form>
														<div className="row align-items-center">
															{
																myTrashedForms.length > 0
																	?
																	<div className="col">
																		<div className="input-group input-group-xl bg-white border border-gray-300 rounded px-3 me-2 me-xl-4">
																			<button type="button" className="border-0 bg-transparent p-1"><img src="/svg/icons/search@14.svg" alt="Search" /></button>
																			<input type="search" className="form-control border-0" placeholder="Search form..." onChange={handleChange} />
																		</div>
																	</div>
																	: null
															}
														</div>
													</form>
												</div> */}
											{
												myTrashedForms.length > 0
													?
													<div className="row group-cards">
														<div className="col-xl-6 mb-4 form-content">
															<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-0">
																{filteredData.length > 0
																	?
																	<div className="formsTable">
																		<div className="table-responsive">
																			<table className="table card-table table-nowrap">
																				<thead>
																					<tr>
																						<th scope="col" className="col-sm-9 col-9">Form</th>
																						<th scope="col">Responses</th>
																						<th scope="col">Archived</th>
																						<th scope="col">Actions</th>
																					</tr>
																				</thead>
																				<tbody>
																					{
																						filteredData.map(function (myForm, i) {
																							return <tr key={i} className="formsTable-archive">
																								{myInfo?.blocked == 1 && !myForm.isOneTime ?
																									<div className="blocked-form-div">
																										To continue using form, please make a <button type="button" onClick={goProfile}>payment</button> first.
																									</div>
																									: null
																								}
																								<td className='d-flex align-items-center'>
																									<div className="avatar">
																										<img className="avatar-img rounded-0" src="/svg/icons/Group_2967.svg" alt="Avatars" />
																									</div>
																									{/* {
																											lastForm.responses !== 0 && lastForm.responses !== undefined
																												?
																												<div className='form-link-button-body form_responses-body'>
																													<div className="form-link-button-title-bottom form-link-button-title-hovered">Responses</div>
																													<Link to={'/responses/' + lastForm._id} className='btn form-link-button'><span className='form_responses'>{lastForm.responses}</span></Link>
																												</div>
																												: null
																										} */}
																									<div className="left-myForms-content">
																										<div className="left-myForms-info-top">
																											<div className="d-flex form-link-left align-items-center">
																												{/* <button type="button" onClick={(e) => changeTitle(e, lastForm._id, 0)}><span><i className="fas fa-save"></i></span></button> */}
																												{/* <input className="mb-1 font-weight-semibold" defaultValue={lastForm.title} onChange={editTitle} /> */}
																												<div className='user-select-text mx-2 mb-1 font-weight-semibold' format="text" suppressContentEditableWarning={true}>{myForm.title}</div>
																											</div>
																										</div>
																										<div className='left-myForms-info-bottom'>
																											<div className="d-flex form-link-left">
																												<p className="card-text small text-gray-600 lh-sm d-flex mb-0">
																													{/* <img className="me-2" src="/svg/icons/clock.svg" alt="Clock" />  */}
																													Created - {moment(myForm.created_at).format('YYYY-MM-DD')}
																												</p>
																											</div>
																										</div>
																									</div>
																								</td>
																								<td>
																									{
																										myForm.responses.length > 0 && myForm.responses !== 0 &&  myForm.responses !== undefined
																											? myInfo?.blocked == 1 && !myForm.isOneTime
																												? <div className='btn form-link-button response-link-button'>{myForm.responses.length}</div>
																												: <Link to={'/responsestrashed/' + myForm._id} className='btn form-link-button response-link-button'>{myForm.responses.length}</Link>
																											: <div className='form-link-button-response-zero response-zero'>0</div>
																									}
																								</td>
																								<td className="formsTable-td">{moment(myForm.deleted_at).format('YYYY-MM-DD')}</td>
																								<td>
																									<div className="d-flex align-items-center">
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Delete</div>
																												{
																													myInfo?.blocked == 1 && !myForm.isOneTime
																														? <button type="button" className='btn form-link-button'><span><i className="fas fa-times"></i></span></button>
																														: <button onClick={e => deleteForm(e, myForm._id)} className='btn form-link-button'><span><i className="fas fa-times"></i></span></button>
																												}
																											</div>
																										</div>
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Restore</div>
																												{
																													myInfo?.blocked == 1 && !myForm.isOneTime
																														?
																														<button type="button" className='btn form-link-button'><span>
																															<img src="/svg/icons/rotate-right-solid.svg" className="svg-icon-default" width="16" alt="" /></span>
																														</button>
																														:
																														<button onClick={e => unarchiveForm(e, myForm._id)} className='btn form-link-button'><span>
																															<img src="/svg/icons/rotate-right-solid.svg" className="svg-icon-default" width="16" alt="" /></span>
																														</button>
																												}
																											</div>
																										</div>
																									</div>
																								</td>
																							</tr>
																						})
																					}
																				</tbody>
																			</table>
																		</div>
																	</div>
																	:
																	<div className="form-search">
																		<div className="form-search-image">
																			<img src="/img/BG/FormSearch.webp" alt="" />
																		</div>
																	</div>
																}
															</div>
														</div>
													</div>
													: <div className="form-search">
														<p className='form-search'>You don't have a form, create it.</p>
														<div className="form-search-image">
															<img src="/img/BG/nodataphotos.svg" alt="" />
														</div>
													</div>
											}
										</div>
										: null
								)

							// )
						}
					</div>
				</div>
			</div>
		</div >
	);
};

export default MyForms;