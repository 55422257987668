import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {API_BASE_URL} from '../../config';
import {eraseCookie, getCookie, setCookie} from '../../cookie';
import {getResponseLimit, getResponseLimitData} from '../../redux/reducers/formReducer';
import {getInfo, getInfoData} from "../../redux/reducers/infoReducer"
import Loader from '../../UI/Loader/Loader';
import Countdown from './FormComponents/Countdown';
import Row from './FormComponents/Row';

const UserForm = () => {
    const form_id = window.location.pathname.substring(3);
    document.title = `FormBuilder - ${form_id}`;
    useLayoutEffect(() => {
        if (getCookie('_sfnu' + form_id)) {
            window.location.href = window.location.origin + '/'
        } else {
        }
        if (!getCookie('_fsuf' + form_id)) {
            setCookie('_fsuf' + form_id, moment(new Date()).format("DD/MM/YYYY H:mm:ss"), 365)
        }
    }, [])
    const params = useParams();
    const messageEdit = useRef();
    const formReset = useRef();
    const userFormSubmit = useRef();
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [pageForm, setPageForm] = useState({});
    const [isBlocked, setIsBlocked] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [checkResponseCountNow, setCheckResponseCountNow] = useState('false')
    // const [responseLimit, setResponseLimit] = useState(false)
    const responseLimitValue = useSelector(getResponseLimitData)
    const defaultMessage = 'Please fill in all the required fields';
    const [files, setFiles] = useState({})
    const [messagePreview, setMessagePreview] = useState('')
    // const form_id = params.formId;
    const string = params.string;
    const [startDate, setStartDate] = useState('')
    const [submitShow, setSubmitShow] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [showLogo, setShowLogo] = useState(false);
    const [formData, setFormData] = useState([
        {
            id: 1,
            title: '',
            color: 'default',
            opacity: '0.1',
            colors: [],
            rows: [
                {
                    id: 1,
                    content: [
                        {
                            cols: [
                                {
                                    id: 1,
                                    tabname: '',
                                    content: [],
                                }
                            ],
                        }
                    ]
                }
            ],
            button: false,
        }
    ]);
    const [paginate, setPaginate] = useState({
        page: 0,
        prev: false,
        next: false,
    })

    const [dataForm, setDataForm] = useState({
        id: form_id,
        email: ''
    })

    function showMessage(message) {
        try {
            messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
            messageEdit.current.classList.add('active')
            setTimeout(() => {
                if (!messageEdit.current != null) {
                    messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
                }
            }, 1000)
            setMessagePreview(message)
        } catch (error) {
            throw error
        }
    }

    function showMessageSuccess(message) {
        try {
            messageEdit.current.classList.remove('form-changes-error')
            messageEdit.current.classList.add('active', 'form-changes-success')
            setTimeout(() => {
                if (messageEdit.current != null) {
                    messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
                }
            }, 1000)
            setMessagePreview(message)
        } catch (error) {
            throw error
        }
    }

    function showMessageError(message) {
        try {
            messageEdit.current.classList.remove('form-changes-success')
            messageEdit.current.classList.add('active', 'form-changes-error')
            setTimeout(() => {
                if (messageEdit.current != null) {
                    messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
                }
            }, 5000)
            setMessagePreview(message)
        } catch (error) {
            throw error
        }
    }

    // useEffect(() => {
    // 	let data = {};
    // 	data.id = form_id;
    // 	data.userId = localStorage.getItem('authUser');
    // 	axios.post(API_BASE_URL + '/api/checkForm', data)
    // 		.then(response => { })
    // 		.catch(error => {
    // 			window.location.href = window.location.origin + '/'
    // 		})
    // }, [])

    const [checkAdd, setCheckAdd] = useState({});
    const [userErrorsAll, setUserErrorsAll] = useState([])
    const [emailError, setEmailError] = useState('')
    const [header, setHeader] = useState('');
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [isShowSubmit, setIsShowSubmit] = useState(false)
    useEffect(() => {
        dispatch(getResponseLimit(form_id))
    }, [])
    useEffect(() => {
        let data = {};
        data.id = form_id;
        data.string = string;
        data.tabname = 'UserForm';
        var formCopy = JSON.parse(JSON.stringify(formData))
        axios.post(API_BASE_URL + '/api/getForm', data)
            .then(response => {
                // if (response.data[0].length > 0) {
                setFormData(response.data[0])
                // setResponseLimit(response.data[2]);
                setLoading(false)
                if (response.data.length > 1) {
                    setPageForm(response.data[1])
                    // setHeader(response.data[1].header)
                }
                setShowLogo(response.data[2]);
                // } else {
                // 	window.location.href = window.location.origin + '/'
                // }
                if (!getCookie('_fsuf' + form_id)) {
                    setCookie('_fsuf' + form_id, moment(new Date()).format("DD/MM/YYYY H:mm:ss"), 365)
                    setStartDate(getCookie('_fsuf' + form_id))
                } else {
                    setStartDate(getCookie('_fsuf' + form_id))
                }
            })
            .catch(error => {
                if (error?.response?.data?.error === 'Expired') {
                    setIsExpired(true)
                } else if (error?.response?.data?.error === 'Blocked') {
                    setIsBlocked(true)
                } else {
                    window.location.href = window.location.origin + '/'
                }
                setLoading(false)
            })

    }, [])
    const types = ['divider', 'empty', 'image', 'paragraph'];
    useEffect(() => {
        setPaginate({...paginate, next: formData && formData.length == 1 ? false : true})
        loop: for (let i = 0; i < formData.length; i++) {
            const forJ = formData[i].rows;
            for (let j = 0; j < forJ.length; j++) {
                const forK = forJ[j].content[0].cols
                for (let k = 0; k < forK.length; k++) {
                    if (forK[k].tabname) {
                        if (!types.includes(forK[k].tabname)) {
                            setIsShowSubmit(true)
                            break loop;
                        }
                    }
                }
            }
        }
    }, [formData])
    const checkPrev = e => {
        e.preventDefault()
        var prevPage = e.currentTarget.getAttribute('prevpage')
        if (prevPage == 0) {
            setPaginate({...paginate, prev: false})
        } else {
            e.currentTarget.setAttribute('href', '#page_' + (+prevPage - 1))
            e.currentTarget.setAttribute('prevpage', +prevPage - 1)
            e.currentTarget.classList.remove('active')
        }
        setSubmitShow(false)
        setPaginate({...paginate, next: true, page: +prevPage})
    }

    const checkNext = e => {
        e.preventDefault()
        var nextPage = e.currentTarget.getAttribute('nextpage')
        if (nextPage == formData.length - 1) {
            setPaginate({...paginate, next: false})
            setSubmitShow(true)
        } else {
            e.currentTarget.setAttribute('href', '#page_' + (+nextPage + 1))
            e.currentTarget.setAttribute('nextpage', +nextPage + 1)
            e.currentTarget.classList.remove('active')
            setSubmitShow(false)
        }
        setPaginate({...paginate, prev: true, page: +nextPage})
        // if (e.currentTarget.getAttribute('page') == formData.length - 1) { setSubmitShow(true) }
        // else { setSubmitShow(false) }
    }

    const [userData, setUserData] = useState({});
    const applyForm = e => {
        try {
              async function getResponseNow (){
                let data = {};
                data.formLink = form_id;
                data.userPlan = responseLimitValue[1];
                const response = axios.post(API_BASE_URL + '/api/checkResponseCountNow', data)
                return response;
              } 

            async function goResponse () {
            const responseCount = await getResponseNow()
            setCheckResponseCountNow(responseCount.data);
            if(!responseCount.data){
                setLoadingForm(true)
                const copyFormData = JSON.parse(JSON.stringify(formData));
                const requiredFields = []
                var userErrors = []
    
                if (autoSubmit) {
                    userErrors = [];
                } else {
                    for (let i = 0; i < copyFormData.length; i++) {
                        const forJ = copyFormData[i].rows;
                        for (let j = 0; j < forJ.length; j++) {
                            const forK = forJ[j].content[0].cols
                            for (let k = 0; k < forK.length; k++) {
                                if (forK[k].tabname != 'image') {
                                    if (forK[k].content.required && forK[k].content.required == true) {
                                        requiredFields.push({'uniqueId': forK[k].uniqueId, 'label': forK[k].content.label})
                                    }
                                }
                            }
                        }
                    }
                    if (requiredFields.length > 0) {
                        if (Object.keys(userData).length == 0) {
                            requiredFields.map(e => {
                                userErrors.push(e)
                            })
                            setUserErrorsAll(userErrors)
                        } else {
                            userErrors = []
                            Object.keys(userData).map(e => {
                                if (userData[e] == '' || userData[e].value == '') {
                                    delete userData[e]
                                }
                            })
                            for (let i = 0; i < requiredFields.length; i++) {
                                if (!userData.hasOwnProperty(requiredFields[i].uniqueId)) {
                                    userErrors.push(requiredFields[i])
                                }
                            }
                            setUserErrorsAll(userErrors)
                        }
                        if (userErrors.length > 0) {
                            setLoadingForm(false)
                            showMessageError("Please complete all required fields.")
                        }
                    }
                }
                if (userErrors.length == 0) {
                    var startDate = getCookie('_fsuf' + form_id);
                    const userFormData = new FormData();
                    userFormData.append('dataForm', JSON.stringify(dataForm))
                    userFormData.append('userData', JSON.stringify(userData));
                    userFormData.append('startDate', startDate);
                    if ((Object).keys(files) && (Object).keys(files).length) {
                        userFormData.append("hasFiles", true);
                        (Object).keys(files).map(key => {
                            return files[key].forEach((file, index) => {
                                if (file.valid) {
                                    userFormData.append("userFormDataFiles/" + key + '/' + index, file.file);
                                }
                            });
                        })
                    }
                    let allInputValue = [];
                    let answerUniqueId;
                    for (let i = 0; i < copyFormData.length; i++) {
                        const forJ = copyFormData[i].rows;
                        for (let j = 0; j < forJ.length; j++) {
                            const forK = forJ[j].content[0].cols
                            let k = forJ[j].content[0].cols;
                            if (k[0].tabname === 'input') {
                                answerUniqueId = k[0].uniqueId
                                Object.keys(userData).map(e => {
                                    if (e == answerUniqueId) {
                                        if (userData[e]?.value?.length > 199) {
                                            allInputValue.push(userData[e])
                                        }
                                    }
                                })
                            }
                        }
    
                    }
                    userFormData.append('shortText', JSON.stringify(allInputValue))
    
                    axios.post(API_BASE_URL + '/api/saveUserForm', userFormData)
                        .then(response => {
                            eraseCookie('_fsuf' + form_id)
                            setLoadingForm(false)
                            userErrors = []
                            setEmailError('')
                            setUserErrorsAll([])
                            formReset.current.reset()
                            setUserData({})
                            setCookie('_sfnu' + form_id, '_an45xzcasd1213>g8,|' + form_id, 365)
                            localStorage.setItem('success', form_id)
                            if (response.data.success_status === "url" && typeof response.data.redirect_url == 'string') {
                                window.location.href = response.data.redirect_url
                            } else {
                                window.location.href = window.location.origin + '/success'
                            }
                        })
                        .catch(error => {
                            if (error?.response?.data?.error?.value) {
                                showMessageError(error.response.data.error.value)
                            }
                            setLoadingForm(false)
                            if (error?.response?.data?.error?.email) {
                                setEmailError(error.response.data.error.email)
                                showMessageError(error.response.data.error.email)
                            }
                        })
                }
            }
           }  
            
            if(userData.selectOllDateValues == false){
                showMessageError('Please fill in the date field completely.')
            }else{
                goResponse (); 
            }
            
        } catch (error) {
            console.log(error);
            showMessageError('Something went wrong.')
        }

    }
    const reportForm = e => {
        axios.post(API_BASE_URL + '/api/reportForm', {form_id})
            .then(response => {
                localStorage.setItem('report', form_id)
                window.location.href = window.location.origin + '/success-report'
            })
            .catch(error => {
            })
    }
    return (
        <>
            {
                isBlocked || responseLimitValue[0] 
                    ?
                    <div
                        className="success success-page rejected preview px-3 px-xxl-5 py-3 py-lg-4 mx-auto userform-preview-container">
                        <div className="card">
                            <div className="success-body">
                                <svg className="declinemark" xmlns="http://www.w3.org/2000/svg" viewBox="0 -72 322 652">
                                    <circle className="declinemark__circle" cx="26" cy="26" r="25" fill="none"/>
                                    <path className="declinemark__check"
                                          d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                                    {/* <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /> */}
                                </svg>
                            </div>
                            <h1>Oops!</h1>
                            <div className="success-edit disactive">
                                <div className='success-edit-text'>It looks like this form doesn't exist any more.</div>
                                <a href="/" className="payment-link">Go back</a>
                            </div>
                        </div>
                    </div>
                    : isExpired || checkResponseCountNow === 'true'
                        ? <div
                            className="success success-page rejected preview px-3 px-xxl-5 py-3 py-lg-4 mx-auto userform-preview-container">
                            <div className="card">
                                <div className="success-body">
                                    <svg className="declinemark" xmlns="http://www.w3.org/2000/svg" viewBox="0 -72 322 652">
                                        <circle className="declinemark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path className="declinemark__check"
                                              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                                        {/* <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /> */}
                                    </svg>
                                </div>
                                <h1>Expired!</h1>
                                <div className="success-edit disactive">
                                    <div className='success-edit-text'>It looks like this form no longer accepting
                                        responses.
                                    </div>
                                    {/* <p className="success-edit-span">Expiration Date: {pageForm.deadline}</p> */}
                                    <a href="/" className="payment-link">Go back</a>
                                </div>
                            </div>
                        </div>
                        :
                        <div id="formPage" className="preview px-3 px-xxl-5 py-3 py-lg-4 mx-auto userform-preview-container"
                            // style={{ backgroundColor: `rgba(${formData[0].colors[formData[0].color]},${formData[0].opacity})` }}>
                             style={{
                                 backgroundColor: pageForm && pageForm?.colors
                                     ? `rgba(${pageForm.colors[pageForm.color]},${pageForm.opacity})`
                                     : `rgba(${formData[0]?.colors[formData[0].color]},${formData[0]?.opacity})`
                             }}
                        >
                            <div className="form-changes form-changes-error" ref={messageEdit}>
                                <div className='img-div'>
                                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
                                            stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                        <path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className='succes-div'>
                                    <p className='paragraph-'>{messagePreview}</p>
                                </div>


                            </div>
                            <div className="preview-container userform-container col-12 col-xl-8 col-xxl-10 mt-4">
                                <div className="formLoader">
                                    {
                                        loadingForm
                                            ?
                                            <div className="formLoader-loader">
                                                <Loader/>
                                            </div>
                                            : null
                                    }
                                    {
                                        loading
                                            ?
                                            <div className='w-100 d-flex justify-content-center'>
                                                <Loader/>
                                            </div>
                                            :
                                            <form tabname="Form" className="form-pages tab-content" ref={formReset}
                                                  onSubmit={e => e.preventDefault()}>
                                                {
                                                    pageForm?.countdown && pageForm?.countdown !== '00:00:00'
                                                        ? <Countdown countdown={pageForm.countdown} formData={formData}
                                                                     color={pageForm && pageForm?.colors
                                                                         ? `rgba(${pageForm.colors[pageForm.color]},${pageForm.opacity})`
                                                                         : `rgba(${formData[0]?.colors[formData[0].color]},${formData[0]?.opacity})`}
                                                                     startDate={getCookie('_fsuf' + form_id)}
                                                                     userFormSubmit={userFormSubmit} autoSubmit={autoSubmit}
                                                                     setAutoSubmit={setAutoSubmit}/>
                                                        : null
                                                }
                                                {formData.map((form, key) => {
                                                    return <div
                                                        className={key == 0 ? "tab-pane fade show active form-page" : "tab-pane fade form-page"}
                                                        key={key} id={"page_" + key}>
                                                        {
                                                            formData.length > 1 && key != 0
                                                                ?
                                                                <div className="page-number page-number-preview">
                                                                    <button type='button'
                                                                            className='page-number-button'>Page {key + 1}</button>
                                                                </div>
                                                                : null
                                                        }
                                                        <div
                                                            className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3  form-body">
                                                            <div
                                                                className="d-flex justify-content-between align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200 form_input-header form-input countdown-header">
                                                                <h2 className="my-3 user-evented">
                                                                    {
                                                                        form.title
                                                                            ? pageForm.header
                                                                            : ''
                                                                    }
                                                                </h2>

                                                            </div>
                                                            <div className='form-content '>
                                                                <div className="form-content-body">
                                                                    {
                                                                        form.rows.map((bform, bkey) => {
                                                                            return <Row
                                                                                checkAdd={checkAdd}
                                                                                setCheckAdd={setCheckAdd}
                                                                                page={key}
                                                                                row={bkey}
                                                                                key={bkey}
                                                                                contentRow={bform}
                                                                                formData={formData}
                                                                                setFormData={setFormData}
                                                                                preview={true}
                                                                                userData={userData}
                                                                                setUserData={setUserData}
                                                                                userErrorsAll={userErrorsAll}
                                                                                setUserErrorsAll={setUserErrorsAll}
                                                                                setDataForm={setDataForm}
                                                                                dataForm={dataForm}
                                                                                emailError={emailError}
                                                                                setEmailError={setEmailError}
                                                                                files={files}
                                                                                setFiles={setFiles}
                                                                            />
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                                <div className="text-center preview-form-pages">
                                                    {
                                                        formData.length > 1
                                                            ?
                                                            <ul className="nav nav-segment nav-pills page" role="tablist">
                                                                {/* <li className="page__numbers">
														<button type='button' className="page-button page-button-arrow" onClick={prevPage}><span>«</span></button>
													</li> */}
                                                                {
                                                                    paginate.prev && paginate.page != 0
                                                                        ?
                                                                        <li className="page__numbers">
                                                                            <a className="page-button page-button-action"
                                                                               prevpage={paginate.page - 1}
                                                                               data-bs-toggle="pill" onClick={checkPrev}
                                                                               href={"#page_" + (+paginate.page - 1)}
                                                                               role="tab">Prev</a>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    paginate.next && paginate.page != formData.length - 1
                                                                        ?
                                                                        <li className="page__numbers">
                                                                            <a className="page-button page-button-action"
                                                                               nextpage={paginate.page + 1}
                                                                               data-bs-toggle="pill" onClick={checkNext}
                                                                               href={"#page_" + (+paginate.page + 1)}
                                                                               role="tab">Next</a>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {/* {
														formData.map((form, key) => {
															return key == 0
															? <li className="page__numbers" key={key}>
																<a className="page-button" page={key} data-bs-toggle="pill" onClick={checkSubmit} href={"#page_" + key} role="tab">Next</a>
															</li>
															: (
																key > 0 && key != formData.length - 1
																? <div key={key} className="d-flex">

																</div>
																: (
																	key == formData.length - 1
																	? <li className="page__numbers">
																		<a className="page-button" page={key - 1} data-bs-toggle="pill" onClick={checkSubmit} href={"#page_" + key - 1} role="tab">Prev</a>
																	</li>
																	: null
																)
															)
															// return <li className="nav-item page-buttons" key={key}>
															// 	<a className={key == 0 ? "page-link active page-button" : "page-link page-button"} page={key} data-bs-toggle="pill" href={"#page_" + key} role="tab" aria-selected="true">{key + 1}</a>
															// </li>
														})
													} */}
                                                                {/* <li className="page__numbers">
														<button type='button' className="page-button page-button-arrow" onClick={nextPage}><span>»</span></button>
													</li> */}
                                                            </ul>
                                                            : <div></div>
                                                    }
                                                    {isShowSubmit ?
                                                        submitShow || formData.length == 1
                                                            ? <button className='btn btn-lg btn-dark px-md-4'
                                                                      ref={userFormSubmit} type="button"
                                                                      onClick={(e) => applyForm()}>Submit</button>
                                                            : null
                                                        : null
                                                    }
                                                </div>
                                            </form>
                                    }
                                </div>
                            </div>
                        </div>
            }
            <div className='powered-formFlex'
                 style={{
                     backgroundColor: pageForm && pageForm?.colors
                         ? `rgba(${pageForm.colors[pageForm.color]},${pageForm.opacity})`
                         : `rgba(${formData[0]?.colors[formData[0].color]},${formData[0]?.opacity})`
                 }}
                // style={{ backgroundColor: `rgba(${formData[0]?.colors[formData[0].color]},${formData[0]?.opacity})` }}
            >
                <div className="formLoader">
                    {
                        loading
                            ?
                            <div className='w-100 d-flex justify-content-center'>
                                <Loader/>
                            </div>
                            :  <div style={{textAlign: 'center'}}>
                                {isExpired || isBlocked ? <div></div> :
                                    <div>
                                        <p style={{fontSize: '13px', marginBottom: '0rem',}}>Never submit passwords or credit card
                                            details. </p>

                                        <button className='btn my-1 report-form'
                                                type="button"
                                                onClick={(e) => reportForm()}>Report this form
                                        </button>
                                    </div>}
                                {showLogo === false ?
                                    <div className="powered-form">
                                        <p>Powered by </p>
                                        <div className="powered-form-image">
                                            <img src="/logo-final.png" alt=""/>
                                        </div>
                                    </div> : null
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default UserForm;