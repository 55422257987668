import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {gsap, Quart} from 'gsap'
import { dhm } from '../../../help';
const Countdown = (props) => {

    const { countdown, startDate, userFormSubmit, autoSubmit, color, setAutoSubmit } = props;

    var totalSeconds = 0;

    const [day, setDay] = useState('00')
    const [hour, setHour] = useState('00')
    const [minute, setMinute] = useState('00')
    const [second, setSecond] = useState('00')
    const countdownBody = useRef(null)
    const element = useRef(null)
    const dayElem = useRef(null)
    const hourElem = useRef(null)
    const minuteElem = useRef(null)
    const secondElem = useRef(null)

    useEffect(() => {
        if (countdown) {
            var splitted = countdown.split(':')
            var day = splitted[0];
            var hour = splitted[1];
            var minute = splitted[2];
            var second = '00';
            if (startDate) {
                totalSeconds = day * 86400 + hour * 60 * 60 + minute * 60;
                var now = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
                var started = startDate
                var diff = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(started,"DD/MM/YYYY HH:mm:ss")); 
                var d = moment.duration(diff);
                var s = moment.duration(diff).as('milliseconds') / 1000
                // console.log(dd, s, totalSeconds);
                // var startTime = moment(started).unix()
                // var nowTime = moment(now).format('x')
                // var diff = (nowTime - startTime) / 1000
                // console.log(nowTime, startTime);
                if (s > totalSeconds) {
                    setAutoSubmit(true);
                    userFormSubmit.current.click()
                    day = '00';
                    hour = '00';
                    minute = '00';
                    minute = '00';
                } else {
                    var nowTimer = totalSeconds - s;
                    var momentedNowTimer = dhm(nowTimer * 1000)
                    var splittedDiff = momentedNowTimer.split(':')
                    day = splittedDiff[0];
                    hour = splittedDiff[1];
                    minute = splittedDiff[2];
                    second = splittedDiff[3];
                }
            }
            setDay(day)
            setHour(hour)
            setMinute(minute)
            setSecond(second)
            
            // console.log(startDate);
            init(day, hour, minute, second)
        }
    }, [countdown])

    // Create Countdown
    var countdown_interval = null;

    const init = (day, hour, minute, second) => {

        var elems = {
            days: dayElem.current.querySelectorAll(".bloc-time.days .figure"),
            hours: hourElem.current.querySelectorAll(".bloc-time.hours .figure"),
            minutes: minuteElem.current.querySelectorAll(".bloc-time.min .figure"),
            seconds: secondElem.current.querySelectorAll(".bloc-time.sec .figure")
        };

        var values = {
            days: +day,
            hours: +hour,
            minutes: +minute,
            seconds: +second,
        };
        if (second === 0 && minute === '00' && hour === '00' && day === '00') {
            totalSeconds = 0;
        } else {
            totalSeconds = 
                values.days * 86400 +
                values.hours * 60 * 60 +
                values.minutes * 60 +
                values.seconds;
        }
        values.total_seconds = totalSeconds
        // Animate countdown to the end
        count(values,elems);
    }

    const count = (value, elem) => {
        var that = value,
            day_1 = elem.days[0],
            day_2 = elem.days[1],
            hour_1 = elem.hours[0],
            hour_2 = elem.hours[1],
            min_1 = elem.minutes[0],
            min_2 = elem.minutes[1],
            sec_1 = elem.seconds[0],
            sec_2 = elem.seconds[1];
        countdown_interval = setInterval(function () {
            if (totalSeconds > 0) {
                --that.seconds;

                if (that.minutes >= 0 && that.seconds < 0) {
                    that.seconds = 59;
                    --that.minutes;
                }

                if (that.hours >= 0 && that.minutes < 0) {
                    that.minutes = 59;
                    --that.hours;
                }

                if (that.days > 0 && that.hours < 0) {
                    that.hours = 23;
                    --that.days;
                }

                // Update DOM values
                // Days
                checkHour(that.days, day_1, day_2);
                
                // Hours
                checkHour(that.hours, hour_1, hour_2);

                // Minutes
                checkHour(that.minutes, min_1, min_2);

                // Seconds
                checkHour(that.seconds, sec_1, sec_2);

                --that.total_seconds;

                if (that.total_seconds == 0) {
                    setAutoSubmit(true);
                    userFormSubmit.current.click()
                    setDay('00')
                    setHour('00')
                    setMinute('00')
                    setSecond('00')
                    clearInterval(countdown_interval);
                }
            } else {
                clearInterval(countdown_interval);
            }
        }, 1000);
    }
    const animateFigure = (element, value) => {
        var that = this,
            top = element.querySelector('.top'),
            bottom = element.querySelector('.bottom'),
            back_top = element.querySelector('.top-back'),
            back_bottom = element.querySelector('.bottom-back');

        // Before we begin, change the back value
        back_top.querySelector('span').innerText = value;

        // Also change the back bottom value
        back_bottom.querySelector('span').innerText = value;

        // Then animate
        gsap.to(top, 0, {
            rotationX: "-180deg",
            transformPerspective: 300,
            ease: Quart.easeOut,
            onComplete: function () {
                top.innerText = value;

                bottom.innerText = value;

                gsap.set(top, { rotationX: 0 });
            }
        });

        gsap.to(back_top, 0, {
            rotationX: 0,
            transformPerspective: 300,
            ease: Quart.easeOut,
            clearProps: "all"
        });
    }
    const checkHour = (value, el_1, el_2) => {
        var val_1 = value.toString().charAt(0),
            val_2 = value.toString().charAt(1),
            fig_1_value = el_1.querySelector('.top').innerText,
            fig_2_value = el_2.querySelector('.top').innerText;
        
        if (value >= 10) {
            // Animate only if the figure has changed
            if (fig_1_value !== val_1) animateFigure(el_1, val_1);
            if (fig_2_value !== val_2) animateFigure(el_2, val_2);
        } else {
            // If we are under 10, replace first figure with 0
            if (fig_1_value !== "0") animateFigure(el_1, 0);
            if (fig_2_value !== val_1) animateFigure(el_2, val_1);
        }
    }

    useEffect(() => {
		if (document.documentElement) {
			window.addEventListener('scroll', function (e) {
                var scrollYpos = document.documentElement.scrollTop;
                if (scrollYpos > 53) { 
                    countdownBody.current.classList.add('_active')
                    countdownBody.current.style.cssText = `
                    background-color: ${color};`;
                } else { 
                    countdownBody.current.classList.remove('_active')
                    countdownBody.current.style.cssText = `
                    background-color: `;
                }
            })
		}
	}, [])

    return (
        <div className="wrap countdown-body" ref={countdownBody} >
            <div className="countdown" ref={element}>
                <div className="bloc-time days" data-init-value={0} ref={dayElem}>
                    <span className="count-title">Days</span>
                    <div className="figure hours hours-1">
                        <span className="top">{day.split('')[0]}</span>
                        <span className="top-back">
                            <span>{day.split('')[0]}</span>
                        </span>
                        <span className="bottom">{day.split('')[0]}</span>
                        <span className="bottom-back">
                            <span>{day.split('')[0]}</span>
                        </span>
                    </div>
                    <div className="figure hours hours-2">
                        <span className="top">{day.split('')[1]}</span>
                        <span className="top-back">
                            <span>{day.split('')[1]}</span>
                        </span>
                        <span className="bottom">{day.split('')[1]}</span>
                        <span className="bottom-back">
                            <span>{day.split('')[1]}</span>
                        </span>
                    </div>
                </div>
                <div className="bloc-time hours" data-init-value={24} ref={hourElem}>
                    <span className="count-title">Hours</span>
                    <div className="figure hours hours-1">
                        <span className="top">{hour.split('')[0]}</span>
                        <span className="top-back">
                            <span>{hour.split('')[0]}</span>
                        </span>
                        <span className="bottom">{hour.split('')[0]}</span>
                        <span className="bottom-back">
                            <span>{hour.split('')[0]}</span>
                        </span>
                    </div>
                    <div className="figure hours hours-2">
                        <span className="top">{hour.split('')[1]}</span>
                        <span className="top-back">
                            <span>{hour.split('')[1]}</span>
                        </span>
                        <span className="bottom">{hour.split('')[1]}</span>
                        <span className="bottom-back">
                            <span>{hour.split('')[1]}</span>
                        </span>
                    </div>
                </div>
                <div className="bloc-time min" data-init-value={0} ref={minuteElem}>
                    <span className="count-title">Minutes</span>
                    <div className="figure min min-1">
                        <span className="top">{minute.split('')[0]}</span>
                        <span className="top-back">
                            <span>{minute.split('')[0]}</span>
                        </span>
                        <span className="bottom">{minute.split('')[0]}</span>
                        <span className="bottom-back">
                            <span>{minute.split('')[0]}</span>
                        </span>
                    </div>
                    <div className="figure min min-2">
                        <span className="top">{minute.split('')[1]}</span>
                        <span className="top-back">
                            <span>{minute.split('')[1]}</span>
                        </span>
                        <span className="bottom">{minute.split('')[1]}</span>
                        <span className="bottom-back">
                            <span>{minute.split('')[1]}</span>
                        </span>
                    </div>
                </div>
                <div className="bloc-time sec" data-init-value={0} ref={secondElem}>
                    <span className="count-title">Seconds</span>
                    <div className="figure sec sec-1">
                        <span className="top">{second.split('')[0]}</span>
                        <span className="top-back">
                            <span>{second.split('')[0]}</span>
                        </span>
                        <span className="bottom">{second.split('')[0]}</span>
                        <span className="bottom-back">
                            <span>{second.split('')[0]}</span>
                        </span>
                    </div>
                    <div className="figure sec sec-2">
                        <span className="top">{second.split('')[1]}</span>
                        <span className="top-back">
                            <span>{second.split('')[1]}</span>
                        </span>
                        <span className="bottom">{second.split('')[1]}</span>
                        <span className="bottom-back">
                            <span>{second.split('')[1]}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countdown;