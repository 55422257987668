import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import MyButton from '../../../UI/button/MyButton';
import MyInput from '../../../UI/Input/MyInput';
import AdminForm from './AdminForm';

const AdminAdd = (props) => {
	const { modalAdd, setModalAdd, tableAdd, editData, deleteData, setInfoSendData, dataAll, setDataAll, setUserDate, setDateRange } = props;
	const [errors, setErrors] = useState([]);
	const [sendData, setSendData] = useState({});
	const [files, setFiles] = useState([]);
	const userForm = useRef();

	const closeModal = () => {
		setModalAdd(false);
		setErrors([])
		setSendData({})
		userForm.current.reset()
		// $('.home__form-content form').trigger("reset")
	}
	return (
		<div className="add-modal__content">
			<AdminForm
				setDateRange={setDateRange}
				setUserDate={setUserDate}
				dataAll={dataAll}
				setDataAll={setDataAll}
				table={tableAdd}
				setModalAdd={setModalAdd}
				errors={errors}
				setErrors={setErrors}
				files={files}
				setFiles={setFiles}
				sendData={sendData}
				setSendData={setSendData}
				editData={editData}
				deleteData={deleteData}
				closeModal={closeModal}
				userForm={userForm}
			/>
			<MyButton onClick={() => closeModal()} style={{ marginTop: '10px', width: '100%' }}>Cancel</MyButton>
		</div >
	);
};

export default AdminAdd;