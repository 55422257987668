import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import FormData from 'form-data';
import { API_BASE_URL, APP_BASE_URL, AxiosConfigs } from "../config";
import Loader from '../UI/Loader/Loader';
import AddMembers from "./AddMembers";
import { useDispatch, useSelector } from "react-redux";
import { getMembers,getInfoData, getMembersConfirm, getMembersConfirmCount, getMembersConfirmCountData, getMembersData, getMembersDataLoading, getMyInfoNowData } from "../redux/reducers/dataReducer";
import Pagination from 'react-laravel-paginex/dist/Pagination';
import MemberAssort from "./MemberAssort";
import moment from "moment";



const Members = () => {
    document.title = "FormBuilder - Members";
	const myInfo = useSelector(getInfoData)
    const [getMyDataNow, setGetMyDataNow] = useState({})
    const [membersCheckedValue, setMembersCheckedValue] = useState(false)
    const [membersData, setMembersData] = useState({});
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('')
    const pdfIframe = useRef()
    const members = useSelector(getMembersData);
    const membersConfirmCount = useSelector(getMembersConfirmCountData);
    const [editedMessage, setEditedMessage] = useState('')
    const myDataNow = useSelector(getMyInfoNowData)
    const membersLoading = useSelector(getMembersDataLoading)
    const messageEdit = useRef(null)

    useEffect(() => {
        axios.post(API_BASE_URL + '/api/getMe', null, AxiosConfigs)
            .then(response => {
                setGetMyDataNow(response.data);
            })
            .catch(error => {
                // console.log(error);
            })
    }, []);

    useEffect(() => {
        dispatch(getMembers({ page: 1 }))
        dispatch(getMembersConfirmCount())
    }, [])

    useEffect(() => {
        setMembersData(members)
    }, [members])

    function showMessagePdf(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active','form-changes-success')
			setEditedMessage(message)
		} catch (error) { throw error }
	}

    const addActive = e => {
        e.preventDefault()
        const toggledButtons = document.querySelectorAll('.animation-div')
        var bool = false;
        if (e.currentTarget.nextSibling.classList.contains('_active')) { bool = true; }
        // toggledButtons.forEach(element => element.classList.remove('_active'));
        if (bool) { e.currentTarget.nextSibling.classList.remove('_active') }
        else { e.currentTarget.nextSibling.classList.add('_active') }
    }
    document.documentElement.addEventListener("click", function (e) {
		try {
			if (!e.target.closest('.animation-div') && !e.target.closest('.myForms-content-right button')) {
				const toggledButtons = document.querySelectorAll('.animation-div')
				toggledButtons.forEach(element => element.classList.remove('_active'));
			}
		} catch (error) { throw error }
	})
    const deleteMember = (e, member) => {
        e.preventDefault()
        let data = {};
        data.memberId = member._id;
        data.confirm = membersCheckedValue;
        data.userId = getMyDataNow?._id;
        data.email = member.email;
        let pageNow = membersData.current_page;
        if (membersData.data.length - 1 == 0) {
            pageNow = pageNow - 1
        }
        axios.post(API_BASE_URL + '/api/deleteMember?page=' + pageNow, data)
            .then(response => {
                if(member.status === 'confirm'){
                    dispatch(getMembersConfirmCount())
                }
                if(response.data.total === 0){
                    dispatch(getMembers({ page: 1 }))
                    setMembersCheckedValue(false)
                } else {
                setMembersData(response.data) }
            })
            .catch(error => {})
    }

    const downloadList = (e) => {
        e.preventDefault()
        showMessagePdf("The file are downloading, please wait.")
        var data = {};
        data.id = getMyDataNow?._id;
        pdfIframe.current.src = `${API_BASE_URL}/api/generateMemberListPdf/${getMyDataNow?._id}`
        setTimeout(() => {
            if (!messageEdit.current != null) {
                messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
            }
        }, 1000);
    }

    const getData = (data) => {
        if (membersData.current_page !== data.page) {
            data.membersCheckedValue = membersCheckedValue;
            if (!membersCheckedValue) {
                axios.post(API_BASE_URL + '/api/getMembers?page=' + data.page, data, AxiosConfigs)
                    .then(response => {
                        setMembersData(response.data)
                    })
                    .catch(error => { })
            } else {
                axios.post(API_BASE_URL + '/api/getMembersConfirm?page=' + data.page, data, AxiosConfigs)
                    .then(response => {
                        setMembersData(response.data)
                    })
                    .catch(error => { })
            }
        }
    }
    return (
        <div className="member-page-style">
            {!membersLoading  ?
                <div className="row w-100 flex-direction-column">
                    <div className="col-12">
                        <div className="card rounded-12 shadow-dark-80 border my-4">
                            <div className="card-body px-0 pb-0">
                                <div className="d-flex align-items-center border-bottom border-gray-200 pb-3 px-3 px-md-4 member-phresp">
                                    <div><h5 className="card-header-title mb-0 font-weight-semibold ps-md-2">Team Members</h5></div>
                                    <div className="ms-auto pe-md-2 dropdown member-function">
                                        <div className="d-flex align-items-center">
                                            {membersConfirmCount > 0?
                                                <MemberAssort
                                                    membersCheckedValue={membersCheckedValue}
                                                    setMembersCheckedValue={setMembersCheckedValue}
                                                    membersData={membersData}
                                                    setMembersData={setMembersData}
                                                />
                                            :null}
                                            <div className="d-flex">
                                                <AddMembers
                                                    membersCheckedValue={membersCheckedValue}
                                                    setMembersCheckedValue={setMembersCheckedValue}
                                                />
                                                <div>
                                                <div className="form-changes" ref={messageEdit} >
                                                    <div className='img-div'>
                                                        <svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                            <path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
                                                                stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>	
                                                    </div>
                                                    <div className='succes-div'>
                                                        <p className='paragraph'>{editedMessage}</p>
                                                    </div>
                                                </div>
                                                    <iframe src="" ref={pdfIframe} id="iFrame" seamless width="200" height="200" frameBorder="0"
                                                        onLoad={e => setIframeSrc(e.target.src)}
                                                        style={{ display: 'none' }} />
                                                    {membersData?.total > 0?
                                                        <div>
                                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-dark-100 btn-icon btn-sm rounded-circle">
                                                                <svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
                                                                    <rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
                                                                    <path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6c757d" />
                                                                </svg>
                                                            </a>
                                                            <ul className="dropdown-menu">
                                                                <li className="dropdown-sub-title">
                                                                    <span>EXPORT AS</span>
                                                                </li>
                                                                <li>
                                                                    <button type="button" className="dropdown-item" onMouseDown={(e) => downloadList(e)} >
                                                                        <svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
                                                                            <rect data-name="Icons/Tabler/File background" width={16} height={16} fill="none" />
                                                                            <path data-name="Combined Shape" d="M2.256,16A2.259,2.259,0,0,1,0,13.744V2.256A2.259,2.259,0,0,1,2.256,0H8a.613.613,0,0,1,.4.148l0,0L8.41.157l0,0,.005.005L8.425.17l0,0L8.435.18l4.1,4.1a.614.614,0,0,1,.185.44v9.026A2.259,2.259,0,0,1,10.462,16ZM1.231,2.256V13.744a1.026,1.026,0,0,0,1.025,1.025h8.205a1.027,1.027,0,0,0,1.026-1.025V5.333H8.821A1.436,1.436,0,0,1,7.387,3.979l0-.082V1.231H2.256A1.026,1.026,0,0,0,1.231,2.256ZM8.616,3.9a.206.206,0,0,0,.168.2l.037,0h1.8l-2-2ZM3.9,12.718a.615.615,0,0,1-.059-1.228l.059,0H8.821a.615.615,0,0,1,.059,1.228l-.059,0Zm0-3.282a.615.615,0,0,1-.059-1.228l.059,0H8.821a.615.615,0,0,1,.059,1.228l-.059,0Zm0-3.281a.616.616,0,0,1-.059-1.228l.059,0h.821a.615.615,0,0,1,.059,1.228l-.059,0Z" transform="translate(2)" fill="#495057" />
                                                                        </svg><span className="ms-2">PDF</span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    :null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {membersData?.total > 0?
                            <div className="col-xl-6 form-content">
                                <div className="card rounded-12 shadow-dark-80 border mb-4 border-gray-200 h-100 px-4 py-0">
                                    <div className="formsTable">
                                        <div className="table-responsive">
                                            <table className="table card-table table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-sm-9 col-9">Members</th>
                                                        <th scope="col">Sent</th>
                                                        <th scope="col">Joined</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="formsTable">
                                                            <td className='d-flex align-items-center'>
                                                                <div className="avatar avatar-circle avatar-border d-flex justify-content-center align-items-center" style={{backgroundColor:myInfo.color}}>
                                                                    <p className="avatar-letter avatar-letter-small member-bg-name-style member-text-color">{myInfo.firstname.split('')[0]}</p>
                                                                </div>
                                                                <div className="left-myForms-content">
                                                                    <div className="left-myForms-info-top">
                                                                        <div className="d-flex form-link-left align-items-center">
                                                                            <div className='user-select-text mx-2 mb-1 font-weight-semibold' format="text">{myInfo.firstname}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='left-myForms-info-bottom'>
                                                                        <div className="d-flex form-link-left">
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="formsTable-td"></td>
                                                            <td className="formsTable-td">
                                                               
                                                            </td>
                                                        </tr>
                                                    {membersData?.data?.map((member, indx) => (
                                                        <tr key={indx} className="formsTable">
                                                            <td className='d-flex align-items-center'>
                                                                <div className="avatar avatar-circle avatar-border d-flex justify-content-center align-items-center"style={{backgroundColor : member?.memberColor}}>
                                                                    <p className="avatar-letter avatar-letter-small member-bg-name-style member-text-color">{member.email.split('')[0]}</p>
                                                                </div>
                                                                <div className="left-myForms-content">
                                                                    <div className="left-myForms-info-top">
                                                                        <div className="d-flex form-link-left align-items-center">
                                                                            <div className='user-select-text mx-2 mb-1 font-weight-semibold' format="text">{member.email}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='left-myForms-info-bottom'>
                                                                        <div className="d-flex form-link-left">
                                                                            {
                                                                                member.status === 'confirm' ?
                                                                                    <p className="card-text small text-gray-600 lh-sm d-flex mb-0 text-success">
                                                                                        {member.status}
                                                                                    </p> :
                                                                                    <p className="card-text small text-gray-600 lh-sm d-flex mb-0">
                                                                                        {member.status}
                                                                                    </p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="formsTable-td">{moment(member.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td className="formsTable-td">
                                                                {moment(member.updated_at).diff(member.created_at) ? moment(member.updated_at).format('YYYY-MM-DD HH:mm:ss') : '-'}
                                                            </td>
                                                            <td className="members-actions">
                                                                <div className="myForms-content-right right-myForms right-td-styles">
                                                                    <div className="right-myForms-buttons dropdown">
                                                                        <button onClick={e => addActive(e)} className="btn btn-dark-100 btn-icon btn-sm rounded-circle right-myForms-buttons-dropdown" >
                                                                            <svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
                                                                                <rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
                                                                                <path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6C757D" />
                                                                            </svg>
                                                                        </button>
                                                                        <div className="animation-div">
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='form-link-buttons button-statistics'>
                                                                                    <div className='form-link-button-body'>
                                                                                        <div className="form-link-button-title-bottom form-link-button-title-hovered">Delete</div>
                                                                                        <button
                                                                                            onClick={e => deleteMember(e, member)}
                                                                                            className={
                                                                                                'btn form-link-button new-response'}><span><i className="fas fa-trash"></i></span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :null}
                    </div>
                </div>:
            <div className="form-loader">
                 <Loader />
            </div>}
            {!membersLoading ?
                membersData.data?.length > 0
                    ?
                    membersData.total > 1
                        ?
                        <div className="d-flex align-items-center p-3 p-md-4 responses-table">
                            <div className="mx-auto pagination">
                                <Pagination
                                    changePage={getData}
                                    data={membersData}
                                    buttonIcons={true}
                                />
                            </div>
                        </div>
                        : null
                    : <p className="p-4">No Data</p> : <div></div>
            }
       </div>
    )
}
export default Members;
