import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminFormsData } from '../../../../redux/reducers/adminReducer';
import { getForms, getFormsData, getFormsDataLoading } from '../../../../redux/reducers/dataReducer';
import Loader from '../../../../UI/Loader/Loader';

const StatForms = () => {
	const forms = useSelector(getFormsData)
	// const formsData = useSelector(getAdminFormsData)
	const loadingForms = useSelector(getFormsDataLoading)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getForms())
	}, [])
	return (
		<div className="col-12 col-lg-6 top-chart">
			<div className="card card-stats mb-4 mb-xl-0">
				<div className="card-body">
					<div className="row">
						<div className="col">
							<h5 className="card-title text-uppercase text-muted mb-0">Forms</h5>
							{
								loadingForms
									?
									<Loader />
									:
									<span className="h3 font-weight-bold mb-0">{forms.count}</span>
							}
						</div>
						<div className="col-auto">
							<div className="icon icon-shape bg-success chart-icon text-white rounded-circle shadow ">
								<i className="fas fa-align-left"></i>
								{/* <img className="imgInProfileDoc" src="/svg/icons/page.svg" alt="img" /> */}
							</div>
						</div>
					</div>
					{/* <p className="mt-3 mb-0 text-muted text-sm">
						<span className="text-danger mr-2"><i className="fas fa-arrow-down" /> 3.48%</span>
						<span className="text-nowrap"> Since last week</span>
					</p> */}
				</div>
			</div>
		</div>
	);
};

export default StatForms;