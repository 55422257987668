import React, { useEffect, useRef, useState } from 'react';
import { trim } from '../../../../help';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';
import { useDispatch } from 'react-redux';



const DateBar = (props) => {
	const { formData, setFormData, settingsData, showMessageError, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const [isShwo, setIsShow] = useState(false)
	const [open, setOpen] = useState(false)
	const [openStart, setOpenStart] = useState(false)
	const [openEnd, setOpenEnd] = useState(false)
	const dispatch = useDispatch()
	const [value, setValue] = useState(
		contentRow.content[0].cols[col].defaultDateValue ? contentRow.content[0].cols[col].defaultDateValue : contentRow.content[0].cols[col].typeDate,
	);
	const [valueStart, setValueStart] = useState('')
	const [valueEnd, setValueEnd] = useState('')
	
	

	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	// useEffect(()=>{
	// 	if(contentRow.content[0].cols[col].defaultTimeLabel == 'Custom' || contentRow.content[0].cols[col].defaultTimeLabel == 'Current'){
	// 		setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: 
	// 			{
	// 				value: contentRow.content[0].cols[col].defaultTimeValue,
	// 				direction: page + '/' + row + '/' + col
	// 			}
	// 		})
	// 	}
	// },[])
	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var separator = formCopy[page].rows[row].content[0].cols[col].separator;
			var typeDate = formCopy[page].rows[row].content[0].cols[col].typeDate;
			var defaultDateLabel = formCopy[page].rows[row].content[0].cols[col].defaultDateLabel;
			var defaultDateValue = formCopy[page].rows[row].content[0].cols[col].defaultDateValue;
			var checkedTime = formCopy[page].rows[row].content[0].cols[col].checkedTime;
			var timeFormat = formCopy[page].rows[row].content[0].cols[col].timeFormat;
			var defaultTimeLabel = formCopy[page].rows[row].content[0].cols[col].defaultTimeLabel;
			var defaultTimeValue = formCopy[page].rows[row].content[0].cols[col].defaultTimeValue;
			var responsable = formCopy[page].rows[row].content[0].cols[col].content.responsable ? formCopy[page].rows[row].content[0].cols[col].content.responsable : false;
			var uniqueId = formCopy[page].rows[row].content[0].cols[col].uniqueId;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page,
					row,
					col
				},
				details: {
					date: {
						required,
						separator,
						typeDate,
						defaultDateLabel,
						defaultDateValue,
						checkedTime,
						timeFormat,
						defaultTimeLabel,
						defaultTimeValue,
						responsable,
						uniqueId
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const showDatePicker = e => {
		e.preventDefault()
		e.currentTarget.showPicker();
		setIsShow(!isShwo)
	}

	const toggleOverflow = e => {
		e.preventDefault()
		e.currentTarget.classList.toggle('label-overflow')
	}
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			padding: '10px',
			cursor: 'pointer',
		}),
		control: (prev, state) => ({
			...prev,
			cursor: 'pointer',
			height: 100 + '%',
		}),
		container: (prev, state) => ({
			...prev,
			cursor: 'pointer',
			height: 100 + '%',
		}),
	}
	// useEffect(()=>{
	// 	if(preview){
	// 		if(contentRow.content[0].cols[col].defaultTimeLabel == 'Custom' || contentRow.content[0].cols[col].defaultTimeLabel == 'Current'){
	// 			setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: 
	// 				{
	// 					value: contentRow.content[0].cols[col].defaultTimeValue,
	// 					direction: page + '/' + row + '/' + col
	// 				}
	// 			})
	// 		}
	// 	}
	// },[])
	const changeStartDate = value => {
		setValueStart(value)
		if (valueStart != null) {
			userData[contentRow.content[0].cols[col].uniqueId] = {
				value: moment(valueStart).format(contentRow.content[0].cols[col].typeDate.toUpperCase()) + ' - ' + moment(value).format(contentRow.content[0].cols[col].typeDate.toUpperCase()),
				direction: page + '/' + row + '/' + col
			}
			userData.selectOllDateValues = true
			
		}else{
			userData.selectOllDateValues = false
	}	
	}
	

	const changeEndDate = value => {
		setValueEnd(value)
			if (valueStart && value != null) {
				userData[contentRow.content[0].cols[col].uniqueId] = {
					value: moment(valueStart).format(contentRow.content[0].cols[col].typeDate.toUpperCase()) + ' - ' + moment(value).format(contentRow.content[0].cols[col].typeDate.toUpperCase()),
					direction: page + '/' + row + '/' + col
				}
				userData.selectOllDateValues = true
				
			}else{
				userData.selectOllDateValues = false
		}
	}
	
	const changeTimeUser = e => {
	}
	const changeDate = (value) => {
		try {
			if (preview) {
				let regex = /yyyy/i;
				if (regex.test(value)) {
					var dateUser = ""
				}
				else {
					var dateUser = `${moment(value).format(contentRow.content[0].cols[col].typeDate.toUpperCase())}`
				}
				userData[contentRow.content[0].cols[col].uniqueId] = {
					value: dateUser,
					direction: page + '/' + row + '/' + col
				}
				// setUserData({
				// 	...userData, [contentRow.content[0].cols[col].uniqueId]:
				// 	{
				// 		value: dateUser,
				// 		direction: page + '/' + row + '/' + col
				// 	}
				// })
			}
			setValue(value)

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				dispatch(setIsEdited(true))
			})
		}
		if (contentRow.content[0].cols[col].defaultDateValue) {
			changeDate(contentRow.content[0].cols[col].defaultDateValue)
			setValue(contentRow.content[0].cols[col].defaultDateValue)
		} else {
			changeDate(contentRow.content[0].cols[col].typeDate)
			setValue(null)
		}
		// console.log(rowError,1111);
	}, [contentRow])

	useEffect(() => {
		if (contentRow.content[0].cols[col].defaultDateLabel == 'Range') {
			if (contentRow.content[0].cols[col].defaultDateValue !== '' && contentRow.content[0].cols[col].defaultDateValue !== null) {
				setValueStart(contentRow.content[0].cols[col].defaultDateValue.split(' - ')[0])
				setValueEnd(contentRow.content[0].cols[col].defaultDateValue.split(' - ')[1])
			}
		}
	}, [value])

	return (
		<div id="datebar" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-text">
						<div className="text-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label} {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Date {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Date {
										contentRow.content[0].cols[col].content.required
											? ' *'
											: ''
									}</label>
							}
						</div>
						<div className="text-inputs d-flex justify-content-start h-100">
							<div className="text-input">
								<div className={rowError
									? "form-input-border required-field"
									: "form-input-border"
								}>
									<div className={contentRow.content[0].cols[col].checkedTime ? `text-input text-input-datepicker text-input-datepicker-checked ${rowError? 'required-field': ''}` : `text-input text-input-datepicker ${rowError?'required-field': ''} `}>
										{/* <DayPickerInput
										formatDate={formatDate}
										parseDate={parseDate}
										placeholder={contentRow.content[0].cols[col].typeDate}
										format={contentRow.content[0].cols[col].typeDate}
										value={contentRow.content[0].cols[col].defaultDateLabel !== 'None'
											? contentRow.content[0].cols[col].defaultDateValue
											: ''
										}
										onDayChange={day => setUserData({
											...userData, [contentRow.content[0].cols[col].uniqueId]:
											{
												value: `${moment(day).format(contentRow.content[0].cols[col].typeDate)}`,
												direction: page + '/' + row + '/' + col
												}

										})}
									/> */}

										{contentRow.content[0].cols[col].defaultDateLabel == 'Range'
											? <div className="d-flex">
												<div className="w-100 me-2">
										 	 		 <LocalizationProvider dateAdapter={AdapterDateFns}>
										 	 			<DatePicker
										 					open={openStart}
															value={valueStart}
															onOpen={() => setOpenStart(true)}
															onClose={() => setOpenStart(false)}
															// maxDate={new Date()}
															//format={contentRow.content[0].cols[col].typeDate}
															//inputFormat={contentRow.content[0].cols[col].typeDate}
															// disableOpenPicker={true}
															format={contentRow.content[0].cols[col].typeDate}
														    inputFormat={contentRow.content[0].cols[col].typeDate}
															onChange={(newValue) => {
																changeStartDate(newValue);
																if(newValue > valueEnd){
																	changeEndDate(null);
																}
															}}
															views={['year', 'month', 'day']}
															// components={{
															// 	OpenPickerIcon: MoreTimeIcon,
															// }}
															renderInput={(params) => (
																<TextField {...params} onClick={() => setOpenStart(!open)}/>
															)}
															InputProps={{ readOnly: true }}
														/>
													</LocalizationProvider>
												</div>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DatePicker
														open={openEnd}
														value={valueEnd}
														onOpen={() => setOpenEnd(true)}
														onClose={() => setOpenEnd(false)}
														// maxDate={new Date()}
														format={contentRow.content[0].cols[col].typeDate}
														inputFormat={contentRow.content[0].cols[col].typeDate}
														// disableOpenPicker={true}
														onChange={(newValue) => {
															if(valueStart && newValue > valueStart){
																changeEndDate(newValue);
															}else{
																changeEndDate(null);
															}
														}}
														views={['year', 'month', 'day']}
														// components={{
														// 	OpenPickerIcon: MoreTimeIcon,
														// }}
														renderInput={(params) => (
															<TextField {...params} onClick={() => setOpenEnd(!open)} />
														)}
														InputProps={{ readOnly: true }}
													/>
												</LocalizationProvider>
											</div> 
											 :<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													open={open}
													value={value}
													onOpen={() => setOpen(true)}
													onClose={() => setOpen(false)}
													// maxDate={new Date()}
													format={contentRow.content[0].cols[col].typeDate}
													inputFormat={contentRow.content[0].cols[col].typeDate}
													// disableOpenPicker={true}
													onChange={(newValue) => {
														changeDate(newValue);
													}}
													views={['year', 'month', 'day']}
													// components={{
													// 	OpenPickerIcon: MoreTimeIcon,
													// }}
													renderInput={(params) => (
														<TextField {...params} onClick={() => setOpen(!open)} />
													)}
													InputProps={{ readOnly: true }}
												/>
											</LocalizationProvider>
										} 



									 	
									 	 {/* <input 
										id="inputId"
										className="dateBar"
										type="date"
										onClick={showDatePicker}
										onChange={changeDate}
										data-date-format={contentRow.content[0].cols[col].typeDate}
									/>  */}
										{
											contentRow.content[0].cols[col].checkedTime
												?
												<div className="timeDate">
													<div className={contentRow.content[0].cols[col].timeFormat == 'AM/PM' ? "timeDate-input" : "timeDate-input timeDate-input-noselect"}>
														<input type="text"
															defaultValue={contentRow.content[0].cols[col].defaultTimeValue}
															onChange={changeTimeUser}
														/>
													</div>
													{
														contentRow.content[0].cols[col].timeFormat == 'AM/PM'
															?
															<div className="timeDate-select">
																<Select
																	components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
																	placeholder={'PM'}
																	isSearchable={false}
																	styles={customStyles}
																	options={[
																		{ id: 1, value: 'AM', label: 'AM' },
																		{ id: 2, value: 'PM', label: 'PM' },
																	]
																	}
																/>
															</div>
															: null
													}
												</div>
												: null
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Date</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Date</label>
								}
							</div>
							{/* {contentRow.content[0].cols[col].userValue 
							? */}
							<div className="text-inputs d-flex justify-content-start w-100 h-100">
								<div className="text-input text-input-datepicker-checked evented">
									<input type="text" className={"form-input-border evented"} placeholder={contentRow.content[0].cols[col].userValue} />
									{/* <DayPickerInput
										formatDate={formatDate}
										parseDate={parseDate}
										format={'LL'}
										placeholder={contentRow.content[0].cols[col].userValue}
									/> */}
									{
										contentRow.content[0].cols[col].checkedTime
											?
											<div className="timeDate">
												<div className={contentRow.content[0].cols[col].timeFormat == 'AM/PM' ? "timeDate-input" : "timeDate-input timeDate-input-noselect"}>
													<input tabIndex='-1' type="text" placeholder={contentRow.content[0].cols[col].userValueTime} />
												</div>
												{
													contentRow.content[0].cols[col].timeFormat == 'AM/PM'
														?
														<div className="timeDate-select">
															<Select
																components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
																placeholder={'PM'}
																isSearchable={false}
																styles={customStyles}
																options={[
																	{ id: 1, value: 'AM', label: 'AM' },
																	{ id: 2, value: 'PM', label: 'PM' },
																]
																}
															/>
														</div>
														: null
												}
											</div>
											: null
									}
								</div>
							</div>
							{/* :
							<h3 className='d-flex justify-content-start w-100'>__</h3>
							} */}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content.responsable
										? <span className='form-content-filter form-content-responsable' title='In response'><i className="fas fa-check"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label}</div>
											:
											// <input type="text" defaultValue="Date" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Date</div>
										)
										:
										<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Date</div>
									// <input type="text" defaultValue="Date" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<div className="text-inputs">
								<div className="text-input text-input-datepicker-checked evented">
									{
										contentRow.content[0].cols[col].defaultDateLabel == 'Range'
											?
											<div className="range-d-flex">
												<input type="text" className='form-input-border evented me-2 input-margin' placeholder={contentRow.content[0].cols[col].defaultDateValue !== '' && contentRow.content[0].cols[col].defaultDateValue !== null
													? contentRow.content[0].cols[col].defaultDateValue.split(' - ')[0]
													: contentRow.content[0].cols[col].typeDate.toUpperCase()
												} />
												<input type="text" className='form-input-border evented ' placeholder={contentRow.content[0].cols[col].defaultDateValue !== '' && contentRow.content[0].cols[col].defaultDateValue !== null
													? contentRow.content[0].cols[col].defaultDateValue.split(' - ')[1]
													: contentRow.content[0].cols[col].typeDate.toUpperCase()
												} />
											</div>
											:
											<input type="text" className='form-input-border evented ' placeholder={contentRow.content[0].cols[col].defaultDateValue !== '' && contentRow.content[0].cols[col].defaultDateValue !== null
												? moment(contentRow.content[0].cols[col].defaultDateValue).format(contentRow.content[0].cols[col].typeDate.toUpperCase())
												: contentRow.content[0].cols[col].typeDate.toUpperCase()
											} />
									}
									{
										contentRow.content[0].cols[col].checkedTime
											?
											<div className="timeDate">
												<div className={contentRow.content[0].cols[col].timeFormat == 'AM/PM' ? "timeDate-input" : "timeDate-input timeDate-input-noselect"}>
													<input type="text" tabIndex='-1' placeholder={
														contentRow.content[0].cols[col].timeFormat == 'AM/PM'
															? contentRow.content[0].cols[col].defaultTimeLabel == 'Custom' || contentRow.content[0].cols[col].defaultTimeLabel == 'Current'
																? contentRow.content[0].cols[col].defaultTimeValue.split(':')[0] == '00'
																	? `12:${contentRow.content[0].cols[col].defaultTimeValue.split(':')[1]}`
																	: +contentRow.content[0].cols[col].defaultTimeValue.split(':')[0] > 12
																		? +contentRow.content[0].cols[col].defaultTimeValue.split(':')[0] < 22
																			? `0${contentRow.content[0].cols[col].defaultTimeValue.split(':')[0] - 12}:${contentRow.content[0].cols[col].defaultTimeValue.split(':')[1]}`
																			: `${contentRow.content[0].cols[col].defaultTimeValue.split(':')[0] - 12}:${contentRow.content[0].cols[col].defaultTimeValue.split(':')[1]}`
																		: contentRow.content[0].cols[col].defaultTimeValue
																: contentRow.content[0].cols[col].defaultTimeValue
															: contentRow.content[0].cols[col].defaultTimeValue
													} />
												</div>
												{
													contentRow.content[0].cols[col].timeFormat == 'AM/PM'
														?
														<div className="timeDate-select">
															<Select
																components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
																placeholder={'PM'}
																isSearchable={false}
																styles={customStyles}
																options={[
																	{ id: 1, value: 'AM', label: 'AM' },
																	{ id: 2, value: 'PM', label: 'PM' },
																]
																}
															/>
														</div>
														: null
												}
											</div>
											: null
									}
								</div>
							</div>
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default DateBar;