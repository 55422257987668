import React from 'react';

const PaymentsRejected = () => {
	return (
		<div className="success success-page rejected">
			<div className="card">
				<div className="success-body">
					<svg className="declinemark" xmlns="http://www.w3.org/2000/svg" viewBox="0 -72 322 652">
						<circle className="declinemark__circle" cx="26" cy="26" r="25" fill="none" />
						<path className="declinemark__check" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
						{/* <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /> */}
					</svg>
				</div>
				<h1>Rejected</h1>
				<div className="success-edit disactive">
					<div className='success-edit-text'>Rejected error</div>
					<a href="/" className="payment-link">Go back</a>
				</div>
			</div>
		</div>
	);
};

export default PaymentsRejected;