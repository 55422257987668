import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getResponsesFullByMonth } from '../../../../redux/reducers/responseReducer';
import StatByMonth from './StatByMonth';
import StatFormResponses from './StatFormResponses';
import StatFormResponsesMonth from './StatFormResponsesMonth';
import StatForms from './StatForms';
import StatResponses from './StatResponses';
import StatUsers from './StatUsers';

const AdminStatistics = () => {
	document.title = "FormBuilder - Admin Statistics";
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getResponsesFullByMonth())
	}, [])
	return (
		<div className="content-main">
			<div className="bg-gradient-primary ">
				<div className="container-fluid">
					<div className="header-body header-body-admin">
						<div className="d-flex justify-content-between flex-wrap">
							<StatUsers />
							<StatForms />
							<StatResponses />
							{/* <div className="col-xl-3 col-lg-6">
                                <div className="card card-stats mb-4 mb-xl-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="card-title text-uppercase text-muted mb-0">Forms</h5>
                                                    <span className="h3 font-weight-bold mb-0">49,65%</span>
                                                </div>
                                            </div>
                                            <p className="mt-3 mb-0 text-muted text-sm">
                                                <span className="text-success mr-2"><i className="fas fa-arrow-up" /> 12%</span>
                                                <span className="text-nowrap">Since last month</span>
                                            </p>
                                        </div>
                                    </div>
                            </div> */}
						</div>

						<div className="d-flex justify-content-between chart-body"> 
							<StatByMonth />
						</div>
						<div className="d-flex justify-content-between chart-body">
							<div className="col-12">
								<span className="caption text-gray-600 d-block mb-1 analytic-text p-3">Responses By Month</span>
								<StatFormResponsesMonth />
							</div>
							{/* <div className="col-3 col-xxl-3 chart-content">
								<span className="caption text-gray-600 d-block mb-1 analytic-text">Responses By Forms</span>
								<StatFormResponses />
							</div> */}
						</div>
						<div className="col-12 chart-body">
							<span className="caption text-gray-600 d-block analytic-text p-3 pb-0">Responses By Forms</span>
							<StatFormResponses />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminStatistics;