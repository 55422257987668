import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import { AuthContext } from '../context';
import SigninForm from './SigninForm';
import SigninFormGoogle from './SigninFormGoogle';
import { useParams } from 'react-router';
import { getCookie, setCookie, eraseCookie } from '../cookie';
import Loader from '../UI/Loader/Loader';
import jwt from 'jsonwebtoken'

import shortId from 'short-mongo-id'

const TestActions = () => {

	const editUsers = e => {
		// axios.post(API_BASE_URL + '/api/testActionsGetForAdmins')
		// 	.then(response => {
				// var newForms = []
				// for (let i = 0; i < response.data.length; i++) {
				// 	newForms.push({
				// 		_id: response.data[i]._id,
				// 		link: shortId(response.data[i]._id),
				// 	})
				// }
			// 	const jwtExpirySeconds = 300
				
			// 	var newUsers = []
			// 	var users = response.data
			// 	for (let i = 0; i < users.length; i++) {
			// 		var jwtKey = users[i]._id
			// 		newUsers.push({
			// 			_id: users[i]._id,
			// 			token: jwt.sign({ email:'asdasdasasdd' }, jwtKey, {
			// 				algorithm: "HS256",
			// 				expiresIn: jwtExpirySeconds,
			// 			})
			// 		})
					
			// 	}
			// 	// console.log(newUsers);
			// 	axios.post(API_BASE_URL + '/api/testActionsSetForAdmins',newUsers)
			// 		.then(response => {
						
						
			// 		})
			// 		.catch(error => {
						
			// 		})
			// })
			// .catch(error => {
				
			// })

		// const jwtKey = "6214ef922a19657f5533ddf2"
		// const jwtExpirySeconds = 300
		// const token = jwt.sign({ email:'asdasdasasdd' }, jwtKey, {
		// 	algorithm: "HS256",
		// 	expiresIn: jwtExpirySeconds,
		// })
		// console.log("token:", token)
	}

	return (

		<div className="signup-template bg-gray-100" style={{ backgroundColor: '#fff'}}>
			<button onClick={() => editUsers()}>sadasdasaddasdassasasadasdasdasdsadasd</button>
		</div>
	);
};

export default TestActions;