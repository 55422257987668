import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { SxProps } from '@mui/material';

const FormSettingsDateRange = (props) => {

	const { open, setOpen, settingsData, changededlinebutton, changeDeadline, changeCountdown, setDataRangeChange} = props;

	
	const [dayCount, setDayCount] = useState('00')
	const [hourCount, setHourCount] = useState('00')
	const [minuteCount, setMinuteCount] = useState('00')
	const [buttonActive, setButtonActive] = useState(false)
	const [datePickerValue, setDatePickerValue] = useState('')

	function toggleButton(day, hour, minute) {
		var oldCountDown = settingsData.details.dateRange.countdown;
		var newCountDown = `${day}:${hour}:${minute}`
		if (oldCountDown !== newCountDown) {setButtonActive(true)} 
		else { setButtonActive(false) }
	}

	useEffect(() => {
		if (settingsData.details.dateRange.countdown && settingsData.details.dateRange.countdown !== '') {
			var countdown = settingsData.details.dateRange.countdown.split(':');
			var day = countdown[0];
			var hour = countdown[1];
			var minute = countdown[2];
			setDayCount(day)
			setHourCount(hour)
			setMinuteCount(minute)
		}
		// console.log(settingsData);
	},[settingsData])

	/*Deadline==========================================================*/

	var nowDeadline = {};

	const changeDeadlineDate = (deadline) => {
		if (deadline && deadline !== null) {
			nowDeadline = deadline
		}
	}

	const saveDeadlineDate = (deadline) => {
		setOpen(false)
		if (deadline && deadline !== null) {
			// console.log(deadline.length);
			changeDeadline(deadline)
		}
	}

	const clearDeadline = () => {
		changeDeadline('empty')
		setDatePickerValue('')
	}

	/*Countdown==========================================================*/

	const formTimeDay = useRef()
	const formTimeHour = useRef()
	const formTimeMinute = useRef()
	const timeDay = useRef()
	const timeHour = useRef()
	const timeMinute = useRef()

	const showDayAbsolute = e => {
		formTimeDay.current.classList.add('active')
		formTimeHour.current.classList.remove('active')
		formTimeMinute.current.classList.remove('active')
	}

	const showHourAbsolute = e => {
		formTimeHour.current.classList.add('active')
		formTimeMinute.current.classList.remove('active')
		formTimeDay.current.classList.remove('active')
	}

	const showMinuteAbsolute = e => {
		formTimeMinute.current.classList.add('active')
		formTimeHour.current.classList.remove('active')
		formTimeDay.current.classList.remove('active')
	}

	const changeTimeDay = e => {
		var day = +timeDay.current.innerText
		if (day > -1 && day < 99) {
			day++
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
		
		
	}
	const changeTimeDayFive = e => {
		var day = +timeDay.current.innerText
		if (day > -1 && day < 99 && day + 5 <= 99) {
			day += 5
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
	}
	const changeTimeDayTen = e => {
		var day = +timeDay.current.innerText
		if (day > -1 && day < 99 && day + 10 <= 99) {
			day += 10
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
	}
	const changeTimeDayMinus = e => {
		var day = +timeDay.current.innerText
		if (day > 0 && day < 100) {
			day--
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
	}
	const changeTimeDayFiveMinus = e => {
		var day = +timeDay.current.innerText
		if (day > 0 && day < 100 && day - 5 > -1) {
			day -= 5
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
	}
	const changeTimeDayTenMinus = e => {
		var day = +timeDay.current.innerText
		if (day > -1 && day < 100 && day - 10 > -1) {
			day -= 10
			if (day < 10) { day = '0' + day }
			setDayCount(day)
			toggleButton(day,hourCount,minuteCount)
		} else {
			setDayCount('00')
			toggleButton('00',hourCount,minuteCount)
		}
	}


	const changeTimeHour = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23) {
			hour++
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}
	const changeTimeHourFive = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23 && hour + 5 <= 23) {
			hour += 5
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}
	const changeTimeHourTen = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23 && hour + 10 <= 23) {
			hour += 10
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}
	const changeTimeHourMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > 0 && hour < 24) {
			hour--
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}
	const changeTimeHourFiveMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > 0 && hour < 24 && hour - 5 > -1) {
			hour -= 5
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}
	const changeTimeHourTenMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 24 && hour - 10 > -1) {
			hour -= 10
			if (hour < 10) { hour = '0' + hour }
			setHourCount(hour)
			toggleButton(dayCount,hour,minuteCount)
		} else {
			setHourCount('00')
			toggleButton(dayCount,'00',minuteCount)
		}
	}


	const changeTimeMinute = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 59) {
			minute++
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}
	const changeTimeMinuteMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > 0 && minute < 60) {
			minute--
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}
	const changeTimeMinuteFive = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute + 5 <= 59) {
			minute += 5
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}
	const changeTimeMinuteTen = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute + 10 <= 59) {
			minute += 10
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}
	const changeTimeMinuteFiveMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute - 5 > -1) {
			minute -= 5
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}
	const changeTimeMinuteTenMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute - 10 > -1) {
			minute -= 10
			if (minute < 10) { minute = '0' + minute }
			setMinuteCount(minute)
			toggleButton(dayCount,hourCount,minute)
		} else {
			setMinuteCount('00')
			toggleButton(dayCount,hourCount,'00')
		}
	}

	const changeCountDownTime = () => {
		var oldCountDown = settingsData.details.dateRange.countdown;
		var newCountDown = `${dayCount}:${hourCount}:${minuteCount}`
		if (oldCountDown !== newCountDown) {
			setButtonActive(false)
			changeCountdown(newCountDown)
		}
	}


	document.documentElement.addEventListener("click", function (e) {
		if (!e.target.closest('.form-time')) {
			if (formTimeDay.current) {
				formTimeDay.current.classList.remove('active')
			}
			if (formTimeHour.current) {
				formTimeHour.current.classList.remove('active')
			}
			if (formTimeMinute.current) {
				formTimeMinute.current.classList.remove('active')
			}
		}
	})
	return (
		<div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Deadline</h6>
					<div className='type-inputs d-flex'>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DateTimePicker
								open={open}
								onOpen={() => setOpen(true)}
								onClose={() => saveDeadlineDate(nowDeadline)}
								PopperProps = {{
									sx: {"& .MuiPaper-root" :{
										marginLeft: '45px'
									}}
								}}
								// format='DD/MM/YYYY HH:mm a'
								// inputFormat='DD/MM/YYYY HH:mm a'
								value={datePickerValue ? datePickerValue : datePickerValue && settingsData.details.dateRange.deadline == null ? null : settingsData.details.dateRange.deadline && false ? null : null}
								onChange={(deadline) => {
									setDatePickerValue(deadline)
									if(deadline) {
										if(moment(moment(deadline).format('MM/DD/YYYY HH:mm:ss')).diff(settingsData.details.dateRange.deadline) !== 0){
											setDataRangeChange(false)
										}
										changeDeadlineDate(deadline);
										
									}
									
								}}
								renderInput={(params) => (
									<TextField {...params} onClick={() => setOpen(!open)} />
								)}
								InputProps={{ readOnly: true }}
							/>
						</LocalizationProvider>
						<button type="button" className="clearDeadline" title="Clear Deadline" onClick={() => clearDeadline()}><span><i className="fas fa-times"></i></span></button>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Set deadline for accepting responses.</p>
			</div>

			<div>
				<div className="p-4 px-lg-5 border-bottom border-gray-200">
					<div className="d-flex flex-column">
						<h6 className="font-weight-semibold">Countdown / Timer</h6>
							<div className='mt-2'>
								<div className="form-times">
									<div className="form-times-day form-time">
										<div onClick={showDayAbsolute} className='form-control custom-button-time'>
											<span>{dayCount ? dayCount : '00'}</span> D
										</div>
										<div className="form-time-absolute form-time-hour" ref={formTimeDay}>
											<div className="absolute-time-content">
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDayFive} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDay}><span><i className="fas fa-caret-up"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDayTen} className='change-time-number'><span>10</span></button>
													</div>
												</div>
												<div className="absolute-time-content-number">
													<span ref={timeDay}>{dayCount ? dayCount : '00'}</span> D
												</div>
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDayFiveMinus} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDayMinus}><span><i className="fas fa-caret-down"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeDayTenMinus} className='change-time-number'><span>10</span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="form-times-hour form-time">
										<div onClick={showHourAbsolute} className='form-control custom-button-time'>
											<span>{hourCount ? hourCount : '00' }</span> H
										</div>
										<div className="form-time-absolute form-time-hour" ref={formTimeHour}>
											<div className="absolute-time-content">
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHourFive} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHour}><span><i className="fas fa-caret-up"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHourTen} className='change-time-number'><span>10</span></button>
													</div>
												</div>
												<div className="absolute-time-content-number">
													<span ref={timeHour}>{hourCount ? hourCount : '00'}</span> H
												</div>
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHourFiveMinus} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHourMinus}><span><i className="fas fa-caret-down"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeHourTenMinus} className='change-time-number'><span>10</span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="form-times-minute form-time">
										<div onClick={showMinuteAbsolute} className='form-control custom-button-time' >
											<span>{minuteCount ? minuteCount : '00' }</span> M
										</div>
										<div className="form-time-absolute form-time-minute" ref={formTimeMinute}>
											<div className="absolute-time-content">
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinuteFive} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinute}><span><i className="fas fa-caret-up"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinuteTen} className='change-time-number'><span>10</span></button>
													</div>
												</div>
												<div className="absolute-time-content-number">
													<span ref={timeMinute}>{minuteCount ? minuteCount : '00'}</span> M
												</div>
												<div className="absolute-time-content-buttons">
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinuteFiveMinus} className='change-time-number'><span>5</span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinuteMinus}><span><i className="fas fa-caret-down"></i></span></button>
													</div>
													<div className="absolute-time-content-button">
														<button type='button' onClick={changeTimeMinuteTenMinus} className='change-time-number'><span>10</span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<button type="button" className={buttonActive ? 'form-times-submit _active' : 'form-times-submit'} onClick={changeCountDownTime}>Set countdown</button>

							<p className="text-gray-600 pt-2 mb-0">Pre-populate the time field with a fixed value or current time.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormSettingsDateRange;