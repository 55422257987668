import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Select from "react-select";
import { Animated } from "react-animated-css";
import { trim } from '../../../../help';
import { useDispatch } from 'react-redux';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';


const Dropdown = (props) => {
	const { settingsData, setSettingsData, showMessageError, responseCheck, splitted, rowError, userData, setUserData, formData, setFormData, deleteThisRowContent, changeOption, col, row, page, preview, checkAdd, contentRow, changeLabel, changeInputInLabel, setCheckAdd } = props;
	const optionsRef = useRef();
	const [opened, setOpened] = useState(false);
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	// console.log(rowError);
	const dispatch = useDispatch()
	const showOptions = () => {
		var nowOpened = opened;
		nowOpened = !nowOpened;
		setOpened(!opened);
		try {
			const optionsShow = optionsRef.current.querySelectorAll('.option-content')
			if (nowOpened) {
				for (let i = 0; i < optionsShow.length; i++) {
					optionsShow[i].classList.add('active')
				}
			} else {
				for (let i = 0; i < optionsShow.length; i++) {
					if (i > 3) {
						optionsShow[i].classList.remove('active')
					}
				}
			}
			var copyOptions = JSON.parse(JSON.stringify(options))
			setOptions([])
			copyOptions = copyOptions.map(function (item, index) {
				item.id = index + 1;
				if (/\b(option\d+)\b/i.test(item.value)) {
					item.value = 'option' + item.id;
					item.label = 'option' + item.id;
				}
				return item;
			});
			setOptions(copyOptions)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const [options, setOptions] = useState(contentRow.content[0].cols[col].content.options)
	useEffect(() => {
		var formCopy = JSON.parse(JSON.stringify(formData))
		setFormData([])
		formCopy[page].rows[row].content[0].cols[col].content.options = options;
		setFormData(formCopy)
	}, [options])

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			// borderBottom: '1px dotted pink',
			borderBottom: '1px dotted #dee2e6',
			backgroundColor: state.isFocused ? '#f8f9fa' : undefined,
			padding: '5px 10px',
			background: state.isSelected ? '#f8f9fa' : '',
			// background: state.isSelected ? '#23adad' : '',
			color: state.isSelected ? 'black' : state.isFocused ? 'black' :
				'#1c1f21',
				// '#495057',
			searchable: false,
			lineHeight: '25px',
			wordBreak: 'break-word'
		}),
		control: (provided, state) => ({
			...provided,
			border: 'none !important',
		}),
	}

	const updateOption = e => {
		try {
			var index = e.target.getAttribute('index')
			var copyOptions = JSON.parse(JSON.stringify(options))
			setOptions([]);
			copyOptions[index].value = e.target.value;
			copyOptions[index].label = e.target.value;
			setOptions(copyOptions);
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const deleteOption = e => {
		e.preventDefault();
		try {
			var copyOptions = JSON.parse(JSON.stringify(options))
			var index = e.currentTarget.getAttribute('index')
			setOptions([]);
			copyOptions.splice(index, 1);
			copyOptions.filter(val => val)
			setOptions(copyOptions);
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const addOption = () => {
		try {
			var copyOptions = JSON.parse(JSON.stringify(options))
			setOptions([])
			copyOptions = copyOptions.map(function (item, index) {
				item.id = index + 1;
				if (/\b(option\d+)\b/i.test(item.value)) {
					item.value = 'option' + item.id;
					item.label = 'option' + item.id;
				}
				return item;
			});
			copyOptions.push({
				id: copyOptions.length + 1, value: 'option' + (copyOptions[copyOptions.length - 1].id + 1), label: 'Option' + (copyOptions[copyOptions.length - 1].id + 1)
			});
			setOptions(copyOptions)
			setTimeout(() => {
				const optionsShow = optionsRef.current.querySelectorAll('.option-content')
				for (let i = 0; i < optionsShow.length; i++) {
					optionsShow[i].classList.add('active')
				}
				setOpened(true)
			}, 10)
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const editThisRowContent = e => {
		e.stopPropagation();
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var filter = formCopy[page].rows[row].content[0].cols[col].content.filter;
			var responsable = formCopy[page].rows[row].content[0].cols[col].content.responsable ? formCopy[page].rows[row].content[0].cols[col].content.responsable : false;
			var uniqueId = formCopy[page].rows[row].content[0].cols[col].uniqueId;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page,
					row,
					col
				},
				details: {
					dropdown: {
						required,
						filter,
						responsable,
						uniqueId
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const toggleOverflow = e => {
		e.preventDefault()
		e.currentTarget.classList.toggle('label-overflow')
	}
	const changeDropdownLabel = e => {
		contentRow.content[0].cols[col].content.inLabel = e.target.value
		dispatch(setIsEdited(true))
	}
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				dispatch(setIsEdited(true))
			})
		}
	}, [contentRow])
	return (
		// <Animated className='animatedDiv styleAnimated' animationIn={checkAdd ? "shake" : ""} animationOut="fadeOut" isVisible={true}  >
		<div id="dropdownPage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-select">
						<div className="select-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label} {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Label {
										contentRow.content[0].cols[col].content.required
											? ' *'
											: ''
									}</label>
							}
						</div>
						<div className="select-inputs d-flex justify-content-start h-100">
							<div className="select-input">
								<Select
									id="text"
									type="text"
									className={splitted
										? (
											rowError
												? "form-content-select splitted form-input-border required-field"
												: "form-content-select form-input-border  splitted"
										)
										: (
											rowError
												? "form-content-select form-input-border  required-field"
												: "form-content-select form-input-border "
										)
									}
									styles={customStyles}
									options={options}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary: "black"
										}
									})}
									isSearchable={false}
									placeholder={contentRow.content[0].cols[col].content.inLabel ? contentRow.content[0].cols[col].content.inLabel : 'Select Option'}
									isClearable={false}
									// onChange={e => setUserData({ ...userData, [page + '/' + row + '/' + col]: e.value })}
									onChange={e => setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: e.value, direction: page + '/' + row + '/' + col } })}
								// openMenuOnClick={false}
								/>
								{/* <div className='options-content'>
									{
										options.map((option, index) => {
											return <div className="option-content" key={index}>
												<input type="text" className="option-label preview-label" placeholder={option.value} index={index} />
											</div>
										})
									}
								</div> */}
							</div>
						</div>
					</div>
				</div>
				:
				(responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-select">
							<div className="select-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
								}
							</div>
							{contentRow.content[0].cols[col].userValue 
							?
							<div className="text-inputs d-flex justify-content-start h-100">
								<div className="text-input">
									<input
										type="text"
										tabIndex='-1'
										readOnly
										className="form-input-border text-dark"
										defaultValue={contentRow.content[0].cols[col].userValue ? contentRow.content[0].cols[col].userValue : ''}
									/>
									{/* <Select
										tabIndex='-1'
										id="text"
										type="text"
										className="form-input-border evented text-dark"
										styles={customStyles}
										options={options}
										isSearchable={false}
										isClearable={false}
										placeholder={contentRow.content[0].cols[col].userValue ? contentRow.content[0].cols[col].userValue : ''}
									/> */}
								</div>
							</div>
							:
							<h3 className='d-flex justify-content-start w-100 h-100'>__</h3>
							}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-select">

							<div className="select-label row-label">
							{
									contentRow.content[0].cols[col].content.responsable
										? <span className='form-content-filter form-content-responsable' title='In response'><i className="fas fa-check"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content.filter
										? <span className='form-content-filter' title='filter'><i className="fas fa-filter"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label}</div>
											:
											// <input type="text" defaultValue="Dropdown" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Dropdown</div>
										)
										:
										<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Dropdown</div>
									// <input type="text" defaultValue="Dropdown" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<div className="select-inputs">
								<div className="select-input">
									<Select
										id="text"
										type="text"
										className={splitted
											? "form-content-select splitted mb-1"
											: "form-content-select mb-1"
										}
										styles={customStyles}
										options={options}
										theme={(theme) => ({
											...theme,
											colors: {
												...theme.colors,
												primary: "black"
											}
										})}
										isSearchable={false}
										placeholder=""
										isClearable={true}
									// openMenuOnClick={false}
									/>
									<input type="text" className='select-input-changable' defaultValue={contentRow.content[0].cols[col].content.inLabel ? contentRow.content[0].cols[col].content.inLabel : 'Select Option'} page={page} row={row} col={col} onChange={e => changeDropdownLabel(e)} />
									{
										options
											?
											<div className='options-content checkbox-options-content' ref={optionsRef}>
												{
													options.map((option, index) => {
														return <div className={index > 3 ? 'option-content checkbox-option-content' : 'option-content checkbox-option-content active'} key={index}>
															{/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"/> */}
															{/* <div className='checkbox-check'></div> */}
															<div className="dropdown-icon"><span>{index + 1}<i className="fas fa-bars"></i></span></div>
															<input tabIndex='-1' type="text" className="option-label checkbox-label" value={option.value} index={index} onChange={updateOption} />
															<div className="option-content-buttons">
																<button type="button" className="option-button option-button-delete" index={index} onClick={deleteOption}><span><i className="fas fa-times"></i></span></button>
															</div>
														</div>
													})
												}
												{
													options && options.length > 4
														?
														<button className='select-button' type='button' onClick={showOptions}>
															{
																opened
																	?
																	<p>Close Options
																		{/* <span> (Options saved automatically)</span> */}
																	</p>
																	:
																	<p>Show more options</p>
															}
														</button>
														: null
												}
												<button type="button" className="option-button-add" onClick={addOption}><span>Add new option</span></button>
											</div>
											: null
									}

									{/* <button className='select-button' type='button' onClick={showOptions}>
										{
											opened
												?
												<p>Close Options<span> (Options saved automatically)</span></p>
												:
												<p>Edit Options</p>
										}
									</button>
									<div className='options-content' ref={optionsRef}>
										{
											options.map((option, index) => {
												return <div className="option-content" key={index}>
													<input type="text" className="option-label" value={option.value} index={index} onChange={updateOption} />
													<div className="option-content-buttons">
														<button type="button" className="option-button option-button-delete" index={index} onClick={deleteOption}><span><i className="fas fa-trash-alt"></i></span></button>
													</div>
												</div>
											})
										}
										<button type="button" className="option-button-add" onClick={addOption}><span>Add new option</span></button>
									</div> */}
									{/* <input type="text" defaultValue="Sublabel" className='form_input row-sublabel' /> */}
									{/* {
									contentRow.content[0].cols[col].content.required
										?
										<label
											className='form_input row-sublabel'>This field is required</label>
										: null
									} */}
								</div>
							</div>
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>

						</div>
					</div>
				)
			}
		</div >
		// </Animated>
	);
};

export default Dropdown;