import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { trim } from '../../../../help';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';
import { useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AutoLinks from 'quill-auto-links';
import AutoType from 'quill-auto-detect-url'
import parse  from "html-react-parser"

const modules = {
    toolbar: {
        container: [
			[{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'link']],
    },
    clipboard: {
        matchVisual: false,
    },
    autoLinks: true,

};

const formats = [
    'bold', 'italic', 'underline', 'strike', 'link',  'font', 'size',
]

const Paragraph = (props) => {
	const { formData, setFormData, settingsData, showMessageError, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const dispatch = useDispatch()

	Quill.register('modules/autoLinks', AutoLinks);
	useEffect(() => {
	
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	
		document.querySelectorAll('.paragraph-input a').forEach(i => {
			i.setAttribute('target','_blank')
		})
	}, [])

	const changeParagraphData = (data) => {
		contentRow.content[0].cols[col].content.paragraph = data
		dispatch(setIsEdited(true))
	}
	// useEffect(() => {
	// 	if (document.querySelector('.ql-size')){
	// 		document.querySelector('.ql-size').addEventListener('mousedown',() => contentRow.content[0].cols[col].content.disabled = true)
	// 	} 
	// 	setTimeout(() => {
	// 		contentRow.content[0].cols[col].content.disabled = false
	// 	}, 1000);
	// },[contentRow])

	return (
		<div id="paragraphPage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-text">
						<div className="text-inputs paragraph-input">
							{contentRow.content[0].cols[col].content.paragraph ? parse(contentRow.content[0].cols[col].content.paragraph) : 'Type here...' }
					
						</div>
					</div>
				</div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-inputs paragraph-input">
								<div dangerouslySetInnerHTML={{ __html: contentRow.content[0].cols[col].content.paragraph ? contentRow.content[0].cols[col].content.paragraph : 'Type here...' }} className='editor'></div>
							</div>
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-inputs text-inputs-editor">

								<div className={contentRow.content[0].cols[col].content.required
									?
									"text-input required-field"
									:
									"text-input"
								}>
									<ReactQuill 
										theme="snow" 
										placeholder={'Write something...'}
										modules={modules}
										formats={formats}
										value={contentRow.content[0].cols[col].content.paragraph ? contentRow.content[0].cols[col].content.paragraph : ""} 
										onChange={changeParagraphData}
									/>
								</div>
							</div>
							<div className="col-buttons">
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default Paragraph;