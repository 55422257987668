import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminForms, getAdminFormsData, getAdminUsers, setAdminLoading, setForms } from '../../../redux/reducers/adminReducer';
import { getAdminUsersData } from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';



const AdminDataForms = (props) => {
	const { addData, setModal, setModalData, dataAll, setDataAll, setTableData, setPageNumber, pageNumber } = props;
	document.title = "FormBuilder - Admin Forms";
	const dispatch = useDispatch();
	const dataNow = useSelector(getAdminFormsData)
	const [searchInfo, setSearchInfo] = useState('');
	const tabName = {
		'Title': 'title',
		'Link': 'link',
		'Email': 'useremail',
		// 'Details': 'formatedDetails',
		'Details': 'details',
		'Responses': 'responses',
		// 'Created': 'formatedDate',
		'Created': 'created_at',
	}

	useEffect(() => {
		setPageNumber(dataNow.current_page)
		setDataAll(dataNow)
	}, [dataNow])

	useEffect(() => {
		dispatch(getAdminForms({ page: 1 }))
	}, [])

	const getData = (data) => {
		if(dataAll.current_page !== data.page){
			dispatch(setAdminLoading(true))
			data.searchInfo = searchInfo;
			axios.post(API_BASE_URL + '/api/getFormsDatasForAdmin?page=' + data.page, data)
				.then(response => {
					dispatch(setAdminLoading(false))
					dispatch(setForms(response.data))
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
		}
	}

	return (
		<div className="home__data">
			<div>
				{/* <SearchAdmin
					dataAdminUsers={dataAdminUsers}
					setDataAdminUsers={setDataAdminUsers}
					searchValue={searchValue}
					nowLocation={nowLocation}
				/> */}
			</div>
			{dataAll.data ?
				<div className='admin-responses'>
					<AdminTable
						tabName={tabName}
						dataAll={dataAll}
						setDataAll={setDataAll}
						table={'Form'}
						getData={getData}
						setModal={setModal}
						setModalData={setModalData}
						setTableData={setTableData}
						dataNow={dataNow}
						setSearchInfo={setSearchInfo}
						searchInfo={searchInfo}
					/>
				</div> :
				<div className="form-loader">
					<Loader />
				</div>}
		</div >
	);

};

export default AdminDataForms;