import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { AuthContext } from "../context";
import Preview from "../pages/Form/UserForm";
import Home from "../pages/Home";
import PreviewSuccess from "../pages/Success";
import Reset from "../pages/Reset";
import Signin from "../pages/Signin";
import Success from "../pages/Success";
import SuccessReport from "../pages/SuccessReport";
import Verify from "../pages/Verify";
import { publicRoutes, privateRoutes, adminRoutes } from "../router";
import Loader from "../UI/Loader/Loader";
import PageLoader from "../UI/Loader/PageLoader";
import UserForm from "../pages/Form/UserForm";
// import axios from 'axios';

// axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const AppRouter = () => {
	const { isAuth, isAuthAdmin, isLoading } = useContext(AuthContext);
	const [verify, setVerify] = useState(false);
	const [reset, setReset] = useState(false);
	const [signin, setSignin] = useState(false)
	const [success, setSuccess] = useState(false)
	const [successReport, setSuccessReport] = useState(false)
	const [formCheck, setFormCheck] = useState(false)
	useEffect(() => {
		if (localStorage.getItem('verify')) { setVerify(true) } else { setVerify(false) }
		if (localStorage.getItem('reset')) { setReset(true) } else { setReset(false) }
		if (localStorage.getItem('signin')) { setSignin(true) } else { setSignin(false) }
		if (localStorage.getItem('success')) { setSuccess(true) } else { setSuccess(false) }
		if (localStorage.getItem('report')) { setSuccessReport(true) } else { setSuccessReport(false) }
	}, [])

	useEffect(() => {
		if (window.location.pathname.split('/')[2] != undefined) { setFormCheck(true) }
		else { setFormCheck(false) }
	}, [])

	if (isLoading) {
		return <PageLoader />
	}

	return (
		isAuthAdmin
			? (
				success === true
					?
					<Switch>
						<Success
							path={'/success'}
							component={Success}
							exact={true}
						/>
					</Switch >
					:
					(window.location.pathname.substring(0, 3) === "/f-"
						?
						<Switch>
							<UserForm
								path={'/:formId'}
								component={UserForm}
								exact={true}
							/>
						</Switch >
						:
						<Switch>
							{adminRoutes.map(route =>
								<Route
									key={route.path}
									component={route.component}
									path={route.path}
									exact={route.exact}
								/>
							)
							}
							<Redirect to='/signin' />
						</Switch >
					)
			)
			: (isAuth
				? (
					success === true
						?
						<Switch>
							<Success
								path={'/success'}
								component={Success}
								exact={true}
							/>
						</Switch >
						:
						(window.location.pathname.substring(0, 3) === "/f-"
							?
							<Switch>
								<UserForm
									path={'/:formId'}
									component={UserForm}
									exact={true}
								/>
							</Switch >
							:
							<Switch>
								{
									privateRoutes.map(route =>
										<Route
											key={route.path}
											component={route.component}
											path={route.path}
											exact={route.exact}
										/>
									)
								}
								<Redirect to='/' />
							</Switch >
						)
				)
				: (verify === true
					?
					<Switch>
						<Verify
							path={'/verify'}
							component={Verify}
							exact={true}
						/>
					</Switch >
					: (
						reset === true
							?
							<Switch>
								<Reset
									path={'/reset/:id'}
									component={Reset}
									exact={true}
								/>
								{publicRoutes.map(route =>
									<Route
										key={route.path}
										component={route.component}
										path={route.path}
										exact={route.exact}
									/>
								)
								}
								<Redirect to='/' />
							</Switch >
							: (
								success === true
									?
									<Switch>
										<Success
											path={'/success'}
											component={Success}
											exact={true}
										/>
									</Switch > :
									successReport === true ?
										<Switch>
											<SuccessReport
												path={'/success-report'}
												component={SuccessReport}
												exact={true}
											/>
										</Switch >

									: (signin === true
										?
										(
											window.location.pathname.split('/')[2] != undefined
												?
												<Switch>
													<Signin
														path={'/signin/:id'}
														component={Signin}
														exact={true}
													/>
												</Switch >
												:
												(window.location.pathname.substring(0, 3) === "/f-"
													?
													<Switch>
														<UserForm
															path={'/:formId'}
															component={UserForm}
															exact={true}
														/>
													</Switch >
													:
													<Switch>
														{publicRoutes.map(route =>
															<Route
																key={route.path}
																component={route.component}
																path={route.path}
																exact={route.exact}
															/>
														)
														}
														<Redirect to='/signin' />
													</Switch >
												)
										)
										:
										(window.location.pathname.substring(0, 3) === "/f-"
											?
											<Switch>
												<UserForm
													path={'/:formId'}
													component={UserForm}
													exact={true}
												/>
											</Switch >
											:
											<Switch>
												{publicRoutes.map(route =>
													<Route
														key={route.path}
														component={route.component}
														path={route.path}
														exact={route.exact}
													/>
												)
												}
												<Redirect to='/signin' />
											</Switch >
										)
									)
							)
					)
				)
			)
	)
}
export default AppRouter;