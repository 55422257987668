import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useSelector } from "react-redux";
import { getResponsesData } from "../../redux/reducers/responseReducer";
import moment from "moment";

const D3Response = (props) => {
    const { allResponses } = props;
    const svgRef = useRef();
    const dimensions = {
        padding: { top: 20, right: 0, bottom: 30, left: 40 },
        margin:  { top: 20, right: 0, bottom: 30, left: 80 },
    };
    const {padding, margin } = dimensions;

    useEffect(() => {
        try {
            if(window.location.pathname.includes('statistics')){
                if(allResponses && allResponses.length > 0){
                    var data = allResponses
                    function drawChart() {
                        const innerWidth = window.innerWidth
            
                        if (innerWidth <= 480) {
                            var height = 300
                        }
                        if (innerWidth >= 481) {
                            var height = 500
                        }
                        let width = parseInt(d3.select('#demo').style('width'), 10)
                        const svgWidth = width;
                        const svgHeight = height + margin.top + margin.bottom;
            
            
                        let minTimeValue = new Date().setHours(0, 0, 0, 0);
                        let maxTimeValue = new Date().setHours(23, 59, 59, 59);
            
            
                        const svg = d3.select(svgRef.current);
                        svg
                            // .attr("transform","translate(70,0)")
                            .attr('width',svgWidth)
                            .attr('height',svgHeight)
                        svg.selectAll("*").remove(); // Clear svg content before adding new elements
            
            
                        var xScale = d3.scaleBand().range([0, width-23]).padding(1).domain([0, 0])
                        var yScale = d3
                            .scaleTime()
                            .domain([minTimeValue, maxTimeValue])
                            .nice(d3.timeDay)
                            .range([height, 0]);
            
            
                        var now = new Date();
                        var date = new Date();
                        date.setDate(date.getDate() - 9);
                        data = data.filter(item => new Date(item.created_at) >= date || new Date(item.created_at) <= now)
            
                        data.sort(function (a, b) {
                            var dateA = new Date(a.created_at), dateB = new Date(b.created_at)
                            return dateA - dateB
                        });
                        var tooltip = d3.select("#d3").append("div")
                            .attr("class", "circleTooltip")
                            .style("opacity", 0)
            
            
                        var mouseover = function (d) {
                            tooltip
                                .style("opacity", 1)
                        }
            
                        var mousemove = (event, datum) => {
                            var pulseCircle = d3.select(event.currentTarget)
                            pulse(pulseCircle)
        
                            var created_at = new Date(datum.created_at).toLocaleString('pt-PT').replace(',','')
                            var days = moment(created_at,"DD/MM/YYYY HH:mm:ss").diff(moment(datum.started_at,"DD/MM/YYYY HH:mm:ss"),'days')
                            var hours = moment(created_at,"DD/MM/YYYY HH:mm:ss").diff(moment(datum.started_at,"DD/MM/YYYY HH:mm:ss"),'hours')
                            var minutes = moment(created_at,"DD/MM/YYYY HH:mm:ss").diff(moment(datum.started_at,"DD/MM/YYYY HH:mm:ss"),'minutes')
                            tooltip
                            .transition()
                            .duration(200)
                            .style("opacity", .9)
                            // tooltip.html(`Name - ${datum.name}<br> Duration -${days != 0 ? days + "d" : ''} ${hours%24 != 0 ? hours%24 + "h": ''}  ${minutes%60 != 0 ? minutes%60 + "min" : ''}`)
                            tooltip.html(`Duration -${days != 0 ? days + "d" : ''} ${hours%24 != 0 ? hours%24 + "h": ''}  ${minutes%60 != 0 ? minutes%60 + "min" : ''}`)
                            .style("left", (event.pageX + 10) + "px")
                            .style("top", (event.pageY - 80) + "px")
                            
                            
                        }
            
                        function pulse(pulseCircle) {
                            (function repeat() {
                                pulseCircle
                                    .transition()
                                    .duration(0)
                                    .attr("stroke-width", 0)
                                    .attr('stroke-opacity', 0)
                                    .attr("r", function () {
                                        if (innerWidth < 480) {
                                            return 5;
                                        }
                                        return 6;
                                    })
                                    .transition()
                                    .duration(200)
                                    .attr("stroke-width", 0)
                                    .attr('stroke-opacity', 0.5)
                                    .transition()
                                    .duration(1000)
                                    .attr("stroke-width", 30)
                                    .attr('stroke-opacity', 0)
                                    .ease(d3.easeSin)
                                    .on("end", repeat);
                            })();
                        }
            
                        var mouseleave = function (event, d) {
                            d3.select(event.currentTarget)
                                .transition()
                                .duration(0)
                                .attr("stroke-width", null)
                                .attr("stroke-opacity", null)
                                .attr("r", function () {
                                    if (innerWidth < 480) {
                                        return 4;
                                    }
                                    return 7;
                                })
                            tooltip
                                .transition()
                                .duration(200)
                                .style("opacity", 0)
                        }
                        // get xaxis,yaxis and circles
                        const xAxis = (g) =>
                            g
                                .attr("class", "myXaxis")
                                .attr("transform", `translate(34,${height + 30})`)
                                .call(d3.axisBottom(xScale).tickSizeOuter(10))
                                .call(g => g.select(".domain").attr("display", "none"))
            
            
                        const yAxis = (g) =>
                            g
                                .attr("class", "myYaxis")
                                .attr("transform", `translate(${padding.left+20},10)`)
                                .call(d3.axisLeft(yScale).ticks(6).tickFormat(d3.timeFormat('%H:%M')))
                                .call(g => g.select(".domain").attr("display", "none"))
            
            
                        svg.append("g")
                            .attr("class", "bars")
                            .selectAll("dot")
                            .data(data)
                            .enter()
                            .append("circle")
                            .attr("cx", function (d) {
                                return xScale(moment(d.created_at).format('MM/DD'));
                            })
                            .attr('cy', d => {
                                let today = new Date();
                                let time = new Date(d.created_at);
                                return yScale(today.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()));
                            })
                            .attr("r", function () {
                                if (innerWidth < 480) {
                                    return 4;
                                }
                                return 7;
                            })
                            .attr("transform", `translate(34,10)`)
                            .style("fill", function (d) {
                                var created_at = new Date(d.created_at).toLocaleString('pt-PT').replace(',','')
                                var diff = moment(created_at,"DD/MM/YYYY HH:mm:ss").diff(moment(d.started_at,"DD/MM/YYYY HH:mm:ss"))
                                var duration = Math.floor(diff/60000)
                                if (duration && duration <= 30) {
                                    return "rgb(75 129 174)"
                                } else if (duration && duration > 30 && duration <= 60) {
                                    return "rgb(97 102 170)"
                                } else {
                                    return "rgb(20, 101, 174)"
                                }
                            })
                            .style("opacity", 0.35)
                            .style("stroke", function (d) {
                                if (d.duration && d.duration <= 30) {
                                    return "rgb(2 37 66)"
                                } else if (d.duration && d.duration > 30 && d.duration <= 60) {
                                    return "rgb(52 56 108)"
                                } else {
                                    return "rgb(3 49 90)"
                                }
                            })
                            .on("mouseover", mouseover)
                            .on("mouseenter", mousemove)
                            .on("mouseleave", mouseleave)
            
            
            
                        // svg.selectAll(".bars circle").each(function (d, i) {
                        //     if (d3.select(this).attr("cy") <= 6.52) {
                        //         return d3.select(this).attr("transform", `translate(60,30)`)
                        //     }
                        // })
                        svg.append("g").attr("class", "x-axis").append("g").call(xAxis);
            
                        svg.append("g").attr("class", "y-axis").append("g").call(yAxis);
            
            
            
                        xScale.domain(data.map(d => {
                            // var splitted = d.started_at.split(' ')[0].split('/');
                            // var ready = splitted[1] + '/' + splitted[2]
                            // return d.started_at.split(' ')[0].split('/')[1].toString() + '/' + d.started_at.split(' ')[0].split('/')[0].toString();
                            // return d.started_at.split(' ')[0];
                            return d.created_at.slice(5, 10).split('-').join('/')
                        }));
            
                        svg.select(".myXaxis")
                            .transition()
                            .duration(500)
                            .attr("opacity", "1")
                            .call(d3.axisBottom(xScale));
                        svg.select(".x-axis .days")
                            .transition()
                            .duration(5500)
                            .attr("opacity", "1")
                        var circle = svg.selectAll("circle")
                            .transition()
                            .delay(function (d, i) { return (i * 3) })
                            .duration(2000)
                            .attr("cx", function (d) {
                                return xScale(moment(d.created_at).format('MM/DD'));
                            })
                            .attr('cy', d => {
                                let today = new Date();
                                let time = new Date(d.created_at);
                                return yScale(today.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()));
                            })
            
            
                        // zoom
            
                        svg
                            .call(zoom);
            
                        function zoom(svg) {
                            const extent = [
                                [padding.left, 0],
                                [width - padding.right, height]
                            ];
            
                            svg.call(
                                d3
                                    .zoom()
                                    .scaleExtent([1, 15])
                                    .translateExtent(extent)
                                    .extent(extent)
                                    .on("zoom", zoomed)
                            );
            
                            function zoomed(event) {
                                xScale.range(
                                    [0, width-23].map((d) =>
                                        event.transform.applyX(d)
                                    )
                                )
                                yScale.range(
                                    [height, 0].map((d) =>
                                        event.transform.applyY(d)
                                    )
                                )
                                svg
                                    .selectAll(".bars circle")
                                    .attr("cx", function (d) {
                                        return xScale(moment(d.created_at).format('MM/DD'));
                                    })
                                    .attr('cy', d => {
                                        let today = new Date();
                                        let time = new Date(d.created_at);
                                        return yScale(today.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()));
                                    })
                                    // .attr("transform", `translate(60,0)`)
                                    .attr("width", xScale.bandwidth())
                                svg.selectAll(".myXaxis").attr("opacity", "1").call(xAxis);
                                svg.selectAll(".myYaxis").attr("opacity", "1").call(yAxis);
                                // svg.selectAll(".bars circle").each(function (d, i) {
                                //     if (d3.select(this).attr("cy") <= 6.52) {
                                //         return d3.select(this).attr("transform", `translate(60,30)`)
                                //     }
                                // })
                            }
                        }
            
                        d3.select("#demo")
                            // .style("width","970px")
                            .style("position","relative")
            
                        var days =  d3.select("#days")
                            .style("opacity", "0")
                            .style("font-weight", "bold")
                            .style("text-align", "end")
                            .transition()
                            .duration(5500)
                            .style("opacity", "1")
                            .text("Days")
            
            
            
                        var hours = d3.select("#hours")
                            .attr("style",
                                "width:50px;transform:rotate(-90deg);position: absolute;top: 15px;left:-20px;font-weight: bold")
                            .text("Hours")
                    }
                    drawChart()
                    // window.resize(drawChart)
                    // window.addEventListener('resize', drawChart )
                }
            }
        } catch (error) {
            throw error
        }
    }, [allResponses])

    return (
        <div id="d3" className="shadow-dark-80 p-2"> 
            <div id="demo">
                <div id="hours"></div>
                <svg ref={svgRef}></svg>
                <div id="days"></div>
            </div>
        </div>
    );
};
export default D3Response;