import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	getMyInfo,
	getUsersInfo,
	getFormsInfo,
	getMembersConfirmCountInfo,
	getMembersInfo,
	getMembersConfirmInfo,
} from '../features/dataApi';

const initialState = {
	info: {},
	users: {},
	myInfoNow: [],
	members: {},
	membersConfirmCount: 0,
	membersConfirm: {},
	isEdited: false,
	usersLoading: false,
	forms: {},
	membersLoading: false,
	membersConfirmLoading: false,
	formsLoading: false,
}
export const getInfo = createAsyncThunk(
	'/api/getMe',
	async () => {
		const response = await getMyInfo()
		return response.data
	}
)

export const getUsers = createAsyncThunk(
	'/api/getUserDatas',
	async () => {
		const response = await getUsersInfo()
		return response.data
	}
)

export const getMembers = createAsyncThunk(
	'/api/getMembers?page=',
	async (page) => {
		const response = await getMembersInfo(page)
		return response.data
	}
)

export const getMembersConfirm = createAsyncThunk(
	'/api/getMembersConfirm?page=',
	async (page) => {
		const response = await getMembersConfirmInfo(page)
		return response.data
	}
)

export const getMembersConfirmCount = createAsyncThunk(
	'/api/getMembersConfirmCount',
	async () => {
		const response = await getMembersConfirmCountInfo()
		return response.data
	}
)

export const getForms = createAsyncThunk(
	'/api/getFormsDatas',
	async () => {
		const response = await getFormsInfo()
		return response.data
	}
)

export const dataSlice = createSlice({
	name: 'dataReducer',
	initialState,
	reducers: {
		setForms: (state, action) => {
			state.forms = action.payload
		},
		setUsers: (state, action) => {
			state.users = action.payload
		},
		setIsEdited: (state, action) => {
			state.isEdited = action.payload
		},
		setMembers: (state, action) => {
			state.members = action.payload
		},
		setMembersConfirm: (state, action) => {
			state.membersConfirm = action.payload
		},
		setMyInfoNow: (state, action) => {
			state.myInfoNow = action.payload
		},
		setMembersConfirmCount: (state, action) => {
			state.membersConfirmCount = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.fulfilled, (state, action) => {
				state.usersLoading = false
				dataSlice.caseReducers.setUsers(state, action)
			})
			.addCase(getUsers.pending, (state, action) => {
				state.usersLoading = true
			})
			.addCase(getForms.fulfilled, (state, action) => {
				state.formsLoading = false
				dataSlice.caseReducers.setForms(state, action)
			})
			.addCase(getForms.pending, (state, action) => {
				state.formsLoading = true
			})
			.addCase(getMembers.fulfilled, (state, action) => {
				state.membersLoading = false
				dataSlice.caseReducers.setMembers(state, action)
			})
			.addCase(getMembers.pending, (state, action) => {
				state.membersLoading = true
			})
			.addCase(getMembersConfirm.fulfilled, (state, action) => {
				state.membersLoading = false
				dataSlice.caseReducers.setMembers(state, action)
			})
			.addCase(getMembersConfirm.pending, (state, action) => {
				state.membersLoading = true
			})
			.addCase(getMembersConfirmCount.fulfilled, (state, action) => {
				dataSlice.caseReducers.setMembersConfirmCount(state, action)
			})
	},
});

export const { setUsers, setForms, setIsEdited, setMembers, setMembersConfirm, setMyInfoNow, setMembersLoading } = dataSlice.actions;

export const getInfoData = (state) => state.infoReducer.info;
export const getUsersData = (state) => state.dataReducer.users;
export const getMembersData = (state) => state.dataReducer.members;
export const getMyInfoNowData = (state) => state.dataReducer.myInfoNow;
export const getMembersConfirmData = (state) => state.dataReducer.members;
export const getMembersConfirmCountData = (state) => state.dataReducer.membersConfirmCount;
export const getMembersDataLoading = (state) => state.dataReducer.membersLoading;
export const getUsersDataLoading = (state) => state.dataReducer.usersLoading;
export const getFormsData = (state) => state.dataReducer.forms;
export const getFormsDataLoading = (state) => state.dataReducer.formsLoading;
export const getIsEdited = (state) => state.dataReducer.isEdited;


export default dataSlice.reducer;
