import React, { useContext, useEffect, useRef, useState } from 'react';
import FormData from 'form-data';
import axios from 'axios';
import { useParams, Prompt } from 'react-router';
import { API_BASE_URL, APP_BASE_URL, AxiosConfigs, getAxiosConfig } from '../config';
import Row from './Form/FormComponents/Row';
import Draggable from 'react-draggable';
import FormNavbar from './Form/FormComponents/FormNavbar';
import Image from './Form/FormComponents/Bars/Image';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import Swal from 'sweetalert2'
import { isMobile, randomString, trim } from '../help';
import FormSettings from './Form/FormComponents/FormSettings/FormSettings';
import ContextMenu from '../components/ContextMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoData } from '../redux/reducers/infoReducer';
import Loader from '../UI/Loader/Loader';
import { getIsEdited, setIsEdited } from '../redux/reducers/dataReducer';
// import { useBeforeunload } from 'react-beforeunload';
import { useLocation } from "react-router-dom";
import { FormEdit } from '../context';
import DateRange from './Form/FormComponents/DateRange';
import ShareSocialIcons from './Form/ShareIcons/ShareSocialIcons';
import { setChangeSuccessStatus, setFormChangeName } from '../redux/reducers/formReducer';

const SortableItem = sortableElement(({ value, page, row, helperClass }) => <li page={page} className={helperClass} row={row}>{value}</li>);

const SortableContainer = sortableContainer(({ children, helperClass }) => {
	return <ul className='form-content-row-liner-ul'>{children}</ul>;
});
const NewForm = (props) => {
	const location = useLocation();
	const params = useParams();
	const form_id = params.id;
	const [clickedPreview, setClickedPreview] = useState(false)
	// const location = useLocation();
	const dispatch = useDispatch()
	// useEffect(() => {
	// 	console.log(location);
	// 	// window.onbeforeunload = function(event) {
	// 	// 	if (isEdited && window.location.pathname.substring(0, 6) === "/form/"){
	// 	// 		if (typeof event == 'undefined') {
	// 	// 			event = window.event;
	// 	// 		}
	// 	// 		if (event) {
	// 	// 			event.returnValue = 'asdasdasdsadsadsa';
	// 	// 		}
	// 	// 		return 'asdasdasdsadsadsa';
	// 	// 	}
	// 	// }
	// }, [location]);
	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setShowSucces(true)
			setShowError(false)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setShowSucces(false)
			setShowError(true)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}

	const [showSucces, setShowSucces] = useState(false)
	const [showError, setShowError] = useState(false)
	const isEdited = useSelector(getIsEdited)
	const [isEditedEmpty, setIsEditedEmpty] = useState(false)
	const myInfo = useSelector(getInfoData)

	// var isEdited = false
	useEffect(() => {
		let data = {};
		data.id = form_id;
		axios.post(API_BASE_URL + '/api/checkForm', data, AxiosConfigs)
			.then(response => { })
			.catch(error => {
				window.location.href = window.location.origin + '/'
			})
	}, [])
	const [loader, setLoader] = useState(true)
	const { myInfoDetails, setMyInfoDetails } = props;
	const [checkAdd, setCheckAdd] = useState({});
	// const [formDataUpdates,setFormDataUpdates] = useState({
	// 	items: []
	// });

	const [formDataUpdates, setFormDataUpdates] = useState([]);
	const [formDataUpdatesAnother, setFormDataUpdatesAnother] = useState([]);
	const [formData, setFormData] = useState([
		{
			id: 1,
			title: 'Heading',
			color: 'default',
			opacity: '1',
			colors: [],
			rows: [
				{
					id: 1,
					content: [
						{
							cols: [
								{
									id: 1,
									tabname: '',
									empty: true,
									uniqueId: randomString(50),
									content: [],
								}
							],
						}
					]
				}
			],
			button: true,
		}
	]);
	document.title = `FormBuilder - Form ${formData[0] ? formData[0].title : 'Heading'}`;

	const [titleInForm, setTitleInForm] = useState('');
	const [formDetails, setFormDetails] = useState({})
	const [header, setHeader] = useState('');
	const [position, setPosition] = useState({ top: 0, left: 0 })
	const [visibility, setVisibility] = useState(false)
	const [settingsData, setSettingsData] = useState({
		info: {
			page: 0,
			row: 0,
			col: 0
		},
		details: {}
	})
	const scrollToTop = useRef()
	const windowSetPosition = e => {
		window.scroll({
			top: position.top,
			left: position.left,
			behavior: 'smooth'
		})
	}
	// useEffect(() => {
	// 	let idNamePage = document.getElementById('newFormPage')
	// 	console.log(idNamePage,12);
	// 	if(idNamePage){
	// 		// if (scrollToTop.current && window.location.pathname.includes('/form/')) {
	// 		if (scrollToTop.current) {
	// 			console.log(scrollToTop.current,11212);
	// 			window.addEventListener('scroll', (e) => {
	// 				window.scrollY > 200
	// 					? scrollToTop.current.classList.add('active')
	// 					: scrollToTop.current.classList.remove('active')
	// 			})
	// 		}
	// 		console.log(idNamePage);
	// 	}
	// })
	const editableHeader = useRef()
	const saveText = useRef();
	const messageEdit = useRef();
	const [editedMessage, setEditedMessage] = useState('Successfully saved!')
	const formContentBody = useRef();
	const [text, setText] = useState('');
	const [colorsData, setColorsData] = useState();
	const [scrollTop, setScrollTop] = useState(0);
	const [tabData, setTabData] = useState({});
	const [tabName, setTabName] = useState('');
	const [draged, setDraged] = useState(false);
	const [dropType, setDropType] = useState('');
	const [rectPosition, setRectPosition] = useState({
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	});
	const [color, setColor] = useState('');
	const [activeLinkCopy, setActiveLinkCopy] = useState(false)
	const changeTextToButton = e => {
		e.preventDefault()
		setText(e.currentTarget.textContent);
		e.currentTarget.textContent = 'Remove Page';
		e.currentTarget.classList.add('active')
	}
	const changeButtonToText = e => {
		e.preventDefault()
		e.currentTarget.textContent = text;
		e.currentTarget.classList.remove('active')
	}
	useEffect(() => {
		let data = {};
		data.id = form_id;
		axios.post(API_BASE_URL + '/api/getForm', data)
			.then(response => {
				if (response.data.length > 0) {
					setTitleInForm(response.data[0][0].title);
					setFormData(response.data[0])
				}
				if (response.data.length > 1) {
					setHeader(response.data[1].header)
					setFormDetails(response.data[1])
					dispatch(setChangeSuccessStatus(response.data[1]))
					// setColorsData(response.data)
				}
				setLoader(false)
			})
			.catch(error => { })
	}, [form_id])

	const saveForm = (ev, formDataNow) => {
		try {
			if (trim(formData[0]?.title).length === 0 || formData[0]?.title?.length === 0) {
				// ev.preventDefault()
				showMessageError("Please input the form name")
			} else {
				var formCopy = formDataNow ? formDataNow : JSON.parse(JSON.stringify(formData))
				var formCopyEdit = formDataNow ? formDataNow : JSON.parse(JSON.stringify(formData))
				// setFormData([])
				let data = new FormData();
				var blobs = []
				// for (var key in formDataEdited) { data.append(key, formDataEdited[key]); }
				if (Object.values(formDataUpdates).length > 0) {
					Object.values(formDataUpdates).map(e => {
						formCopy[e.page].rows[e.row].content[0].cols[e.col].content = e.content
						if (e.content[0].src) {
							blobs.push([e.content[0].realSrc, e.content[0].srcOld, e.content[0].src])
						}
					})
				}
				// if (Object.values(formDataUpdatesAnother).length > 0) {
				// 	Object.values(formDataUpdatesAnother).map(e => {
				// 		if (formCopy[e.page].rows[e.row].content[0].cols[e.col].content.length == 0) {
				// 			if (e.content[0].inLabel) {
				// 				formCopy[e.page].rows[e.row].content[0].cols[e.col].content = {
				// 					label: e.content[0].label,
				// 					inLabel: e.content[0].inLabel,
				// 				}
				// 			}
				// 			formCopy[e.page].rows[e.row].content[0].cols[e.col].content = { label: e.content[0].label }
				// 		} else {
				// 			if (e.content[0].inLabel) {
				// 				formCopy[e.page].rows[e.row].content[0].cols[e.col].content['label'] = e.content[0].label
				// 				formCopy[e.page].rows[e.row].content[0].cols[e.col].content['inLabel'] = e.content[0].inLabel
				// 			}
				// 			formCopy[e.page].rows[e.row].content[0].cols[e.col].content['label'] = e.content[0].label
				// 		}
				// 	})
				// }
				// // console.log(formDataUpdatesAnother);

				for (let i = 0; i < blobs.length; i++) {
					const thisBlob = blobs[i]
					data.append('blob_' + thisBlob[1].replace(/ /g, '-'), thisBlob[0]);
				}
				setTitleInForm(formData[0].title)
				const userFormlabels = []
				const emptyFields = []
				for (let i = 0; i < formCopy.length; i++) {
					const forJ = formCopy[i].rows;
					for (let j = 0; j < forJ.length; j++) {
						const forK = forJ[j].content[0].cols
						for (let k = 0; k < forK.length; k++) {
							if (forK[k].content.hasOwnProperty('disabled')) {
								delete formCopy[i].rows[j].content[0].cols[k].content.disabled
							}
							if (forK[k].empty == true) {
								emptyFields.push({
									page: i,
									row: j,
									col: k
								})
							}
							else if (forK[k].tabname != 'image' && forK[k].tabname != '') {
								userFormlabels.push({ 'label': forK[k].content['label'], 'uniqueId': forK[k].uniqueId, 'type': forK[k].tabname });
							}
						}
					}
				}
				// document.getElementsByClassName('form-content-row-drag')[0].classList.remove('emptyField');
				if (emptyFields.length === 1 && formData[0]['rows'][0]['content'][0]['cols'][0]['tabname'] === "") {
					if(formData[0]['rows'][0]['content'][0]['cols'][0]['tabname'] === "" &&  formData[0]['rows'].length > 1){
						showMessageError('You have an empty field. Please remove the field or add any from the left bar')
						emptyFields.map(e => {
							document.querySelectorAll(`[page="${e.page}"] [row="${e.row}"] [col="${e.col}"]`)[0].classList.add('emptyField')
						})
					}
					else if (clickedPreview) {
						window.open(
							APP_BASE_URL + '/preview/' + formDetails._id, "_blank")
						setClickedPreview(false)
					} else {
						data.append('title', titleInForm);
						data.append('formData', JSON.stringify(formCopy));
						data.append('form_id', form_id);
						data.append('form_header', header)
						data.append('userFormlabels', JSON.stringify(userFormlabels));
						axios.post(API_BASE_URL + '/api/saveForm', data)
							.then(response => {
								setFormData(response.data[0])
								setFormDetails(response.data[1])
								dispatch(setIsEdited(false))
								window.onbeforeunload = null;

								showMessageSuccess('Successfully saved.')
							})
							.catch(error => {
								// showMessageError('Error on save.')
							})
					}
				}
				else {
					if (emptyFields.length == 1) {
						showMessageError('You have an empty field. Please remove the field or add any from the left bar')
						emptyFields.map(e => {
							document.querySelectorAll(`[page="${e.page}"] [row="${e.row}"] [col="${e.col}"]`)[0].classList.add('emptyField')
						})
					}
					else if (emptyFields.length > 1) {
						showMessageError('You have empty fields. Please remove fields or add any from the left bar')
						emptyFields.map(e => {
							document.querySelectorAll(`[page="${e.page}"] [row="${e.row}"] [col="${e.col}"]`)[0].classList.add('emptyField')
						})
					} else {
						if (clickedPreview) {
							window.open(
								APP_BASE_URL + '/preview/' + formDetails._id, "_blank")
							setClickedPreview(false)
						} else {
							dispatch(setFormChangeName({id:formDetails._id, title:formData[0].title}))
							data.append('title', titleInForm);
							data.append('formData', JSON.stringify(formCopy));
							data.append('form_id', form_id);
							data.append('form_header', header)
							data.append('userFormlabels', JSON.stringify(userFormlabels));
							axios.post(API_BASE_URL + '/api/saveForm', data)
								.then(response => {
									setFormData(response.data[0])
									setFormDetails(response.data[1])
									dispatch(setIsEdited(false))
									window.onbeforeunload = null;

									showMessageSuccess('Successfully saved.')
								})
								.catch(error => {
									// showMessageError('Error on save.')
								})
						}
					}
				}
			}
		} catch (error) {
			showMessageError('Something went wrong.')
		}

	}

	const changeTitle = e => {
		// isEdited = true
		dispatch(setIsEdited(true))
		var formCopy = JSON.parse(JSON.stringify(formData))
		setFormData([])
		formCopy[0].title = e.target.value
		setFormData(formCopy)
	}
	const changeHeader = e => {
		dispatch(setIsEdited(true))
		setHeader(e.target.value)
	}
	// useEffect(() => {
	// 	if (formDataUpdates.length > 0 || formDataUpdatesAnother.length > 0) {
	// 		setTimeout(() => {
	// 			var formCopy = JSON.parse(JSON.stringify(formData))
	// 			let data = new FormData();
	// 			var blobs = []
	// 			// for (var key in formDataEdited) { data.append(key, formDataEdited[key]); }
	// 			if (Object.values(formDataUpdates).length > 0) {
	// 				Object.values(formDataUpdates).map(e => {
	// 					formCopy[e.page].rows[e.row].content[0].cols[e.col].content = e.content
	// 					if (e.content[0].src) {
	// 						blobs.push([e.content[0].realSrc, e.content[0].srcOld, e.content[0].src])
	// 					}
	// 				})
	// 			}
	// 			if (Object.values(formDataUpdatesAnother).length > 0) {
	// 				Object.values(formDataUpdatesAnother).map(e => {
	// 					if (formCopy[e.page].rows[e.row].content[0].cols[e.col].content.length == 0) {
	// 						formCopy[e.page].rows[e.row].content[0].cols[e.col].content = { label: e.content[0].label }
	// 					} else {
	// 						formCopy[e.page].rows[e.row].content[0].cols[e.col].content['label'] = e.content[0].label
	// 					}
	// 				})
	// 			}
	// 			// // console.log(formDataUpdatesAnother);
	// 			data.append('formData', JSON.stringify(formCopy));
	// 			data.append('form_id', form_id);
	// 			for (let i = 0; i < blobs.length; i++) {
	// 				const thisBlob = blobs[i]
	// 				data.append('blob_' + thisBlob[1].replace(' ', '_'), thisBlob[0]);
	// 			}
	// 			setFormData(formCopy)
	// 			showMessageSuccess('Automatically saved.')
	// 			// axios.post(API_BASE_URL + '/api/saveForm', data)
	// 			// 	.then(response => {
	// 			// 		setFormData(formCopy)
	// 			// 		showMessageSuccess('Successfully saved.')
	// 			// 	})
	// 			// 	.catch(error => {
	// 			// 		showMessageError('Error on save.')
	// 			// 	})
	// 		}, 5000)
	// 	}
	// }, [formDataUpdates, formDataUpdatesAnother,])

	// const changeHeader = e => {
	// 	let HeadName = 
	// 	setHeader()
	// 	console.log(header,1);
	// }

	const publishForm = e => {
		e.preventDefault()
	}

	const previewForm = e => {
		e.preventDefault()
	}
	const moveThis = e => {
		e.preventDefault();
		e.currentTarget.classList.add('active');
	}
	const addRow = e => {
		e.preventDefault();
		try {
			var pageNumber = +e.currentTarget.getAttribute('page')
			var formCopy = JSON.parse(JSON.stringify(formData))
			if (formCopy[pageNumber].rows.length < 10) {
				setFormData([])
				var newRow = <Row key={formCopy[pageNumber].rows.length + 1} />;
				formCopy[pageNumber].rows.push({
					id: formCopy[pageNumber].rows.length + 1,
					content: [
						{
							cols: [
								{
									id: 1,
									tabname: '',
									empty: true,
									uniqueId: randomString(50),
									content: [],
								}
							],
						}
					]
				})
				setFormData(formCopy)
				// saveForm(e, formCopy)
			} else {
				showMessageError('Page is full, create a new page.')
			}
			// var checkNewPage = false;
			// var countPages = 0;

			// for (let i = 0; i < formCopy.length; i++) {
			// 	countPages++;
			// 	if (formCopy[i].rows.length < 5) {
			// setFormData([])
			// var newRow = <Row key={formCopy[i].rows.length + 1} />;
			// formCopy[i].rows.push({
			// 	id: formCopy[i].rows.length + 1,
			// 	content: [
			// 		{
			// 			cols: [
			// 				{
			// 					id: 1,
			// 					tabname: '',
			// 					content: [],
			// 				}
			// 			],
			// 		}
			// 	]
			// })
			// setFormData(formCopy)
			// break;
			// 	}
			// 	if (countPages == formData.length) {
			// 		checkNewPage = true;
			// 	}
			// }
			// if (checkNewPage) {
			// 	formCopy[formCopy.length - 1].button = false;
			// 	var newPage =
			// 	{
			// 		id: formCopy.length + 1,
			// 		rows: [
			// 			{
			// 				id: 1,
			// 				content: [
			// 					{
			// 						cols: [
			// 							{
			// 								id: 1,
			// 								tabname: '',
			// 								content: [],
			// 							}
			// 						],
			// 					}
			// 				]
			// 			}
			// 		],
			// 		button: true
			// 	};
			// 	formCopy.push(newPage)
			// 	setFormData(formCopy)
			// }
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const removePage = e => {
		e.preventDefault();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var currentTarget = e.currentTarget;
			var page = +currentTarget.getAttribute('pagenumber');
			if (page == formCopy.length - 1) {
				if (formCopy[page].rows[0].content[0].cols[0].tabname != '') {
					Swal.fire({
						title: 'Are you sure?',
						text: 'This page will be removed and it will not be possible to restore',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#dc3545',
						confirmButtonText: 'Delete',
						cancelButtonText: 'Close',
						customClass: {
							cancelButton: 'btn-outline-dark-default'
						}
					}).then((result, e) => {
						if (result.isConfirmed) {
							// setFormData([])
							const lastPageNumbers = document.querySelectorAll('.page-button')
							lastPageNumbers[lastPageNumbers.length - 2].classList.add('active')
							const lastFormNumbers = document.querySelectorAll('.form-page')
							lastFormNumbers[lastFormNumbers.length - 2].classList.add('active', 'show')
							var newFormCopy = formCopy.splice(page, 1);
							setFormData(formCopy)
							Swal.fire({
								icon: 'error',
								title: 'Page deleted',
								showConfirmButton: false,
								timer: 1500
							})
							// saveForm(e, formCopy)
							showMessage('Page is removed.')
						}
					})
				} else {
					const lastPageNumbers = document.querySelectorAll('.page-button')
					lastPageNumbers[lastPageNumbers.length - 2].classList.add('active')
					const lastFormNumbers = document.querySelectorAll('.form-page')
					lastFormNumbers[lastFormNumbers.length - 2].classList.add('active', 'show')
					var newFormCopy = formCopy.splice(page, 1);
					setFormData(formCopy)
					// saveForm(e, formCopy)
					showMessage('Page is removed.')
				}
			} else {
				if (formCopy[page].rows[0].content[0].cols[0].tabname != '') {
					Swal.fire({
						title: 'Are you sure?',
						text: 'This page will be removed and it will not be possible to restore',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#dc3545',
						customClass: {
							cancelButton: 'btn-outline-dark-default'
						},
						confirmButtonText: 'Delete',
						cancelButtonText: 'Close'
					}).then((result, e) => {
						if (result.isConfirmed) {
							// setFormData([])
							var newFormCopy = formCopy.splice(page, 1);
							setFormData(formCopy)
							Swal.fire({
								icon: 'error',
								title: 'Page deleted',
								showConfirmButton: false,
								timer: 1500
							})
							// saveForm(e, formCopy)
							showMessage('Page is removed.')
						}
					})
				} else {
					var newFormCopy = formCopy.splice(page, 1);
					setFormData(formCopy)
					// saveForm(e, formCopy)
					showMessage('Page is removed.')
				}
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}

	}
	const addNewPage = e => {
		e.preventDefault();
		let i = formData.length-1;
			if(formData[i].rows[0].content[0].cols[0].empty == true){
				showMessageError('You have an empty page.')
			}else {
				try {
					const activePageNumber = document.querySelectorAll('.form-page.active')[0].getAttribute('page')
					var formCopy = JSON.parse(JSON.stringify(formData))
					if(formCopy[activePageNumber].rows.length >= 1){
						setFormData([])
						var page = formCopy.length;
						var newPage = {
							id: page + 2,
							rows: [
								{
									id: 1,
									content: [
										{
											cols: [
												{
													id: 1,
													tabname: '',
													empty: true,
													uniqueId: randomString(50),
													content: [],
												}
											],
										}
									]
								}
							],
							// button: page + 1 == formCopy.length ? true : false
							button: true
						}
					
					// if (page + 1 == formCopy.length) {
					// 	formCopy[page].button = false;
					// }
				
					formCopy.splice(page + 1, 0, newPage)
					formCopy = formCopy.map(function (item, index) {
						item.id = index + 1;
						return item;
					});
					setFormData(formCopy)
					// saveForm(e, formCopy)
					setTimeout(() => {
						const lastPageNumbers = document.querySelectorAll('.page-button:not(.page-button-arrow)')
						for (let i = 0; i < lastPageNumbers.length; i++) {
							lastPageNumbers[i].classList.remove('active')
						}
						lastPageNumbers[lastPageNumbers.length - 1].classList.add('active')
						const lastFormNumbers = document.querySelectorAll('.form-page')
						for (let i = 0; i < lastFormNumbers.length; i++) {
							lastFormNumbers[i].classList.remove('active', 'show')
						}
						lastFormNumbers[lastFormNumbers.length - 1].classList.add('active', 'show')
					}, 10)
					showMessageSuccess('A new page is added')
					dispatch(setIsEdited(true))
				}else{
					showMessageError('You have an empty page.')
				}
				} catch (error) {
					showMessageError('something went wrong')
				}

			}
	}
	const nextPage = e => {
		e.preventDefault()
		try {
			const lastPageNumbers = document.querySelectorAll('.page-button:not(.page-button-arrow)')
			const lastPageNumbersActive = document.querySelectorAll('.page-button.active')[0]
			var lastPageNumber = +lastPageNumbersActive.getAttribute('page')
			if (lastPageNumber + 1 < lastPageNumbers.length) {
				// e.currentTarget.classList.remove('no-more')
				for (let i = 0; i < lastPageNumbers.length; i++) { lastPageNumbers[i].classList.remove('active') }
				lastPageNumbers[lastPageNumber + 1].classList.add('active')
				const lastFormNumbers = document.querySelectorAll('.form-page')
				const lastFormNumbersActive = document.querySelectorAll('.form-page.active')[0]
				var lastFormNumber = +lastFormNumbersActive.getAttribute('page')
				for (let i = 0; i < lastFormNumbers.length; i++) {
					lastFormNumbers[i].classList.remove('active', 'show')
				}
				lastFormNumbers[lastFormNumber + 2].classList.add('active', 'show')
			}
			// else {
			// 	e.currentTarget.classList.add('no-more')
			// }

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const prevPage = e => {
		e.preventDefault()
		try {
			const lastPageNumbers = document.querySelectorAll('.page-button')
			const lastPageNumbersActive = document.querySelectorAll('.page-button.active')[0]
			var lastPageNumber = +lastPageNumbersActive.getAttribute('page')
			if (lastPageNumber + 1 > 1) {
				// e.currentTarget.classList.remove('no-more')
				for (let i = 0; i < lastPageNumbers.length; i++) { lastPageNumbers[i].classList.remove('active') }
				lastPageNumbers[lastPageNumber].classList.add('active')
				const lastFormNumbers = document.querySelectorAll('.form-page')
				const lastFormNumbersActive = document.querySelectorAll('.form-page.active')[0]
				var lastFormNumber = +lastFormNumbersActive.getAttribute('page')
				for (let i = 0; i < lastFormNumbers.length; i++) {
					lastFormNumbers[i].classList.remove('active', 'show')
				}
				lastFormNumbers[lastFormNumber].classList.add('active', 'show')
			}
			// else {
			// 	e.currentTarget.classList.add('no-more')
			// }
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const [dragData, setDragData] = useState([0, 0]);
	const [dragElement, setDragElement] = useState([]);

	const onSortStart = (ev) => {
		document.body.style.cursor = 'grabbing'
		setDragData([+ev.helper.getAttribute('page'), +ev.helper.getAttribute('row')]);
		setDropType('row');
	}

	function arraymove(arr, fromIndex, toIndex) {
		try {
			const element = JSON.parse(JSON.stringify(arr[fromIndex]));
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			// saveForm(null, arr)
			// arr.sort(function(a, b) { 
			// 	return a.id - b.id;
			// });
			// console.log(arr, fromIndex, toIndex);
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const onSortMove = e => {
		const nowTarget = e.target
		// const lines = document.querySelectorAll('.form-content-row-line')
		// for (let i = 0; i < lines.length; i++) {
		// 	lines[i].classList.remove('active')
		// 	lines[i].classList.remove('after')newFormData
		// }
		// if (nowTarget.classList.contains('forDragAnimation')) {
		// 	nowTarget.closest('.form-content-row-body').nextSibling.classList.add('active')
		// 	nowTarget.closest('.form-content-row-body').nextSibling.classList.add('after')
		// }
	}

	const onSortEnd = (ev) => {
		try {
			document.body.style.cursor = 'default'
			if (dropType === 'row') {
				const oldIndex = ev.oldIndex;
				const newIndex = ev.newIndex;
				const dragPageIndex = dragData[0];
				const newFormData = JSON.parse(JSON.stringify(formData));
				// const oldElement = JSON.parse(JSON.stringify(newFormData[dragPageIndex].rows[oldIndex]))
				// const newElement = JSON.parse(JSON.stringify(newFormData[dragPageIndex].rows[newIndex]))
				// newFormData[dragPageIndex].rows[oldIndex]['content'] = newElement['content']
				// newFormData[dragPageIndex].rows[newIndex]['content'] = oldElement['content']
				if (oldIndex != newIndex) {
					// setFormData([])
					arraymove(newFormData[dragPageIndex].rows, oldIndex, newIndex)
					setFormData(newFormData);
				}
				// const lines = document.querySelectorAll('.form-content-row-line')
				// for (let i = 0; i < lines.length; i++) {
				// 	lines[i].classList.remove('active')
				// 	lines[i].classList.remove('after')
				// }

			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	};

	const handleDropNavs = (ev, page, row, col) => {
		try {
			const dropPageIndex = +page;
			const dropRowIndex = +row;
			const dropColIndex = +col;
			const newFormData = JSON.parse(JSON.stringify(formData));
			// var uploadError = false;
			// if (tabName === 'upload') {
			// 	var details = formDetails.details
			// 	if (details) {
			// 		for (var i = 0; i < details.length; i++) {
			// 			if (details[i].type == 'upload') {
			// 				uploadError = true;
			// 				break;
			// 			}
			// 		}
			// 	}
			// 	loop: for (let i = 0; i < newFormData.length; i++) {
			// 		const forJ = newFormData[i].rows;
			// 		for (let j = 0; j < forJ.length; j++) {
			// 			const forK = forJ[j].content[0].cols
			// 			for (let k = 0; k < forK.length; k++) {
			// 				if (forK[k].tabname == 'upload') {
			// 					uploadError = true;
			// 					break loop;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			// if (uploadError) {
			// 	showMessageError('On form already has a upload file.')
			// } else {
			// setFormData([]);

			if (tabName === 'dropdown') {
				let obj = {
					options: [
						{ id: 1, value: 'option1', label: 'Option1' },
						{ id: 2, value: 'option2', label: 'Option2' },
						{ id: 3, value: 'option3', label: 'Option3' },
						{ id: 4, value: 'option4', label: 'Option4' },
					],
				}
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Dropdown';
			}
			if (tabName != 'image') {
				if ((Object).keys(newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content).length) {
					newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.required = false;
				} else {
					newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = { required: false }
				}
			}
			if (tabName === 'input') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.typeinput = 'text';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Short Text';
			}
			if (tabName === 'rating') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Rating';
			}
			if (tabName === 'checkbox') {
				let obj = {
					options: [
						{ id: 1, value: 'option1', label: 'Option1' },
						{ id: 2, value: 'option2', label: 'Option2' },
						{ id: 3, value: 'option3', label: 'Option3' },
						{ id: 4, value: 'option4', label: 'Option4' },
					],
				}
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Multiple Choice';
			}
			if (tabName === 'radio') {
				let obj = {
					options: [
						{ id: 1, value: 'option1', label: 'Option1' },
						{ id: 2, value: 'option2', label: 'Option2' },
						{ id: 3, value: 'option3', label: 'Option3' },
						{ id: 4, value: 'option4', label: 'Option4' },
					],
				}
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Single Choice';
			}
			if (tabName === 'text') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Long Text';
			}
			if (tabName === 'paragraph') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Paragraph';
			}
			if (tabName === 'image') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].width = 'auto';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].height = 'auto';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].fit = 'cover';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].align = 'center';
			}
			if (tabName === 'upload') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'File Upload';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.limit = 1;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.size = {
					min: 0,
					max: 10,
				};
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.text = 'Select files';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.reachable = 'pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif, webp';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.types = 'pdf, doc, docx, jpg, jpeg, png, webp';
			}
			if (tabName === 'empty') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Empty';
			}
			if (tabName === 'divider') {
				if (newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols.length == 1 && newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[0].tabname == '') {
					newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Divider';
				} else {
					return showMessageError("There can't be other elements with a divider")
				}
			}
			if (tabName === 'date') {
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Date Picker';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].separator = '/';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultDateLabel = 'None';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultDateValue = '';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].checkedTime = false;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].timeFormat = '24';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultTimeLabel = 'None';
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultTimeValue = 'HH:MM';
				var sep = newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].separator;
				newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].typeDate = 'dd' + sep + 'MM' + sep + 'yyyy';
			}
			newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].tabname = tabName;
			newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].empty = false;
			newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].uniqueId = randomString(50);
			setFormData(newFormData);
			// saveForm(ev, newFormData)
			dispatch(setIsEdited(true))

			setTimeout(() => {
				const activeLabel = document.querySelector(`.form-content-row-drag[page='${dropPageIndex}'][row='${dropRowIndex}'][col='${dropColIndex}']`)
				if (activeLabel) {
					const activeText = activeLabel.querySelector('.user-select-text')
					if (activeText) {
						var p = activeText,
							s = window.getSelection(),
							r = document.createRange();
						r.setStart(p, 1);
						r.setEnd(p, 1);
						s.removeAllRanges();
						s.addRange(r);
					}
				}
			}, 1);
			// }

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	};

	// useEffect(() => {
	// 	if (document.documentElement) {
	// 		window.addEventListener('scroll', function (e) {
	// 			if (window.location.pathname.substring(0, 6) === "/form/"){
	// 				if (window.innerWidth > 1400) {
	// 					var scrollYpos = document.documentElement.scrollTop;
	// 					if (scrollYpos > 25) { 
	// 						formNavbarBody.current.style.cssText = `
	// 						top: 91px;`;
	// 						// setScrollTop(scrollYpos - 100); 
	// 					}
	// 					else { 
	// 						formNavbarBody.current.style.cssText = `
	// 						top: 199px;`;
	// 						// setScrollTop(0); 
	// 					}
	// 				}
	// 			}
	// 		});
	// 	}
	// }, [])

	window.onbeforeunload = function (event) {
		if (isEdited && window.location.pathname.substring(0, 6) === "/form/") {
			if (typeof event == 'undefined') {
				event = window.event;
			}
			if (event) {
				event.returnValue = 'asdasdasdsadsadsa';
			}
			return 'asdasdasdsadsadsa';
		}
	}

	const forContent = useRef()
	const forContentBody = useRef()
	const formNavbarBody = useRef()
	const formNavbarClosed = useRef()
	const toggleForm = e => {
		e.preventDefault();
		e.target.offsetParent.classList.add('disActive')
		formNavbarBody.current.classList.add('disActive')
	}
	const toggleNavbar = e => {
		e.preventDefault();
		formNavbarBody.current.classList.remove('disActive')
		formNavbarClosed.current.classList.remove('disActive')
	}

	const clickSave = useRef()
	useEffect(() => {
		document.addEventListener('keydown', e => {
			if (clickSave.current) {
				if (e.ctrlKey && e.key === 's') {
					e.preventDefault();
					clickSave.current.click()
				} else if (e.ctrlKey && e.key === 'ս') {
					e.preventDefault();
					clickSave.current.click()
				} else if (e.ctrlKey && e.key === 'с') {
					e.preventDefault();
					clickSave.current.click()
				}
			}
		});
	}, [])

	const saveWithPreview = e => {
		e.preventDefault()
		setTimeout(() => {
			clickSave.current.click()
		}, 50);
		setClickedPreview(true)
		// useEffect(() => {
		// 	if (previewSave) {
		// 		window.open(
		// 			APP_BASE_URL + '/preview/' + formDetails.link, "_blank")
		// 	}
		// }, [previewSave])
		// window.location.href = APP_BASE_URL + '/form/' + formDetails.link
	}

	// useEffect(() => {
	// 	document.addEventListener('contextmenu', event => event.preventDefault());
	// })
	// document.addEventListener("contextmenu", (event) => {
	// 	event.preventDefault();
	// 	const xPos = event.pageX + "px";
	// 	const yPos = event.pageY + "px";
	// 	//
	// });
	let changeTextRef = useRef(null);
	const [countRows, setCountRows] = useState(-1)
	const [copyButton, setCopyButton] = useState({ copied: 'Link Copied!', notCopied: 'Copy Link' })
	const copyLink = (e, button) => {
		e.preventDefault()
		var currentTarget = e.currentTarget
		var forThisChangeText = currentTarget.querySelectorAll('.forChangeText')[0]
		var forChangeIcon = currentTarget.closest('.form-link-buttons').querySelectorAll('.forChangeIcon')[0]
		var forChangeText = currentTarget.closest('.form-link-buttons').querySelectorAll('.forChangeText')[0]
		var formLinkButtonTitle = currentTarget.closest('.form-link-buttons').querySelectorAll('.form-link-button-title')[0]
		// navigator.clipboard.writeText(e.currentTarget.getAttribute('link'))
		if (button) {
			forThisChangeText.classList.toggle('copied')
			formLinkButtonTitle.classList.toggle('copied')
			if (forChangeIcon.classList.contains('fa-link')) {
				forChangeIcon.classList.remove('fa-link')
				forChangeIcon.classList.add('fa-check', 'copied')
			} else {
				forChangeIcon.classList.remove('fa-check', 'copied')
				forChangeIcon.classList.add('fa-link')
			}

			setTimeout((e) => {
				forThisChangeText.classList.remove('copied')
				forChangeIcon.classList.remove('fa-check', 'copied')
				forChangeIcon.classList.add('fa-link')
				formLinkButtonTitle.classList.remove('copied')
			}, 500);
		} else {
			forChangeText.classList.toggle('copied')
			formLinkButtonTitle.classList.toggle('copied')
			if (forChangeIcon.classList.contains('fa-link')) {
				forChangeIcon.classList.remove('fa-link')
				forChangeIcon.classList.add('fa-check', 'copied')
			} else {
				forChangeIcon.classList.remove('fa-check', 'copied')
				forChangeIcon.classList.add('fa-link')
			}

			setTimeout(() => {
				forChangeText.classList.remove('copied')
				currentTarget.closest('.form-link-buttons').querySelectorAll('.form-link-button-title')[0].classList.remove('copied')
				forChangeIcon.classList.remove('fa-check', 'copied')
				forChangeIcon.classList.add('fa-link')
			}, 500);
		}
		var textField = document.createElement('textarea')
		textField.innerText = e.currentTarget.getAttribute('link')
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy')
		textField.remove()


	}
	const [hidedNavbar, setHidedNavbar] = useState(false)
	const hideNavbar = e => {
		setHidedNavbar(!hidedNavbar);
		formNavbarBody.current.classList.toggle('disHide')
	}
	const editBackgroundColor = e => {
		e.stopPropagation();
		// var formCopy = JSON.parse(JSON.stringify(formData))
		// console.log(formDetails,852);
		// var color = formCopy[0].color;
		setSettingsData({})
		
		var newSettingsData = {
			info: {
				form_id: form_id,
				settings: 'color',
			},
			details: {
				color: formDetails['color'],
				opacity: formDetails['opacity'],
				colors: formDetails['colors'],
				// colors: formCopy[0]['colors'],
				// opacity: colorsData[1]['opacity'],
			}
		}
		setSettingsData(newSettingsData)
		document.querySelector('body').classList.toggle('customize-box');
	}
	// useEffect(() => {
	// 	alert()
	// 	console.log("Location changed",location);
	// }, [location]);
	const shouldBlockNavigation = e => {
		// window.addEventListener("beforeunload", function (event) {
		// 	if (isEdited && window.location.pathname.substring(0, 6) === "/form/"){
		// 		console.log(4564564564)
		// 	}
		// });
		// window.onbeforeunload = function(event) {
		// 	if (isEdited && window.location.pathname.substring(0, 6) === "/form/"){
		// 		console.log()
		// // 		if (typeof event == 'undefined') {
		// // 			event = window.event;
		// // 		}
		// // 		if (event) {
		// // 			event.returnValue = 'asdasdasdsadsadsa';
		// // 		}
		// // 		return 'asdasdasdsadsadsa';
		// // 		// Swal.fire({
		// // 		// 	title: 'Are you sure?',
		// // 		// 	text: 'This form will be removed and it will not be possible to restore',
		// // 		// 	icon: 'warning',
		// // 		// 	showCancelButton: true,
		// // 		// 	confirmButtonColor: '#dc3545',
		// // 		// 	customClass: {
		// // 		// 		cancelButton: 'btn-outline-dark-default'
		// // 		// 	},
		// // 		// 	confirmButtonText: 'Delete',
		// // 		// 	cancelButtonText: 'Close'
		// // 		// }).then((result) => {
		// // 		// 	if (result.isConfirmed) {
		// // 		// 	}
		// // 		// })
		// 	}
		// }
	}
	// useEffect(() => {
	// 	http://localhost:3000/f-jecCv4(location);
	// 	// window.onbeforeunload = function(event) {
	// 	// 	if (isEdited && window.location.pathname.substring(0, 6) === "/form/"){
	// 	// 		if (typeof event == 'undefined') {
	// 	// 			event = window.event;
	// 	// 		}
	// 	// 		if (event) {
	// 	// 			event.returnValue = 'asdasdasdsadsadsa';
	// 	// 		}
	// 	// 		return 'asdasdasdsadsadsa';
	// 	// 	}
	// 	// }
	// }, [location]);
	useEffect(() => {
		formData[0]['rows'][0]['content'][0]['cols'][0]['tabname'] !== "" ? setActiveLinkCopy(true) : setActiveLinkCopy(false);
		formData[0]['rows'][0]['content'][0]['cols'][0]['tabname'] !== "" ? setIsEditedEmpty(true) : setIsEditedEmpty(false);
	}, [formData])
	return (
		<div id='newFormPage' className='form-loader-body newform-body'>
			{/* {
				isEdited ||!isEditedEmpty
				?
				<Prompt
					when={shouldBlockNavigation()}
					message='You have unsaved changes, are you sure you want to leave?'
				/>
				: null
			} */}
			
			{
				loader
					?
					<div className="form-loader">
						<Loader />
					</div>
					: null
			}
			{/* <ContextMenu style={{ top: xPos, left: yPos }} /> */}
			<div className={loader ? "px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header form-blur" : "px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header"}>
				<div className="container-fluid px-0">
					<div className="row align-items-center form-header-side">
						<div className="col">
							<h1 className="h2 mb-0 lh-sm">
								<input
									type="text"
									className='form_input form_input-name h2 h1 mb-0 lh-sm'
									value={formData[0] ? formData[0].title : 'Heading'}
									onChange={changeTitle}
								/>
								{/* {titleInForm?titleInForm:'Heading'} */}
							</h1>
						</div>
						{activeLinkCopy ?
							<div className='col-auto d-flex align-items-center my-2 my-sm-0 px-1 form-link-buttons'>
								<div className='form-link-button-link'><button type='button' link={APP_BASE_URL + '/f-' + formDetails.link} onClick={e => copyLink(e, 'button')}><p ref={changeTextRef} className='forChangeText'></p></button></div>
								<div className='form-link-button-body'>
									<div className="form-link-button-title form-link-button-title-newform form-link-button-title-hovered"></div>
									<button type='button' className='btn form-link-button' link={APP_BASE_URL + '/f-' + formDetails.link} onClick={e => copyLink(e)}><span><i className=" fas fa-link forChangeIcon"></i></span></button>
								</div>
							</div> :
							<div className='col-auto d-flex align-items-center my-2 my-sm-0 px-1 form-link-buttons'>
								<div className='form-link-button-link'><button className='cursor-default' type='button'><p ref={changeTextRef} className='forChangeTextDisabled slashIconLink'></p></button></div>
								<div>
									<div className="form-link-button-title form-link-button-title-newform form-link-button-title-hovered"></div>
									<button type='button' className='btn form-link-button cursor-default'>
										<span className="slashIconLink">
											<i className="fas fa-link"></i>
											{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">*/}
											{/*	<path d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4*/}
											{/*	318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3*/}
											{/*	281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99*/}
											{/*	320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404*/}
											{/*	255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741*/}
											{/*	392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6*/}
											{/*	193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8*/}
											{/*	262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5*/}
											{/*	108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5*/}
											{/*	59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z"/>*/}
											{/*</svg>*/}


											{/*<svg   className='slashIconLink' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">*/}
											{/*	<path d="M185.7 120.3C242.5 75.82 324.7 79.73 376.1 131.1C420.1 175.1*/}
											{/*		430.9 239.6 406.7 293.5L438.6 318.4L534.5 222.5C566 191 566 139.1 534.5*/}
											{/*		108.5C506.7 80.63 462.7 76.1 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1*/}
											{/*		384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.732 529.8*/}
											{/*		13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L489.3 358.2L630.8*/}
											{/*		469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2*/}
											{/*		506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065*/}
											{/*		38.81 5.112L185.7 120.3zM238.1 161.1L353.4 251.7C359.3 225.5 351.7 197.2 331.7*/}
											{/*		177.2C306.6 152.1 269.1 147 238.1 161.1V161.1zM263 380C233.1 350.1 218.7 309.8*/}
											{/*		220.9 270L406.6 416.4C357.4 431 301.9 418.9 263 380V380zM116.6 187.9L167.2*/}
											{/*		227.8L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435*/}
											{/*		209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5*/}
											{/*		452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3*/}
											{/*		3.741 300.7 60.21 244.3L116.6 187.9z"/>*/}
											{/*</svg>*/}
										</span>
									</button>
								</div>
							</div>
						}
						{/* <div className='col saved save-text' ref={saveText}>
							<p className='mb-0 font-weight-bold text-success'>Saved Successfully!!</p>
						</div> */}
						<div className="col-auto d-flex align-items-center my-2 my-sm-0 px-1 form-action-button">
							<button onClick={saveWithPreview} className="btn btn-lg preview-button-header" type="button">Preview</button>
						</div>
						{/* <div className="col-auto d-flex align-items-center my-2 my-sm-0 px-1">
							<button type='button' className="btn btn-lg btn-success" onClick={publishForm}><span>Publish</span>
							</button>
						</div> */}
						<div className="col-auto d-flex align-items-center my-2 my-sm-0 px-1 form-action-button">
							<button type='button' className="btn btn-lg btn-warning" onClick={saveForm} title="Ctrl + s" ref={clickSave}><span>Save</span>
							</button>
						</div>
						{/* <div className="col-auto d-flex align-items-center my-2 my-sm-0 px-1">
							<button type='button' className="btn palette-button" onClick={editBackgroundColor} title="Form background color"><span><i className="fas fa-palette"></i></span>
							</button>
						</div> */}

					</div>
				</div>
			</div >
			{/* <a href="javascript:void(0);" className="btn btn-dark btn-lg customize-btn"><span className="ps-2">Customize</span></a> */}
			<div className={loader ? "form-changes form-blur" : "form-changes"} ref={messageEdit} >
				{
					showSucces?
						<>
							<div className='img-div'>
								<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
										stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
							
						</>
					:
					showError?
						<>
							<div className='img-div'>
								<svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
										stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
						</>
					:null
				}
			</div>
			<div className={loader ? "p-3 p-xxl-3 form-blur" : "p-3 p-xxl-3"}>
				<div className="container-fluid px-0">
					<div className="row">
						<div className="col-12 col-xl-4 col-xxl-2 mb-3 animated-formNavbar">
							{/* {
								window.innerWidth < 1200
									? */}
							<div className="FormNavbar__closed" ref={formNavbarClosed}>
								<div className="FormNavbar__closed-body">
									<p className="FormNavbar__closed-text">Add Form <br /> Element</p>
									<button onClick={toggleForm} className="FormNavbar__closed-button">
										<span>+</span>
									</button>
								</div>
							</div>
							{/* : null
							} */}
							{/* {
								window.innerWidth > 1200 && window.innerWidth < 1400
									? */}
							<button className="hideNavbar" type="button" onClick={hideNavbar} title="hide">
								<span>
									{
										hidedNavbar
											?
											<img src="/svg/icons/list-solid.svg" alt="" />
											:
											<img src="/svg/icons/arrow-down-short-wide-solid.svg" alt="" />
									}
								</span>
							</button>
							{/* : null
							} */}
							<aside className='card border border-gray-200 rounded-12 mb-xl-4 animated-formNavbar-aside formnavbar-menu' ref={formNavbarBody}>
								{/* {
									window.innerWidth < 1200
										? */}
								<button className="toggleNavbar" type="button" onClick={toggleNavbar} title="hide">
									<span><i className="fas fa-arrow-left"></i></span>
								</button>
								{/* : null
								} */}
								<FormNavbar
									// setIsEdited={setIsEdited}
									formData={formData}
									setFormData={setFormData}
									formDetails={formDetails}
									setFormDetails={setFormDetails}
									tabData={tabData}
									setTabData={setTabData}
									setTabName={setTabName}
									setDropType={setDropType}
									dropType={dropType}
									setCheckAdd={setCheckAdd}
									checkAdd={checkAdd}
									showMessage={showMessage}
									showMessageSuccess={showMessageSuccess}
									showMessageError={showMessageError}
									saveForm={saveForm}
								/>
							</aside>
						</div>
						<div className="col-12 col-xxl-10">
							<form onSubmit={saveForm} tabname="Form" className="form-pages">
								<div className="form-page mb-xl-5">
									<div className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 form-body">
										<div className="tab-content" id="pills-tabContent-1">
											<div className="tab-pane fade p-4 py-1 show active" id="pills-result-1" role="tabpanel" aria-labelledby="pills-result-tab-1">
												<div className="d-flex justify-content-between align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200 form_input-header form-input">
													<input
														type="text"
														className='form_input'
														value={formDetails ? header : 'Heading'}
														onChange={changeHeader}
														ref={editableHeader}
													/>
													<div className="col-auto d-flex align-items-center my-2 my-sm-0 px-1">
														<div className="myForms-content-right right-myForms right-td-styles">
															<ShareSocialIcons  formLink={formDetails.link} formTitle={formDetails.title}/>
															<DateRange form_id={form_id} formDetails={formDetails} settingsData={settingsData} setSettingsData={setSettingsData} formData={formData} setFormData={setFormData} showMessage={showMessage} showMessageSuccess={showMessageSuccess} showMessageError={showMessageError} saveForm={saveForm} form={formDetails} setForm={setFormDetails}/>
															<button type='button' className="btn palette-button" onClick={editBackgroundColor} title="Form background color"><span><i className="fas fa-cog"></i></span>
															</button>
														</div>
													</div>
												</div>
												<div className="text-center p-4 py-4 pt-2 d-flex">
													{
														formData.length > 1
															?
															<ul className="nav nav-segment nav-pills page" role="tablist">
																<li className="page__numbers">
																	<button type='button' className="page-button page-button-arrow" onClick={prevPage}><span>«</span></button>
																</li>
																{
																	formData.map((form, key) => {
																		return <li className="page__numbers" key={key}>
																			<a className={key == 0 ? "page-button form-page-button active" : "page-button form-page-button"} page={key} data-bs-toggle="pill" href={"#page_" + key} role="tab" aria-selected={key == 0 ? "true" : "false"}>{key + 1}</a>
																		</li>
																		// return <li className="nav-item page-buttons" key={key}>
																		// 	<a className={key == 0 ? "page-link active page-button" : "page-link page-button"} page={key} data-bs-toggle="pill" href={"#page_" + key} role="tab" aria-selected="true">{key + 1}</a>
																		// </li>
																	})
																}
																<li className="page__numbers">
																	<button type='button' className="page-button page-button-arrow" onClick={nextPage}><span>»</span></button>
																</li>
															</ul>
															: null
													}
													<div className="form-page-new">
														<button type="button" onClick={addNewPage} title="Add new page"><span>+</span></button>
													</div>
												</div>
												<div className="tab-content">
													{
														formData.map((form, key) => {
															return <div className={key == 0 ? "tab-pane fade show active pt-3 form-page form-page-content" : "tab-pane pt-3 fade form-page form-page-content"} id={"page_" + key} role="tabpanel" key={key} page={key}>
																{
																	key != 0
																		?
																		<div className="page-number">
																			<button className='page-number-button'
																				pagenumber={key}
																				onMouseOver={changeTextToButton}
																				onMouseLeave={changeButtonToText}
																				onClick={removePage}
																			>Page {key + 1}
																			</button>
																		</div>
																		: null
																}
																<div className="settings-tab-content card rounded-12 mb-3 form-body">
																	<div className='form-content'>
																		<div className="form-content-body" ref={formContentBody}>
																			<SortableContainer
																				onSortStart={onSortStart}
																				onSortEnd={onSortEnd}
																				lockAxis={'y'}
																				lockToContainerEdges={true}
																				lockOffset={"0%"}
																			>
																				{
																					form.rows.map((bform, bkey) => {
																						return <SortableItem
																							key={bkey}
																							index={bkey}
																							page={key}
																							row={bkey}
																							helperclassName={'form-content-row-liner'}
																							value={
																								<Row
																									checkAdd={checkAdd}
																									setCheckAdd={setCheckAdd}
																									handleDropNavs={handleDropNavs}
																									page={key}
																									row={bkey}
																									contentRow={bform}
																									formData={formData}
																									setFormData={setFormData}
																									tabName={tabName}
																									dropType={dropType}
																									setDropType={setDropType}
																									preview={false}
																									formDataUpdates={formDataUpdates}
																									setFormDataUpdates={setFormDataUpdates}
																									formDataUpdatesAnother={formDataUpdatesAnother}
																									setFormDataUpdatesAnother={setFormDataUpdatesAnother}
																									formid={form_id}
																									settingsData={settingsData}
																									setSettingsData={setSettingsData}
																									saveForm={saveForm}
																									showMessageError={showMessageError}
																								/>
																							}>
																						</SortableItem>
																					})
																				}
																			</SortableContainer>
																		</div>
																	</div>
																</div>
																{/* {
																	form.rows.length < 10
																		? */}
																<div className="card-body px-0 px-md-4 py-0">
																	<div className="px-3 align-items-center justify-content-center">
																		<button className="addRow" page={key} onClick={addRow}>
																			<span className="">+</span>
																		</button>
																	</div>
																</div>
																{/* : null
																} */}
															</div>
														})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div >
			</div >
			<div className="scrollToTop" ref={scrollToTop}>
				<div className="scrollToTop-body">
					<div className="scrollToTop-button">
						<button type='button'
							onClick={windowSetPosition}
						><span><i className="fas fa-chevron-up"></i></span></button>
					</div>
				</div>
			</div>
			<FormSettings formDetails={formDetails} settingsData={settingsData} setSettingsData={setSettingsData} colorsData={colorsData} formData={formData} setFormData={setFormData} showMessage={showMessage} showMessageSuccess={showMessageSuccess} showMessageError={showMessageError} saveForm={saveForm} form={formDetails} setForm={setFormDetails} />
		</div >
	);
};

export default NewForm;