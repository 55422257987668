import React from 'react';
import { useSelector } from 'react-redux';
import { getGeneralInfoData, getInfoData, getInfoDataLoading } from '../../../redux/reducers/infoReducer';
import Loader from '../../../UI/Loader/Loader';

const GeneralEdit = (props) => {
	const { updateUserGeneralDetails, errorsGeneral, setErrorsGeneral, updateDataGeneral, setUpdateDataGeneral, generalChanged, setGeneralChanged } = props;
	const myInfo = useSelector(getInfoData)
	const loading = useSelector(getInfoDataLoading)
	const changeGeneral = e => {
		e.preventDefault();
		updateUserGeneralDetails();
		setTimeout(() => {
			setGeneralChanged(false)
		}, 2000)
		setErrorsGeneral([]);

	}
	return (
		<div tabname="General" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
			{myInfo?.blocked == 1 ?
				<div className="blocked-form-div">
					To edit your information, please make a payment first.
				</div>
				: null
			}
			<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
				<h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Profile</h5>
			</div>
			{
				generalChanged
					?
					// <div className="changed-password  px-md-4 ">
					// 	<p className="">Your information is successfully changed</p>
					// </div>

					<div className="add-members-messages changed-password">
							<div className='img-div'>
								<svg className='img-icon' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125" stroke="#16CF96" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className='succes-div'>
								<p>Your information is successfully changed</p>
							</div>
					</div>
					: null
			}
			<div className="card-body px-0 p-md-4">
				{
					loading
						?
						<Loader />
						:
						<form className="px-3 form-style-two" onSubmit={e => myInfo?.blocked == 1 ? e.preventDefault() : changeGeneral(e)}>
							<div className="row">
								<div className="col-sm-6 mb-md-4 pb-3">
									<label htmlFor="Firstname" className="form-label form-label-lg">Firstname</label>
									<input type="text" id="Firstname"
										   className={errorsGeneral && errorsGeneral.hasOwnProperty('firstname') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
										   defaultValue={myInfo.firstname}
										onChange={e => setUpdateDataGeneral({ ...updateDataGeneral, firstname: e.target.value })}
									/>
									{errorsGeneral && errorsGeneral.hasOwnProperty('firstname') ? <p className="Authmessage text-red"> {errorsGeneral.firstname} </p> : null}
								</div>
								<div className="col-sm-6 mb-md-4 pb-3">
									<label htmlFor="Lastname" className="form-label form-label-lg">Lastname</label>
									<input type="text" id="Lastname"
										defaultValue={myInfo.lastname}
										   className={errorsGeneral && errorsGeneral.hasOwnProperty('lastname') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
										   onChange={e => setUpdateDataGeneral({ ...updateDataGeneral, lastname: e.target.value })}
									/>
									{errorsGeneral && errorsGeneral.hasOwnProperty('lastname') ? <p className="Authmessage Autherror"> {errorsGeneral.lastname} </p> : null}
								</div>
							</div>
							<div className="row">
								{/* <div className="col-sm-6 mb-md-4 pb-3">
								<label htmlFor="Username" className="form-label form-label-lg">Username</label>
								{errorsGeneral && errorsGeneral.hasOwnProperty('username') ? <p className="Authmessage Autherror"> {errorsGeneral.username} </p> : null}
								<input type="text" className="form-control form-control-xl" id="Username"
									defaultValue={myInfo.username}
									onChange={e => setUpdateDataGeneral({ ...updateDataGeneral, username: e.target.value })}
								/>
							</div> */}
								<div className="col-sm-6 mb-md-4 pb-3">
									<label htmlFor="Email" className="form-label form-label-lg">Email</label>
									<input type="text" id="Email"
										   defaultValue={myInfo.email}
										   disabled={true}
										   className={errorsGeneral && errorsGeneral.hasOwnProperty('email') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
										   onChange={e => setUpdateDataGeneral({ ...updateDataGeneral, email: e.target.value })}
									/>
									{errorsGeneral && errorsGeneral.hasOwnProperty('email') ? <p className="Authmessage Autherror"> {errorsGeneral.email} </p> : null}
								</div>
							</div>
							<div className="text-end py-md-3">
								<button className="btn btn-lg btn-dark px-md-4 mt-lg-3"><span className="px-md-3">Save</span></button>
							</div>
						</form>
				}
			</div>
		</div>
	);
};

export default GeneralEdit;