import React from 'react';
import Select from "react-select";


const AdminEditSelects = (props) => {
    const {keyName, modalContent,value, setModalContent} = props;
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: '10px',
            backgroundColor: state.isFocused ? '#f8f9fa' : undefined,
            color: state.isSelected ? 'black' : state.isFocused ? 'black' :
                '#1c1f21',
            cursor: 'pointer',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'black',
        }),
        control: (prev, state) => ({
            ...prev,
            cursor: 'pointer',
            height: 100 + '%',
            borderColor: 'black',
            borderRadius: '0',
            color: 'black',

        }),
        container: (prev, state) => ({
            ...prev,
            cursor: 'pointer',
            height: 100 + '%',
        }),
    }

    return (
        keyName === 'type' ?
            <Select
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                placeholder={value ? value : ''}
                isSearchable={false}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "black"
                    }
                })}
                options={[
                    { id: 1, value: 'user', label: 'user' },
                    { id: 2, value: 'admin', label: 'admin' },
                ]}
                onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
            />
            : (keyName === 'plan' ?
                    <Select
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        placeholder={value ? value : ''}
                        isSearchable={false}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "black"
                            }
                        })}
                        options={[
                            { id: 1, value: 'Free', label: 'Free' },
                            { id: 2, value: 'Standart', label: 'Standart' },
                            { id: 3, value: 'Premium', label: 'Premium' },
                        ]}
                        onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
                    />
                    : (keyName === 'haveOneTimeForm' ?
                    <Select
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        placeholder={value == 1 ? 'true' : 'false'}
                        isSearchable={false}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "black"
                            }
                        })}
                        options={[
                            { id: 1, value: '1', label: 'true' },
                            { id: 2, value: '0', label: 'false' }
                        ]}
                        onChange={(e) => setModalContent({ ...modalContent,  [keyName]: e.value })}
                    />
                    : (keyName === 'blocked' ?
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                placeholder={value == 1 ? 'suspended' : 'active'}
                                isSearchable={false}
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "black"
                                    }
                                })}
                                options={[
                                    { id: 1, value: '1', label: 'suspended' },
                                    { id: 2, value: '0', label: 'active' }
                                ]}
                                onChange={(e) => setModalContent({ ...modalContent,  [keyName]: e.value })}
                            />
                            
                    : (keyName === 'confirm' ?
                <Select
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    placeholder={value == 1 ? 'verified' : 'not verified'}
                    isSearchable={false}
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: "black"
                        }
                    })}
                    options={[
                        { id: 1, value: '1', label: 'verified' },
                        { id: 2, value: '0', label: 'not verified' },
                    ]}
                    onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
                />
                : (keyName === 'email_notification' ?
                    <Select
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        placeholder={value == 1 ? 'enabled' : 'disabled' }
                        isSearchable={false}
                        styles={customStyles}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "black"
                            }
                        })}
                        options={[
                            { id: 1, value: '1', label: 'enabled' },
                            { id: 2, value: '0', label: 'disabled' },
                        ]}
                        onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
                    />
                    : (keyName === 'browser_notification' ?
                        <Select
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            placeholder={value == 1 ? 'enabled' : 'disabled'}
                            isSearchable={false}
                            styles={customStyles}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: "black"
                                }
                            })}
                            options={[
                                { id: 1, value: '1', label: 'enabled' },
                                { id: 2, value: '0', label: 'disabled' },
                            ]}
                            onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
                        />
                        : (keyName === 'opacity' ?
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                placeholder={value ?
                                    (value === '0.1'
                                        ? 'opacity 10%'
                                        : (value === '0.25'
                                            ? 'opacity 25%'
                                            : (value === '0.5'
                                                ? 'opacity 50%'
                                                : (value === '0.75'
                                                    ? 'opacity 75%'
                                                    : 'opacity 100%'
                                                )
                                            )
                                        )
                                    ) : 'opacity 100%'
                                }
                                isSearchable={false}
                                styles={customStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "black"
                                    }
                                })}
                                options={[
                                    { id: 1, value: '0.1', label: 'opacity 10%' },
                                    { id: 2, value: '0.25', label: 'opacity 25%' },
                                    { id: 3, value: '0.5', label: 'opacity 50%' },
                                    { id: 4, value: '0.75', label: 'opacity 75%' },
                                    { id: 5, value: '1', label: 'opacity 100%' },
                                ]}
                                onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.value })}
                            />
                            : null
                            )
                        )
                    )
                )
            )
        )
      )
    );
};

export default AdminEditSelects;