import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMembers, getMembersConfirm, getMembersConfirmData, getMembersData } from '../redux/reducers/dataReducer';


const MemberAssort = (props) => {
    const {membersCheckedValue, setMembersCheckedValue, membersData, setMembersData} = props;
    const dispatch = useDispatch();
    
     const membersConfirm = useSelector(getMembersConfirmData);
    // const payingLoading = useSelector(getPayingDataLoading);
    function membersChecked(e){
        setMembersCheckedValue(e.target.checked)

        if (e.target.checked) {
            dispatch(getMembersConfirm({ page: 1 }))
        } else {
            dispatch(getMembers({ page: 1 }))
        }

    }
    useEffect(()=>{
        setMembersData(membersConfirm)
    }, [membersCheckedValue])


    return (
        <div className={`form-control form-control-xl d-flex align-items-center members-check ${membersCheckedValue?'members-check-active':''}`}>
            <input className="form-check-input " id='pay-check' checked={membersCheckedValue} onChange={(e) => membersChecked(e)} type="checkbox" foroption="option1" />
            <label className="form-check-label" htmlFor='pay-check' >Members confirm</label>
        </div>
    );
}

export default MemberAssort;