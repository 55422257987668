import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from '../../UI/Loader/Loader';
import TimeToComplete from './TimeToComplete';



const AllDataDisplayed = (props) => {
    const { formNow } = props;

    return (
        <div className='row mb-4'>
            <div className="col-12">
                <div className="card rounded-12 shadow-dark-80">
                    <div className="card-body px-0">
                        <div className="card-body-inner px-3 px-md-4">
                            {formNow[0] ?
                                <div className='d-flex justify-content-between flex-wrap all-data-parent'>
                                    <div className='all-data-displayed'>
                                        <span>Views</span>
                                        <div>{formNow[0].views}</div>
                                    </div>
                                    <div className='all-data-displayed'>
                                        <span>Starts</span>
                                        <div>{formNow[0].views}</div>
                                    </div>
                                    <div  className='all-data-displayed'>
                                        <span>Submissions</span>
                                        <div>{formNow[0].responses.length}</div>
                                    </div>
                                    <div className='all-data-displayed'>
                                        <span>Completion rate</span>
                                        <div>{Math.round(((formNow[0].responses.length / formNow[0].views) * 100)*10)/10}%</div>
                                    </div>
                                    <div className='all-data-displayed'>
                                        <span>Time to complete</span>
                                        <div>
                                            {/* {
                                                formNow[0].responses.reduce((aggr, val) => {
                                                    // let created = moment(val.created_at, "HH:mm:ss").diff(moment().startOf('day'), 'seconds');
                                                    // // let started = moment(val.started_at, "HH:mm:ss")
                                                    // let started = moment(val.started_at, "HH:mm:ss").diff(moment().startOf('HH'), 'seconds')
                                                    // let started = moment(val.started_at, "HH:mm:ss").diff(moment().startOf(), 'seconds');
                                                    // let started = moment(val.started_at, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
                                                    // .diff(moment(val.created_at,"HH:mm:ss"),'seconds')
                                                    let created = moment(val.created_at).format("HH:mm:ss");
                                                    let started = moment(val.started_at, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
                                                    let createdDiff = moment(created, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
                                                    let count = createdDiff - started
                                                    // .diff(moment().startOf('hours'), 'seconds');
                                                    // let created = moment(val.created_at).format("HH:mm:ss");
                                                    console.log(count); 
                                                    // console.log(started);
                                                    return val._id
                                                }, 0)
                                            } */}
                                            <TimeToComplete />
                                        </div>
                                    </div>
                                </div> : <div className="row group-cards">
                                    <div className="col-xl-6 mb-4 form-content">
                                        <div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
                                            <Loader />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllDataDisplayed;