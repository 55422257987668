import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, APP_BASE_URL } from '../../config';
import Loader from '../../UI/Loader/Loader';
import Row from '../Form/FormComponents/Row';
import { CSVLink } from 'react-csv'


import {
	getResponses,
	getTrashedResponses,
	getResponsesData,
	getResponsesDataLoading,
	getResponsesTrashedData,
	getResponsesTrashedDataLoading,
	getResponsesFilters,
	getResponsesLabels,
	getResponsesMonth,
	getResponsesToday,
	getResponsesYear,
	setResponses,
	setTrashedResponses,
	getResponsesFavorites
} from '../../redux/reducers/responseReducer';

const UserResponse = () => {

	const params = useParams();
	const response_id = params.id;
	document.title = `FormBuilder - Response ${response_id}`;
	const messageEdit = useRef();
	const formReset = useRef();
	const defaultMessage = 'Please fill in all the required fields';
	const [files, setFiles] = useState([])
	const [visible, setVisible] = useState(false)
	const [messagePreview, setMessagePreview] = useState('')
	const [response, setResponse] = useState([])
	const [startDate, setStartDate] = useState('')
	const [submitShow, setSubmitShow] = useState(false)
	const [responseIdFormId, setResponseIdFormId] = useState({})
	const [formData, setFormData] = useState([
		{
			id: 1,
			title: '',
			rows: [
				{
					id: 1,
					content: [
						{
							cols: [
								{
									id: 1,
									tabname: '',
									content: [],
								}
							],
						}
					]
				}
			],
			button: false,
		}
	]);
	const [labels, setLabels] = useState([])
	const [csvData, setCsvData] = useState({
		headers: [],
		data: [],
		filename: ""
	})
	const [paginate, setPaginate] = useState({
		page: 0,
		prev: false,
		next: false,
	})
	useEffect(() => {
		setPaginate({ ...paginate, next: formData && formData.length == 1 ? false : true })
	}, [formData])
	useEffect(() => {
		setStartDate(moment(new Date()).format("DD/MM/YYYY H:mm:ss"))
	}, [])
	const [dataForm, setDataForm] = useState({
		id: response_id,
		email: ''
	})
	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	// useEffect(() => {
	// 	let data = {};
	// 	data.id = response_id;
	// 	data.userId = localStorage.getItem('authUser');
	// 	axios.post(API_BASE_URL + '/api/checkForm', data)
	// 		.then(response => { })
	// 		.catch(error => {
	// 			window.location.href = window.location.origin + '/'
	// 		})
	// }, [])

	const [checkAdd, setCheckAdd] = useState({});
	const [userErrorsAll, setUserErrorsAll] = useState([])
	const [emailError, setEmailError] = useState('')
	const [loading, setLoading] = useState(true);
	const [showLogo, setShowLogo] = useState(false);
	useEffect(() => {
		let data = {};
		data.id = response_id;
		var formCopy = JSON.parse(JSON.stringify(formData))
		axios.post(API_BASE_URL + '/api/getFormWithResponse', data)
			.then(response => {
				setFormData(response.data[0])
				setResponse(response.data[1])
				setLabels(response.data[2])
				setResponseIdFormId(response.data[3])
				setShowLogo(response.data[4]);	
				setLoading(false)
				if (localStorage.getItem('print')) {
					localStorage.removeItem('print')
					// formPages.current.classList.add('print-formpages-hidden')
					// var pages = document.querySelectorAll('.tab-pane')
					// for (let i = 0; i < pages.length; i++) {
					// 	pages[i].classList.add('active')
					// 	pages[i].classList.add('show')
					// }
					// document.querySelectorAll('.response-files').forEach(f => {
					// 	return f.closest('.form-content-row-drag-li').classList.add('print-files')
					// })


					let logoForm = document.querySelectorAll('.logoFormbuilder')
					logoForm[0].classList.remove('d-flex')
					logoForm[0].classList.add('d-none')

					let logoFormPrint = document.querySelectorAll('.logoFormPrint')
					logoFormPrint[0].classList.remove('d-none')
					logoFormPrint[0].classList.add('d-flex')


					setTimeout(() => {

						window.print()
					}, 1000)
				}
			})
			.catch(error => {
				window.location.href = window.location.origin + '/'
			})
	}, [])

	const checkSubmit = e => {
		e.preventDefault()
		if (e.currentTarget.getAttribute('page') == formData.length - 1) { setSubmitShow(true) }
		else { setSubmitShow(false) }
	}
	const checkPrev = e => {
		e.preventDefault()
		var prevPage = e.currentTarget.getAttribute('prevpage')
		if (prevPage == 0) {
			setPaginate({ ...paginate, prev: false })
		} else {
			e.currentTarget.setAttribute('href', '#page_' + (+prevPage - 1))
			e.currentTarget.setAttribute('prevpage', +prevPage - 1)
			e.currentTarget.classList.remove('active')
		}
		setPaginate({ ...paginate, next: true, page: +prevPage })
	}

	const checkNext = e => {
		e.preventDefault()
		var nextPage = e.currentTarget.getAttribute('nextpage')
		if (nextPage == formData.length - 1) {
			setPaginate({ ...paginate, next: false })
		} else {
			e.currentTarget.setAttribute('href', '#page_' + (+nextPage + 1))
			e.currentTarget.setAttribute('nextpage', +nextPage + 1)
			e.currentTarget.classList.remove('active')
		}
		setPaginate({ ...paginate, prev: true, page: +nextPage })
		// if (e.currentTarget.getAttribute('page') == formData.length - 1) { setSubmitShow(true) }
		// else { setSubmitShow(false) }
	}
	const [userData, setUserData] = useState({});
	const applyForm = e => {
		const copyFormData = JSON.parse(JSON.stringify(formData));
		const requiredFields = []
		var userErrors = []
		for (let i = 0; i < copyFormData.length; i++) {
			const forJ = copyFormData[i].rows;
			for (let j = 0; j < forJ.length; j++) {
				const forK = forJ[j].content[0].cols
				for (let k = 0; k < forK.length; k++) {
					if (forK[k].tabname != 'image') {
						if (forK[k].content.required && forK[k].content.required == true) {
							// requiredFields.push(i + '/' + j + '/' + k)
							requiredFields.push(forK[k].content.label + '_' + i + j + k)
						}
					}
				}
			}
		}
		if (requiredFields.length > 0) {
			// var requiredFieldsNames = []
			if (Object.keys(userData).length == 0) {
				requiredFields.map(e => {
					userErrors.push(e)
					// const splittedFields = e.split('/').map(k => +k);
					// requiredFieldsNames.push(copyFormData[splittedFields[0]].rows[splittedFields[1]].content[0].cols[splittedFields[2]].content.label)
				})
				setUserErrorsAll(userErrors)
			} else {
				userErrors = []
				Object.keys(userData).map(e => {
					if (userData[e] == '') { delete userData[e] }
				})
				for (let i = 0; i < requiredFields.length; i++) {
					if (!userData.hasOwnProperty(requiredFields[i])) {
						userErrors.push(requiredFields[i])
						// const splittedFields = requiredFields[i].split('/').map(e => +e);
						// requiredFieldsNames.push(copyFormData[splittedFields[0]].rows[splittedFields[1]].content[0].cols[splittedFields[2]].content.label)
					}
				}
				setUserErrorsAll(userErrors)
			}
			if (userErrors.length > 0) {
				showMessage(userErrors.map((e, i) => <span key={i} className='errorMessageP'>{e.split('_')[0]}</span>))
			}
		}
		if (userErrors.length == 0) {
			const userFormData = new FormData();
			userFormData.append('dataForm', JSON.stringify(dataForm))
			userFormData.append('userData', JSON.stringify(userData));
			userFormData.append('startDate', startDate);
			if (files && files.length > 0) {
				userFormData.append("hasFiles", true);
				files.forEach((file, index) => {
					if (file.valid) {
						userFormData.append("userFormDataFiles/" + index, file.file);
					}
				});
			}
			axios.post(API_BASE_URL + '/api/saveUserForm', userFormData)
				.then(response => {
					userErrors = []
					setEmailError('')
					setUserErrorsAll([])
					formReset.current.reset()
					setUserData({})
					showMessageSuccess(<p>Thanks for your submitting!</p>)

				})
				.catch(error => {
					if (error.response.data.error.email) {
						setEmailError(error.response.data.error.email)
					}
				})
		}
	}
	const formPages = useRef()
	// const logoFormPrint = useRef()
	const printResponse = e => {
		let logoForm = document.querySelectorAll('.logoFormbuilder')
		logoForm[0].classList.remove('d-flex')
		logoForm[0].classList.add('d-none')

		let logoFormPrint = document.querySelectorAll('.logoFormPrint')
		logoFormPrint[0].classList.remove('d-none')
		logoFormPrint[0].classList.add('d-flex')
		// setVisible(true)

		var style = "<style>.form-page { display: none !important;} </style>";
		// formPages.current.classList.add('print-formpages-hidden')
		var pages = document.querySelectorAll('.tab-pane')
		for (let i = 0; i < pages.length; i++) {
			pages[i].classList.add('active')
			pages[i].classList.add('show')
		}
		document.querySelectorAll('.response-files').forEach(f => {
			return f.closest('.form-content-row-drag-li').classList.add('print-files')
		})
		let divs = document.querySelectorAll('.responseText')
		// divs.classList.add("my-class")
		// console.log(divs);
		// console.log(111)

		window.print()

	}
	const downloadResponse = (e) => {
		let response = responseIdFormId;
		const headers = []
		const CSVResponse = [{}]
		labels.map((label, index) => {
			if (response.response.hasOwnProperty(label.uniqueId)) {
				headers.push({
					label: label.label,
					key: label.uniqueId
				})
				let tmp = document.createElement("DIV");
				tmp.innerHTML = response.response[label.uniqueId].value.replaceAll('</p><p>', " ");
				CSVResponse[0][label.uniqueId] = tmp.textContent.replace(/"/g, "'")  || tmp.innerText.replace(/"/g, "'") || ""
				// CSVResponse[0][label.label] = response.response[label.uniqueId].value
			}
		})
		const csvLinks = {
			headers: headers,
			data: CSVResponse,
			filename: `Response - ${response._id}.csv`
		}
		setCsvData(csvLinks)
	}

	const copyLink = e => {
		e.preventDefault()
		try {
			e.currentTarget.previousSibling.classList.toggle('copied')
			if (e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.contains('fa-link')) {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-link')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-check', 'copied')
			} else {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-check', 'copied')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-link')
			}
			// navigator.clipboard.writeText(e.currentTarget.getAttribute('link'))
			var textField = document.createElement('textarea')
			textField.innerText = e.currentTarget.getAttribute('link')
			document.body.appendChild(textField)
			textField.select()
			document.execCommand('copy')
			textField.remove()
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const afterPrint = e => {
		let logoForm = document.querySelectorAll('.logoFormbuilder')
		logoForm[0].classList.remove('d-none')
		logoForm[0].classList.add('d-flex')

		let logoFormPrint = document.querySelectorAll('.logoFormPrint')
		logoFormPrint[0].classList.remove('d-flex')
		logoFormPrint[0].classList.add('d-none')
		// setVisible(false)

		var style = "<style>.form-page { display: none !important;} </style>";
		formPages.current.classList.remove('print-formpages-hidden')
		var pages = document.querySelectorAll('.tab-pane')
		for (let i = 1; i < pages.length; i++) {
			pages[i].classList.remove('active')
			pages[i].classList.remove('show')
		}
		document.querySelectorAll('.response-files').forEach(f => {
			return f.closest('.form-content-row-drag-li').classList.remove('print-files')
		})
	}
	useEffect(() => {
		if (localStorage.getItem('print')) {
			// formPages.current.classList.add('print-formpages-hidden')
			// var pages = document.querySelectorAll('.tab-pane')
			// for (let i = 0; i < pages.length; i++) {
			// 	pages[i].classList.add('active')
			// 	pa<Pdf targetRef={ref} filename="code-example.pdf">
			// }
			// let logoForm = document.querySelectorAll('.logoFormbuilder')
			// logoForm[0].classList.remove('d-flex')
			// logoForm[0].classList.add('d-none')
			//
			// let logoFormPrint = document.querySelectorAll('.logoFormPrint')
			// logoFormPrint[0].classList.remove('d-none')
			// logoFormPrint[0].classList.add('d-flex')
		}
		window.onafterprint = afterPrint

	}, [])

	// useEffect(() => {
	// 	console.log(visible)
	// },[visible])

	return (

		<div id="userResponsePage" className="preview bg-gray-100 px-3 px-xxl-5 py-3 py-lg-4 mx-auto">
			<div className="form-changes form-changes-error" ref={messageEdit} >
				<div className='img-div'>
					<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
							<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
								  stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
					</svg>	
				</div>
				<div className='succes-div'>
					<p className='paragraph'>{messagePreview}</p>
				</div> 


			</div>
			<div className="preview-container col-12 col-xl-8 col-xxl-10">
				{
					loading
						?
						<div className='w-100 d-flex justify-content-center'>
							<Loader />
						</div>
						:
						<form tabname="Form" className="form-pages tab-content mt-3" ref={formReset}>
							{formData && formData.map((form, key) => {
								return <div className={key == 0 ? "form-page" : "form-page"} key={key} id={"page_" + key}>
									{/* {
										formData.length > 1 && key != 0
											?
											<div className="page-number page-number-preview">
												<button type='button' className='page-number-button'>Page {key + 1}</button>
											</div>
											: null
									} */}

									<div className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3  form-body">
										{
											form.title
												?

												<div className="d-flex align-items-center justify-content-between px-3 px-md-4 py-3 border-bottom border-gray-200 form_input-header form-input">
													<h4 className="my-3 user-evented">{response.header} - {moment(response.response_created_at).format("DD/MM/YYYY")}</h4>
													<div className='d-none logoFormPrint print-logo-div-style'>
													{showLogo === false?
													<>
														<span className='text-dark print-span-text'>Powered by</span>
														<img src="/logo_one_print.png" alt="Formbuilder Logo"  width={40}/>
														</>:null
							                        }
													</div>
													<div className='d-flex logoFormbuilder'>
														<div className="form-link-buttons">
															<div className='form-link-buttons button-link'>
																<div className='form-link-button-body'>
																	<div className="response-link-button-title response-link-button-title-hovered"></div>
																	<button type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'} link={APP_BASE_URL + "/response/" + response.response_id} onClick={copyLink}><span><i className="fas fa-link forChangeIcon"></i></span></button>
																</div>
															</div>
														</div>
														<div className='form-link-buttons button-statistics'>
															<div className='form-link-button-body'>
																<div className="form-link-button-title-bottom form-link-button-title-hovered">Download response(CSV)</div>
																<button type="button" className="p-0 m-0" onClick={e => downloadResponse(e, response)}>
																	{
																		csvData
																			?
																			<CSVLink {...csvData} className={
																				response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-download"></i></span></CSVLink>
																			: <span><i className="fas fa-download"></i></span>
																	}
																</button>
															</div>
														</div>
														<div className='form-link-button-body'>
															<div className="form-link-button-title-bottom form-link-button-title-hovered text-center download-text">Print response</div>
															<button title='Print response' type='button' onClick={e => printResponse(e, response._id)} className='response-link'>
																<span className="font-weight-semibold text-gray-700"><i className="fas fa-print"></i></span>
															</button>
														</div>
													</div>

												</div>
												: null
										}

										<div className='form-content '>
											<div className="form-content-body">
												{
													form.rows.map((bform, bkey) => {
														return <Row
															checkAdd={checkAdd}
															setCheckAdd={setCheckAdd}
															page={key}
															row={bkey}
															key={bkey}
															contentRow={bform}
															formData={formData}
															setFormData={setFormData}
															preview={false}
															response={response}
															responseCheck={true}
															userData={userData}
															setUserData={setUserData}
															userErrorsAll={userErrorsAll}
															setUserErrorsAll={setUserErrorsAll}
															setDataForm={setDataForm}
															dataForm={dataForm}
															emailError={emailError}
															setEmailError={setEmailError}
															files={files}
															setFiles={setFiles}
														/>
													})
												}
											</div>
										</div>
									</div>
								</div>
							})}
							{/* <div className="text-center preview-form-pages" ref={formPages}>
								{
									formData && formData.length > 1
										?
										<ul className="nav nav-segment nav-pills page" role="tablist"> */}
											{/*NOT*/}
											{/*<li className="page__numbers">*/}
											{/*	<button type='button' className="page-button page-button-arrow" onClick={prevPage}><span>«</span></button>*/}
											{/*</li>*/}
											{/*NOT*/}
											{/* {
												paginate.prev && paginate.page != 0
													?
													<li className="page__numbers">
														<a className="page-button page-button-action" prevpage={paginate.page - 1} data-bs-toggle="pill" onClick={checkPrev} href={"#page_" + (+paginate.page - 1)} role="tab">Prev</a>
													</li>
													: null
											}
											{
												paginate.next && paginate.page != formData.length - 1
													?
													<li className="page__numbers">
														<a className="page-button page-button-action" nextpage={paginate.page + 1} data-bs-toggle="pill" onClick={checkNext} href={"#page_" + (+paginate.page + 1)} role="tab">Next</a>
													</li>
													: null
											} */}
											{/*NOT*/}
											{/*{*/}
											{/*	formData.map((form, key) => {*/}
											{/*		return <li className="page__numbers" key={key}>*/}
											{/*			<a className={key == 0 ? "page-button active" : "page-button"} page={key} data-bs-toggle="pill" onClick={checkSubmit} href={"#page_" + key} role="tab" aria-selected={key == 0 ? "true" : "false"}>{key + 1}</a>*/}
											{/*		</li>*/}
											{/*		return <li className="nav-item page-buttons" key={key}>*/}
											{/*			<a className={key == 0 ? "page-link active page-button" : "page-link page-button"} page={key} data-bs-toggle="pill" href={"#page_" + key} role="tab" aria-selected="true">{key + 1}</a>*/}
											{/*		</li>*/}
											{/*	})*/}
											{/*}*/}
											{/*<li className="page__numbers">*/}
											{/*	<button type='button' className="page-button page-button-arrow" onClick={nextPage}><span>»</span></button>*/}
											{/*</li>*/}
											{/*NOT*/}
										{/* </ul>
										: <div></div>
								}
							</div> */}
						</form>
				}
			</div>
		</div>

	);
};

export default UserResponse;