import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import MyButton from '../../../UI/button/MyButton';
import MyInput from '../../../UI/Input/MyInput';
import MySelect from '../../../UI/Select/MySelect';
import FormData from 'form-data';
import { Dropzone, FileItem } from "@dropzone-ui/react";
import MyTextarea from '../../../UI/textarea/MyTextarea';
import { API_BASE_URL } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminUsersData, getGlobalSearchTextInfo, setGlobalDateRange, setGlobalSearchText, setPayingInfo, setSearchData, setUsers } from '../../../redux/reducers/adminReducer';


const AdminForm = (props) => {
	const [option, setOption] = useState();
	const dispatch = useDispatch();
	const { dataAll, setDataAll, userForm, setModalAdd, errors, setErrors, table, sendData, setSendData, files, setFiles, editData, deleteData, closeModal, setUserDate, setDateRange } = props;
	const updateFiles = (incommingFiles) => {
		setFiles(incommingFiles);
	};
	function selectChange(e) {
		setOption(e.target.value)
	}
	const getTableName = (name) => {
		// setInfoSendData(true); 
		setDateRange([null, null])
		setUserDate({})
		dispatch(setGlobalDateRange(
			{
				from: null,
				to: null
			}))
			dispatch(setGlobalSearchText(''))
			dispatch(setSearchData(''))
		dispatch(setPayingInfo(false))
		setSendData({ ...sendData, tableName: name })
	}
	const sendForm = e => {
		e.preventDefault()
		let formdata = new FormData();
		// var imagefile = document.querySelector('#image');
		for (var key in sendData) {
			formdata.append(key, sendData[key]);
		}
		if (files[0]) {
			formdata.append('image', files[0].file);
		}
		axios.post(API_BASE_URL + '/api/addTableData', formdata)
			.then(response => {
				dispatch(setUsers(response.data))
				closeModal();
			})
			.catch(error => {
				setErrors(error.response.data.error)
			})

	}
	return (
		table === 'users' || table === '' || table === undefined
			?
			<div className="home__form">
				<div className="home__form-content">
					<p className="home__form-title">add new User</p>
					<form onSubmit={sendForm} ref={userForm}>
						<MyInput
							onChange={e => setSendData({ ...sendData, email: e.target.value })}
							type="text"
							placeholder="Email" />
						{errors && errors.hasOwnProperty('email') ? <p className="Authmessage Autherror"> {errors.email} </p> : null}
						<MyInput
							onChange={e => setSendData({ ...sendData, password: e.target.value })}
							type="text"
							placeholder="Password" />
						{errors && errors.hasOwnProperty('password') ? <p className="Authmessage Autherror"> {errors.password} </p> : null}
						<MyButton onClick={() => getTableName('User')}>Add User</MyButton>
					</form>
				</div>
			</div >
			:
			(table === 'apply'
				? <div></div>
				: (table === 'homes'
					? <div></div>
					: <div></div>
				)
			)
	);
};

export default AdminForm;