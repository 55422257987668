import Select from "react-select";

export function randomString(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const isMobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function () {
		return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
	},
	any: function () {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
};

// export function getBase64(file) {
//     var reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = function () {
//       console.log(reader.result);
//     };
//     reader.onerror = function (error) {
//       console.log('Error: ', error);
//     };
//  }

export function trim(value) {
	return value.replace(/^\s+|\s+$/g, "");
}

export function convertDate(string) {
	var d = new Date(Date.parse(string.replace(/-/g, "/")));
	var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
		'Nov', 'Dec'];
	var date = d.getDay().toString() + " " + month[d.getMonth().toString()] + ", " +
		d.getFullYear().toString();
	return (date);
};

export const makeCsv = async (rows, filename) => {
	const separator = ';';
	const keys = Object.keys(rows[0]);

	const csvContent = `${keys.join(separator)}\n${rows.map((row) => keys.map((k) => {
		let cell = row[k] === null || row[k] === undefined ? '' : row[k];

		cell = cell instanceof Date
			? cell.toLocaleString()
			: cell.toString().replace(/"/g, '""');

		if (cell.search(/("|,|\n)/g) >= 0) {
			cell = `"${cell}"`;
		}
		return cell;
	}).join(separator)).join('\n')}`;
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	return blob
	// 	if (navigator.msSaveBlob) { // In case of IE 10+
	// 	  navigator.msSaveBlob(blob, filename);
	// 	} else {
	// 	  const link = document.createElement('a');
	// 	  if (link.download !== undefined) {
	// 		// Browsers that support HTML5 download attribute
	// 		const url = URL.createObjectURL(blob);
	// 		link.setAttribute('href', url);
	// 		link.setAttribute('download', filename);
	// 		link.style.visibility = 'hidden';
	// 		document.body.appendChild(link);
	// 		link.click();
	// 		document.body.removeChild(link);
	// 	  }
	// 	}
}

export const urlify = (text) => {
	var urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, function (url) {
		return '<a target="_blank" className="font-weight-bold" href="' + url + '">' + url + '</a>';
	})
}

export const dhm = (ms) =>{
    var days = Math.floor(ms / (24*60*60*1000));
	var daysms = ms % (24*60*60*1000);
	var hours = Math.floor(daysms / (60*60*1000));
	var hoursms = ms % (60*60*1000);
	var minutes = Math.floor(hoursms / (60*1000));
	var minutesms = ms % (60*1000);
	var sec = Math.floor(minutesms / 1000);
		days = days < 10 ? '0' + days : days;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		sec = sec < 10 ? '0' + sec : sec;
	
  return days + ":" + hours + ":" + minutes + ":" + sec;
}