import React, { useEffect, useState } from 'react';
import Select from "react-select";
import axios from 'axios';
import MyInput from '../../../UI/Input/MyInput';
import MyButton from '../../../UI/button/MyButton';
import MyTextarea from '../../../UI/textarea/MyTextarea';
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { getAdminSearchData, getAdminUsers, getGlobalDateRangeInfo, getGlobalSearchTextInfo, getPayingInfo } from '../../../redux/reducers/adminReducer';
import FormData from 'form-data';
import { API_BASE_URL } from '../../../config';
import { getAdmInfo, getAdminInfoData } from '../../../redux/reducers/adminReducer';
import AdminEditSelects from './AdminEditSelects';


const AdminEdit = (props) => {
	const { setModal, modalData, setModalData, tableData, setTableData, setDataAll, pageNumber, table, userDate, tabNameInSolved, dataAll } = props;
	const [editableData, setEditableData] = useState([]);
	const [modalContent, setModalContent] = useState([]);
	const infoAdmin = useSelector(getAdminInfoData)
	const [files, setFiles] = useState([]);
	const dispatch = useDispatch();
	const searchData = useSelector(getAdminSearchData)
	const pageNumberNow = dataAll.current_page
	const payingInfo = useSelector(getPayingInfo)
	const globalDateRange = useSelector(getGlobalDateRangeInfo);
	const globalSearchTextInfo = useSelector(getGlobalSearchTextInfo);
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			padding: '10px',
			cursor: 'pointer',
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: 'black',
		}),
		control: (prev, state) => ({
			...prev,
			cursor: 'pointer',
			height: 100 + '%',
			borderColor: 'black',
			borderRadius: '0',
			color: 'black',

		}),
		container: (prev, state) => ({
			...prev,
			cursor: 'pointer',
			height: 100 + '%',
		}),
	}

	const updateFiles = (incommingFiles) => {
		setFiles(incommingFiles);
	};

	const closeModal = () => {
		setModal(false);
		setModalData([]);
		setTableData([])
		setModalContent([])
	}

	const saveData = () => {
		var data = {}
		data.modalContent = modalContent;
		data.tableData = tableData;
		data.payingInfo = payingInfo;
		data.globalDateRange = globalDateRange;
		data.globalSearchTextInfo = globalSearchTextInfo;
		if(payingInfo){
			data.payingInfo = payingInfo
		}
		// var data = [modalContent,tableData];
		if ((table == 'users' || table === '' || table === undefined) && Object.keys(userDate).length > 0) {
			// data.push(userDate)
			data.userDate = userDate
		}
		if (searchData.length > 0) {
			data.searchData = searchData
		}
		if ((table == 'messages' || table === 'customers')) {
            data.tabNameInSolved = tabNameInSolved
        }
		axios.post(API_BASE_URL + '/api/editTableData?page=' + pageNumberNow, data)
			.then(response => {
				setDataAll(response.data)
			})
			.catch(error => { })
		closeModal()
	}

	return (
		<div className="modalData">
			<div className="modalData-contents">
				{modalData
					?
					Object.keys(modalData).map((keyName, i) => {
						if (keyName != '_id'
							&& keyName != 'created_at'
							&& keyName != 'status'
							&& keyName != 'count'
							&& keyName != 'updated_at'
							&& keyName != 'avatar'
							&& keyName != 'cover'
							&& keyName != 'googleImage'
							&& keyName != 'googleId'
							&& keyName != 'edit'
							&& keyName != 'color'
							&& keyName != 'formatedDate'
							&& keyName != 'token'
							&& keyName != 'fullName'
							&& keyName != 'buttons'
							&& keyName != 'details'
							&& keyName != 'formatedDetails'
							&& keyName != 'deleted_at'
							&& keyName != 'user_id'
							&& keyName != 'link'
							&& keyName != 'email'
							&& keyName != 'username'
							&& keyName != 'formsadmin'
							&& keyName != 'useremail'
							&& keyName != 'solved'
							&& keyName != 'responses'
							&& keyName != 'Responsables'
							&& keyName != 'response_count'
						) {
							return (
								<div className="modalData-content" key={i}>
									{ keyName === 'blocked' ? <label>Status</label> : <label>{keyName}</label>}
									{
										keyName === 'blocked' || keyName === 'plan' || keyName === 'type' || keyName === 'confirm' || keyName === 'email_notification' || keyName === 'browser_notification' || keyName === 'opacity' || keyName === 'haveOneTimeForm' ?
												<AdminEditSelects
													keyName={keyName}
													value={modalData[keyName] }
													modalContent={modalContent}
													setModalContent={setModalContent}
												/>
												:
												<MyInput
													key={i}
													onChange={(e) => setModalContent({ ...modalContent, [keyName]: e.target.value })}
													defaultValue={
														modalData[keyName] ? modalData[keyName] : ''
													}
												/>

									}
								</div>
							)
						}
					})
					: <div></div>}
			</div>
			<div className="modalData-buttons">
				<MyButton onClick={() => saveData()}>Edit</MyButton>
				<MyButton onClick={() => closeModal()}>Cancel</MyButton>
			</div>
		</div>
	);
};

export default AdminEdit;