import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import QRCode from 'qrcode';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    TwitterIcon,
    TwitterShareButton,
} from "react-share";
import axios from 'axios';
import { API_BASE_URL, AxiosConfigs } from '../config';
import { useDispatch } from 'react-redux';
import { getMembers } from '../redux/reducers/dataReducer';
import Loader from '../UI/Loader/Loader';

const AddMembers = (props) => {
    const { membersCheckedValue, setMembersCheckedValue } = props;
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState(false)
    const handleClose = () => { setShow(false); setErrors('') };
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const [errorValidate, setErrorValidate] = useState(false)
    const [sendEmailAddress, setSendEmailAddress] = useState('');
    const [editedMessage, setEditedMessage] = useState('')


    const changeEmailData = (e) => {
        e.preventDefault();
        setSendEmailAddress(e.target.value)
    }
    const messageEdit = useRef();


    function showMessageSuccess(message) {
        try {
            if (messageEdit.current != null) {
                messageEdit.current.classList.remove('form-changes-error')
                messageEdit.current.classList.add('active', 'form-changes-success')
                setTimeout(() => {
                    if (messageEdit.current != null) {
                        messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
                    }
                }, 1400)
            }
            setEditedMessage(message)
        } catch (error) { throw error }
    }

    const sendEmail = e => {
        e.preventDefault()
        setLoading(true)
        var data = {};
        data.email = sendEmailAddress;
        axios.post(API_BASE_URL + '/api/addmembers', data, AxiosConfigs)
            .then(response => {
                if (membersCheckedValue) {
                    setMembersCheckedValue(false)
                }
                setLoading(false)
                showMessageSuccess("Registration request has been successfully sent")
                setShow(false)
                setSendEmailAddress('')
                dispatch(getMembers({ page: 1 }))
            })
            .catch(error => {
                setLoading(false)
                setErrors(error.response.data.errors)

            })
    }

    return (
        <>
            <div className="form-changes add-members-messages" ref={messageEdit} >
                <div className='img-div'>
                    <svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
                              stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
					</svg>	
                </div>
            <div className='succes-div'>
                <p className='paragraph'>{editedMessage}</p>
            </div>
            </div>
            <button type='button' onClick={handleShow} className="add-members btn btn-dark-100 btn-icon btn-sm rounded-circle">
                <span>+</span>
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center p-4'>
                    {!loading ?
                        <form className="pt-2 col-12" onSubmit={sendEmail}>
                            <div className="pb-md-2">
                                <input
                                    type="text"
                                    className={"form-control form-control-xl"}
                                    id="Email"
                                    placeholder="Email"
                                    value={sendEmailAddress}
                                    onChange={e => changeEmailData(e)}
                                />
                                {errors && errors.hasOwnProperty('email') ? <p className="Authmessage text-red "> {errors.email} </p> : null}
                            </div>

                            <div className="d-grid">
                                <button className="btn btn-xl btn-warning">Send</button>
                            </div>
                        </form> : <div className="form-loader">
                            <Loader />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddMembers;