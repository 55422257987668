import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, AxiosConfigs } from '../../../config';
import { getErrorLogs } from '../../../redux/features/dataApi';
import { getAdminErrorLogs, getAdminErrorLogsLoading, getErrorLogsData, setErrorLogs } from '../../../redux/reducers/adminReducer';
import Loader from '../../../UI/Loader/Loader';

const AdminErrors = () => {
	document.title = "FormBuilder - Errors";
    const dispatch = useDispatch();
    var errorLogs = useSelector(getAdminErrorLogs);
    const loader = useSelector(getAdminErrorLogsLoading)
    const [normErrors, setNormErrors] = useState('')
    useEffect(() => {
        dispatch(getErrorLogsData())
    },[])

    useEffect(() => {
        if (errorLogs) {
            setNormErrors(errorLogs.replaceAll('"}','"} <br><br>').split('<br><br>').reverse().join('<br><br>').slice(10))
        }
    },[errorLogs])

    const clearLogs = () => {
        axios.post(API_BASE_URL + '/api/clearErrorLogs', [], AxiosConfigs)
			.then(response => {
				dispatch(setErrorLogs(''))
                setNormErrors('')
			})
			.catch(error => {
				console.log(error)
			})
    }

    return (
        <div className="home__data">
            <div className="content-main d-flex justify-content-center  h-100">
                {
                    loader
                    ? <Loader/>
                    :
                    <div className="container-fluid">
                        <div className="col-xs-3 no-padding text-right form-group d-flex justify-content-end home__data-add">
                            <button type="button" onClick={() => clearLogs()} >Clear Log File</button>
                        </div>
                        <div className="card card-stats mb-4 mt-2 mb-xl-0">
                            <div className="card-body">
                                {
                                    normErrors 
                                    ? <div dangerouslySetInnerHTML={{ __html: normErrors }} className="error-logs-div"></div>
                                    : 'No errors :)'
                                }
                            </div>
                            {/* <?= ($contents) ? nl2br($contents) : 'No errors :)' ?> */}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default AdminErrors;