import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { APP_BASE_URL } from '../config';
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/Input/MyInput';

const SigninForm = (props) => {
	const { errors, setErrors, signinData, setSigninData, signin } = props;
	const [passwordShowCheck, setPasswordShowCheck] = useState(false)
	const passwordShow = (e) => {
		e.preventDefault()
		setPasswordShowCheck(!passwordShowCheck);
	}
	const addStoragelocal = e => {
		localStorage.setItem('verify', 'true')
		window.location.href = APP_BASE_URL + '/verify'
	}
	return (
		<form className="pt-2" onSubmit={signin}>
			<div className="mb-4 pb-md-2">
				{/*<label className="form-label form-label-lg" htmlFor="Email">Email*</label>*/}
				<input
					type="text"
					className={errors && (errors.hasOwnProperty('verify') || errors.hasOwnProperty('both') || errors.hasOwnProperty('email')) ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
					id="Email"
					placeholder="Email"
					onChange={e => setSigninData({ ...signinData, email: e.target.value })}
				/>
				{errors && errors.hasOwnProperty('verify')
					? <p className="Authmessage Autherror bg-white text-red text-end">Please<button type='button' className="text-red font-weight-bold text-decoration-underline" onClick={addStoragelocal}>verify</button>your account. </p>
					: null}
				{errors && errors.hasOwnProperty('both') ? <p className="Authmessage text-red text-end"> {errors.both} </p> : null}
				{errors && errors.hasOwnProperty('email') ? <p className="Authmessage text-red text-end"> {errors.email} </p> : null}
			</div>
			<div className="mb-4 pb-md-2">
				{/*<label className="form-label form-label-lg" htmlFor="Password">Password*</label>*/}
				<div className='passwordShow'>
					<input
						type={passwordShowCheck ? "text" : "password"}
						className={errors && errors.hasOwnProperty('password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
						id="Password"
						placeholder="Password"
						onChange={e => setSigninData({ ...signinData, password: e.target.value })}
					/>
					<button type="button" onClick={passwordShow}>
						{passwordShowCheck
							? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
							: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
						}
					</button>
				</div>
				{errors && errors.hasOwnProperty('password') ? <p className="Authmessage text-red "> {errors.password} </p> : null}
			</div>
			<div className="d-grid">
				<button className="btn btn-xl btn-warning">Sign In</button>
			</div>
			<div className="my-4 d-flex pb-3">
				<div className="form-check form-check-sm mb-0" style={{ alignItems: 'center' }}>
					<input className="form-check-input dark-form-check" defaultChecked={signinData.remember} type="checkbox" id="gridCheck" onChange={e => setSigninData({ ...signinData, remember: e.target.checked })} />
					<label className="form-check-label small text-gray-600" htmlFor="gridCheck">
						Remember me
					</label>
				</div>
				<Link to="/forgot" className="small text-gray-600 ms-auto mt-1">Forgot password?</Link>
			</div>
			<div className="border-top border-gray-200 pt-4">
				<span className="text-gray-700">Don't have an account? <Link to="/signup" className="link-primary" style={{ color: '#FFB840' }}>Sign Up</Link></span>
			</div>
		</form>
	);
};

export default SigninForm;