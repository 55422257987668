import React, { useEffect, useRef, useState } from 'react';
import FormData from 'form-data';
import FormSettingsCheckbox from './FormSettingsCheckbox';
import FormSettingsDropdown from './FormSettingsDropdown';
import FormSettingsImage from './FormSettingsImage';
import FormSettingsInput from './FormSettingsInput';
import FormSettingsParagraph from './FormSettingsParagraph';
import FormSettingsRadio from './FormSettingsRadio';
import FormSettingsText from './FormSettingsText';
import FormSettingsUpload from './FormSettingsUpload';
import FormSettingsDate from './FormSettingsDate';
import moment from 'moment';
import FormSettingsColor from './FormSettingsColor';
import Swal from 'sweetalert2';
import FormSettingsDivider from './FormSettingsDivider';
import { useParams } from 'react-router';
import axios from 'axios';
import { API_BASE_URL } from '../../../../config';
import FormSettingsDateRange from './FormSettingsDateRange';

const FormSettings = (props) => {
	const { settingsData, setSettingsData, formDetails, formData, setFormData, showMessage, showMessageSuccess, showMessageError, saveForm, colorsData,form,setForm } = props;
	const [checkedText, setCheckedText] = useState(false)
	const [checkedTextResponse, setCheckedTextResponse] = useState(false)
	const [checkedDropdown, setCheckedDropdown] = useState(false)
	const [checkedDropdownResponse, setCheckedDropdownResponse] = useState(false)
	const [checkedInput, setCheckedInput] = useState(false)
	const [checkedInputResponse, setCheckedInputResponse] = useState(false)
	const [checkedUpload, setCheckedUpload] = useState(false)
	const [checkedCheckbox, setCheckedCheckbox] = useState(false)
	const [checkedCheckboxResponse, setCheckedCheckboxResponse] = useState(false)
	const [checkedParagraph, setCheckedParagraph] = useState(false)
	const [checkedRadio, setCheckedRadio] = useState(false)
	const [checkedRadioResponse, setCheckedRadioResponse] = useState(false)
	const [checkedDate, setCheckedDate] = useState(false)
	const [checkedDateResponse, setCheckedDateResponse] = useState(false)
	const [checkedTime, setCheckedTime] = useState(false)
	const [checkedRadioFilter, setCheckedRadioFilter] = useState(false)
	const [dataRangeChange, setDataRangeChange] = useState(false)
	const [checkedDropdownFilter, setCheckedDropdownFilter] = useState(false)
	const [checkedCheckboxFilter, setCheckedCheckboxFilter] = useState(false)
	const params = useParams();
	const form_id = params.id
	const [open, setOpen] = useState(false)
	// const checkResponseFunction = (id) => {
	// 	return formDetails?.structure?.filter(e => e[0] === id);
	// }
	
	useEffect(() => {
		if (settingsData.details.input) {
			setCheckedInput(settingsData.details.input.required)
			// console.log(checkResponseFunction(settingsData.details.input.uniqueId))
			setCheckedInputResponse(settingsData.details.input.responsable)
		}
		if (settingsData.details.dropdown) {
			setCheckedDropdown(settingsData.details.dropdown.required)
			setCheckedDropdownResponse(settingsData.details.dropdown.responsable)
			setCheckedDropdownFilter(settingsData.details.dropdown.filter)
		}
		if (settingsData.details.text) {
			setCheckedText(settingsData.details.text.required)
			setCheckedTextResponse(settingsData.details.text.responsable)
		}
		if (settingsData.details.checkbox) {
			setCheckedCheckbox(settingsData.details.checkbox.required)
			setCheckedCheckboxResponse(settingsData.details.checkbox.responsable)
			setCheckedCheckboxFilter(settingsData.details.checkbox.filter)
		}
		if (settingsData.details.radio) {
			setCheckedRadio(settingsData.details.radio.required)
			setCheckedRadioResponse(settingsData.details.radio.responsable)
			setCheckedRadioFilter(settingsData.details.radio.filter)
		}
		if (settingsData.details.paragraph) {
			setCheckedParagraph(settingsData.details.paragraph.required)
		}
		if (settingsData.details.upload) {
			setCheckedUpload(settingsData.details.upload.required)
		}
		if (settingsData.details.date) {
			setCheckedDate(settingsData.details.date.required)
			setCheckedDateResponse(settingsData.details.date.responsable)
			setCheckedTime(settingsData.details.date.checkedTime)
		}
		if (settingsData.details.color) {
			// setCheckedDate(settingsData.details.date.required)
			// setCheckedTime(settingsData.details.date.checkedTime)
		}
	}, [settingsData])

	// useEffect(() => {
	// 	let data = {};
	// 	data.id = form_id;
	// 	axios.post(API_BASE_URL + '/api/getForm', data)
	// 		.then(response => {
	// 			if (response.data.length > 0) {
	// 				setForm(response.data[1])
	// 			}
	// 		})
	// 		.catch(error => { })
	// }, [])

	const requiredChange = (page, row, col, fieldtype, checked) => {
		try {
			if (fieldtype == 'input') {
				setCheckedInput(!checkedInput)
			} else if (fieldtype == 'dropdown') {
				setCheckedDropdown(!checkedDropdown)
			} else if (fieldtype == 'text') {
				setCheckedText(!checkedText)
			} else if (fieldtype == 'checkbox') {
				setCheckedCheckbox(!checkedCheckbox)
			} else if (fieldtype == 'radio') {
				setCheckedRadio(!checkedRadio)
			} else if (fieldtype == 'paragraph') {
				setCheckedParagraph(!checkedParagraph)
			} else if (fieldtype == 'upload') {
				setCheckedUpload(!checkedUpload)
			} else if (fieldtype == 'date') {
				setCheckedDate(!checkedDate)
			}

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.required = checked;
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details[fieldtype].required = checked
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const responseChange = (page, row, col, id, fieldtype, checked) => {
		// try {
			if (fieldtype === 'input') {
				setCheckedInputResponse(!checkedInputResponse)
			} else if (fieldtype === 'dropdown') {
				setCheckedDropdownResponse(!checkedDropdownResponse)
			} else if (fieldtype === 'text') {
				setCheckedTextResponse(!checkedTextResponse)
			} else if (fieldtype === 'checkbox') {
				setCheckedCheckboxResponse(!checkedCheckboxResponse)
			} else if (fieldtype === 'radio') {
				setCheckedRadioResponse(!checkedRadioResponse)
			} else if (fieldtype === 'date') {
				setCheckedDateResponse(!checkedDateResponse)
			}

			// console.log(formDetails)
			var detailsCopy = JSON.parse(JSON.stringify(formDetails))
			var responsables = detailsCopy.responsables && (Object).keys(detailsCopy.responsables.length > 0) ? detailsCopy.responsables : []
			var formCopy = JSON.parse(JSON.stringify(formData))
			// console.log(detailsStructure)
			formCopy[page].rows[row].content[0].cols[col].content.responsable = checked;
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details[fieldtype].responsable = checked
			var data = new FormData();
			if (checked === true) {
				responsables.push({[id]: formCopy[page].rows[row].content[0].cols[col].content.label });
			} else {
				responsables = responsables.filter(e => Object.keys(e)[0] !== id)
				// if (Object.keys(responsables) === 0){
				// 	data.append('emptyRes',true);
				// }
				// responsables.push({[id]: formCopy[page].rows[row].content[0].cols[col].content.label});
			}
			data.append('formId', form_id);
			data.append('responsables', JSON.stringify(responsables));
			axios.post(API_BASE_URL + '/api/changeResponsable', data)
				.then(response => {
					setSettingsData(settingsCopy)
					saveForm(null, formCopy)
					showMessageSuccess('Successfully saved.')
				})
				.catch(error => {
					showMessageError('Error on save.')
				})
			// setFormData(formCopy)
			// showMessage('Settings changed')
		// } catch (error) {
		// 	console.log(error)
		// 	// showMessageError('Something went wrong.')
		// }
	}

	const filterChange = (page, row, col, fieldtype, checked) => {
		try {

			if (fieldtype == 'dropdown') {
				setCheckedDropdownFilter(!checkedDropdownFilter)
			} else if (fieldtype == 'checkbox') {
				setCheckedCheckboxFilter(!checkedCheckboxFilter)
			} else if (fieldtype == 'radio') {
				setCheckedRadioFilter(!checkedRadioFilter)
			}
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.filter = checked;
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details[fieldtype].filter = checked
			setSettingsData(settingsCopy)
			setFormData(formCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeType = (page, row, col, type) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.typeinput = type;
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.input.typeinput = type
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeAlignment = (page, row, col, type) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].align = type;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.image.align = type
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeFit = (page, row, col, type) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].fit = type;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.image.fit = type
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeWidth = (page, row, col, type) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].width = type;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.image.width = type
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeHeight = (page, row, col, height) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].height = height;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.image.height = height;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const resetColSettings = e => {
		try {
			var PageIndex = +settingsData.info.page;
			var RowIndex = +settingsData.info.row;
			var ColIndex = +settingsData.info.col;
			var formCopy = JSON.parse(JSON.stringify(formData))
			var formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
			var tabname = ''
			if (!settingsData.details.color && !settingsData.details.dateRange) {
				tabname = formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].tabname
			}
			if (settingsData.details.dateRange) {
				tabname = 'dateRange';
			}
			// console.log('ok');
			// setSettingsData({})
			// setFormData([])
			if (tabname == 'image') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].width = 'auto';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].height = 'auto';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].fit = 'cover';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].align = 'center';
				var newSettingsData = {
					info: {
						page: PageIndex,
						row: RowIndex,
						col: ColIndex,
					},
					details: {
						image: {
							width: 'auto',
							height: 'auto',
							align: 'center',
							fit: 'cover'
						}
					}
				}
				// setFormData(formCopy)
				setSettingsData(newSettingsData)
			}
			if (tabname == 'text') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.text.required = false
				settingsCopy.details.text.responsable = false
				setSettingsData(settingsCopy)
				setCheckedText(false)
			}
			if (tabname == 'dropdown') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.filter = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				setCheckedDropdown(false)
				setCheckedDropdownFilter(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.dropdown.responsable = false
				settingsCopy.details.dropdown.required = false
				settingsCopy.details.dropdown.filter = false
				setSettingsData(settingsCopy)
			}
			if (tabname == 'input') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.typeinput = 'text';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				setCheckedInput(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.input.responsable = false
				settingsCopy.details.input.required = false
				settingsCopy.details.input.typeinput = 'text'
				setSettingsData(settingsCopy)
			}
			if (tabname == 'checkbox') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.filter = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				setCheckedCheckbox(false)
				setCheckedCheckboxFilter(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.checkbox.responsable = false
				settingsCopy.details.checkbox.required = false
				settingsCopy.details.checkbox.filter = false
				setSettingsData(settingsCopy)
			}
			if (tabname == 'radio') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.filter = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				setCheckedRadio(false)
				setCheckedRadioFilter(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.radio.responsable = false
				settingsCopy.details.radio.required = false
				settingsCopy.details.radio.filter = false
				setSettingsData(settingsCopy)
			}
			if (tabname == 'paragraph') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				setCheckedParagraph(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.paragraph.required = false
				setSettingsData(settingsCopy)
			}
			if (tabname == 'upload') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.limit = 1;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.size = {
					min: 0,
					max: 10,
				};
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.text = 'Select files';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.types = 'pdf, doc, docx, jpg, jpeg, png, webp';
				var newSettingsData = {
					info: {
						page: PageIndex,
						row: RowIndex,
						col: ColIndex,
					},
					details: {
						upload: {
							required: false,
							limit: 1,
							size: {
								min: 0,
								max: 10,
							},
							text: 'Select files',
							reachable: 'pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif, webp',
							types: 'pdf, doc, docx, jpg, jpeg, png, webp',
						}
					}
				}
				setSettingsData(newSettingsData)
				setCheckedUpload(false)
			}
			if (tabname == 'date') {
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.required = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].separator = '/';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].defaultDateLabel = 'None';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].defaultDateValue = '';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].checkedTime = false;
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].timeFormat = '24';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].defaultTimeLabel = 'None';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].defaultTimeValue = 'HH:MM';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content.responsable = false;
				var sep = '/';
				formCopy[PageIndex].rows[RowIndex].content[0].cols[ColIndex].typeDate = 'dd' + sep + 'MM' + sep + 'yyyy';
				setCheckedDate(false)
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.date.responsable = false
				settingsCopy.details.date.required = false
				settingsCopy.details.date.separator = '/';
				settingsCopy.details.date.defaultDateLabel = 'None';
				settingsCopy.details.date.defaultDateValue = '';
				settingsCopy.details.date.checkedTime = false;
				settingsCopy.details.date.timeFormat = '24';
				settingsCopy.details.date.defaultTimeLabel = 'None';
				settingsCopy.details.date.defaultTimeValue = 'HH:MM';
				settingsCopy.details.date.typeDate = 'dd' + sep + 'MM' + sep + 'yyyy';
				setSettingsData(settingsCopy)
			}
			if (tabname == 'dateRange') {
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.dateRange.countdown = '00:00:00';
				settingsCopy.details.dateRange.deadline = '';
				setSettingsData(settingsCopy)
				var data = {}
				data.formId = form_id;

				axios.post(API_BASE_URL + '/api/resetDateRange', data)
					.then(response => {
						// showMessageSuccess('Successfully saved.')
					})
					.catch(error => {
						// showMessageError('Error on save.')
					})
			}
			if (tabname == '') {
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				formCopy[0].opacity = '1';
				settingsCopy.details.opacity = '1';
				formCopy[0].color = 'default';
				settingsCopy.details.color = 'default';
				setSettingsData(settingsCopy)
			}
			saveForm(e, formCopy)
			// showMessage('Settings changed')
			// document.querySelector('body').classList.toggle('customize-box');

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeLimit = (page, row, col, limit) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.limit = limit;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.upload.limit = limit;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeSize = (page, row, col, size) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.size.max = size;
			// setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.upload.size.max = size;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeTypes = (page, row, col, elem, checked) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			// console.log(e);
			if (!checked) {
				// console.log();
				var array = formCopy[page].rows[row].content[0].cols[col].content.types.split(', ')
				array.push(elem);
				array = array.join(', ')
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.upload.types = array
				formCopy[page].rows[row].content[0].cols[col].content.types = array
				setSettingsData(settingsCopy)
			} else {
				var array = formCopy[page].rows[row].content[0].cols[col].content.types.split(', ');
				var index = array.indexOf(elem)
				var spliced = array.splice(index, 1)
				array = array.join(', ')
				var settingsCopy = JSON.parse(JSON.stringify(settingsData))
				settingsCopy.details.upload.types = array;
				formCopy[page].rows[row].content[0].cols[col].content.types = array
				setSettingsData(settingsCopy)
			}
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeText = (page, row, col, text) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].content.text = text;
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.upload.text = text;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeSeparator = (page, row, col, separator) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var oldSeparator = formCopy[page].rows[row].content[0].cols[col].separator
			var oldTypeDate = formCopy[page].rows[row].content[0].cols[col].typeDate
			var newTypeDate = oldTypeDate.split(oldSeparator).join(separator)
			formCopy[page].rows[row].content[0].cols[col].separator = separator;
			formCopy[page].rows[row].content[0].cols[col].typeDate = newTypeDate;
			setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.date.separator = separator;
			settingsCopy.details.date.typeDate = newTypeDate;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeDateType = (page, row, col, typedate) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].typeDate = typedate;
			setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.date.typeDate = typedate;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
			// showMessage('Settings changed')
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeDefaultDate = (page, row, col, date, label) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var typeDate = formCopy[page].rows[row].content[0].cols[col].typeDate;
			settingsCopy.details.date.defaultDateLabel = label;
			formCopy[page].rows[row].content[0].cols[col].defaultDateLabel = label;
			if (label == 'None') {
				formCopy[page].rows[row].content[0].cols[col].defaultDateValue = '';
				settingsCopy.details.date.defaultDateValue = '';
			} else if (label == 'Current') {
				setCheckedDate(false)
				formCopy[page].rows[row].content[0].cols[col].content.required = false;
				settingsCopy.details.date.required = false
				formCopy[page].rows[row].content[0].cols[col].defaultDateValue = date;
				settingsCopy.details.date.defaultDateValue = date;
			} else if (label == 'Custom') {
				setCheckedDate(false)
				formCopy[page].rows[row].content[0].cols[col].content.required = false;
				settingsCopy.details.date.required = false
				formCopy[page].rows[row].content[0].cols[col].defaultDateValue = date;
				settingsCopy.details.date.defaultDateValue = date;
			} else if (label == 'Range') {
				// console.log(date)
				setCheckedDate(false)
				var joinedDate = ''; 
				if (date.start && date.end) {
					joinedDate = date.start + ' - ' + date.end; 
				}
				formCopy[page].rows[row].content[0].cols[col].content.required = false;
				settingsCopy.details.date.required = false
				formCopy[page].rows[row].content[0].cols[col].defaultDateValue = joinedDate;
				settingsCopy.details.date.defaultDateValue = joinedDate;
			}
			setFormData(formCopy)
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const checkedTimeChange = (page, row, col, check) => {
		try {
			setCheckedTime(!checkedTime)
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].checkedTime = check;
			setFormData(formCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeFormatTime = (page, row, col, format) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			formCopy[page].rows[row].content[0].cols[col].timeFormat = format;
			setFormData(formCopy)
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.date.timeFormat = format;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeTime = (page, row, col, label) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			formCopy[page].rows[row].content[0].cols[col].defaultTimeLabel = label;
			settingsCopy.details.date.defaultTimeLabel = label;
			if (label === 'None') {
				formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = 'HH:MM';
				settingsCopy.details.date.defaultTimeValue = 'HH:MM';
			}
			else if (label === 'Current') {
				var nowTime = moment(new Date()).format('HH:mm')
				formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = nowTime;
				settingsCopy.details.date.defaultTimeValue = nowTime;
			}
			else if (label === 'Custom') {
				var nowTime = moment(new Date()).format('HH:mm')
				formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = nowTime;
				settingsCopy.details.date.defaultTimeValue = nowTime;
			}
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeTimeDayNow = (page, row, col, hour) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var oldTime = formCopy[page].rows[row].content[0].cols[col].defaultTimeValue;
			oldTime = oldTime.split(':')
			// if (formCopy[page].rows[row].content[0].cols[col].timeFormat == 'AM/PM') {
			// 	if (hour > 10) {
			// 		oldTime[0] = hour - 10
			// 	}
			// } else {
			// 	oldTime[0] = hour
			// }
			oldTime[0] = hour
			oldTime = oldTime.join(':')
			var newTime = oldTime
			// console.log(oldTime);
			formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = oldTime;
			settingsCopy.details.date.defaultTimeValue = oldTime;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeTimeHourNow = (page, row, col, hour) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var oldTime = formCopy[page].rows[row].content[0].cols[col].defaultTimeValue;
			oldTime = oldTime.split(':')
			// if (formCopy[page].rows[row].content[0].cols[col].timeFormat == 'AM/PM') {
			// 	if (hour > 10) {
			// 		oldTime[0] = hour - 10
			// 	}
			// } else {
			// 	oldTime[0] = hour
			// }
			oldTime[0] = hour
			oldTime = oldTime.join(':')
			var newTime = oldTime
			// console.log(oldTime);
			formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = oldTime;
			settingsCopy.details.date.defaultTimeValue = oldTime;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeTimeMinuteNow = (page, row, col, minute) => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var oldTime = formCopy[page].rows[row].content[0].cols[col].defaultTimeValue;
			oldTime = oldTime.split(':')
			oldTime[1] = minute
			oldTime = oldTime.join(':')
			var newTime = oldTime
			formCopy[page].rows[row].content[0].cols[col].defaultTimeValue = oldTime;
			settingsCopy.details.date.defaultTimeValue = oldTime;
			setSettingsData(settingsCopy)
			saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeBackgroundColor = color => {
		try {
			// var formCopy = JSON.parse(JSON.stringify(formData))
			var pageFormCopy = JSON.parse(JSON.stringify(form))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			if (color == 'default') {
				pageFormCopy.opacity = '1';
				settingsCopy.details.opacity = '1';
			}
			
			pageFormCopy.color = color;
			settingsCopy.details.color = color;
			setSettingsData(settingsCopy)
			let data = {};
			data.formId = form_id;
			data.opacity = pageFormCopy.opacity;
			data.color = color;
			setForm(pageFormCopy)
			axios.post(API_BASE_URL + '/api/changeColor', data)
				.then(response => {
					showMessageSuccess('Successfully saved.')
				})
				.catch(error => {
					// showMessageError('Error on save.')
				})
			// saveForm(null, formCopy)

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeBackgroundOpacity = opacity => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var pageFormCopy = JSON.parse(JSON.stringify(form))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			// var colorsDataInfo = colorsData;
			// colorsDataInfo[1].opacity = opacity;
			settingsCopy.details.opacity = opacity;
			setSettingsData(settingsCopy)
			pageFormCopy.opacity = opacity;
			let data = {};
			data.formId = form_id;
			data.opacity = opacity;
			setForm(pageFormCopy)
			axios.post(API_BASE_URL + '/api/changeColorOpacity', data)
				.then(response => {
					showMessageSuccess('Successfully saved.')
				})
				.catch(error => {
					// showMessageError('Error on save.')
				})

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const addNewColor = color => {
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var pageFormCopy = JSON.parse(JSON.stringify(form))
			var countCustoms = 0;
			var newColor = true;
			(Object).values(settingsCopy.details.colors).map(e => e.includes(color) ? newColor = false : null)
			if (!newColor) {
				showMessageError('This color already exists')
			} else {

				(Object).keys(settingsCopy.details.colors).map(e => e.includes('user') ? countCustoms++ : null)
				settingsCopy.details.colors['user_' + (countCustoms + 1)] = color
				settingsCopy.details.color = 'user_' + (countCustoms + 1)
				formCopy[0].colors = settingsCopy.details.colors;
				formCopy[0].color = 'user_' + (countCustoms + 1);

				if (formCopy[0].opacity == '1') {
					settingsCopy.details.opacity = '0.1';
					formCopy[0].opacity = '0.1'
				}

				setSettingsData(settingsCopy)
				pageFormCopy.color = 'user_' + (countCustoms + 1);
				// pageFormCopy.opacity = '0.1'
				let data = {};
				data.formId = form_id;
				// data.opacity = '0.1';
				data.color = 'user_' + (countCustoms + 1);
				data.colors = settingsCopy.details.colors
				setForm(pageFormCopy)

				axios.post(API_BASE_URL + '/api/addColor', data)
					.then(response => {
						showMessageSuccess('Successfully saved.')
					})
					.catch(error => {
						// showMessageError('Error on save.')
					})
				// saveForm(null, formCopy)
			}

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	
	const removeUserColor = (color) => {
		try {

			var formCopy = JSON.parse(JSON.stringify(formData))
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			var pageFormCopy = JSON.parse(JSON.stringify(form))
			delete formCopy[0].colors[color]
			delete settingsCopy.details.colors[color]
			var userColors = [];
			var defaultColors = {};
			let data = {};

			(Object).entries(settingsCopy.details.colors).map((e, index) => e[0].includes('user')
				? userColors[e[0].split('_')[0] + '_' + ((Object).keys(userColors).length + 1)] = e[1]
				: defaultColors[e[0]] = e[1]);
			if ((Object).keys(userColors).length > 0) {
				settingsCopy.details.color = 'user_' + ((Object).keys(userColors).length);
				data.color = 'user_' + ((Object).keys(userColors).length);
				formCopy[0].color = 'user_' + ((Object).keys(userColors).length);
				pageFormCopy.color = 'user_' + ((Object).keys(userColors).length);
				pageFormCopy.opacity = settingsCopy.details.opacity;
				(Object).entries(userColors).map(e => defaultColors[e[0]] = e[1])
			} else {
				settingsCopy.details.color = 'default';
				data.color = 'default';
				settingsCopy.details.opacity = '1';
				pageFormCopy.color = 'default';
				pageFormCopy.opacity = '1';
				formCopy[0].color = 'default';
				formCopy[0].opacity = '1';
			}

			formCopy[0].colors = defaultColors;
			settingsCopy.details.colors = defaultColors;
			setSettingsData(settingsCopy)
			data.formId = form_id;
			data.colors = settingsCopy.details.colors;
			setForm(pageFormCopy)
			axios.post(API_BASE_URL + '/api/removeColor', data)
				.then(response => {
					showMessageSuccess('Successfully saved.')
				})
				.catch(error => {
					// showMessageError('Error on save.')
				})
			
			// saveForm(null, formCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const changeDeadline = deadline => {
		try {
			let settingsCopy = JSON.parse(JSON.stringify(settingsData))
			// if (moment(deadline).format('MM/DD/YYYY HH:mm') != settingsCopy.details.dateRange.deadline) {

			settingsCopy.details.dateRange.deadline = deadline === 'empty' ? null : deadline;
			setSettingsData(settingsCopy)
			let data = {}
			data.formId = form_id;
			data.deadline = deadline === 'empty' ? null : moment(deadline).format('MM/DD/YYYY HH:mm');
			axios.post(API_BASE_URL + '/api/changeDeadline', data)
				.then(response => {
					showMessageSuccess('Successfully saved.')
					setDataRangeChange(false)
				})
				.catch(error => {
					// showMessageError('Error on save.')
				})
			// }
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const changeCountdown = ( value) => {
		try {
			var settingsCopy = JSON.parse(JSON.stringify(settingsData))
			settingsCopy.details.dateRange.countdown = value;
			setSettingsData(settingsCopy)
			var data = {}
			data.formId = form_id;
			data.countdown = value;

			axios.post(API_BASE_URL + '/api/changeCountdown', data)
				.then(response => {
					showMessageSuccess('Successfully saved.')
				})
				.catch(error => {
					// showMessageError('Error on save.')
				})
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}


	const closeDeadline = ()=>{
		document.querySelectorAll('.customize-btn, .customize-close ').forEach(muzeCustomizerToggle => {
			muzeCustomizerToggle.addEventListener('click', (e) => {
				e.stopPropagation();
				document.querySelector('body').classList.toggle('customize-box');
				setOpen(false)
				
			});
		});
	}

	

	return (
		<div className="customize-sidebar">
			<div className="border-gray-200 p-3 pb-3">
				<div className="text-end sidebar-close">
					<button type="button" className="btn btn-light btn-icon rounded-pill customize-close" onClick={closeDeadline}>
						<svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 16 16">
							<rect data-name="Icons/Tabler/Close background" width={16} height={16} fill="none" />
							<path d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z" transform="translate(2 2)" fill="#1E1E1E" />
						</svg>
					</button>
				</div>
				<div className="px-2 px-md-4">
					<h3 className="mb-0"><span><i className="fas fa-cog customize-btn"></i></span> Settings</h3>
					{ settingsData.details.color
						? null 
						: settingsData.details.dateRange
							? <p className="text-gray-700 mb-0 lh-lg">Set the response date range</p>
							: <p className="text-gray-700 mb-0 lh-lg">Edit the column settings</p>
						}
				</div>
			</div>
			
			<div className="customize-body" data-simplebar>
				{
					settingsData.details.input
						?
						<FormSettingsInput formData={formData} responseChange={responseChange} settingsData={settingsData} checkedInput={checkedInput} checkedInputResponse={checkedInputResponse} requiredChange={requiredChange} changeType={changeType} />
						: (
							settingsData.details.dropdown
								?
								<FormSettingsDropdown responseChange={responseChange} checkedDropdownResponse={checkedDropdownResponse} settingsData={settingsData} checkedDropdown={checkedDropdown} checkedDropdownFilter={checkedDropdownFilter} filterChange={filterChange} requiredChange={requiredChange} />
								:
								(
									settingsData.details.text
										? <FormSettingsText responseChange={responseChange} checkedTextResponse={checkedTextResponse} settingsData={settingsData} checkedText={checkedText} requiredChange={requiredChange} />
										:
										(
											settingsData.details.checkbox
												? <FormSettingsCheckbox responseChange={responseChange} checkedCheckboxResponse={checkedCheckboxResponse} settingsData={settingsData} checkedCheckbox={checkedCheckbox} checkedCheckboxFilter={checkedCheckboxFilter} filterChange={filterChange} requiredChange={requiredChange} />
												:
												(
													settingsData.details.radio
														? <FormSettingsRadio responseChange={responseChange} checkedRadioResponse={checkedRadioResponse} settingsData={settingsData} checkedRadio={checkedRadio} checkedRadioFilter={checkedRadioFilter} filterChange={filterChange} requiredChange={requiredChange} />
														:
														(
															settingsData.details.paragraph
																? <FormSettingsParagraph settingsData={settingsData} checkedParagraph={checkedParagraph} requiredChange={requiredChange} />
																:
																(
																	settingsData.details.upload
																		? <FormSettingsUpload changeText={changeText} changeTypes={changeTypes} changeSize={changeSize} changeLimit={changeLimit} settingsData={settingsData} checkedUpload={checkedUpload} requiredChange={requiredChange} />
																		:
																		(
																			settingsData.details.date
																				? <FormSettingsDate responseChange={responseChange} checkedDateResponse={checkedDateResponse} settingsData={settingsData} changeTimeHourNow={changeTimeHourNow} changeTimeMinuteNow={changeTimeMinuteNow} changeTime={changeTime} changeFormatTime={changeFormatTime} checkedTime={checkedTime} checkedTimeChange={checkedTimeChange} checkedDate={checkedDate} requiredChange={requiredChange} changeSeparator={changeSeparator} changeDateType={changeDateType} changeDefaultDate={changeDefaultDate} />
																				:
																				(
																					settingsData.details.color
																						? <FormSettingsColor settingsData={settingsData} colorsData={colorsData} removeUserColor={removeUserColor} changeBackgroundColor={changeBackgroundColor} changeBackgroundOpacity={changeBackgroundOpacity} addNewColor={addNewColor} form={form} setForm={setForm} />
																						:
																						(
																							settingsData.details.divider
																								? <FormSettingsDivider settingsData={settingsData} />
																								:
																								(
																									settingsData.details.dateRange
																										? <FormSettingsDateRange open={open} setOpen={setOpen} setDataRangeChange={setDataRangeChange} settingsData={settingsData} changeDeadline={changeDeadline} changeCountdown={changeCountdown}/>
																										:
																										(
																											settingsData.details.image
																												?
																												<FormSettingsImage settingsData={settingsData} changeAlignment={changeAlignment} changeFit={changeFit} changeWidth={changeWidth} changeHeight={changeHeight} />
																												: null
																										)
																								)
																						)
																				)
																		)
																)
														)
												)
										)
								)
						)
				}
			</div>
			<div className="p-4 px-lg-5 border-top border-gray-200 bg-white form-settings-bottom">
				<div className="row">
					{!settingsData.details.color?
					<div className="col-6 d-grid">
						<button type="button" className="btn btn-xl btn-outline-danger " id="ResetCustomizer" page={settingsData.info.page} row={settingsData.info.row} col={settingsData.info.col} onClick={resetColSettings}>Reset</button>
					</div>:null
					}
					<div className="col-6 d-grid">
						<button type="button" className="btn btn-xl settings-button-close customize-close" id="CustomizerPreview" onClick={closeDeadline}>Close</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormSettings;