import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context';
import { API_BASE_URL, AxiosConfigs } from '../config';
import { Link, useHistory } from "react-router-dom";
import { eraseCookie, getCookie, setCookie } from '../cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralInfoChangeFullNameData, setGeneralInfoChangeFullName, getInfoData, getInfoDataLoading, getNotifications, getNotificationsData, getGeneralInfoDataLoading } from '../redux/reducers/infoReducer';
import Loader from '../UI/Loader/Loader';
import { getIsEdited, setIsEdited } from '../redux/reducers/dataReducer';
import Swal from 'sweetalert2';
import axios from 'axios';


const Header = (props) => {
	// const { myInfo, setMyInfo } = props;
	const loading = useSelector(getInfoDataLoading)
	const myInfo = useSelector(getInfoData)
	const isEditedForm = useSelector(getIsEdited)
	const notifications = useSelector(getNotificationsData)
	// const notificationsError = useSelector(getNotificationsErrorData)
	const { isAuth, setIsAuth } = useContext(AuthContext);
	const isEdited = useSelector(getIsEdited)
	const toggledMenu = useRef();
	const generalInformationFullName = useSelector(getGeneralInfoChangeFullNameData)
	const toggleMenu = e => {
		toggledMenu.current.classList.toggle('active')
	}
	const dispatch = useDispatch()
	const history = useHistory()
	useEffect(() => {
		if ((Object).keys(myInfo).length) {
			const data = {};
			data.id = myInfo._id;
			dispatch(getNotifications(data))
		}
	}, [myInfo])

	const goProfile = e => {
		localStorage.setItem('navigation',true);
		history.push('/profile')

	}

	useEffect(()=>{
		dispatch(setGeneralInfoChangeFullName({lastName:myInfo.lastname, firstName:myInfo.firstname}));
	}, [myInfo])

	const changeRoute = (link) => {
		if (isEditedForm) {
			Swal.fire({
				title: 'Leave without saving?',
				text: 'Are you sure you want to leave this page without saving? Any unsaved information will be deleted.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				confirmButtonText: 'Leave page',
				cancelButtonText: 'Cancel',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				}
			}).then((result, e) => {
				if (result.isConfirmed) {
					// setFormData([])
					dispatch(setIsEdited(false))
					history.push(link)
				}
			})
		} else {
			history.push(link)
		}
	}

	const signout = e => {
		// if (isEdited){
		// 	window.addEventListener('beforeunload', (event) => {
		// 		event.returnValue = 'You have unfinished changes!';
		// 	});
		// 	window.onbeforeunload = function(event) {event.returnValue = 'You have unfinished changes!';}
		// } else {
		if (isEditedForm) {
			Swal.fire({
				title: 'Leave without saving?',
				text: 'Are you sure you want to leave this page without saving? Any unsaved information will be deleted.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				confirmButtonText: 'Leave page',
				cancelButtonText: 'Cancel',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				}
			}).then((result, e) => {
				if (result.isConfirmed) {
					if (getCookie('_rmfo')) { eraseCookie('_rmfo') }
					localStorage.removeItem('auth')
					localStorage.removeItem('authUser')
					localStorage.removeItem('signin')
					setIsAuth(false)
				}
			})
		} else {
			if (getCookie('_rmfo')) { eraseCookie('_rmfo') }
			localStorage.removeItem('auth')
			localStorage.removeItem('authUser')
			localStorage.removeItem('signin')
			setIsAuth(false)
			axios.post(API_BASE_URL + '/api/signout', AxiosConfigs)
			.then(response => {})
			.catch(error => {
				console.log(error);
				
			})
		}
		// }
		// window.location.reload()
	}

	document.documentElement.addEventListener("click", function (e) {
		try {
			if (!e.target.closest('.avatar-div') && !e.target.closest('.dropdown-menu-navbar')) {
				if (toggledMenu.current) {
					toggledMenu.current.classList.remove('active')
				}
			}
		} catch (error) { throw error }
	})

	return (
		<div className="header border-bottom border-gray-200 header-fixed">
			<div className="container-fluid px-0">
				<div className="header-body px-3 px-xxl-5 py-3 py-lg-4">
					<div className="row align-items-center">
						<div className="muze-hamburger d-block d-lg-none col-auto backArrowCursor" style={{ marginTop: -2 + 'px' }}>
							<img src="/svg/icons/hamburger1.svg" alt="img" />
							<img src="/svg/icons/close1.svg" style={{ width: '20px' }} className="menu-close" alt="img" />
						</div>
						<Link to="/" className="navbar-brand mx-auto d-lg-none col-auto px-0" >
							<img src="/logo-final.png" alt="FormBuilder" width="150" />
							<img src="/logo_one.jpg" alt="FormBuilder" className="white-logo" />
						</Link>
						<div className="col d-flex align-items-center backArrowButton">
							<div className="back-arrow backArrowCursor bg-white circle circle-sm shadow border border-gray-200 rounded mb-0">
								<svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 16 16">
									<g data-name="icons/tabler/chevrons-left" transform="translate(0)">
										<rect data-name="Icons/Tabler/Chevrons Left background" width={16} height={16} fill="none" />
										<path d="M14.468,14.531l-.107-.093-6.4-6.4a.961.961,0,0,1-.094-1.25l.094-.107,6.4-6.4a.96.96,0,0,1,1.451,1.25l-.094.108L10,7.36l5.72,5.721a.961.961,0,0,1,.094,1.25l-.094.107a.96.96,0,0,1-1.25.093Zm-7.68,0-.107-.093-6.4-6.4a.961.961,0,0,1-.093-1.25l.093-.107,6.4-6.4a.96.96,0,0,1,1.45,1.25l-.093.108L2.318,7.36l5.72,5.721a.96.96,0,0,1,.093,1.25l-.093.107a.96.96,0,0,1-1.25.093Z" transform="translate(0 1)" fill="#6C757D" />
									</g>
								</svg>
							</div>
							{/* <div className="ps-3 header-search">
								<form>
									<div className="input-group bg-white border border-gray-300 rounded py-1 px-3">
										<img src="/svg/icons/search@14.svg" alt="Search" />
										<input type="search" className="form-control border-0" placeholder="Search..." />
									</div>
								</form>
								<span className="muze-search d-lg-none ms-3">
									<svg id="icons_tabler_close" data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 16 16">
										<rect data-name="Icons/Tabler/Close background" width={16} height={16} fill="none" />
										<path d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z" transform="translate(2 2)" fill="#1e1e1e" />
									</svg>
								</span>
							</div> */}
						</div>
						<div className="col-auto d-flex flex-wrap align-items-center icon-blue-hover ps-0">
							{/* <a href="#" className="d-lg-none muze-search">
								<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 14 14">
									<rect id="Icons_Tabler_Search_background" data-name="Icons/Tabler/Search background" width={14} height={14} fill="none" />
									<path data-name="Combined Shape" d="M13.141,13.895l-.06-.052L9.1,9.859A5.569,5.569,0,1,1,9.859,9.1l3.983,3.983a.539.539,0,0,1-.7.813ZM1.077,5.564A4.487,4.487,0,1,0,5.564,1.077,4.492,4.492,0,0,0,1.077,5.564Z" fill="#1e1e1e" />
								</svg>
							</a> */}
							{/* <div className="upgrade-plan"><Link to="/profile"><img src="/img/Another/up-arrow.png" alt="" /></Link></div> */}
							{
								myInfo?.plan && myInfo?.plan === 'Free' || myInfo?.plan === 'Standart'
								?  
								<div className="upgrade-plan">
									<button type="button" onClick={() => goProfile()}>
										{/* <img src="/img/Another/up-arrow.png" alt="" /> */}
										Upgrade
									</button>
								</div>
								: null
							}
							<div className="dropdown grid-option">
								<a href="#" className="text-dark ms-2  mb-0 notification" data-bs-toggle="dropdown" aria-expanded="false" id="notification">
									<svg id="Icons_tabler_notification" data-name="Icons/tabler/notification" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
										<rect id="Icons_Tabler_Notification_background" data-name="Icons/Tabler/Notification background" width={24} height={24} fill="none" />
										<path d="M6.162,19.63l-.005-.246v-.308H.926A.923.923,0,0,1,.471,17.35a4,4,0,0,0,1.956-2.66l.036-.229V10.726A9.492,9.492,0,0,1,7.292,2.873l.147-.08,0-.018A3.369,3.369,0,0,1,10.566.007L10.771,0a3.379,3.379,0,0,1,3.287,2.573l.045.22.147.08a9.556,9.556,0,0,1,4.806,7.541l.023.355-.007,3.582a4.016,4.016,0,0,0,2,3,.924.924,0,0,1-.329,1.719l-.126.008H15.387v.308a4.616,4.616,0,0,1-9.225.246ZM8,19.385a2.769,2.769,0,0,0,5.532.189l.007-.189v-.308H8ZM9.242,3.228l-.012.238-.005.045L9.2,3.63l-.039.113-.054.107-.035.055L9,4l-.036.038-.05.046-.1.074L8.7,4.219A7.7,7.7,0,0,0,4.332,10.46l-.022.309-.007,3.8a5.875,5.875,0,0,1-.94,2.541l-.084.119H18.266l-.007-.012a6.007,6.007,0,0,1-.983-2.452l-.043-.306V10.812a7.674,7.674,0,0,0-4.4-6.593.919.919,0,0,1-.518-.7l-.009-.132a1.538,1.538,0,0,0-3.069-.157Z" transform="translate(1.499)" fill="#1e1e1e" />
									</svg>
									{
										(Object).keys(notifications).length > 0 && notifications != 'disabled'
											?
											<sup className="status bg-warning">&nbsp;</sup>
											: null
									}
								</a>
								<div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="notification">
									<div className="dropdown-header d-flex align-items-center px-4 py-2 border-bottom border-gray-200">
										<span className="fs-16 Montserrat-font font-weight-semibold text-black-600">Notifications</span>
									</div>
									<div className="tab-content" id="myTabContent">
										<div className="tab-pane fade show active" id="Inbox" role="tabpanel" aria-labelledby="home-tab">
											<div className="dropdown-body notifications-body" data-simplebar>
												{
													(Object).keys(notifications).length > 0 && notifications != 'disabled'
														?
														(Object).values(notifications).map((notify, index) => {
															return <button type="button" onMouseDown={() => changeRoute('/responses/' + notify.form_id)} className="dropdown-item text-wrap unread notification-body" key={index}>
																<div className='notification-content'>
																	<div className="notification-info">
																		<span className="avatar-status avatar-sm-status position-relative me-2 end-0 bottom-0">&nbsp;</span>
																		{/* <div className="notification-info-avatar">
																			<p className="avatar-letter avatar-letter-small">{notify.title.split('')[0]}</p>
																		</div> */}
																		<div className="notification-info-email">
																			<p title={notify.title}>{
																				notify.title.length > 20
																					? notify.title.substring(0, 20) + ' ...'
																					: notify.title
																			}
																			</p>
																		</div>
																	</div>
																	<div className="notification-data">
																		<p>{
																			notify.formatedDate
																		}</p>
																	</div>
																</div>
																{/* <div className="media">
																	<span className="d-flex align-items-center infouser">
																		<span className="avatar-status avatar-sm-status avatar-primary position-relative me-2 end-0 bottom-0">&nbsp;</span>
																		<div className="avatar avatar-sm avatar-circle ms-3 ms-xxl-3">
																			<img className="avatar-img" src="/img/avatar-sm4.png" />
																		</div>
																	</span>
																	<div className="media-body ps-1">
																		<div className="d-flex align-items-center">
																			<span className="fs-16 font-weight-semibold dropdown-title">{notify.email}</span>
																			<span className="fs-16 font-weight-semibold tiny text-gray-600 ms-auto px-2">{notify.formatedDate}</span>
																		</div>
																	</div>
																</div> */}
															</button>
														})
														: (notifications == 'disabled'
															? <div className="dropdown-item text-wrap notify-text">
																<p className='mb-0'>Your Notifications are disabled. <br></br> Please enable from <Link to="/profile">profile</Link> page settings</p>
															</div>
															: <div className="dropdown-item text-wrap ">
																<p className='mb-0'>No notifications yet</p>
															</div>
														)
												}
											</div>
										</div>
									</div>
									{/* <div className="dropdown-footer text-center py-2 border-top border-gray-50">
										<a href="#" className="btn btn-link link-dark my-2">View all<svg className="ms-2" data-name="Icons/Tabler/Chevron Down" xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 10 10">
											<rect data-name="Icons/Tabler/Chevron Right background" width={10} height={10} fill="none" />
											<path d="M.163.163A.556.556,0,0,1,.886.109L.948.163,5.393,4.607a.556.556,0,0,1,.054.723l-.054.062L.948,9.837a.556.556,0,0,1-.839-.723l.054-.062L4.214,5,.163.948A.556.556,0,0,1,.109.225Z" transform="translate(2.5)" fill="#1E1E1E" />
										</svg>
										</a>
									</div> */}
								</div>
							</div>
							{/* <div className="dropdown grid-option">
								<a href="#" className="text-dark ms-4 ms-xxl-5 h5 mb-0" data-bs-toggle="dropdown" aria-expanded="false" id="grid">
									<svg data-name="Icons/Tabler/Settings" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
										<rect data-name="Icons/Tabler/apps background" width={24} height={24} fill="none" />
										<path d="M16.115,24a2.4,2.4,0,0,1-2.4-2.4V16.115a2.4,2.4,0,0,1,2.4-2.4H21.6a2.4,2.4,0,0,1,2.4,2.4V21.6A2.4,2.4,0,0,1,21.6,24Zm-.343-7.885V21.6a.343.343,0,0,0,.343.343H21.6a.343.343,0,0,0,.343-.343V16.115a.343.343,0,0,0-.343-.343H16.115A.343.343,0,0,0,15.772,16.115ZM2.4,24A2.4,2.4,0,0,1,0,21.6V16.115a2.4,2.4,0,0,1,2.4-2.4H7.885a2.4,2.4,0,0,1,2.4,2.4V21.6a2.4,2.4,0,0,1-2.4,2.4Zm-.343-7.885V21.6a.343.343,0,0,0,.343.343H7.885a.343.343,0,0,0,.343-.343V16.115a.343.343,0,0,0-.343-.343H2.4A.343.343,0,0,0,2.057,16.115Zm14.058-5.829a2.4,2.4,0,0,1-2.4-2.4V2.4a2.4,2.4,0,0,1,2.4-2.4H21.6A2.4,2.4,0,0,1,24,2.4V7.885a2.4,2.4,0,0,1-2.4,2.4ZM15.772,2.4V7.885a.343.343,0,0,0,.343.343H21.6a.343.343,0,0,0,.343-.343V2.4a.343.343,0,0,0-.343-.343H16.115A.343.343,0,0,0,15.772,2.4ZM2.4,10.286A2.4,2.4,0,0,1,0,7.885V2.4A2.4,2.4,0,0,1,2.4,0H7.885a2.4,2.4,0,0,1,2.4,2.4V7.885a2.4,2.4,0,0,1-2.4,2.4ZM2.057,2.4V7.885a.343.343,0,0,0,.343.343H7.885a.343.343,0,0,0,.343-.343V2.4a.343.343,0,0,0-.343-.343H2.4A.343.343,0,0,0,2.057,2.4Z" transform="translate(0 0)" fill="#1e1e1e" />
									</svg>
								</a>
								<div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="grid">
									<div className="dropdown-header d-flex align-items-center px-4 py-2">
										<span className="fs-16 Montserrat-font font-weight-semibold text-black-600 mb-1 mt-1">Mostly Used</span>
									</div>
									<div className="dropdown-body" data-simplebar>
										<a href="#" className="dropdown-item text-wrap">
											<div className="media align-items-center">
												<span className="me-3">
													<img className="avatar avatar-xs rounded-0" src="/svg/icons/dribbble-icon-1.svg" alt="Icon" />
												</span>
												<div className="media-body">
													<span className="fs-16 font-weight-semibold dropdown-title me-2">My Forms</span>
													<span className="d-block small text-gray-600 mt-1 dropdown-content">Instant stats for your apps and websites</span>
												</div>
											</div>
										</a>
										<a href="#" className="dropdown-item text-wrap">
											<div className="media align-items-center">
												<span className="me-3">
													<img className="avatar avatar-xs rounded-0" src="/svg/icons/google-analytics-3.svg" alt="Icon" />
												</span>
												<div className="media-body">
													<span className="fs-16 font-weight-semibold dropdown-title">Online Forms</span>
													<span className="d-block small text-gray-600 mt-1 dropdown-content">Instant stats for your apps and websites</span>
												</div>
											</div>
										</a>
										<a href="#" className="dropdown-item text-wrap">
											<div className="media align-items-center">
												<span className="me-3">
													<img className="avatar avatar-xs rounded-0" src="/svg/icons/dribbble-icon-1.svg" alt="Icon" />
												</span>
												<div className="media-body">
													<span className="fs-16 font-weight-semibold dropdown-title me-2">Interview Forms</span>
													<span className="d-block small text-gray-600 mt-1 dropdown-content">Instant stats for your apps and websites</span>
												</div>
											</div>
										</a>
										<a href="#" className="dropdown-item text-wrap">
											<div className="media align-items-center">
												<span className="me-3">
													<img className="avatar avatar-xs rounded-0" src="/svg/icons/evernote-icon.svg" alt="Icon" />
												</span>
												<div className="media-body">
													<span className="fs-16 font-weight-semibold dropdown-title">Surveys</span>
													<span className="d-block small text-gray-600 mt-1 dropdown-content">Instant stats for your apps and websites</span>
												</div>
											</div>
										</a>
										<a href="#" className="dropdown-item text-wrap">
											<div className="media align-items-center">
												<span className="me-3">
													<img className="avatar avatar-xs rounded-0" src="/svg/icons/dropbox.svg" alt="Icon" />
												</span>
												<div className="media-body">
													<span className="fs-16 font-weight-semibold dropdown-title">Polls</span>
													<span className="d-block small text-gray-600 mt-1 dropdown-content">Instant stats for your apps and websites</span>
												</div>
											</div>
										</a>
									</div>
									<div className="dropdown-footer text-center py-2 border-top border-gray-50"></div>
								</div>
							</div> */}
							<div className="dropdown profile-dropdown toggleHidenMenu infouser">
								<div className="avatar avatar-sm avatar-circle ms-3 ms-xxl-3 avatar-dropdown" aria-expanded="false" onClick={toggleMenu}>
									{/* <img className="avatar-img" src="/img/avatar1.png" alt="Avatar" /> */}
									{
										loading
											?
											<div className="loader-info">
												<Loader />
											</div>
											:
											(Object).keys(myInfo).length > 0 ?
												(myInfo.avatar === 'firstname'
													?
													(generalInformationFullName.firstName ? <div className="avatar-div" style={{ backgroundColor: myInfo.color }}>
														<p className="avatar-letter avatar-letter-small">{generalInformationFullName.firstName.split('')[0]}</p>
													</div>
														: <div className="avatar-div" style={{ backgroundColor: myInfo.color }}>
															<p className="avatar-letter avatar-letter-small">{myInfo.email.split('')[0]}</p>
														</div>
													)
													: <img className="avatar-img" src={API_BASE_URL + `/files/User/${myInfo._id}/${myInfo.avatar}`} alt="Avatars" />)
												: null
									}
									{/* <span className="avatar-status avatar-sm-status avatar-success">&nbsp;</span> */}
								</div>


								{/* <a className="dropdown-item" href="#" onClick={signout}><i className="fas fa-sign-out-alt signStyle"></i><span className="ms-2">Sign out</span></a> */}
								<ul className="dropdown-menu-navbar" id="dropdown-menu-in-navbar" ref={toggledMenu}>
									<li className="pt-2 px-4 d-flex">
										{/* <span className="fs-16 font-weight-bold text-black-600 Montserrat-font me-2">{myInfo.username}</span><img src="/svg/icons/fill-check.svg" alt="icon" /> */}
										<span className="fs-16 font-weight-bold text-black-600 Montserrat-font me-2">{generalInformationFullName.firstName}</span><img width="20" height="20" src="/svg/icons/fill-check-svg.png" alt="icon" />
										{/* <small className="text-gray-600 pb-3 d-block">{myInfo.email}</small> */}
									</li>
									<li>
										<button type="button" onClick={() => changeRoute('/profile')} className="dropdown-item">
											<img src="/svg/icons/profile11.svg" width={16} height={16} alt="img" />
											{/* <i className="fas fa-user-circle"></i> */}
											{/* <svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16"> <rect id="Icons_Tabler_User_background" data-name="Icons/Tabler/User background" width={16} height={16} fill="none" /> <path d="M11.334,16H.667a.665.665,0,0,1-.661-.568L0,15.343v-1.75A4.179,4.179,0,0,1,4.029,9.44l.193,0H7.778A4.186,4.186,0,0,1,12,13.4l0,.191v1.75a.661.661,0,0,1-.576.651ZM4.222,10.749a2.869,2.869,0,0,0-2.884,2.683l-.005.162v1.094h9.334V13.594A2.857,2.857,0,0,0,8.1,10.767l-.162-.013-.164,0ZM6,8.314A4.2,4.2,0,0,1,1.778,4.157a4.223,4.223,0,0,1,8.445,0A4.2,4.2,0,0,1,6,8.314Zm0-7A2.87,2.87,0,0,0,3.111,4.157a2.889,2.889,0,0,0,5.778,0A2.87,2.87,0,0,0,6,1.313Z" transform="translate(2)" fill="#495057" /> </svg> */}
											{/* <img src="/svg/icons/page.svg" alt="img" /> */}
											{/* <img className="imgInProfileDoc" src="/svg/icons/page.svg" alt="img" /> */}
											<span className="ms-2">Profile</span>
										</button>
									</li>
									{/* <li>
										<Link to="/settings" className="dropdown-item">
											<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
												<path data-name="Combined Shape" d="M6.027,14.449a.8.8,0,0,0-1.193-.494,2.025,2.025,0,0,1-1.063.31,2.086,2.086,0,0,1-1.779-1.069,1.961,1.961,0,0,1,.051-2.03.8.8,0,0,0-.493-1.193,2.03,2.03,0,0,1,0-3.945.8.8,0,0,0,.494-1.193,1.962,1.962,0,0,1-.052-2.03,2.086,2.086,0,0,1,1.78-1.071,2.022,2.022,0,0,1,1.062.31.8.8,0,0,0,1.193-.493,2.03,2.03,0,0,1,3.945,0,.808.808,0,0,0,.472.551.788.788,0,0,0,.305.06.8.8,0,0,0,.417-.117,2.024,2.024,0,0,1,1.062-.31,2.087,2.087,0,0,1,1.78,1.07,1.963,1.963,0,0,1-.052,2.03.8.8,0,0,0,.494,1.192,2.03,2.03,0,0,1,0,3.946.8.8,0,0,0-.494,1.193,1.962,1.962,0,0,1,.052,2.03,2.086,2.086,0,0,1-1.779,1.07,2.025,2.025,0,0,1-1.063-.31.8.8,0,0,0-.722-.056.8.8,0,0,0-.471.55,2.03,2.03,0,0,1-3.945,0Zm0-1.687a2.03,2.03,0,0,1,1.2,1.4.8.8,0,0,0,1.553,0A2.029,2.029,0,0,1,11.8,12.9l.077.042a.78.78,0,0,0,.341.08.822.822,0,0,0,.7-.421.773.773,0,0,0-.02-.8l-.078-.141a2.03,2.03,0,0,1,1.333-2.889.8.8,0,0,0,0-1.552A2.031,2.031,0,0,1,12.9,4.195l.042-.076a.768.768,0,0,0-.042-.757.813.813,0,0,0-.68-.387.793.793,0,0,0-.418.122l-.141.078a2.038,2.038,0,0,1-.916.219,2.02,2.02,0,0,1-.777-.155,2.039,2.039,0,0,1-1.2-1.4l-.029-.1a.8.8,0,0,0-1.524.1A2.027,2.027,0,0,1,4.195,3.1l-.076-.041a.78.78,0,0,0-.341-.08.822.822,0,0,0-.7.422.772.772,0,0,0,.021.8l.078.141A2.029,2.029,0,0,1,1.841,7.223a.8.8,0,0,0,0,1.553A2.029,2.029,0,0,1,3.1,11.8l-.041.077a.768.768,0,0,0,.042.757.815.815,0,0,0,.68.387.791.791,0,0,0,.418-.122l.141-.078a2.027,2.027,0,0,1,1.693-.064ZM4.923,8A3.077,3.077,0,1,1,8,11.077,3.081,3.081,0,0,1,4.923,8ZM6.154,8A1.846,1.846,0,1,0,8,6.154,1.848,1.848,0,0,0,6.154,8Z" fill="#495057" />
											</svg>
											<span className="ms-2">Settings</span>
										</Link>
									</li> */}
									<li><hr className="dropdown-divider" /></li>
									<li>
										<button type='button' className="dropdown-item" onClick={signout}>
											{/* <i className="fas fa-sign-out-alt"></i> */}
											<img src="/svg/icons/log-out11.svg" width={16} height={16} alt="img" />
											<span className="ms-2">Sign out</span>
										</button>
									</li>
								</ul>
							</div>
							<a href="#" className="btn btn-dark btn-lg customize-btn ms-4 ms-xxl-5">
								<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24">
									<rect data-name="Icons/Tabler/Contrast background" width={24} height={24} fill="none" />
									<path d="M0,12A12,12,0,1,1,12,24,12.014,12.014,0,0,1,0,12Zm1.847,0A10.153,10.153,0,1,0,12,1.847,10.165,10.165,0,0,0,1.847,12Zm9.234,6.242,0-.089V5.845A.923.923,0,0,1,12,4.923a7.077,7.077,0,0,1,0,14.153A.923.923,0,0,1,11.081,18.243Z" fill="#fff" />
								</svg>
								<span className="ps-2">Customize</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;