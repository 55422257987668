import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { getResponsesData, getResponsesYear, getResponsesYearData, getResponsesYearDataLoading } from '../../redux/reducers/responseReducer';
import Loader from '../../UI/Loader/Loader';

const ResponseYear = () => {
	const responsesAll = useSelector(getResponsesData)
	const responsesYear = useSelector(getResponsesYearData)
	const loadingYear = useSelector(getResponsesYearDataLoading)
	const [chartYear, setChartYear] = useState(
		{
			series: [{
				name: '2022',
				data: []
			}],
			options: {
				chart: {
					height: 330,
					type: 'area',
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true
				},
				stroke: {
					curve: 'smooth'
				},
				legend: {
					show: true,
					position: 'top',
					horizontalAlign: 'left',
					fontSize: '13px',
					fontFamily: 'Open Sans,sans-serif',
					fontWeight: 400,
					labels: {
						colors: '#6C757D',
					},
					markers: {
						width: 12,
						height: 12,
						strokeWidth: 0,
						strokeColor: '#fff',
						fillColors: ['#0D6EFD', '#00e396', '#6C757D'],
						radius: 12,
					},
				},
				grid: {
					show: true,
					borderColor: '#E9ECEF',
					xaxis: {
						lines: {
							show: false
						}
					},
					row: {
						colors: undefined,
						opacity: 0
					},
				},
				yaxis: {
					show: true,
					labels: {
						style: {
							colors: '#6C757D',
							fontSize: '13px',
							fontFamily: 'Open Sans,sans-serif',
							fontWeight: 400,
						}
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
						style: {
							colors: '#6C757D',
							fontSize: '13px',
							fontFamily: 'Open Sans,sans-serif',
							fontWeight: 400,
						}
					},
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					stroke: {
						width: 0,
					},
					tooltip: {
						enabled: false,
					}
				},
			}
		}
	)
	useEffect(() => {
		if (responsesYear) {
			var keys = []
			var values = []
			for (const key in responsesYear) {
				keys.push(responsesYear[key])
				values.push(key)
			}
			var chartYearCopy = JSON.parse(JSON.stringify(chartYear))
			setChartYear([])
			chartYearCopy.series[0].data = keys
			chartYearCopy.options.xaxis.categories = values
			setChartYear(chartYearCopy)
		}
	}, [responsesYear])

	return (
		<div className='row mb-4'>
			<div className="col-12">
				<div className="card rounded-12 shadow-dark-80">
					<div className="card-body px-0">
						<div className="d-flex align-items-center border-bottom border-gray-200 pb-3 mb-2 px-3 px-md-4">
							<h5 className="card-header-title mb-0 font-weight-semibold ps-md-2">Overview</h5>
						</div>
						<div className="card-body-inner px-3 px-md-4">
							{
								loadingYear
									? <Loader />
									:
									(Object.keys(responsesYear).length > 0
										? <Chart options={chartYear.options} series={chartYear.series} type="area" height={400} />
										: <p>No Data</p>)
							}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResponseYear;