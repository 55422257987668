import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import { AuthContext } from '../context';

const CheckUser = () => {
	// const { isAuth, isAuthAdmin } = useContext(AuthContext);
	// const [checkUser, setCheckUser] = useState(false)
	// useLayoutEffect(() => {
	// 	if (isAuth || isAuthAdmin) { setCheckUser(true) }
	// 	else { setCheckUser(false) }

	// }, [])
	useEffect(()=>{
		setTimeout(()=>{
			if(localStorage.getItem('authUser')){
				setTimeout(function(){
					var iframeEl = document.getElementById('iFrame')
					iframeEl.contentWindow.postMessage('authUser','https://formbuilder.ai')
				})
			} else {
				setTimeout(function(){
					var iframeEl = document.getElementById('iFrame')
					iframeEl.contentWindow.postMessage('authUserNone','https://formbuilder.ai')
				})
			}
		},1000)
	},[])
	return (
		<div>
			<iframe src="https://formbuilder.ai/" title="iFrame" id="iFrame" seamless width="200" height="200" frameBorder="0"
		style={{display: 'none'}} />
			{/* {checkUser
				? <p id='checkUser'>TRUE</p>
				: <p id='checkUser'>FALSE</p>
			} */}
		</div>
	);
};

export default CheckUser;