import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { APP_BASE_URL, API_BASE_URL } from '../../config';
import { useParams } from 'react-router';
import { Pagination } from 'react-laravel-paginex'
import { MDBDataTableV5 } from 'mdbreact';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import { getMyFormsData } from '../../redux/reducers/formReducer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoData, getNotifications } from '../../redux/reducers/infoReducer';

import Chart from "react-apexcharts";
import {
	getResponses,
	getTrashedResponses,
	getResponsesData,
	getResponsesDataLoading,
	getResponsesTrashedData,
	getResponsesTrashedDataLoading,
	getResponsesFilters,
	getResponsesLabels,
	getResponsesMonth,
	getResponsesToday,
	getResponsesYear,
	setResponses,
	setTrashedResponses,
	getResponsesFavorites
} from '../../redux/reducers/responseReducer';
import ResponseMonth from './ResponseMonth';
import ResponseToday from './ResponseToday';
import AllResponses from './AllResponses';
import Loader from '../../UI/Loader/Loader';
import ResponseYear from './ResponseYear';
import ResponseWeek from './ResponseWeek';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const ResponseTrashed = (props) => {
	// const { myInfo, setMyInfo } = props;
	const myInfo = useSelector(getInfoData)
	// const responsesMonth = useSelector(getResponsesMonthData)
	// const loadingMonth = useSelector(getResponsesMonthDataLoading)
	const [allResponses, setAllResponses] = useState({})
	const favorites = useSelector(getResponsesFavorites)
	const [allTrashedResponses, setAllTrashedResponses] = useState({})
	const responses = useSelector(getResponsesData)
	const trashedResponses = useSelector(getResponsesTrashedData)
	const responsesTrashedLoading = useSelector(getResponsesTrashedDataLoading)
	const labels = useSelector(getResponsesLabels)
	const [tabname, setTabname] = useState('All')
	const formList = useSelector(getMyFormsData);
	var countLabelsTrashed = 0
	var countValuesTrashed = 0


	const dispatch = useDispatch()
	const params = useParams();
	const form_id = params.id;
	const formTitle = formList.filter(form => form._id === form_id);
	let formTitleReady;
	if (formTitle[0]) {
		formTitleReady = formTitle[0]['title'];
	}

	const getData = (responses) => {
		let data = {};
		data.formId = form_id;
		// data.formId.tabname = tabname
		axios.post(API_BASE_URL + '/api/getResponsesTrashed?page=' + responses.page, data)
			.then(response => {
				dispatch(setTrashedResponses(response.data))
			}
		);
	}
	useEffect(() => {
		let data = {};
		data.id = form_id;
		data.trashed = true
		data.page = 1;
		dispatch(getResponses(data))
		dispatch(getTrashedResponses(data))
		// dispatch(getResponsesToday(data))
		// dispatch(getResponsesMonth(data))
		// dispatch(getResponsesYear(data))
	}, [])
	useEffect(() => {
		if ((Object).keys(myInfo).length) {
			const data = {};
			data.id = form_id;
			axios.post(API_BASE_URL + '/api/setResponsesViewed', data)
				.then(response => {
					const info = {};
					info.id = myInfo._id;
					async function getNots() {
						await dispatch(getNotifications(info))
					}
					getNots()
				})
				.catch(error => { })

		}
	}, [myInfo])
	// useEffect(() => {
	// 	setAllResponses(responses)
	// }, [responses])
	useEffect(() => {
		setAllTrashedResponses(trashedResponses)
	}, [trashedResponses])


	const tabAll = useRef(null)

	const deleteResponse = (e, responseId) => {
		e.preventDefault()
		var currentTarget = e.currentTarget;
		let pageNum = allTrashedResponses.current_page;
		Swal.fire({
			title: 'Are you sure?',
			text: 'This response will be removed and it will not be possible to restore',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			customClass: {
				cancelButton: 'btn-outline-dark-default'
			},
			confirmButtonText: 'Delete',
			cancelButtonText: 'Close'
		}).then((result) => {
			if (result.isConfirmed) {
				var data = {};
				data.responseId = responseId;
				data.formId = form_id;
				data.pageName = "trashedResponse";
				axios.post(API_BASE_URL + '/api/deleteResponse?page='+ pageNum, data)
					.then(response => {
						dispatch(setTrashedResponses(response.data))
						// setMyTrashedForms(myTrashedFormsCopy)
					})
					.catch(error => { })
				Swal.fire({
					icon: 'error',
					title: 'Form deleted',
					showConfirmButton: false,
					timer: 1500
				})
			}
		})
	}

	const goResponse = (e, id) => {
		// var responsesCopy = JSON.parse(JSON.stringify(allTrashedResponses.data))
		// var responsesDataCopy = JSON.parse(JSON.stringify(allTrashedResponses))
		// const thisResponse = responsesCopy.filter(item => {
		// 	return item._id == id;
		// });
		// const index = responsesCopy.findIndex(item => {
		// 	return item._id == id;
		// });
		// thisResponse[0].status = 'old'
		// responsesCopy[index] = thisResponse[0]
		// setAllTrashedResponses(responsesCopy)
		window.open(
			APP_BASE_URL + "/response/" + id, "_blank")

		let data = {};
		data.id = id;
		axios.post(API_BASE_URL + '/api/changeStatus', data)
			.then(response => { })
			.catch(error => { })
	}

	return (
		<div id="responses">
			<div className="p-3 px-5 py-4">
				<div className="container-fluid px-0">
					<div className="row mb-4">
						<div className="col-12">
							<div className="card rounded-12 shadow-dark-80 border border-gray-50 responses-loader-body">
								<div className="card">
									<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative">
										<li className="nav-item">
											<button type="button" ref={tabAll} className={tabname == 'All' ? "nav-link active" : "nav-link"}>All {allTrashedResponses && allTrashedResponses.length > 0 ? '(' + allTrashedResponses.length + ')' : null}</button>
										</li>
									</ul>
								</div>
								<div>
									<div className="table-responsive mb-0">
										{
											responsesTrashedLoading
												?
												<div className="responses-loader">
													<Loader />
												</div>
												:
												<table className="table card-table table-nowrap">
													<thead>
														<tr>
															{
																labels ?
																	labels.map((label, index) => {
																		if (countLabelsTrashed < 3) {
																			if (label.type == 'input' || label.type == 'text') {
																				countLabelsTrashed++
																				return <th key={index} title={label.label.length > 15 ? label.label : ''}>{label.label.length > 15 ? label.label.substring(0, 15) + ' ...' : label.label}</th>
																			}
																		}
																	})
																	: null
															}
															{/* <th>Response ID</th> */}
															<th>Start Date</th>
															<th>End Date</th>
															{/* <th>Deleted Date</th> */}
															<th className="col-2">Actions</th>
														</tr>
													</thead>
													<tbody className="list">
														{
															allTrashedResponses.data && Object.values(allTrashedResponses.data).length > 0
																? allTrashedResponses.data.map((response, index) => {
																	countValuesTrashed = 0
																	return <tr className={
																		response.status == 'new'
																			? "new-response-tr"
																			: ""
																	} key={index}>
																		{
																			labels.map((label, index) => {
																				if (countValuesTrashed < 3) {
																					if (label.type == 'input' || label.type == 'text') {
																						if (response.response.hasOwnProperty(label.uniqueId)) {
																							countValuesTrashed++
																							return <td key={index}><span onClick={e => goResponse(e, response._id)} className={
																								response.status == 'new'
																									? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																									: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																							}> {response.response[label.uniqueId].value}</span></td>
																						} else {
																							countValuesTrashed++
																							return <td key={index}><span className={
																								response.status == 'new'
																									? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																									: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																							}></span></td>
																						}
																					}
																				}
																			})

																		}
																		{/* <td><button type="button" onClick={e => goResponse(e, response._id)} className='response-link'><span className="font-weight-semibold text-gray-700">{response._id}</span></button></td> */}
																		<td><span className="font-weight-semibold text-gray-700">{response.started_at}</span></td>
																		<td>
																			<span className={
																				response.status == 'new'
																					? "font-weight-semibold text-gray-700 new-response"
																					: "font-weight-semibold text-gray-700"
																			}>{
																					response.updated_at !== 'null'
																						? moment(response.updated_at).format('DD-MM-YYYY HH:MM:SS') 
																						: ''

																				}
																			</span>
																		</td>
																		{/* <td>
																			<span className={
																				response.status == 'new'
																					? "font-weight-semibold text-gray-700 new-response"
																					: "font-weight-semibold text-gray-700"
																			}>{response.deleted_at && response.deleted_at !== 'null'
																				? moment(response.deleted_at).format('YYYY-MM-DD')
																				: ''}
																			</span>
																		</td> */}
																		<td className="col-2">
																			<div className="d-flex align-items-center">
																				<div className='form-link-buttons button-statistics'>
																					<div className='form-link-button-body'>
																						<div className="form-link-button-title-bottom form-link-button-title-hovered">See response</div>
																						<button onClick={e => goResponse(e, response._id)} className={
																							response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-eye"></i></span></button>
																					</div>
																				</div>
																				<div className='form-link-buttons button-statistics'>
																					<div className='form-link-button-body'>
																						<div className="form-link-button-title-bottom form-link-button-title-hovered">Delete response</div>
																						<button onClick={e => deleteResponse(e, response._id)} className='btn form-link-button'><span><i className="fas fa-times"></i></span></button>
																					</div>
																				</div>
																				{/* <div className='form-link-buttons button-statistics'>
																					<div className='form-link-button-body'>
																						<div className="form-link-button-title-bottom form-link-button-title-hovered">Unarchive response</div>
																						<button onClick={e => unArchiveResponse(e, response._id)} className='btn form-link-button'><span><img src="/svg/icons/rotate-right-solid.svg" className={'svg-icon-default'} width="16" alt="" /></span></button>
																					</div>
																				</div> */}
																			</div>

																		</td>
																	</tr>
																})
																: null
														}
													</tbody>
												</table>
										}
									</div>
									{
										allTrashedResponses?.data?.length
											?
											(
												Object.keys(allTrashedResponses.data).length > 0
													?
													<div className="d-flex align-items-center p-3 p-md-4 border-top border-gray-200 responses-table">
														<div className="mx-auto pagination">
															<Pagination
																changePage={getData}
																data={allTrashedResponses}
																buttonIcons={true}
															/>
														</div>
													</div>
													: null
											)
											: <p className="p-4">No Data</p>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default ResponseTrashed;