import React from 'react';
import Loader from '../../UI/Loader/Loader';

const AdminLoginForm = (props) => {
	const { errors, setErrors, loading } = props;

	return (
		<form className="login100-form" onSubmit={props.login}>
			<div className="form-body">
				<span className="login100-form-title">FormBuilder | Admin</span>
				<div className="form-inputs">
					{
						loading
							?
							<div className="signupForm-loader">
								<Loader />
							</div>
							:
							null
					}
					{Object.keys(errors).length > 0 && !errors.hasOwnProperty('email') && !errors.hasOwnProperty('password') &&
						<p className="Authmessage Autherror AuthStyleError"> {errors} </p>}
					<div className="wrap-input100 validate-input" >
						{errors.hasOwnProperty('email') &&
							<p className="Authmessage Autherror AuthStyleError"> {errors.email} </p>}
						<div className="focusdiv">
							<input
								onChange={e => props.setLoginData({ ...props.loginData, email: e.target.value })}
								className="input100"
								type="text"
								placeholder="Email"
							/>
							<span className="focus-input100"></span>
						</div>
					</div>
					<div className="wrap-input100 validate-input" >
						{errors.hasOwnProperty('password') &&
							<p className="Authmessage Autherror AuthStyleError"> {errors.password} </p>}
						<div className="focusdiv">
							<input
								onChange={e => props.setLoginData({ ...props.loginData, password: e.target.value })}
								className="input100"
								type="password"
								placeholder="Password"
							/>
							<span className="focus-input100"></span>
						</div>
					</div>
				</div>
				<div className="login100-form-btn">
					<button className="login100-form-btn">
						Sign In
					</button>
				</div>
			</div>
			<div className="w-full text-center9"></div>
		</form>
	);
};

export default AdminLoginForm;