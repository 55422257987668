import React, { useEffect, useLayoutEffect, useState } from "react";
import { AuthContext } from "./context";
import AppRouter from "./components/AppRouter";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import { API_BASE_URL } from './config'
import { getCookie, setCookie, eraseCookie } from "./cookie";
import Loader from "./UI/Loader/Loader";
import AdminNavbar from "./UI/Navbar/AdminNavbar";

function App() {
	document.title = "FormBuilder";
	const [isAuth, setIsAuth] = useState(false)
	const [isAuthAdmin, setIsAuthAdmin] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		if (getCookie('_rmfo')) {
			var rememberData = getCookie('_rmfo');
			var id = rememberData.split('|')[1]
			localStorage.setItem('auth', 'true')
			localStorage.setItem('authUser', id)
			setIsAuth(true)
		} else {
			if (localStorage.getItem('auth') && localStorage.getItem('authUser')) {
				setIsAuth(true)
			} else {
				setIsAuth(false)
			}
			if (localStorage.getItem('authAdmin') && localStorage.getItem('authUser')) {
				setIsAuthAdmin(true)
			} else {
				setIsAuthAdmin(false)
			}
		}
		setIsLoading(false);
		// console.log(isLoading);
	}, [])

	useEffect(() => {
		if (document.documentElement) {
			if (document.querySelector('.body-page')){

				window.addEventListener('scroll', function (e) {
					if (window.innerWidth > 1400) {
						var scrollYpos = document.documentElement.scrollTop;
						if (scrollYpos > 25) {
							document.querySelector('.body-page').classList.add('scrolledHeader')
						}
						else {
							document.querySelector('.body-page').classList.remove('scrolledHeader')
						}
					}
				});
			}
		}
	}, [])


	return (
		<div className='body-page'>
			{/* <div className='body-page-loader'>
					<Loader/>
				</div> */}
			<AuthContext.Provider value={{
				isAuth,
				setIsAuth,
				isAuthAdmin,
				setIsAuthAdmin,
				isLoading
			}}>
				<Router>
					{/* <Navbar /> */}
					{
						window.location.href.indexOf('admin') > -1
							?
							<AdminNavbar />
							:
							null
					}
					<AppRouter />
				</Router>
			</AuthContext.Provider>
		</div>
	)
}

export default App;
