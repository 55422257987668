import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { APP_BASE_URL, API_BASE_URL } from '../../config';
import { useParams } from 'react-router';
import { Pagination } from 'react-laravel-paginex'
import { MDBDataTableV5 } from 'mdbreact';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoData } from '../../redux/reducers/infoReducer';
import Chart from "react-apexcharts";
import { getMyFormsData } from '../../redux/reducers/formReducer';
import { getResponses, getResponsesData, getResponsesDataLoading, getResponsesLabels, getResponsesMonth, getResponsesToday, getResponsesYear, setResponsesYear } from '../../redux/reducers/responseReducer';
import ResponseMonth from './ResponseMonth';
import ResponseToday from './ResponseToday';
import AllResponses from './AllResponses';
import Loader from '../../UI/Loader/Loader';
import ResponseYear from './ResponseYear';
import ResponseWeek from './ResponseWeek';
import D3Response from "./D3Response";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import AllDataDisplayed from './AllDataDisplayed';


const Statistics = (props) => {
	const params = useParams();
	const form_id = params.id;
	document.title = `FormBuilder - Statistics`;
	// const { myInfo, setMyInfo } = props;
	const myInfo = useSelector(getInfoData)
	// const responsesMonth = useSelector(getResponsesMonthData)
	// const loadingMonth = useSelector(getResponsesMonthDataLoading)
	const [allResponses, setAllResponses] = useState({})
	const responses = useSelector(getResponsesData)
	const responsesLoading = useSelector(getResponsesDataLoading)
	const responsesAll = useSelector(getResponsesData)
	const labels = useSelector(getResponsesLabels)
	const [pathnameTab, setPathnameTab] = useState(window.location.pathname);
	let pathnameSearchResponse = pathnameTab.search('responses');
	let pathnameSearchStatistics = pathnameTab.search('statistics');

	// const [responsesMonth, setResponsesMonth] = useState(0)
	const [datatable, setDatatable] = useState({
		columns: [], rows: []
	});
	const dispatch = useDispatch()

	const formList = useSelector(getMyFormsData);
	let readyForm = formList.filter(form => form._id === form_id);
	let formTitleReady;
	if (readyForm[0]) {
		formTitleReady = readyForm[0]['title'];
	}

	useEffect(() => {
		let data = {};
		data.id = form_id;
		data.page = 1;
		data.pageName = 'Statistics';
		// dispatch(getResponses(data))
		axios.post(API_BASE_URL + '/api/getResponses?page=1', data).then(response => {
			setAllResponses(response.data.responses);
		});
		dispatch(getResponsesToday(data))
		dispatch(getResponsesMonth(data))
		dispatch(getResponsesYear(data))
	}, [])
	// useEffect(() => {
	// 	let data = { formId: {} };
	// 	data.formId.id = form_id;
	// 	data.formId.page = 2;
	// 	data.pageName = 'statistics';
		// axios.post(API_BASE_URL + '/api/getResponses?page=' + responses.page, data).then(response => {
		// 	setAllResponses(response.data.responses);
		// });
	// 	// setAllResponses(responses)
	// },[])
	const getData = (responses) => {
		let data = { formId: {} };
		data.formId.id = form_id;
		data.formId.page = 2;
		axios.post(API_BASE_URL + '/api/getResponses?page=' + responses.page, data).then(response => {
			setAllResponses(response.data.responses);
		});
	}

	const responsesDaysFilter = e => {
		e.preventDefault()
		const filter = e.currentTarget.getAttribute('days')
		let data = {};
		data.id = form_id;
		data.filter = filter;
		axios.post(API_BASE_URL + '/api/getResponsesDaysFilter', data)
			.then(response => {
				setAllResponses(response.data)
				// dispatch(getResponses(response.data))
			})
			.catch(error => { })
	}

	// Datepicker

	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	
	useEffect(() => {
		if (dateRange[0] && dateRange[1]) {
			handleClose()
		}
		if (!dateRange[0] && !dateRange[1]) {
			let data = {};
			data.id = form_id;
			data.page = 1;
			dispatch(getResponses(data))
		}
	}, [dateRange])

	// const [dateState, setDateState] = useState({
	// 	from: undefined,
	// 	to: undefined,
	// });

	// var { from, to } = dateState;
	// const modifiers = { start: from, end: to };

	// const showFromMonth = e => {
	// 	var { from, to } = dateState;
	// 	if (!from) {
	// 		return;
	// 	}
	// 	if (moment(to).diff(moment(from), 'months') < 2) {
	// 		to.getDayPicker().showMonth(from);
	// 	}
	// }

	// const handleFromChange = from => {
	// 	setDateState({ from });
	// }

	// const handleToChange = to => {
	// 	var toDate = to
	// 	setDateState({ ...dateState, to: toDate });
	// }
	// useEffect(() => {
	// 	if (dateState.to) {
	// 		handleClose()
	// 	}
	// }, [dateState])
	const [show, setShow] = useState(false);

	const handleClose = () => {
		let data = {};
		data.id = form_id;
		data.dateTimes = {
			from: moment(dateRange[0]).toLocaleString(),
			to: moment(dateRange[1]).toLocaleString()
		};
		axios.post(API_BASE_URL + '/api/getResponsesYearFilter', data)
			.then(response => {
				dispatch(setResponsesYear(response.data))
			})
			.catch(error => { })
		setShow(false)
	};
	const handleShow = () => setShow(true);
	return (
		<div id="statistics">
			<div className="px-3 px-xxl-5  form-tabs border-bottom border-gray-200 after-header">
				<div className="container-fluid px-0 h-100">
					<div className="row align-items-center h-100">
						<div className="col form-tabs-crumbsTitle d-flex align-items-center justify-content-between h-100">
						<h1 className="h2 mb-0 lh-sm account-tabname" title={formTitleReady?.length > 15 ? formTitleReady : ''}>{formTitleReady?.length > 15 ? formTitleReady.substring(0, 15) + ' ...' : formTitleReady}</h1>
							<div className='crumbsTitle d-flex justify-content-between align-items-end  form-crumbsTitle'>
								{/* <span className="text-uppercase subtabname tiny text-gray-600 Montserrat-font font-weight-semibold">Form <i className="fas fa-arrow-right"></i>{' ' + formTitleReady}</span> */}
								{/* <div className='breadcrumbs'>
									<div 
										className="breadcrumbs__item" linkname='forms'> 
										<Link to={'/form/' + form_id} target="_blank">
											<span>Form</span>
										</Link>
									</div>
									<div 
										className="breadcrumbs__item">
										<Link to={'/responses/' + form_id}>
											<span>Responses</span>
										</Link>
									</div>
								</div> */}
								{/* <ul className="breadcrumb-form">
									<li style={{ borderRadius: '50px 0 0 50px', paddingLeft: '15px' }}>
										<Link to={'/form/' + form_id}>
											<span>Form</span>
										</Link>
									</li>
									<li>
										<Link to={'/responses/' + form_id}>
											<span>Responses</span>
										</Link></li>
									<li className='_active' style={{ borderRadius: '0 50px 50px 0', paddingRight: '15px' }}>
										<Link to={'/statistics/' + form_id}>
											<span>Statistics</span>
										</Link>
									</li>
								</ul> */}

								<ul className="nav nav-tabs nav-tabs-md form-nav-tabs nav-tabs-line position-relative zIndex-0">
										<Link to={'/form/' + form_id}>
											<li className="nav-item">
												<button type="button" className={"nav-link py-1"}>
														<span>Form</span>
												</button>
											</li>
										</Link>
									{
										<Link to={'/responses/' + form_id}>
											<li className="nav-item">
												<button type="button" className={pathnameSearchResponse != -1?"nav-link active py-1":"nav-link py-1"}>
														<span>Responses</span>
												</button>
											</li>
										</Link>
									}
									{
										<Link to={'/statistics/' + form_id}>
											<li className="nav-item">
												<button type="button" className={pathnameSearchStatistics != -1?"nav-link active py-1":"nav-link py-1"}>
													<span>Statistics</span>
												</button>
											</li>
										</Link>
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="p-3 p-xxl-5">
				<div className="container-fluid px-0">
					<AllDataDisplayed  formNow={readyForm}/>
					<div className="row mb-4">
						<AllResponses />
						<ResponseToday />
						{/* <ResponseWeek /> */}
						<ResponseMonth />
					</div>
					{/* <div className="row mb-4">
						<div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4">
							<div className="card h-100 rounded-12 shadow-dark-80 row-4">
								<div className="card-body px-0">
									<div className="d-flex align-items-center border-bottom border-gray-200 pb-3 mb-2 px-3 px-md-4">
										<h5 className="card-header-title mb-0 font-weight-semibold ps-md-2">Refferals</h5>
										<div className="ms-auto pe-md-2 dropdown">
											<a href="#" role="button" id="morebtn" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-dark-100 btn-icon btn-sm rounded-circle">
												<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
													<rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
													<path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6c757d" />
												</svg>
											</a>
											<ul className="dropdown-menu" aria-labelledby="morebtn">
												<li className="dropdown-sub-title">
													<span>Options</span>
												</li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Share background" width={16} height={16} fill="none" />
													<path d="M9.846,12.923a3.07,3.07,0,0,1,.1-.768L5.516,9.874a3.077,3.077,0,1,1,0-3.748L9.943,3.845a3.084,3.084,0,1,1,.541,1.106L6.057,7.232a3.087,3.087,0,0,1,0,1.537l4.427,2.281a3.075,3.075,0,1,1-.638,1.874Zm1.231,0a1.846,1.846,0,1,0,.2-.84q-.011.028-.025.055l-.014.025A1.836,1.836,0,0,0,11.077,12.923ZM1.231,8a1.846,1.846,0,0,0,3.487.845.623.623,0,0,1,.027-.061l.017-.031a1.845,1.845,0,0,0,0-1.508l-.017-.031a.622.622,0,0,1-.027-.061A1.846,1.846,0,0,0,1.231,8ZM12.923,4.923a1.846,1.846,0,1,0-1.682-1.086l.013.024q.014.027.025.056A1.848,1.848,0,0,0,12.923,4.923Z" fill="#495057" />
												</svg><span className="ms-2">Share Chart</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect id="Icons_Tabler_Export_background" data-name="Icons/Tabler/Export background" width={16} height={16} fill="none" />
													<path d="M10.318,15.828a.585.585,0,0,1-.042-.78L10.318,15l1.342-1.342H7.61a.585.585,0,0,1-.056-1.168l.056,0h4.05l-1.342-1.342a.583.583,0,0,1-.042-.78l.042-.048a.587.587,0,0,1,.734-.076l.047.034.048.042,2.367,2.369.034.042.021.031.024.042.027.059.022.067.01.049.006.053,0,.044v.04l-.006.054-.014.06-.019.058-.014.032a.625.625,0,0,1-.05.087l-.032.043-.035.039-2.342,2.341a.584.584,0,0,1-.828,0ZM2.147,15.22A2.14,2.14,0,0,1,0,13.166l0-.093V2.147A2.14,2.14,0,0,1,2.053,0l.094,0H7.708l.012,0h0l.01,0h0l.01,0h0l.012,0h0A.573.573,0,0,1,7.929.1l0,0h0a.589.589,0,0,1,.083.068l0,0,3.882,3.883a.583.583,0,0,1,.187.353v.017a.592.592,0,0,1,0,.064v3.9a.585.585,0,0,1-1.168.057l0-.057V5.073H8.39A1.367,1.367,0,0,1,7.026,3.785l0-.077V1.171H2.147a.978.978,0,0,0-.974.909l0,.067V13.073a.978.978,0,0,0,.909.973l.067,0H5.658a.585.585,0,0,1,.057,1.168l-.057,0ZM8.2,3.707a.2.2,0,0,0,.16.193l.034,0H10.1L8.2,2Z" transform="translate(1)" fill="#495057" />
												</svg><span className="ms-2">Export</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Bookmark background" width={16} height={16} fill="none" />
													<path d="M.687,16a.686.686,0,0,1-.681-.595L0,15.314V2.514A2.516,2.516,0,0,1,2.361,0l.153,0H8a2.516,2.516,0,0,1,2.51,2.361l0,.153v12.8A.688.688,0,0,1,9.827,16a.671.671,0,0,1-.27-.057L9.475,15.9,5.257,13.37,1.039,15.9A.684.684,0,0,1,.687,16Zm4.57-4.115a.678.678,0,0,1,.256.05l.1.048L9.142,14.1V2.514A1.139,1.139,0,0,0,8.116,1.377L8,1.372H2.514L2.4,1.377A1.147,1.147,0,0,0,1.377,2.4l-.005.116V14.1L4.9,11.983A.687.687,0,0,1,5.257,11.885Z" transform="translate(3)" fill="#495057" />
												</svg><span className="ms-2">Bookmark</span></a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<path data-name="Combined Shape" d="M6.027,14.449a.8.8,0,0,0-1.193-.494,2.025,2.025,0,0,1-1.063.31,2.086,2.086,0,0,1-1.779-1.069,1.961,1.961,0,0,1,.051-2.03.8.8,0,0,0-.493-1.193,2.03,2.03,0,0,1,0-3.945.8.8,0,0,0,.494-1.193,1.962,1.962,0,0,1-.052-2.03,2.086,2.086,0,0,1,1.78-1.071,2.022,2.022,0,0,1,1.062.31.8.8,0,0,0,1.193-.493,2.03,2.03,0,0,1,3.945,0,.808.808,0,0,0,.472.551.788.788,0,0,0,.305.06.8.8,0,0,0,.417-.117,2.024,2.024,0,0,1,1.062-.31,2.087,2.087,0,0,1,1.78,1.07,1.963,1.963,0,0,1-.052,2.03.8.8,0,0,0,.494,1.192,2.03,2.03,0,0,1,0,3.946.8.8,0,0,0-.494,1.193,1.962,1.962,0,0,1,.052,2.03,2.086,2.086,0,0,1-1.779,1.07,2.025,2.025,0,0,1-1.063-.31.8.8,0,0,0-.722-.056.8.8,0,0,0-.471.55,2.03,2.03,0,0,1-3.945,0Zm0-1.687a2.03,2.03,0,0,1,1.2,1.4.8.8,0,0,0,1.553,0A2.029,2.029,0,0,1,11.8,12.9l.077.042a.78.78,0,0,0,.341.08.822.822,0,0,0,.7-.421.773.773,0,0,0-.02-.8l-.078-.141a2.03,2.03,0,0,1,1.333-2.889.8.8,0,0,0,0-1.552A2.031,2.031,0,0,1,12.9,4.195l.042-.076a.768.768,0,0,0-.042-.757.813.813,0,0,0-.68-.387.793.793,0,0,0-.418.122l-.141.078a2.038,2.038,0,0,1-.916.219,2.02,2.02,0,0,1-.777-.155,2.039,2.039,0,0,1-1.2-1.4l-.029-.1a.8.8,0,0,0-1.524.1A2.027,2.027,0,0,1,4.195,3.1l-.076-.041a.78.78,0,0,0-.341-.08.822.822,0,0,0-.7.422.772.772,0,0,0,.021.8l.078.141A2.029,2.029,0,0,1,1.841,7.223a.8.8,0,0,0,0,1.553A2.029,2.029,0,0,1,3.1,11.8l-.041.077a.768.768,0,0,0,.042.757.815.815,0,0,0,.68.387.791.791,0,0,0,.418-.122l.141-.078a2.027,2.027,0,0,1,1.693-.064ZM4.923,8A3.077,3.077,0,1,1,8,11.077,3.081,3.081,0,0,1,4.923,8ZM6.154,8A1.846,1.846,0,1,0,8,6.154,1.848,1.848,0,0,0,6.154,8Z" fill="#495057" />
												</svg><span className="ms-2">Settings</span></a></li>
											</ul>
										</div>
									</div>
									<div className="d-flex justify-content-center mt-4">
										<div id="MuzePieChartOne" style={{ height: '380px' }} />
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4">
							<div className="card h-100 rounded-12 shadow-dark-80 row-4">
								<div className="card-body px-0">
									<div className="d-flex align-items-center border-bottom border-gray-200 pb-3 mb-2 px-3 px-md-4">
										<h5 className="card-header-title mb-0 font-weight-semibold ps-md-2">Refferals</h5>
										<div className="ms-auto pe-md-2 dropdown">
											<a href="#" role="button" id="morebtn" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-dark-100 btn-icon btn-sm rounded-circle">
												<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
													<rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
													<path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6c757d" />
												</svg>
											</a>
											<ul className="dropdown-menu" aria-labelledby="morebtn">
												<li className="dropdown-sub-title">
													<span>Options</span>
												</li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Share background" width={16} height={16} fill="none" />
													<path d="M9.846,12.923a3.07,3.07,0,0,1,.1-.768L5.516,9.874a3.077,3.077,0,1,1,0-3.748L9.943,3.845a3.084,3.084,0,1,1,.541,1.106L6.057,7.232a3.087,3.087,0,0,1,0,1.537l4.427,2.281a3.075,3.075,0,1,1-.638,1.874Zm1.231,0a1.846,1.846,0,1,0,.2-.84q-.011.028-.025.055l-.014.025A1.836,1.836,0,0,0,11.077,12.923ZM1.231,8a1.846,1.846,0,0,0,3.487.845.623.623,0,0,1,.027-.061l.017-.031a1.845,1.845,0,0,0,0-1.508l-.017-.031a.622.622,0,0,1-.027-.061A1.846,1.846,0,0,0,1.231,8ZM12.923,4.923a1.846,1.846,0,1,0-1.682-1.086l.013.024q.014.027.025.056A1.848,1.848,0,0,0,12.923,4.923Z" fill="#495057" />
												</svg><span className="ms-2">Share Chart</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect id="Icons_Tabler_Export_background" data-name="Icons/Tabler/Export background" width={16} height={16} fill="none" />
													<path d="M10.318,15.828a.585.585,0,0,1-.042-.78L10.318,15l1.342-1.342H7.61a.585.585,0,0,1-.056-1.168l.056,0h4.05l-1.342-1.342a.583.583,0,0,1-.042-.78l.042-.048a.587.587,0,0,1,.734-.076l.047.034.048.042,2.367,2.369.034.042.021.031.024.042.027.059.022.067.01.049.006.053,0,.044v.04l-.006.054-.014.06-.019.058-.014.032a.625.625,0,0,1-.05.087l-.032.043-.035.039-2.342,2.341a.584.584,0,0,1-.828,0ZM2.147,15.22A2.14,2.14,0,0,1,0,13.166l0-.093V2.147A2.14,2.14,0,0,1,2.053,0l.094,0H7.708l.012,0h0l.01,0h0l.01,0h0l.012,0h0A.573.573,0,0,1,7.929.1l0,0h0a.589.589,0,0,1,.083.068l0,0,3.882,3.883a.583.583,0,0,1,.187.353v.017a.592.592,0,0,1,0,.064v3.9a.585.585,0,0,1-1.168.057l0-.057V5.073H8.39A1.367,1.367,0,0,1,7.026,3.785l0-.077V1.171H2.147a.978.978,0,0,0-.974.909l0,.067V13.073a.978.978,0,0,0,.909.973l.067,0H5.658a.585.585,0,0,1,.057,1.168l-.057,0ZM8.2,3.707a.2.2,0,0,0,.16.193l.034,0H10.1L8.2,2Z" transform="translate(1)" fill="#495057" />
												</svg><span className="ms-2">Export</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Bookmark background" width={16} height={16} fill="none" />
													<path d="M.687,16a.686.686,0,0,1-.681-.595L0,15.314V2.514A2.516,2.516,0,0,1,2.361,0l.153,0H8a2.516,2.516,0,0,1,2.51,2.361l0,.153v12.8A.688.688,0,0,1,9.827,16a.671.671,0,0,1-.27-.057L9.475,15.9,5.257,13.37,1.039,15.9A.684.684,0,0,1,.687,16Zm4.57-4.115a.678.678,0,0,1,.256.05l.1.048L9.142,14.1V2.514A1.139,1.139,0,0,0,8.116,1.377L8,1.372H2.514L2.4,1.377A1.147,1.147,0,0,0,1.377,2.4l-.005.116V14.1L4.9,11.983A.687.687,0,0,1,5.257,11.885Z" transform="translate(3)" fill="#495057" />
												</svg><span className="ms-2">Bookmark</span></a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<path data-name="Combined Shape" d="M6.027,14.449a.8.8,0,0,0-1.193-.494,2.025,2.025,0,0,1-1.063.31,2.086,2.086,0,0,1-1.779-1.069,1.961,1.961,0,0,1,.051-2.03.8.8,0,0,0-.493-1.193,2.03,2.03,0,0,1,0-3.945.8.8,0,0,0,.494-1.193,1.962,1.962,0,0,1-.052-2.03,2.086,2.086,0,0,1,1.78-1.071,2.022,2.022,0,0,1,1.062.31.8.8,0,0,0,1.193-.493,2.03,2.03,0,0,1,3.945,0,.808.808,0,0,0,.472.551.788.788,0,0,0,.305.06.8.8,0,0,0,.417-.117,2.024,2.024,0,0,1,1.062-.31,2.087,2.087,0,0,1,1.78,1.07,1.963,1.963,0,0,1-.052,2.03.8.8,0,0,0,.494,1.192,2.03,2.03,0,0,1,0,3.946.8.8,0,0,0-.494,1.193,1.962,1.962,0,0,1,.052,2.03,2.086,2.086,0,0,1-1.779,1.07,2.025,2.025,0,0,1-1.063-.31.8.8,0,0,0-.722-.056.8.8,0,0,0-.471.55,2.03,2.03,0,0,1-3.945,0Zm0-1.687a2.03,2.03,0,0,1,1.2,1.4.8.8,0,0,0,1.553,0A2.029,2.029,0,0,1,11.8,12.9l.077.042a.78.78,0,0,0,.341.08.822.822,0,0,0,.7-.421.773.773,0,0,0-.02-.8l-.078-.141a2.03,2.03,0,0,1,1.333-2.889.8.8,0,0,0,0-1.552A2.031,2.031,0,0,1,12.9,4.195l.042-.076a.768.768,0,0,0-.042-.757.813.813,0,0,0-.68-.387.793.793,0,0,0-.418.122l-.141.078a2.038,2.038,0,0,1-.916.219,2.02,2.02,0,0,1-.777-.155,2.039,2.039,0,0,1-1.2-1.4l-.029-.1a.8.8,0,0,0-1.524.1A2.027,2.027,0,0,1,4.195,3.1l-.076-.041a.78.78,0,0,0-.341-.08.822.822,0,0,0-.7.422.772.772,0,0,0,.021.8l.078.141A2.029,2.029,0,0,1,1.841,7.223a.8.8,0,0,0,0,1.553A2.029,2.029,0,0,1,3.1,11.8l-.041.077a.768.768,0,0,0,.042.757.815.815,0,0,0,.68.387.791.791,0,0,0,.418-.122l.141-.078a2.027,2.027,0,0,1,1.693-.064ZM4.923,8A3.077,3.077,0,1,1,8,11.077,3.081,3.081,0,0,1,4.923,8ZM6.154,8A1.846,1.846,0,1,0,8,6.154,1.848,1.848,0,0,0,6.154,8Z" fill="#495057" />
												</svg><span className="ms-2">Settings</span></a></li>
											</ul>
										</div>
									</div>
									<div className="d-flex justify-content-center mt-4">
										<div id="MuzePieChartOne" style={{ height: '380px' }} />
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4">
							<div className="card h-100 rounded-12 shadow-dark-80 row-4">
								<div className="card-body px-0">
									<div className="d-flex align-items-center border-bottom border-gray-200 pb-3 mb-2 px-3 px-md-4">
										<h5 className="card-header-title mb-0 font-weight-semibold ps-md-2">Refferals</h5>
										<div className="ms-auto pe-md-2 dropdown">
											<a href="#" role="button" id="morebtn" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-dark-100 btn-icon btn-sm rounded-circle">
												<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
													<rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
													<path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6c757d" />
												</svg>
											</a>
											<ul className="dropdown-menu" aria-labelledby="morebtn">
												<li className="dropdown-sub-title">
													<span>Options</span>
												</li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Share background" width={16} height={16} fill="none" />
													<path d="M9.846,12.923a3.07,3.07,0,0,1,.1-.768L5.516,9.874a3.077,3.077,0,1,1,0-3.748L9.943,3.845a3.084,3.084,0,1,1,.541,1.106L6.057,7.232a3.087,3.087,0,0,1,0,1.537l4.427,2.281a3.075,3.075,0,1,1-.638,1.874Zm1.231,0a1.846,1.846,0,1,0,.2-.84q-.011.028-.025.055l-.014.025A1.836,1.836,0,0,0,11.077,12.923ZM1.231,8a1.846,1.846,0,0,0,3.487.845.623.623,0,0,1,.027-.061l.017-.031a1.845,1.845,0,0,0,0-1.508l-.017-.031a.622.622,0,0,1-.027-.061A1.846,1.846,0,0,0,1.231,8ZM12.923,4.923a1.846,1.846,0,1,0-1.682-1.086l.013.024q.014.027.025.056A1.848,1.848,0,0,0,12.923,4.923Z" fill="#495057" />
												</svg><span className="ms-2">Share Chart</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect id="Icons_Tabler_Export_background" data-name="Icons/Tabler/Export background" width={16} height={16} fill="none" />
													<path d="M10.318,15.828a.585.585,0,0,1-.042-.78L10.318,15l1.342-1.342H7.61a.585.585,0,0,1-.056-1.168l.056,0h4.05l-1.342-1.342a.583.583,0,0,1-.042-.78l.042-.048a.587.587,0,0,1,.734-.076l.047.034.048.042,2.367,2.369.034.042.021.031.024.042.027.059.022.067.01.049.006.053,0,.044v.04l-.006.054-.014.06-.019.058-.014.032a.625.625,0,0,1-.05.087l-.032.043-.035.039-2.342,2.341a.584.584,0,0,1-.828,0ZM2.147,15.22A2.14,2.14,0,0,1,0,13.166l0-.093V2.147A2.14,2.14,0,0,1,2.053,0l.094,0H7.708l.012,0h0l.01,0h0l.01,0h0l.012,0h0A.573.573,0,0,1,7.929.1l0,0h0a.589.589,0,0,1,.083.068l0,0,3.882,3.883a.583.583,0,0,1,.187.353v.017a.592.592,0,0,1,0,.064v3.9a.585.585,0,0,1-1.168.057l0-.057V5.073H8.39A1.367,1.367,0,0,1,7.026,3.785l0-.077V1.171H2.147a.978.978,0,0,0-.974.909l0,.067V13.073a.978.978,0,0,0,.909.973l.067,0H5.658a.585.585,0,0,1,.057,1.168l-.057,0ZM8.2,3.707a.2.2,0,0,0,.16.193l.034,0H10.1L8.2,2Z" transform="translate(1)" fill="#495057" />
												</svg><span className="ms-2">Export</span></a></li>
												<li><a className="dropdown-item" href="#"><svg data-name="Icons/Tabler/Share" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<rect data-name="Icons/Tabler/Bookmark background" width={16} height={16} fill="none" />
													<path d="M.687,16a.686.686,0,0,1-.681-.595L0,15.314V2.514A2.516,2.516,0,0,1,2.361,0l.153,0H8a2.516,2.516,0,0,1,2.51,2.361l0,.153v12.8A.688.688,0,0,1,9.827,16a.671.671,0,0,1-.27-.057L9.475,15.9,5.257,13.37,1.039,15.9A.684.684,0,0,1,.687,16Zm4.57-4.115a.678.678,0,0,1,.256.05l.1.048L9.142,14.1V2.514A1.139,1.139,0,0,0,8.116,1.377L8,1.372H2.514L2.4,1.377A1.147,1.147,0,0,0,1.377,2.4l-.005.116V14.1L4.9,11.983A.687.687,0,0,1,5.257,11.885Z" transform="translate(3)" fill="#495057" />
												</svg><span className="ms-2">Bookmark</span></a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
													<path data-name="Combined Shape" d="M6.027,14.449a.8.8,0,0,0-1.193-.494,2.025,2.025,0,0,1-1.063.31,2.086,2.086,0,0,1-1.779-1.069,1.961,1.961,0,0,1,.051-2.03.8.8,0,0,0-.493-1.193,2.03,2.03,0,0,1,0-3.945.8.8,0,0,0,.494-1.193,1.962,1.962,0,0,1-.052-2.03,2.086,2.086,0,0,1,1.78-1.071,2.022,2.022,0,0,1,1.062.31.8.8,0,0,0,1.193-.493,2.03,2.03,0,0,1,3.945,0,.808.808,0,0,0,.472.551.788.788,0,0,0,.305.06.8.8,0,0,0,.417-.117,2.024,2.024,0,0,1,1.062-.31,2.087,2.087,0,0,1,1.78,1.07,1.963,1.963,0,0,1-.052,2.03.8.8,0,0,0,.494,1.192,2.03,2.03,0,0,1,0,3.946.8.8,0,0,0-.494,1.193,1.962,1.962,0,0,1,.052,2.03,2.086,2.086,0,0,1-1.779,1.07,2.025,2.025,0,0,1-1.063-.31.8.8,0,0,0-.722-.056.8.8,0,0,0-.471.55,2.03,2.03,0,0,1-3.945,0Zm0-1.687a2.03,2.03,0,0,1,1.2,1.4.8.8,0,0,0,1.553,0A2.029,2.029,0,0,1,11.8,12.9l.077.042a.78.78,0,0,0,.341.08.822.822,0,0,0,.7-.421.773.773,0,0,0-.02-.8l-.078-.141a2.03,2.03,0,0,1,1.333-2.889.8.8,0,0,0,0-1.552A2.031,2.031,0,0,1,12.9,4.195l.042-.076a.768.768,0,0,0-.042-.757.813.813,0,0,0-.68-.387.793.793,0,0,0-.418.122l-.141.078a2.038,2.038,0,0,1-.916.219,2.02,2.02,0,0,1-.777-.155,2.039,2.039,0,0,1-1.2-1.4l-.029-.1a.8.8,0,0,0-1.524.1A2.027,2.027,0,0,1,4.195,3.1l-.076-.041a.78.78,0,0,0-.341-.08.822.822,0,0,0-.7.422.772.772,0,0,0,.021.8l.078.141A2.029,2.029,0,0,1,1.841,7.223a.8.8,0,0,0,0,1.553A2.029,2.029,0,0,1,3.1,11.8l-.041.077a.768.768,0,0,0,.042.757.815.815,0,0,0,.68.387.791.791,0,0,0,.418-.122l.141-.078a2.027,2.027,0,0,1,1.693-.064ZM4.923,8A3.077,3.077,0,1,1,8,11.077,3.081,3.081,0,0,1,4.923,8ZM6.154,8A1.846,1.846,0,1,0,8,6.154,1.848,1.848,0,0,0,6.154,8Z" fill="#495057" />
												</svg><span className="ms-2">Settings</span></a></li>
											</ul>
										</div>
									</div>
									<div className="d-flex justify-content-center mt-4">
										<div id="MuzePieChartOne" style={{ height: '380px' }} />
									</div>
								</div>
							</div>
						</div>
					</div> */}
					<div className="dropdown export-dropdown d-flex align-items-center datepicker-dropdown mb-4">
						{/* <p className='mStatistics mb-0 datepicker-title'>Custom Date</p> */}
						<div className="px-3 InputFromTo response-daypicker statistics-deypicker">
							<DatePicker
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								placeholderText="Custom date"
								isClearable={true}
							/>
						</div>
					</div>
					<ResponseYear />
					<D3Response allResponses={allResponses} />
				</div>
			</div>
		</div >
	);
};

export default Statistics;