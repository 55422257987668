import React from 'react';

const FormSettingsDivider = () => {
    return (
        <div className="p-4 px-lg-5 border-bottom border-gray-200">
        {/* <div className="d-flex align-items-center">
            <h6 className="font-weight-semibold mb-0">Required</h6>
            <div className="form-check form-switch mb-0 ms-auto" >
                <input className="form-check-input me-0" type="checkbox"
                    page={settingsData.info.page}
                    row={settingsData.info.row}
                    col={settingsData.info.col}
                    checked={checkedParagraph}
                    onChange={requiredChange}
                    fieldtype='paragraph'
                />
            </div>
        </div> */}
        <p className="text-gray-600 pt-2 mb-0">No settings available</p>
    </div>
    );
};

export default FormSettingsDivider;