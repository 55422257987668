import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../../config';

function RedirectUrl(props) {
    const {form, showMessageError, showMessageSuccess} = props
    const [url, setUrl] = useState('')
    const changeUrlData = e => {
        setUrl(e.target.value)
    }
    

    const saveUrl = () => {
        let data = {};
        data.formId = form._id
        data.urlUpdate = url
        axios.post(API_BASE_URL + '/api/saveRedirectUrl', data)
            .then(response => {
                showMessageSuccess('Successfully saved!!')
            })
            .catch(error => {
                showMessageError('Something went wrong.')
            })

    }
    useEffect(()=>{
        setUrl(form.redirect_url)
    }, [form])

    return (
        <div className="mt-3 bg-colors" >
            <div className="w-100 ckChange">
                <>
                    <input 
                        type="text" 
                        className='text-input p-1' 
                        placeholder='Please put the link...'
                        value={url?url:''} 
                        onChange={e=>changeUrlData(e)}
                    />
                </>
                <div className="pt-3 d-flex justify-content-end">
                    <button type='button' title="Change text"
                        onClick={saveUrl}
                        className="btn btn-m settings-button-close customize-close">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RedirectUrl;