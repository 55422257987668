import React from 'react';

const FormSettingsDropdown = (props) => {
	const { settingsData, checkedDropdown, checkedDropdownFilter, filterChange, requiredChange, responseChange, checkedDropdownResponse } = props;

	const changeRequiredField = e => {
		requiredChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, 'dropdown', e.target.checked)
	}

	const changeResponseField = e => {
		responseChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, settingsData.details.dropdown.uniqueId, 'dropdown',e.target.checked)
	}

	const changeFilterField = e => {
		filterChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, 'dropdown', e.target.checked)
	}

	return (
		<div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Required</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedDropdown}
							onChange={changeRequiredField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Is the field required?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">In response</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedDropdownResponse}
							onChange={changeResponseField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Whould you like to see this field on responses page?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Filter</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedDropdownFilter}
							onChange={changeFilterField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Should this field be in the filters?</p>
			</div>
		</div>
	);
};

export default FormSettingsDropdown;