import axios from 'axios';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { API_BASE_URL, AxiosConfigs } from '../../config';
import Row from './FormComponents/Row';
import MediaQuery from 'react-responsive'
import moment from 'moment';
import Loader from '../../UI/Loader/Loader';
import { eraseCookie, getCookie, setCookie } from '../../cookie';

const Preview = () => {
	const params = useParams();
	const form_id = params.id;
	const string = params.string;
	document.title = `FormBuilder - Preview ${form_id}`;
	const [pageStyle, setPageStyle] = useState('desktop');
	const messageEdit = useRef();
	const [pageForm, setPageForm] = useState({});
	const formReset = useRef();
	const [files, setFiles] = useState({})
	const defaultMessage = 'Please fill all required field';
	const [messagePreview, setMessagePreview] = useState('')
	// const [startDate, setStartDate] = useState('')
	const [submitShow, setSubmitShow] = useState(false)
	const [loadingForm, setLoadingForm] = useState(false)

	useEffect(() => {
		// setStartDate(moment(new Date()).format("DD/MM/YYYY H:mm:ss"))
		if (!getCookie('_fsuf' + form_id)) {
			setCookie('_fsuf' + form_id, moment(new Date()).format("DD/MM/YYYY H:mm:ss"), 365)
		}
	}, [])
	const [dataForm, setDataForm] = useState({
		id: form_id,
		email: ''
	})
	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setMessagePreview(message)
		} catch (error) { throw error }
	}
	const [formData, setFormData] = useState([
		{
			id: 1,
			title: '',
			color: 'default',
			opacity: '0.1',
			colors: [],
			rows: [
				{
					id: 1,
					content: [
						{
							cols: [
								{
									id: 1,
									tabname: '',
									content: [],
								}
							],
						}
					]
				}
			],
			button: false,
		}
	]);
	const [isShowSubmit, setIsShowSubmit] = useState(false)

	// let allTabnameInPreview = [];
	// let filterTabname = allTabnameInPreview.filter(tName => 
	// 	tName.includes('input') || 
	// 	tName.includes('text')|| 
	// 	tName.includes('dropdown') || 
	// 	tName.includes('radio') || 
	// 	tName.includes('checkbox') || 
	// 	tName.includes('upload') || 
	// 	tName.includes('date'))

	const [paginate, setPaginate] = useState({
		page: 0,
		prev: false,
		next: false,
	})
	useEffect(() => {
		setPaginate({ ...paginate, next: formData && formData.length == 1 ? false : true })
	}, [formData])
	const [checkAdd, setCheckAdd] = useState({});
	const [userErrorsAll, setUserErrorsAll] = useState([])
	// const [header, setHeader] = useState('');
	const [emailError, setEmailError] = useState('')
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		let data = {};
		data.id = form_id;
		data.string = string;
		data.preview = true;
		var formCopy = JSON.parse(JSON.stringify(formData))
		axios.post(API_BASE_URL + '/api/getForm', data, AxiosConfigs)
			.then(response => {
				// setColor(response.data[1])
				setFormData(response.data[0])
				setLoading(false)
				if (response.data.length > 1) {
					setPageForm(response.data[1])
					// setHeader(response.data[1].header)
				}
			})
			.catch(error => {
				window.location.href = window.location.origin + '/'
			})

	}, [])
	const types = ['divider', 'empty', 'image', 'paragraph'];
	useEffect(() => {
		loop: for (let i = 0; i < formData.length; i++) {
			const forJ = formData[i].rows;
			for (let j = 0; j < forJ.length; j++) {
				const forK = forJ[j].content[0].cols
				for (let k = 0; k < forK.length; k++) {
					if (forK[k].tabname) {
						if (!types.includes(forK[k].tabname)) {
							setIsShowSubmit(true)
							break loop;
						}
					}
				}
			}
		}
	}, [formData])
	const checkSubmit = e => {
		e.preventDefault()
		if (e.currentTarget.getAttribute('page') == formData.length - 1) { setSubmitShow(true) }
		else { setSubmitShow(false) }
	}
	const checkPrev = e => {
		e.preventDefault()
		var prevPage = e.currentTarget.getAttribute('prevpage')
		if (prevPage == 0) {
			setPaginate({ ...paginate, prev: false })
		} else {
			e.currentTarget.setAttribute('href', '#page_' + (+prevPage - 1))
			e.currentTarget.setAttribute('prevpage', +prevPage - 1)
			e.currentTarget.classList.remove('active')
		}
		setSubmitShow(false)
		setPaginate({ ...paginate, next: true, page: +prevPage })
	}

	const checkNext = e => {
		e.preventDefault()
		var nextPage = e.currentTarget.getAttribute('nextpage')
		if (nextPage == formData.length - 1) {
			setPaginate({ ...paginate, next: false })
			setSubmitShow(true)
		} else {
			e.currentTarget.setAttribute('href', '#page_' + (+nextPage + 1))
			e.currentTarget.setAttribute('nextpage', +nextPage + 1)
			e.currentTarget.classList.remove('active')
			setSubmitShow(false)
		}
		setPaginate({ ...paginate, prev: true, page: +nextPage })
		// if (e.currentTarget.getAttribute('page') == formData.length - 1) { setSubmitShow(true) }
		// else { setSubmitShow(false) }
	}
	const [userData, setUserData] = useState({});
	const applyForm = e => {
		try {

			setLoadingForm(true)
			const copyFormData = JSON.parse(JSON.stringify(formData));
			const requiredFields = []
			var userErrors = []
			for (let i = 0; i < copyFormData.length; i++) {
				const forJ = copyFormData[i].rows;
				for (let j = 0; j < forJ.length; j++) {
					const forK = forJ[j].content[0].cols
					for (let k = 0; k < forK.length; k++) {
						if (forK[k].tabname != 'image') {
							if (forK[k].content.required && forK[k].content.required == true) {
								// requiredFields.push(i + '/' + j + '/' + k)
								requiredFields.push({ 'uniqueId': forK[k].uniqueId, 'label': forK[k].content.label })
							}
						}
					}
				}
			}
			if (requiredFields.length > 0) {
				// var requiredFieldsNames = []
				if (Object.keys(userData).length == 0) {
					requiredFields.map(e => {
						userErrors.push(e)
						// const splittedFields = e.split('/').map(k => +k);
						// requiredFieldsNames.push(copyFormData[splittedFields[0]].rows[splittedFields[1]].content[0].cols[splittedFields[2]].content.label)
					})
					setUserErrorsAll(userErrors)
				} else {
					userErrors = []
					Object.keys(userData).map(e => {
						if (userData[e] == '' || userData[e].value == '') { delete userData[e] }
					})
					for (let i = 0; i < requiredFields.length; i++) {
						// console.log(userData,requiredFields[i]);
						// if(!userData.filter(e => e.uniqueId === requiredFields[i].uniqueId).length > 0){
						// 	userErrors.push(requiredFields[i])
						// }
						if (!userData.hasOwnProperty(requiredFields[i].uniqueId)) {
							userErrors.push(requiredFields[i])
							// const splittedFields = requiredFields[i].split('/').map(e => +e);
							// requiredFieldsNames.push(copyFormData[splittedFields[0]].rows[splittedFields[1]].content[0].cols[splittedFields[2]].content.label)
						}
					}
					setUserErrorsAll(userErrors)
				}
				if (userErrors.length > 0) {
					setLoadingForm(false)
					showMessageError(userErrors.map((e, i) => 
					<div className='errorMessageDIv'>
						<span key={i} className='errorMessageP'>{e.label} </span> 
						<span>field is required</span>
					</div>
					))
				}
			}
			if (userErrors.length == 0) {
				var startDate = getCookie('_fsuf' + form_id);
				const userFormData = new FormData();
				userFormData.append('dataForm', JSON.stringify(dataForm))
				userFormData.append('userData', JSON.stringify(userData));
				userFormData.append('startDate', startDate);
				if ((Object).keys(files) && (Object).keys(files).length) {
					userFormData.append("hasFiles", true);
					(Object).keys(files).map(key => {
						return files[key].forEach((file, index) => {
							if (file.valid) {
								userFormData.append("userFormDataFiles/" + key + '/' + index, file.file);
							}
						});
					})
				}
				axios.post(API_BASE_URL + '/api/saveUserForm', userFormData)
					.then(response => {
						eraseCookie('_fsuf' + form_id)
						setLoadingForm(false)
						userErrors = []
						setEmailError('')
						setUserErrorsAll([])
						formReset.current.reset()
						setUserData({})
						// showMessageSuccess(<p>Thanks for your submitting!</p>)
						// setCookie('_sfnu' + form_id, '_an45xzcasd1213>g8,|' + form_id, 365)
						localStorage.setItem('success', form_id)
						localStorage.setItem('successAdmin', 'true')
						window.location.href = window.location.origin + '/success'

					})
					.catch(error => {
						setLoadingForm(false)
						console.log(error);
						if (error?.response?.data?.error?.email) {
							setEmailError(error.response.data.error.email)
							showMessageError(error.response.data.error.email)
						}
					})
			}
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	return (
		<div 
		id="previewPage" 
		className={"preview user-form-body px-3 px-xxl-5 py-3 py-lg-4 mx-auto bg-filter-50"} 
		// style={{ backgroundColor: `rgba(${formData[0].colors[formData[0].color]},${formData[0].opacity})` }}
		style={{ backgroundColor: pageForm && pageForm.colors 
									? `rgba(${pageForm.colors[pageForm.color]},${pageForm.opacity})`
									: `rgba(${formData[0].colors[formData[0].color]},${formData[0].opacity})` 
			}}
			>
			<div className="page-style">
				<div className="page-style-buttons">
					<button className={pageStyle == 'desktop' ? 'page-style-button active' : 'page-style-button'} onClick={() => setPageStyle('desktop')}>
						<span><i className="fas fa-desktop"></i></span>
						<p className='mb-0'>Desktop</p>
					</button>
					<button className={pageStyle == 'tablet' ? 'page-style-button active' : 'page-style-button'} onClick={() => setPageStyle('tablet')}>
						<span><i className="fas fa-tablet"></i></span>
						<p className='mb-0'>Tablet</p>
					</button>
					<button className={pageStyle == 'phone' ? 'page-style-button active' : 'page-style-button'} onClick={() => setPageStyle('phone')}>
						<span><i className="fas fa-mobile"></i></span>
						<p className='mb-0'>Phone</p>
					</button>
				</div>
				<div className={`page-style-container ${pageStyle}`}>
					<div className="form-changes form-changes-error" ref={messageEdit} >
						<div className='img-div'>
							<svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
									stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</div>
						<div className='succes-div'>
							<p className='paragraph'>{messagePreview}</p>
						</div>
					
					</div>	
					<div className="preview-container col-12">
						<div className="formLoader">
							{
								loadingForm
									?
									<div className="formLoader-loader">
										<Loader />
									</div>
									: null
							}
							{
								loading
									?
									<div className='w-100 d-flex justify-content-center'>
										<Loader />
									</div>
									:
									<form tabname="Form" className="form-pages tab-content " ref={formReset} onSubmit={e=>e.preventDefault()}>
										{formData.map((form, key) => {
											return <div className={key == 0 ? "tab-pane fade show active form-page " : "tab-pane fade form-page"} key={key} id={"page_" + key}>
												{
													formData.length > 1 && key != 0
														?
														<div className="page-number page-number-preview">
															<button type='button' className='page-number-button'>Page {key + 1}</button>
														</div>
														: null
												}
												<div className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3  form-body ">
													{
														form.title
															?
															<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200 form_input-header form-input">
																<h2 className="my-3 user-evented">{pageForm?.header}</h2>
															</div>
															: null
													}
													<div className='form-content '>
														<div className="form-content-body">
															{
																form.rows.map((bform, bkey) => {
																	return <Row
																		checkAdd={checkAdd}
																		setCheckAdd={setCheckAdd}
																		page={key}
																		row={bkey}
																		key={bkey}
																		contentRow={bform}
																		formData={formData}
																		setFormData={setFormData}
																		preview={true}
																		userData={userData}
																		setUserData={setUserData}
																		userErrorsAll={userErrorsAll}
																		setUserErrorsAll={setUserErrorsAll}
																		setDataForm={setDataForm}
																		dataForm={dataForm}
																		emailError={emailError}
																		setEmailError={setEmailError}
																		files={files}
																		setFiles={setFiles}
																	/>
																})
															}
														</div>
													</div>
												</div>
											</div>
										})}
										<div className="text-center preview-form-pages">
											{
												formData.length > 1
													?
													<ul className="nav nav-segment nav-pills page" role="tablist">
														{/* <li className="page__numbers">
												<button type='button' className="page-button page-button-arrow" onClick={prevPage}><span>«</span></button>
											</li> */}
														{
															paginate.prev && paginate.page != 0
																?
																<li className="page__numbers">
																	<a className="page-button page-button-action" prevpage={paginate.page - 1} data-bs-toggle="pill" onClick={checkPrev} href={"#page_" + (+paginate.page - 1)} role="tab">Prev</a>
																</li>
																: null
														}
														{
															paginate.next && paginate.page != formData.length - 1
																?
																<li className="page__numbers">
																	<a className="page-button page-button-action" nextpage={paginate.page + 1} data-bs-toggle="pill" onClick={checkNext} href={"#page_" + (+paginate.page + 1)} role="tab">Next</a>
																</li>
																: null
														}
														{/* {
														formData.map((form, key) => {
															return <li className="page__numbers" key={key}>
																<a className={key == 0 ? "page-button active" : "page-button"} page={key} data-bs-toggle="pill" onClick={checkSubmit} href={"#page_" + key} role="tab" aria-selected={key == 0 ? "true" : "false"}>{key + 1}</a>
															</li>
															// return <li className="nav-item page-buttons" key={key}>
															// 	<a className={key == 0 ? "page-link active page-button" : "page-link page-button"} page={key} data-bs-toggle="pill" href={"#page_" + key} role="tab" aria-selected="true">{key + 1}</a>
															// </li>
														})
													} */}
														{/* <li className="page__numbers">
												<button type='button' className="page-button page-button-arrow" onClick={nextPage}><span>»</span></button>
											</li> */}
													</ul>
													: <div></div>
											}
											{/*{isShowSubmit ?*/}
											{/*	submitShow || formData.length == 1*/}
											{/*		? <button className='btn btn-lg btn-dark px-md-4' type="button" onClick={applyForm}>Submit</button>*/}
											{/*		: null*/}
											{/*	: null*/}
											{/*}*/}
										</div>
									</form>
							}
						</div>
					</div>
				</div>
				{/* <MediaQuery minWidth={1224}>
					<p>You are a desktop or laptop</p>
					<MediaQuery minWidth={1824}>
						<p>You also have a huge screen</p>
					</MediaQuery>
				</MediaQuery>
				<MediaQuery minResolution="2dppx">
					{(matches) =>
						matches
							? <p>You are retina</p>
							: <p>You are not retina</p>
					}
				</MediaQuery> */}
				{/* <iframe src="http://localhost:3000/form/wCtYccy3Y2wqPcosPitspUajdyKR2y22J8sZhtMA/621bd019bff162457f1dbca3" seamless width="768" height="1024"></iframe> */}
			</div>
		</div >
	);
};

export default Preview;