import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from 'react-redux';
import {
    getAdminForms,
    getAdminReports,
    getAdminSearchData,
    getAdminUsers,
    getAdmResponses,
    getGlobalDateRangeInfo,
    getNotSolvedCustomers,
    getNotSolvedMessages,
    getNotSolvedPayments,
    getPayingInfo,
    getSolvedCustomers,
    getSolvedMessages,
    getSolvedPayments,
    getSuspended,
    setAdminLoading,
    setGlobalSearchText,
    setSearchData,
    setGlobalSearchTextPayment,
    getAdminUserTabNameData, getGlobalSearchTextPaymentData, getGlobalPaymentTabNameData
} from '../../../redux/reducers/adminReducer';
import {API_BASE_URL} from "../../../config";


const SearchAdmin = (props) => {
    const {
        table,
        setDataAll,
        setSearchInfo,
        tabNameInSolved,
        tabNameInPayment
    } = props;
    const [searchText, setSearchText] = useState("");
    const searchInput = useRef();
    const dispatch = useDispatch();
    const globalDateRange = useSelector(getGlobalDateRangeInfo);
    const payingInfo = useSelector(getPayingInfo);
    const [checkSearchTextChange, setCheckSearchTextChange] = useState(false)
    const [repetitiveSearchText, setRepetitiveSearchText] = useState(true)
    const searchDataNow = useSelector(getAdminSearchData);
    const tabName = useSelector(getAdminUserTabNameData);
    const getGlobalSearchTextInPayment = useSelector(getGlobalSearchTextPaymentData)
    const getPaymentGlobalTabName = useSelector(getGlobalPaymentTabNameData)

    useEffect(() => {
        if (!searchDataNow && searchDataNow.length == 0) {
            setSearchText('')
        }
    }, [searchDataNow])

    useEffect(() => {
        setSearchText("")
    }, [tabNameInSolved])

    useEffect(() => {
        setSearchText("")

    }, [tabNameInPayment])

    const changeSearch = (e) => {
        e.preventDefault();
        setSearchText(e.target.value)
        if(!checkSearchTextChange){
            setCheckSearchTextChange(true);
        }
        if (!repetitiveSearchText) {
            setRepetitiveSearchText(true)
        }
        if (e.target.value === '') {
            dispatch(setGlobalSearchText(''))
            dispatch(setGlobalSearchTextPayment(''))
            if (table === "User") {
                // dispatch(setPayingInfo(false))
                // setUserDate({});
                // setDateRange([null, null]);
                // dispatch(setGlobalDateRange({}));
                // dispatch(setGlobalSearchText(''));
                let data = {}
                data.globalDateRange = globalDateRange;
                data.payingInfo = payingInfo;
                data.globalSearchTextInfo = e.target.value;
                data.tabName = tabName
                axios.post(API_BASE_URL + "/api/searchUserTextAdmin", data)
                    .then(response => {
                        dispatch(setAdminLoading(false))
                        setDataAll(response.data);
                    })
                    .catch(error => {
                        dispatch(setAdminLoading(false))
                    })
            }

        }
    }

    const submitSearch = (e) => {
        e.preventDefault()
        if(searchText !== '' ) {
            if (repetitiveSearchText) {
                dispatch(setAdminLoading(true))
                setSearchInfo(searchText)

                dispatch(setSearchData(searchText))
                dispatch(setGlobalSearchText(searchText))
                dispatch(setGlobalSearchTextPayment(searchText))
                // if (table === "User") {
                //     dispatch(setPayingInfo(false))
                //     setUserDate({})
                //     setDateRange([null, null])
                // }
                let data = {}
                data.globalDateRange = globalDateRange;
                data.payingInfo = payingInfo;
                data.text = searchText;
                data.globalSearchTextInfo = searchText;
                data.pageName = table;
                data.tabName = tabName
                if (table === "ContactUs" || table === "Customer") {
                    data.tabNameInSolved = tabNameInSolved;
                    axios.post(API_BASE_URL + "/api/searchTextDoubleTable", data)
                        .then(response => {
                            dispatch(setAdminLoading(false))
                            setDataAll(response.data);
                        })
                        .catch(error => {
                        })

                } else if (table === "User") {
                    axios.post(API_BASE_URL + "/api/searchUserTextAdmin", data)
                        .then(response => {
                            dispatch(setAdminLoading(false))
                            setDataAll(response.data);
                        })
                        .catch(error => {
                            dispatch(setAdminLoading(false))
                        })
                } else if (table === "Payment") {
                    console.log('payment_search')
                    data.tabNameInPayment = getPaymentGlobalTabName;
                    axios.post(API_BASE_URL + "/api/searchTextPaymentTable", data)
                        .then(response => {
                            dispatch(setAdminLoading(false))
                            setDataAll(response.data);
                        })
                        .catch(error => {
                        })
                } else {
                    axios.post(API_BASE_URL + "/api/searchTextAdmin", data)
                        .then(response => {
                            dispatch(setAdminLoading(false))
                            setDataAll(response.data);
                        })
                        .catch(error => {
                            dispatch(setAdminLoading(false))
                        })
                }
                setRepetitiveSearchText(false)
            }
        }
    }
    useEffect(() => {
        if (searchText === "" && checkSearchTextChange) {
            dispatch(setSearchData(""))
            setSearchInfo('')
            if (table === "Customer") {
                if (tabNameInSolved === "Not Solved") {
                    dispatch(getNotSolvedCustomers({ page: 1 }))
                } else {
                    dispatch(getSolvedCustomers({ page: 1 }))
                }
            }
            if (table === "ContactUs") {
                if (tabNameInSolved === "Not Solved") {
                    dispatch(getNotSolvedMessages({ page: 1 }))
                } else {
                    dispatch(getSolvedMessages({ page: 1 }))
                }
            }
            if (table === "Payment") {
                if (tabNameInPayment === "Paid") {
                    dispatch(getSolvedPayments({ page: 1 }))
                } else if (tabNameInPayment === "Rejected"){
                    dispatch(getNotSolvedPayments({ page: 1 }))
                }
                else {
                    dispatch(getSuspended({ page: 1 }))
                }
            }
            if (table === "Form") {
                dispatch(getAdminForms({ page: 1 }))
            }
            // if (table === "User") {
            //     let data = {}
            //     data.globalDateRange = globalDateRange;
            //     data.payingInfo = payingInfo;
            //     data.globalSearchTextInfo = searchText;
            //     data.tabName = tabName
            //     axios.post(API_BASE_URL + "/api/searchUserTextAdmin", data)
            //     .then(response => {
            //         dispatch(setAdminLoading(false))
            //         setDataAll(response.data);
            //     })
            //     .catch(error => {
            //         dispatch(setAdminLoading(false))
            //     })
            //     // dispatch(getAdminUsers({ page: 1 }))
            // }
            if (table === 'FormResponse') {
                dispatch(getAdmResponses({ page: 1 }))
            }
            if (table === 'Report') {
                dispatch(getAdminReports({ page: 1 }))
            }
        }
    }, [searchText])

    // if (document.getElementById("searchInput")) {
    //     let input = document.getElementById("searchInput");
    //     input.addEventListener("keypress", function (event) {
    //         if (event.key === "Enter") {
    //             event.preventDefault();
    //             document.getElementById("buttonSearch").click();
    //         }
    //     });
    // }
    return (
        <div className='pe-md-2 btn btn-outline-default text-gray-700 border-gray-700 px-3 search-text-styles '>
            <input
                ref={searchInput}
                id='searchInput'
                type="text"
                className='search-response-input-styles'
                placeholder='Search...'
                value={searchText}
                // onChange={(e) => {
                //     setSearchText(e.target.value)
                // }}
                onChange={changeSearch}
            />
            <button id="buttonSearch" type="button" onClick={submitSearch}
                    className="border-0 bg-transparent p-1 response-text-search-icon"><img
                src="/svg/icons/search@14.svg" alt="Search"/></button>
        </div>
    );
}

export default SearchAdmin;