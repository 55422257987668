import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { useDispatch } from 'react-redux';
import { trim, urlify } from '../../../../help';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';


const Input = (props) => {
	const { formData, setFormData, settingsData, showMessageError, responseCheck, emailError, setEmailError, setSettingsData, dataForm, setDataForm, deleteThisRowContent, rowError, userData, setUserData, col, row, page, changeLabel, checkAdd, preview, setCheckAdd, contentRow } = props;
	const [scroll, setScroll] = useState(false)
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const divFocus = useRef(null)
	const dispatch = useDispatch()
	const [inputTextValueLength, setInputTextValueLength] = useState(false)
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
		
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var typeinput = formCopy[page].rows[row].content[0].cols[col].content.typeinput;
			var responsable = formCopy[page].rows[row].content[0].cols[col].content.responsable ? formCopy[page].rows[row].content[0].cols[col].content.responsable : false;
			var uniqueId = formCopy[page].rows[row].content[0].cols[col].uniqueId;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page,
					row,
					col
				},
				details: {
					input: {
						required,
						typeinput,
						responsable,
						uniqueId
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	// useEffect(()=>{
	// 	if(divFocus.current){
	// 		// let temp = divFocus.current.innerText;
	// 		// divFocus.current.innerText = "";
	// 		// divFocus.current.innerText= temp;
	// 		divFocus.current.focus();
	// 	}
	// }, [contentRow.content[0].cols[col]])
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				dispatch(setIsEdited(true))
			})
		}
	}, [contentRow])
	// const messageShow = (e) => {
	// 	showMessageError('asdasdsa')
	// }

	// const requiredFields = document.querySelectorAll('.form_input-required')
	// for (let i = 0; i < requiredFields.length; i++) {
	// 	var label = requiredFields[i].value
	// 	var newLabel = label + ' *'
	// 	requiredFields[i].value = newLabel

	// }

	const shortTextChange = e => {
		if(e.target.value.length > 200){
			setInputTextValueLength(true)
		} else {
			setInputTextValueLength(false)
		}
		trim(e.target.value)
			? setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: e.target.value, direction: page + '/' + row + '/' + col } })
			: setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: '' })
	}

	const mailSet = e => {
		if (trim(e.target.value)) {
			setDataForm({ ...dataForm, email: e.target.value })
			// setUserData({ ...userData, [page + '/' + row + '/' + col]: 'forDeleteEmail' })
			setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: e.target.value, direction: page + '/' + row + '/' + col } })
		} else {
			setDataForm({ ...dataForm, email: '' })
			// setUserData({ ...userData, [page + '/' + row + '/' + col]: '' })
			setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: '' })
		}
	}
	const toggleOverflow = e => {
		e.preventDefault()
		e.currentTarget.classList.toggle('label-overflow')
	}

	// const checkKeyDown = e => {
	// 	if (e.ctrlKey && e.key === 'x') {
	// 		setUserData({ ...userData, [contentRow.content[0].cols[col].content.label]: '' })
	// 	}
	// }
	// useEffect(() => {
	// document.addEventListener('keydown', e => {
	// });
	// }, [])

	return (
		// <Animated className='animatedDiv styleAnimated' animationIn={checkAdd ? "shake" : ""} animationOut="fadeOut" isVisible={true}  >
		<div
			className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-text">
						<div className="text-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow} >{contentRow.content[0].cols[col].content.label} {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
							}
						</div>
						<div className="text-inputs d-flex justify-content-start h-100">
							<div className="text-input">
								{
									contentRow.content[0].cols[col].content.typeinput == 'email'
										?
										<input
											type="text"
											className={rowError
												?
												"form-input-border required-field"
												: (
													emailError != '' && contentRow.content[0].cols[col].content.typeinput == 'email'
														?
														"form-input-border required-field"
														: "form-input-border"
												)
											}
											onChange={mailSet}
										/>
										:
										(contentRow.content[0].cols[col].content.typeinput == 'number'
											?
											<input
											    type="number"
												min="1"
												className={rowError
													? "form-input-border required-field"
													: "form-input-border"
												}
												// onChange={e => trim(e.target.value) ? setUserData({ ...userData, [page + '/' + row + '/' + col]: e.target.value }) : setUserData({ ...userData, [page + '/' + row + '/' + col]: '' })}
												onChange={e => trim(e.target.value) ? setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: e.target.value, direction: page + '/' + row + '/' + col } }) : setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: '' })}
											// onKeyDown={checkKeyDown}
											/>
											:
											<input
												type="text"
												className={rowError
													?
													"form-input-border required-field"
													: (!inputTextValueLength?
															 "form-input-border" : "form-input-border borderColorRed"
													)
												}
												// className={errors && errors.hasOwnProperty('value') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"
												// }
												// onChange={e => trim(e.target.value) ? setUserData({ ...userData, [page + '/' + row + '/' + col]: e.target.value }) : setUserData({ ...userData, [page + '/' + row + '/' + col]: '' })}
												onChange={e => shortTextChange(e) 
													}
											/>
										)
								}
								{/* {
									emailError != '' && contentRow.content[0].cols[col].content.typeinput == 'email'
										?
										// <label className='form_input row-sublabel row-sublabel-error'>{emailError}</label>
										messageShow
										: null
								} */}
							</div>
						</div>
					</div>
				</div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text  d-flex justify-content-start w-100 h-100">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow} title={contentRow.content[0].cols[col].content.label}>{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
								}
							</div>
							{contentRow.content[0].cols[col].userValue 
							?
							<div className="text-inputs">
								<div className="text-input">
								<div dangerouslySetInnerHTML={{ __html: contentRow.content[0].cols[col].userValue ? urlify(contentRow.content[0].cols[col].userValue) : '' }} className='form-input-border paragraph-response-quill'></div>
									{/* <input
										type="text"
										tabIndex='-1'
										readOnly
										className="form-input-border"
										defaultValue={contentRow.content[0].cols[col].userValue ? contentRow.content[0].cols[col].userValue : ''}
									/> */}
								</div>
							</div>
							:
							<h3 className='d-flex justify-content-start w-100 h-100'>__</h3>
							}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content.responsable
										? <span className='form-content-filter form-content-responsable' title='In response'><i className="fas fa-check"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} ref={divFocus} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label}</div>
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input form_input-required' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											:
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} ref={divFocus} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Label</div>
											// <input type="text" defaultValue="Heading" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
										)
										:
										<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} ref={divFocus} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Label</div>
									// <input type="text" defaultValue="Heading" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<div className="text-inputs">
								<div className="text-input">
									<input type="text" tabIndex='-1' className='form-input-border evented ' />
									{/* <input type="text" defaultValue="Sublabel" className='form_input row-sublabel' /> */}
									{/* {
									contentRow.content[0].cols[col].content.required
										?
										<label
											className='form_input row-sublabel'>This field is required</label>
										: null
									} */}
								</div>
							</div>
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
		// </Animated>
	);
};

export default Input;