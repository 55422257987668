import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../UI/Loader/Loader';
import Chart from "react-apexcharts";
import { getResponsesFull, getResponsesFullData, getResponsesFullDataLoading } from '../../../../redux/reducers/responseReducer';

const StatFormResponses = () => {
	const responses = useSelector(getResponsesFullData)
	const loadingResponse = useSelector(getResponsesFullDataLoading)
	const dispatch = useDispatch()
	// useEffect(() => {
	// 	dispatch(getResponsesFull())
	// }, [])
	const [chart, setChart] = useState(
		{
			series: [],
			options: {
				chart: {
					width: 380,
					type: 'donut',
				},
				labels: [],
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						},
						legend: {
							show: false
						}
					}
				}],
				legend: {
					show: false
				}
			},

		}
	)
	useEffect(() => {
		if ((Object).keys(responses).length > 0) {
			var labelsResp = [];
			var labelsRespTitle = [];
			var seriesResp = [];
			(Object).values(responses).map(elem => {
				if (labelsResp.indexOf(elem.form_id) == -1) {
					labelsResp.push(elem.form_id)
					labelsRespTitle.push(elem.title ? elem.title.title : '')
					seriesResp.push(1)
				} else {
					seriesResp[labelsResp.indexOf(elem.form_id)]++
				}
			})
			var chartCopy = JSON.parse(JSON.stringify(chart))
			chartCopy.options.labels = labelsRespTitle
			chartCopy.series = seriesResp
			setChart(chartCopy)
		}
	}, [responses])
	return (
		loadingResponse
			? <div className="d-flex w-100 justify-content-center pb-4">
				<Loader />
			</div>
			:
			<Chart options={chart.options} series={chart.series} type="donut" width={'100%'} height={250} />
	);
};

export default StatFormResponses;