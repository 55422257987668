export const API_BASE_URL = 'https://api.formbuilder.ai';
export const APP_BASE_URL = window.origin;
export const HOME_BASE_URL = 'http://formbuilder.ai';
export const Google_ClientId = "173606518187-nl027ee3rjte70vp74odps5rp4i64vjv.apps.googleusercontent.com";
export const token = localStorage.getItem('authUser') ? localStorage.getItem('authUser') : ''
export const AxiosConfigs = {
	headers: { Authorization: 'Bearer ' + token }
};
export const getAxiosConfig = () => {
	const token = localStorage.getItem('authUser') ? localStorage.getItem('authUser') : ''
	return  {
		headers: { Authorization: 'Bearer ' + token }
	};
}