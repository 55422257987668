import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmCustomers, getAdminCustomersData, getNotSolvedCustomerData, getNotSolvedCustomers, getSolvedCustomerData, getSolvedCustomers, setAdminLoading, setCustomers } from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';

const AdminCustomers = (props) => {
	const { addData, setModal, setModalData, dataAll, setDataAll, setTableData, setPageNumber, setTabNameInSolved, tabNameInSolved } = props;
	document.title = "FormBuilder - Admin Support";
	const dispatch = useDispatch();
	const [countNotSolved, setCountNotSolved] = useState(1)
	const [countSolved, setCountSolved] = useState(1)
	const [searchInfo, setSearchInfo] = useState('');
	const dataNow = useSelector(getAdminCustomersData)
	const dataNotSolved = useSelector(getNotSolvedCustomerData);
	const dataSolved = useSelector(getSolvedCustomerData);
	const tabName = {
		'Name' : 'name',
		'Surname' : 'surname',
		'Title': 'title',
		'Email': 'email',
		'Message': 'message',
		'Solved': 'solved',
		// 'Sent': 'formatedDate',
		'Sended': 'created_at',
	}
	useEffect(() => {
		setPageNumber(dataNow.current_page)
		setDataAll(dataNotSolved)
		setCountNotSolved(dataNotSolved.total)
	}, [dataNotSolved])

	useEffect(()=>{
		setCountSolved(dataSolved.total)
		if(searchInfo.length === 0 && tabNameInSolved === "Solved"){
			setDataAll(dataSolved)
		};
	}, [dataSolved])

	useEffect(() => {
		// dispatch(getAdmCustomers({ page: 1 }))
		dispatch(getNotSolvedCustomers({ page: 1 }))
		dispatch(getSolvedCustomers({ page: 1 }))
	}, [])

	const getData = (data) => {
		if(dataAll.current_page !== data.page){
			dispatch(setAdminLoading(true))
			data.searchInfo = searchInfo;
			data.tabNameInSolved = tabNameInSolved;
			data.tabName = "Customer";
			axios.post(API_BASE_URL + '/api/getCustomerContactData?page=' + data.page, data)
				.then(response => {
					dispatch(setAdminLoading(false))
					setDataAll(response.data)
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
		}
	}
	useEffect(() => {
		if (tabNameInSolved === "Not Solved") {
			// setDataAll(dataNotSolved)
		    dispatch(getNotSolvedCustomers({ page: 1 }))
		} else {
			setDataAll(dataSolved)
		    // dispatch(getSolvedMessages({ page: 1 }))
		}
	}, [tabNameInSolved])

	return (
		<div className="home__data">
			<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative solved-notsolved">
				{
					dataNotSolved? countNotSolved > 0
						?
						<li className="nav-item">
							<button type="button" onClick={e => setTabNameInSolved('Not Solved')} className={tabNameInSolved == 'Not Solved' ? "nav-link active" : "nav-link"}>Not Solved({countNotSolved})</button>
						</li>
						: null
					:null
				}
				{
					dataSolved? countSolved > 0 ?
						<li className="nav-item">
							<button type="button" onClick={e => setTabNameInSolved('Solved')} className={tabNameInSolved == 'Solved' ? "nav-link active" : "nav-link"}>Solved({countSolved})</button>
						</li>
						: null
					:null
				}
			</ul>
			{dataAll.data ?
				<div className='admin-responses'>
					<AdminTable
						setCountSolved={setCountSolved}
						setCountNotSolved={setCountNotSolved}
						tabName={tabName}
						dataAll={dataAll}
						tabNameInSolved={tabNameInSolved}
						setDataAll={setDataAll}
						table={'Customer'}
						getData={getData}
						setModal={setModal}
						setModalData={setModalData}
						setTableData={setTableData}
						dataNow={dataNow}
						setSearchInfo={setSearchInfo}
						searchInfo={searchInfo}
					/>
				</div> :
				<div className="form-loader">
					<Loader />
				</div>}
		</div >
	);

};

export default AdminCustomers;