import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUsersData, getUsersDataLoading } from '../../../../redux/reducers/dataReducer';
import Loader from '../../../../UI/Loader/Loader';
import Chart from "react-apexcharts";
import { getResponsesFullByMonth, getResponsesFullByMonthData, getResponsesFullByMonthDataLoading } from '../../../../redux/reducers/responseReducer';

const StatFormResponsesMonth = () => {
	const fullData = useSelector(getResponsesFullByMonthData)
	const loading = useSelector(getResponsesFullByMonthDataLoading)
	const [chart, setChart] = useState(
		{
			series: [],
			options: {
				chart: {
					height: 330,
					type: 'area',
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true
				},
				stroke: {
					curve: 'smooth'
				},
				legend: {
					show: true,
					position: 'top',
					horizontalAlign: 'left',
					fontSize: '13px',
					fontFamily: 'Open Sans,sans-serif',
					fontWeight: 400,
					labels: {
						colors: '#6C757D',
					},
					markers: {
						width: 12,
						height: 12,
						strokeWidth: 0,
						strokeColor: '#fff',
						fillColors: ['#0D6EFD', '#00e396', '#6C757D'],
						radius: 12,
					},
				},
				grid: {
					show: true,
					borderColor: '#E9ECEF',
					xaxis: {
						lines: {
							show: false
						}
					},
					row: {
						colors: undefined,
						opacity: 0
					},
				},
				yaxis: {
					show: true,
					labels: {
						style: {
							colors: '#6C757D',
							fontSize: '13px',
							fontFamily: 'Open Sans,sans-serif',
							fontWeight: 400,
						}
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
						style: {
							colors: '#6C757D',
							fontSize: '13px',
							fontFamily: 'Open Sans,sans-serif',
							fontWeight: 400,
						}
					},
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					stroke: {
						width: 0,
					},
					tooltip: {
						enabled: false,
					}
				},
			}

		}
	)
	useEffect(() => {
		if ((Object).keys(fullData).length > 0) {
			var seriesData = []
			var values = []
			var monthNames = {
				"Jan": 1,
				"Feb": 2,
				"Mar": 3,
				"Apr": 4,
				"May": 5,
				"Jun": 6,
				"Jul": 7,
				"Aug": 8,
				"Sep": 9,
				"Oct": 10,
				"Nov": 11,
				"Dec": 12
			};
			for (const year in fullData) {
				for (const month in fullData[year]) {
					if (!values[month]) { values.push(month) }
				}
			}
			var seriesAll = []
			values.sort((a, b) => { return monthNames[a] - monthNames[b] })
			for (const year in fullData) {
				var seriesData = {
					name: year,
					data: []
				}
				for (let i = 0; i < values.length; i++) {
					if (fullData[year][values[i]]) {
						seriesData.data.push(fullData[year][values[i]])
					} else {
						seriesData.data.push(0)
					}
				}
				seriesAll.push(seriesData)
			}
			var chartCopy = JSON.parse(JSON.stringify(chart))
			setChart({})
			chartCopy.series = [seriesData]
			chartCopy.options.xaxis.categories = values
			setChart(chartCopy)
		}
	}, [fullData])
	return (
		<Chart options={chart.options} series={chart.series} type="area" width={'100%'} height={300} />
	);
};

export default StatFormResponsesMonth;