import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { getResponsesData, getResponsesMonth, getResponsesMonthData, getResponsesMonthDataDifference, getResponsesMonthDataLoading } from '../../redux/reducers/responseReducer';
import Loader from '../../UI/Loader/Loader';

const ResponseMonth = () => {
	const responsesMonth = useSelector(getResponsesMonthData)
	const responsesAll = useSelector(getResponsesData)
	const responsesMonthDifference = useSelector(getResponsesMonthDataDifference)
	const loadingMonth = useSelector(getResponsesMonthDataLoading)
	const [chartMonth, setChartMonth] = useState(
		{
			series: [0],
			options: {
				chart: {
					// height: 350,
					type: 'radialBar',
					offsetY: -10
				},
				plotOptions: {
					radialBar: {
						startAngle: -135,
						endAngle: 135,
						dataLabels: {
							name: {
								fontSize: '16px',
								color: undefined,
								offsetY: 70,
							},
							value: {
								offsetY: -7,
								fontSize: '25px',
								color: undefined,
								fontWeight: 900,
								formatter: function (val) {
									return val;
								}
							}
						}
					}
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						shadeIntensity: 0.15,
						inverseColors: false,
						opacityFrom: 1,
						opacityTo: 1,
						stops: [0, 50, 65, 91]
					},
				},
				stroke: {
					dashArray: 4
				},
				labels: ['This Month'],
			},

		}
	)
	useEffect(() => {
		setChartMonth({ ...chartMonth, series: [responsesMonth] })
	}, [responsesMonth])
	return (
		<div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4">
			<div className="card mb-4 rounded-12 shadow">
				<div className="card-body p-3 p-xl-3 p-xxl-4">
					{
						loadingMonth
							? <Loader />
							:
							(Object.keys(responsesAll).length > 0
								?
								(Object.keys(responsesAll.data).length > 0
									?
									<div className="row align-items-center">
										<div className="col-4 col-xxl-4">
											<span className="caption text-gray-600 d-block mb-1 analytic-text">Last Month</span>
											{/* <span className="h3 mb-0">{responsesMonth ? responsesMonth : 0}</span> */}
											<span className="d-block fs-11 mt-2 font-weight-semibold">
												{
													responsesMonthDifference < 0
														?
														<svg className="me-1" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14">
															<g data-name="Icons/Tabler/Trend down" transform="translate(0)">
																<rect data-name="Icons/Tabler/Trend down background" width={14} height={14} fill="none" />
																<path d="M.218.106.158.158a.539.539,0,0,0-.052.7L.158.919,4.465,5.227a.539.539,0,0,0,.7.052l.06-.052L7.718,2.736l4.443,4.443H8.436a.539.539,0,0,0-.533.465L7.9,7.718a.54.54,0,0,0,.465.534l.073,0h5.012a.537.537,0,0,0,.5-.3h0l0-.007,0-.007h0A.537.537,0,0,0,14,7.791V7.783a.544.544,0,0,0,0-.06V2.692a.539.539,0,0,0-1.073-.072l0,.072V6.418L8.1,1.593a.539.539,0,0,0-.7-.052l-.061.052L4.846,4.084.919.158a.538.538,0,0,0-.7-.052Z" transform="translate(0 2.625)" fill="#FD7E14" />
															</g>
														</svg>
														:
														<svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16">
															<g data-name="icons/tabler/trend-up" transform="translate(0)">
																<rect data-name="Icons/Tabler/Trend background" width="16" height="16" fill="none" />
																<path d="M.249,9.315.18,9.256a.616.616,0,0,1-.059-.8L.18,8.385,5.1,3.462A.616.616,0,0,1,5.9,3.4l.068.059L8.821,6.309,13.9,1.231H9.641A.616.616,0,0,1,9.031.7L9.025.616a.617.617,0,0,1,.532-.61L9.641,0h5.728a.614.614,0,0,1,.569.346h0l0,.008,0,.008h0a.613.613,0,0,1,.048.168V.541A.621.621,0,0,1,16,.61V6.359a.616.616,0,0,1-1.226.083l-.005-.083V2.1L9.256,7.615a.616.616,0,0,1-.8.059l-.069-.059L5.539,4.768,1.05,9.256a.615.615,0,0,1-.8.059Z" transform="translate(0 3)" fill="#20C997" />
															</g>
														</svg>
												}
												{responsesMonthDifference <= 0
													? responsesMonthDifference
													: '+' + responsesMonthDifference
												}
											</span>
										</div>
										<div className="col-8 col-xxl-8 pe-xxl-0">
											<Chart options={chartMonth.options} series={chartMonth.series} type="radialBar" width={'100%'} height={230} />
										</div>
									</div>
									: <p>No Data</p>)
								: <p>No Data</p>)
					}
				</div>
			</div>
		</div>
	);
};

export default ResponseMonth;