import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {API_BASE_URL} from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import {useDispatch, useSelector} from 'react-redux';
import {
    getNotSolvedPaymentData,
    getNotSolvedPayments,
    getSolvedPaymentData,
    getSolvedPayments,
    getSuspended,
    getSuspendedData,
    setAdminLoading,
    getGlobalDateRangeInfo,
    setGlobalDateRange, getGlobalSearchTextPaymentData, getGlobalPaymentTabNameData, setGlobalPaymentTabName
} from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';
import moment from "moment/moment";

const Payment = (props) => {
    const {
        setModal,
        setModalData,
        dataAll,
        setDataAll,
        setTableData,
        setUserDate,
        // setTabNameInPayment,
        // getPaymentGlobalTabName,
        paymentDate,
        tabNameInPayment,
        setPaymentDate,
        dateRange,
        setDateRange
    } = props;
    document.title = "FormBuilder - Payment";
    const dispatch = useDispatch();
    const [countRejected, setCountRejected] = useState(1)
    const [countSuspended, setCountSuspended] = useState(1)
    const [countPaid, setCountPaid] = useState(1)
    const dataPaid = useSelector(getSolvedPaymentData);
    const dataRejected = useSelector(getNotSolvedPaymentData);
    const suspended = useSelector(getSuspendedData);
    const [searchInfo, setSearchInfo] = useState('');
    const globalDateRange = useSelector(getGlobalDateRangeInfo);
    const [startDate, endDate] = dateRange;
    const [show, setShow] = useState(false);
    const getPaymentGlobalTabName = useSelector(getGlobalPaymentTabNameData)
    const getGlobalSearchTextInPayment = useSelector(getGlobalSearchTextPaymentData)
    const deleteRangeData = (datesNow) => {
        dispatch(setAdminLoading(true))
        if (!datesNow[0] && !datesNow[1]) {
            let data = {}
            data.searchInfo = getGlobalSearchTextInPayment;
            data.globalDateRange = null;
            data.tabNameInPayment = getPaymentGlobalTabName;
            axios.post(API_BASE_URL + '/api/getPaidRejectedData?page=1', data)
                .then(response => {
                    dispatch(setAdminLoading(false))
                    setDataAll(response.data)
                    // dispatch(setResponsesYear(response.data))
                })
                .catch(error => {
                    dispatch(setAdminLoading(false))
                })
            setShow(false)

        }
    }
    const dataRangeFunction = (data) => {
        if (data) {
            setDateRange(data)
            if (data[0] && data[1]) {
                dispatch(setGlobalDateRange(
                    {
                        from: moment(data[0]).toLocaleString(),
                        to: moment(data[1]).toLocaleString()
                    }))
                handleClose(data)
            } else if (!data[0] && !data[1]) {
                dispatch(setGlobalDateRange(
                    {
                        from: null,
                        to: null
                    }))
                deleteRangeData(data)
            }
            // if (!data[0] && !data[1]) {
            // 	if (!searchData && searchData.length === 0) {
            // 		// dispatch(getAdminUsers({ page: 1 }))
            // 	}
            // }
        }
    }
    let tabName = {
        'Name': 'paymentuser',
        'Email': 'paymentuser',
        'Registered': 'paymentuser',
        'Status': 'subscription_id',
        'Amount': 'money',
        'Plan': 'payment_type',
        'Created': 'created_at',
    }
    if (getPaymentGlobalTabName === "Suspended") {
        tabName = {
            'Name': 'firstname',
            'Email': 'email',
            'Plan': 'plan',
            'AvailableForms': 'formCount',
            'OneTimeForm': 'haveOneTimeForm',
            'Paid': 'payd_at'
        }
    }

    useEffect(() => {
        setDataAll(dataPaid)
        setCountPaid(dataPaid.total)
    }, [dataPaid])

    useEffect(() => {
        // setPageNumber(dataNow.current_page)
        setCountRejected(dataRejected.total)
        if (searchInfo.length === 0 && getPaymentGlobalTabName === "Rejected") {
            setDataAll(dataRejected)
        }
    }, [dataRejected])

    useEffect(() => {
        setCountSuspended(suspended.total)
        if (searchInfo.length === 0 && getPaymentGlobalTabName === "Suspended") {
            setDataAll(suspended)
        }
    }, [suspended])

    useEffect(() => {
        dispatch(getSolvedPayments({page: 1}))
        dispatch(getNotSolvedPayments({page: 1}))
        dispatch(getSuspended({page: 1}))
    }, [])

    const getData = (data) => {
        if (dataAll.current_page !== data.page) {
            let pageNum = dataAll.current_page;
            if (dataAll.data.length - 1 === 0) {
                pageNum = pageNum - 1
            }
            dispatch(setAdminLoading(true))
            data.searchInfo = getGlobalSearchTextInPayment;
            data.tabNameInPayment = getPaymentGlobalTabName;
            data.globalDateRange = null;
            axios.post(API_BASE_URL + '/api/getPaidRejectedData?page=' + pageNum, data)
                .then(response => {
                    dispatch(setAdminLoading(false))
                    setDataAll(response.data)
                })
                .catch(error => {
                    dispatch(setAdminLoading(false))
                })
        }
    }
    const changeTabNameInPayment = (e, tabName) => {
        dispatch(setGlobalDateRange(
            {
                from: null,
                to: null
            }))
        setUserDate({})
        setDateRange([null, null])
        dispatch(setGlobalPaymentTabName(tabName))
    }

    useEffect(() => {
        if (getPaymentGlobalTabName === "Paid") {
            setDataAll(dataPaid)
            // dispatch(getNotSolvedMessages({ page: 1 }))
        } else if (getPaymentGlobalTabName === "Rejected") {
            setDataAll(dataRejected)
            // dispatch(getSolvedMessages({ page: 1 }))
        } else {
            setDataAll(suspended)
        }
    }, [getPaymentGlobalTabName])
    const handleClose = (datesNow) => {
        dispatch(setAdminLoading(true))
        if (datesNow[0] && datesNow[1]) {
            var dates = {
                from: moment(datesNow[0]).toLocaleString(),
                to: moment(datesNow[1]).toLocaleString()
            };
            let pageNum = dataAll.current_page;
            if (dataAll.data.length - 1 === 0) {
                pageNum = pageNum - 1
            }
            let data = {}
            data.globalDateRange = dates
            data.searchInfo = getGlobalSearchTextInPayment;
            data.tabNameInPayment = getPaymentGlobalTabName;
            setPaymentDate(dates)

            axios.post(API_BASE_URL + '/api/getPaidRejectedData?page=' + pageNum, data)
                .then(response => {
                    dispatch(setAdminLoading(false))
                    setDataAll(response.data)
                })
                .catch(error => {
                    dispatch(setAdminLoading(false))
                })
        }
    };

    return (
        <div className="home__data">
            <ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative solved-notsolved">
                {
                    dataPaid ? countPaid > 0 ?
                            <li className="nav-item">
                                <button type="button" onClick={e => changeTabNameInPayment(e, 'Paid')}
                                        className={getPaymentGlobalTabName == 'Paid' ? "nav-link active" : "nav-link"}>Paid({countPaid})
                                </button>
                            </li>
                            : null
                        : null
                }
                {
                    dataRejected ? countRejected > 0
                            ?
                            <li className="nav-item">
                                <button type="button" onClick={e => changeTabNameInPayment(e, 'Rejected')}
                                        className={getPaymentGlobalTabName == 'Rejected' ? "nav-link active" : "nav-link"}>Rejected({countRejected})
                                </button>
                            </li>
                            : null
                        : null
                }
                {
                    suspended ? countSuspended > 0
                            ?
                            <li className="nav-item">
                                <button type="button" onClick={e => changeTabNameInPayment(e, 'Suspended')}
                                        className={getPaymentGlobalTabName == 'Suspended' ? "nav-link active" : "nav-link"}>Suspended({countSuspended})
                                </button>
                            </li>
                            : null
                        : null
                }

            </ul>
            {dataAll.data ?
                <div className='admin-responses'>
                    <AdminTable
                        setCountPaid={setCountPaid}
                        setCountSuspended={setCountSuspended}
                        setCountRejected={setCountRejected}
                        tabName={tabName}
                        tabNameInPayment={tabNameInPayment}
                        dataAll={dataAll}
                        setDataAll={setDataAll}
                        table={'Payment'}
                        getData={getData}
                        startDate={startDate}
                        endDate={endDate}
                        setModal={setModal}
                        setModalData={setModalData}
                        dataRangeFunction={dataRangeFunction}
                        setTableData={setTableData}
                        dispatchName={"getAdminMessagesData"}
                        setSearchInfo={setSearchInfo}
                        searchInfo={searchInfo}
                    />
                </div> :
                <div className="form-loader">
                    <Loader />
                </div>}
        </div >
    );
};

export default Payment;