import React from 'react';

const Fullname = () => {
	return (
		<div className="form-content-row-drag droped" >
			<div className="form-content-row-drag-body">
				<div className="form-content-row-drag-fullname">
					<div className="fullname-label row-label">
						<input type="text" defaultValue="Full Name" className='form_input' />
					</div>
					<div className="fullname-inputs">
						<div className="fullname-input">
							<input type="text" className="form-input-border evented" />
							{/* <input type="text" defaultValue="First Name" className='form_input row-sublabel' /> */}
						</div>
						<div className="fullname-input">
							<input id="fullName_last" type="text" className="form-input-border evented" />
							{/* <input type="text" defaultValue="Last Name" className='form_input row-sublabel' /> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Fullname;