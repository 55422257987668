import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSelector } from 'react-redux';
import { getInfoData } from '../../../redux/reducers/infoReducer';
import {trim} from "../../../help";

const PasswordEdit = (props) => {
	const { updateUserPasswordDetails, errorsPassword, setErrorsPassword, updateDataPassword, setUpdateDataPassword, passwordChanged, setPasswordChanged } = props;
	const [validLength, setValidlength] = useState(false);
	const [validUpp, setValidUpp] = useState(false);
	const [validNumber, setValidNumber] = useState(false);
	const [validSymbol, setValidSymbol] = useState(false);
	const [validPasswordCount, setValidPasswordCount] = useState(0);
	const [passwordForm, setPasswordForm] = useState([])
	const myInfo = useSelector(getInfoData)
	let capsCount, smallCount, numberCount, symbolCount
	capsCount = (updateDataPassword?.password?.match(/[A-Z]/g) || []).length
	smallCount = (updateDataPassword?.password?.match(/[a-z]/g) || []).length
	numberCount = (updateDataPassword?.password?.match(/[0-9]/g) || []).length
	symbolCount = (updateDataPassword?.password?.match(/\W/g) || []).length

	function hasUpperCase(str) { return str.toLowerCase() != str; }
	function hasNumber(str) { return /\d/.test(str); }
	function hasCharacter(str) {
		var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
		return format.test(str)
	}
	function incrementValidCount() {
		var oldCount = validPasswordCount;
		oldCount++;
		setValidPasswordCount(oldCount);
	}
	function decrementValidCount() {
		var oldCount = validPasswordCount;
		oldCount--;
		setValidPasswordCount(oldCount);
	}
	function newPassword(data) {
		setUpdateDataPassword({ ...updateDataPassword, password: data });
		if (data.length > 6) {
			setValidlength(true);
			// incrementValidCount();
		} else {
			setValidlength(false);
			// decrementValidCount();
		}
		if (hasUpperCase(data)) {
			setValidUpp(true)
			// incrementValidCount();
		} else {
			setValidUpp(false)
			// decrementValidCount();
		}
		if (hasNumber(data)) {
			setValidNumber(true)
			// incrementValidCount();
		} else {
			setValidNumber(false)
			// decrementValidCount();
		}
		if (hasCharacter(data)) {
			setValidSymbol(true)
			// incrementValidCount();
		} else {
			setValidSymbol(false)
			// decrementValidCount();
		}
	}
	const changePassword = e => {
		e.preventDefault();
		updateUserPasswordDetails();
		setPasswordForm(e.target)
	}
	useEffect(() => {
		if (passwordChanged) {
			passwordForm.reset()
			setTimeout(() => {
				setPasswordChanged(false)
			}, 2000)
			setErrorsPassword([]);
			setValidlength(false);
			setValidUpp(false);
			setValidNumber(false);
			setValidPasswordCount(false);
			setValidSymbol(false)
		}
	}, [passwordChanged])
	const [passwordShowCheck, setPasswordShowCheck] = useState(false)
	const [passwordShowCheckConfirm, setPasswordShowCheckConfirm] = useState(false)
	const [passwordShowCheckCurrent, setPasswordShowCheckCurrent] = useState(false)
	const passwordShow = (e) => {
		e.preventDefault()
		setPasswordShowCheck(!passwordShowCheck);
	}
	const passwordShowConfirm = (e) => {
		e.preventDefault()
		setPasswordShowCheckConfirm(!passwordShowCheckConfirm);
	}
	const passwordShowCurrent = (e) => {
		e.preventDefault()
		setPasswordShowCheckCurrent(!passwordShowCheckCurrent);
	}
	useEffect(() => {

		if (updateDataPassword?.password?.length > 0) {
			if (updateDataPassword?.password?.length > 6 || capsCount || numberCount || symbolCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				one.className = 'passwordBar-element passwordBar-count1'
			} else {
				return false
			}

			if (updateDataPassword?.password?.length > 6 && capsCount && !numberCount && !symbolCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else if (updateDataPassword?.password?.length > 6 && numberCount && !capsCount && !symbolCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else if (updateDataPassword?.password?.length > 6 && symbolCount && !capsCount && !numberCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else if (capsCount && numberCount && !symbolCount && updateDataPassword?.password?.length <= 6) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else if (capsCount && symbolCount && !numberCount && updateDataPassword?.password?.length <= 6) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else if (numberCount && symbolCount && !capsCount && updateDataPassword?.password?.length <= 6) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				one.className = 'passwordBar-element passwordBar-count2'
				two.className = 'passwordBar-element passwordBar-count2'
			} else {
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				two.className = 'passwordBar-element passwordBar-count0'
			}

			if (updateDataPassword?.password?.length > 6 && capsCount && numberCount && !symbolCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
				one.className = 'passwordBar-element passwordBar-count3'
				two.className = 'passwordBar-element passwordBar-count3'
				three.className = 'passwordBar-element passwordBar-count3'
			} else if (updateDataPassword?.password?.length > 6 && numberCount && symbolCount && !capsCount) {
					let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
					let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
					let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
					one.className = 'passwordBar-element passwordBar-count3'
					two.className = 'passwordBar-element passwordBar-count3'
					three.className = 'passwordBar-element passwordBar-count3'
			} else if (updateDataPassword?.password?.length > 6 && symbolCount && capsCount && !numberCount) {
					let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
					let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
					let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
					one.className = 'passwordBar-element passwordBar-count3'
					two.className = 'passwordBar-element passwordBar-count3'
					three.className = 'passwordBar-element passwordBar-count3'
			} else
				if (updateDataPassword?.password?.length <= 6 && symbolCount && capsCount && numberCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
				one.className = 'passwordBar-element passwordBar-count3'
				two.className = 'passwordBar-element passwordBar-count3'
				three.className = 'passwordBar-element passwordBar-count3'
			} else {
				let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
				three.className = 'passwordBar-element passwordBar-count0'
			}

			if (updateDataPassword?.password?.length > 6 && capsCount && numberCount && symbolCount) {
				let one = document.querySelector(`.passwordBar-elements :nth-child(1)`)
				let two = document.querySelector(`.passwordBar-elements :nth-child(2)`)
				let three = document.querySelector(`.passwordBar-elements :nth-child(3)`)
				let four = document.querySelector(`.passwordBar-elements :nth-child(4)`)

				one.className = 'passwordBar-element passwordBar-count4'
				two.className = 'passwordBar-element passwordBar-count4'
				three.className = 'passwordBar-element passwordBar-count4'
				four.className = 'passwordBar-element passwordBar-count4'
			} else {
				let four = document.querySelector(`.passwordBar-elements :nth-child(4)`)
				four.className = 'passwordBar-element passwordBar-count0'
			}

		}

	},[updateDataPassword.password])
	return (
		<div tabname="Security" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
			{myInfo?.blocked == 1 ?
				<div className="blocked-form-div">
					To edit your information, please make a payment first.
				</div>
				: null
			}
			<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
				<h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Manage Password</h5>
			</div>
			{
				passwordChanged
					?
					<div className="px-md-4 changed-password">
						<p className="px-3 mb-0 py-1 text-white font-weight-bold">Your password is successfully changed</p>
					</div>
					: null
			}
			<div className="card-body px-0 p-md-4">
				<form className="px-3 form-style-two" onSubmit={changePassword}>
					<div className="mb-md-4 pb-3">
						<label htmlFor="CurrentPassword" className="form-label form-label-lg">Current password</label>
						<div className='passwordShow'>
							<input
								type={passwordShowCheckCurrent ? "text" : "password"}
								className={errorsPassword && errorsPassword.hasOwnProperty('current_password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
								id="CurrentPassword"
								placeholder="Enter your current password"
								onChange={e => setUpdateDataPassword({ ...updateDataPassword, current_password: e.target.value })}
							/>
							<button type="button" onClick={passwordShowCurrent}>
								{passwordShowCheckCurrent
									? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
									: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
								}
							</button>
						</div>
						{errorsPassword && errorsPassword.hasOwnProperty('current_password') ? <p className="Authmessage Autherror"> {errorsPassword.current_password} </p> : null}
					</div>
					<div className="mb-2 mb-md-4 pb-2">
						<label htmlFor="NewPassword" className="form-label form-label-lg">New password</label>
						<div className='passwordShow'>
							<input
								type={passwordShowCheck ? "text" : "password"}
								className={errorsPassword && errorsPassword.hasOwnProperty('current_password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
								id="NewPassword"
								placeholder="Enter your new password "
								onChange={e => newPassword(e.target.value)}
							/>
							<button type="button" onClick={passwordShow}>
								{passwordShowCheck
									? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
									: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
								}
							</button>
						</div>
						{errorsPassword && errorsPassword.hasOwnProperty('password') ? <p className="Authmessage Autherror"> {errorsPassword.password} </p> : null}
					</div>
					<div className="mb-2 mb-md-4 pb-2">
						<label htmlFor="NewPassword" className="form-label form-label-lg">Confirm new password</label>
						<div className='passwordShow'>
							<input
								type={passwordShowCheckConfirm ? "text" : "password"}
								className={errorsPassword && errorsPassword.hasOwnProperty('current_password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
								id="NewPassword"
								placeholder="Confirm your new password"
								onChange={e => setUpdateDataPassword({ ...updateDataPassword, confirm_password: e.target.value })}
							/>
							<button type="button" onClick={passwordShowConfirm}>
								{passwordShowCheckConfirm
									? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
									: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
								}
							</button>
						</div>
						{errorsPassword && errorsPassword.hasOwnProperty('confirm_password') ? <p className="Authmessage Autherror"> {errorsPassword.confirm_password} </p> : null}
					</div>

					{/*<div className="passwordBar" style="position: relative;">*/}
					{/*	<div style="display: flex; align-items: center; margin: 5px 0px 0px;">*/}
					{/*		<div*/}
					{/*			style="flex-basis: 0px; flex-grow: 1; position: relative; max-width: 100%; width: 100%; height: 2px; background-color: rgb(221, 221, 221);"></div>*/}
					{/*		<div style="width: 4px;"></div>*/}
					{/*		<div*/}
					{/*			style="flex-basis: 0px; flex-grow: 1; position: relative; max-width: 100%; width: 100%; height: 2px; background-color: rgb(221, 221, 221);"></div>*/}
					{/*		<div style="width: 4px;"></div>*/}
					{/*		<div*/}
					{/*			style="flex-basis: 0px; flex-grow: 1; position: relative; max-width: 100%; width: 100%; height: 2px; background-color: rgb(221, 221, 221);"></div>*/}
					{/*		<div style="width: 4px;"></div>*/}
					{/*		<div*/}
					{/*			style="flex-basis: 0px; flex-grow: 1; position: relative; max-width: 100%; width: 100%; height: 2px; background-color: rgb(221, 221, 221);"></div>*/}
					{/*	</div>*/}
					{/*	<p style="margin: 5px 0px 0px; color: rgb(137, 135, 146); font-size: 14px; text-align: right;">too*/}
					{/*		short</p>*/}
					{/*</div>*/}



					{/*<PasswordStrengthBar*/}
					{/*	password={updateDataPassword.password}*/}
					{/*	className="passwordBar"*/}
					{/*	minLength={6}*/}
					{/*	onChangeScore={score}*/}
					{/*/>*/}

					{/* passBar*/}
					{
						// updateDataPassword?.password && (updateDataPassword?.password?.length >= 6 || updateDataPassword?.password[0].match(/[A-Z]/g) || updateDataPassword?.password[0].match(/[0-9]/g) || updateDataPassword?.password[0].match(/\W/g))
						updateDataPassword?.password?.length > 6 || capsCount || symbolCount || numberCount
							?
						<div className="passwordBar">
							<div className="passwordBar-body">
								<div className="passwordBar-elements">
									<div className={`passwordBar-element passwordBar-count${validPasswordCount}`}></div>
									<div className={`passwordBar-element passwordBar-count${validPasswordCount}`}></div>
									<div className={`passwordBar-element passwordBar-count${validPasswordCount}`}></div>
									<div className={`passwordBar-element passwordBar-count${validPasswordCount}`}></div>
								</div>
							</div>
						</div>
						: null
					}

					{/* <div className="mb-2 mb-md-4 pb-2">
                        <div className="progress" style={{ height: '8px' }}>
                            <div className="progress-bar bg-teal-500" role="progressbar" style={{ width: '88%' }} aria-valuenow={88} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                        <label className="small text-gray-700 pt-2">Password strength: <span className="text-success font-weight-semibold">Strong</span></label>
                    </div> */}
					<div className="mb-2 mb-md-4">
						<h6 className="font-weight-semibold open-sans-font">Password requirments</h6>
						<ul>
							<li className={
								validLength
									? "line-through"
									: null
							} >• Minumum 6 charachters or longer</li>
							<li className={
								validUpp
									? "line-through"
									: null
							}>• One uppercase letter</li>
							<li className={
								validNumber
									? "line-through"
									: null
							}>• At least one number</li>
							<li className={
								validSymbol
									? "line-through"
									: null
							}>• At least one special character ( @$!%*?& )</li>
						</ul>
					</div>
					<div className="text-end pb-md-3 pt-md-4">
						<button type="submit" className="btn btn-lg btn-dark px-md-4 mt-lg-4">Change password</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PasswordEdit;