import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';
import Loader from '../../../UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmResponses, setAdminLoading, setResponses } from '../../../redux/reducers/adminReducer';
import AdminTable from './AdminTable';
import { getAdminResponsesData } from '../../../redux/reducers/adminReducer';



const AdminResponses = (props) => {
	const { addData, setModal, setModalData, dataAll, setDataAll, setTableData } = props;
	document.title = "FormBuilder - Admin Responses";
	const dispatch = useDispatch();
	const dataNow = useSelector(getAdminResponsesData)
	const [searchInfo, setSearchInfo] = useState('');
	const tabName = {
		'Form': "title",
		'Response': '_id',
		// 'Responsed': 'formatedDate',
		'Responsed': 'created_at',
	}
	useEffect(() => {
		setDataAll(dataNow)
	}, [dataNow])

	useEffect(() => {
		dispatch(getAdmResponses({ page: 1 }))
	}, [])

	const getData = (data) => {
		if(dataAll.current_page !== data.page){
			dispatch(setAdminLoading(true))
			data.searchInfo = searchInfo;
			axios.post(API_BASE_URL + '/api/getResponsesDatasForAdmin?page=' + data.page, data)
				.then(response => {	
					dispatch(setAdminLoading(false))
					dispatch(setResponses(response.data))
				})
				.catch(error => {
					dispatch(setAdminLoading(false))
				})
		}
	}


	return (
		<div className="home__data">	
			{dataAll.data ?
				<div className='admin-responses'>
					<AdminTable 
						setSearchInfo={setSearchInfo}
						searchInfo={searchInfo}
						tabName={tabName} 
						dataAll={dataAll} 
						setDataAll={setDataAll} 
						table={'FormResponse'} 
						getData={getData} 
						setModal={setModal} 
						setModalData={setModalData} 
						setTableData={setTableData}
						dispatchName={"getAdminResponsesData"} 
						dataNow={dataNow} 
					/>
				</div> :
				
				<div className="form-loader">
					<Loader />
				</div>
			}
				
		</div >
	);

};

export default AdminResponses;