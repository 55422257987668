import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMyInfo, getMyGeneralInfo, getNotificationsInfo } from '../features/dataApi';

const initialState = {
	info: {},
	formCountInfo: 0,
	generalInfo: [],
	generalInfoChangeFullName: {},
	infoLoading: false,
	generalInfoLoading: false,
	notifications: [],
	notificationsLoading: false,
	// notificationsError: null,
}

export const getInfo = createAsyncThunk(
	'/api/getMe',
	async () => {
		const response = await getMyInfo()
		return response.data
	}
)

export const getNotifications = createAsyncThunk(
	'/api/getNotifications',
	async () => {
		const response = await getNotificationsInfo()
		return response.data
	}
)

export const getGeneralInfo = createAsyncThunk(
	'/api/GetMyGeneralDetails',
	async () => {
		const response = await getMyGeneralInfo()
		return response.data
	}
)

export const infoSlice = createSlice({
	name: 'infoReducer',
	initialState,
	reducers: {
		setMe: (state, action) => {
			state.info = { ...action.payload }
		},
		setFormCountInfo: (state, action) => {
			state.formCountInfo = action.payload
		},
		setMyGeneralinfo: (state, action) => {
			state.generalInfo = action.payload
		},
		setNotifications: (state, action) => {
			state.notifications = action.payload
		},
		setGeneralInfoChangeFullName: (state, action) => {
			state.generalInfoChangeFullName = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getInfo.fulfilled, (state, action) => {
				state.infoLoading = false
				infoSlice.caseReducers.setMe(state, action)
			})
			.addCase(getInfo.pending, (state, action) => {
				state.infoLoading = true
			})
			.addCase(getGeneralInfo.fulfilled, (state, action) => {
				state.generalInfoLoading = false
				infoSlice.caseReducers.setMyGeneralinfo(state, action)
			})
			.addCase(getGeneralInfo.pending, (state, action) => {
				state.generalInfoLoading = true
			})
			.addCase(getNotifications.fulfilled, (state, action) => {
				state.notificationsLoading = false
				infoSlice.caseReducers.setNotifications(state, action)
			})
			.addCase(getNotifications.pending, (state, action) => {
				state.notificationsLoading = true
			})
		// .addCase(getNotifications.rejected, (state, action) => {
		// 	state.notificationsError = action.payload
		// })
	},
});

export const { setMe, setMyGeneralinfo, setNotifications, setFormCountInfo, setGeneralInfoChangeFullName } = infoSlice.actions;

export const getInfoData = (state) => state.infoReducer.info;
export const getFormCountInfo = (state) => state.infoReducer.formCountInfo;
export const getInfoDataLoading = (state) => state.infoReducer.infoLoading;
export const getGeneralInfoData = (state) => state.infoReducer.generalInfo;
export const getGeneralInfoDataLoading = (state) => state.infoReducer.generalInfoLoading;
export const getNotificationsData = (state) => state.infoReducer.notifications;
export const getNotificationsDataLoading = (state) => state.infoReducer.notificationsLoading;
export const getGeneralInfoChangeFullNameData = (state) => state.infoReducer.generalInfoChangeFullName;
// export const getNotificationsErrorData = (state) => state.infoReducer.notificationsError;

export default infoSlice.reducer;
