import React, { useEffect, useRef } from 'react';

const Divider = (props) => {
	const { formData, setFormData, settingsData, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
		var formCopy = JSON.parse(JSON.stringify(formData))
		// var required = formCopy[page].rows[row].content[0].cols[col].content.required;
		setSettingsData({})
		var newSettingsData = {
			info: {
				page: page,
				row: row,
				col: col
			},
			details: {
				divider: {
					// required: required,
				}
			}
		}
		setSettingsData(newSettingsData)
		document.querySelector('body').classList.toggle('customize-box');
	}

	return (
		<div id="dividerPage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}>
			{preview
				?
				<div className="form-content-row-drag-body">
                    <span className='form-content-row-divider'></span>
                </div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
                        <span className='form-content-row-divider'></span>
                    </div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text form-content-row-divider-body">
                            <span className='form-content-row-divider'></span>
							<div className="col-buttons">
                                {/* <div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div> */}
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default Divider;