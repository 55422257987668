import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { getResponsesData, getResponsesDataLoading } from '../../redux/reducers/responseReducer';
import Loader from '../../UI/Loader/Loader';


const AllResponses = () => {
	const responsesAll = useSelector(getResponsesData)
	const loadingAll = useSelector(getResponsesDataLoading)
	const [chartAll, setChartAll] = useState(
		{
			series: [0],
			options: {
				chart: {
					// height: 350,
					type: 'radialBar',
					offsetY: -10,
				},
				horizontalAlign: 'right',
				plotOptions: {
					radialBar: {
						startAngle: -135,
						endAngle: 135,
						dataLabels: {
							name: {
								fontSize: '16px',
								color: undefined,
								offsetY: 70,
							},
							value: {
								offsetY: -7,
								fontSize: '25px',
								color: undefined,
								fontWeight: 900,
								formatter: function (val) {
									return val;
								}
							}
						}
					}
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						shadeIntensity: 0.15,
						inverseColors: false,
						opacityFrom: 1,
						opacityTo: 1,
						stops: [0, 50, 65, 91]
					},
				},
				stroke: {
					dashArray: 4
				},
				labels: ['Responses'],
			},

		}
	)
	useEffect(() => {
		// console.log(responsesAll,'alllll');
		if (Object.keys(responsesAll).length > 0 && Object.keys(responsesAll.data).length > 0) {
			// console.log(responsesAll.data.length);
			setChartAll({ ...chartAll, series: [responsesAll.total] })
		}
	}, [responsesAll])
	return (
		<div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4">
			<div className="card mb-4 rounded-12 shadow">
				<div className="card-body p-3 p-xl-3 p-xxl-4">
					{
						loadingAll
							? <Loader />
							:
							(Object.keys(responsesAll).length > 0
								?
								(Object.keys(responsesAll.data).length > 0
									?
									<div className="row align-items-center">
										<div className="col-8 col-xxl-8 pe-xxl-0 w-100">
											<Chart options={chartAll.options} series={chartAll.series} type="radialBar" width={'100%'} height={230} />
										</div>
									</div>
									: <p>No Data</p>
								)
								: <p>No Data</p>
							)
					}
				</div>
			</div>
		</div>
	);
};

export default AllResponses;