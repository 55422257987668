import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Admin from './Admin';
import { Provider } from 'react-redux'
import {store} from "./redux/store"

if (document.getElementById('root')) {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);
}
// if (document.getElementById('admin')) {
// 	ReactDOM.render(
// 		<Admin />,
// 		document.getElementById('admin')
// 	);
// }