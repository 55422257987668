import React, { useContext, useEffect, useRef, useState } from 'react';
import { API_BASE_URL, APP_BASE_URL, AxiosConfigs } from '../../config';
import moment from 'moment';
import ProfileEdit from './ProfileEdit';
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from '../../context';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getInfoData } from '../../redux/reducers/infoReducer';


const Settings = (props) => {
	const { myInfoDetails, setMyInfoDetails } = props;
	const { isAuth, setIsAuth } = useContext(AuthContext);
	const myInfo = useSelector(getInfoData)
	useEffect(() => {
		if (window.innerWidth > 1200) {
			window.addEventListener('scroll', (e) => {
				var scrollYpos = window.scrollY;
				if (scrollYpos > 120) {
					document.querySelectorAll('.animated-div')[0].classList.add('fixed-menu')
				} else {
					document.querySelectorAll('.animated-div')[0].classList.remove('fixed-menu')
				}
			});
		}
	}, [])
	// useEffect(() => {
	// 	$('.settings-tab').on('click', function () {
	// 		$('.settings-tab button').removeClass('active')
	// 		$(this).find('button').addClass('active')
	// 		var tabname = $(this).attr('tabname')
	// 		$(window).scrollTop($(`.settings-tab-content[tabname = '${tabname}']`).offset().top - 100);
	// 	})
	// }, [])
	const deleteAccount = e => {
		e.preventDefault();
		const data = {};
		// data.id = myInfo._id;
		axios.post(API_BASE_URL + '/api/deleteAccount', null, AxiosConfigs)
			.then(response => {
				localStorage.removeItem('auth')
				localStorage.removeItem('authUser')
				window.location.href = APP_BASE_URL + '/signin'
				setIsAuth(false)
			})
			.catch(error => { })
	}
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<div>
			<div className="px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header">
				<div className="container-fluid px-0">
					<div className="row align-items-center">
						<div className="col">
							<span className="text-uppercase tiny text-gray-600 Montserrat-font font-weight-semibold">Account</span>
							<h1 className="h2 mb-0 lh-sm account-tabname">Profile</h1>
						</div>
						{/* <div className="col-auto d-flex align-items-center my-2 my-sm-0">
							<a href={APP_BASE_URL + "/profile"} className="btn btn-lg btn-warning"><svg className="me-2" data-name="icons/tabler/user" xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 16 16">
								<rect data-name="Icons/Tabler/User background" width={16} height={16} fill="none" />
								<path d="M11.334,16H.667a.665.665,0,0,1-.661-.568L0,15.343v-1.75A4.179,4.179,0,0,1,4.029,9.44l.193,0H7.778A4.186,4.186,0,0,1,12,13.4l0,.191v1.75a.661.661,0,0,1-.576.651ZM4.222,10.749a2.869,2.869,0,0,0-2.884,2.683l-.005.162v1.094h9.334V13.594A2.857,2.857,0,0,0,8.1,10.767l-.162-.013-.164,0ZM6,8.314A4.2,4.2,0,0,1,1.778,4.157a4.223,4.223,0,0,1,8.445,0A4.2,4.2,0,0,1,6,8.314Zm0-7A2.87,2.87,0,0,0,3.111,4.157a2.889,2.889,0,0,0,5.778,0A2.87,2.87,0,0,0,6,1.313Z" transform="translate(2)" fill="#1e1e1e" />
							</svg><span>My Profile</span>
							</a>
						</div> */}
					</div>
				</div>
			</div>
			<div className="p-3 p-xxl-5">
				<div className="container-fluid px-0">
					<div className="row">
						<div className="col-12 col-xl-4 col-xxl-3 mb-3">
							<aside className="card border border-gray-200 rounded-12 mb-xl-4 animated-div">
								<div className="p-3 p-md-4 d-flex align-items-center">
									<span className="avatar avatar-lg avatar-circle">
										{(Object).keys(myInfo).length > 0 ?
											(myInfo.avatar === 'firstname'
												?
												<div className="avatar-div" style={{ backgroundColor: myInfo.color }}>
													<p className="avatar-letter avatar-letter-medium">{myInfo.firstname.split('')[0]}</p>
												</div>
												: <img className="avatar-img" src={API_BASE_URL + `/files/User/${myInfo._id}/${myInfo.avatar}`} alt="Avatars" />)
											: null
										}
										{/* <span className="avatar-status avatar-lg-status avatar-success">&nbsp;</span> */}
									</span>
									<div className="ps-2 ps-md-3">
										<h5 className="mb-1">{myInfo.firstname} {myInfo.lastname}
											{/* <svg className="ml-1" data-name="Group 1" xmlns="http://www.w3.org/2000/svg" width={16} height="15.25" viewBox="0 0 24 23.25">
												<path d="M23.823,11.991a.466.466,0,0,0,0-.731L21.54,8.7c-.12-.122-.12-.243-.12-.486L21.779,4.8c0-.244-.121-.609-.478-.609L18.06,3.46c-.12,0-.36-.122-.36-.244L16.022.292a.682.682,0,0,0-.839-.244l-3,1.341a.361.361,0,0,1-.48,0L8.7.048a.735.735,0,0,0-.84.244L6.183,3.216c0,.122-.24.244-.36.244L2.58,4.191a.823.823,0,0,0-.48.731l.36,3.412a.74.74,0,0,1-.12.487L.18,11.381a.462.462,0,0,0,0,.732l2.16,2.437c.12.124.12.243.12.486L2.1,18.449c0,.244.12.609.48.609l3.24.735c.12,0,.36.122.36.241l1.68,2.924a.683.683,0,0,0,.84.244l3-1.341a.353.353,0,0,1,.48,0l3,1.341a.786.786,0,0,0,.839-.244L17.7,20.035c.122-.124.24-.243.36-.243l3.24-.734c.24,0,.48-.367.48-.609l-.361-3.413a.726.726,0,0,1,.121-.485Z" fill="#0D6EFD" />
												<path data-name="Path" d="M4.036,10,0,5.8,1.527,4.2,4.036,6.818,10.582,0,12,1.591Z" transform="translate(5.938 6.625)" fill="#fff" />
											</svg> */}
										</h5>
										<p className="small text-gray-600 mb-0 lh-base">Since {myInfo.formatedDate}
											{/* / {moment(myInfo.created_at).fromNow()} */}
										</p>
									</div>
									<button className="navbar-toggler collapsed ms-auto d-block d-xl-none p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav2" aria-controls="navbarNav2" aria-expanded="false" aria-label="Toggle navigation">
										<svg className="menu-icon" data-name="icons/tabler/hamburger" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 16 16">
											<rect data-name="Icons/Tabler/Hamburger background" width={16} height={16} fill="none" />
											<path d="M15.314,8H.686A.661.661,0,0,1,0,7.368a.653.653,0,0,1,.593-.625l.093-.006H15.314A.661.661,0,0,1,16,7.368a.653.653,0,0,1-.593.626Zm0-6.737H.686A.661.661,0,0,1,0,.632.654.654,0,0,1,.593.005L.686,0H15.314A.661.661,0,0,1,16,.632a.653.653,0,0,1-.593.625Z" transform="translate(0 4)" fill="#1e1e1e" />
										</svg>
										<svg className="menu-close" data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 16 16">
											<rect data-name="Icons/Tabler/Close background" width={16} height={16} fill="none" />
											<path d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z" transform="translate(2 2)" fill="#1e1e1e" />
										</svg>
									</button>
								</div>
								<div className="collapse navbar-collapse d-xl-block" id="navbarNav2">
									<ul className="sidebar-nav">
										<li className="settings-tab" tabname="General"	>
											<button type="button" className="active"><svg className="me-2" data-name="icons/tabler/user" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<rect data-name="Icons/Tabler/User background" width="16" height="16" fill="none"></rect>
												<path d="M11.334,16H.667a.665.665,0,0,1-.661-.568L0,15.343v-1.75A4.179,4.179,0,0,1,4.029,9.44l.193,0H7.778A4.186,4.186,0,0,1,12,13.4l0,.191v1.75a.661.661,0,0,1-.576.651ZM4.222,10.749a2.869,2.869,0,0,0-2.884,2.683l-.005.162v1.094h9.334V13.594A2.857,2.857,0,0,0,8.1,10.767l-.162-.013-.164,0ZM6,8.314A4.2,4.2,0,0,1,1.778,4.157a4.223,4.223,0,0,1,8.445,0A4.2,4.2,0,0,1,6,8.314Zm0-7A2.87,2.87,0,0,0,3.111,4.157a2.889,2.889,0,0,0,5.778,0A2.87,2.87,0,0,0,6,1.313Z" transform="translate(2)" fill="#1e1e1e"></path>
											</svg><p>General information</p></button>
										</li>
										{/* <li className="settings-tab" tabname="Basic">
											<button type="button"><svg className="me-2" data-name="Icons/Tabler/Paperclip Copy 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18">
												<rect data-name="Icons/Tabler/Bolt background" width="18" height="18" fill="none" />
												<path d="M6.377,18a.7.7,0,0,1-.709-.6l-.006-.1V11.537H.709A.7.7,0,0,1,.1,11.193a.673.673,0,0,1-.014-.672l.054-.083L7.693.274,7.755.2,7.828.141,7.913.087,7.981.055l.087-.03L8.16.006,8.256,0h.037l.059.005.04.007.052.011.045.014.043.016.052.023.089.055.016.011A.765.765,0,0,1,8.756.2L8.82.273l.055.083.033.067.03.085L8.957.6l.007.094V6.461h4.952a.7.7,0,0,1,.613.345.672.672,0,0,1,.013.672l-.053.082L6.942,17.714A.691.691,0,0,1,6.377,18ZM7.548,2.821,2.1,10.153H6.369a.7.7,0,0,1,.7.6l.006.093v4.331l5.449-7.331H8.256a.7.7,0,0,1-.7-.6l-.007-.094Z" transform="translate(2.25 0)" fill="#6C757D" />
											</svg><p>Basic information</p>
											</button>
										</li> */}
										<li className="settings-tab" tabname="Security">
											<button type="button"><svg className="me-2" data-name="Icons/Tabler/Lock Closed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<rect data-name="Icons/Tabler/Lock Closed background" width="16" height="16" fill="none" />
												<path d="M2.164,16A2.214,2.214,0,0,1,0,13.743V8.82A2.215,2.215,0,0,1,2.164,6.564h.2V3.9A3.821,3.821,0,0,1,6.1,0,3.815,3.815,0,0,1,9.837,3.78l0,.117V6.564h.2A2.215,2.215,0,0,1,12.2,8.82v4.923A2.214,2.214,0,0,1,10.036,16ZM1.181,8.82v4.923a1.006,1.006,0,0,0,.984,1.026h7.871a1.006,1.006,0,0,0,.984-1.026V8.82a1.006,1.006,0,0,0-.984-1.025H2.164A1.006,1.006,0,0,0,1.181,8.82ZM8.658,6.564V3.9A2.613,2.613,0,0,0,6.1,1.231,2.609,2.609,0,0,0,3.543,3.8l0,.1V6.564ZM4.722,11.281A1.409,1.409,0,0,1,6.1,9.846a1.437,1.437,0,0,1,0,2.872A1.41,1.41,0,0,1,4.722,11.281Zm1.181,0a.2.2,0,1,0,.2-.2A.2.2,0,0,0,5.9,11.281Z" transform="translate(2)" fill="#6c757d" />
											</svg><p>Security</p>
											</button>
										</li>
										{/* <li className="settings-tab" tabname="Connected">
											<button type="button"><svg className="me-2" data-name="icons/tabler/plug" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
												<rect data-name="Icons/Tabler/Plug background" width="16" height="16" fill="none" />
												<path d="M6.7,16a2.378,2.378,0,0,1-2.373-2.234l0-.145V12.541H3.244A3.241,3.241,0,0,1,0,9.47L0,9.3V4.109a.649.649,0,0,1,.561-.643L.649,3.46H1.73V.649A.649.649,0,0,1,3.021.561l.005.088V3.46H6.919V.649A.649.649,0,0,1,8.211.561l.005.088V3.46H9.3a.649.649,0,0,1,.643.561l.006.088V9.3a3.241,3.241,0,0,1-3.071,3.239l-.173,0H5.621v1.081A1.081,1.081,0,0,0,6.593,14.7l.11.005H9.3a.649.649,0,0,1,.088,1.292L9.3,16Zm0-4.757A1.951,1.951,0,0,0,8.644,9.431l0-.134V4.757H1.3V9.3A1.951,1.951,0,0,0,3.11,11.239l.133,0H6.7Z" transform="translate(3)" fill="#1e1e1e" />
											</svg><p>Connected accounts</p>
											</button>
										</li> */}
										<li className="settings-tab" tabname="Delete">
											<button type="button"><svg className="me-2" height={16} viewBox="-40 0 427 427.00131" width={16} xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" /><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" fill="#6C757D" /></svg>
												<p>Delete account</p>
											</button>
										</li>
									</ul>
								</div>
							</aside>
						</div>
						<div className="col-12 col-xl-8 col-xxl-9">
							<ProfileEdit myInfoDetails={myInfoDetails} setMyInfoDetails={setMyInfoDetails} />
							{/* <div tabname="Connected" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
								<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
									<h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Connected Accounts</h5>
								</div>
								<div className="card-body px-0 px-md-4 py-0">
									<div className="px-3">
										<div className="media border-bottom border-gray-200 py-2 py-md-4 align-items-center">
											<span className="avatar avatar-sm shadow-sm rounded-circle d-flex align-items-center justify-content-center bg-white"><img src="/svg/icons/google@20.svg" alt="Facebook" /></span>
											<div className="media-body ps-3 w-100 my-2">
												<div className="row align-items-center">
													<div className="col">
														<span className="fs-16">Facebook</span>
														<span className="d-block small text-gray-600 mt-1">lee@bruce.com</span>
													</div>
													<div className="col-auto">
														<a href="#0" className="text-primary font-weight-semibold caption">Disconnect</a>
													</div>
												</div>
											</div>
										</div>
										<div className="media border-bottom border-gray-200 py-2 py-md-4 align-items-center">
											<span className="avatar avatar-sm shadow-sm rounded-circle d-flex align-items-center justify-content-center bg-white"><img src="/svg/icons/facebook2.svg" alt="Facebook" /></span>
											<div className="media-body ps-3 w-100 my-2">
												<div className="row align-items-center">
													<div className="col">
														<span className="fs-16">Google</span>
														<span className="d-block small text-gray-600 mt-1">Not connected</span>
													</div>
													<div className="col-auto">
														<a href="#0" className="text-black-600 font-weight-semibold caption">Connect</a>
													</div>
												</div>
											</div>
										</div>
										<div className="media py-2 py-md-4 align-items-center">
											<span className="avatar avatar-sm shadow-sm rounded-circle d-flex align-items-center justify-content-center bg-white"><img src="/svg/icons/medium.svg" alt="Facebook" /></span>
											<div className="media-body ps-3 w-100 my-2">
												<div className="row align-items-center">
													<div className="col">
														<span className="fs-16">Medium</span>
														<span className="d-block small text-gray-600 mt-1">Not connected</span>
													</div>
													<div className="col-auto">
														<a href="#0" className="text-black-600 font-weight-semibold caption">Connect</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
							<div tabname="Delete" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 pb-3">
								<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
									<h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Delete Account</h5>
								</div>
								<div className="card-body px-0 px-md-4 py-0">
									<div className="px-3">
										<div className="media py-2 py-md-4">
											<div className="media-body my-2 w-100">
												<div className="row align-items-center">
													<div className="col">
														<span className="fs-16">Remove account completely</span>
														<span className="d-block small text-gray-600 mt-2">This will delete your account and all its data</span>
													</div>
													<div className="col-auto mt-3 mb-md-3">
														<button type="button" variant="primary" onClick={handleShow} className="btn btn-lg btn-danger">Delete Account</button>
													</div>
													<Modal show={show} onHide={handleClose} centered>
														<Modal.Header closeButton>
															<Modal.Title>Delete Account</Modal.Title>
														</Modal.Header>
														<Modal.Body className="font-weight-bold">Do you really want to delete your account? This process cannot be undone.</Modal.Body>
														<Modal.Footer>
															<Button variant="secondary" onClick={handleClose}>
																Cancel
															</Button>
															<Button variant="danger" onClick={deleteAccount}>
																Yes, Delete
															</Button>
														</Modal.Footer>
													</Modal>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		</div >
	);
};

export default Settings;