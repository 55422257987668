import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config';
import axios from 'axios';

const Verify = () => {
	// const verify = useRef(null);
	const [sended, setSended] = useState(false);
	const [limited, setLimited] = useState(false);
	const [countSended, setCountSended] = useState(0);
	const [verifyEmail, setVerifyEmail] = useState('')
	const resend = (e) => {
		e.preventDefault();
		if (countSended <= 3) {
			var data = {}
			data.email = verifyEmail
			axios.post(API_BASE_URL + '/api/newVerify', data)
				.then(response => {
					setSended(false)
					setTimeout(() => {
						setSended(true)
						var count = countSended;
						count++;
						setCountSended(count);
					}, 500)
				})
				.catch(error => { console.log(error); })
		} else {
			setLimited(true)
		}
	}

	useEffect(() => {
		if (localStorage.getItem('verifyEmail')) {
			setVerifyEmail(localStorage.getItem('verifyEmail'))
			localStorage.removeItem('verifyEmail')
		}
		localStorage.removeItem('verify')
		localStorage.setItem('signin', 'true')
	}, [verifyEmail])

	return (

		// <div className="signup-template bg-gray-100">
		// 	<div className="position-absolute top-0 start-0 p-4 p-md-5">
		// 		<Link to="/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></Link>
		// 	</div>
		// 	<div className="row g-0 align-items-center">
		// 		<div className="col-lg-7">
		// 			<img src="/img/BG/verify.webp" className="cover-fit" alt="Verify your email" />
		// 		</div>
		// 		<div className="col-lg-5 px-md-3 px-xl-5">
		// 			<div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
		// 				<div className="login-form py-2 py-md-0 mx-auto mx-lg-0">
		// 					<h2 className="h1 mb-3 pb-1">Verify your email</h2>
		// 					<form className="pt-md-2">
		// 						<p className="text-gray-700 pb-0">A verification email has been sent.
		// 							{/* to <a href="mailto:bruce@lee.com" className="text-gray-700 font-weight-semibold">bruce@lee.com</a>  */}
		// 							&nbsp;Please check your email to activate your account</p>
		// 						<div className="d-grid">
		// 							<a href='/signin' className="btn btn-xl btn-dark"><span className="signin-color">Back to Sign In</span></a>
		// 						</div>
		// 						<div className="my-1">
		// 							{sended && !limited && <p className="text-green-500 mb-0">A new verification link is sent to your email</p>}
		// 							<p className="text-gray-700 mb-0">Didn’t receive an email? <a href="#" onClick={resend} className="link-warning">Resend</a></p>
		// 							{limited && <p className="text-red-500">You have exhausted your password recovery limit, please try again later</p>}
		// 						</div>
		// 						<div className="border-top border-gray-200 pt-4">
		// 							<span className="text-gray-600 small">If you’re having any trouble resetting your password, head to our <br /><a href="#" className="text-gray-600"><u>Support Page</u></a> or contact our <a href="#" className="text-gray-600"><u>Support Team</u></a></span>
		// 						</div>
		// 						<div className="border-top border-gray-200 pt-4">
		// 						</div>
		// 					</form>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<div className="signup-simple-template bg-gray-100">
			<div className="header-background-body signup-header text-center">
				{/* <div className="container">
					<Link to="/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></Link>
				</div> */}
				<div className="header-background">
					{/* <img src="/img/BG/verify.webp" className="cover-fit" alt="Verify your email" /> */}
					<img src="/logo-final.png"  alt="FormBuilder" /> 
				</div>
			</div>
			<div className="container header-background-content">
				<div className="simple-login-form rounded-12 shadow-dark-80 bg-white mb-0">
					<h2 className="mb-0 text-center">Verify your email</h2>
					<form>
						<div className="pt-2 pb-2">
							<p className="text-gray-700 pb-0 m-0 text-center">A verification email was sent to</p>
							{verifyEmail?<div> <p className='text-black text-center m-0'>{verifyEmail}</p></div>:null}
							<p className="text-gray-700 pb-0 text-center">Follow the instructions to activate your account</p>
						</div>
						<div className="d-grid">
							<a href='/signin' className="btn btn-xl btn-dark"><span className="signin-color">Already did that</span></a>
						</div>
						<div className="my-1 verifyResendStyleDiv">
							{sended && !limited && <p className="text-green-500 mb-0">A new verification link is sent to your email</p>}
								<p className="verifyResendStyle text-gray-700 mb-0 text-center">Didn’t receive an email? <a href="#" onClick={resend} className="link-warning">Resend</a></p>
							{limited && <p className="text-red-500">You have exhausted your password recovery limit, please try again later</p>}
						</div>
						{/* <div className="border-top border-gray-200 pt-4">
							<span className="text-gray-600 small">If you’re having any trouble resetting your password, head to our <br /><a href="#" className="text-gray-600"><u>Support Page</u></a> or contact our <a href="#" className="text-gray-600"><u>Support Team</u></a></span>
						</div>
						<div className="border-top border-gray-200 pt-4">
						</div> */}
					</form>
				</div>
			</div>
		</div>
	);
};

export default Verify;