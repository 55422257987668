import axios from 'axios';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Block = () => {
	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setShowSucces(true)
			setShowError(false)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setShowSucces(false)
			setShowError(true)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}

	const [showSucces, setShowSucces] = useState(false)
	const [showError, setShowError] = useState(false)
	const [admin, setAdmin] = useState(false)
	const [formId, setFormId] = useState(null)
	const [editorData, setEditorData] = useState('')
	var writing = false
	useEffect(() => {
		if (localStorage.getItem('success')) {
			if (localStorage.getItem('successAdmin')) {
				localStorage.removeItem('successAdmin')
				setAdmin(true)
			}
			setFormId(localStorage.getItem('success'))
			var data = {}
			data.id = localStorage.getItem('success')
			localStorage.removeItem('success')
			axios.post(API_BASE_URL + '/api/getSuccessText', data)
				.then(response => {
					if (response.data) {
						setEditorData(response.data)
						// changeText.current.innerText = response.data
					} else {
						setEditorData("<span>We received your response <br/> we'll be in touch shortly!</span>")
						// changeText.current.innerText = "We received your response <br/> we'll be in touch shortly!"
					}
				})
				.catch(error => {
					showMessageError('Something went wrong.')
				})
		}
	}, [])
	const changeText = useRef(null)
	const [editedMessage, setEditedMessage] = useState('')
	const [liveWriting, setLiveWriting] = useState(false)
	const messageEdit = useRef();
	const editorCK = useRef();

	const editPreview = e => {
		e.preventDefault()
		changeText.current.classList.remove('disactive')
		writing = true
		setLiveWriting(true)
	}
	const editorConfiguration = {
		toolbar: [],
		heading: {
			options: []
		},
		placeholder: "",
	};

	document.documentElement.addEventListener("click", function (e) {
		if (admin) {
			if (!e.target.closest('.success-edit')) {
				changeText.current.classList.add('disactive')
				if (writing === true) {
					writing = false
					setLiveWriting(false)
					try {
						var data = {}
						data.id = formId;
						data.text = editorCK.current.editor.getData()
						axios.post(API_BASE_URL + '/api/saveSuccessText', data)
							.then(response => {
								showMessageSuccess('Successfully saved!!')
							})
							.catch(error => {
								showMessageError('Something went wrong.')
							})
					} catch (error) {
						showMessageError('Something went wrong.')
					}
				}
			}
		}
	})
	return (
		<div className="success success-page">
			<div className="form-changes" ref={messageEdit} >
			{
					showSucces?
						<>
							<div className='img-div'>
								<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
										stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
							
						</>
				:null
				}
				{
					showError?
						<>
							<div className='img-div'>
								<svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
										stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
						</>
					:null
				}
		</div>
			<div className="card">
				<div className="success-body">
					<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
						<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
					</svg>
				</div>
				<h1>Success</h1>
				<div className="success-edit disactive" ref={changeText}>
					{
						admin
						?
						<>
						<CKEditor
							ref={editorCK}
							editor={ClassicEditor}
							config={editorConfiguration}
							data={editorData ? editorData : ''}
							onChange={(event, editor) => {
								setEditorData(editor.getData())
							}}
						/>
						<button type='button' title="Change text" onClick={e => editPreview(e)}><span><i className="fas fa-pen"></i></span>
							<div className={liveWriting ? "writing-circles _active" : "writing-circles"}>
								<span className="writing-circle writing-circle-1"></span>
								<span className="writing-circle writing-circle-2"></span>
								<span className="writing-circle writing-circle-3"></span>
							</div>
						</button>
						</>
						:
						<div dangerouslySetInnerHTML={{ __html: editorData ? editorData : "" }} className='success-edit-text'></div>
					}
				</div>
			</div>
		</div>
	);
};

export default Block;