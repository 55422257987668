import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdmDataByMonth, getAdminDataByMonth, getAdminDataByMonthLoading } from "../../../../redux/reducers/adminReducer";
import Loader from "../../../../UI/Loader/Loader";

const StatByMonth = () => {
    const dataByMonth = useSelector(getAdminDataByMonth)
    const [dataAllByMonth, setDataAllByMonth] = useState();
    const loadingDataByMonth = useSelector(getAdminDataByMonthLoading)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAdmDataByMonth())
    }, [])
    // useEffect(()=>{
    //     console.log(dataByMonth)
    // },[dataByMonth])

    return (<>
        {loadingDataByMonth
            ?
            <div className="d-flex w-100 justify-content-center pb-4"><Loader /></div>
            :
            <table className="table">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        {
                            dataByMonth && dataByMonth.Registration  
                            ? Object.keys(dataByMonth.Registration).map((e,idx) => {
                                return <th key={idx} scope="col">{e}</th>
                            })
                            : null
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        dataByMonth
                        ?
                            Object.keys(dataByMonth).map((data, idx) => {
                                return <tr key={idx}>
                                    <th className="col-md-1">{data}</th>
                                    {
                                        Object.values(dataByMonth[data]).map((key, index) => {
                                           return <td className="col-md-1" key={index}>{key}</td>
                                        })
                                    }
                                </tr>
                            })
                        : null
                    }
                </tbody>
            </table>
        }

    </>
    );
};


export default StatByMonth;