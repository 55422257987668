import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { trim } from '../../../../help';

const Rating = (props) => {
	const { formData, setFormData, settingsData, showMessageError, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const dispatch = useDispatch()
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var uniqueId = formCopy[page].rows[row].content[0].cols[col].uniqueId;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page,
					row,
					col
				},
				details: {
					radio: {
						required,
						uniqueId
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

    const setRating = (e,rating) => {
        e.preventDefault()
        document.querySelectorAll('.rating-star').forEach(star => {
            star.classList.remove('_active');
        });
        e.target.classList.add('_active')
        setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: rating, direction: page + '/' + row + '/' + col } })
    }
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				//dispatch(setIsEdited(true))
			})
		}
	}, [contentRow])
	return (
		<div id="textPage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body">
					<div className="form-content-row-drag-text">
						<div className="text-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow'>{contentRow.content[0].cols[col].content.label} {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label {
											contentRow.content[0].cols[col].content.required
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Label {
										contentRow.content[0].cols[col].content.required
											? ' *'
											: ''
									}</label>
							}
						</div>
						<div className="text-inputs d-flex justify-content-start h-100">
							<div className="text-input">
                                <div className="star-wrapper" style={{ border: rowError ? "2px dotted red" : 'none' }}>
                                    <button type="button" onClick={(e) => setRating(e,5)} className="fas fa-star rating-star star-s1"></button>
                                    <button type="button" onClick={(e) => setRating(e,4)} className="fas fa-star rating-star star-s2"></button>
                                    <button type="button" onClick={(e) => setRating(e,3)} className="fas fa-star rating-star star-s3"></button>
                                    <button type="button" onClick={(e) => setRating(e,2)} className="fas fa-star rating-star star-s4"></button>
                                    <button type="button" onClick={(e) => setRating(e,1)} className="fas fa-star rating-star star-s5"></button>
                                </div>
							</div>
						</div>
					</div>
				</div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1 label-overflow' >{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
								}
							</div>
							{contentRow.content[0].cols[col].userValue
								?
								<div className="text-inputs d-flex justify-content-start h-100">
									<div className="">
                                        <div className="star-wrapper evented">
                                            <button type="button" className={`fas fa-star rating-star star-s1 ${contentRow.content[0].cols[col].userValue && contentRow.content[0].cols[col].userValue === 5 ? '_active' : ''}`}></button>
                                            <button type="button" className={`fas fa-star rating-star star-s2 ${contentRow.content[0].cols[col].userValue && contentRow.content[0].cols[col].userValue === 4 ? '_active' : ''}`}></button>
                                            <button type="button" className={`fas fa-star rating-star star-s3 ${contentRow.content[0].cols[col].userValue && contentRow.content[0].cols[col].userValue === 3 ? '_active' : ''}`}></button>
                                            <button type="button" className={`fas fa-star rating-star star-s4 ${contentRow.content[0].cols[col].userValue && contentRow.content[0].cols[col].userValue === 2 ? '_active' : ''}`}></button>
                                            <button type="button" className={`fas fa-star rating-star star-s5 ${contentRow.content[0].cols[col].userValue && contentRow.content[0].cols[col].userValue === 1 ? '_active' : ''}`}></button>
                                        </div>
									</div>
								</div>
								:
								<h3 className='d-flex justify-content-start w-100 h-100'>__</h3>
							}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text">
							<div className="text-label row-label">
								{
									contentRow.content[0].cols[col].content.responsable
										? <span className='form-content-filter form-content-responsable' title='In response'><i className="fas fa-check"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label} </div>
											
											:
											// <input type="text" defaultValue="Text" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Text</div>
										)
										:
										<div className={contentRow.content[0].cols[col].content.required ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Text</div>
									// <input type="text" defaultValue="Text" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<div className="text-inputs">
								<div className="text-input">
                                    <div className="star-wrapper evented">
                                        <button type="button" className="fas fa-star star star-s1"></button>
                                        <button type="button" className="fas fa-star star star-s2"></button>
                                        <button type="button" className="fas fa-star star star-s3"></button>
                                        <button type="button" className="fas fa-star star star-s4"></button>
                                        <button type="button" className="fas fa-star star star-s5"></button>
                                    </div>
								</div>
							</div>
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default Rating;