import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const FormSettingsInput = (props) => {
	const { settingsData, checkedInput, requiredChange, changeType, checkedInputType, formData, responseChange, checkedInputResponse } = props;
	const [showEmail, setShowEmail] = useState(true)

	useEffect(() => {
		const copyFormData = JSON.parse(JSON.stringify(formData));
		var stopLoop = true;
		for (let i = 0; i < copyFormData.length; i++ && stopLoop == true) {
			const forJ = copyFormData[i].rows;
			for (let j = 0; j < forJ.length; j++) {
				const forK = forJ[j].content[0].cols
				for (let k = 0; k < forK.length; k++) {
					if (forK[k].tabname == 'input') {
						if (forK[k].content.typeinput == 'email' && !(i == settingsData.info.page && j == settingsData.info.row && k == settingsData.info.col)) {
							setShowEmail(false)
							stopLoop = false;
						}
					}
				}
			}
		}
		stopLoop ? setShowEmail(true) : setShowEmail(false)
	}, [settingsData])

	const changeRequiredField = e => {
		requiredChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, 'input', e.target.checked)
	}

	const changeResponseField = e => {
		responseChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, settingsData.details.input.uniqueId, 'input',e.target.checked)
	}

	const changeTypeField = (type) => {
		changeType(settingsData.info.page, settingsData.info.row, settingsData.info.col, type)
	}

	return (
		<div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Required</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedInput}
							onChange={changeRequiredField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Is the field required?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">In response</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedInputResponse}
							onChange={changeResponseField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Whould you like to see this field on responses page?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Type</h6>
					<div className='type-inputs d-flex'>
						{
							showEmail
								?
								<div className={settingsData.details.input.typeinput == 'email' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
									onClick={() => changeTypeField('email')}
								>
									<button id='Email' className="me-0">Email</button>
								</div>
								: null
						}
						<div className={settingsData.details.input.typeinput == 'text' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeTypeField('text')}
						>
							<button id='Text' className="me-0">Text</button>
						</div>
						<div className={settingsData.details.input.typeinput == 'number' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeTypeField('number')}
						>
							<button id='Number' className="me-0">Number</button>
						</div>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">What is the type of input?</p>
			</div>
		</div>
	);
};

export default FormSettingsInput;