import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { AuthContext } from '../context';
import Sidebar from '../UI/Navbar/Sidebar';
import Navbar from '../UI/Navbar/Navbar';
import Header from '../components/Header';
import Footer from '../UI/Navbar/Footer';
import Content from '../components/Content';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { getMyForms, getMyFormsData, getMyTrashedForms } from '../redux/reducers/formReducer';
import { getGeneralInfo, getInfo, getInfoData } from '../redux/reducers/infoReducer';
import { useMemo } from 'react';
import { useHistory } from 'react-router';

const Home = () => {
	// const { isAuth, setIsAuth } = useContext(AuthContext);
	const me = useSelector(getInfoData);
	// const [myInfo, setMyInfo] = useState([]);
	// const [myInfoDetails, setMyInfoDetails] = useState([]);
	// const [myForms, setMyForms] = useState([]);
	// const [myTrashedForms, setMyTrashedForms] = useState([]);
	const dispatch = useDispatch()



	useEffect(() => {
		// useCallback(() => {
		// var data = {};
		// data.id = localStorage.getItem('authUser');
		// async function formsGet() {
		// 	await dispatch(getMyForms())
		// }
		// async function infoGet() {
		// 	await dispatch(getInfo())
		// }
		// async function generalGet() {
		// 	await dispatch(getGeneralInfo())
		// }
		// async function trashedGet() {
		// 	await dispatch(getMyTrashedForms())
		// }
		// formsGet()
		// infoGet()
		// generalGet()
		// trashedGet()

		dispatch(getMyForms())
		dispatch(getInfo())
		dispatch(getGeneralInfo())
		dispatch(getMyTrashedForms())
	}, [])

	useEffect(()=>{
		if(me.plan_pricing){
			 goProfile()
		}
	},[me])
	const history = useHistory()
	const goProfile = e => {
		localStorage.setItem('navigation',true);
		history.push('/profile')
	}

	return (
		<div className="bg-gray-100 project-management-template">
			{me?.blocked == 1 &&
				<div className="block-message"><p>
					Your account has been suspended. Please <button type="button" className="payment-button" onClick={goProfile}>pay</button> to continue using Formbuilder.
					</p></div>
			}
			<Sidebar />
			<Navbar />
			<div className="main-content" >
				<Header />
				<Content />
				<Footer />
			</div>
		</div>
	);
};

export default Home;