import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { SketchPicker } from 'react-color';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { getFormChangeSuccessStatus, getPreviewFormData } from "../../../../redux/reducers/formReducer";
import axios from "axios";
import { getInfo, getInfoData } from "../../../../redux/reducers/infoReducer"
import RedirectUrl from './RedirectUrl';
import { API_BASE_URL, AxiosConfigs } from "../../../../config";

const FormSettingsColor = (props) => {
	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	const myInfo = useSelector(getInfoData)
	const changeShowlogo = e => {
		setcheckedLogo(!checkedLogo)
		let data = {}
		data.value = e.target.checked;
		data.userId = myInfo._id;
		axios.post(API_BASE_URL + '/api/changeshowlogo', data, AxiosConfigs)
			.then(response => {
			})
			.catch(error => {
				window.location.href = window.location.origin + '/'
			})
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setShowError(false)
			setShowSucces(true)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setShowSucces(false)
			setShowError(true)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	const [tabname, setTabname] = useState('App')
	const [showSucces, setShowSucces] = useState(false)
	const [showError, setShowError] = useState(false)
	const [checkedLogo, setcheckedLogo] = useState(false)
    const getMyFormNow = useSelector(getFormChangeSuccessStatus);

	const changeTab = (e, tabname) => {
		e.preventDefault()
		try {
			const currentTarget = e.currentTarget
			if (tabname === 'App') {
				if (tabSuccess.current != null) { tabSuccess.current.classList.remove('active') }
					else if (tabLogo.current != null) { tabLogo.current.classList.remove('active') }
			} else if(tabname === 'Success'){
				if (tabApp.current != null) { tabApp.current.classList.remove('active') }
					else if (tabLogo.current != null) { tabLogo.current.classList.remove('active') }
			} else if(tabname === 'Logo') {
				if (tabSuccess.current != null) { tabSuccess.current.classList.remove('active') }
					else if (tabApp.current != null) { tabApp.current.classList.remove('active') }
			}
			currentTarget.classList.add('active')
			setTabname(tabname)

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const { settingsData, changeBackgroundColor, changeBackgroundOpacity, addNewColor, removeUserColor, form, setForm, colorsData } = props;
	const [colors, setColors] = useState([])
	const [successStatus, setSuccessStatus] = useState('')
	const params = useParams();
	const form_id = params.id
	const editorCK = useRef()
	const tabApp = useRef(null)
	const tabSuccess = useRef(null)
	const tabLogo = useRef(null)
	// const [colorsDataReady, setColorsDataReady] = useState([]);
	// const [form, setForm] = useState([])
	const [successText, setSuccessText] = useState("<span>We received your response <br/> we'll be in touch shortly!</span>")
	const [opacity, setOpacity] = useState(1)
	const editConfiguration = {
		toolbar: null,
		heading: {
			options: []
		},
		placeholder: ""
	};
	const defaultPickerColor = {
		r: '35',
		g: '173',
		b: '173',
		a: '1',
	};
	const [pickerColor, setPickerColor] = useState({
		r: '35',
		g: '173',
		b: '173',
		a: '1',
	})
	useEffect(() => {
		setSuccessStatus(getMyFormNow.success_status)
		setTabname('App')
	}, [getMyFormNow])
	// const [hoverText, setHoverText] = useState('')
	const changeColor = color => {
		changeBackgroundColor(color)
	}
	const changeOpacity = opacity => {
		// setOpacity(opacity)
		changeBackgroundOpacity(opacity)
	}
	useEffect(() => {
		setcheckedLogo(myInfo.showlogo === 1 ? true : false)
	}, [myInfo])
	useEffect(() => {
		// setColorsDataReady(colorsData)
		setColors(settingsData.details.colors)
		setOpacity(settingsData.details.opacity)
		setSuccessText("<span>We received your response <br/> we'll be in touch shortly!</span>")
	}, [settingsData])
	const [displayColorPicker, setDisplayColorPicker] = useState(false)

	const showPicker = () => {
		setDisplayColorPicker(!displayColorPicker)
	};

	const closePicker = e => {
		e.stopPropagation();
		setDisplayColorPicker(false)
		var newColor = `${pickerColor.r}, ${pickerColor.g}, ${pickerColor.b}`;
		addNewColor(newColor)
		// console.log(newColor);
		// setPickerColor(defaultPickerColor)
	};

	const addColor = (color) => {
		// console.log(color.rgb);
		setPickerColor(color.rgb)
		// this.setState({ color: color.rgb })
	};

	// const textHover = useRef()
	// const showHoverText = (text, elem) => {
	// 	var rect = elem.target.getBoundingClientRect();
	// 	var top = +rect.top
	// 	var left = +rect.left
	// 	textHover.current.style.top = top + 'px'
	// 	// textHover.current.style.left = left + 'px'
	// 	console.log(textHover.current);
	// 	// textHover.current[0].style.left = left
	// }
	const removeColor = color => {
		removeUserColor(color)
	}
	const [editedMessage, setEditedMessage] = useState('')
	const messageEdit = useRef();
	const successChanging = () => {
		if (form.success === "") {
			showMessageError('Please write something.')
		}
		else {
			try {
				var data = {}
				data.id = form_id;
				data.text = editorCK.current.editor.getData()
				axios.post(API_BASE_URL + '/api/saveSuccessText', data)
					.then(response => {
						showMessageSuccess('Successfully saved!!')
					})
					.catch(error => {
						showMessageError('Something went wrong.')
					})
			} catch (error) {
				showMessageError('Something went wrong.')
			}
		}
	}

	const changeSettingSuccess = e => {
		var data = {}
		data.formId = form_id;
		data.successStatus = e.target.value;
		setSuccessStatus(e.target.value);
		axios.post(API_BASE_URL + '/api/changeSuccesStatus', data)
			.then(response => { })
			.catch(error => {
				showMessageError('Something went wrong.')
			})
	}

	return (
		<div>
			<div className="form-changes" ref={messageEdit} >
				{
					showSucces ?
						<>
							<div className='img-div'>
								<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
									<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
										stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
									<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>

						</>
						: null
				}
				{
					showError ?
						<>
							<div className='img-div'>
								<svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z"
										stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
									<path d="M20 12L12 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
									<path d="M12 12L20 20" stroke="#EF585D" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
						</>
						: null
				}
			</div>

			<div className="container-fluid p-4 px-lg-5 py-0">
				<div className="mb-2 mb-md-3 mb-xl-4 pb-3 d-flex justify-content-between align-items-center">
					<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative zIndex-0 col-12">
						<li className="nav-item col-6">
							<button type="button" ref={tabApp} onClick={e => changeTab(e, 'App')} className={tabname == 'App' ? "nav-link w-100 active" : " w-100 nav-link"}>Appearance</button>
						</li>
						<li className="nav-item col-6">
							<button type="button" ref={tabSuccess} onClick={e => changeTab(e, 'Success')} className={tabname == 'Success' ? "nav-link w-100 active" : " w-100 nav-link"}>Success page</button>
						</li>
						{
							myInfo.plan === 'Premium' ?
								<li className="nav-item col-6">
									<button type="button" ref={tabLogo} onClick={e => changeTab(e, 'Logo')} className={tabname == 'Logo' ? "nav-link w-100 active" : " w-100 nav-link"}>Logo</button>
								</li> : ''
						}
					</ul>
				</div>
			</div>
			<div className="tabContent">
				{
					tabname == 'App'
						?
						<>
							<div className="p-4 px-lg-5 border-bottom border-gray-200">
								<div className="d-flex flex-column">
									<h6 className="font-weight-semibold mb-0">Background color</h6>
									<div className="mt-3 bg-colors" >
										{
											(Object).keys(colors).map((color, key) => {
												return <div className="bg-color" key={key}>
													<input className={color == 'default' ? "form-check-input me-0 bg-default" : "form-check-input me-0"} type="checkbox"
														title={`${color} ${colors[color]}`}
														checked={color == form.color ?? true}
														onChange={() => changeColor(color)}
														style={{ backgroundColor: `rgba(${colors[color]},1)` }}
													/>
													{
														color.includes('user')
															?
															<button type='button' className='remove-color' title='delete user color' onClick={() => removeColor(color)}><span><i className="fas fa-times"></i></span></button>
															: null
													}
												</div>
											})
										}
										{/* <div className="bg-color" onMouseEnter={(e) => showHoverText('Custom color', e)}> */}
										<div className="bg-color">
											<button type='button' title='Custom color' onClick={showPicker} className='btn-dark add-color'><span>{
												displayColorPicker ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>
											}</span></button>
										</div>
										{displayColorPicker ? <div className='picker-popover'>
											{/* <div className='picker-cover' onClick={closePicker} /> */}
											<SketchPicker color={pickerColor} onChange={addColor} />
											<button type='button' className='sketch-footer-button' onClick={closePicker}>ADD COLOR</button>
										</div> : null}
									</div>
								</div>
								<p className="text-gray-600 pt-2 mb-0">What the background color?</p>
							</div>
							<div className="p-4 px-lg-5 border-gray-200">
								<div className="d-flex flex-column">
									<h6 className="font-weight-semibold mb-0">Background color opacity</h6>
									<div className="mt-3 bg-colors" >
										{
											form.color != 'default'
												?
												<>
													<div className="bg-color">
														<input className="form-check-input me-0 bg-default" type="checkbox"
															style={{ backgroundColor: `rgba(${colors[form.color]},0.1)` }}
															checked={form.opacity == '0.1'}
															title='opacity 10%'
															onChange={() => changeOpacity('0.1')}
														/>
													</div>
													<div className="bg-color">
														<input className="form-check-input me-0" type="checkbox"
															style={{ backgroundColor: `rgba(${colors[form.color]},0.25)` }}
															checked={form.opacity == '0.25'}
															title='opacity 25%'
															onChange={() => changeOpacity('0.25')}
														/>
													</div>
													<div className="bg-color">
														<input className="form-check-input me-0" type="checkbox"
															style={{ backgroundColor: `rgba(${colors[form.color]},0.5)` }}
															checked={form.opacity == '0.5'}
															title='opacity 50%'
															onChange={() => changeOpacity('0.5')}
														/>
													</div>
													<div className="bg-color">
														<input className="form-check-input me-0" type="checkbox"
															style={{ backgroundColor: `rgba(${colors[form.color]},0.75)` }}
															checked={form.opacity == '0.75'}
															title='opacity 75%'
															onChange={() => changeOpacity('0.75')}
														/>
													</div>
												</>
												: null
										}
										<div className="bg-color">
											<input className={form.color == 'default' ? "form-check-input me-0 bg-default" : "form-check-input me-0"} type="checkbox"
												style={{ backgroundColor: `rgba(${colors[form.color]},1)` }}
												checked={form.opacity == '1'}
												title='opacity 100%'
												onChange={() => changeOpacity('1')}
											/>
										</div>
									</div>
								</div>
								<p className="text-gray-600 pt-2 mb-0">Choose the opacity of background color</p>
							</div>
						</>
						: tabname == 'Success' ?
						<div className="p-4 px-lg-5 border-gray-200">
							<div className="d-flex flex-column">
								<div className="option-content checkbox-option-content active ">
									<input
										className="form-check-input"
										type="radio"
										value="message"
										id='checkMessage'
										checked={successStatus === 'message'}
										name='succMessageOrLink'
										onChange={e => changeSettingSuccess(e)}
									/>
									<label className="preview-label" htmlFor={"checkMessage"}>Success Message</label>
								</div>
								<div className="option-content checkbox-option-content active succ-link-or-text">
									<input
										className="form-check-input "
										type="radio"
										id='checkLink'
										checked={successStatus === 'url'}
										value="url"
										name='succMessageOrLink'
										onChange={e => changeSettingSuccess(e)}
									/>
									<label className="preview-label" htmlFor={"checkLink"}>Redirect to URL</label>
								</div>
								{successStatus === 'message' ?
									<>
										<h6 className="font-weight-semibold mb-0">Success text</h6>
										<div className="mt-3 bg-colors" >
											<div className="w-100 ckChange">
												<>
													<CKEditor
														editor={ClassicEditor}
														ref={editorCK}
														data={form.success && form.success !== "" ? form.success : successText}
														config={editConfiguration}
														onChange={(event, editor) => {
															if (editor.getData() === "") {
																setSuccessText("")
															}
															setForm({ ...form, success: editor.getData() })
														}}
													/>
												</>
												<div className="pt-3 d-flex justify-content-end">
													<button type='button' title="Change text"
														// onClick={e => console.log(form.success)}
														onClick={successChanging}
														className="btn btn-m settings-button-close customize-close">
														Save
													</button>
												</div>
											</div>
										</div>
									</>
									: successStatus === 'url' ?
										<RedirectUrl form={form} showMessageSuccess={showMessageSuccess} showMessageError={showMessageError} /> :
										null
								}
							</div>
						</div>
				:
				myInfo.plan === 'Premium' ?
				<div className="p-4 px-lg-5 border-bottom border-gray-200">
					<div className="d-flex align-items-center">
						<h6 className="font-weight-semibold mb-0">Hide</h6>
						<div className="form-check form-switch mb-0 ms-auto" >
							<input className="form-check-input me-0" type="checkbox"
								checked={checkedLogo}
								onChange={e => changeShowlogo(e)}
							/>
						</div>
					</div>
				</div>
				:''		
			}

		</div>
		</div>
	)
};

export default FormSettingsColor;