import React, { useState, useEffect } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';
import MyButton from '../../../UI/button/MyButton';
import AdminEdit from './AdminEdit';
import AdminDelete from './AdminDelete';
import MyModal from '../../../UI/MyModal/MyModal';
import AdminEditRoom from './AdminEditRoom';
import AdminAdd from './AdminAdd';
import { API_BASE_URL, APP_BASE_URL } from '../../../config';
import AdminStatistics from './AdminStatistics/AdminStatistics';
import Pagination from 'react-laravel-paginex/dist/Pagination';
import AdminResponses from './AdminResponses';
import AdminMessages from './AdminMessages';
import AdminDataForms from './AdminDataForms';
import { useDispatch, useSelector } from 'react-redux';
import {getAdminUsers, getPayingInfo, setGlobalSearchText, setPayingInfo, setGlobalDateRange} from '../../../redux/reducers/adminReducer';
import AdminUsers from './AdminUsers';
import AdminCustomers from './AdminCustomers';
import Payment from './Payment';
import AdminErrors from './AdminErrors';
import AdminFormReports from "./AdminFormReports";
import AdminSuggestions from "./AdminSuggestions";

const AdminData = (props) => {
	//const [addError, setAddError] = useState('');
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [modalAdd, setModalAdd] = useState(false);
	const [tableAdd, setTableAdd] = useState('');
	const [sendData, setSendData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [savedData, setSavedData] = useState(false);
	const [clicked, setClicked] = useState(false)
	const dispatch = useDispatch();
	const [clickedAdd, setClickedAdd] = useState(false)
	const [clickedDelete, setClickedDelete] = useState(false);
	const [table, setTable] = useState('');
	const [dataAll, setDataAll] = useState([])
	const [dataAdminResponse, setDataAdminResponse] = useState([]);
	const [pageNumber, setPageNumber] = useState(1)
	const [userDate, setUserDate] = useState({})
	const [dateRange, setDateRange] = useState([null, null]);
	const [paymentDate, setPaymentDate] = useState({})
	const [tabNameInSolved, setTabNameInSolved] = useState('Not Solved')
	const [tabNameInPayment, setTabNameInPayment] = useState('Paid')
	// const editData = (data) => {
	// 	setModal(true)
	// 	setClicked(true)
	// 	setTableData(data)
	// }
	const addData = (data) => {
		setTableAdd(table)
		setModalAdd(true)
		// setClickedAdd(true)
	}

	// useEffect(() => {
	// 	let data = {}
	// 	data.page = 1;
	// 	dispatch(getAdminUsers(data))
	// }, [])

	useEffect(() => {
		const nowLocation = window.location.href.split('/evi-admin/')[1];
		setTable(nowLocation)
	}, [])


	useEffect(()=>{
		dispatch(setPayingInfo(false))
		dispatch(setGlobalSearchText(""))
		dispatch(setGlobalDateRange(
			{
				from: null,
				to: null
			}))
	}, [table])

	return (
		<div className="home__data">
			{
				table == 'statistics'
					?
					<AdminStatistics />
					:
					table == 'responses'
						?
						<AdminResponses
							addData={addData}
							setModal={setModal}
							setModalData={setModalData}
							dataAll={dataAll}
							setDataAll={setDataAll}
							tableData={tableData}
							setTableData={setTableData}
						/>
						:
						table == 'messages'
							?
							<AdminMessages
								tabNameInSolved={tabNameInSolved}
								setTabNameInSolved={setTabNameInSolved}
								pageNumber={pageNumber}
								setPageNumber={setPageNumber}
								addData={addData}
								setModal={setModal}
								setModalData={setModalData}
								dataAll={dataAll}
								setDataAll={setDataAll}
								tableData={tableData}
								setTableData={setTableData}
							/>
							:
							table == 'suggestions'
								?
								<AdminSuggestions
									pageNumber={pageNumber}
									setPageNumber={setPageNumber}
									addData={addData}
									setModal={setModal}
									setModalData={setModalData}
									dataAll={dataAll}
									setDataAll={setDataAll}
									tableData={tableData}
									setTableData={setTableData}
								/>
								:
							table == 'forms'
								?
								<AdminDataForms
									pageNumber={pageNumber}
									setPageNumber={setPageNumber}
									addData={addData}
									setModal={setModal}
									setModalData={setModalData}
									dataAll={dataAll}
									setDataAll={setDataAll}
									tableData={tableData}
									setTableData={setTableData}
								/>
								:

								table == 'customers'
									?
									<AdminCustomers
										tabNameInSolved={tabNameInSolved}
										setTabNameInSolved={setTabNameInSolved}
										pageNumber={pageNumber}
										setPageNumber={setPageNumber}
										addData={addData}
										setModal={setModal}
										setModalData={setModalData}
										dataAll={dataAll}
										setDataAll={setDataAll}
										tableData={tableData}
										setTableData={setTableData}
									/>
									:
									table == 'reports'
										?
										<AdminFormReports
											pageNumber={pageNumber}
											setPageNumber={setPageNumber}
											addData={addData}
											setModal={setModal}
											setModalData={setModalData}
											dataAll={dataAll}
											setDataAll={setDataAll}
											tableData={tableData}
											setTableData={setTableData}
										/>
										:
									table == 'payments'
										?
										<Payment
											tabNameInPayment={tabNameInPayment}
											setTabNameInPayment={setTabNameInPayment}
											pageNumber={pageNumber}
											setPageNumber={setPageNumber}
											addData={addData}
											setModal={setModal}
											setModalData={setModalData}
											dataAll={dataAll}
											setDataAll={setDataAll}
											tableData={tableData}
											setTableData={setTableData}
											dateRange={dateRange}
											setUserDate={setUserDate}
											setDateRange={setDateRange}
											paymentDate={paymentDate}
											setPaymentDate={setPaymentDate}
										/> :
										table == 'error-logs'
											?
											<AdminErrors />
											:
											table == 'users' || table === '' || table === undefined
												?
												<AdminUsers
													dateRange={dateRange}
													setDateRange={setDateRange}
													pageNumber={pageNumber}
													setPageNumber={setPageNumber}
													addData={addData}
													setModal={setModal}
													setModalData={setModalData}
													dataAll={dataAll}
													setDataAll={setDataAll}
													tableData={tableData}
													setTableData={setTableData}
													userDate={userDate}
													setUserDate={setUserDate}
												/>
												:
												<AdminStatistics />
			}

			<MyModal visible={modal} setVisible={setModal}>
				<AdminEdit
					dataAll={dataAll}
					setDataAll={setDataAll}
					modal={modal}
					setModal={setModal}
					modalData={modalData}
					setModalData={setModalData}
					tableData={tableData}
					setTableData={setTableData}
					pageNumber={pageNumber}
					table={table}
					userDate={userDate}
					tabNameInSolved={tabNameInSolved}
				/>
			</MyModal>

			<MyModal visible={modalAdd} setVisible={setModalAdd}>
				<AdminAdd
					setDateRange={setDateRange}
					setUserDate={setUserDate}
					dataAll={dataAll}
					setDataAll={setDataAll}
					modalAdd={modalAdd}
					setModalAdd={setModalAdd}
					tableAdd={tableAdd}
				/>
			</MyModal>
		</div >
	);

};

export default AdminData;