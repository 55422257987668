import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import { AuthContext } from '../context';
import SigninForm from './SigninForm';
import SigninFormGoogle from './SigninFormGoogle';
import { useParams } from 'react-router';
import { getCookie, setCookie, eraseCookie } from '../cookie';
import Loader from '../UI/Loader/Loader';

const Signin = () => {

	const { isAuthAdmin, setIsAuthAdmin, isAuth, setIsAuth } = useContext(AuthContext)
	const [signinData, setSigninData] = useState({ remember: true });
	const [errors, setErrors] = useState([]);
	const params = useParams();
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (window.location.pathname.split('/')[2] != undefined) {
			// console.log(window.location.pathname.split('/')[2]);
			if (localStorage.getItem('signin')) {
				localStorage.setItem('auth', 'true')
				localStorage.setItem('authUser', window.location.pathname.split('/')[2])
				if (localStorage.getItem('authAdmin')) {
					localStorage.removeItem('authAdmin')
					setIsAuthAdmin(false)
				}
				localStorage.removeItem('signin')
				setIsAuth(true)
				window.location.href = APP_BASE_URL + '/forms'
				// axios.post(API_BASE_URL + '/api/removeToken',{token:window.location.pathname.split('/')[2]})
				// 	.then(response => {
				// 		setIsAuth(true)
				// 		window.location.href = APP_BASE_URL + '/profile'
				// 	})
				// 	.catch(error => {
				// 		window.location.href = APP_BASE_URL + '/profile'
				// 	})
			} else {
				window.location.href = APP_BASE_URL + '/signin'
			}
		}
	}, [])
	const signin = e => {
		e.preventDefault()
		setLoading(true)
		axios.post(API_BASE_URL + '/api/signinUser', signinData)
			.then(response => {
				setLoading(false)
				const token = response.data.access_token;
				if (signinData.remember) {
					setCookie('_rmfo', '_an26:Pxk94>g8,|' + token, 365)
				}
				localStorage.setItem('auth', 'true')
				localStorage.setItem('authUser', token)
				if (localStorage.getItem('authAdmin')) {
					localStorage.removeItem('authAdmin')
					setIsAuthAdmin(false)
				}
				setIsAuth(true)
				window.location.href = APP_BASE_URL + '/forms'
			})
			.catch(error => {
				setLoading(false)
				if (error?.response?.data?.error == 'admin') {
					window.location.href = APP_BASE_URL + '/evi-admin'
				}
				if (error?.response?.data?.error.hasOwnProperty('verify')) {
					localStorage.setItem('verifyEmail', error.response.data.error.verify)
				}
				setErrors(error.response.data.error)
			})
	}

	return (

		<div className="signup-template bg-gray-100" style={{ backgroundColor: '#fff' }}>
			<div className="position-absolute top-0 start-0 p-4 p-md-5">
				<a href="http://formbuilder.ai/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></a>
			</div>
			<div className="row g-0 align-items-center" >
				<div className="col-lg-5" style={{ textAlign: 'center', backgroundColor: '#FBF3E5' }}>
					<img src="/img/BG/sign-in.svg" className="cover-fit" alt="Sign in Cover" />
				</div>
				<div className="col-lg-7 px-md-3 px-xl-5">
					<div className="signupForm">
						{
							loading
								?
								<div className="signupForm-loader">
									<Loader />
								</div>
								:
								null
						}
						<div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
							<div className="login-form py-2 py-md-0 mx-auto mx-lg-0" style={{ margin: 'auto' }}>
								<h2 className="h1 mb-2">Sign in</h2>
								<SigninFormGoogle isAuthAdmin={isAuthAdmin} setIsAuthAdmin={setIsAuthAdmin} isAuth={isAuth} setIsAuth={setIsAuth} />
								<div className="position-relative">
									<hr className="bg-gray-200 border-gray-200 opacity-100" />
									<span className="position-absolute top-0 start-50 translate-middle text-gray-600 small bg-gray-100 px-2 px-xxl-4 text-nowrap">Or sign in with email</span>
								</div>
								<SigninForm signinData={signinData} setSigninData={setSigninData} signin={signin} errors={errors} setErrors={setErrors} />
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};

export default Signin;