import React, { useEffect, useRef, useState } from 'react';
import Image from './Bars/Image';
import Fullname from './Bars/Fullname';
import Dropdown from './Bars/Dropdown';
import Text from './Bars/Text';
import Input from './Bars/Input';
import Checkbox from './Bars/Checkbox';
import Radio from './Bars/Radio';
import Paragraph from './Bars/Paragraph';
import Empty from './Bars/Empty';
import Rating from './Bars/Rating';
import Swal from 'sweetalert2'
import { randomString } from '../../../help';
import Upload from './Bars/Upload';
import DateBar from './Bars/DateBar';
import Divider from './Bars/Divider';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { setIsEdited } from '../../../redux/reducers/dataReducer';
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';

const SortableItem = sortableElement(({ value, isMultiple }) => <li
	className={isMultiple ? 'form-content-row-liner form-content-row-drag-li' : 'form-content-row-drag-li'}
// onMouseDown="return false"
>{value}</li>);

const SortableContainer = sortableContainer(({ children, helperClass, contentRow, isMultiple }) => {
	return <ul
		className={
			contentRow.content[0].cols.length == 1 || contentRow.content[0].cols.length == 0
				? isMultiple ? 'form-content-row-drags form-content-row-liner-ul' : 'form-content-row-drags'
				: (
					contentRow.content[0].cols.length == 2
						? isMultiple ? 'form-content-row-drags form-content-row-liner-ul splitted splitted-two' : 'form-content-row-drags splitted splitted-two'
						: (
							contentRow.content[0].cols.length == 3
								? isMultiple ? 'form-content-row-drags form-content-row-liner-ul splitted splitted-three' : 'form-content-row-drags splitted splitted-three'
								: isMultiple ? 'form-content-row-drags form-content-row-liner-ul splitted splitted-four' : 'form-content-row-drags splitted splitted-four'
						)
				)
		}
	>{children}</ul>;
});

const Row = (props) => {
	const { formData, setFormData, showMessageError, page, row, col, files, responseCheck, response, setFiles, userErrorsAll, emailError, setEmailError,  setDataForm, dataForm, setUserErrorsAll, saveForm, settingsData, setSettingsData, handleDropRows, userData, setUserData, formid, formDataUpdates, setFormDataUpdates, formDataUpdatesAnother, setFormDataUpdatesAnother, handleDropNavs, preview, contentRow, tabName, dropType, setDropType, checkAdd, setCheckAdd } = props;
	const [oneClicked, setOneClicked] = useState(false);
	const [twoClicked, setTwoClicked] = useState(false);
	const [threeClicked, setThreeClicked] = useState(false);
	const [fourClicked, setFourClicked] = useState(false);
	const dispatch = useDispatch()
	const components = {
		image: Image,
		fullname: Fullname,
		dropdown: Dropdown,
		text: Text,
		input: Input,
		checkbox: Checkbox,
		radio: Radio,
		paragraph: Paragraph,
		upload: Upload,
		empty: Empty,
		date: DateBar,
		divider: Divider,
		rating: Rating,
	};
	const [dragData, setDragData] = useState([0, 0]);

	const rowDrags = useRef();

	const [isDown, setIsDown] = useState(false);
	const [offset, setOffset] = useState([0, 0]);
	const [mousePosition, setMousePosition] = useState([]);
	const [copyItem, setCopyItem] = useState([]);
	const [splitted, setSplitted] = useState(false);
	// const focusInDiv = useRef()
	const editThisRow = e => {
	}
	const deleteThisRow = e => {
		e.preventDefault();
		try {
			var currentTarget = e.currentTarget;
			// Swal.fire({
			// 	title: 'Are you sure?',
			// 	text: 'This row will be removed and it will not be possible to restore',
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#dc3545',
			// 	customClass: {
			// 		cancelButton: 'btn-outline-dark-default'
			// 	},
			// 	confirmButtonText: 'Delete',
			// 	cancelButtonText: 'Close'
			// }).then((result) => {
			// 	if (result.isConfirmed) {
			// 		var formCopy = JSON.parse(JSON.stringify(formData))
			// 		// setFormData([])
			// 		var newFormCopy = formCopy[page].rows.splice(row, 1);
			// 		if (page !== 0) {
			// 			if (formCopy[page].rows.length === 0) {
			// 				formCopy.splice(page, 1);
			// 				console.log(formCopy)
			// 				formCopy[formCopy.length - 1].button = true;
			// 				const pages = document.querySelectorAll('.form-page-content')
			// 				pages[pages.length - 2].classList.add('show','active')
			//
			// 				const pagesButtons = document.querySelectorAll('.form-page-button')
			// 				pagesButtons[pagesButtons.length - 2].classList.add('active')
			// 			}
			// 		}
			// 		// console.log(pages)
			// 		setFormData(formCopy)
			// 		// saveForm(e, formCopy)
			// 		Swal.fire({
			// 			icon: 'error',
			// 			title: 'Row deleted',
			// 			showConfirmButton: false,
			// 			timer: 1500
			// 		})
			// 	}
			// })
			var formCopy = JSON.parse(JSON.stringify(formData))
			// setFormData([])
			var newFormCopy = formCopy[page].rows.splice(row, 1);
			if (page !== 0) {
				if (formCopy[page].rows.length === 0) {
					formCopy.splice(page, 1);
					console.log(formCopy)
					formCopy[formCopy.length - 1].button = true;
					const pages = document.querySelectorAll('.form-page-content')
					pages[pages.length - 2].classList.add('show', 'active')

					const pagesButtons = document.querySelectorAll('.form-page-button')
					pagesButtons[pagesButtons.length - 2].classList.add('active')
				}
			}
			// console.log(pages)
			setFormData(formCopy)
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const deleteThisRowContent = e => {
		e.preventDefault();
		try {
			var currentTarget = e.currentTarget;
			// Swal.fire({
			// 	title: 'Are you sure?',
			// 	text: 'This content will be removed and it will not be possible to restore',
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#dc3545',
			// 	customClass: {
			// 		cancelButton: 'btn-outline-dark-default'
			// 	},
			// 	confirmButtonText: 'Delete',
			// 	cancelButtonText: 'Close'
			// }).then((result) => {
			// 	if (result.isConfirmed) {
			// 		var col = currentTarget.getAttribute('col')
			// 		var formCopy = JSON.parse(JSON.stringify(formData))
			// 		// setFormData([])
			// 		var oldId = formCopy[page].rows[row].content[0].cols[col].id
			// 		formCopy[page].rows[row].content[0].cols[col] = {
			// 			'id': oldId,
			// 			'tabname': '',
			// 			'uniqueId': randomString(50),
			// 			'empty': true,
			// 			'content': [],
			// 		}
			// 		setFormData(formCopy)
			// 		// saveForm(e, formCopy)
			// 		Swal.fire({
			// 			icon: 'error',
			// 			title: 'Content deleted',
			// 			showConfirmButton: false,
			// 			timer: 1500
			// 		})
			// 	}
			// })
			var col = currentTarget.getAttribute('col')
			var formCopy = JSON.parse(JSON.stringify(formData))
			// setFormData([])
			var oldId = formCopy[page].rows[row].content[0].cols[col].id
			formCopy[page].rows[row].content[0].cols[col] = {
				'id': oldId,
				'tabname': '',
				'uniqueId': randomString(50),
				'empty': true,
				'content': [],
			}
			setFormData(formCopy)
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const activeThis = e => {
		// e.preventDefault();
		var rows = document.querySelectorAll('.form-content-row')
		for (let i = 0; i < rows.length; i++) { rows[i].classList.remove('active'); }
		e.currentTarget.classList.add('active')
	}
	const oneRow = e => {
		e.preventDefault();
		try {
			if (!oneClicked) {
				var formCopy = JSON.parse(JSON.stringify(formData))
				var formCopyData = formCopy[page].rows[row].content[0].cols
				var emptyData = []
				for (let i = 0; i < formCopyData.length; i++) {
					if (formCopyData[i].tabname) {
						emptyData.push(formCopyData[i])
					}
				}
				setFormData([])
				var newFormCopy = formCopy[page].rows[row].content[0].cols.splice(0);
				if (emptyData.length > 0) {
					formCopy[page].rows[row].content[0].cols.push(emptyData[0])
				} else {
					formCopy[page].rows[row].content[0].cols.push({
						id: 1,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					});
				}
				setFormData(formCopy)
				setOneClicked(true)
				setTwoClicked(false)
				setThreeClicked(false)
				setFourClicked(false)
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const twoRows = e => {
		e.preventDefault();
		try {
			if (!twoClicked) {
				var formCopy = JSON.parse(JSON.stringify(formData))
				var formCopyData = formCopy[page].rows[row].content[0].cols
				var emptyData = []
				for (let i = 0; i < formCopyData.length; i++) {
					if (formCopyData[i].tabname) {
						emptyData.push(formCopyData[i])
					}
				}
				setFormData([])
				var newFormCopy = formCopy[page].rows[row].content[0].cols.splice(0);
				if (emptyData.length == 0) {
					formCopy[page].rows[row].content[0].cols.push({
						id: 1,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}, {
						id: 2,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}
					);
				} else {
					for (let i = 0; i < emptyData.length; i++) {
						if (i < 2) {
							formCopy[page].rows[row].content[0].cols.push(emptyData[i])
						} else { break; }
					}
				}
				for (let i = formCopy[page].rows[row].content[0].cols.length; i < 2; i++) {
					formCopy[page].rows[row].content[0].cols.push({
						id: i,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}
					);
				}
				setFormData(formCopy)
				setTwoClicked(true)
				setOneClicked(false)
				setThreeClicked(false)
				setFourClicked(false)
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const threeRows = e => {
		e.preventDefault();
		try {
			if (!threeClicked) {
				var formCopy = JSON.parse(JSON.stringify(formData))
				var formCopyData = formCopy[page].rows[row].content[0].cols
				var emptyData = []
				for (let i = 0; i < formCopyData.length; i++) {
					if (formCopyData[i].tabname) {
						emptyData.push(formCopyData[i])
					}
				}
				setFormData([])
				var newFormCopy = formCopy[page].rows[row].content[0].cols.splice(0);
				if (emptyData.length == 0) {
					formCopy[page].rows[row].content[0].cols.push({
						id: 1,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}, {
						id: 2,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}, {
						id: 3,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}
					);
				} else {
					for (let i = 0; i < emptyData.length; i++) {
						if (i < 3) {
							formCopy[page].rows[row].content[0].cols.push(emptyData[i])
						} else { break; }
					}
				}
				for (let i = formCopy[page].rows[row].content[0].cols.length; i < 3; i++) {
					formCopy[page].rows[row].content[0].cols.push({
						id: i,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}
					);
				}
				setFormData(formCopy)
				setThreeClicked(true)
				setOneClicked(false)
				setTwoClicked(false)
				setFourClicked(false)
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const fourRows = e => {
		e.preventDefault();
		try {
			if (!fourClicked) {
				var formCopy = JSON.parse(JSON.stringify(formData))
				var formCopyData = formCopy[page].rows[row].content[0].cols
				var emptyData = []
				for (let i = 0; i < formCopyData.length; i++) {
					if (formCopyData[i].tabname) {
						emptyData.push(formCopyData[i])
					}
				}
				setFormData([])
				var newFormCopy = formCopy[page].rows[row].content[0].cols.splice(0);
				if (emptyData.length == 0) {
					formCopy[page].rows[row].content[0].cols.push({
						id: 1,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}, {
						id: 2,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}, {
						id: 3,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}, {
						id: 4,
						tabname: '',
						uniqueId: randomString(50),
						empty: true,
						content: [],
					}
					);
				} else {
					for (let i = 0; i < emptyData.length; i++) {
						if (i < 4) {
							formCopy[page].rows[row].content[0].cols.push(emptyData[i])
						} else { break; }
					}
				}
				for (let i = formCopy[page].rows[row].content[0].cols.length; i < 4; i++) {
					formCopy[page].rows[row].content[0].cols.push({
						id: i,
						tabname: '',
						empty: true,
						uniqueId: randomString(50),
						content: [],
					}
					);
				}
				setFormData(formCopy)
				setFourClicked(true)
				setOneClicked(false)
				setTwoClicked(false)
				setThreeClicked(false)
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}

	}
	const addActive = e => {
		e.preventDefault();
		var thisButton = e.currentTarget;
		const buttons = Array.prototype.slice.call(e.currentTarget.parentNode.children);
		var thisButtonIndex = buttons.indexOf(thisButton);
		for (let i = 0; i < buttons.length; i++) {
			if (i <= thisButtonIndex) {
				buttons[i].classList.add('active')
			}
		}
	}
	const removeActive = e => {
		e.preventDefault();
		const buttons = Array.prototype.slice.call(e.currentTarget.parentNode.children);
		for (let i = 0; i < buttons.length; i++) {
			buttons[i].classList.remove('active')
		}
	}
	const handleDragEnter = (ev) => {
		ev.preventDefault()
		ev.currentTarget.parentNode.classList.add('beforeDrop')
		ev.currentTarget.parentNode.style.outline = 'none'
	}
	const handleDragLeave = (ev) => {
		ev.preventDefault()
		ev.currentTarget.parentNode.classList.remove('beforeDrop')
		ev.currentTarget.parentNode.style.outline = '2px dashed rgba(0, 0, 0, 0.15)'
	}
	// const changeLabel = e => {
	// 	e.preventDefault()
	// 	var PageIndex = +e.currentTarget.getAttribute('page');
	// 	var RowIndex = +e.currentTarget.getAttribute('row');
	// 	var ColIndex = +e.currentTarget.getAttribute('col');
	// 	const updateName = `form_${PageIndex}_${RowIndex}_${ColIndex}`
	// 	// const copyFormUpdatesAnother = JSON.parse(JSON.stringify(formDataUpdatesAnother))
	// 	// setFormDataUpdatesAnother([]);
	// 	formDataUpdatesAnother[updateName] = {
	// 		page: PageIndex,
	// 		row: RowIndex,
	// 		col: ColIndex,
	// 		content: [
	// 			{
	// 				label: e.target.value
	// 			}
	// 		]
	// 	}
	// 	if (formDataUpdatesAnother[updateName]) {
	// 		if (formDataUpdatesAnother[updateName].content[0].inLabel) {
	// 			formDataUpdatesAnother[updateName].content[0].label = e.target.value
	// 		} else {
	// 			formDataUpdatesAnother[updateName] = {
	// 				page: PageIndex,
	// 				row: RowIndex,
	// 				col: ColIndex,
	// 				content: [
	// 					{
	// 						label: e.target.value
	// 					}
	// 				]
	// 			}
	// 		}
	// 	} else {
	// 		formDataUpdatesAnother[updateName] = {
	// 			page: PageIndex,
	// 			row: RowIndex,
	// 			col: ColIndex,
	// 			content: [
	// 				{
	// 					label: e.target.value
	// 				}
	// 			]
	// 		}
	// 	}
	// 	// saveForm(e, null)
	// 	// setFormDataUpdatesAnother(copyFormUpdatesAnother)
	// }
	// const changeInputInLabel = e => {
	// 	e.preventDefault()
	// 	var PageIndex = +e.currentTarget.getAttribute('page');
	// 	var RowIndex = +e.currentTarget.getAttribute('row');
	// 	var ColIndex = +e.currentTarget.getAttribute('col');
	// 	const updateName = `form_${PageIndex}_${RowIndex}_${ColIndex}`
	// 	// const copyFormUpdatesAnother = JSON.parse(JSON.stringify(formDataUpdatesAnother))
	// 	// setFormDataUpdatesAnother([]);
	// 	if (formDataUpdatesAnother[updateName]) {
	// 		if (formDataUpdatesAnother[updateName].content[0].label) {
	// 			formDataUpdatesAnother[updateName].content[0].inLabel = e.target.value
	// 		} else {
	// 			formDataUpdatesAnother[updateName] = {
	// 				page: PageIndex,
	// 				row: RowIndex,
	// 				col: ColIndex,
	// 				content: [
	// 					{
	// 						inLabel: e.target.value
	// 					}
	// 				]
	// 			}
	// 		}
	// 	} else {
	// 		formDataUpdatesAnother[updateName] = {
	// 			page: PageIndex,
	// 			row: RowIndex,
	// 			col: ColIndex,
	// 			content: [
	// 				{
	// 					inLabel: e.target.value
	// 				}
	// 			]
	// 		}
	// 	}
	// }
	function changeOption(options) {
		// console.log(options);
		// e.preventDefault()
		// var PageIndex = +e.currentTarget.getAttribute('page');
		// var RowIndex = +e.currentTarget.getAttribute('row');
		// var ColIndex = +e.currentTarget.getAttribute('col');
		// const updateName = `form_${PageIndex}_${RowIndex}_${ColIndex}`
		// // const copyFormUpdatesAnother = JSON.parse(JSON.stringify(formDataUpdatesAnother))
		// // setFormDataUpdatesAnother([]);
		// formDataUpdatesAnother[updateName] = {
		// 	page: PageIndex,
		// 	row: RowIndex,
		// 	col: ColIndex,
		// 	content: [
		// 		{
		// 			label: e.target.value
		// 		}
		// 	]
		// }
		// setFormDataUpdatesAnother(copyFormUpdatesAnother)
	}
	const changeImage = e => {
		e.preventDefault()
		try {
			var file = e.target.files[0];
			if (file && file['type'].split('/')[0] === 'image') {
				var currentTarget = e.currentTarget.closest('.form-content-row-drag');
				const PageIndex = +currentTarget.getAttribute('page');
				const RowIndex = +currentTarget.getAttribute('row');
				const ColIndex = +currentTarget.getAttribute('col');
				const updateName = `form_${PageIndex}_${RowIndex}_${ColIndex}`
				// const copyFormUpdates = JSON.parse(JSON.stringify(formDataUpdates))
				var oldFileName = file.name
				var splittedName = oldFileName.split('.').slice(0, -1).join('.')
				var splittedExt = oldFileName.split('.').pop();
				var newFileName = splittedName + Date.now() + randomString(8)
				const newFormData = JSON.parse(JSON.stringify(formData));
				var realSrc = URL.createObjectURL(file);
				setFormData([])
				newFormData[PageIndex].rows[RowIndex].content[0].cols[ColIndex].content = [{
					realSrc: realSrc,
				}]
				file.oldPath = '/files/Form/' + formid + '/' + newFileName.replace(/ /g, '-')
				setFormData(newFormData);
				let index = Array.prototype.indexOf.call(currentTarget.parentNode.childNodes, currentTarget)
				let obj = {
					page: PageIndex,
					row: RowIndex,
					col: ColIndex,
					content: [
						{
							realSrc: file,
							src: '/files/Form/' + formid + '/' + newFileName.replace(/ /g, '-') + '.' + splittedExt,
							srcOld: '/files/Form/' + formid + '/' + newFileName.replace(/ /g, '-'),
						}
					]
				}
				var checkForm = false;

				if (formDataUpdates.length > 0) {
					for (let i = 0; i < formDataUpdates.length; i++) {
						const el = formDataUpdates[i];
						if (el.page === PageIndex && el.row === RowIndex && el.col === ColIndex) {
							el.content[0].realSrc = file;
							el.content[0].src = '/files/Form/' + formid + '/' + newFileName.replace(/ /g, '-') + '.' + splittedExt;
							el.content[0].srcOld = '/files/Form/' + formid + '/' + newFileName.replace(/ /g, '-');
							break;
						} else { }
						if (i == formDataUpdates.length - 1) { checkForm = true; }
					}
					if (checkForm) { setFormDataUpdates([...formDataUpdates, obj]) }
				} else {
					setFormDataUpdates([obj]);
				}
				dispatch(setIsEdited(true))
			} else {
				showMessageError('Please select image file.')
			}
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	useEffect(() => {
		contentRow.content[0].cols.length > 1 ? setSplitted(true) : setSplitted(false);
	})

	const onSortStart = (ev) => {
		document.body.style.cursor = 'grabbing'
		setDropType('row');
		setDragData([+page, +row]);
	}

	function arraymove(arr, fromIndex, toIndex) {
		// console.log(arr);
		try {
			const element = JSON.parse(JSON.stringify(arr[fromIndex]));
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			// saveForm(null, arr)
			// arr.sort(function(a, b) { 
			// 	return a.id - b.id;
			// });
			// console.log(arr, fromIndex, toIndex);
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const onSortEnd = (ev) => {
		try {
			document.body.style.cursor = 'default'
			if (dropType === 'row') {
				const oldIndex = ev.oldIndex;
				const newIndex = ev.newIndex;
				const dragPageIndex = dragData[0];
				const newFormData = JSON.parse(JSON.stringify(formData));
				// const oldElement = JSON.parse(JSON.stringify(newFormData[dragPageIndex].rows[oldIndex]))
				// const newElement = JSON.parse(JSON.stringify(newFormData[dragPageIndex].rows[newIndex]))
				// newFormData[dragPageIndex].rows[oldIndex]['content'] = newElement['content']
				// newFormData[dragPageIndex].rows[newIndex]['content'] = oldElement['content']
				if (oldIndex != newIndex) {
					// setFormData([])
					arraymove(newFormData[dragPageIndex].rows[row].content[0].cols, oldIndex, newIndex)
					setFormData(newFormData);
				}
				// const lines = document.querySelectorAll('.form-content-row-line')
				// for (let i = 0; i < lines.length; i++) {
				// 	lines[i].classList.remove('active')
				// 	lines[i].classList.remove('after')
				// }

			}
			dispatch(setIsEdited(true))

		} catch (error) {
			showMessageError('Something went wrong.')
		}
	};
	// console.log(userErrorsAll);
	return (
		<div>
			{preview
				?
				<div className="form-content-row py-1"
					onClick={activeThis}>
					<div className="form-content-row-body axis-y preview-form-content-row-body"

					>
						<div className="form-content-row-content"
							page={page}
							row={row}
						>
							{
								<div className={
									contentRow.content[0].cols.length == 1 || contentRow.content[0].cols.length == 0
										? 'form-content-row-drags'
										: (
											contentRow.content[0].cols.length == 2
												? 'form-content-row-drags splitted splitted-two'
												: (
													contentRow.content[0].cols.length == 3
														? 'form-content-row-drags splitted splitted-three'
														: 'form-content-row-drags splitted splitted-four'

												)
										)
								} >

									{contentRow.content[0].cols.map((cRow, index) => {
										const nowTabName = cRow.tabname;
										const DynamicComponent = nowTabName == '' ? '' : components[nowTabName];
										return nowTabName == ''
											?
											null
											:
											// <Animated animationIn="shake" animationOut="fadeOut" isVisible={true} key={index} >
											<div className="form-content-row-drag-li" key={index}>
												<DynamicComponent
													userData={userData}
													setUserData={setUserData}
													formData={formData}
													setFormData={setFormData}
													preview={preview}
													page={page}
													row={row}
													checkAdd={checkAdd}
													setCheckAdd={setCheckAdd}
													col={index}
													contentRow={contentRow}
													splitted={splitted}
													rowError={userErrorsAll.filter(e => e.uniqueId === contentRow.content[0].cols[index].uniqueId).length > 0 ? true : false}
													dataForm={dataForm}
													setDataForm={setDataForm}
													emailError={emailError}
													setEmailError={setEmailError}
													files={files}
													setFiles={setFiles}
													showMessageError={showMessageError}
												/>
											</div>
										// </Animated>
									})
									}
								</div>
							}
						</div>
					</div >
					<span className='form-content-row-line'></span>
				</div>
				:
				(
					responseCheck
						?
						<div className="form-content-row py-1"
							onClick={activeThis}>
							<div className="form-content-row-body axis-y preview-form-content-row-body">
								<div className="form-content-row-content"
									page={page}
									row={row}
								>
									{
										<div className={
											contentRow.content[0].cols.length == 1 || contentRow.content[0].cols.length == 0
												? 'form-content-row-drags'
												: (
													contentRow.content[0].cols.length == 2
														? 'form-content-row-drags splitted splitted-two'
														: (
															contentRow.content[0].cols.length == 3
																? 'form-content-row-drags splitted splitted-three'
																: 'form-content-row-drags splitted splitted-four'

														)
												)
										} >

											{contentRow.content[0].cols.map((cRow, index) => {
												const nowTabName = cRow.tabname;
												const DynamicComponent = nowTabName == '' ? '' : components[nowTabName];
												return nowTabName == ''
													?
													<div className="form-content-row-drag-li" key={index}>
														<div className="form-content-row-drag" page={page} row={row} col={index}>
															<div className="form-content-row-drag-text empty-row" >
																<div className="form-content-row-drag-icon">
																	<i className="fas fa-arrows-alt"></i>
																</div>
																<p>Drag your content here from the left bar. </p>
															</div>
															<div className="forDragAnimation" ></div>
														</div>
													</div>
													:
													// <Animated animationIn="shake" animationOut="fadeOut" isVisible={true} key={index} >
													<div className="form-content-row-drag-li" key={index}>
														<DynamicComponent
															userData={userData}
															setUserData={setUserData}
															formData={formData}
															setFormData={setFormData}
															preview={preview}
															page={page}
															row={row}
															response={response}
															checkAdd={checkAdd}
															responseCheck={true}
															setCheckAdd={setCheckAdd}
															col={index}
															contentRow={contentRow}
															splitted={splitted}
															rowError={userErrorsAll.indexOf(contentRow.content[0].cols[index].content.label + '_' + page + row + index) != -1 ? true : false}
															dataForm={dataForm}
															setDataForm={setDataForm}
															emailError={emailError}
															setEmailError={setEmailError}
															files={files}
															setFiles={setFiles}
														/>
													</div>
												// </Animated>
											})
											}
										</div>
									}
								</div>
							</div >
							<span className='form-content-row-line'></span>
						</div>
						:
						<div className="form-content-row py-1 newform-page">
							<div className={contentRow.content[0].cols[0].tabname == 'divider' ? "form-content-row-body form-content-row-body-divider axis-y" : "form-content-row-body axis-y"}>
								<div className="form-content-row-content"
									draggable={true}
									onDragOver={(ev) => ev.preventDefault()}
									page={page}
									row={row}>
									<SortableContainer
										onSortStart={onSortStart}
										onSortEnd={onSortEnd}
										lockAxis={window.innerWidth >= 768 ? 'x' : 'y'}
										axis={window.innerWidth >= 768 ? 'x' : 'y'}
										lockToContainerEdges={true}
										lockOffset={"0%"}
										pressDelay={100}
										contentRow={contentRow}
										isMultiple={contentRow.content[0].cols.length == 1 ? false : true}>
										{
											contentRow.content[0].cols.map((cRow, index) => {
												const nowTabName = cRow.tabname;
												const DynamicComponent = nowTabName == '' ? '' : components[nowTabName];
												return nowTabName == ''
													?
													<SortableItem
														key={index}
														index={index}
														ref={rowDrags}
														disabled={contentRow.content[0].cols.length == 1}
														isMultiple={contentRow.content[0].cols.length == 1 ? false : true}
														value={
															<div className="form-content-row-drag" onDragOver={(ev) => ev.preventDefault()} onDrop={(ev) => handleDropNavs(ev, page, row, index)} page={page} row={row} col={index}>
																<div className="form-content-row-drag-text empty-row" >
																	<div className="form-content-row-drag-icon">
																		<i className="fas fa-arrows-alt"></i>
																	</div>
																	<p>Drag your content here from the left bar. </p>
																</div>
																<div className="forDragAnimation" onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onMouseLeave={handleDragLeave}></div>
															</div>
														}>
													</SortableItem>
													: <SortableItem
														key={index}
														index={index}
														isMultiple={contentRow.content[0].cols.length == 1 ? false : true}
														// disabled={cRow.content.disabled}
														disabled={contentRow.content[0].cols.length == 1}
														value={
															<DynamicComponent
																formData={formData}
																setFormData={setFormData}
																key={index}
																chanegOption={changeOption}
																changeImage={changeImage}
																preview={preview}
																page={page}
																row={row}
																deleteThisRowContent={deleteThisRowContent}
																checkAdd={checkAdd}
																setCheckAdd={setCheckAdd}
																col={index}
																contentRow={contentRow}
																splitted={splitted}
																settingsData={settingsData}
																setSettingsData={setSettingsData}
																showMessageError={showMessageError}
															/>
														}>
													</SortableItem>
											})
										}
									</SortableContainer>
								</div>
								{
									contentRow.content[0].cols[0].tabname != 'divider'
										?
										<div className="form-content-row-buttons">
											{
												// contentRow.content[0].cols.length > 0 && contentRow.content[0].cols[0].content.length > 0
												// 	? <div className="edit-button row-buttons-button">
												// 		<button className="row-button-body edit-button-body" type='button' onClick={editThisRow} title='Settings'><span><i className="fas fa-cog"></i></span></button>
												// 	</div>
												// 	:
												<div className={(page == 0 && row == 0) ? "row-buttons row-buttons-button row-buttons-body row-buttons-button-split d-flex flex-row align-items-center" : "row-buttons row-buttons-body row-buttons-button row-buttons-button-split"} >
													<div className="row-button" onMouseEnter={addActive} onMouseLeave={removeActive} onClick={oneRow}><span></span></div>
													<div className="row-button" onMouseEnter={addActive} onMouseLeave={removeActive} onClick={twoRows}><span></span></div>
													<div className="row-button" onMouseEnter={addActive} onMouseLeave={removeActive} onClick={threeRows}><span></span></div>
													<div className="row-button" onMouseEnter={addActive} onMouseLeave={removeActive} onClick={fourRows}><span></span></div>
												</div>
											}
											{
												!(page == 0 && row == 0)
													?
													<div className="delete-button row-buttons-button">
														<button className={"row-button-body delete-button-body"} type='button' onClick={deleteThisRow} title='Delete Row'><span><i className="fas fa-times"></i></span></button>
													</div>
													: null
											}

										</div>
										: null
								}
							</div >
							<span className='form-content-row-line'></span>
						</div>
				)
			}
		</div>
	);
};

export default Row;