import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Loader from '../../UI/Loader/Loader';
import {
	getResponsesMonthInfo,
	getResponsesTodayInfo,
	getResponsesAllInfo,
	getResponsesYearInfo,
	getResponsesFullInfo,
	getTrashedResponsesAllInfo,
	getResponsesFullByMonthInfo
} from '../features/dataApi';

const initialState = {
	responsesData: {},
	responsesLoading: false,
	responsesTrashedData: {},
	responsesTrashedDataLoading: false,
	responsesLabels: [],
	responsesFilters: [],
	responsesFavorites: {},
	responsesToday: [],
	responsesTodayDifference: [],
	responsesTodayLoading: false,
	responsesMonthCount: [],
	responsesMonthDifference: [],
	responsesMonthLoading: false,
	responsesYear: [],
	responsesYearLoading: false,
	responsesFull: {},
	responsesFullLoading: false,
	responsesFullByMonth: {},
	responsesFullByMontLoading: false,
}

export const getResponses = createAsyncThunk(
	'/api/getResponses?page=',
	async (formId) => {
		const response = await getResponsesAllInfo(formId)
		return response.data 
	}
)

export const getTrashedResponses = createAsyncThunk(
	'/api/getTrashedResponses',
	async (formId) => {
		const response = await getTrashedResponsesAllInfo(formId)
		return response.data
	}
)

export const getResponsesMonth = createAsyncThunk(
	'/api/getResponsesMonth',
	async (formId) => {
		const response = await getResponsesMonthInfo(formId)
		return response.data
	}
)

export const getResponsesToday = createAsyncThunk(
	'/api/getResponsesToday',
	async (formId) => {
		const response = await getResponsesTodayInfo(formId)
		return response.data
	}
)

export const getResponsesYear = createAsyncThunk(
	'/api/getResponsesYear',
	async (formId) => {
		const response = await getResponsesYearInfo(formId)
		return response.data
	}
)

export const getResponsesFull = createAsyncThunk(
	'/api/getResponsesDatas',
	async (formId) => {
		const response = await getResponsesFullInfo()
		return response.data
	}
)

export const getResponsesFullByMonth = createAsyncThunk(
	'/api/getResponsesFullByMonth',
	async () => {
		const response = await getResponsesFullByMonthInfo()
		return response.data
	}
)

export const responseSlice = createSlice({
	name: 'responseReducer',
	initialState,
	reducers: {
		setResponses: (state, action) => {
			state.responsesData = action.payload.responses
			state.responsesLabels = action.payload.labels
			state.responsesFilters = action.payload.filters
			state.responsesFavorites = action.payload.favorites
		},
		setTrashedResponses: (state, action) => {
			state.responsesTrashedData = action.payload
		},
		setResponsesMonth: (state, action) => {
			state.responsesMonthCount = action.payload.count
			state.responsesMonthDifference = action.payload.difference
		},
		setResponsesToday: (state, action) => {
			state.responsesToday = action.payload.count
			state.responsesTodayDifference = action.payload.difference
		},
		setResponsesYear: (state, action) => {
			state.responsesYear = action.payload
		},
		setResponsesFull: (state, action) => {
			state.responsesFull = action.payload
		},
		setResponsesFullByMonth: (state, action) => {
			state.responsesFullByMonth = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getResponses.fulfilled, (state, action) => {
				state.responsesLoading = false
				responseSlice.caseReducers.setResponses(state, action)
			})
			.addCase(getResponses.pending, (state, action) => {
				state.responsesLoading = true
			})
			.addCase(getTrashedResponses.fulfilled, (state, action) => {
				state.responsesTrashedDataLoading = false
				responseSlice.caseReducers.setTrashedResponses(state, action)
			})
			.addCase(getTrashedResponses.pending, (state, action) => {
				state.responsesTrashedDataLoading = true
			})
			.addCase(getResponsesMonth.fulfilled, (state, action) => {
				state.responsesMonthLoading = false
				responseSlice.caseReducers.setResponsesMonth(state, action)
			})
			.addCase(getResponsesMonth.pending, (state, action) => {
				state.responsesMonthLoading = true
			})
			.addCase(getResponsesToday.fulfilled, (state, action) => {
				state.responsesTodayLoading = false
				responseSlice.caseReducers.setResponsesToday(state, action)
			})
			.addCase(getResponsesToday.pending, (state, action) => {
				state.responsesTodayLoading = true
			})
			.addCase(getResponsesYear.fulfilled, (state, action) => {
				state.responsesYearLoading = false
				responseSlice.caseReducers.setResponsesYear(state, action)
			})
			.addCase(getResponsesYear.pending, (state, action) => {
				state.responsesYearLoading = true
			})
			.addCase(getResponsesFull.fulfilled, (state, action) => {
				state.responsesFullLoading = false
				responseSlice.caseReducers.setResponsesFull(state, action)
			})
			.addCase(getResponsesFull.pending, (state, action) => {
				state.responsesFullLoading = true
			})
			.addCase(getResponsesFullByMonth.fulfilled, (state, action) => {
				state.responsesFullByMontLoading = false
				responseSlice.caseReducers.setResponsesFullByMonth(state, action)
			})
			.addCase(getResponsesFullByMonth.pending, (state, action) => {
				state.responsesFullByMontLoading = true
			})
	},
});

export const { setResponses, setTrashedResponses, setResponsesMonth, setResponsesToday, setResponsesFull, setResponsesFullByMonth, setResponsesYear } = responseSlice.actions;

export const getResponsesData = (state) => state.responseReducer.responsesData;
export const getResponsesTrashedData = (state) => state.responseReducer.responsesTrashedData;
export const getResponsesTrashedDataLoading = (state) => state.responseReducer.responsesTrashedDataLoading;
export const getResponsesFullData = (state) => state.responseReducer.responsesFull;
export const getResponsesFullDataLoading = (state) => state.responseReducer.responsesFullLoading;
export const getResponsesFullByMonthData = (state) => state.responseReducer.responsesFullByMonth;
export const getResponsesFullByMonthDataLoading = (state) => state.responseReducer.responsesFullByMonthLoading;
export const getResponsesLabels = (state) => state.responseReducer.responsesLabels;
export const getResponsesFilters = (state) => state.responseReducer.responsesFilters;
export const getResponsesFavorites = (state) => state.responseReducer.responsesFavorites;
export const getResponsesDataLoading = (state) => state.responseReducer.responsesLoading;
export const getResponsesTodayData = (state) => state.responseReducer.responsesToday;
export const getResponsesTodayDataDifference = (state) => state.responseReducer.responsesTodayDifference;
export const getResponsesTodayDataLoading = (state) => state.responseReducer.responsesTodayLoading;
export const getResponsesMonthData = (state) => state.responseReducer.responsesMonthCount;
export const getResponsesMonthDataDifference = (state) => state.responseReducer.responsesMonthDifference;
export const getResponsesMonthDataLoading = (state) => state.responseReducer.responsesMonthLoading;
export const getResponsesYearData = (state) => state.responseReducer.responsesYear;
export const getResponsesYearDataLoading = (state) => state.responseReducer.responsesYearLoading;

export default responseSlice.reducer;
