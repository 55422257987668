import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context';
import { API_BASE_URL, APP_BASE_URL } from '../../config';
import AdminLoginForm from './AdminLoginForm';
import { eraseCookie, getCookie } from '../../cookie';
import Loader from '../../UI/Loader/Loader';

const AdminLogin = () => {
	const { isAuthAdmin, setIsAuthAdmin, isAuth, setIsAuth } = useContext(AuthContext)
	const [loginData, setLoginData] = useState({});
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false)

	const login = e => {
		e.preventDefault()
		setLoading(true)
		try {
			axios.post(API_BASE_URL + '/api/sendlogin', loginData)
				.then(response => {
					setLoading(false)
					const token = response.data.access_token;
					localStorage.setItem('authAdmin', 'true')
					localStorage.setItem('authUser', token)
					if (localStorage.getItem('auth')) {
						localStorage.removeItem('auth')
						setIsAuth(false)
					}
					setIsAuthAdmin(true)
					if (getCookie('_rmfo')) { eraseCookie('_rmfo') }
				})
				.catch(error => {
					setLoading(false)
					// console.log(error);
					setErrors(error.response.data.error)
				})
		} catch (error) {}
	}
	return (
		<div className="login100">
			<div className="wrap-login100">		
				<AdminLoginForm login={login} loginData={loginData} setLoginData={setLoginData} errors={errors} setErrors={setErrors} loading={loading}/>		
				<div className="login100-more" style={{ backgroundImage: "url(../assets/img/BG.webp)", }}></div>
			</div>
		</div>
	);
};

export default AdminLogin;