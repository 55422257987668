import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, AxiosConfigs } from '../../config';
import { getInfo, getInfoData, getNotifications, setMe } from '../../redux/reducers/infoReducer';

const ProfileNotifications = () => {
	const myInfo = useSelector(getInfoData)
	const dispatch = useDispatch()
	const emailCheck = e => {
		const data = {};
		// data.id = myInfo._id;
		data.check = !e.target.checked;
		axios.post(API_BASE_URL + '/api/emailCheck', data, AxiosConfigs)
			.then(response => {
				// dispatch(setMe(response.data))
			})
			.catch(error => { })
	}

	const browserCheck = e => {
		const data = {};
		// data.id = myInfo._id;
		data.check = !e.target.checked;
		axios.post(API_BASE_URL + '/api/browserCheck', data, AxiosConfigs)
			.then(response => {
				// dispatch(setMe(response.data))
			})
			.catch(error => { })
	}
	return (
		<div tabname="Notification" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
			<div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
				<h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Notifications settings</h5>
			</div>
			<div className="card-body px-0 px-md-4 py-0">
				<div className="px-3">
					<div className="media border-bottom border-gray-200 py-2 py-md-4">
						<div className="media-body my-2 w-100">
							<div className="row align-items-center">
								<div className="col">
									<span className="fs-16">Email notification</span>
									<span className="d-block small text-gray-600 mt-1">Send notifications to my mail</span>
								</div>
								<div className="col-auto">
									<div className="form-check form-switch mb-0">
										{myInfo.email_notification && myInfo.email_notification == '1'
											?
											<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onMouseDown={emailCheck} defaultChecked />
											:
											<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onMouseDown={emailCheck} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="media border-bottom border-gray-200 py-2 py-md-4">
						<div className="media-body my-2 w-100">
							<div className="row align-items-center">
								<div className="col">
									<span className="fs-16">Browser notification</span>
									<span className="d-block small text-gray-600 mt-1">When someone fills in your form, you get a notification</span>
								</div>
								<div className="col-auto">
									<div className="form-check form-switch mb-0">
										{myInfo.browser_notification && myInfo.browser_notification == '1'
											?
											<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" onMouseDown={browserCheck} defaultChecked />
											:
											<input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked2" onMouseDown={browserCheck} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileNotifications;