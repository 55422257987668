import React, {useCallback, useEffect, useRef, useState} from "react";
import {APP_BASE_URL} from "../../config";
import {Link, useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from 'react-router';
import {getFormChangeNameData, getFormPreview, getMyFormsData} from "../../redux/reducers/formReducer";
import {FormEdit} from "../../context";
import MonoStackedBar from "mono-stacked-bar"
import "mono-stacked-bar/dist/index.css"
import {getIsEdited, setIsEdited} from "../../redux/reducers/dataReducer";
import Swal from "sweetalert2";
import {getFormCountInfo, getInfoData, setFormCountInfo} from "../../redux/reducers/infoReducer";

const Navbar = (props) => {
    const [pageName, setPageName] = useState('')
    const history = useHistory()
    const FeaturesRef = useRef(null);
    const AccountRef = useRef(null);
    const AccountRefShow = useRef(null);
    const dispatch = useDispatch()
    const isEditedForm = useSelector(getIsEdited)
    const params = useParams();
    const [link, setLink] = useState('');
    const myForms = useSelector(getMyFormsData)
    const changeName = useSelector(getFormChangeNameData)
    const [recentForms, setRecentForms] = useState([]);
    const myInfo = useSelector(getInfoData);
    const myFormCountInfo = useSelector(getFormCountInfo);

    function hasNewForm(name) {
        return /\b(form_\d+)\b/i.test(name);
    }

    useEffect(() => {
        setLink(params.id || window.location.pathname)
    }, [params.id])

    useEffect(() => {
        if (Object.keys(myInfo).length > 0) {
            if (myInfo.blocked && myInfo.blocked == 1) {
                var copyMyForms = JSON.parse(JSON.stringify(myForms))
                var filteredForms = copyMyForms.filter(e => e.isOneTime == 1)
                setRecentForms(filteredForms)
            } else {
                setRecentForms(myForms)
            }
        } else {
            setRecentForms(myForms)
        }
    }, [myForms])

    useEffect(() => {
        dispatch(setFormCountInfo(myInfo.formCount))
    }, [myInfo])


    useEffect(() => {
        setRecentForms([
            ...recentForms.map((form) =>
                form._id === changeName.id ?
                    {...form, title: changeName.title} : {...form}
            )
        ])
    }, [changeName])
    const changeRoute = (link, target) => {
        if (isEditedForm) {
            Swal.fire({
                title: 'Leave without saving?',
                text: 'Are you sure you want to leave this page without saving? Any unsaved information will be deleted.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Leave page',
                cancelButtonText: 'Cancel',
                customClass: {
                    cancelButton: 'btn-outline-dark-default'
                }
            }).then((result, e) => {
                if (result.isConfirmed) {
                    // setFormData([])
                    dispatch(setIsEdited(false))
                    // if (target) {
                    // 	window.open(link,'_blank');
                    // } else {
                    // setPageName('')
                    history.push(link)
                    // window.location.reload()
                    // }
                }
            })
        } else {
            // if (target) {
            // 	window.open(link,'_blank');
            // } else {
            // setPageName('')
            history.push(link)
            // window.location.reload()
            // }
        }
    }
    // useEffect(() => {
    // 	var pageNowName = window.location.pathname
    // 	var splittedPagNow = pageNowName.split('/')
    // 	if (splittedPagNow[1] == 'form' && splittedPagNow.length == 3) { setPageName('/form_' + splittedPagNow[splittedPagNow.length - 1]) }
    // 	else { setPageName(pageNowName); }
    // 	$(`.nav-item a[href="${pageNowName}"]`).addClass('active')
    // 	$(`.nav-item a[href="${pageNowName}"]`).closest('div').addClass('show')
    // 	$(`.nav-item a[href="${pageNowName}"]`).closest('ul').closest('li').addClass('active')
    // }, [])

    return (
        <nav id="leftNavbar" className="navbar navbar-vertical navbar-expand-lg navbar-light">
            <button type="button" onClick={() => changeRoute('/')}
                    className="navbar-brand mx-auto d-none d-lg-block my-0 my-lg-4">
                <img src="/logo-final.png" alt="FormBuilder" className="logoImage"/>
                <img src="/svg/brand/logo-white.svg" alt="FormBuilder" className="white-logo2"/>
                <img src="/logo_one.jpg" className="muze-icon logo-one" alt="FormBuilder"/>
            </button>
            <div className="navbar-collapse">
                <ul className="navbar-nav mb-2" id="accordionExample" data-simplebar>
                    {/* <li className="nav-item">
						<a className="nav-link collapsed" ref={FeaturesRef} href="#sidebarPages" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages">
							<svg data-name="Icons/Tabler/Bolt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
								<rect data-name="Icons/Tabler/Page background" width="16" height="16" fill="none" />
								<path d="M1.975,14A1.977,1.977,0,0,1,0,12.026V1.975A1.977,1.977,0,0,1,1.975,0h5.04a.535.535,0,0,1,.249.069l.007,0h0a.534.534,0,0,1,.109.084l3.574,3.575a.536.536,0,0,1,.163.289h0l0,.013h0l0,.013v0l0,.011v.053s0,.009,0,.014v7.9A1.977,1.977,0,0,1,9.154,14Zm-.9-12.026V12.026a.9.9,0,0,0,.9.9H9.154a.9.9,0,0,0,.9-.9V4.667H7.718a1.255,1.255,0,0,1-1.248-1.12L6.461,3.41V1.077H1.975A.9.9,0,0,0,1.077,1.975ZM7.538,3.41a.179.179,0,0,0,.122.17l.057.01H9.29L7.538,1.838Z" transform="translate(2 1)" fill="#1e1e1e" />
							</svg> &nbsp;<span className="mx-2 navbar-span">Features</span>
						</a>
						<div className="collapse collapse-box" id="sidebarPages" data-bs-parent="#accordionExample">
							<ul className="nav nav-sm flex-column" id="submenu">
								<li className="nav-item">
									<Link to='' className="nav-link"><span>Online Forms</span></Link>
								</li>
								<li className="nav-item">
									<Link to='' className="nav-link"><span>Interview Forms</span></Link>
								</li>
								<li className="nav-item">
									<Link to='' className="nav-link"><span>Surveys</span></Link>
								</li>
								<li className="nav-item">
									<Link to='' className="nav-link"><span>Polls</span></Link>
								</li>
							</ul>
						</div>
					</li> */}
                    <li className="nav-item active w-100">
                        {/* <a className="nav-link collapsed" ref={AccountRef} href="#sidebarAuthentication" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAuthentication">
							<svg data-name="Icons/Tabler/Paperclip" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
								<rect data-name="Icons/Tabler/Plug background" width="16" height="16" fill="none" />
								<path d="M6.7,16a2.378,2.378,0,0,1-2.373-2.234l0-.145V12.541H3.244A3.241,3.241,0,0,1,0,9.47L0,9.3V4.109a.649.649,0,0,1,.561-.643L.649,3.46H1.73V.649A.649.649,0,0,1,3.021.561l.005.088V3.46H6.919V.649A.649.649,0,0,1,8.211.561l.005.088V3.46H9.3a.649.649,0,0,1,.643.561l.006.088V9.3a3.241,3.241,0,0,1-3.071,3.239l-.173,0H5.621v1.081A1.081,1.081,0,0,0,6.593,14.7l.11.005H9.3a.649.649,0,0,1,.088,1.292L9.3,16Zm0-4.757A1.951,1.951,0,0,0,8.644,9.431l0-.134V4.757H1.3V9.3A1.951,1.951,0,0,0,3.11,11.239l.133,0H6.7Z" transform="translate(3)" fill="#1e1e1e" />
							</svg> &nbsp;<span className="mx-2 navbar-span">Account</span>
						</a> */}
                        <div className="nav-item collapsed" ref={AccountRefShow} id="sidebarAuthentication"
                             data-bs-parent="#accordionExample">
                            {
                                hasNewForm(pageName)
                                    ?
                                    <ul className="nav nav-sm flex-column" id="submenu2">
                                        <li className="nav-item">
                                            <a href="/profile" className="nav-link" target="_blank"><i
                                                className="fas fa-user-circle"></i><span>Profile</span></a>
                                        </li>
                                        <li className="nav-item active">
                                            <a href="/forms" className="nav-link" target="_blank"><img
                                                className="imgInProfileDoc" src="/svg/icons/page.svg"
                                                alt="img"/><span>Forms</span></a>
                                        </li>
                                        {/* <li className="nav-item">
											<a href="/settings" className="nav-link" target="_blank"><span>Settings</span></a>
										</li> */}
                                    </ul>
                                    :
                                    <ul className="nav nav-sm flex-column" id="submenu2">
                                        <li className="nav-item">
                                            <button type="button" onClick={() => changeRoute('/forms')}
                                                    className={link == '/forms' || link == '/' ? "nav-link link-active" : "nav-link"}>
                                                <svg width="16" height="16" viewBox="0 0 44 46" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg " strokeWidth="4"
                                                     stroke="lightgrey">
                                                    <path d="M24 35H11" stroke="black" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <path
                                                        d="M6.25 1H27.25H39C41.2091 1 43 2.79086 43 5V7.32497V7.73747V14.2V41C43 43.2091 41.2091 45 39 45H14.875H6.25C4.85761 45 3.52226 44.5364 2.53769 43.7113C1.55312 42.8861 1 41.767 1 40.6V5.4C1 4.23305 1.55312 3.11389 2.53769 2.28873C3.52226 1.46357 4.85761 1 6.25 1Z"
                                                        stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M32.3333 23H11" stroke="black" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <path d="M33 12H22H11" stroke="black" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                </svg>
                                                &nbsp;
                                                {/* <img src="/svg/icons/forms1253.svg" width={16} height={16} alt="img" />&nbsp; */}
                                                {/* <img className="imgInProfileDoc" src="/svg/icons/page.svg" alt="img" /> */}
                                                <span>Forms</span>
                                            </button>
                                        </li>

                                        <li className="nav-item">
                                            <button type="button" onClick={() => changeRoute('/profile')}
                                                    className={link == '/profile' ? "nav-link link-active" : "nav-link"}>
                                                {/* <img src="/svg/icons/profile11.svg" width={16} height={16} alt="img" />&nbsp; */}
                                                <svg width="16" height="16" viewBox="0 -1 44 46" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg" strokeWidth="4"
                                                     stroke="lightgrey">
                                                    <path
                                                        d="M39 45V40.3333C39 37.858 37.9991 35.484 36.2175 33.7337C34.4359 31.9833 32.0196 31 29.5 31H10.5C7.98044 31 5.56408 31.9833 3.78249 33.7337C2.00089 35.484 1 37.858 1 40.3333V45"
                                                        stroke="lightgrey" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M20.5 20C25.7467 20 30 15.7467 30 10.5C30 5.25329 25.7467 1 20.5 1C15.2533 1 11 5.25329 11 10.5C11 15.7467 15.2533 20 20.5 20Z"
                                                        stroke="lightgrey" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                </svg>
                                                &nbsp;
                                                {/* <i className="fas fa-user-circle"></i> */}
                                                {/* <img className="imgInProfileDoc" src="/svg/icons/page.svg" alt="img" /> */}
                                                <span>Profile</span></button>
                                        </li>
                                        {/* <li className="nav-item">
											<Link to="/settings" className="nav-link"><span>Settings</span></Link>
										</li> */}

                                        <li className="nav-item help-support-icon">
                                            <button type="button" onClick={() => changeRoute('/support')}
                                                    className={link == '/support' ? "nav-link link-active" : "nav-link"}
                                                    style={{marginLeft: -1 + 'px'}}>
                                                {/*<img src="/svg/icons/help-circle.svg" width="19" alt=""/>&nbsp;*/}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="18.5"
                                                     viewBox="0 0 24 24" fill="none" stroke="lightgrey"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                    // className={link == '/support' ? "feather feather-help-circle filter-invert" : "feather feather-help-circle"}
                                                     className="feather feather-help-circle">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
                                                          stroke="lightgrey"></path>
                                                    <circle cx="12" cy="17" r="0.5"></circle>
                                                </svg>
                                                &nbsp;
                                                <span>Support</span>
                                            </button>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <button type="button" onClick={() => changeRoute('/members')}*/}
                                        {/*            className={link == '/members' ? "nav-link link-active" : "nav-link"}*/}
                                        {/*            style={{marginLeft: -1 + 'px'}}>*/}
                                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="18.5"*/}
                                        {/*             viewBox="0 0 24 24" fill="none" stroke="lightgrey"*/}
                                        {/*             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"*/}
                                        {/*             className="feather feather-users">*/}
                                        {/*            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>*/}
                                        {/*            <circle cx="9" cy="7" r="4"></circle>*/}
                                        {/*            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>*/}
                                        {/*            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>*/}
                                        {/*        </svg>*/}
                                        {/*        <span style={{marginLeft: 4 + 'px'}}>Members</span>*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}

                                        <li className="nav-item help-support-icon">
                                            <button type="button" onClick={() => changeRoute('/suggestions')}
                                                    className={link == '/suggestions' ? "nav-link link-active" : "nav-link"}
                                                    style={{marginLeft: -1 + 'px'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="18.5"
                                                     viewBox="0 0 43 50"  stroke="lightgrey" strokeWidth="2"  strokeLinecap="round" strokeLinejoin="round"   className="feather">
                                                    <path d="M31 3.91667L29.2516 5.83334M15.8349 3.91667L17.7516 5.83334M23.5016 4.5V2.16666M17.7516 36.8334H29.2516M19.6682 42.5834H27.3349M23.5016 8.08334C15.8349 8.08334 11.9096 11.8208 12.0016 17.6667C12.0457 20.5168 12.9599 22.4583 14.8766 24.375C16.7932 26.2917 17.7516 27.25 17.7516 31.0833H29.2516C29.2516 27.25 30.2099 26.2917 32.1266 24.375C34.0432 22.4583 34.9575 20.5168 35.0016 17.6667C35.0936 11.8208 31.1682 8.08334 23.5016 8.08334Z" />
                                                </svg>

                                                &nbsp;
                                                <span>Suggestions</span>
                                            </button>
                                        </li>
                                        {
                                            recentForms && recentForms.length > 0
                                                ?
                                                <div className="collapse collapse-box show mt-2 recentForms-navbar"
                                                     id="sidebarPages" data-bs-parent="#accordionExample">
                                                    <ul className="nav nav-sm flex-column" id="submenu">
                                                        <li className="nav-item">
                                                            <a className="nav-link collapsed" href="#RecentForms"
                                                               data-bs-toggle="collapse" role="button"
                                                               aria-expanded="true"
                                                               aria-controls="RecentForms">Recent Forms</a>
                                                            <div className="collapse collapse-box show" id="RecentForms"
                                                                 data-bs-parent="#submenu">
                                                                <ul className="nav nav-sm flex-column">
                                                                    <li className="nav-item">
                                                                        {
                                                                            recentForms ? recentForms.slice(0, 3).map(function (lastForm, i) {
                                                                                return <button type="button"
                                                                                               onClick={() => {
                                                                                                   return changeRoute("/form/" + lastForm._id, true)
                                                                                               }} key={i}
                                                                                               target="_blank"
                                                                                               className={link == lastForm._id ? "nav-link link-active" : "nav-link"}
                                                                                               style={{padding: '2px 0 2px 1.5rem'}}>
                                                                                    <div
                                                                                        className='mx-2 recent-forms-width-styles'>{lastForm.title}</div>
                                                                                </button>
                                                                            }) : null
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                : null
                                        }
                                        {myInfo?.plan === 'Standart' || myInfo?.plan === 'Free' ?
                                            <li className="nav-item">
                                                <div className="forms-mono-chart-line">
                                                    <MonoStackedBar
                                                        width={144}
                                                        height={5}
                                                        displayLabels={false}
                                                        data={[
                                                            {
                                                                value: myInfo.maxCount - myFormCountInfo,
                                                                color: "#16CF96"
                                                            },
                                                            {value: myFormCountInfo, color: "#fd4949"}
                                                        ]}
                                                    />
                                                </div>
                                                <div className="nav-link pt-0">
                                                    {
                                                        myInfo.plan === 'Free' ? <span>Form Created</span> :
                                                            <span>Forms Created</span>

                                                    }

                                                    {
                                                        myInfo?.plan === 'Standart' || myInfo?.plan === 'Free'
                                                            ? <span
                                                                className="myForms-count">{myInfo.maxCount - myFormCountInfo}{+myInfo.haveOneTimeForm == 1 ? '+1' : ''}/<span>{myInfo.maxCount}</span></span>
                                                            : null
                                                    }
                                                </div>
                                            </li> : null
                                        }

                                    </ul>
                            }
                        </div>
                    </li>
                    {/* <li className="nav-item">
						<a className="nav-link" href="#" target="_blank">
							<svg data-name="Icons/Tabler/Paperclip" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
								<rect data-name="Icons/Tabler/Paperclip background" width="16" height="16" fill="none" />
								<path d="M6.766,1.178A4.018,4.018,0,0,1,12.591,6.71l-.147.155-5.1,5.11A2.352,2.352,0,0,1,3.9,8.77l.114-.123,5.1-5.11a.685.685,0,0,1,1.035.893l-.066.077-5.1,5.11a.981.981,0,0,0,1.3,1.465l.086-.076,5.1-5.11A2.648,2.648,0,0,0,7.861,2.028l-.127.119-5.1,5.11a4.315,4.315,0,0,0,5.941,6.255l.156-.149,5.1-5.11a.685.685,0,0,1,1.035.893l-.066.077-5.1,5.11A5.685,5.685,0,0,1,1.5,6.457l.162-.169Z" transform="translate(1)" fill="#1e1e1e" />
							</svg> &nbsp;<span className="ms-2 navbar-span">Docs</span> <span className="docs-version">v1.0</span>
						</a>
					</li> */}
                </ul>
                {/* <div className="mt-3 mt-md-auto mb-3 signout d-grid">
					<a href="#" className="btn btn-dark btn-lg customize-btn"><img src="/svg/icons/dark-mode@24.svg" alt="Customize" /><span className="ps-2">Customize</span></a>
				</div> */}
                {/* <div className="navbar-vertical-footer border-top border-gray-50">
					<ul className="navbar-vertical-footer-list">
						<li>
							<a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="18.047" height="18.047" viewBox="0 0 18.047 18.047">
								<g data-name="Icons/Tabler/Paperclip Copy" transform="translate(0.047 0.047)">
									<rect data-name="Icons/Tabler/Adjustments background" width="18" height="18" fill="none" />
									<path d="M14.4,17.3l0-.074V6.579a2.829,2.829,0,0,1,0-5.443V.772A.772.772,0,0,1,15.94.7l0,.074v.364a2.829,2.829,0,0,1,0,5.443v10.65A.771.771,0,0,1,14.4,17.3ZM13.885,3.858a1.285,1.285,0,1,0,1.286-1.286A1.287,1.287,0,0,0,13.885,3.858ZM8.232,17.3l0-.074V15.836a2.829,2.829,0,0,1,0-5.443V.772A.771.771,0,0,1,9.768.7l0,.074v9.621a2.829,2.829,0,0,1,0,5.443v1.393a.772.772,0,0,1-1.54.074Zm-.517-4.188A1.285,1.285,0,1,0,9,11.829,1.287,1.287,0,0,0,7.714,13.115ZM2.06,17.3l0-.074V9.664a2.829,2.829,0,0,1,0-5.443V.772A.771.771,0,0,1,3.6.7l0,.074V4.221a2.829,2.829,0,0,1,0,5.443v7.565a.772.772,0,0,1-1.54.074ZM1.543,6.943A1.285,1.285,0,1,0,2.829,5.657,1.287,1.287,0,0,0,1.543,6.943Z" transform="translate(-0.047 -0.047)" fill="#6c757d" />
								</g>
							</svg></a>
						</li>
						<li>
							<a href="#"><svg data-name="Icons/Tabler/Paperclip Copy 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
								<rect data-name="Icons/Tabler/Bolt background" width="18" height="18" fill="none" />
								<path d="M6.377,18a.7.7,0,0,1-.709-.6l-.006-.1V11.537H.709A.7.7,0,0,1,.1,11.193a.673.673,0,0,1-.014-.672l.054-.083L7.693.274,7.755.2,7.828.141,7.913.087,7.981.055l.087-.03L8.16.006,8.256,0h.037l.059.005.04.007.052.011.045.014.043.016.052.023.089.055.016.011A.765.765,0,0,1,8.756.2L8.82.273l.055.083.033.067.03.085L8.957.6l.007.094V6.461h4.952a.7.7,0,0,1,.613.345.672.672,0,0,1,.013.672l-.053.082L6.942,17.714A.691.691,0,0,1,6.377,18ZM7.548,2.821,2.1,10.153H6.369a.7.7,0,0,1,.7.6l.006.093v4.331l5.449-7.331H8.256a.7.7,0,0,1-.7-.6l-.007-.094Z" transform="translate(2.25 0)" fill="#6c757d" />
							</svg></a>
						</li>
					</ul>
				</div> */}
            </div>
        </nav>
    )
}

export default Navbar;