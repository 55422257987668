import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { AuthContext } from "./context";
// import AdminNavbar from "./UI/Navbar/AdminNavbar";

function Admin() {
	const [isAuth, setIsAuth] = useState(false)
	const [isAuthAdmin, setIsAuthAdmin] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	useEffect(() => {
		if (localStorage.getItem('authAdmin')) {
			setIsAuthAdmin(true)
		}
		setIsLoading(false)
	}, [])
	return (
		<div className='body-page'>
			<AuthContext.Provider value={{
				isAuth,
				setIsAuth,
				isAuthAdmin,
				setIsAuthAdmin,
				isLoading
			}}>
				<BrowserRouter>
					{/* <AdminNavbar /> */}
					<AppRouter />
				</BrowserRouter>
			</AuthContext.Provider>
		</div>
	)
}

export default Admin;
