import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Forgot from "../pages/Forgot";
import Verify from "../pages/Verify";
import Form from "../pages/Form/Form";
import Home from "../pages/Home";
import Profile from "../pages/Profile/Profile";
import Settings from "../pages/Profile/Settings";
import { APP_BASE_URL } from "../config";
import Reset from "../pages/Reset";
import AdminLogin from "../Admin/pages/AdminLogin";
import Preview from "../pages/Form/Preview";
import Response from "../pages/Response/Response";
import AdminHome from "../Admin/pages/AdminHome";
import AdminUser from "../Admin/pages/AdminUser";
import AdminForms from "../Admin/pages/AdminForms";
import Statistics from "../pages/Response/Statistics";
import UserForm from "../pages/Form/UserForm";
import UserResponse from "../pages/Response/UserResponse";
import Success from "../pages/Success";
import PreviewSuccess from "../pages/Success";
import CheckUser from "../pages/CheckUser";
import TestActions from "../pages/TestActions";
import PaymentsSuccess from "../pages/PaymentSuccess";
import PaymentsRejected from "../pages/PaymentRejected";
import Block from "../pages/Block";
import Page404 from "../pages/Page404";
import SignupMembers from "../pages/SignupMembers";

export const adminRoutes = [
	{ path: '/signin', component: Signin, exact: true },
	{ path: '/signup', component: Signup, exact: true },
	{ path: '/signup/:type', component: Signup, exact: true },
	{ path: '/forgot', component: Forgot, exact: true },
	{ path: '/response/:id', component: UserResponse, exact: true },
	{ path: '/evi-admin', component: AdminHome, exact: true },
	{ path: '/evi-admin/users', component: AdminHome, exact: true },
	{ path: '/evi-admin/forms', component: AdminHome, exact: true },
	{ path: '/evi-admin/responses', component: AdminHome, exact: true },
	{ path: '/evi-admin/statistics', component: AdminHome, exact: true },
	{ path: '/evi-admin/messages', component: AdminHome, exact: true },
	{ path: '/evi-admin/customers', component: AdminHome, exact: true },
	{ path: '/evi-admin/reports', component: AdminHome, exact: true },
	{ path: '/evi-admin/payments', component: AdminHome, exact: true },
	{ path: '/evi-admin/error-logs', component: AdminHome, exact: true },
	{ path: '/checkUser', component: CheckUser, exact: true },
	{ path: '/paymentSuccess', component: PaymentsSuccess, exact: true },
	{ path: '/paymentRejected', component: PaymentsRejected, exact: true },
	{ path: '/TestActions', component: TestActions, exact: true },
	{ path: '/404', component: Page404, exact: true },
	{ path: '/evi-admin/suggestions', component: AdminHome, exact: true },
]

export const privateRoutes = [
	{ path: '/', component: Home, exact: true },
	{ path: '/form/:id', component: Home, exact: true },
	{ path: '/profile', component: Home, exact: true },
	{ path: '/forms', component: Home, exact: true },
	{ path: '/members', component: Home, exact: true },
	{ path: '/support', component: Home, exact: true },
	{ path: '/preview/:id', component: Preview },
	{ path: '/responses/:id', component: Home, exact: true },
	{ path: '/responsestrashed/:id', component: Home, exact: true },
	{ path: '/response/:id', component: UserResponse, exact: true },
	{ path: '/statistics/:id', component: Home, exact: true },
	{ path: '/checkUser', component: CheckUser, exact: true },
	{ path: '/paymentSuccess', component: PaymentsSuccess, exact: true },
	{ path: '/paymentRejected', component: PaymentsRejected, exact: true },
	{ path: '/block', component: Block, exact: true },
	{ path: '/evi-admin', component: AdminLogin, exact: true },
	// { path: '/404', component: Page404, exact: true },
	{ path: '/suggestions', component: Home, exact: true },
]

export const publicRoutes = [
	{ path: '/', component: Signin, exact: true },
	{ path: '/signin', component: Signin, exact: true },
	{ path: '/paymentSuccess', component: PaymentsSuccess, exact: true },
	{ path: '/paymentRejected', component: PaymentsRejected, exact: true },
	{ path: '/response/:id', component: UserResponse, exact: true },
	{ path: '/signin/:id', component: Signin, exact: true },
	{ path: '/signup', component: Signup, exact: true },
	{ path: '/signupMembers/:id', component: SignupMembers, exact: true },
	{ path: '/signup/:type', component: Signup, exact: true },
	{ path: '/forgot', component: Forgot, exact: true },
	{ path: '/reset/:id', component: Reset, exact: true },
	{ path: '/evi-admin', component: AdminLogin, exact: true },
	{ path: '/checkUser', component: CheckUser, exact: true },
	{ path: '/block', component: Block, exact: true },
	{ path: '/404', component: Page404, exact: true },
]

