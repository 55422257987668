import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { APP_BASE_URL, API_BASE_URL, AxiosConfigs } from '../../config';
import { useParams } from 'react-router';
import { Pagination } from 'react-laravel-paginex'
import { MDBDataTableV5 } from 'mdbreact';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import { getMyFormsData } from '../../redux/reducers/formReducer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoData, getNotifications } from '../../redux/reducers/infoReducer';
import { CSVLink } from 'react-csv'
import JSZip from 'jszip'
import { saveAs } from 'file-saver';

import Chart from "react-apexcharts";
import {
	getResponses,
	getTrashedResponses,
	getResponsesData,
	getResponsesDataLoading,
	getResponsesTrashedData,
	getResponsesTrashedDataLoading,
	getResponsesFilters,
	getResponsesLabels,
	getResponsesMonth,
	getResponsesToday,
	getResponsesYear,
	setResponses,
	setTrashedResponses,
	getResponsesFavorites
} from '../../redux/reducers/responseReducer';
import ResponseMonth from './ResponseMonth';
import ResponseToday from './ResponseToday';
import AllResponses from './AllResponses';
import Loader from '../../UI/Loader/Loader';
import ResponseYear from './ResponseYear';
import ResponseWeek from './ResponseWeek';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { makeCsv } from '../../help';


const Response = (props) => {
	document.title = `FormBuilder - Responses`;
	const myInfo = useSelector(getInfoData)
	const [allResponses, setAllResponses] = useState({})
	const [allFavorites, setAllFavorites] = useState({})
	const [editedMessage, setEditedMessage] = useState('')
	const favorites = useSelector(getResponsesFavorites)
	const [allTrashedResponses, setAllTrashedResponses] = useState({})
	const responses = useSelector(getResponsesData)
	const trashedResponses = useSelector(getResponsesTrashedData)
	const responsesLoading = useSelector(getResponsesDataLoading)
	const responsesTrashedLoading = useSelector(getResponsesTrashedDataLoading)
	const responsesAll = useSelector(getResponsesData)
	const labels = useSelector(getResponsesLabels)
	const filters = useSelector(getResponsesFilters)
	const [tabname, setTabname] = useState('All')
	const formList = useSelector(getMyFormsData);
	const [daysFilter, setDaysFilter] = useState('All')
	const [radioChecks, setRadioChecks] = useState({})
	const datePickerShow = useRef();
	const [visibleDatePicker, setVisibleDatePicker] = useState(false);
	const [repetitiveSearchText, setRepetitiveSearchText] = useState(true)
	let loading = useSelector(getResponsesDataLoading);
	const [searchTextValue, setSearchTextValue] = useState('')
	const [pathnameTab, setPathnameTab] = useState(window.location.pathname);
	const [datePickerShowInline, setDatePickerShowInline] = useState(false)
	const [showSucces, setShowSucces] = useState(false)
	const [showError, setShowError] = useState(false)
	const pdfIframe = useRef()
	let pathnameSearchResponse = pathnameTab.search('responses');
	let pathnameSearchStatistics = pathnameTab.search('statistics');
	var countLabels = 0
	var countValues = 0
	var countLabelsTrashed = 0
	var countValuesTrashed = 0
	var countValuesFavourite = 0
	var countLabelsFavourite = 0

	function showMessage(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			setTimeout(() => {
				if (!messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessagePdf(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error', 'form-changes-success')
			messageEdit.current.classList.add('active')
			// setTimeout(() => {
			// 	if (!messageEdit.current != null) {
			// 		messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
			// 	}
			// }, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setShowSucces(true)
			setShowError(false)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setShowSucces(false)
			setShowError(true)
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	const messageEdit = useRef(null)
	document.documentElement.addEventListener("click", function (e) {
		try {
			if (!e.target.closest('.date-picker-absolute') && !e.target.closest('.responses-filter-ul')) {
				// console.log(e.target);
				if (isOpenDatepicker) {
					setIsOpenDatepicker(false)
				}
			}
		} catch (error) { throw error }
	})
	const [datatable, setDatatable] = useState({
		columns: [], rows: []
	});
	const [iframeSrc, setIframeSrc] = useState('')
	const dispatch = useDispatch()
	const [responsesFilters, setResponsesFilters] = useState({})
	const params = useParams();
	const form_id = params.id;
	const formTitle = formList.filter(form => form._id === form_id);
	let formTitleReady;
	if (formTitle[0]) {
		formTitleReady = formTitle[0]['title'];
	}

	useEffect(() => {
		let data = {};
		data.id = form_id;
		data.page = 1;
		// dispatch(getResponses(data))
		dispatch(getTrashedResponses(data))
		// dispatch(getResponsesToday(data))
		// dispatch(getResponsesMonth(data))
		// dispatch(getResponsesYear(data))
	}, [])
	useEffect(() => {
		setAllFavorites(favorites)
	}, [favorites])
	useEffect(() => {
		if ((Object).keys(myInfo).length) {
			const data = {};
			data.id = form_id;
			axios.post(API_BASE_URL + '/api/setResponsesViewed', data)
				.then(response => {
					const info = {};
					info.id = myInfo._id;
					async function getNots() {
						await dispatch(getNotifications(info))
					}
					getNots()
				})
				.catch(error => { })

		}
	}, [myInfo])
	useEffect(() => {
		setAllResponses(responses)
	}, [responses])
	useEffect(() => {
		setAllTrashedResponses(trashedResponses)
	}, [trashedResponses])
	const getData = (responses) => {
		let data = { formId: {} };
		data.formId.id = form_id;
		data.formId.page = 2;
		data.formId.tabname = tabname
		if (tabname == 'All' ? allResponses.current_page !== responses.page
			: tabname == 'Favorite' ? allFavorites.current_page !== responses.page
				: allTrashedResponses.current_page !== responses.page
		) {
			axios.post(API_BASE_URL + '/api/getResponses?page=' + responses.page, data).then(response => {
				if (tabname == 'Trash') {
					dispatch(setTrashedResponses(response.data.trashedAll))
					// setTrashedResponses(response.data.trashedAll)
				}
				else if (tabname == 'Favorite') {
					setAllFavorites(response.data.favorites)
					setAllResponses(response.data.responses);
				}
				else {
					setAllResponses(response.data.responses);
				}
			});
		}
	}

	const responsesDaysFilter = (e, filter) => {
		e.preventDefault()
		// const filter = e.currentTarget.getAttribute('days')
		let data = {};
		setDaysFilter(e.currentTarget.innerText)
		data.id = form_id;
		data.filtersAlso = filters;
		data.filter = filter;
		data.filters = responsesFilters ? responsesFilters : [];
		axios.post(API_BASE_URL + '/api/getResponsesDaysFilter', data)
			.then(response => {
				// setAllResponses(response.data)
				dispatch(setResponses(response.data))
				// dispatch(getResponses(response.data))
			})
			.catch(error => { })
	}


	// Datepicker
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;

	useEffect(() => {
		if (dateRange[0] && dateRange[1]) {
			handleClose()
		}
		if (!dateRange[0] && !dateRange[1]) {
			let data = {};
			data.id = form_id;
			data.page = 1;
			dispatch(getResponses(data))
		}
	}, [dateRange])

	// const modifiers = { start: from, end: to };

	// const showFromMonth = e => {
	// 	var { from, to } = dateState;
	// 	if (!from) {
	// 		return;
	// 	}
	// 	if (moment(to).diff(moment(from), 'months') < 2) {
	// 		to.getDayPicker().showMonth(from);
	// 	}
	// }

	// const handleFromChange = from => {
	// 	setDateState({ from });
	// }

	const handleClose = () => {
		// console.log(dateState);
		let data = {};
		data.id = form_id;
		data.filter = 'custom';
		data.filtersAlso = filters;
		data.dateTimes = {
			from: moment(dateRange[0]).toLocaleString(),
			to: moment(dateRange[1]).toLocaleString()
		};
		data.filters = responsesFilters ? responsesFilters : [];
		axios.post(API_BASE_URL + '/api/getResponsesDaysFilter', data)
			.then(response => {
				// setAllResponses(response.data)
				dispatch(setResponses(response.data))
				setIsOpenDatepicker(false)
			})
			.catch(error => { })
		setShow(false)
	};
	// const handleToChange = to => {
	// 	var toDate = to
	// 	setDateState({ ...dateState, to: toDate });
	// }


	// Datepicker

	const [show, setShow] = useState(false);
	const resetFormFilters = useRef()

	const filterResponses = (filtersResponse) => {
		// e.preventDefault()
		try {
			let data = {};
			data.id = form_id;
			data.filters = filtersResponse;
			data.filtersAlso = filters;
			axios.post(API_BASE_URL + '/api/getResponsesFiltered', data)
				.then(response => {
					dispatch(setResponses(response.data))
					// setDateState({
					// 	from: undefined,
					// 	to: undefined,
					// })
				})
				.catch(error => { })
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const handleShow = () => {
		datePickerShow.current.click()
		setVisibleDatePicker(!visibleDatePicker)
		// setShow(true)
	};

	const [isOpenDatepicker, setIsOpenDatepicker] = useState(false)

	const changeIsOpenDatepicker = (e) => {
		e.preventDefault();
		var innerText = e.currentTarget.innerText
		setIsOpenDatepicker(true)
		setTimeout(() => {
			document.querySelector('.responses-filter-button').classList.remove('show')
			document.querySelector('.responses-filter-ul').classList.remove('show')
			document.querySelector('.responses-filter-a').classList.remove('show')
			document.querySelector('.responses-filter-a').setAttribute('aria-expanded', false)
			setDaysFilter(innerText)
		}, 100);
	}

	const addFilterCheckbox = e => {
		// e.preventDefault()
		try {
			const filtersCopy = JSON.parse(JSON.stringify(responsesFilters));
			setResponsesFilters([])
			var key = e.target.getAttribute('forkey')
			var option = e.target.getAttribute('foroption')
			if (e.target.checked) {
				if (filtersCopy.hasOwnProperty(key)) { filtersCopy[key].push(option) }
				else { filtersCopy[key] = [option] }
			} else {
				var index = filtersCopy[key].indexOf(option)
				if (index != -1) {
					filtersCopy[key].splice(index, 1);
					if (filtersCopy[key].length == 0) {
						delete filtersCopy[key];
					}
				}
			}
			setResponsesFilters(filtersCopy)
			filterResponses(filtersCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const addFilterRadio = (e, uniqueId, index) => {
		try {
			const filtersCopy = JSON.parse(JSON.stringify(responsesFilters));
			setResponsesFilters([])
			var key = e.target.getAttribute('forkey')
			var option = e.target.getAttribute('foroption')
			if (e.target.checked) {
				setRadioChecks({ ...radioChecks, [uniqueId]: index })
				var group = document.querySelector(`[name="${e.target.getAttribute("name")}"]`);
				group.removeAttribute("checked");
				e.target.setAttribute("checked", true);
				filtersCopy[key] = [option]
			} else {
				setRadioChecks({ ...radioChecks, [uniqueId]: -1 })
				e.target.setAttribute("checked", false);
				var index = filtersCopy[key].indexOf(option)
				if (index != -1) {
					filtersCopy[key].splice(index, 1);
					if (filtersCopy[key].length == 0) {
						delete filtersCopy[key];
					}
				}
			}
			setResponsesFilters(filtersCopy)
			filterResponses(filtersCopy)
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const resetFilter = e => {
		setResponsesFilters([])
		resetFormFilters.current.reset()

	}

	const imageTypes = ['jpg', 'webp', 'jpeg', 'png', 'gif'];
	const docTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'rtf', 'html'];
	const videoTypes = ['mpg', 'flv', 'avi'];
	const musicTypes = ['mp3', 'wma'];
	const archiveTypes = ['zip'];

	const tabAll = useRef(null)
	const tabFavorite = useRef(null)
	const tabTrash = useRef(null)

	const archiveResponse = (e, responseId, pageName) => {
		e.preventDefault()
		var nowPage = allResponses.current_page;
		if (pageName === 'favorite') {
			nowPage = allFavorites.current_page;
		}
		var currentTarget = e.currentTarget;
		var data = {};
		data.responseId = responseId;
		data.formId = form_id;
		if (allResponses.data.length - 1 == 0) {
			nowPage = nowPage - 1
		}
		axios.post(API_BASE_URL + '/api/archiveResponse?page=' + nowPage, data)
			.then(response => {
				setAllFavorites(response.data.favorites)
				dispatch(setTrashedResponses(response.data.trashedAll))
				dispatch(setAllResponses(response.data.responses))

				// var nowLabels = JSON.parse(JSON.stringify(labels))
				// var nowFilters = JSON.parse(JSON.stringify(filters))
				// var readyDispatch = response.data
				// readyDispatch.filters = nowFilters
				// readyDispatch.labels = nowLabels
				// var trashedAllResponses = response.data.trashedAll;
				// var responsesCopy = JSON.parse(JSON.stringify(allResponses))
				// var responsesDataCopy = JSON.parse(JSON.stringify(allResponses.data))
				// var favoritesCopy = JSON.parse(JSON.stringify(allFavorites))
				// var favoritesCopyData = favoritesCopy.data
				// var foundIndex = responsesDataCopy.findIndex(x => x._id == responseId)
				// if (foundIndex != -1) {
				// 	favoritesCopyData = favoritesCopyData.filter(function (item) {
				// 		return item._id !== responseId
				// 	})
				// 	favoritesCopy.data = favoritesCopyData
				// 	setAllFavorites(favoritesCopy)
				// }
				// responsesDataCopy = responsesDataCopy.filter(function (item) {
				// 	return item._id !== responseId
				// })
				// responsesCopy.data = responsesDataCopy;
				// responsesCopy.total = responsesCopy.total - 1;
				// responsesCopy.to = responsesCopy.to - 1;

				// var trashedResponsesCopy = JSON.parse(JSON.stringify(allTrashedResponses))
				// var trashedResponsesDataCopy = JSON.parse(JSON.stringify(allTrashedResponses.data))
				// trashedResponsesDataCopy.unshift(response.data)
				// trashedResponsesCopy.data = trashedResponsesDataCopy;
				// trashedResponsesCopy.total = trashedResponsesCopy.total + 1;
				// trashedResponsesCopy.to = trashedResponsesCopy.to + 1;
				// const removed = responsesCopy.splice(index, 1);
				// // setMyForms([])
				// dispatch(setTrashedResponses(trashedAllResponses))
				// dispatch(setResponses(readyDispatch))
				// setAllResponses(responses.data)
				// setDateState({
				// 	from: undefined,
				// 	to: undefined,
				// })
			})
			.catch(error => { })
	}


	const deleteResponse = (e, responseId) => {
		e.preventDefault()
		var currentTarget = e.currentTarget;
		let pageNum = allTrashedResponses.current_page
		Swal.fire({
			title: 'Are you sure?',
			text: 'This response will be removed and it will not be possible to restore',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			customClass: {
				cancelButton: 'btn-outline-dark-default'
			},
			confirmButtonText: 'Delete',
			cancelButtonText: 'Close'
		}).then((result) => {
			if (result.isConfirmed) {
				var data = {};
				data.responseId = responseId;
				data.formId = form_id;
				axios.post(API_BASE_URL + '/api/deleteResponse?page=' + pageNum, data)
					.then(response => {

						var nowLabels = JSON.parse(JSON.stringify(labels))
						var nowFilters = JSON.parse(JSON.stringify(filters))
						var data = response.data
						data.filters = nowFilters
						data.labels = nowLabels
						var readyDispatch = {
							"responses": data.responses,
							"filters": data.filters,
							"labels": data.labels,
							"favorites": data.favorites
						}
						var trashedAllResponses = response.data.trashedAll;
						dispatch(setTrashedResponses(trashedAllResponses))
						if (trashedAllResponses.data.length === 0) {
							setTabname('All')
						}

						// const trashedResponsesCopy = trashedResponses.filter(item => {
						// 	return item._id != responseId;
						// });
						// dispatch(setTrashedResponses(trashedResponsesCopy))
						// setMyTrashedForms(myTrashedFormsCopy)
					})
					.catch(error => { })
				Swal.fire({
					icon: 'error',
					title: 'Response deleted',
					showConfirmButton: false,
					timer: 1500
				})
			}
		})
	}

	const deleteAllResponse = (e) => {
		e.preventDefault()
		var nowPage = trashedResponses.current_page;
		if (trashedResponses.data.length - 1 == 0) {
			nowPage = nowPage - 1
		}
		Swal.fire({
			title: 'Are you sure?',
			text: 'This response will be removed and it will not be possible to restore',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			customClass: {
				cancelButton: 'btn-outline-dark-default'
			},
			confirmButtonText: 'Delete',
			cancelButtonText: 'Close'
		}).then((result) => {
			if (result.isConfirmed) {
				var data = {};
				data.id = form_id;
				axios.post(API_BASE_URL + '/api/deleteAllResponse', data)
					.then(response => {
						setTabname('All')
						dispatch(setTrashedResponses([]))
						// dispatch(setMyTrashedForm([]));
						// dispatch(setTrashedResponses(trashedResponsesCopy))
						// setMyTrashedForms(myTrashedFormsCopy)
					})
					.catch(error => { })
				Swal.fire({
					icon: 'error',
					title: 'Response deleted',
					showConfirmButton: false,
					timer: 1500
				})
			}
		})
	}
	const unArchiveResponse = (e, responseId) => {
		e.preventDefault()
		var data = {};
		data.responseId = responseId;
		data.formId = form_id;
		var nowPage = trashedResponses.current_page;
		if (trashedResponses.data.length - 1 == 0) {
			nowPage = nowPage - 1
		}
		axios.post(API_BASE_URL + '/api/unArchiveResponse?page=' + nowPage, data)
			.then(response => {
				// var responsesCopy = JSON.parse(JSON.stringify(allResponses))
				// var responsesDataCopy = JSON.parse(JSON.stringify(allResponses.data))
				// responsesDataCopy.unshift(response.data.responses)	
				// responsesCopy.data = responsesDataCopy;
				// responsesCopy.total = responsesCopy.total + 1;
				// responsesCopy.to = responsesCopy.to + 1;
				// setAllResponses(responsesCopy)
				// var trashedResponsesCopy = JSON.parse(JSON.stringify(allTrashedResponses))
				// var trashedResponsesDataCopy = trashedResponsesCopy.data.filter(item => {
				// 	return item._id != responseId;
				// });
				// trashedResponsesCopy.data = trashedResponsesDataCopy;
				// trashedResponsesCopy.total = trashedResponsesCopy.total - 1;
				// trashedResponsesCopy.to = trashedResponsesCopy.to - 1;
				// dispatch(setTrashedResponses(trashedResponsesCopy))
				// if (trashedResponsesDataCopy.length == 0) {
				// 	setTabname('All')
				// }
				var nowLabels = JSON.parse(JSON.stringify(labels))
				var nowFilters = JSON.parse(JSON.stringify(filters))
				var data = response.data
				data.filters = nowFilters
				data.labels = nowLabels
				var readyDispatch = {
					"responses": data.responses,
					"filters": data.filters,
					"labels": data.labels,
					"favorites": data.favorites
				}
				var trashedAllResponses = response.data.trashedAll;
				dispatch(setTrashedResponses(trashedAllResponses))
				dispatch(setResponses(readyDispatch))
				if (trashedAllResponses.data.length == 0) {
					setTabname('All')
				}

				if (response.data.favourite == 1) {
					var favoritesCopy = JSON.parse(JSON.stringify(allFavorites))
					var favoritesCopyData = JSON.parse(JSON.stringify(favoritesCopy.data))
					favoritesCopyData.unshift(response.data)
					favoritesCopy.data = favoritesCopyData
					setAllFavorites(favoritesCopy)
				}
			})
			.catch(error => { })
	}
	const changeTab = (e, tabname) => {
		e.preventDefault()
		setSearchTextValue('')
		if (tabname !== 'All') {
			let data = {};
			data.id = form_id;
			data.page = 1;
			dispatch(getResponses(data))
		}
		//  if(tabname !== 'All'){
		// 	 setAllResponses(responses)

		//   }

		const currentTarget = e.currentTarget
		// if (tabname === 'All') {
		if (tabTrash.current != null) { tabTrash.current.classList.remove('active') }
		currentTarget.classList.add('active')
		// } else {
		// 	if (tabAll.current != null) { tabAll.current.classList.remove('active') }
		// 	currentTarget.classList.add('active')
		// }
		setTabname(tabname)
	}
	const goResponse = (e, id, trashed) => {
		var responsesCopy = JSON.parse(JSON.stringify(allResponses))
		var responsesDataCopy = JSON.parse(JSON.stringify(trashed === true ? allTrashedResponses : allResponses.data))
		const thisResponse = responsesDataCopy.filter(item => {
			return item._id == id;
		});
		const index = responsesDataCopy.findIndex(item => {
			return item._id == id;
		});
		thisResponse[0].status = 'old'
		responsesDataCopy[index] = thisResponse[0]
		if (!trashed) {
			responsesCopy.data = responsesDataCopy;
			setAllResponses(responsesCopy)
		}
		setAllResponses(responsesCopy)
		window.open(
			APP_BASE_URL + "/response/" + id, "_blank")

		let data = {};
		data.id = id;
		axios.post(API_BASE_URL + '/api/changeStatus', data)
			.then(response => { })
			.catch(error => { })
	}
	const goResponseTrashed = (e, id) => {
		var responsesCopy = JSON.parse(JSON.stringify(allTrashedResponses))
		var responsesDataCopy = JSON.parse(JSON.stringify(allTrashedResponses))
		const thisResponse = responsesDataCopy.filter(item => {
			return item._id == id;
		});
		const index = responsesDataCopy.findIndex(item => {
			return item._id == id;
		});
		thisResponse[0].status = 'old'
		responsesDataCopy[index] = thisResponse[0]
		// responsesCopy.data = responsesDataCopy;
		setAllTrashedResponses(responsesCopy)
		window.open(
			APP_BASE_URL + "/response/" + id, "_blank")

		let data = {};
		data.id = id;
		axios.post(API_BASE_URL + '/api/changeStatus', data)
			.then(response => { })
			.catch(error => { })
	}
	const runDatebar = () => {
		alert('sad')
	}
	const toggleFilter = e => {
		e.preventDefault()
		var element = e.currentTarget.querySelectorAll('i')[0]
		if (element.classList.contains('fa-plus')) {
			element.classList.add('fa-minus')
			element.classList.remove('fa-plus')
		} else {
			element.classList.add('fa-plus')
			element.classList.remove('fa-minus')
		}
		// var uniqueId = e.currentTarget.getAttribute('toggledata')
		// console.log(uniqueId);
		// document.querySelectorAll('#toggledDropdown' + uniqueId)[0].classList.toggle('disActive')
		// e.currentTarget.classList.toggle('disActive')
	}

	const printResponse = (e, id) => {
		let data = {};
		data.id = id;
		axios.post(API_BASE_URL + '/api/changeStatus', data)
			.then(response => { })
			.catch(error => { })
		localStorage.setItem('print', 'true')
		var url = APP_BASE_URL + "/response/" + id
		window.open(url, '_target');

	}

	const addFavorite = (e, responseId) => {
		e.preventDefault()
		var nowPage = allResponses.current_page;

		var data = {};
		data.formId = form_id;
		data.responseId = responseId;
		// if (allResponses.data.length - 1 == 0) {
		// 	nowPage = nowPage - 1
		// }
		// var responsesCopy = JSON.parse(JSON.stringify(allResponses))
		// var responsesDataCopy = JSON.parse(JSON.stringify(allResponses.data))
		// var foundIndex = responsesDataCopy.findIndex(x => x._id == responseId);
		// var foundItem = responsesDataCopy.filter(x => x._id == responseId)[0];
		// foundItem.favourite = 1;
		// responsesDataCopy[foundIndex] = foundItem;
		// responsesCopy.data = responsesDataCopy;
		// setAllResponses(responsesCopy)	

		// var favoritesCopy = JSON.parse(JSON.stringify(allFavorites))
		// var favoritesCopyData = JSON.parse(JSON.stringify(favoritesCopy.data))
		// favoritesCopyData.unshift(foundItem)
		// favoritesCopy.data = favoritesCopyData
		// setAllFavorites(favoritesCopy)
		// setDateState({
		// 	from: undefined,
		// 	to: undefined,
		// })
		axios.post(API_BASE_URL + '/api/addFavorite?page=' + nowPage, data)
			.then(response => {
				setAllFavorites(response.data.favorites)
				dispatch(setAllResponses(response.data.responses))
			})
			.catch(error => { })
	}

	const generatePdf = (e, responseId) => {
		e.preventDefault()
		showMessagePdf("The file is downloading, please wait.")
		// var data = {};
		// data. = responseId;
		pdfIframe.current.src = `${API_BASE_URL}/api/generatePdf/${responseId}`
		setTimeout(() => {
			if (!messageEdit.current != null) {
				messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
			}
			// Promise.resolve().then(_ => {
			// if (!messageEdit.current != null) {
			// 	messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
			// }
			// });
		}, 1000);

		// axios.get(API_BASE_URL + `/api/generatePdf/${responseId}`)
		// .then(response => {
		// 	})
		// 	.catch(error => {
		// 		console.log(error)

		// })
	}

	const removeFavorite = (e, responseId, pageName) => {
		e.preventDefault()
		var nowPage = allResponses.current_page;
		var data = {};
		data.formId = form_id;
		data.responseId = responseId;
		if (pageName === 'favorite') {
			nowPage = allFavorites.current_page;
		} else {
			data.pageName = true;
		}
		if (allFavorites.data.length - 1 == 0) {
			nowPage = nowPage - 1
		}
		// var responsesCopy = JSON.parse(JSON.stringify(allResponses))
		// var responsesDataCopy = JSON.parse(JSON.stringify(allResponses.data))
		// var foundIndex = responsesDataCopy.findIndex(x => x._id == responseId);
		// var foundItem = responsesDataCopy.filter(x => x._id == responseId)[0];
		// foundItem.favourite = 0;
		// responsesDataCopy[foundIndex] = foundItem;
		// responsesCopy.data = responsesDataCopy;
		// setAllResponses(responsesCopy)

		// var favoritesCopy = JSON.parse(JSON.stringify(allFavorites))
		// var favoritesCopyData = favoritesCopy.data.filter(item => {
		// 	return item._id != responseId;
		// });
		// favoritesCopy.data = favoritesCopyData
		// setAllFavorites(favoritesCopy)
		axios.post(API_BASE_URL + '/api/removeFavorite?page=' + nowPage, data)
			.then(response => {
				if (response.data.favorites.data.length == 0) {
					setTabname('All')
				}
				// setAllFavorites(response.data.favorites)
				var nowLabels = JSON.parse(JSON.stringify(labels))
				var nowFilters = JSON.parse(JSON.stringify(filters))
				var data = response.data
				data.filters = nowFilters
				data.labels = nowLabels
				dispatch(setResponses(data))
				// dispatch(setAllResponses(response.data.responses))
			})
			.catch(error => { })
	}
	const [copyButton, setCopyButton] = useState({ copied: 'Link Copied!', notCopied: 'Copy Link' })
	const copyLink = e => {
		var currentTarget = e.currentTarget
		var forThisChangeIcon = currentTarget.querySelectorAll('.forChangeIcon')[0]
		var formLinkButtonTitle = currentTarget.closest('.form-link-buttons').querySelectorAll('.response-link-button-title')[0]
		e.preventDefault()
		try {
			e.currentTarget.previousSibling.classList.toggle('copied')
			if (e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.contains('fa-link')) {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-link')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-check', 'copied')
			} else {
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.remove('fa-check', 'copied')
				e.currentTarget.querySelectorAll('.forChangeIcon')[0].classList.add('fa-link')
			}
			setTimeout(() => {
				forThisChangeIcon.classList.add('fa-link')
				formLinkButtonTitle.classList.remove('copied')
			}, 500);
			// navigator.clipboard.writeText(e.currentTarget.getAttribute('link'))
			var textField = document.createElement('textarea')
			textField.innerText = e.currentTarget.getAttribute('link')
			document.body.appendChild(textField)
			textField.select()
			document.execCommand('copy')
			textField.remove()
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	const [csvData, setCsvData] = useState({
		headers: [],
		data: [],
		filename: ""
	})
	const [csvDataAll, setCsvDataAll] = useState({
		headers: [],
		data: [],
		filename: ""
	})

	const csvLinkAll = useRef()
	const downloadResponse = (e, response) => {
		const headers = []
		const CSVResponse = [{}]
		let a = 0;
		labels.map((label, index) => {
			let tabname = label.type;
			if (tabname !== 'divider' && tabname !== 'image' && tabname !== 'paragraph' && tabname !== 'empty') {
					if (!CSVResponse.hasOwnProperty(label.label)) {
					headers.push({
						label: label.label,
						key: label.uniqueId
					})
					if (response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value) {
						if(response.response[label.uniqueId].value === 'forDeleteFiles'){
							labels.forEach((e)=>{
								if(e.type === 'upload' ){
									if(response.response.files[a] != undefined) {
										CSVResponse[0][e.uniqueId] = response.response.files[a][0];
										a++
									}
								}
							})
						}else{
							let value = response.response[label.uniqueId].value.toString();
							let tmp = document.createElement("DIV");
							tmp.innerHTML = value.replaceAll('</p><p>', " ");
							CSVResponse[0][label.uniqueId] = tmp.textContent.replace(/"/g, "'") || tmp.innerText.replace(/"/g, "'") || ""
						}
						
					} else {
						let text = []
						if (response.response.hasOwnProperty(label.uniqueId)) {
							response.response[label.uniqueId].forEach((item) => {
								text.push(item.value)
							})
							text = text.join(', ');
							let tmp = document.createElement("DIV");
							tmp.innerHTML = text.replaceAll('</p><p>', " ");
							CSVResponse[0][label.uniqueId] = tmp.textContent.replace(/"/g, "'") || tmp.innerText.replace(/"/g, "'") || ""
						} 

					}
					// CSVResponse[0][label.label] = response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value?.length > 0 ? response.response[label.uniqueId].value.replace(/"/g, "'") : '-'
				}
			}
		})
		const csvLinks = {
			headers: headers,
			data: CSVResponse,
			filename: `Response - ${response._id}.csv`
		}
		setCsvData(csvLinks)
	}

	const searchText = e => {
		setSearchTextValue(e.target.value)
		if(!repetitiveSearchText){
			setRepetitiveSearchText(true)
		}
		if (e.target.value === '') {
			setAllResponses(responses)
		}
	}

	const submitSearch = e => {
		e.preventDefault()
		let data = {};
		data.id = form_id;
		data.searchText = searchTextValue;
		// data.searchText = e.target.value;
		// console.log(data.searchText);
		if (data.searchText === '') {
			setAllResponses(responses)
		}
		else {
			if(repetitiveSearchText){
				axios.post(API_BASE_URL + '/api/getResponsesInSearchText', data)
					.then(response => {
						let responsesCopy = JSON.parse(JSON.stringify(allResponses))
						let responsesDataCopy = JSON.parse(JSON.stringify(response.data))
						responsesCopy.data = responsesDataCopy;
						responsesCopy.total = responsesCopy.data.length;
						responsesCopy.to = responsesCopy.data.length - 1;
						setAllResponses(responsesCopy)
					})
					.catch(error => { })
					setRepetitiveSearchText(false)
			}
		}
	}

	const [liveResponsable, setLiveResponsable] = useState([]);

	const addFilterResponsables = (uniqueId, value, checked) => {
		try {
			var newResponsable = {}
			newResponsable[uniqueId] = value
			var copyAllResponses = JSON.parse(JSON.stringify(allResponses))
			var oldResponsables = liveResponsable.length > 0
				? liveResponsable
				: copyAllResponses.data[0].form.responsables
					? copyAllResponses.data[0].form.responsables
					: [];
			if (!checked) {
				oldResponsables = [...oldResponsables, newResponsable]
				setTimeout(() => {
					setLiveResponsable(oldResponsables)
				}, 0);
			} else {
				setTimeout(() => {
					setLiveResponsable(oldResponsables.filter(e => Object.keys(e)[0] !== uniqueId))
				}, 0);
			}
		} catch (error) {
			console.log(error)
			showMessageError('Something went wrong.')
		}
	}

	const resetColumns = () => {
		setLiveResponsable([])
	}

	const downloadResponses = e => {
		// var zip = new JSZip();
		 
		if (allResponses.data && Object.values(allResponses.data).length > 0) {
			const CSVResponseAll = []
			const headers = []
			
			var bool = true;
			Object.values(allResponses.data).map((response, indexResponse) => {
				const CSVResponse = {}
				labels.map((label, index) => {
					let tabname = label.type;
					if (tabname !== 'divider' && tabname !== 'image' && tabname !== 'paragraph' && tabname !== 'empty') {
						if (!CSVResponse.hasOwnProperty(label.label)) {
							if (bool) {
								headers.push({
									label: label.label,
									key: label.label
								})
							}
							if (response.response[label.uniqueId]?.value=='forDeleteFiles') {
								CSVResponse[label.label]=response.response.files[0][0]
							}else if (response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value?.length > 0 ){
								let tmp = document.createElement("DIV");
								// response.response[label.uniqueId].value.replace(/<br>/g, 'BOOM')
								tmp.innerHTML = response.response[label.uniqueId].value.replaceAll('</p><p>', " ");
								CSVResponse[label.label] = tmp.textContent.replace(/"/g, "'")  || tmp.innerText.replace(/"/g, "'") || ""
							} else {
								let text = []
								if (response.response.hasOwnProperty(label.uniqueId)) {
									if (Array.isArray(response.response[label.uniqueId])) {
										response.response[label.uniqueId].forEach((item) => {
											text.push(item.value)
										})
									} else {
										text.push(response.response[label.uniqueId].value)
									}
								}

								text = text.join(', ');

								let tmp = document.createElement("DIV");
								tmp.innerHTML = text.replaceAll('</p><p>', " ");
								CSVResponse[label.label] = tmp.textContent.replace(/"/g, "'") || tmp.innerText.replace(/"/g, "'") || ""
							}
							// CSVResponse[label.label] = response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value?.length > 0 ? response.response[label.uniqueId].value.replace(/"/g, "'") : '-'
						}
						// else {
						// 	if (bool) {
						// 		headers.push({
						// 			label: label.label + ' ',
						// 			key: label.label + ' '
						// 		})
						// 	}
						// 	if (response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value?.length > 0 ){
						// 		let tmp = document.createElement("DIV");
						// 		// response.response[label.uniqueId].value.replace(/<br>/g, 'FOOM')
						// 		tmp.innerHTML = response.response[label.uniqueId].value.replaceAll('</p><p>', " ");
						// 		CSVResponse[0][label.label] = tmp.textContent.replace(/"/g, "'")  || tmp.innerText.replace(/"/g, "'") || ""
						// 	} else {
						// 		CSVResponse[label.label] = "-"
						// 	}
						// 	// CSVResponse[label.label + ' '] = response.response.hasOwnProperty(label.uniqueId) && response.response[label.uniqueId]?.value?.length > 0 ? response.response[label.uniqueId].value.replace(/"/g, "'") : '-'
						// }
					}
				})
				bool = false;
				CSVResponseAll.push(CSVResponse)
			})
			const csvDataAll = {
				headers: headers,
				data: CSVResponseAll,
				filename: `Responses - ${moment(new Date()).format("DD/MM/YYYY")}.csv`
			}
			// console.log(CSVResponseAll)
			setCsvDataAll(csvDataAll)
		}
	}

	const downloadAllResponsesInPdf = e => {
		showMessagePdf("The files are downloading, please wait.")
		axios.get(API_BASE_URL + `/api/downloadAllResponsesInPdf/${form_id}`, null, AxiosConfigs)
			.then(response => {
				var textField = document.createElement('a')
				textField.href = API_BASE_URL + response.data
				document.body.appendChild(textField)
				textField.click()
				textField.remove()

				axios.post(API_BASE_URL + `/api/removeTemp`, null, AxiosConfigs)
					.then(response => {
						// setTimeout(() => {
						// 	if (!messageEdit.current != null) {
						messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
						// 	}
						// }, 1000)
					})
			})
			.catch(error => { })
	}

	const archiveAllResponses = e => {
		e.preventDefault();
		try {
			Swal.fire({
				title: 'Are you sure?',
				text: 'This form will be removed and it will not be possible to restore',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#dc3545',
				customClass: {
					cancelButton: 'btn-outline-dark-default'
				},
				confirmButtonText: 'Archive',
				cancelButtonText: 'Close'
			}).then((result) => {
				if (result.isConfirmed) {
					var data = {};
					data.id = form_id
					axios.post(API_BASE_URL + '/api/archiveAllResponses', data)
						.then(response => {
							dispatch(setTrashedResponses(response.data))
							dispatch(setAllFavorites([]), setAllResponses([]))
						})
						.catch(error => { })
					Swal.fire({
						icon: 'error',
						title: 'Form archived',
						showConfirmButton: false,
						timer: 1500
					})
				}
			})

		} catch (error) {
			showMessageError('Something went wrong.')
		}

	}

	useEffect(() => {
		if (document.getElementById("searchInput")) {
			let input = document.getElementById("searchInput");
			input.addEventListener("keypress", function (event) {
				if (event.key === "Enter") {
					event.preventDefault();
					document.getElementById("buttonSearch").click();
				}
			});
		}
	}, [searchTextValue])

	const addActive = e => {
		e.preventDefault()
		const toggledButtons = document.querySelectorAll('.animation-div')
		var bool = false;
		if (e.currentTarget.nextSibling.classList.contains('_active')) { bool = true; }
		// toggledButtons.forEach(element => element.classList.remove('_active'));
		if (bool) { e.currentTarget.nextSibling.classList.remove('_active') }
		else { e.currentTarget.nextSibling.classList.add('_active') }

	}

	document.documentElement.addEventListener("click", function (e) {
		try {
			if (!e.target.closest('.animation-div') && !e.target.closest('.myForms-content-right button')) {
				const toggledButtons = document.querySelectorAll('.animation-div')
				toggledButtons.forEach(element => element.classList.remove('_active'));
			}
		} catch (error) { throw error }
	})

	return (
		<div id="responses">
			<div className="px-3 px-xxl-5 form-tabs border-bottom border-gray-200 after-header">
				<div className="form-changes" ref={messageEdit} >
				{
					showSucces?
						<>
							<div className='img-div'>
							<svg className="img-icon" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
								<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
									stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
							</svg>	
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
							
						</>
					:null
					}
					{
					showError?
						<>
							<div className='img-div'>
								<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327Z" stroke="#EF585D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M20 12L12 20" stroke="#EF585D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M12 12L20 20" stroke="#EF585D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
							<div className='succes-div'>
								<p className='paragraph'>{editedMessage}</p>
							</div>
						</>
					:null
				}
				</div>
				<iframe src="" ref={pdfIframe} id="iFrame" seamless width="200" height="200" frameBorder="0"
					onLoad={(e) => setIframeSrc(e.target.src)}
					style={{ display: 'none' }} />
				<div className="container-fluid px-0 h-100">
					<div className="row align-items-center h-100">
						<div className="col form-tabs-crumbsTitle d-flex justify-content-between h-100 align-items-center">
							<h1 className="h2 mb-0 lh-sm account-tabname"
								title={window.innerWidth <= 768 && formTitleReady?.length > 15
									? formTitleReady
									: window.innerWidth > 768 && formTitleReady?.length > 25 ? formTitleReady : ''}>
								{window.innerWidth <= 768 && formTitleReady?.length > 15
									? formTitleReady.substring(0, 15) + ' ...' : window.innerWidth > 768 && formTitleReady?.length > 25
										? formTitleReady.substring(0, 25) + ' ...' : formTitleReady}
							</h1>
							{/* <button type="button">Settings</button> */}
							<div className='crumbsTitle d-flex justify-content-between align-items-end form-crumbsTitle'>
								{/* <span className="text-uppercase subtabname tiny text-gray-600 Montserrat-font font-weight-semibold">Form <i className="fas fa-arrow-right"></i>{' ' + formTitleReady}</span> */}
								{/* <div className='breadcrumbs '>
									<div
										className="breadcrumbs__item" linkname='forms'>
										<Link to={'/form/' + form_id} target="_blank">
											<span>Form</span>
										</Link>
									</div>
									<div
										className="breadcrumbs__item">
										<Link to={'/statistics/' + form_id}>
											<span>Statistics</span>
										</Link>
									</div>
								</div> */}
								{/* <div className="d-flex align-items-center">
										<nav aria-label="breadcrumb" className="first d-md-flex">
										<ol className="breadcrumb">
											<li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase" href="#"><span>home</span></a><img className="ml-md-3" src="https://img.icons8.com/offices/100/000000/double-right.png" width={20} height={20} /> </li>
											<li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase" href="#"><span>about</span></a><img className="ml-md-3" src="https://img.icons8.com/offices/100/000000/double-right.png" width={20} height={20} /></li>
											<li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase" href="#"><span>team</span></a><img className="ml-md-3" src="https://img.icons8.com/offices/100/000000/double-right.png" width={20} height={20} /> </li>
											<li className="breadcrumb-item font-weight-bold mr-0 pr-0"><a className="black-text active-1" href="#"><span>Payam Daliri</span></a> </li>
										</ol>
										</nav>
									</div> */}
								{/* <ul className="breadcrumb-form">
									<li style={{ borderRadius: '50px 0 0 50px', paddingLeft: '15px' }}>
										<Link to={'/form/' + form_id}>
											<span>Form</span>
										</Link>
									</li>
									<li className='_active'>Responses</li>
									<li style={{ borderRadius: '0 50px 50px 0', paddingRight: '15px' }}>
										<Link to={'/statistics/' + form_id}>
											<span>Statistics</span>
										</Link>
									</li>
								</ul> */}
								<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative zIndex-0 form-nav-tabs">
									<Link to={'/form/' + form_id}>
										<li className="nav-item">
											<button type="button" className={"nav-link py-1"}>
												<span>Form</span>
											</button>
										</li>
									</Link>
									{
										<Link to={'/responses/' + form_id}>
											<li className="nav-item">
												<button type="button" className={pathnameSearchResponse != -1 ? "nav-link active  py-1" : "nav-link py-1"}>
													<span>Responses</span>
												</button>
											</li>
										</Link>
									}
									{
										<Link to={'/statistics/' + form_id}>
											<li className="nav-item">
												<button type="button" className={pathnameSearchStatistics != -1 ? "nav-link active py-1" : "nav-link py-1"}>
													<span>Statistics</span>
												</button>
											</li>
										</Link>
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="filter-container">
				<div className="container-fluid px-0 col-12 d-flex flex-wrap">
					<div className="d-flex align-items-center justify-content-between  col-12 response-filter-top">
						<form action="#" ref={resetFormFilters}>
							<div className="filters-body">
								<div className='pe-md-2 btn btn-outline-default text-gray-700 border-gray-700 px-3 search-text-styles '>
									<input
										id='searchInput'
										type="text"
										className='search-response-input-styles'
										placeholder='Search responses...'
										value={searchTextValue}
										onChange={searchText}
									// onInput={e => submitSearch(e)}
									/>
									<button id="buttonSearch" type="button" onClick={(e) => searchTextValue ? submitSearch(e) : e.preventDefault()} className="border-0 bg-transparent p-1 response-text-search-icon"><img src="/svg/icons/search@14.svg" alt="Search" /></button>
								</div>
								<div className="d-flex align-items-center py-0 justify-content-start filter-body-response" >
									<div className="dropdown export-dropdown pe-md-2 responses-filter-button">
										<a href="#" role="button" id="Sources" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-outline-default text-gray-700 border-gray-700 px-3 responses-filter-a"><span>{daysFilter} </span> <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 13 13">
											<rect data-name="Icons/Tabler/Chevron Down background" width={13} height={13} fill="none" />
											<path d="M.214.212a.738.738,0,0,1,.952-.07l.082.07L7.1,5.989a.716.716,0,0,1,.071.94L7.1,7.011l-5.85,5.778a.738.738,0,0,1-1.034,0,.716.716,0,0,1-.071-.94l.071-.081L5.547,6.5.214,1.233A.716.716,0,0,1,.143.293Z" transform="translate(13 3.25) rotate(90)" fill="#495057" />
										</svg>
										</a>
										<ul className="dropdown-menu dropdown-menu-end responses-filter-ul" aria-labelledby="Sources" disabled>
											<li>
												{/* <form onSubmit={(e) => responsesDaysFilter(e)} days='all'> */}
												<button type="button" onMouseDown={(e) => responsesDaysFilter(e, 'all')} className="dropdown-item"><span>All</span></button>
												{/* </form> */}
											</li>
											<li>
												{/* <form onSubmit={responsesDaysFilter} days='0'> */}
												<button type="button" onMouseDown={(e) => responsesDaysFilter(e, '0')} className="dropdown-item"><span>Today</span></button>
												{/* </form> */}
											</li>
											{/* <li>
														<form onSubmit={responsesDaysFilter} days='2'>
															<button type="submit" className="dropdown-item"><span>Yesterday</span></button>
														</form>
													</li> */}
											<li>
												{/* <form onSubmit={responsesDaysFilter} days='7'> */}
												<button type="button" onMouseDown={(e) => responsesDaysFilter(e, '7')} className="dropdown-item"><span>Last 7 days</span></button>
												{/* </form> */}
											</li>
											<li>
												{/* <form onSubmit={responsesDaysFilter} days='month'> */}
												<button type="button" onMouseDown={(e) => responsesDaysFilter(e, 'month')} className="dropdown-item"><span>This month</span></button>
												{/* </form> */}
											</li>
											<li>
												{/* <div className="InputFromTo response-daypicker">
											<DatePicker
												selectsRange={true}
												startDate={startDate}
												endDate={endDate}
												onChange={(update) => {
													setDateRange(update);
												}}
												placeholderText="Select date range"
												isClearable={true}
											/>
										</div> */}
												{/* <form onSubmit={runDatebar}> */}
												{/* <button type="button" onMouseDown={e => }  className="dropdown-item"><span>Custom</span></button> */}
												{/* </form> */}
											</li>
											<li>
												<hr className="dropdown-divider" />
											</li>
											<li className="datepicker-li">
												<button type='button' onMouseDown={(e) => changeIsOpenDatepicker(e)} className="dropdown-item"><span>Custom</span></button>
												{/* <div className="dropdown-item">
															<div>
																<svg data-name="icons/tabler/calendar" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16">
																	<rect data-name="Icons/Tabler/Calendar background" width={16} height={16} fill="none" />
																	<path d="M2.256,16A2.259,2.259,0,0,1,0,13.743V3.9A2.259,2.259,0,0,1,2.256,1.641H3.282V.616A.615.615,0,0,1,4.507.532l.005.084V1.641H9.846V.616A.615.615,0,0,1,11.071.532l.006.084V1.641H12.1A2.259,2.259,0,0,1,14.359,3.9v9.846A2.259,2.259,0,0,1,12.1,16ZM1.231,13.743a1.027,1.027,0,0,0,1.025,1.026H12.1a1.027,1.027,0,0,0,1.026-1.026V7.795H1.231Zm11.9-7.179V3.9A1.027,1.027,0,0,0,12.1,2.872H11.077V3.9a.616.616,0,0,1-1.226.084L9.846,3.9V2.872H4.513V3.9a.615.615,0,0,1-1.225.084L3.282,3.9V2.872H2.256A1.026,1.026,0,0,0,1.231,3.9V6.564Z" transform="translate(1)" fill="#495057" />
																</svg>
																<span className="ms-2">
																	Custom
																</span>
															</div>
														</div> */}

											</li>
										</ul>
										{/* <Modal show={show} >
													<Modal.Header closeButton>
														<Modal.Title>Custom DatePicker</Modal.Title>
													</Modal.Header>
													<Modal.Body> */}

										<div className="InputFromTo response-daypicker date-picker-show date-picker-absolute">
											{
												isOpenDatepicker && (<DatePicker
													ref={datePickerShow}
													selectsRange={true}
													startDate={startDate}
													endDate={endDate}
													onChange={(update) => {
														setVisibleDatePicker(!visibleDatePicker)
														setDateRange(update);
													}}
													placeholderText="Select date range"
													isClearable={true}
													inline
												/>)
											}
										</div>
										{/* </Modal.Body>
													<Modal.Footer>
														<Button variant="secondary" onClick={handleClose}>
															Close
														</Button>
														<Button variant="primary" onClick={handleClose}>
															Filter
														</Button>
													</Modal.Footer>
												</Modal> */}

										{/* <div className="dropdown export-dropdown pe-2 d-flex align-items-center datepicker-dropdown">
											<div className="InputFromTo response-daypicker">
												<DatePicker
													selectsRange={true}
													startDate={startDate}
													endDate={endDate}
													onChange={(update) => {
														setDateRange(update);
													}}
													placeholderText="Select date range"
													isClearable={true}
												/>
											</div>
									</div> */}
									</div>
								</div>
								{
									filters && Object.keys(filters).length > 0
										? Object.keys(filters).map((key, index) => {
											return <div className="my-2 dropdown responses-dropdown-body export-dropdown pe-md-2 responses-filter-button" key={index}>
												<a href="#" role="button" id="Sources" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-outline-default text-gray-700 border-gray-700 px-3 responses-filter-a"><span>{filters[key]['label']} {filters[key]['inLabel'] ? ' - ' + filters[key]['inLabel'] : null} </span> <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 13 13">
													<rect data-name="Icons/Tabler/Chevron Down background" width={13} height={13} fill="none" />
													<path d="M.214.212a.738.738,0,0,1,.952-.07l.082.07L7.1,5.989a.716.716,0,0,1,.071.94L7.1,7.011l-5.85,5.778a.738.738,0,0,1-1.034,0,.716.716,0,0,1-.071-.94l.071-.081L5.547,6.5.214,1.233A.716.716,0,0,1,.143.293Z" transform="translate(13 3.25) rotate(90)" fill="#495057" />
												</svg>
												</a>
												<ul className="dropdown-menu dropdown-menu-end responses-filter-ul responses-dropdown-ul" aria-labelledby="Sources" disabled>
													{
														filters[key]['data'].map((option, index) => {
															// console.log(filters[key])
															return <li className="form-check form-check-inline" key={index}>
																{
																	filters[key]['type'] == "radio" || filters[key]['type'] == "dropdown"
																		?
																		<input
																			className="form-check-input form-check-radio"
																			type='checkbox'
																			name={"inlineCheckbox" + filters[key].uniqueId}
																			id={"inlineCheckbox" + filters[key].uniqueId + key + option + index}
																			forkey={filters[key]['uniqueId']}
																			foroption={option}
																			checked={radioChecks.hasOwnProperty(filters[key].uniqueId) && radioChecks[filters[key].uniqueId] === index}
																			onChange={e => addFilterRadio(e, filters[key].uniqueId, index)} />
																		:
																		<input
																			className="form-check-input"
																			type={'checkbox'}
																			name={"inlineCheckbox" + filters[key].uniqueId}
																			id={"inlineCheckbox" + filters[key].uniqueId + key + option + index}
																			forkey={filters[key]['uniqueId']}
																			foroption={option}
																			onChange={addFilterCheckbox} />
																}
																<label className="form-check-label" htmlFor={"inlineCheckbox" + filters[key].uniqueId + key + option + index} >{option}</label>
															</li>
														})
													}
													{/* <li>
															<form onSubmit={responsesDaysFilter} days='all'>
																<button type="submit" className="dropdown-item"><span>All</span></button>
															</form>
														</li> */}
												</ul>
											</div>

										})
										: null
									//<div>No filters</div>
								}
							</div>
						</form>

					</div>

				</div>
			</div>
			<div className="p-3 px-5 py-4">
				<div className="container-fluid px-0">
					<div className="row mb-4">
						<div className="col-12">
							<div className="card rounded-12 shadow-dark-80 border border-gray-50 responses-loader-body">
								<div className="d-flex justify-content-between align-items-center min-size responsanses-tabs-nav">
									<ul className="nav nav-tabs nav-tabs-md nav-tabs-line position-relative">
										<li className="nav-item">
											<button type="button" ref={tabAll} onClick={e => changeTab(e, 'All')} className={tabname == 'All' ? "nav-link active" : "nav-link"}>All {(Object).keys(allResponses).length > 0 && (Object).keys(allResponses.data).length > 0 ? '(' + allResponses.total + ')' : null}</button>
										</li>
										{
											(Object).keys(allFavorites).length > 0 && (Object).keys(allFavorites.data).length
												?
												<li className="nav-item">
													<button type="button" ref={tabFavorite} onClick={e => changeTab(e, 'Favorite')} className={tabname == 'Favorite' ? "nav-link active" : "nav-link"}>Favorite {(Object).keys(allFavorites).length > 0 && (Object).keys(allFavorites.data).length ? '(' + allFavorites.total + ')' : null}</button>
												</li>
												: null
										}
										{
											(Object).keys(trashedResponses).length > 0 && (Object).keys(trashedResponses.data).length
												?
												<li className="nav-item">
													<button type="button" ref={tabTrash} onClick={e => changeTab(e, 'Trash')} tabname="Trash" className={tabname == 'Trash' ? "nav-link active nav-link-archive" : "nav-link nav-link-archive"}>Archived {(Object).keys(trashedResponses).length > 0 && (Object).keys(trashedResponses.data).length ? '(' + trashedResponses.total + ')' : null}</button>
												</li>
												: null
										}
									</ul>
									<div className='form-link-buttons-content mx-3'>

										<div className='form-link-buttons form-link-buttons-columns button-statistics d-flex download-zip-response align-items-center response-head-styles'>
											<div className="my-2 dropdown responses-columns-body export-dropdown pe-md-2 responses-filter-button" >
												<div className=''>
													<a href="#" role="button" id="Sources" data-bs-toggle="dropdown" className="form-link-button-body btn text-gray-700 border-gray-700 px-3 responses-filter-a">
														<>
															<div className="form-link-button-title-bottom form-link-button-title-hovered text-center">Table Settings </div>
															<span><i className="fas fa-eye"></i> Columns</span>
														</>
														<svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 13 13">
															<rect data-name="Icons/Tabler/Chevron Down background" width={13} height={13} fill="none" />
															<path d="M.214.212a.738.738,0,0,1,.952-.07l.082.07L7.1,5.989a.716.716,0,0,1,.071.94L7.1,7.011l-5.85,5.778a.738.738,0,0,1-1.034,0,.716.716,0,0,1-.071-.94l.071-.081L5.547,6.5.214,1.233A.716.716,0,0,1,.143.293Z" transform="translate(13 3.25) rotate(90)" fill="#495057" />
														</svg>
													</a>
													<ul className="dropdown-menu dropdown-menu-end responses-filter-ul responses-columns-ul columns-response-all" aria-labelledby="Sources" disabled>
														<div className="responses-columns-info">
															<p className="responses-columns-title">SHOW/HIDE COLUMNS</p>
															<button type="button" onMouseDown={() => resetColumns()} className="responses-columns-button" title="Reset Columns">
																<img src="/svg/icons/rotate-right-solid.svg" width="20" alt="" />
															</button>
														</div>
														<ul className="responses-filter-ul-inside">
															{/* {
																allResponses.data
																&& Object.values(allResponses.data).length > 0
																&& allResponses.data[0]?.form?.responsables?.length > 0
																? console.log(allResponses.data[0].form.responsables) : null
															} */}
															{
																labels ?
																	labels.map((label, index) => {
																		// console.log(label)
																		return label.type !== 'empty' && label.type !== 'paragraph' && label.type !== 'divider' && label.type !== 'image'
																			?
																			<li className="form-check form-check-inline" key={index}>
																				<input

																					className="form-check-input"
																					type={'checkbox'}
																					// name={"inlineCheckbox" + filters[key].uniqueId}
																					id={"inlineCheckbox" + label.uniqueId + index}
																					checked={
																						liveResponsable && liveResponsable.length > 0
																							? liveResponsable.filter(e => Object.keys(e)[0] === label.uniqueId).length > 0
																								? true
																								: false
																							:
																							allResponses.data
																								&& Object.values(allResponses.data).length > 0
																								&& allResponses.data[0]?.form?.responsables?.length > 0
																								?
																								allResponses?.data[0].form.responsables.filter(e => Object.keys(e)[0] === label.uniqueId).length > 0
																									? true : false
																								: false
																					}
																					// forkey={filters[key]['uniqueId']}
																					// foroption={option}
																					onChange={e => e.preventDefault()}
																					onMouseUp={e => addFilterResponsables(label.uniqueId, label.label, e.target.checked)}
																				/>
																				<label
																					onMouseUp={e => addFilterResponsables(label.uniqueId, label.label, e.target.previousSibling.checked)}
																					className="form-check-label"
																					title={label.label}
																					htmlFor={"inlineCheckbox" + label.uniqueId + index}
																				>{label.label}</label>
																			</li>
																			: null
																		// <th key={index} title={label.label.length > 15 ? label.label : ''}>{label.label.length > 15 ? label.label.substring(0, 15) + ' ...' : label.label}</th>
																	})
																	: null
															}
														</ul>
													</ul>
												</div>
											</div>
										</div>
										{
											tabname == 'All'
												?
												<div className='form-link-buttons-actions'>
													<div className="my-2 dropdown responses-dropdown-body export-dropdown pe-md-2 responses-filter-button" >
														<div className=''>
															<a href="#" role="button" id="Sources" data-bs-toggle="dropdown" className="form-link-button-body btn text-gray-700 border-gray-700 px-3 responses-filter-a  responsanses-tabs-download">
																<>
																	<div className="form-link-button-title-bottom form-link-button-title-hovered text-center download-text">Download responsess </div>
																	<span><i className="fas fa-download"></i></span>
																</>
																<svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 13 13">
																	<rect data-name="Icons/Tabler/Chevron Down background" width={13} height={13} fill="none" />
																	<path d="M.214.212a.738.738,0,0,1,.952-.07l.082.07L7.1,5.989a.716.716,0,0,1,.071.94L7.1,7.011l-5.85,5.778a.738.738,0,0,1-1.034,0,.716.716,0,0,1-.071-.94l.071-.081L5.547,6.5.214,1.233A.716.716,0,0,1,.143.293Z" transform="translate(13 3.25) rotate(90)" fill="#495057" />
																</svg>
															</a>
															<ul className="dropdown-menu dropdown-menu-end responses-filter-ul responses-dropdown-ul download-response-all" aria-labelledby="Sources" disabled>
																<li>
																	<div className=''>
																		<button type="button" className="p-0 m-0 d-flex align-items-center" onMouseDown={e => downloadAllResponsesInPdf(e)}>
																			<span><i className="fas fa-file-pdf"></i></span>
																			<div><h6 className='p-1'>PDF (Zip)</h6></div>
																		</button>
																	</div>
																</li>
																<li>
																	<div className=''>
																		<button type="button" className="p-0 m-0 d-flex align-items-center" onMouseDown={e => downloadResponses()}><span><i className="fas fa-download"></i></span>
																			{
																				csvDataAll
																					? <CSVLink {...csvDataAll}><div><h6 className='p-1'>CSV</h6></div></CSVLink>
																					: <div><h6 className='p-1'>CSV</h6></div>
																			}
																		</button>
																	</div>
																</li>
															</ul>
														</div>
													</div>
													<div className='form-link-button-body mx-2 archive-all-response'>
														<div className="form-link-button-title-bottom form-link-button-title-hovered text-center ">Archive All</div>
														<button type="button" className="p-0 m-0" onClick={e => archiveAllResponses(e)}><span><i className="fas fa-trash"></i></span></button>
													</div>
												</div>
												:
												tabname == 'Trash'
													?
													<div className='form-link-button-body form-link-buttons-actions'>
														<div className="form-link-button-title-bottom form-link-button-title-hovered text-center">Delete All</div>
														<button type="button" className="p-0 m-0" onClick={e => deleteAllResponse(e)}><span><i className="fas fa-times"></i></span></button>
													</div>
													: null
										}

									</div>
								</div>
								{
									tabname == 'All'
										?
										!loading ?
											<div>
												<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="modal-dialog" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
																<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																	<span aria-hidden="true">×</span>
																</button>
															</div>
															<div className="modal-body">

															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
																<button type="button" className="btn btn-dark">Save changes</button>
															</div>
														</div>
													</div>
												</div>
												<div className="table-responsive mb-0">

													{/* {console.log(datatable)} */}
													{/* <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} /> */}
													{
														responsesLoading
															?
															<div className="responses-loader">
																<Loader />
															</div>
															:
															<table className="table card-table table-nowrap">
																<thead>
																	<tr>
																		{/* <th>Response</th> */}
																		{
																			liveResponsable && liveResponsable.length > 0
																				?
																				liveResponsable.map((res, index) => {
																					return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																				})
																				:
																				allResponses.data
																					&& Object.values(allResponses.data).length > 0
																					&& allResponses.data[0]?.form?.responsables?.length > 0
																					?
																					allResponses.data[0].form.responsables.map((res, index) => {
																						return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																					})
																					:
																					labels ?
																						labels.map((label, index) => {
																							if (countLabels < 3) {
																								if (label.type == 'input' || label.type == 'text') {
																									countLabels++
																									return <th key={index} title={label.label.length > 15 ? label.label : ''}>{label.label.length > 15 ? label.label.substring(0, 15) + ' ...' : label.label}</th>
																								}
																							}
																						})
																						: null

																		}
																		{/* <th>Response ID</th> */}
																		<th>Start Date</th>
																		<th>End Date</th>
																		<th className="col-2">Actions</th>
																	</tr>
																</thead>
																<tbody className="list">
																	{

																		allResponses.data && Object.values(allResponses.data).length > 0
																			? Object.values(allResponses.data).map((response, index) => {
																				countValues = 0
																				return <tr className={
																					response.status == 'new'
																						? "response-tr new-response-tr"
																						: "response-tr"
																				} key={index}>
																					{
																						liveResponsable && liveResponsable.length > 0
																							?
																							liveResponsable.map((res, indexx) => {
																								if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																									return <td key={indexx}><div onClick={e => goResponse(e, response._id, false)} className={
																										response.status == 'new'
																											? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																											: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																									} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																								} else {
																									return <td key={indexx}><span className={
																										response.status == 'new'
																											? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																											: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																									}></span></td>
																								}
																							})
																							:
																							allResponses.data[0]?.form?.responsables?.length > 0
																								?
																								allResponses.data[0].form.responsables.map((res, indexx) => {
																									if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																										return <td key={indexx}><div onClick={e => goResponse(e, response._id, false)} className={
																											response.status == 'new'
																												? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																												: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																										} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																									} else {
																										return <td key={indexx}><span className={
																											response.status == 'new'
																												? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																												: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																										}></span></td>
																									}
																								})
																								:
																								labels.map((label, index) => {
																									if (countValues < 3) {
																										if (label.type == 'input' || label.type == 'text') {
																											if (response.response.hasOwnProperty(label.uniqueId)) {
																												countValues++
																												return <td key={index}><div onClick={e => goResponse(e, response._id, false)} className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																												} dangerouslySetInnerHTML={{ __html: response.response[label.uniqueId].value }}></div></td>
																											} else {
																												countValues++
																												return <td key={index}><span className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																												}></span></td>
																											}
																										}
																									}
																								})

																					}
																					<td>
																						<span className={
																							response.status == 'new'
																								? "font-weight-semibold text-gray-700 new-response"
																								: "font-weight-semibold text-gray-700"
																						}>{
																								response.started_at !== 'null'
																									? response.started_at
																									: ''

																							}
																						</span>
																					</td>
																					<td>
																						<span className={
																							response.status == 'new'
																								? "font-weight-semibold text-gray-700 new-response"
																								: "font-weight-semibold text-gray-700"
																						}>{response.created_at !== 'null'
																							? moment(response.created_at).format('DD/MM/YYYY HH:mm:ss')
																							: ''}
																						</span>
																					</td>
																					<td>
																						<div className="myForms-content-right right-myForms right-td-styles">
																							<div className="d-flex align-items-center">
																								<div className='form-link-buttons button-statistics'>
																									<div className='form-link-button-body'>
																										<div className="form-link-button-title-bottom form-link-button-title-hovered">See response</div>
																										<button onClick={e => goResponse(e, response._id, false)} className={
																											response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-eye"></i></span></button>
																									</div>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className='form-link-button-body'>
																										{
																											response.favourite == 1
																												?
																												<>
																													<div className="form-link-button-title-bottom form-link-button-title-hovered">Remove from favorite</div>
																													<button onClick={e => removeFavorite(e, response._id)} className={
																														response.status == 'new' ? 'btn form-link-button new-response favourite-response' : 'btn form-link-button favourite-response'}><span><i className="fas fa-star"></i></span></button>
																												</>
																												:
																												<>
																													<div className="form-link-button-title-bottom form-link-button-title-hovered">Add to favorite</div>
																													<button onClick={e => addFavorite(e, response._id)} className={
																														response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-star"></i></span></button>
																												</>
																										}
																									</div>
																								</div>
																							</div>
																							<div className="right-myForms-buttons dropdown">
																								<button onClick={e => addActive(e)} className="btn btn-dark-100 btn-icon btn-sm rounded-circle right-myForms-buttons-dropdown" >
																									<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
																										<rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
																										<path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6C757D" />
																									</svg>
																								</button>
																								{/* <div className="dropdown-menu dropdown-menu-end ">
																								<div className='d-flex'>
																								<div className='form-link-buttons button-link'>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Print response</div>
																									<button onClick={e => printResponse(e, response._id)} className={
																										response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-print"></i></span></button>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Download response(CSV)</div>
																									<button type="button" className="p-0 m-0" onClick={e => downloadResponse(e, response)}>
																										{
																											csvData
																												?
																												<CSVLink {...csvData} className={
																													response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-download"></i></span></CSVLink>
																												: <span><i className="fas fa-download"></i></span>
																										}
																									</button>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Edit</div>
																									<div className="response-download-response response-link-button-title-hovered"></div>
																									<button type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'} onClick={e => generatePdf(e, response._id)}><span><i className="fas fa-file-pdf"></i></span></button>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																									<div className="response-link-button-title response-link-button-title-hovered"></div>
																									<button type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'} link={APP_BASE_URL + "/response/" + response._id} onClick={copyLink}><span><i className=" fas fa-link forChangeIcon"></i></span></button>
																								</div>
																								<div className='form-link-buttons button-statistics'>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																									<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																									<button onClick={e => archiveResponse(e, response._id)} className={
																										response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-trash"></i></span></button>
																								</div>
																								</div>
																							</div> */}
																								<div className="animation-div">
																									<div className='d-flex align-items-center'>
																										<div className='form-link-buttons button-link'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Print response</div>
																												<button onClick={e => printResponse(e, response._id)} className={
																													response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-print"></i></span></button>
																											</div>
																										</div>
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Download response(CSV)</div>
																												<button type="button" className="p-0 m-0" onClick={e => downloadResponse(e, response)}>
																													{
																														csvData
																															?
																															<CSVLink {...csvData} className={
																																response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-download"></i></span></CSVLink>
																															: <span><i className="fas fa-download"></i></span>
																													}
																												</button>
																											</div>
																										</div>
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="response-download-response response-link-button-title-hovered"></div>
																												<button onMouseDown={e => generatePdf(e, response._id)} type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-file-pdf"></i></span></button>
																											</div>
																										</div>
																										<div className="form-link-buttons">
																											<div className='form-link-buttons button-link'>
																												<div className='form-link-button-body'>
																													<div className="response-link-button-title response-link-button-title-hovered"></div>
																													<button type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'} link={APP_BASE_URL + "/response/" + response._id} onClick={copyLink}><span><i className=" fas fa-link forChangeIcon"></i></span></button>
																												</div>
																											</div>
																										</div>
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																												<button onClick={e => archiveResponse(e, response._id)} className={
																													response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-trash"></i></span></button>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</td>
																				</tr>
																			})
																			: null
																	}
																</tbody>
															</table>
													}
												</div>
												{
													allResponses?.data?.length > 0
														?
														(
															allResponses.total > 24
																?
																<div className="d-flex align-items-center p-3 p-md-4 border-top border-gray-200 responses-table">
																	<div className="mx-auto pagination">
																		<Pagination
																			changePage={getData}
																			data={allResponses}
																			buttonIcons={true}
																		/>
																	</div>
																</div>
																: null
														)
														: <p className="p-4">No Data</p>
												}

											</div>
											:
											<div className="table-responsive mb-0">
												{/* <div className="col-xl-6 mb-4 form-content"> */}
												<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
													<Loader />
												</div>
												{/* </div> */}
											</div>

										: (
											tabname == 'Favorite'
												?
												<div>
													<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
														<div className="modal-dialog" role="document">
															<div className="modal-content">
																<div className="modal-header">
																	<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
																	<button type="button" className="close" data-dismiss="modal" aria-label="Close">
																		<span aria-hidden="true">×</span>
																	</button>
																</div>
																<div className="modal-body">

																</div>
																<div className="modal-footer">
																	<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
																	<button type="button" className="btn btn-dark">Save changes</button>
																</div>
															</div>
														</div>
													</div>
													{!loading ?
														<div className="table-responsive mb-0">

															{
																responsesLoading
																	?
																	<div className="responses-loader">
																		<Loader />
																	</div>
																	:
																	<table className="table card-table table-nowrap">
																		<thead>
																			<tr>
																				{/* <th>Response</th> */}
																				{
																					liveResponsable && liveResponsable.length > 0
																						?
																						liveResponsable.map((res, index) => {
																							return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																						})
																						:
																						allResponses.data
																							&& Object.values(allResponses.data).length > 0
																							&& allResponses.data[0]?.form?.responsables?.length > 0
																							?
																							allResponses.data[0].form.responsables.map((res, index) => {
																								return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																							})
																							:
																							labels.map((label, index) => {
																								if (countLabelsFavourite < 3) {
																									if (label.type == 'input' || label.type == 'text') {
																										countLabelsFavourite++
																										return <th key={index} title={label.label.length > 15 ? label.label : ''}>{label.label.length > 15 ? label.label.substring(0, 15) + ' ...' : label.label}</th>
																									}
																								}
																							})
																				}
																				<th>Start Date</th>
																				<th>End Date</th>
																				<th className="col-2">Actions</th>
																			</tr>
																		</thead>
																		<tbody className="list">
																			{
																				allFavorites.data && Object.values(allFavorites.data).length > 0
																					? Object.values(allFavorites.data).map((response, index) => {
																						countValuesFavourite = 0;
																						return <tr className={
																							response.status == 'new'
																								? "new-response-tr"
																								: ""
																						} key={index}>
																							{
																								liveResponsable && liveResponsable.length > 0
																									?
																									liveResponsable.map((res, indexx) => {
																										if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																											return <td key={indexx}><div onClick={e => goResponse(e, response._id, false)} className={
																												response.status == 'new'
																													? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																													: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																											} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																										} else {
																											return <td key={indexx}><span className={
																												response.status == 'new'
																													? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																													: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																											}></span></td>
																										}
																									})
																									:
																									allFavorites.data[0]?.form?.responsables?.length > 0
																										?
																										allFavorites.data[0].form.responsables.map((res, indexx) => {
																											if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																												return <td key={indexx}><div onClick={e => goResponse(e, response._id, false)} className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																												} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																											} else {
																												return <td key={indexx}><span className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																												}></span></td>
																											}
																										})
																										:
																										labels.map((label, index) => {
																											if (countValuesFavourite < 3) {
																												if (label.type == 'input' || label.type == 'text') {
																													if (response.response.hasOwnProperty(label.uniqueId)) {
																														countValuesFavourite++
																														return <td key={index}><div onClick={e => goResponse(e, response._id, false)} className={
																															response.status == 'new'
																																? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response text-decoration-underline"
																																: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																														} dangerouslySetInnerHTML={{ __html: response.response[label.uniqueId].value }}></div></td>
																													} else {
																														countValuesFavourite++
																														return <td key={index}><span className={
																															response.status == 'new'
																																? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response text-decoration-underline"
																																: "font-weight-semibold text-gray-700 response-styles response-link-text-style text-decoration-underline"
																														}></span></td>
																													}
																												}
																											}
																										})

																							}
																							<td>
																								<span className={
																									response.status == 'new'
																										? "font-weight-semibold text-gray-700 new-response"
																										: "font-weight-semibold text-gray-700"
																								}>{
																										response.started_at !== 'null'
																											? response.started_at
																											: ''
																									}
																								</span>
																							</td>
																							<td>
																								<span className={
																									response.status == 'new'
																										? "font-weight-semibold text-gray-700 new-response"
																										: "font-weight-semibold text-gray-700"
																								}>{response.created_at !== 'null'
																									? moment(response.created_at).format('DD/MM/YYYY HH:MM:SS')
																									: ''}
																								</span>
																							</td>
																							<td>
																								<div className="myForms-content-right right-myForms right-td-styles">
																									<div className="d-flex align-items-center">
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												<div className="form-link-button-title-bottom form-link-button-title-hovered">See response</div>
																												<button onClick={e => goResponse(e, response._id, false)} className={
																													response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-eye"></i></span></button>
																											</div>
																										</div>
																										<div className='form-link-buttons button-statistics'>
																											<div className='form-link-button-body'>
																												{
																													response.favourite == 1
																														?
																														<>
																															<div className="form-link-button-title-bottom form-link-button-title-hovered">Remove from favorite</div>
																															<button onClick={e => removeFavorite(e, response._id, 'favorite')} className={
																																response.status == 'new' ? 'btn form-link-button new-response favourite-response' : 'btn form-link-button favourite-response'}><span><i className="fas fa-star"></i></span></button>
																														</>
																														:
																														<>
																															<div className="form-link-button-title-bottom form-link-button-title-hovered">Add to favorite</div>
																															<button onClick={e => addFavorite(e, response._id)} className={
																																response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-star"></i></span></button>
																														</>
																												}
																											</div>
																										</div>
																									</div>
																									<div className="right-myForms-buttons dropdown favorite-dots">
																										<button onClick={e => addActive(e)} className="btn btn-dark-100 btn-icon btn-sm rounded-circle right-myForms-buttons-dropdown" >
																											<svg data-name="Icons/Tabler/Notification" xmlns="http://www.w3.org/2000/svg" width="13.419" height="13.419" viewBox="0 0 13.419 13.419">
																												<rect data-name="Icons/Tabler/Dots background" width="13.419" height="13.419" fill="none" />
																												<path d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z" transform="translate(5.368 0.839)" fill="#6C757D" />
																											</svg>
																										</button>
																										<div className="animation-div">
																											<div className='d-flex'>
																												<div className='form-link-buttons button-link'>
																													<div className='form-link-button-body'>
																														<div className="form-link-button-title-bottom form-link-button-title-hovered">Print response</div>
																														<button onClick={e => printResponse(e, response._id)} className={
																															response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-print"></i></span></button>
																													</div>
																												</div>
																												<div className="form-link-buttons">
																													<div className='form-link-buttons button-link'>
																														<div className='form-link-button-body'>
																															<div className="response-link-button-title response-link-button-title-hovered"></div>
																															<button type='button' className={response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'} link={APP_BASE_URL + "/response/" + response._id} onClick={copyLink}><span><i className=" fas fa-link forChangeIcon"></i></span></button>
																														</div>
																													</div>
																												</div>
																												<div className='form-link-buttons button-statistics'>
																													<div className='form-link-button-body'>
																														<div className="form-link-button-title-bottom form-link-button-title-hovered">Archive</div>
																														<button onClick={e => archiveResponse(e, response._id, 'favorite')} className={
																															response.status == 'new' ? 'btn form-link-button new-response' : 'btn form-link-button'}><span><i className="fas fa-trash"></i></span></button>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</td>
																						</tr>
																					})
																					: null
																			}
																		</tbody>
																	</table>
															}
														</div>
														: <div className="table-responsive mb-0">
															{/* <div className="col-xl-6 mb-4 form-content"> */}
															<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
																<Loader />
															</div>
															{/* </div> */}
														</div>}
													{
														allFavorites
															?
															(
																allFavorites.total > 24
																	?
																	!loading ? (
																		<div className="d-flex align-items-center p-3 p-md-4 border-top border-gray-200 responses-table">
																			<div className="mx-auto pagination">
																				<Pagination
																					changePage={getData}
																					data={allFavorites}
																					buttonIcons={true}
																				/>
																			</div>
																		</div>
																	) : ''
																	: null
															)
															: <p className="p-4">No Data</p>
													}
												</div>
												:
												<div>
													{!loading ?
														<div className="table-responsive mb-0">
															{
																responsesTrashedLoading
																	?
																	<div className="responses-loader">
																		<Loader />
																	</div>
																	:

																	<table className="table card-table table-nowrap">
																		<thead>
																			<tr>
																				{
																					liveResponsable && liveResponsable.length > 0
																						?
																						liveResponsable.map((res, index) => {
																							return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																						})
																						:
																						allTrashedResponses.data
																							&& Object.values(allTrashedResponses.data).length > 0
																							&& allTrashedResponses.data[0]?.form?.responsables?.length > 0
																							?
																							allTrashedResponses.data[0].form.responsables.map((res, index) => {
																								return <th key={index} title={Object.keys(res)[0].length > 15 ? res[1] : ''}>{res[Object.keys(res)[0]].length > 15 ? res[Object.keys(res)[0]].substring(0, 15) + ' ...' : res[Object.keys(res)[0]]}</th>
																							})
																							:
																							labels
																								?
																								labels.map((label, index) => {
																									if (countLabelsTrashed < 3) {
																										if (label.type == 'input' || label.type == 'text') {
																											countLabelsTrashed++
																											return <th key={index} title={label.label.length > 15 ? label.label : ''}>{label.label.length > 15 ? label.label.substring(0, 15) + ' ...' : label.label}</th>
																										}
																									}
																								})
																								: null
																				}
																				{/* <th>Response ID</th> */}
																				<th>Start Date</th>
																				<th>End Date</th>
																				<th>Deleted Date</th>
																				<th className="col-2">Actions</th>
																			</tr>
																		</thead>
																		<tbody className="list">
																			{

																				allTrashedResponses.data && Object.values(allTrashedResponses.data).length > 0
																					? Object.values(allTrashedResponses.data).map((response, index) => {
																						countValuesTrashed = 0
																						return <tr className={
																							response.status == 'new'
																								? "new-response-tr"
																								: ""
																						} key={index}>
																							{
																								liveResponsable && liveResponsable.length > 0
																									?
																									liveResponsable.map((res, indexx) => {
																										if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																											return <td key={indexx}><div className={
																												response.status == 'new'
																													? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																													: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																											} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																										} else {
																											return <td key={indexx}><span className={
																												response.status == 'new'
																													? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																													: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																											}></span></td>
																										}
																									})
																									:
																									allTrashedResponses.data[0]?.form?.responsables?.length > 0
																										?
																										allTrashedResponses.data[0].form.responsables.map((res, indexx) => {
																											if (response.response.hasOwnProperty(Object.keys(res)[0])) {
																												return <td key={indexx}><div className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																												} dangerouslySetInnerHTML={{ __html: response.response[Object.keys(res)[0]].value }}></div></td>
																											} else {
																												return <td key={indexx}><span className={
																													response.status == 'new'
																														? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																														: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																												}></span></td>
																											}
																										})
																										:
																										labels.map((label, index) => {
																											if (countValuesTrashed < 3) {
																												if (label.type == 'input' || label.type == 'text') {
																													if (response.response.hasOwnProperty(label.uniqueId)) {
																														countValuesTrashed++
																														return <td key={index}><div className={
																															response.status == 'new'
																																? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																																: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																														} dangerouslySetInnerHTML={{ __html: response.response[label.uniqueId].value }}></div></td>
																													} else {
																														countValuesTrashed++
																														return <td key={index}><span className={
																															response.status == 'new'
																																? "font-weight-semibold text-gray-700 response-styles response-link-text-style new-response"
																																: "font-weight-semibold text-gray-700 response-styles response-link-text-style"
																														}></span></td>
																													}
																												}
																											}
																										})

																							}
																							<td>
																								<span className={
																									response.status == 'new'
																										? "font-weight-semibold text-gray-700 new-response"
																										: "font-weight-semibold text-gray-700"
																								}>
																									{
																										response.started_at !== 'null'
																											? response.started_at
																											: ''
																									}</span>
																							</td>
																							<td>
																								<span className={
																									response.status == 'new'
																										? "font-weight-semibold text-gray-700 new-response"
																										: "font-weight-semibold text-gray-700"
																								}>
																									{
																										response.created_at !== 'null'
																											? moment(response.created_at).format('DD/MM/YYYY HH:MM:SS')
																											: ''

																									}
																								</span>
																							</td>
																							<td>
																								<span className={
																									response.status == 'new'
																										? "font-weight-semibold text-gray-700 new-response"
																										: "font-weight-semibold text-gray-700"
																								}>{response.deleted_at !== 'null'
																									? moment(response.deleted_at).format('DD/MM/YYYY')
																									: ''}
																								</span>
																							</td>
																							<td className="col-2">
																								<div className="d-flex align-items-center">
																									<div className='form-link-buttons button-statistics'>
																										<div className='form-link-button-body'>
																											<div className="form-link-button-title-bottom form-link-button-title-hovered">Delete</div>
																											<button onClick={e => deleteResponse(e, response._id)} className='btn form-link-button'><span><i className="fas fa-times"></i></span></button>
																										</div>
																									</div>
																									<div className='form-link-buttons button-statistics'>
																										<div className='form-link-button-body'>
																											<div className="form-link-button-title-bottom form-link-button-title-hovered">Restore</div>
																											<button onClick={e => unArchiveResponse(e, response._id)} className='btn form-link-button'><span><img src="/svg/icons/rotate-right-solid.svg" className={'svg-icon-default'} width="16" alt="" /></span></button>
																										</div>
																									</div>
																								</div>

																							</td>
																						</tr>
																					})
																					: null
																			}
																		</tbody>
																	</table>
															}
														</div>
														: <div className="table-responsive mb-0">
															{/* <div className="col-xl-6 mb-4 form-content"> */}
															<div className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
																<Loader />
															</div>
															{/* </div> */}
														</div>}
													{
														allTrashedResponses
															?
															(
																allTrashedResponses.total > 24
																	?
																	!loading ? (
																		<div className="d-flex align-items-center p-3 p-md-4 border-top border-gray-200 responses-table">
																			<div className="mx-auto pagination">
																				<Pagination
																					changePage={getData}
																					data={allTrashedResponses}
																					buttonIcons={true}

																				/>
																			</div>
																		</div>
																	) : ''
																	: null
															)
															: <p className="p-4">No Data</p>
													}
												</div>

										)
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default Response;