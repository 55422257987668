import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import MyButton from '../../../UI/button/MyButton';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free';
import { AuthContext } from '../../../context';
import { eraseCookie, getCookie } from '../../../cookie';
import { useSelector } from 'react-redux';
import { getAdmInfo, getAdminInfoData } from '../../../redux/reducers/adminReducer';

const AdminInfo = (props) => {
	const { isAuthAdmin, setIsAuthAdmin } = useContext(AuthContext);
	const infoAdmin = useSelector(getAdminInfoData)
	
	const logout = () => {
		if (getCookie('_rmfo')) { eraseCookie('_rmfo') }
		localStorage.removeItem('authAdmin')
		localStorage.removeItem('authUser')
		setIsAuthAdmin(false)
		window.location.href = window.location.href.split('/evi-admin')[0] + '/evi-admin'
	}
	
	return (
		<div className="home__info" >
			<div className="home__info-body">
				<div className="home__info-top top-info">
					<div className="top-info-content">
						<div className="bottom-info__name_surname bottom-info__column">
							<p className="bottom-info-name "> {infoAdmin.email}</p>
						</div>
					</div>
				</div>
				<div className="home__info-bottom bottom-info">
					<button className="_btn" onClick={logout}>
						<img src="/svg/icons/logout.svg" alt="" />
					</button>
				</div>
			</div>
			<div className="header-menu__icon">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div >
	);
};

export default AdminInfo;