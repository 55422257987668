import React, {useContext} from "react";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../../context";
import MyButton from "../button/MyButton";


const AdminNavbar = () => {
    const {isAuthAdmin, setIsAuthAdmin} = useContext(AuthContext);

    // const logout = () => {
    // 	setIsAuthAdmin(false)
    // 	localStorage.removeItem('authAdmin')
    // 	window.location.href = window.location.href.split('/evi-admin')[0] + '/evi-admin'
    // }
    // var pageNowName = window.location.pathname
    // useEffect(() => {
    // 	var splittedPagNow = pageNowName.split('/')
    // 	var linknow = splittedPagNow[splittedPagNow.length - 1]
    // 	console.log(linknow);
    // 	const navItems = document.querySelectorAll('.navbar-admin__link')
    // 	for (let i = 0; i < navItems.length; i++) {
    // 		if (navItems[i].getAttribute('linkname') == linknow) {
    // 			console.log(navItems[i].children[0]);
    // 			navItems[i].children[0].classList.add('_active')
    // 		}

    // 	}
    // $(`.nav-item a[href="${pageNowName}"]`).addClass('active')
    // $(`.nav-item a[href="${pageNowName}"]`).closest('div').addClass('show')
    // $(`.nav-item a[href="${pageNowName}"]`).closest('ul').closest('li').addClass('active')
    // }, [pageNowName])
    return (
        isAuthAdmin ?
            <div className="navbar-admin header-menu">
                <div className="navbar-admin__links">
                    <div className="navbar-admin__link" linkname='statistics'>
                        <Link to="/evi-admin/statistics">
                            <img src="/svg/icons/chart-line-solid.svg" alt=""/><span> Statistics</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='users'>
                        <Link to="/evi-admin/users">
                            <img src="/svg/icons/users-solid.svg" alt=""/><span> Users</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='forms'>
                        <Link to="/evi-admin/forms">
                            <img src="/svg/icons/wpforms-brands.svg" alt=""/><span> Forms</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='responses'>
                        <Link to="/evi-admin/responses">
                            <img src="/svg/icons/square-poll-horizontal-solid.svg" alt=""/><span> Responses</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='messages'>
                        <Link to="/evi-admin/messages">
                            <img src="/svg/icons/mail.svg" alt=""/><span> Contact us</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='customers'>
                        <Link to="/evi-admin/customers">
                            <img src="/svg/icons/headset-solid.svg" alt=""/><span>Support</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='suggestions'>
                        <Link to="/evi-admin/suggestions">
                            <img src="/svg/icons/suggestion.svg" alt=""/><span>Suggestions</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='reports'>
                        <Link to="/evi-admin/reports">
                            <img src="/svg/icons/docs.svg" alt=""/><span>Reports</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='payments'>
                        <Link to="/evi-admin/payments">
                            <img src="/svg/icons/payment.svg" alt=""/><span>Payment</span>
                        </Link>
                    </div>
                    <div className="navbar-admin__link" linkname='error-logs'>
                        <Link to="/evi-admin/error-logs">
                            <img src="/svg/icons/triangle-exclamation-solid.svg" alt=""/><span> Error logs</span>
                        </Link>
                    </div>
                </div>
                {/* <button className="_btn" onClick={logout}>
					Logout
				</button> */}

            </div>
            : <div></div>
    )
}

export default AdminNavbar;