import React, { useContext, useEffect, useState } from 'react';
import AdminInfo from './components/AdminInfo';
import AdminData from './components/AdminData';
import AdminForm from './components/AdminForm';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import { AuthContext } from '../../context';
import { API_BASE_URL, AxiosConfigs } from '../../config';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmInfo } from '../../redux/reducers/adminReducer';


const AdminHome = () => {
	const homeElement = useRef()
	const dispatch = useDispatch()
	const { isAuthAdmin, setIsAuthAdmin } = useContext(AuthContext)
	if (localStorage.getItem('auth')) {
		localStorage.removeItem('auth')
	}
	useEffect(() => {
		dispatch(getAdmInfo())
	}, [])
	return (
		<div className="home public-home" ref={homeElement}>
			<AdminInfo />
			<AdminData homeElement={homeElement} />
		</div >
	);
};

export default AdminHome;