import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormData from 'form-data';
import axios from 'axios';
import { AuthContext } from "../context";
import { useParams } from 'react-router';
import SignupForm from './SignupForm';
import SignupFormGoogle from './SignupFormGoogle';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import SignupFormFacebook from './SignupFormFacebook';
import Loader from '../UI/Loader/Loader';
import SigninFormGoogle from './SigninFormGoogle';

const Signup = () => {

	const { isAuth, setIsAuth, isAuthAdmin, setIsAuthAdmin } = useContext(AuthContext)
	const [signupData, setSignupData] = useState({ confirm: true });
	const [files, setFiles] = useState([]);
	const [errors, setErrors] = useState([]);
	const params = useParams();
	const signupType = params.type ? params.type : '';
	const [loading, setLoading] = useState(false)
	const signup = e => {
		setLoading(true)
		e.preventDefault()
		let data = new FormData();
		for (var key in signupData) {
			data.append(key, signupData[key]);
		}
		if (files[0]) {
			data.append('avatar', files[0].file);
		}
		if (signupType !== '') {
			data.append('signupType', signupType);
		}
		axios.post(API_BASE_URL + '/api/signupUser', data)
			.then(response => {
				setLoading(false)
				localStorage.setItem('verify', 'true')
				localStorage.setItem('verifyEmail', signupData['email'])

				if(localStorage.getItem('authUser')){

					localStorage.removeItem('authUser')
				}


				window.location.href = APP_BASE_URL + '/verify'
			})
			.catch(error => {
				setLoading(false)
				setErrors(error.response.data['errors'])
			})
	}

	return (
		<div className="signup-template bg-gray-100" style={{ backgroundColor: '#fff' }}>
			<div className="position-absolute top-0 start-0 p-4 p-md-5">
				<a href="http://formbuilder.ai/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></a>
			</div>
			<div className="row g-0 align-items-center" >
				<div className="col-lg-5" style={{ textAlign: 'center', backgroundColor: '#FBF3E5' }}>
					<img src="/img/BG/sign-in.svg" className="cover-fit" alt="Sign in Cover" />
				</div>
				<div className="col-lg-7 px-md-3 px-xl-5">
					<div>
						{
							loading
								?
								<div className="signupForm-loader">
									<Loader />
								</div>
								:
								null
						}

						<div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
							<div className="login-form py-2 py-md-0 mx-auto mx-lg-0" style={{ margin: 'auto' }}>
								<h2 className="h1 mb-2">Create Account</h2>
								<SignupFormGoogle isAuthAdmin={isAuthAdmin} setIsAuthAdmin={setIsAuthAdmin} isAuth={isAuth} setIsAuth={setIsAuth} />
								<div className="position-relative">
									<hr className="bg-gray-200 border-gray-200 opacity-100" />
									<span className="position-absolute top-0 start-50 translate-middle text-gray-600 small bg-gray-100 px-2 px-xxl-4 text-nowrap">Or sign up with email</span>
								</div>
								{/* <SignupFormGoogle /> */}
								{/* <SignupFormFacebook /> */}
								{/* <div className="position-relative">
										<hr className="bg-gray-200 border-gray-200 opacity-100" />
										<span
											className="position-absolute top-0 start-50 translate-middle text-gray-600 small bg-gray-100 px-2 px-xxl-4 text-nowrap">Or
											sign up with email</span>
									</div> */}
								<SignupForm signupData={signupData} setSignupData={setSignupData} signup={signup} files={files} setFiles={setFiles} errors={errors} setErrors={setErrors} />
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
};

export default Signup;