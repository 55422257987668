import React from 'react'
import { saveAs } from 'file-saver'
import Button from 'react-bootstrap/esm/Button'
import moment from 'moment'

const DownloadQr = (props) => {
    const {src, formTitle} = props
    let dateNow = moment().format("DD-MM-YYYY");
    const downloadImage = () => {
        saveAs( src, `${formTitle + dateNow}.jpg`) 
    }

  return <Button className='btn btn-lg btn-warning text-white' onClick={downloadImage}>Download!</Button>
}

export default DownloadQr;