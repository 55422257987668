import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	MailruIcon,
	MailruShareButton,
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import ShareButton from 'react-share/lib/ShareButton';
import { APP_BASE_URL } from '../../../config';
import QrCode from './QrCode';
import ShareIframe from './ShareIframe';

const ShareSocialIcons = (props) => {
	const {formLink, formTitle} = props

	const addActive = e => {
		e.preventDefault()
		const toggledButtons = document.querySelectorAll('.animation-div')
		var bool = false;
		if (e.currentTarget.nextSibling.classList.contains('_active')) { bool = true; }
		toggledButtons.forEach(element => element.classList.remove('_active'));
		if (bool) { e.currentTarget.nextSibling.classList.remove('_active') }
		else { e.currentTarget.nextSibling.classList.add('_active') }

	}

	return (
		<div className="myForms-content-right right-myForms right-td-styles">
			<div className="right-myForms-buttons dropdown">
				<button onClick={e => addActive(e)} title="Share Icons" className="btn btn-icon btn-sm rounded-circle right-myForms-buttons-dropdown" >
					<img src="/svg/icons/socialshare.svg" alt="" />
				</button>
				<div className="animation-div">
					<div className='d-flex align-items-center'>
						<EmailShareButton 
							url={APP_BASE_URL + '/f-' + formLink}
							className="share-button-styles"
						>
							<EmailIcon size={27} round />
						</EmailShareButton>
						{/* <a className="video-email_button button-hover" target="_top" href={`mailto:?subject=I wanted you to see this site&body=Check out this video https://youtu.be/yul2-4mc6mg`} title="Share viaEmail">
							<span className="video-email_button-text">1</span>
						</a> */}
						<FacebookShareButton
							url={APP_BASE_URL + '/f-' + formLink}
							className="share-button-styles"
							// quote={"A form which has been created on Form Builder. "}
							// hashtag={"#FormBuilder"}
							// className="Demo__some-network__share-button"
						>
							<FacebookIcon size={27} round />
						</FacebookShareButton>
						<LinkedinShareButton

							url={APP_BASE_URL + '/f-' + formLink}
							className="share-button-styles"
						>
							<LinkedinIcon size={27} round />
						</LinkedinShareButton>
						<TwitterShareButton
							url={APP_BASE_URL + '/f-' + formLink}
							className="share-button-styles"
						>
							<TwitterIcon size={27} round />
						</TwitterShareButton>
						<ShareIframe formTitle={formTitle} formLink={formLink}/>
						<QrCode formTitle={formTitle} formLink={formLink}/>

						{/* <div className='form-link-buttons button-statistics'>
							<div className='form-link-button-body'>
								<div className="response-share-email response-link-button-title-hovered"></div>
								<button type='button' className={'btn form-link-button'}><span><img src="/svg/icons/email.svg" alt="" /> </span></button>
							</div>
						</div> */}
						{/* <div className='form-link-buttons button-statistics'>
							<div className='form-link-button-body'>
								<div className="response-share-facebook response-link-button-title-hovered"></div>
								<button type='button' className={'btn form-link-button'}><span><img src="/svg/icons/fb.svg" alt="" /> </span></button>
							</div>
						</div> */}
						{/* <div className='form-link-buttons button-statistics'>
							<div className='form-link-button-body'>
								<div className="response-share-linkedin response-link-button-title-hovered"></div>
								<button type='button' className={'btn form-link-button'}><span><img src="/svg/icons/linkedin-brands.svg" alt="" /> </span></button>
							</div>
						</div>
						<div className='form-link-buttons button-statistics'>
							<div className='form-link-button-body'>
								<div className="response-share-twitter response-link-button-title-hovered"></div>
								<button type='button' className={'btn form-link-button'}><span><img src="/svg/icons/twitt.svg" alt="" /> </span></button>
							</div>
						</div>
						<div className='form-link-buttons button-statistics'>
							<div className='form-link-button-body'>
								<div className="response-share-qrcode response-link-button-title-hovered"></div>
								<button type='button' className={'btn form-link-button'}><span><img src="/svg/icons/qrcode.svg" alt="" /> </span></button>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShareSocialIcons;