import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL, APP_BASE_URL,AxiosConfigs } from '../../config';
import { getInfoData } from '../../redux/reducers/infoReducer';

const ProfilePlan = () => {

    const myInfo = useSelector(getInfoData);

    const change = e => {
        axios.post(API_BASE_URL + '/api/planPricing',myInfo)
        .then(response => {
            window.location.href = APP_BASE_URL + "/form"
        })
        .catch(error => {
        })
    }
    const changePlan = (e, type) => {
        axios.post(API_BASE_URL + '/api/generate-payment-token', {}, AxiosConfigs).then(r => {
            if (type === 'Standart') {
                window.open(`https://checkout.bluesnap.com/buynow/checkout?enc=${r.data}&sku4025890=1&storeid=625371`)
            }
            if (type === 'Premium') {
                window.open(`https://checkout.bluesnap.com/buynow/checkout?enc=${r.data}&sku4026266=1&storeid=625371`)
            }
        })
    }

    return (
        <div tabname="Plan" className="settings-tab-content card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5">
            <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                <h5 className="card-header-title my-2 ps-md-3 font-weight-semibold">Subscription plans</h5>
            </div>
            {
               !myInfo.plan_pricing ? 
            <div className="card-body px-0 px-md-4 py-0">
                <div className="px-3 pricing-table-area-four">
                    <div className="row justify-content-center py-4">
                          <div onClick={change} className={ myInfo.plan === 'Free' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" } >
                            <div className="pr-table-wrapper position-relative">
                                <div className="pack-name pb-50">FREE</div>
                                <div className="top-banner d-md-flex" style={{ background: '#FFF7EB' }}>
                                    <div className="price"><sup>$</sup>0</div>
                                </div>
                                <div>
                                    <ul className="pr-feature first_pr_feature">
                                        <li>1 Form</li>
                                        <li>10 responses</li>
                                        <li>Unlimited questions</li>
                                        <li>Customization</li>
                                        <li>Automatic notifications</li>
                                        <li>Analytics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className={ myInfo.plan === 'Standart' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" }>
                            <div className="pr-table-wrapper active most-popular">
                                <div className="pack-name pb-50">Standard</div>
                                <div className="top-banner d-md-flex" style={{ background: '#E2F2FD' }}>
                                    <div className="price"><sup>$</sup>4.<sup>99</sup>
                                        <p className="price-text-right">per/month</p>
                                    </div>
                                </div>
                                <div>
                                    <ul className="pr-feature">
                                        <li>5 Forms</li>
                                        <li>Unlimited responses</li>
                                        <li>Unlimited questions</li>
                                        <li>Customization</li>
                                        <li>Automatic notifications</li>
                                        <li>Analytics</li>
                                    </ul>
                                </div>
                                <div className="position-sticky pt-50" style={{ top: '100%', borderTop: '1px solid #e8e8e8' }}>
                                    { myInfo.plan === 'Standart' 
                                        ? myInfo.blocked == 1 
                                            ? <button type="button" onClick={e => changePlan(e, 'Standart')} className="btn btn-lg preview-button-header">Continue</button> 
                                            : null
                                        : <button type="button" onClick={e => changePlan(e, 'Standart')} className="btn btn-lg preview-button-header">Choose the plan</button> 
                                        // <a href="https://checkout.bluesnap.com/buynow/checkout?sku4026266=1&storeid=625371" target="_blank" className="btn btn-lg preview-button-header">Set plan</a>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={ myInfo.plan === 'Premium' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" }>
                            <div className="pr-table-wrapper">
                                <div className="pack-name pb-50">Premium</div>
                                <div className="top-banner d-md-flex" style={{ background: '#FFEBEB' }}>
                                    <div className="price"><sup>$</sup>21
                                        <p className="price-text-right">per/month</p>
                                    </div>
                                </div>
                                <div>
                                    <ul className="pr-feature">
                                        <li>Unlimited Forms</li>
                                        <li>Unlimited responses</li>
                                        <li>Unlimited questions</li>
                                        <li>Customization</li>
                                        <li>Automatic notifications</li>
                                        <li>Analytics</li>
                                    </ul>
                                </div>
                                <div className="position-sticky pt-50" style={{ top: '100%', borderTop: '1px solid #e8e8e8' }}>
                                    { myInfo.plan === 'Premium' 
                                        ? myInfo.blocked == 1
                                            ? <button type="button" onClick={e => changePlan(e, 'Premium')} className="btn btn-lg preview-button-header">Continue</button> 
                                            : null
                                        : <button type="button" onClick={e => changePlan(e, 'Premium')} className="btn btn-lg preview-button-header">Choose the plan</button> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="card-body px-0 px-md-4 py-0">
            <div className="px-3 pricing-table-area-four">
                <div className="row justify-content-center py-4">
                      <div className={ myInfo.plan_pricing === 'Free' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" } >
                        <div className="pr-table-wrapper position-relative">
                            <div className="pack-name pb-50">FREE</div>
                            <div className="top-banner d-md-flex" style={{ background: '#FFF7EB' }}>
                                <div className="price"><sup>$</sup>0</div>
                            </div>
                            <div>
                                <ul className="pr-feature first_pr_feature">
                                    <li>1 Form</li>
                                    <li>10 responses</li>
                                    <li>Unlimited questions</li>
                                    <li>Customization</li>
                                    <li>Automatic notifications</li>
                                    <li>Analytics</li>
                                </ul>
                            </div>
                            <div className="position-sticky pt-50">
                                
                            </div>
                            <div className="position-sticky pt-50" style={{ top: '100%', borderTop: '1px solid #e8e8e8' }}>
                                <button onClick={change} className="btn btn-lg preview-button-header">Start for Free</button>
                            </div>
                        </div>
                    </div>
                    
                    <div className={ myInfo.plan_pricing === 'Standart' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" }>
                        <div className="pr-table-wrapper active most-popular">
                            <div className="pack-name pb-50">Standard</div>
                            <div className="top-banner d-md-flex" style={{ background: '#E2F2FD' }}>
                                <div className="price"><sup>$</sup>4.<sup>99</sup>
                                    <p className="price-text-right">per/month</p>
                                </div>
                            </div>
                            <div>
                                <ul className="pr-feature">
                                    <li>5 Forms</li>
                                    <li>Unlimited responses</li>
                                    <li>Unlimited questions</li>
                                    <li>Customization</li>
                                    <li>Automatic notifications</li>
                                    <li>Analytics</li>
                                </ul>
                            </div>
                            <div className="position-sticky pt-50" style={{ top: '100%', borderTop: '1px solid #e8e8e8' }}>
                                { myInfo.plan_pricing === 'Standart' 
                                    ?  <button type="button" onClick={e => changePlan(e, 'Standart')} className="btn btn-lg preview-button-header">Continue</button> 
                                      
                                    : <button type="button" onClick={e => changePlan(e, 'Standart')} className="btn btn-lg preview-button-header">Choose the plan</button> 
                                    // <a href="https://checkout.bluesnap.com/buynow/checkout?sku4026266=1&storeid=625371" target="_blank" className="btn btn-lg preview-button-header">Set plan</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={ myInfo.plan_pricing === 'Premium' ? "col-xl-4 col-sm-12 col-md-6 col-lg-6 selected-package pr-table-package" : "col-xl-4 col-sm-12 col-md-6 col-lg-6 pr-table-package" }>
                        <div className="pr-table-wrapper">
                            <div className="pack-name pb-50">Premium</div>
                            <div className="top-banner d-md-flex" style={{ background: '#FFEBEB' }}>
                                <div className="price"><sup>$</sup>21
                                    <p className="price-text-right">per/month</p>
                                </div>
                            </div>
                            <div>
                                <ul className="pr-feature">
                                    <li>Unlimited Forms</li>
                                    <li>Unlimited responses</li>
                                    <li>Unlimited questions</li>
                                    <li>Customization</li>
                                    <li>Automatic notifications</li>
                                    <li>Analytics</li>
                                </ul>
                            </div>
                            <div className="position-sticky pt-50" style={{ top: '100%', borderTop: '1px solid #e8e8e8' }}>
                                { myInfo.plan_pricing === 'Premium' 
                                    ? <button type="button" onClick={e => changePlan(e, 'Premium')} className="btn btn-lg preview-button-header">Continue</button> 
                                     
                                    : <button type="button" onClick={e => changePlan(e, 'Premium')} className="btn btn-lg preview-button-header">Choose the plan</button> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            }

        </div>
    );
};

export default ProfilePlan;