import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, AxiosConfigs } from '../../config';
import { getGeneralInfo, getGeneralInfoData, getInfo, getInfoData, setGeneralInfoChangeFullName, setMe } from '../../redux/reducers/infoReducer';
import BasicEdit from './EditComponents/BasicEdit';
import GeneralEdit from './EditComponents/GeneralEdit';
import PasswordEdit from './EditComponents/PasswordEdit';

const ProfileEdit = (props) => {
	// const { myInfoDetails, setMyInfoDetails } = props;
	const myInfo = useSelector(getInfoData)
	const myInfoDetails = useSelector(getGeneralInfoData)
	const [errorsGeneral, setErrorsGeneral] = useState([]);
	const [errorsBasic, setErrorsBasic] = useState([]);
	const [errorsPassword, setErrorsPassword] = useState([]);
	const [updateDataGeneral, setUpdateDataGeneral] = useState({});
	const [updateDataBasic, setUpdateDataBasic] = useState({});
	const [updateDataPassword, setUpdateDataPassword] = useState({});
	const [basicChanged, setBasicChanged] = useState(false);
	const [generalChanged, setGeneralChanged] = useState(false);
	const [passwordChanged, setPasswordChanged] = useState(false);
	const formRef = useRef();
	const dispatch = useDispatch()
	useEffect(() => {
		setUpdateDataGeneral({
			firstname: myInfo.firstname,
			lastname: myInfo.lastname,
			email: myInfo.email,
			// username: myInfo.username,
		});
	}, [myInfo])
	const updateUserGeneralDetails = e => {
		axios.post(API_BASE_URL + '/api/UpdateMyGeneralDetails', ['', updateDataGeneral], AxiosConfigs)
			.then(response => {
				dispatch(setGeneralInfoChangeFullName({lastName:updateDataGeneral.lastname, firstName:updateDataGeneral.firstname}));
				// var newMyInfo = response.data;
				// window.location.href = window.location.href.split('/')[0] + 'signin'
				// const myInfoCopy = JSON.parse(JSON.stringify(myInfo));
				// Object.keys(newMyInfo).map((key, value) => {
				// 	myInfoCopy[key] = newMyInfo[key]
				// })
				// dispatch(setMe(newMyInfo))
				setGeneralChanged(true)
			})
			.catch(error => {
				// console.log(error);
				console.log(error.response.data);
				setErrorsGeneral(error.response.data.error)
			})
	}
	const updateUserBasicDetails = e => {
		axios.post(API_BASE_URL + '/api/UpdateMyBasicDetails', ['', updateDataBasic], AxiosConfigs)
			.then(response => {
				// var newMyInfo = response.data;
				// const myInfoCopy = JSON.parse(JSON.stringify(myInfo));
				// Object.keys(newMyInfo).map((key, value) => {
				// 	myInfoCopy[key] = newMyInfo[key]
				// })
				// dispatch(getGeneralInfoData(myInfoCopy))
				// setBasicChanged(true)
			})
			.catch(error => {
				setErrorsBasic(error.response.data.error)
			})
	}
	const updateUserPasswordDetails = e => {
		axios.post(API_BASE_URL + '/api/UpdateMyPasswordDetails', ['', updateDataPassword], AxiosConfigs)
			.then(response => {
				setPasswordChanged(true)
			})
			.catch(error => {
				setErrorsPassword(error.response.data.error)
			})
	}
	return (
		<div>
			<GeneralEdit
				updateUserGeneralDetails={updateUserGeneralDetails}
				errorsGeneral={errorsGeneral}
				setErrorsGeneral={setErrorsGeneral}
				updateDataGeneral={updateDataGeneral}
				setUpdateDataGeneral={setUpdateDataGeneral}
				generalChanged={generalChanged}
				setGeneralChanged={setGeneralChanged}
			/>
			{/* <BasicEdit
				updateUserBasicDetails={updateUserBasicDetails}
				errorsBasic={errorsBasic}
				setErrorsBasic={setErrorsBasic}
				updateDataBasic={updateDataBasic}
				setUpdateDataBasic={setUpdateDataBasic}
				myInfo={myInfo}
				setMyInfo={setMyInfo}
				myInfoDetails={myInfoDetails}
				setMyInfoDetails={setMyInfoDetails}
				basicChanged={basicChanged}
				setBasicChanged={setBasicChanged}
			/> */}
			<PasswordEdit
				updateUserPasswordDetails={updateUserPasswordDetails}
				errorsPassword={errorsPassword}
				setErrorsPassword={setErrorsPassword}
				updateDataPassword={updateDataPassword}
				setUpdateDataPassword={setUpdateDataPassword}
				passwordChanged={passwordChanged}
				setPasswordChanged={setPasswordChanged}
				formRef={formRef}
			/>
		</div>
	);
};

export default ProfileEdit;