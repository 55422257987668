import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import QRCode from 'qrcode';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import { APP_BASE_URL } from '../../../config';
import DownloadQr from './DownloadQr';

const QrCode = (props) => {
	const { formLink, formTitle } = props
	const [show, setShow] = useState(false);
	const [src, setSrc] = useState('');
	let text = APP_BASE_URL + '/f-' + formLink;

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		QRCode.toDataURL(text).then((data) => {
			setSrc(data)
		})
	}, [formLink])



	return (
		<>
			<button type='button' onClick={handleShow} className={'btn form-link-button share-button-styles'}><span><img src="/svg/icons/qrcode.svg" alt="" /> </span></button>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Form link</Modal.Title>
				</Modal.Header>
				<Modal.Body className='d-flex justify-content-center'>
					<img src={src} />
				</Modal.Body>
				<Modal.Footer>
					<DownloadQr formTitle={formTitle} src={src} />
					<Button className='btn btn-lg btn-secondary text-white' onClick={handleClose}>
						Close
					</Button>
					{/* <Button variant="primary">Understood</Button> */}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default QrCode;