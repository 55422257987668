import React, { useEffect, useRef, useState } from 'react';
import Select from "react-select";
import { Animated } from "react-animated-css";
import { trim } from '../../../../help';
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { API_BASE_URL } from '../../../../config';
import Image from 'react-image-webp';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { setIsEdited } from '../../../../redux/reducers/dataReducer';
import { useDispatch } from 'react-redux';


const Upload = (props) => {
	const { settingsData, setSettingsData,showMessageError, splitted, responseCheck, response, rowError, userData, files, setFiles, setUserData, formData, setFormData, deleteThisRowContent, changeOption, col, row, page, preview, checkAdd, contentRow, changeLabel, changeInputInLabel, setCheckAdd } = props;
	
	const copiRequired = contentRow.content[0].cols[col].content.required
	const contentRowUniqueId = contentRow.content[0].cols[0].uniqueId
	const optionsRef = useRef();
	const [opened, setOpened] = useState(false);
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	const dispatch = useDispatch()


	const updateFiles = (incommingFiles) => {
		try {
			if (incommingFiles.length == 0) {
				setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: '', direction: page + '/' + row + '/' + col } })
			}else{
					setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: 'forDeleteFiles', direction: page + '/' + row + '/' + col }})
				
			}
			setFiles({...files,[contentRow.content[0].cols[col].uniqueId]: incommingFiles});
			
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	};
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])

	const [types, setTypes] = useState('')
	const [imageSrc, setImageSrc] = useState(undefined);

	useEffect(() => {
		let conts = document.querySelectorAll('.dz-ui-file-item-container')
		if (conts && conts.length > 0) {
			for (let i = 0; i < conts.length; i++) {
				// console.log(conts[i].querySelector('.file-status'))
				var contElem = conts[i].querySelector('.file-status')
				var firstChild = contElem.childNodes[0]
				if (firstChild.classList.contains('file-status-error')) {
					conts[i].classList.add('not-valid-file')
				}
				
			}
		}
	},[files])

	useEffect(() => {
		var modified = contentRow.content[0].cols[col].content.types.split(', ').map(e => {
			return '.' + e + ','
		}).join(' ')
		setTypes(modified)
	}, [contentRow])



	
	const onDelete = (file) => {
		try {
			setFiles({...files, [contentRow.content[0].cols[col].uniqueId]: files[contentRow.content[0].cols[col].uniqueId].filter(x => x.id !== file.id)});
			if ((Object).keys(files).length - 1 <= 0) {
				setUserData({ ...userData, [contentRow.content[0].cols[col].uniqueId]: { value: '', direction: page + '/' + row + '/' + col } })
			}
			//  else {
			// 	// 'Max amount of files (1) has been reached'
			// 	var nowFiles = files[contentRow.content[0].cols[col].uniqueId]
			// 	for (let i = 0; i < nowFiles.length; i++) {
			// 		if (nowFiles[i]?.errors[0]?.startsWith("Max amount of files")){
			// 			var thisFile = files[contentRow.content[0].cols[col].uniqueId].filter(x => x.id === nowFiles[i].id);
			// 			thisFile.errors = []
			// 			thisFile.valid = true
			// 			setFiles({...files, [contentRow.content[0].cols[col].uniqueId]: 
			// 				files[contentRow.content[0].cols[col].uniqueId].filter(x => x.id === nowFiles[i].id) = thisFile});
			// 			break;
			// 		}
			// 	}
			// }
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	};
	const handleSee = (imageSource) => {
		setImageSrc(imageSource);
	};

	const imageTypes = ['jpg', 'webp', 'jpeg', 'png', 'gif'];
	const docTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'rtf', 'html'];
	const videoTypes = ['mpg', 'flv', 'avi'];
	const musicTypes = ['mp3', 'wma'];
	const archiveTypes = ['zip'];

	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var required = formCopy[page].rows[row].content[0].cols[col].content.required;
			var limit = formCopy[page].rows[row].content[0].cols[col].content.limit;
			var size = formCopy[page].rows[row].content[0].cols[col].content.size;
			var text = formCopy[page].rows[row].content[0].cols[col].content.text;
			var types = formCopy[page].rows[row].content[0].cols[col].content.types;
			var reachable = formCopy[page].rows[row].content[0].cols[col].content.reachable;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page: page,
					row: row,
					col: col
				},
				details: {
					upload: {
						required: required,
						limit: limit,
						size: size,
						text: text,
						reachable: reachable,
						types: types,
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');
			
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}
	const toggleOverflow = e => {
		e.preventDefault()
		e.currentTarget.classList.toggle('label-overflow')
	}
	useEffect(() => {
		if (document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId)) {
			document.getElementById("form_input_" + contentRow.content[0].cols[col].uniqueId).addEventListener("input", e => {
				contentRow.content[0].cols[col].content.label = e.target.innerText
				dispatch(setIsEdited(true))
			})
		}
	}, [contentRow])
	return (
		<div className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body hideDuringSelect">
					<div className="form-content-row-drag-select">
						<div className="select-label row-label">
							{
								contentRow.content[0].cols[col].content
									?
									(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
										?
										<label className='form_input text-dark font-weight-bold mb-1 label-overflow' onClick={toggleOverflow}>{contentRow.content[0].cols[col].content.label} {
											copiRequired
												? ' *'
												: ''
										}</label>
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label {
											copiRequired
												? ' *'
												: ''
										}</label>
									)
									:
									<label className='form_input text-dark font-weight-bold mb-1'>Label {
										copiRequired
											? ' *'
											: ''
									}</label>
							}
						</div>
						<Dropzone
							accept={types}
							maxFiles={contentRow.content[0].cols[col].content.limit}
							onChange={(e)=>{
								copiRequired ? updateFiles(e,copiRequired,contentRowUniqueId):updateFiles(e,copiRequired,contentRowUniqueId)
							}}
							maxFileSize={+contentRow.content[0].cols[col].content.size.max * 1000000}
							value={files[contentRow.content[0].cols[col].uniqueId]}
							label={contentRow.content[0].cols[col].content.text}
							style={{ border: rowError ? "2px dotted red" : "2px dotted grey" }}
							view={'grid'}
						>
							{(Object).keys(files).length && files.hasOwnProperty(contentRow.content[0].cols[col].uniqueId) ? files[contentRow.content[0].cols[col].uniqueId].map((f, index) => (
								<FileItem {...f} key={f.id} alwaysActive info resultOnTooltip onDelete={()=>onDelete(f)} preview hd onSee={handleSee} />
							)): null}
							<FullScreenPreview
								imgSource={imageSrc}
								openImage={imageSrc}
								onClose={(e) => handleSee(undefined)}
							/>
						</Dropzone>
					</div>

				</div>
				:
				(responseCheck
					?
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-select">
							<div className="select-label row-label">
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label && trim(contentRow.content[0].cols[col].content.label)
											?
											<label className='form_input text-dark font-weight-bold mb-1'>{contentRow.content[0].cols[col].content.label}</label>
											:
											<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
										)
										:
										<label className='form_input text-dark font-weight-bold mb-1'>Label</label>
								}
							</div>
							{
								response.files && response.files.length > 0
									?
									<div className="response-files">
										{
											Object.values(response.files).filter(f => f[2] === contentRow.content[0].cols[col].uniqueId).map((e, index) => {
												return <a href={API_BASE_URL + '/files/Response/' + response.response_id + '/' + e[0]} loading="lazy" className="response-file" target="_blank" key={index} title={e[0]}>
													{
														imageTypes.includes(e[1])
															? <div className="response-file-type response-file-img">
																<img src={API_BASE_URL + '/files/Response/' + response.response_id + '/' + e[0]} alt="" loading="lazy" />
															</div>
															: (
																docTypes.includes(e[1])
																	? <div className="response-file-type response-file-doc">
																		<img src='/img/Another/doc.jpg' alt="" loading="lazy" />
																		{/* <iframe src={API_BASE_URL + '/files/Response/' + response._id + '/' + e[0]}></iframe> */}
																	</div>
																	: (
																		videoTypes.includes(e[1])
																			? <div className="response-file-type response-file-video">
																				<img src='/img/Another/video.jpg' alt="" loading="lazy" />
																				{/* <iframe src={API_BASE_URL + '/files/Response/' + response._id + '/' + e[0]}></iframe> */}
																			</div>
																			: (
																				musicTypes.includes(e[1])
																					? <div className="response-file-type response-file-music">
																						<img src='/img/Another/music.jpg' alt="" loading="lazy" />
																						{/* <iframe src={API_BASE_URL + '/files/Response/' + response._id + '/' + e[0]}></iframe> */}
																					</div>
																					: (
																						archiveTypes.includes(e[1])
																							? <div className="response-file-type response-file-archive">
																								<img src='/img/Another/archive.jpg' alt="" loading="lazy" />
																								{/* <iframe src={API_BASE_URL + '/files/Response/' + response._id + '/' + e[0]}></iframe> */}
																							</div>
																							: null
																					)
																			)
																	)
															)
													}
												</a>
											})
										}
									</div>
									: <h3 className='d-flex justify-content-start w-100 h-100'>__</h3>
									}
						</div>
					</div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-select form-content-not-preview">
							<div className="select-label row-label">
								{
									contentRow.content[0].cols[col].content.filter
										? <span className='form-content-filter' title='filter'><i className="fas fa-filter"></i></span>
										: null
								}
								{
									contentRow.content[0].cols[col].content
										?
										(contentRow.content[0].cols[col].content.label
											?
											// <input type="text" defaultValue={contentRow.content[0].cols[col].content.label} className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
											<div className={copiRequired ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>{contentRow.content[0].cols[col].content.label}</div>
											:
											<div className={copiRequired? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Upload</div>
											// <input type="text" defaultValue="Dropdown" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
										)
										:
										<div className={copiRequired ? 'user-select-text user-select-text-required' : 'user-select-text'} id={"form_input_" + contentRow.content[0].cols[col].uniqueId} format="text" contentEditable="true" suppressContentEditableWarning={true}>Upload</div>
									// <input type="text" defaultValue="Dropdown" className='form_input' page={page} row={row} col={col} onChange={e => contentRow.content[0].cols[col].content.label = e.target.value} />
								}
							</div>
							<Dropzone
								accept={types}
								maxFiles={contentRow.content[0].cols[col].content.limit}
								onChange={e => e.preventDefault ? e.preventDefault() : e.returnValue = false}
								disabled={true}
								clickable={false}
								uploadOnDrop={e => e.stopPropagation()}
								onDrag={e => e.stopPropagation()}
								onUploadFinish={e => e.stopPropagation()}
								maxFileSize={+contentRow.content[0].cols[col].content.size.max * 1000000}
								value={files}
								label={contentRow.content[0].cols[col].content.text}
							>
							</Dropzone>
							{/* {
							copiRequired
								?
								<label
									className='form_input row-sublabel'>This field is required</label>
								: null
							} */}
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div >
	);
};

export default Upload;