import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment'
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateRange from 'react-datepicker'

const FormSettingsDate = (props) => {
	const { settingsData, checkedDate, checkedTime, changeTime, changeTimeHourNow, changeTimeMinuteNow, changeFormatTime, checkedTimeChange, requiredChange, changeSeparator, changeDateType, changeDefaultDate, responseChange, checkedDateResponse } = props;
	const [nowDate, setNowDate] = useState('')
	const [nowCheckedTime, setNowCheckedTime] = useState(false)
	const changeDayDate = (day, label) => {
		changeDefaultDate(settingsData.info.page, settingsData.info.row, settingsData.info.col, day, label)
	}
	const [open, setOpen] = useState(false)
	const [openStart, setOpenStart] = useState(false)
	const [openEnd, setOpenEnd] = useState(false)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const changeRequiredField = e => {
		requiredChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, 'date', e.target.checked)
	}

	const changeStartDate = value => {
		setStartDate(value)
		// setTimeout(() => {
			if (endDate) {
				changeDefaultDate(settingsData.info.page, settingsData.info.row, settingsData.info.col,
					{
						'start': moment(value, settingsData.details.date.typeDate).format(settingsData.details.date.typeDate.toUpperCase()),
						'end': moment(endDate, settingsData.details.date.typeDate).format(settingsData.details.date.typeDate.toUpperCase())
					},
					'Range')
			}
		// }, 0);
	}

	const changeEndDate = value => {
		setEndDate(value)
		// setTimeout(() => {
			if (startDate) {
				changeDefaultDate(settingsData.info.page, settingsData.info.row, settingsData.info.col,
					{
						'start': moment(startDate, settingsData.details.date.typeDate).format(settingsData.details.date.typeDate.toUpperCase()),
						'end': moment(value, settingsData.details.date.typeDate).format(settingsData.details.date.typeDate.toUpperCase())
					}, 'Range')
			}
		// }, 0);
	}

	useEffect(() => {
		if (settingsData.details.date.defaultDateValue !== null) {
			setNowDate(moment(settingsData.details.date.defaultDateValue).format(settingsData.details.date.typeDate))
			if (settingsData.details.date.defaultDateLabel == 'Range') {
				setStartDate(settingsData.details.date.defaultDateValue.split(' - ')[0])
				setEndDate(settingsData.details.date.defaultDateValue.split(' - ')[1])
			}
		} else {
			setNowDate(moment(new Date(), settingsData.details.date.typeDate).format(settingsData.details.date.typeDate))
		}
		// console.log( settingsData.details.date.defaultDateValue.split(' - ')[1]);
	}, [changeDefaultDate])
	const changeTimeChecked = e => {
		checkedTimeChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.target.checked)
	}
	const changeTimeFormat = format => {
		changeFormatTime(settingsData.info.page, settingsData.info.row, settingsData.info.col, format)
	}
	const changeTimeDate = label => {
		changeTime(settingsData.info.page, settingsData.info.row, settingsData.info.col, label)
	}
	useEffect(() => {
		setNowCheckedTime(checkedTime)
	}, [checkedTime])
	const formTimeHour = useRef()
	const formTimeMinute = useRef()
	const timeHour = useRef()
	const timeMinute = useRef()
	const showHourAbsolute = e => {
		formTimeHour.current.classList.add('active')
		formTimeMinute.current.classList.remove('active')
	}
	const showMinuteAbsolute = e => {
		formTimeMinute.current.classList.add('active')
		formTimeHour.current.classList.remove('active')
	}

	const changeTimeHour = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23) {
			hour++
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}

	const changeTimeHourFive = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23 && hour + 5 <= 23) {
			hour += 5
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}
	const changeTimeHourTen = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 23 && hour + 10 <= 23) {
			hour += 10
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}
	const changeTimeHourMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > 0 && hour < 24) {
			hour--
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '23')
		}
	}
	const changeTimeHourFiveMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > 0 && hour < 24 && hour - 5 > -1) {
			hour -= 5
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '23')
		}
	}
	const changeTimeHourTenMinus = e => {
		var hour = +timeHour.current.innerText
		if (hour > -1 && hour < 24 && hour - 10 > -1) {
			hour -= 10
			if (hour < 10) { hour = '0' + hour }
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, hour)
		} else {
			changeTimeHourNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '23')
		}
	}

	const changeTimeMinute = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 59) {
			minute++
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}
	const changeTimeMinuteMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > 0 && minute < 60) {
			minute--
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '59')
		}
	}
	const changeTimeMinuteFive = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute + 5 <= 59) {
			minute += 5
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}
	const changeTimeMinuteTen = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute + 10 <= 59) {
			minute += 10
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '00')
		}
	}
	const changeTimeMinuteFiveMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute - 5 > -1) {
			minute -= 5
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '59')
		}
	}
	const changeTimeMinuteTenMinus = e => {
		var minute = +timeMinute.current.innerText
		if (minute > -1 && minute < 60 && minute - 10 > -1) {
			minute -= 10
			if (minute < 10) { minute = '0' + minute }
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, minute)
		} else {
			changeTimeMinuteNow(settingsData.info.page, settingsData.info.row, settingsData.info.col, '59')
		}
	}

	const changeResponseField = e => {
		responseChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, settingsData.details.date.uniqueId, 'date', e.target.checked)
	}

	const changeDateTypeField = (typedate) => {
		changeDateType(settingsData.info.page, settingsData.info.row, settingsData.info.col, typedate)
	}

	const changeSeparatoreField = (separator) => {
		changeSeparator(settingsData.info.page, settingsData.info.row, settingsData.info.col, separator)
	}

	document.documentElement.addEventListener("click", function (e) {
		if (!e.target.closest('.form-time')) {
			if (formTimeHour.current) {
				formTimeHour.current.classList.remove('active')
			}
		}
		if (!e.target.closest('.form-time')) {
			if (formTimeMinute.current) {
				formTimeMinute.current.classList.remove('active')
			}
		}
	})


	return (
		<div id="dateSettingsForm">

			{
				settingsData.details.date.defaultDateLabel == 'None' || settingsData.details.date.defaultDateLabel == 'Range'
					?
					<div className="p-4 px-lg-5 border-bottom border-gray-200">
						<div className="d-flex align-items-center">
							<h6 className="font-weight-semibold mb-0">Required</h6>
							<div className="form-check form-switch mb-0 ms-auto" >
								<input className="form-check-input me-0" type="checkbox"
									checked={checkedDate}
									onChange={changeRequiredField}
								/>
							</div>
						</div>
						<p className="text-gray-600 pt-2 mb-0">Is the field required?</p>
					</div>
					: null
			}
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">In response</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedDateResponse}
							onChange={changeResponseField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Whould you like to see this field on responses page?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Separator</h6>
					<div className='type-inputs d-flex'>
						<div className={settingsData.details.date.separator == '/' ? "type-input date-input form-switch mb-0 my-2 checked" : "type-input date-input form-switch mb-0 my-2"}
							onClick={() => changeSeparatoreField('/')}
						>
							<button className="me-0">/</button>
						</div>
						<div className={settingsData.details.date.separator == '-' ? "type-input date-input form-switch mb-0 my-2 checked" : "type-input date-input form-switch mb-0 my-2"}
							onClick={() => changeSeparatoreField('-')}
						>
							<button className="me-0">-</button>
						</div>
						<div className={settingsData.details.date.separator == '.' ? "type-input date-input form-switch mb-0 my-2 checked" : "type-input date-input form-switch mb-0 my-2"}
							onClick={() => changeSeparatoreField('.')}
						>
							<button className="me-0">.</button>
						</div>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Select which character to use between the date fields.</p>
			</div>

			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">Date Format</h6>
					<div className='type-inputs d-flex type-dates'>
						<div className={settingsData.details.date.typeDate == 'MM' + settingsData.details.date.separator + 'dd' + settingsData.details.date.separator + 'yyyy' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDateTypeField('MM' + settingsData.details.date.separator + 'dd' + settingsData.details.date.separator + 'yyyy')}
						>
							<button className="me-0 datetype-input">{'MM' + settingsData.details.date.separator + 'dd' + settingsData.details.date.separator + 'yyyy'}</button>
						</div>
						<div className={settingsData.details.date.typeDate == 'dd' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'yyyy' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDateTypeField('dd' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'yyyy')}
						>
							<button className="me-0 datetype-input">{'dd' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'yyyy'}</button>
						</div>
						<div className={settingsData.details.date.typeDate == 'yyyy' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'dd' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDateTypeField('yyyy' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'dd')}
						>
							<button className="me-0 datetype-input">{'yyyy' + settingsData.details.date.separator + 'MM' + settingsData.details.date.separator + 'dd'}</button>
						</div>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Select the date format you wish you use. M stands for month, D for day and Y for year.</p>
			</div>

			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">Default Date</h6>
					<div className='type-inputs d-flex'>
						<div className={settingsData.details.date.defaultDateLabel == 'None' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDayDate(null, 'None')}
						>
							<button type='button' className="me-0 datetype-input">None</button>
						</div>
						<div className={settingsData.details.date.defaultDateLabel == 'Current' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDayDate(new Date(), 'Current')}
							defaultlabel='Current'
						>
							<button type='button' className="me-0 datetype-input">Current</button>
						</div>
						<div className={settingsData.details.date.defaultDateLabel == 'Custom' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDayDate(new Date(), 'Custom')}
						>
							<button type='button' className="me-0 datetype-input">Custom</button>
						</div>
						<div className={settingsData.details.date.defaultDateLabel == 'Range' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeDayDate(new Date(), 'Range')}
						>
							<button type='button' className="me-0 datetype-input">Range</button>
						</div>
					</div>
					{
						settingsData.details.date.defaultDateLabel == 'Custom'
							?
							<div className='mt-3'>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										open={open}
										value={settingsData.details.date.defaultDateValue ? settingsData.details.date.defaultDateValue : new Date()}
										onOpen={() => setOpen(true)}
										onClose={() => setOpen(false)}
										// maxDate={new Date()}
										format={settingsData.details.date.typeDate}
										inputFormat={settingsData.details.date.typeDate}
										// disableOpenPicker={true}
										onChange={(newValue) => {
											changeDayDate(newValue, 'Custom');
										}}
										// components={{
										// 	OpenPickerIcon: MoreTimeIcon,
										// }}
										renderInput={(params) => (
											<TextField {...params} onClick={() => setOpen(!open)} />
										)}
										InputProps={{ readOnly: true }}
									/>
								</LocalizationProvider>
								{/* <DayPickerInput
									formatDate={formatDate}
									parseDate={parseDate}
									format={settingsData.details.date.typeDate}
									placeholder={nowDate !== ''
										? nowDate
										: moment(new Date()).format(settingsData.details.date.typeDate)}
									onDayChange={day => changeDayDate(`${moment(day).format(settingsData.details.date.typeDate)}`, 'Custom')}
								/> */}
							</div>
							: settingsData.details.date.defaultDateLabel == 'Range'
								? <></> : null
								// ? <div className="mt-3">
								// 	<div className="mb-3">
								// 		<LocalizationProvider dateAdapter={AdapterDateFns}>
								// 			<DatePicker
								// 				open={openStart}
								// 				value={startDate
								// 					? startDate
								// 					: new Date()}
								// 				onOpen={() => setOpenStart(true)}
								// 				onClose={() => setOpenStart(false)}
								// 				// maxDate={new Date()}
								// 				format={settingsData.details.date.typeDate}
								// 				inputFormat={settingsData.details.date.typeDate}
								// 				// disableOpenPicker={true}
								// 				onChange={(newValue) => {
								// 					changeStartDate(newValue);
								// 				}}
								// 				// components={{
								// 				// 	OpenPickerIcon: MoreTimeIcon,
								// 				// }}
								// 				renderInput={(params) => (
								// 					<TextField {...params} onClick={() => setOpenStart(!open)} />
								// 				)}
								// 				InputProps={{ readOnly: true }}
								// 			/>
								// 		</LocalizationProvider>
								// 	</div>
								// 	<LocalizationProvider dateAdapter={AdapterDateFns}>
								// 		<DatePicker
								// 			open={openEnd}
								// 			value={endDate
								// 				? endDate
								// 				: new Date()}
								// 			onOpen={() => setOpenEnd(true)}
								// 			onClose={() => setOpenEnd(false)}
								// 			// maxDate={new Date()}
								// 			format={settingsData.details.date.typeDate}
								// 			inputFormat={settingsData.details.date.typeDate}
								// 			// disableOpenPicker={true}
								// 			onChange={(newValue) => {
								// 				changeEndDate(newValue);
								// 			}}
								// 			// components={{
								// 			// 	OpenPickerIcon: MoreTimeIcon,
								// 			// }}
								// 			renderInput={(params) => (
								// 				<TextField {...params} onClick={() => setOpenEnd(!open)} />
								// 			)}
								// 			InputProps={{ readOnly: true }}
								// 		/>
								// 	</LocalizationProvider>
								// </div>
								// : null
					}
				</div>
				<p className="text-gray-600 pt-2 mb-0">Pre-populate the date field with a fixed value or current date.</p>
			</div>

			{/* <div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Time Field</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedTime}
							onChange={changeTimeChecked}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Allow users to specify a time with date.</p>
			</div> */}
			{
				nowCheckedTime
					?
					<div>
						<div className="p-4 px-lg-5 border-bottom border-gray-200">
							<div className="d-flex align-items-center time-formats">
								<h6 className="font-weight-semibold mb-0">Time Format</h6>
								<div className="form-check form-switch mb-0 ms-auto" >
									<div className={settingsData.details.date.timeFormat == '24' ? "type-input form-switch mb-0 checked" : "type-input form-switch mb-0"}
										onClick={() => changeTimeFormat('24')}
									>
										<button className="me-0 datetype-input">24 HOUR</button>
									</div>
									<div className={settingsData.details.date.timeFormat == 'AM/PM' ? "type-input form-switch mb-0 checked" : "type-input form-switch mb-0"}
										onClick={() => changeTimeFormat('AM/PM')}
									>
										<button className="me-0 datetype-input">AM/PM</button>
									</div>
								</div>
							</div>
							<p className="text-gray-600 pt-2 mb-0">Select which time format to use.</p>
						</div>
						<div className="p-4 px-lg-5 border-bottom border-gray-200">
							<div className="d-flex flex-column">
								<h6 className="font-weight-semibold mb-0">Default Time</h6>
								<div className="d-flex" >
									<div className={settingsData.details.date.defaultTimeLabel == 'None' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
										onClick={() => changeTimeDate('None')}
									>
										<button type='button' className="me-0 datetype-input">None</button>
									</div>
									<div className={settingsData.details.date.defaultTimeLabel == 'Current' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
										onClick={() => changeTimeDate('Current')}
									>
										<button type='button' className="me-0 datetype-input">Current</button>
									</div>
									<div className={settingsData.details.date.defaultTimeLabel == 'Custom' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
										onClick={() => changeTimeDate('Custom')}
									>
										<button type='button' className="me-0 datetype-input">Custom</button>
									</div>
								</div>
							</div>
							{
								settingsData.details.date.defaultTimeLabel == 'Custom'
									?
									<div className='mt-3'>
										<div className="form-times">
											<div className="form-times-hour form-time">
												<div onClick={showHourAbsolute} className='form-control custom-button-time'><span>{settingsData.details.date.defaultTimeValue.split(':')[0]}</span> H</div>
												<div className="form-time-absolute form-time-hour" ref={formTimeHour}>
													<div className="absolute-time-content">
														<div className="absolute-time-content-buttons">
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHourFive} className='change-time-number'><span>5</span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHour}><span><i className="fas fa-caret-up"></i></span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHourTen} className='change-time-number'><span>10</span></button>
															</div>
														</div>
														<div className="absolute-time-content-number">
															<span ref={timeHour}>{settingsData.details.date.defaultTimeValue.split(':')[0]}</span> H
														</div>
														<div className="absolute-time-content-buttons">
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHourFiveMinus} className='change-time-number'><span>5</span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHourMinus}><span><i className="fas fa-caret-down"></i></span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeHourTenMinus} className='change-time-number'><span>10</span></button>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="form-times-minute form-time">
												<div onClick={showMinuteAbsolute} className='form-control custom-button-time' ><span>{settingsData.details.date.defaultTimeValue.split(':')[1]}</span> M</div>
												<div className="form-time-absolute form-time-minute" ref={formTimeMinute}>
													<div className="absolute-time-content">
														<div className="absolute-time-content-buttons">
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinuteFive} className='change-time-number'><span>5</span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinute}><span><i className="fas fa-caret-up"></i></span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinuteTen} className='change-time-number'><span>10</span></button>
															</div>
														</div>
														<div className="absolute-time-content-number">
															<span ref={timeMinute}>{settingsData.details.date.defaultTimeValue.split(':')[1]}</span> M
														</div>
														<div className="absolute-time-content-buttons">
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinuteFiveMinus} className='change-time-number'><span>5</span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinuteMinus}><span><i className="fas fa-caret-down"></i></span></button>
															</div>
															<div className="absolute-time-content-button">
																<button type='button' onClick={changeTimeMinuteTenMinus} className='change-time-number'><span>10</span></button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									: null
							}
							<p className="text-gray-600 pt-2 mb-0">Pre-populate the time field with a fixed value or current time.</p>
						</div>
					</div>
					: null
			}
		</div >
	);
};

export default FormSettingsDate;