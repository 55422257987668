import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { API_BASE_URL } from '../../../../config';


const Image = (props) => {
	const { formData, setFormData, settingsData,showMessageError,responseCheck, setSettingsData, page, row, col, contentRow, preview, deleteThisRowContent, changeImage, checkAdd, setCheckAdd } = props;
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
		try {
			var formCopy = JSON.parse(JSON.stringify(formData))
			var align = formCopy[page].rows[row].content[0].cols[col].align;
			var fit = formCopy[page].rows[row].content[0].cols[col].fit;
			var height = formCopy[page].rows[row].content[0].cols[col].height;
			var width = formCopy[page].rows[row].content[0].cols[col].width;
			setSettingsData({})
			var newSettingsData = {
				info: {
					page: page,
					row: row,
					col: col,
				},
				details: {
					image: {
						width: width,
						height: height,
						align: align,
						fit: fit
					}
				}
			}
			setSettingsData(newSettingsData)
			document.querySelector('body').classList.toggle('customize-box');
			
		} catch (error) {
			showMessageError('Something went wrong.')
		}
	}

	return (
		// <Animated className='animatedDiv styleAnimated' animationIn={checkAdd ? "shake" : ""} animationOut="fadeOut" isVisible={true}  >
		<div id="imagePage" className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}
			page={page} row={row} col={col}>
			{preview
				?
				<div className="form-content-row-drag-body drag-image preview-image" >
					<div  className={"form-content-row-drag-image " + (contentRow.content[0].cols[col].content.length > 0 ? 'hasImage' : '')} style={{
						justifyContent: contentRow.content[0].cols[col].align,
						height: contentRow.content[0].cols[col].height == 'auto' ? '' : +contentRow.content[0].cols[col].height * 10 + 'rem',
					}}>
						{
							contentRow.content[0].cols[col].content[0]
								?
								(
									contentRow.content[0].cols[col].content[0].src
										?
										<img src={API_BASE_URL + contentRow.content[0].cols[col].content[0].src} alt=""
											style={{
												objectFit: contentRow.content[0].cols[col].fit,
												width: contentRow.content[0].cols[col].width,
												// height: contentRow.content[0].cols[col].height + 'px',
											}} />
										:
										(
											contentRow.content[0].cols[col].content[0].realSrc
												?
												<img src={contentRow.content[0].cols[col].content[0].realSrc} alt="" style={{
													objectFit: contentRow.content[0].cols[col].fit,
													width: contentRow.content[0].cols[col].width,
													// height: contentRow.content[0].cols[col].height + 'px',
												}} />
												:
												<img src="/img/Another/default-image.jpg" alt="" style={{
													objectFit: contentRow.content[0].cols[col].fit,
													width: contentRow.content[0].cols[col].width,
													// height: contentRow.content[0].cols[col].height + 'px',
												}} />
										)
								)
								:
								<img src="/img/Another/default-image.jpg" alt="" />
						}
					</div>
				</div>
				:(responseCheck
					?
					<div className="form-content-row-drag-body drag-image preview-image" >
						<div className={"form-content-row-drag-image " + (contentRow.content[0].cols[col].content.length > 0 ? 'hasImage' : '')} style={{
							justifyContent: contentRow.content[0].cols[col].align,
							height: contentRow.content[0].cols[col].height == 'auto' ? '' : +contentRow.content[0].cols[col].height * 10 + 'rem',
						}}>
							{
								contentRow.content[0].cols[col].content[0]
									?
									(
										contentRow.content[0].cols[col].content[0].src
											?
											<img src={API_BASE_URL + contentRow.content[0].cols[col].content[0].src} alt=""
												style={{
													objectFit: contentRow.content[0].cols[col].fit,
													width: contentRow.content[0].cols[col].width,
													// height: contentRow.content[0].cols[col].height + 'px',
												}} />
											:
											(
												contentRow.content[0].cols[col].content[0].realSrc
													?
													<img src={contentRow.content[0].cols[col].content[0].realSrc} alt="" style={{
														objectFit: contentRow.content[0].cols[col].fit,
														width: contentRow.content[0].cols[col].width,
														// height: contentRow.content[0].cols[col].height + 'px',
													}} />
													:
													<img src="/img/Another/default-image.jpg" alt="" style={{
														objectFit: contentRow.content[0].cols[col].fit,
														width: contentRow.content[0].cols[col].width,
														// height: contentRow.content[0].cols[col].height + 'px',
													}} />
											)
									)
									:
									<img src="/img/Another/default-image.jpg" alt="" />
							}
						</div>
					</div>
					:	
					<div className="form-content-row-drag-body drag-image" >
						<div className={"form-content-row-drag-image " + (contentRow.content[0].cols[col].content.length > 0 ? 'hasImage' : '')} style={{
							justifyContent: contentRow.content[0].cols[col].align,
							height: contentRow.content[0].cols[col].height == 'auto' ? '' : +contentRow.content[0].cols[col].height * 10 + 'rem',
						}}>
							<input
								type="file"
								className="drop-image"
								title="Click to change Image"
								accept="jpeg,jpg,png,webp"
								onChange={changeImage}
							/>
							{
								contentRow.content[0].cols[col].content[0]
									?
									(
										contentRow.content[0].cols[col].content[0].src
											?
											<img src={API_BASE_URL + contentRow.content[0].cols[col].content[0].src} alt="" style={{
												objectFit: contentRow.content[0].cols[col].fit,
												width: contentRow.content[0].cols[col].width,
												// height: contentRow.content[0].cols[col].height + 'px',
											}} />
											:
											(
												contentRow.content[0].cols[col].content[0].realSrc
													?
													<img src={contentRow.content[0].cols[col].content[0].realSrc} alt="" style={{
														objectFit: contentRow.content[0].cols[col].fit,
														width: contentRow.content[0].cols[col].width,
														// height: contentRow.content[0].cols[col].height + 'px',
													}} />
													:
													<img src="/img/Another/default-image.jpg" alt="" style={{
														objectFit: contentRow.content[0].cols[col].fit,
														width: contentRow.content[0].cols[col].width,
														// height: contentRow.content[0].cols[col].height + 'px',
													}} />
											)
									)
									:
									<img src="/img/Another/default-image.jpg" alt="" />
							}
							<div className="col-buttons">
								<div className="edit-col col-button">
									<div className="edit-col-body col-button-body">
										<button type='button' title='Settings' onClick={editThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-cog"></i></span></button>
									</div>
								</div>
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div >
		// </Animated>
	);
};

export default Image;