import axios from 'axios';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {API_BASE_URL, APP_BASE_URL, HOME_BASE_URL} from '../config';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SuccessReport = () => {
	function showMessageSuccess(message) {
		try {
			messageEdit.current.classList.remove('form-changes-error')
			messageEdit.current.classList.add('active', 'form-changes-success')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 1000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	function showMessageError(message) {
		try {
			messageEdit.current.classList.remove('form-changes-success')
			messageEdit.current.classList.add('active', 'form-changes-error')
			setTimeout(() => {
				if (messageEdit.current != null) {
					messageEdit.current.classList.remove('active', 'form-changes-error', 'form-changes-success')
				}
			}, 5000)
			setEditedMessage(message)
		} catch (error) { throw error }
	}
	const [admin, setAdmin] = useState(false)
	const [formId, setFormId] = useState(null)
	const [editorData, setEditorData] = useState('')
	var writing = false
	useEffect(() => {
		if (localStorage.getItem('report')) {
			if (localStorage.getItem('successAdmin')) {
				localStorage.removeItem('successAdmin')
				setAdmin(true)
			}
			setEditorData("<span>We received your report <br/> we'll be in touch shortly!</span>")
		}
	setTimeout(()=> {if (localStorage.getItem('report')) {
			localStorage.removeItem('report')
		}
		window.location.href = HOME_BASE_URL}, 5000)
	}, [])
	const changeText = useRef(null)
	const [editedMessage, setEditedMessage] = useState('')
	const [liveWriting, setLiveWriting] = useState(false)
	const messageEdit = useRef();
	const editorCK = useRef();

	const editPreview = e => {
		e.preventDefault()
		changeText.current.classList.remove('disactive')
		writing = true
		setLiveWriting(true)
	}
	const editorConfiguration = {
		toolbar: [],
		heading: {
			options: []
		},
		placeholder: "",
	};

	document.documentElement.addEventListener("click", function (e) {
		if (admin) {
			if (!e.target.closest('.success-edit')) {
				changeText.current.classList.add('disactive')
				if (writing === true) {
					writing = false
					setLiveWriting(false)
					try {
						var data = {}
						data.id = formId;
						data.text = editorCK.current.editor.getData()
						axios.post(API_BASE_URL + '/api/saveSuccessText', data)
							.then(response => {
								showMessageSuccess('Successfully saved!!')
							})
							.catch(error => {
								showMessageError('Something went wrong.')
							})
					} catch (error) {
						showMessageError('Something went wrong.')
					}
				}
			}
		}
	})
	return (
		<div className="success success-page">
			<div className="form-changes" ref={messageEdit} >
				<p>{editedMessage}</p>
			</div>
			<div className="card">
				<div className="success-body">
					<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
						<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
					</svg>
				</div>
				<h1>Success</h1>
				<div className="success-edit disactive" ref={changeText}>
					{
						admin
						?
						<>
						<CKEditor
							ref={editorCK}
							editor={ClassicEditor}
							config={editorConfiguration}
							data={editorData ? editorData : ''}
							onChange={(event, editor) => {
								setEditorData(editor.getData())
							}}
						/>
						<button type='button' title="Change text" onClick={e => editPreview(e)}><span><i className="fas fa-pen"></i></span>
							<div className={liveWriting ? "writing-circles _active" : "writing-circles"}>
								<span className="writing-circle writing-circle-1"></span>
								<span className="writing-circle writing-circle-2"></span>
								<span className="writing-circle writing-circle-3"></span>
							</div>
						</button>
						</>
						:
						<div dangerouslySetInnerHTML={{ __html: editorData ? editorData : "" }} className='success-edit-text'></div>
					}
				</div>
			</div>
		</div>
	);
};

export default SuccessReport;