import React from 'react';

const DateRange = (props) => {
    const {settingsData, setSettingsData,form_id, formDetails, formData, setFormData, showMessage, showMessageSuccess, showMessageError, saveForm, colorsData,form,setForm} = props;
   
    const editDateRange = (e) => {
        e.stopPropagation();
		var formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
		var deadline = formDetailsCopy.deadline;
		// var countdown = formDetailsCopy.countdown;
		var countdown = formDetailsCopy.countdown ? formDetailsCopy.countdown : '00:00:00';
		setSettingsData({})
		var newSettingsData = {
			info: {
				form_id: form_id,
				settings: 'dateRange',
			},
			details: {
				dateRange: {
					deadline,
					countdown,
				},
			}
		}
		setSettingsData(newSettingsData)
		document.querySelector('body').classList.toggle('customize-box');
    }
	
    return (
        <div className="dateRange mx-2">
            <button type="button" title="Date range" onClick={editDateRange}>
                <span><i className="fas fa-hourglass-start"></i></span>
            </button>
        </div>
    );
};

export default DateRange;