import React from 'react';
import { API_BASE_URL } from '../../../../config';
import Select from "react-select";
import { useState } from 'react';
import { useEffect } from 'react';

const FormSettingsImage = (props) => {
	const { settingsData, changeAlignment, changeFit, changeWidth, changeHeight } = props;

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? '#f8f9fa' : undefined,
			borderBottom: '1px dotted #dee2e6',
			padding: 5,
			background: state.isSelected ? '#f8f9fa'  : '',
			color: state.isSelected ? 'black' : state.isFocused ? 'black' :
				'#1c1f21',
			// color: state.isSelected ? 'white' : '',
			searchable: false,
			//
			":active": {
				...provided[":active"],
				backgroundColor: !state.isDisabled ? state.isSelected
						? '#21252917'
					: '#21252917' : undefined,
			}
		}),
	}
	const [settingsDetailsFit, setSettingsDetailsFit] = useState('')
	const [optionsFit, setOptionsFit] = useState([
		{ id: 1, value: 'cover', label: 'Cover', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		{ id: 2, value: 'fill', label: 'Fill', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		{ id: 3, value: 'contain', label: 'Contain', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
	])
	const [optionsWidth, setOptionsWidth] = useState([
		{ id: 1, value: 'auto', label: 'auto', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		{ id: 2, value: '100%', label: '100%', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		// { id: 3, value: 'contain', label: 'Contain', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		// { id: 4, value: 'scale-down', label: 'Scale Down', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
	])
	const [optionsHeight, setOptionsHeight] = useState('auto')
	useEffect(() => {
		setOptionsFit([
			{ id: 1, value: 'cover', label: 'Cover', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
			{ id: 2, value: 'fill', label: 'Fill', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
			{ id: 3, value: 'contain', label: 'Contain', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		])
		setOptionsWidth([
			{ id: 1, value: 'auto', label: 'auto', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
			{ id: 2, value: '100%', label: '100%', page: settingsData.info.page, row: settingsData.info.row, col: settingsData.info.col },
		])
		setOptionsHeight(settingsData.details.image.height)
		setSettingsDetailsFit(settingsData.details.image.fit)
	}, [settingsData])

	const changeWidthField = e => {
		changeWidth(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.value)
	}

	const changeHeightField = e => {
		changeHeight(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.target.value)
	}

	const changeAlignmentField = (alignment) => {
		changeAlignment(settingsData.info.page, settingsData.info.row, settingsData.info.col, alignment)
	}

	const changeFitField = e => {
		changeFit(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.value)
	}

	return (
		<div>
			{/* <div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Width</h6>
					<div className="form-check form-switch mb-0" >
						<h6 className="font-weight-semibold mb-0 d-flex align-items-center">Auto</h6>
						<input className="form-check-input mx-2" type="checkbox"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fieldtype='input'
						/>
					</div>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-control me-0" type="text"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fieldtype='image'
							defaultValue={settingsData.details.image.width}
						/>
					</div>
				</div>
			</div> */}
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Width</h6>
					<Select
						id="text"
						type="text"
						className="form-content-select my-2"
						styles={customStyles}
						options={optionsWidth}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary: "black"
							}
						})}
						isSearchable={false}
						placeholder={settingsData.details.image.width == 'auto' ? 'auto' : settingsData.details.image.width}
						isClearable={false}
						// onChange={e => setUserData({ ...userData, [page + '/' + row + '/' + col]: e.value })}
						onChange={changeWidthField}
					// openMenuOnClick={false}
					/>
				</div>
				<p className="text-gray-600 pt-2 mb-0">What is the width of image?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Height</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-control me-0" type="number"
							onChange={changeHeightField}
							value={optionsHeight == 'auto' ? '' : optionsHeight}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Alignment</h6>
					<div className='type-inputs d-flex'>
						<div className={settingsData.details.image.align == 'flex-start' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeAlignmentField("flex-start")}
						>
							<button className="me-0">Left</button>
						</div>
						<div className={settingsData.details.image.align == 'center' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeAlignmentField("center")}
						>
							<button className="me-0">Center</button>
						</div>
						<div className={settingsData.details.image.align == 'flex-end' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={() => changeAlignmentField("flex-end")}
						>
							<button className="me-0">Right</button>
						</div>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">What is the type of input?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold">Fit</h6>
					<Select
						id="text"
						type="text"
						className="form-content-select my-2"
						styles={customStyles}
						options={optionsFit}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary: "black"
							}
						})}
						isSearchable={false}
						placeholder={settingsDetailsFit}
						isClearable={false}
						// onChange={e => setUserData({ ...userData, [page + '/' + row + '/' + col]: e.value })}
						onChange={changeFitField}
					// openMenuOnClick={false}
					/>
					{/* <div className='type-inputs d-flex'>
						<div className={settingsData.details.image.fit == 'fill' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={changeAlignment}
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fittype="fill"
						>
							<button className="me-0">Fill</button>
						</div>
						<div className={settingsData.details.image.fit == 'cover' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={changeAlignment}
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fittype="cover"
						>
							<button className="me-0">Cover</button>
						</div>
						<div className={settingsData.details.image.fit == 'contain' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={changeAlignment}
							fittype="contain"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
						>
							<button className="me-0">Contain</button>
						</div>
						<div className={settingsData.details.image.fit == 'scale-down' ? "type-input form-switch mb-0 my-2 checked" : "type-input form-switch mb-0 my-2"}
							onClick={changeAlignment}
							fittype="scale-down"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
						>
							<button className="me-0">Scale Down</button>
						</div>
					</div> */}
				</div>
				<p className="text-gray-600 pt-2 mb-0">What type of fit do you want for this image?</p>
			</div>
			{/* <div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">Change Image</h6>
					<div className="form-switch mb-0 " >
						<div className="form-content-row-drag-body drag-image settings-image">
							<div className="form-content-row-drag-image" className={"form-content-row-drag-image " + (contentRow.content[0].cols[col].content.length > 0 ? 'hasImage' : '')}>
							<div className="form-content-row-drag-image mt-4 justify-content-flex-start">
								<input
									type="file"
									className="drop-image"
									title=""
									accept="jpeg,jpg,png,webp"
									// onChange={changeImage}
									page={settingsData.info.page}
									row={settingsData.info.row}
									col={settingsData.info.col}
									fieldtype='image'
								/>
								<img src="/img/Another/default-image.jpg" alt="" />
								{
									contentRow.content[0].cols[col].content[0]
										?
										(
											contentRow.content[0].cols[col].content[0].src
												?
												<img src={API_BASE_URL + contentRow.content[0].cols[col].content[0].src} alt="" />
												:
												(
													contentRow.content[0].cols[col].content[0].realSrc
														?
														<img src={contentRow.content[0].cols[col].content[0].realSrc} alt="" />
														:
														<img src="/img/Another/default-image.jpg" alt="" />
												)
										)
										:
										<img src="/img/Another/default-image.jpg" alt="" />
								}
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default FormSettingsImage;