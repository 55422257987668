import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { randomString } from '../../../help';
import { setIsEdited } from '../../../redux/reducers/dataReducer';
import Row from './Row';

const FormNavbar = (props) => {
	const { formData, setFormData, formDetails, setFormDetails, contentRow, tabData, showMessage, showMessageSuccess, saveForm, showMessageError, setTabData, tabName, setTabName, setDropType, dropType, checkAdd, setCheckAdd } = props;
	const handleDragNavs = (ev) => {
		var tabname = ev.currentTarget.getAttribute('tabname');
		setTabName(tabname);
		setDropType('tab');
		// const addTab = components.tabname;
		// console.log(<components.tabname />)
		// console.log(addTab);
	};
	const dispatch = useDispatch()
	const addRespRow = e => {
		try {
			var newTabname = e.currentTarget.getAttribute('tabname');
			const newFormData = JSON.parse(JSON.stringify(formData));
			const activePage = document.querySelectorAll('.form-page.active')[0]
			const activePageNumber = document.querySelectorAll('.form-page.active')[0].getAttribute('page')
			const allRowsNotDroped = activePage.querySelectorAll('.form-content-row-drag:not(.droped)')
			var checkLoop = false;
			// var uploadError = false;
			if (newFormData[activePageNumber].rows.length <= 10) {
				// if (newTabname === 'upload') {
				// 	var details = formDetails.details
				// 	if(details){
				// 		for (var i = 0; i < details.length; i++) {
				// 			if (details[i].type == 'upload') {
				// 				uploadError = true;
				// 				break;
				// 			}
				// 		}
				// 	}
				// 	loop:for (let i = 0; i < newFormData.length; i++) {
				// 		const forJ = newFormData[i].rows;
				// 		for (let j = 0; j < forJ.length; j++) {
				// 			const forK = forJ[j].content[0].cols
				// 			for (let k = 0; k < forK.length; k++) {
				// 				if (forK[k].tabname == 'upload') {
				// 					uploadError = true;
				// 					break loop;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
				// if (uploadError) {
				// showMessageError('"File Upload" section is already added.')
				// } else {
				if (allRowsNotDroped.length == 0) {
					checkLoop = true;
				} else {
					const dropPageIndex = allRowsNotDroped[0].getAttribute('page');
					const dropRowIndex = allRowsNotDroped[0].getAttribute('row');
					const dropColIndex = allRowsNotDroped[0].getAttribute('col');
					var dividerCheck = true;
					if (newTabname === 'dropdown') {
						let obj = {
							options: [
								{ id: 1, value: 'option1', label: 'Option1' },
								{ id: 2, value: 'option2', label: 'Option2' },
								{ id: 3, value: 'option3', label: 'Option3' },
								{ id: 4, value: 'option4', label: 'Option4' },
							]
						}
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Dropdown';
					}
					if (newTabname != 'image') {
						if ((Object).keys(newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content).length) {
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.required = false;
						} else {
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = { required: false }
						}
					}
					if (newTabname === 'input') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.typeinput = 'text';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Short Text';
					}
					if (newTabname === 'checkbox') {
						let obj = {
							options: [
								{ id: 1, value: 'option1', label: 'Option1' },
								{ id: 2, value: 'option2', label: 'Option2' },
								{ id: 3, value: 'option3', label: 'Option3' },
								{ id: 4, value: 'option4', label: 'Option4' },
							]
						}
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Multiple Choice';
					}
					if (newTabname === 'radio') {
						let obj = {
							options: [
								{ id: 1, value: 'option1', label: 'Option1' },
								{ id: 2, value: 'option2', label: 'Option2' },
								{ id: 3, value: 'option3', label: 'Option3' },
								{ id: 4, value: 'option4', label: 'Option4' },
							]
						}
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content = obj
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.filter = true;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Single Choice';
					}
					if (newTabname === 'text') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Long Text';
					}
					if (newTabname === 'upload') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'File Upload';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.limit = 1;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.size = {
							min: 0,
							max: 10,
						};
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.text = 'Select files';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.reachable = 'pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif, webp';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.types = 'pdf, doc, docx, jpg, jpeg, png, webp';
					}
					if (newTabname === 'paragraph') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Paragraph';
					}
					if (newTabname === 'image') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].width = 'auto';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].height = 'auto';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].fit = 'cover';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].align = 'center';
					}
					if (newTabname === 'empty') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Empty';
					}
					if (newTabname === 'rating') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Rating';
					}
					if (newTabname === 'divider') {
						if (newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols.length == 1 && newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[0].tabname == '') {
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Divider';
						} else {
							checkLoop = true;
							dividerCheck = false;
						}
					}
					if (newTabname === 'date') {
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].content.label = 'Date Picker';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].separator = '/';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultDateLabel = 'None';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultDateValue = '';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].checkedTime = false;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].timeFormat = '24';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultTimeLabel = 'None';
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].defaultTimeValue = 'HH:MM';
						var sep = newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].separator;
						newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].typeDate = 'dd' + sep + 'MM' + sep + 'yyyy';
					}
					// if (dividerCheck) {
					// 	newFormData[dropPageIndex].rows[newFormData[dropPageIndex].rows.length - 1].content[0].cols[0].tabname = newTabname
					// 	newFormData[dropPageIndex].rows[newFormData[dropPageIndex].rows.length - 1].content[0].cols[0].empty = false;
					// 	newFormData[dropPageIndex].rows[newFormData[dropPageIndex].rows.length - 1].content[0].cols[0].uniqueId = randomString(50);
					// 	console.log(newFormData[dropPageIndex]);
					// }
					// else {
						if(dividerCheck){
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].tabname = newTabname
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].empty = false;
							newFormData[dropPageIndex].rows[dropRowIndex].content[0].cols[dropColIndex].uniqueId = randomString(50);
						}
					// }
					setFormData(newFormData);
					setCheckAdd({ page: +dropPageIndex, row: +dropRowIndex, col: +dropColIndex });
					// saveForm(e, newFormData)
					setTimeout(() => {
						const activeLabel = document.querySelector(`.form-content-row-drag[page='${dropPageIndex}'][row='${dropRowIndex}'][col='${dropColIndex}']`)
						if (activeLabel){
							const activeText = activeLabel.querySelector('.user-select-text')
							if (activeText) {
								var p = activeText,
									s = window.getSelection(),
									r = document.createRange();
								r.setStart(p, 1);
								r.setEnd(p, 1);
								s.removeAllRanges();
								s.addRange(r);
							}
						}
					}, 1);
					setTimeout(() => {
						setCheckAdd({})
					}, 1000)
				}
				if (checkLoop) {
					// setFormData([])
					if (newFormData[activePageNumber].rows.length < 10) {
						var newRow = <Row key={newFormData[activePageNumber].rows.length + 1} />;
						newFormData[activePageNumber].rows.push({
							id: newFormData[activePageNumber].rows.length + 1,
							content: [
								{
									cols: [
										{
											id: 1,
											tabname: newTabname,
											content: newTabname === 'dropdown' ? {
												options: [
													{ id: 1, value: 'option1', label: 'Option1' },
													{ id: 2, value: 'option2', label: 'Option2' },
													{ id: 3, value: 'option3', label: 'Option3' },
													{ id: 4, value: 'option4', label: 'Option4' },
												]
											} : [],
										}
									],
								}
							]
						})
						if (newTabname != 'image') {
							if ((Object).keys(newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content).length) {
								newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.required = false;
							} else {
								newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content = { required: false }
							}
						}
						if (newTabname === 'dropdown') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.filter = true;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Dropdown';
						}
						if (newTabname === 'input') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.typeinput = 'text';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Short Text';
						}
						if (newTabname === 'checkbox') {
							let obj = {
								options: [
									{ id: 1, value: 'option1', label: 'Option1' },
									{ id: 2, value: 'option2', label: 'Option2' },
									{ id: 3, value: 'option3', label: 'Option3' },
									{ id: 4, value: 'option4', label: 'Option4' },
								]
							}
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content = obj
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.filter = true;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Multiple Choice';
						}
						if (newTabname === 'radio') {
							let obj = {
								options: [
									{ id: 1, value: 'option1', label: 'Option1' },
									{ id: 2, value: 'option2', label: 'Option2' },
									{ id: 3, value: 'option3', label: 'Option3' },
									{ id: 4, value: 'option4', label: 'Option4' },
								]
							}
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content = obj
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.filter = true;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Single Choice';
						}
						if (newTabname === 'text') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Long Text';
						}
						if (newTabname === 'paragraph') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Paragraph';
						}
						if (newTabname === 'image') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].width = 'auto';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].height = 'auto';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].fit = 'cover';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].align = 'center';
						}
						if (newTabname === 'upload') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'File Upload';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.limit = 1;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.size = {
								min: 0,
								max: 10,
							};
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.text = 'Select files';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.reachable = 'pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif, webp';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.types = 'pdf, doc, docx, jpg, jpeg, png, webp';
						}
						if (newTabname === 'empty') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Empty';
						}
						if (newTabname === 'rating') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Rating';
						}
						if (newTabname === 'divider') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Divider';
						}
						if (newTabname === 'date') {
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].content.label = 'Date Picker';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].separator = '/';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].defaultDateLabel = 'None';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].defaultDateValue = '';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].checkedTime = false;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].timeFormat = '24';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].defaultTimeLabel = 'None';
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].defaultTimeValue = 'HH:MM';
							var sep = newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].separator;
							newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].typeDate = 'dd' + sep + 'MM' + sep + 'yyyy';
						}
						newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].empty = false;
						newFormData[activePageNumber].rows[newFormData[activePageNumber].rows.length - 1].content[0].cols[0].uniqueId = randomString(50);
						setFormData(newFormData)
						setCheckAdd({ page: activePageNumber, row: newFormData[activePageNumber].rows.length - 1, col: 0 });
						// saveForm(e, newFormData)
						setTimeout(() => {
							const activeLabel = document.querySelector(`.form-content-row-content[page='${activePageNumber}'][row='${newFormData[activePageNumber].rows.length - 1}']`)
							if (activeLabel){
								const activeText = activeLabel.querySelector('.user-select-text')
								if (activeText) {
									var p = activeText,
										s = window.getSelection(),
										r = document.createRange();
									r.setStart(p, 1);
									r.setEnd(p, 1);
									s.removeAllRanges();
									s.addRange(r);
								}
							}
						}, 1);
						setTimeout(() => {
							setCheckAdd({})
						}, 1000)
						dispatch(setIsEdited(true))
						// setIsEdited(true)
					} else {
						showMessageError('The page is full.')
					}
				}
				// setTimeout(() => {
				// 	const activeLabel = document.querySelector(`.form-content-row-drag[page='${}'][row='${}'][col='${}']`)
				// 	// const activePage = document.querySelectorAll('.form-page.active')[0]
				// 	// const textsBody = activePage.querySelectorAll('.form-content-row-drag-body')
				// 	// const textLabels = textsBody[textsBody.length - 1].querySelectorAll('.user-select-text')
				// 	// if (textLabels.length > 0) {
				// 	// 	textLabels[textLabels.length - 1].focus()
				// 	// }
				// }, 1);

				// }
			} else {
				showMessageError('The page is full.')
			}
			dispatch(setIsEdited(true))
		} catch (error) {
			showMessageError('Something went wrong.')
		}

	}
	return (
		<div className="collapse navbar-collapse d-xl-block show" id="navbarNav2">
			<ul className="sidebar-nav">
				<li
					className="settings-tab"
					tabname="input"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-digital-tachograph"></i></span><p>Short Text</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="text"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-align-justify"></i></span><p>Long Text</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="image"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-image "></i></span><p>Image-static</p></button>
				</li>
				{/* <li
					className="settings-tab"
					tabname="fullname"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
				>
					<button><span><i className="fas fa-user-circle"></i></span><p>Full Name</p></button>
				</li> */}
				<li
					className="settings-tab"
					tabname="dropdown"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-chevron-circle-down"></i></span><p>Dropdown</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="radio"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-dot-circle"></i></span><p>Single Choice</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="checkbox"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-check-square"></i></span><p>Multiple Choice</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="paragraph"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-paragraph"></i></span><p>Paragraph-static</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="upload"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-upload"></i></span><p>File Upload</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="empty"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-square"></i></span><p>Empty Space</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="date"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-calendar-day"></i></span><p>Date Picker</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="divider"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-grip-lines"></i></span><p>Divider</p></button>
				</li>
				<li
					className="settings-tab"
					tabname="rating"
					draggable={true}
					onDragOver={(ev) => ev.preventDefault()}
					onDragStart={handleDragNavs}
					onClick={addRespRow}
				>
					<button><span><i className="fas fa-star"></i></span><p>Rating</p></button>
				</li>
			</ul>
		</div>
	);
};

export default FormNavbar;