import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResponsesFull, getResponsesFullData, getResponsesFullDataLoading } from '../../../../redux/reducers/responseReducer';
import Loader from '../../../../UI/Loader/Loader';

const StatResponses = () => {
	const responses = useSelector(getResponsesFullData)
	const loadingResponse = useSelector(getResponsesFullDataLoading)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getResponsesFull())
	}, [])
	return (
		<div className="col-12 col-lg-6 top-chart">
			<div className="card card-stats mb-4 mb-xl-0">
				<div className="card-body">
					<div className="row">
						<div className="col">
							<h5 className="card-title text-uppercase text-muted mb-0">Responses</h5>
							{
								loadingResponse
									?
									<Loader />
									:
									<span className="h3 font-weight-bold mb-0">{responses.length}</span>
							}
						</div>
						<div className="col-auto">
							<div className="icon icon-shape bg-warning chart-icon text-white rounded-circle shadow">
								<i className="fas fa-reply-all "></i>
							</div>
						</div>
					</div>
					{/* <p className="mt-3 mb-0 text-muted text-sm">
						<span className="text-warning mr-2"><i className="fas fa-arrow-down" /> 1.10%</span>
						<span className="text-nowrap"> Since yesterday</span>
					</p> */}
				</div>
			</div>
		</div>
	);
};

export default StatResponses;