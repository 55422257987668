import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const ForgotForm = (props) => {
	const { errors, setErrors, email, setEmail, forgot, sended, setSended } = props;
	const history = useHistory()

	const toSignin = e => {
		history.push('/signin')
	}
	return (
		<form onSubmit={forgot} className="pt-0 pt-md-4">
			<div className="mb-3 pb-md-2">
				<label className="form-label form-label-lg" forhtml="EmailAddress">Email address</label>
				{sended ? <p className="Authmessage text-dark sucses-forgot-mail">
					<svg className="img-icon sucses-forgot-svg" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
							stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					   We have sent a password recovery link to your email. </p> : ''}
				<input
					onChange={e => setEmail({ email: e.target.value })}
					type="text"
					className={errors && errors.hasOwnProperty('email') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
					id="EmailAddress"
					placeholder="Your email address"
				/>
				{errors && errors.hasOwnProperty('email') ? <p className="Authmessage Autherror"> {errors.email} </p> : null}
			</div>
			<div className="d-grid forgotBtnStyle">
				<button className="btn btn-xl btn-dark">Send reset link</button>
			</div>
			{/* <div className="my-4 d-flex pb-3">
				<a href="#" className="small text-gray-600 ms-auto mt-1 ms-auto font-weight-semibold"><u>Try a different method</u></a>
			</div> */}
			{/* <div className="border-top border-gray-200 pt-4">
				<span className="text-gray-600 small">If you’re having any trouble resetting your password, head to our <br /><a href="#" className="text-gray-600"><u>Support Page</u></a> or contact our <a href="#" className="text-gray-600"><u>Support Team</u></a></span>
			</div> */}
			<div className="forgotText border-top border-gray-200 text-center">
				<span className="text-gray-700">Remember a password? <button type="button" onClick={toSignin} className='link-warning'>Sign In</button></span>
			</div>
		</form>
	);
};

export default ForgotForm;