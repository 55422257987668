import React from "react";
import classes from './PageLoader.module.css';


const PageLoader = () => {

	return (
		<div className={classes.loaderBody}>
            <div className={classes.loader}> </div>
		</div>
	)
}

export default PageLoader;