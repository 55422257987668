import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
import Loader from '../../UI/Loader/Loader';



const TimeToComplete = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const form_id = params.id;
    const [timeToComplete, setTimeToComplete] = useState('')
    useEffect(() => {
        setLoading(true)
        axios.post(API_BASE_URL + '/api/getTimeToComplete', { form_id })
            .then(response => {
                setLoading(false)
                setTimeToComplete(response.data)
            })
            .catch(error => { setLoading(false) })
    }, [])

    return (
        <>{!loading ?
            timeToComplete
            :
            <div className="row group-cards">
                <div className="card align-items-center">
                    <Loader />
                </div>
            </div>
        }
        </>
    );
};

export default TimeToComplete;