import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL, APP_BASE_URL } from '../config';
import ForgotForm from './ForgotForm';
import ResetForm from './ResetForm';

const Reset = () => {

	const [resetData, setResetData] = useState({});
	const [errors, setErrors] = useState([]);
	const [changed, setChanged] = useState(false);
	const [token,setToken] = useState()
	useEffect(() => {
		if(window.location.pathname.split('/')[2] !== undefined){
			setToken(window.location.pathname.split('/')[2])
		}
		if (localStorage.getItem('reset')) {
			localStorage.removeItem('reset')
		} else {
			window.location.href = APP_BASE_URL + '/signin'
		}
	}, [])
	const reset = e => {
		e.preventDefault()
		resetData.token = token;
		axios.post(API_BASE_URL + '/api/resetPassword', resetData)
			.then(response => {
				setErrors([])
				setChanged(true);
				setTimeout(() => {
					window.location.href = APP_BASE_URL + '/signin'
				}, 1000)
			})
			.catch(error => {
				setErrors(error.response.data.error)
			})
	}
	useEffect(() => {

	}, [])

	return (

		// <div className="signup-template bg-gray-100">
		// 	<div className="position-absolute top-0 start-0 p-4 p-md-5">
		// 		<Link to="/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></Link>
		// 	</div>
		// 	<div className="row g-0 align-items-center">
		// 		<div className="col-lg-7">
		// 			<img src="/img/BG/Reset.svg" className="cover-fit" alt="Reset password" />
		// 		</div>
		// 		<div className="col-lg-5 px-md-3 px-xl-5">
		// 			<div className="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
		// 				<div className="login-form py-2 py-md-0 mx-auto mx-lg-0">
		// 					<h2 className="h1 mb-3">Reset password</h2>
		// 					<ResetForm resetData={resetData} setResetData={setResetData} errors={errors} setErrors={setErrors} reset={reset} changed={changed} setChanged={setChanged} />
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>


	<div className="signup-simple-template bg-gray-100">
		<div className="header-background-body signup-header text-center">
			{/* <div className="container">
					<Link to="/" className="ps-lg-3"><img width='250' src="/logo-final.png" alt="FormBuilder" /></Link>
				</div> */}
			<div className="header-background">
				{/* <img src="/img/BG/Forgot.svg" className="cover-fit" alt="Reset password" /> */}
				<img src="/logo-final.png"  alt="FormBuilder" />
			</div>
		</div>
		<div className="container header-background-content">
			<div className="simple-login-form rounded-12 shadow-dark-80 bg-white mb-0">
				<h2 className="mb-0 text-center">Reset password</h2>
				<ResetForm resetData={resetData} setResetData={setResetData} errors={errors} setErrors={setErrors} reset={reset} changed={changed} setChanged={setChanged} />
			</div>
			{/* <div className="text-center py-4">
					<span className="text-gray-600 small">If you’re having any trouble resetting your password, head to our <br className="d-none d-sm-block" /><a href="#" className="text-gray-600"><u>Support Page</u></a> or contact our <a href="#" className="text-gray-600"><u>Support Team</u></a></span>
				</div> */}
		</div>
	</div>

	);
};

export default Reset;