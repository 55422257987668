import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminUsersData } from '../../../../redux/reducers/adminReducer';
import { getUsers, getUsersData, getUsersDataLoading } from '../../../../redux/reducers/dataReducer';
import Loader from '../../../../UI/Loader/Loader';

const StatUsers = () => {
	const users = useSelector(getUsersData)
	// const users = useSelector(getAdminUsersData)
	const loadingUsers = useSelector(getUsersDataLoading)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getUsers())
	}, [])
	return (
		<div className="col-12 col-lg-6 top-chart">
			<div className="card card-stats mb-4 mb-xl-0">
				<div className="card-body">
					<div className="row">
						<div className="col">
							<h5 className="card-title text-uppercase text-muted mb-0">Users</h5>
							{
								loadingUsers
									?
									<Loader />
									:
									<span className="h3 font-weight-bold mb-0">{users.count}</span>
							}
						</div>
						<div className="col-auto">
							<div className="icon icon-shape bg-info chart-icon text-white rounded-circle shadow">
								<i className="fas fa-users"></i>
							</div>
						</div>
					</div>
					{/* <p className="mt-3 mb-0 text-muted text-sm">
						<span className="text-success mr-2"><i className="fa fa-arrow-up" /> 3.48%</span>
						<span className="text-nowrap"> Since last month</span>
					</p> */}
				</div>
			</div>
		</div>
	);
};

export default StatUsers;