import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAdminUsers,
    getAdminUsersData,
    getAdminUserTabNameData,
    getGlobalDateRangeInfo,
    getGlobalSearchTextInfo,
    getPaayingInAllInfo,
    getPaying,
    getPayingData,
    getPayingDataLoading,
    getPayingInAllInfo,
    getPayingInfo,
    setPayingInfo,
    setSearchData
} from '../../../redux/reducers/adminReducer';


const AdminUserAssort = (props) => {
    const {addData, setDataAll, setDateRange,userDate,searchData, setUserDate, table, dateRange } = props;
    const dataNow = useSelector(getAdminUsersData)
    const dispatch = useDispatch();
    const payingInfo = useSelector(getPayingInfo);
    const payingData = useSelector(getPayingData);
    const payingInAllInfo = useSelector(getPayingInAllInfo);
    const payingLoading = useSelector(getPayingDataLoading);
    const globalDateRange = useSelector(getGlobalDateRangeInfo);
	const globalSearchText = useSelector(getGlobalSearchTextInfo);
    const tabName = useSelector(getAdminUserTabNameData);
    function clearValue(e){
        dispatch(setPayingInfo(e.target.checked))
        
        // if (userDate) {
        //     setUserDate({})
        //     setDateRange([null, null])
        // }
        // if (searchData !== '') {
        //     setGlobalSearchText
        // }
        // if (e.target.checked) {
            let data = {};
            data.globalSearchTextInfo = globalSearchText;
            data.globalDateRange = globalDateRange;
            data.payingInfo = e.target.checked;
            data.tabName = tabName;
            dispatch(getPaayingInAllInfo(data))
            // dispatch(getPaying({ page: 1 }))
            // setDataAll(payingData)
        // } else {
        //     dispatch(getAdminUsers({ page: 1 }))
        // }
        // setCheckedPaying(e.target.checked);
    }

    useEffect(()=>{
        if (payingData && Object.keys(payingData).length > 0) {
            // if(dateRange){
            //     axios.post(API_BASE_URL + '/api/payingDataRange?page=' + pageNum, data)
            //             .then(response => {
                              
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             })
            // } else{ 
                setDataAll(payingData)
            // }
        }
        // setCheckedPaying(payingInfo)
    }, [payingData])

       useEffect(()=>{
        if (payingInAllInfo && Object.keys(payingInAllInfo).length > 0) {
            // if(dateRange){
            //     axios.post(API_BASE_URL + '/api/payingDataRange?page=' + pageNum, data)
            //             .then(response => {
                              
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             })
            // } else{ 
                setDataAll(payingInAllInfo)
                // setDataAll(payingData)
            // }
        }
        // setCheckedPaying(payingInfo)
    }, [payingInAllInfo])

    return (
        <>
        <div className="paying-button-check form-check form-check-inline pe-md-0 btn btn-outline-default text-gray-700 border-gray-700 px-4">
            <input className="form-check-input" id='pay-check' checked={payingInfo} onChange={(e) => clearValue(e)} type="checkbox" foroption="option1" />
            <label className="form-check-label" htmlFor='pay-check' >Paying users</label>
            
        </div>
        <div className="home__data-add">
            <button onClick={() => addData()}>Add user</button>
        </div>
			
         </>
    );
}

export default AdminUserAssort;