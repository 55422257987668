import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const FormSettingsUpload = (props) => {
	const { settingsData, checkedUpload, requiredChange, changeLimit, changeSize, changeTypes, changeText } = props;
	const [types, setTypes] = useState([])
	const reachableTypes = useRef()
	const toggleReachable = e => {
		reachableTypes.current.classList.toggle('active')
	}

	const changeRequiredField = e => {
		requiredChange(settingsData.info.page, settingsData.info.row, settingsData.info.col, 'upload', e.target.checked)
	}

	const changeLimitField = e => {
		if(e.currentTarget.value < 0){
			changeLimit(!settingsData.info.page, !settingsData.info.row, !settingsData.info.col, !e.currentTarget.value)
		}else{
		    changeLimit(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.currentTarget.value)
		}
	}

	const changeSizeField = e => {
		if(e.target.value < 0){
			changeSize(!settingsData.info.page, !settingsData.info.row, !settingsData.info.col, !e.target.value)

		}else{
			changeSize(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.target.value)
		}
	}

	const changeTypesField = (e, elem) => {
		changeTypes(settingsData.info.page, settingsData.info.row, settingsData.info.col, elem, e.target.checked)
	}

	const changeTextField = e => {
		changeText(settingsData.info.page, settingsData.info.row, settingsData.info.col, e.currentTarget.value)
	}

	return (
		<div>

			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center">
					<h6 className="font-weight-semibold mb-0">Required</h6>
					<div className="form-check form-switch mb-0 ms-auto" >
						<input className="form-check-input me-0" type="checkbox"
							checked={checkedUpload}
							onChange={changeRequiredField}
						/>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Is the field required?</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex align-items-center justify-content-between w-100">
					<h6 className="col-8 font-weight-semibold mb-0 ">Limit number of files</h6>
					<div className="form-switch mb-0 col-4" >
						<input className="form-control " type="number"
							onChange={changeLimitField}
							value={settingsData.details.upload.limit}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">Limit file max size ( MB )</h6>
					<div className="w-100 d-flex mt-4" >
						{/* <input className="form-control w-50 me-3" type="number"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fieldtype='upload'
							defaultValue={settingsData.details.upload.size.min}
						/> */}
						<input className="form-control" type="number"
							onChange={changeSizeField}
							value={settingsData.details.upload.size.max}
						/>
					</div>
				</div>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">File types</h6>
					<div className="mt-4 w-100" >
						<div className="reachables-selected">
							{
								settingsData.details.upload.types.split(', ').map((e, index) => {
									return <div className="reachable-selected btn-warning" key={index}><span>{e}</span></div>
								})
							}

						</div>
						{/* <textarea
							className="form-control me-0" type="text"
							page={settingsData.info.page}
							row={settingsData.info.row}
							col={settingsData.info.col}
							fieldtype='upload'
							onChange={changeTypes}
							defaultValue={settingsData.details.upload.types}
						/> */}
						<button type='button' className="reachable-selected-btn btn btn-lg btn-outline-dark my-4" onClick={toggleReachable}><span>Select reachable types</span></button>
						<div className='reachable-types' ref={reachableTypes}>
							{/* {
								settingsData.details.upload.reachable.split(',').map(e => {
									return <button onClick={addType} typeupload={e}><span className='reachable-type'>{e}</span></button>
								})
							} */}
							{
								settingsData.details.upload.reachable.split(', ').map((elem, index) => {
									return <div className="reachable-type" key={index}>
										<input className="form-check-input"
											type="checkbox"
											defaultChecked={
												settingsData.details.upload.types.includes(elem)
													? true
													: false
											}
											onMouseUp={(e) => changeTypesField(e, elem)}
										/>
										<label className="preview-label">{elem}</label>
									</div>
								})
							}
						</div>
					</div>
				</div>
				<p className="text-gray-600 pt-2 mb-0">Write the name extensions of the allowed file types. Separate each with a comma.</p>
			</div>
			<div className="p-4 px-lg-5 border-bottom border-gray-200">
				<div className="d-flex flex-column">
					<h6 className="font-weight-semibold mb-0">Button text</h6>
					<div className="mt-4" >
						<input className="form-control me-0" type="text"
							value={settingsData.details.upload.text}
							onChange={changeTextField}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormSettingsUpload;