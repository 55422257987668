import React, { useEffect, useRef } from 'react';

const Empty = (props) => {
	const { formData, setFormData, settingsData, responseCheck, setSettingsData, deleteThisRowContent, userData, rowError, setUserData, col, row, page, checkAdd, contentRow, preview, changeLabel, setCheckAdd } = props
	const thisElement = useRef(null)
	const thisElementScroll = useRef(null)
	useEffect(() => {
		if (thisElementScroll.current) {
			const y = thisElementScroll.current.getBoundingClientRect().top + window.scrollY;
			window.scroll({
				top: y - 150,
				behavior: 'smooth'
			});
		}
	}, [])
	const editThisRowContent = e => {
		e.stopPropagation();
	}

	return (
		<div className={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? "form-content-row-drag droped adding-animation" : "form-content-row-drag droped"}
			ref={checkAdd.page == page && checkAdd.row == row && checkAdd.col == col ? thisElementScroll : thisElement}>
			{preview
				?
				<div className="form-content-row-drag-body"></div>
				: (responseCheck
					?
					<div className="form-content-row-drag-body"></div>
					:
					<div className="form-content-row-drag-body">
						<div className="form-content-row-drag-text form-content-row-drag-empty">
                            <div className="empty-content-body">
                                <p>Empty Space</p>
                            </div>
							<div className="col-buttons">
								<div className="delete-col col-button">
									<div className="delete-col-body col-button-body">
										<button type='button' title='Delete' onClick={deleteThisRowContent} page={page} row={row} col={col}><span><i className="fas fa-times"></i></span></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default Empty;