import React from 'react';

const Sidebar = () => {
	return (
		<div className="customize-sidebar" style={{ display: "none !important" }}>
			<div className="border-bottom border-gray-200 p-3 p-md-4">
				<div className="text-end">
					<a href="#" className="btn btn-light btn-icon rounded-pill customize-close">
						<svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 16">
							<rect data-name="Icons/Tabler/Close background" width="16" height="16" fill="none"></rect>
							<path d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z" transform="translate(2 2)" fill="#1E1E1E"></path>
						</svg>
					</a>
				</div>
				<div className="px-2 px-md-4">
					<h3 className="mb-0"><img src="/svg/icons/tio-tune2.svg" alt="Tio Tune" /> Customize</h3>
					<p className="text-gray-700 mb-0 lh-lg">Choose your style and settings</p>
				</div>
			</div>
			<div className="customize-body" data-simplebar>
				<div className="p-4 px-lg-5 border-bottom border-gray-200">
					<h6 className="font-weight-semibold pb-3">Select Skin</h6>
					<div className="d-flex muze-skins customizer-controls">
						<div className="text-center pe-4">
							<div className="form-check-lg bg-white shadow-dark-80 p-4 rounded-circle avatar avatar-xl d-flex align-items-center justify-content-center">
								<input className="form-check-input mx-0 rounded-circle" type="radio" name="MuzeSkins" id="LightMode" value="muze-light-mode" checked="checked" onChange={e => { }} />
							</div>
							<label htmlFor="LightMode" className="text-gray-700 mt-2">Light mode</label>
						</div>
						<div className="text-center ps-4 dark-radio">
							<div className="form-check-lg bg-gray-800 shadow-dark-80 p-4 rounded-circle avatar avatar-xl d-flex align-items-center justify-content-center">
								<input className="form-check-input mx-0 rounded-circle" type="radio" name="MuzeSkins" id="DarkMode" value="muze-dark-mode" />
							</div>
							<label htmlFor="DarkMode" className="text-gray-700 mt-2">Dark mode</label>
						</div>
					</div>
				</div>
				<div className="p-4 px-lg-5 border-bottom border-gray-200">
					<div className="d-flex align-items-center">
						<h6 className="font-weight-semibold mb-0">RTL Mode</h6>
						<div className="form-check form-switch mb-0 ms-auto">
							<input className="form-check-input me-0" type="checkbox" id="RTLMode" value="muze-rtl" />
						</div>
					</div>
					<p className="text-gray-600 pt-2 mb-0">Switch layout direction</p>
				</div>
				<div className="p-4 px-lg-5 border-bottom border-gray-200">
					<div className="d-flex align-items-center">
						<h6 className="font-weight-semibold mb-0">Fluid Layout</h6>
						<div className="form-check form-switch mb-0 me-0 ms-auto">
							<input className="form-check-input me-0" type="checkbox" id="FluidLayout" value="container" onChange={e => { }} />
						</div>
					</div>
					<p className="text-gray-600 pt-2 mb-0">Toggle between full width and contained layouts</p>
				</div>
			</div>
			<div className="p-4 px-lg-5 border-top border-gray-200 bg-white">
				<div className="row">
					<div className="col-6 d-grid">
						<a href="#" className="btn btn-xl btn-outline-default" id="ResetCustomizer">Reset</a>
					</div>
					<div className="col-6 d-grid">
						<a href="#" className="btn btn-xl btn-dark" id="CustomizerPreview">Preview</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;