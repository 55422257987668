import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import MyButton from '../../../UI/button/MyButton';
import {API_BASE_URL, APP_BASE_URL} from '../../../config';
import {useDispatch, useSelector} from 'react-redux';
import Pagination from 'react-laravel-paginex/dist/Pagination';
import SearchAdmin from './SearchAdmin';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {
    getAdminDataLoading,
    getAdminSearchData,
    getAdminUsers,
    getGlobalDateRangeInfo,
    getGlobalPaymentTabNameData,
    getGlobalSearchTextInfo,
    getPayingDataLoading,
    getPayingInfo,
    getSolvedCustomers,
    getSolvedMessages,
    getSuspended,
    setNotSolvedCustomers,
    setNotSolvedMessages,
    setSolvedMessages
} from '../../../redux/reducers/adminReducer';
import moment from 'moment';
import AdminUserAssort from './AdminUserAssort';
import Loader from '../../../UI/Loader/Loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMoneyBill1Wave} from '@fortawesome/free-solid-svg-icons';


const AdminTable = (props) => {
    let {
        tabNameShow,
        addData,
        tabName,
        dataAll,
        setDataAll,
        getData,
        setModal,
        setModalData,
        setTableData,
        dataRangeFunction,
        table,
        dispatchName,
        dataNow,
        setDateRange,
        startDate,
        endDate,
        userDate,
        setUserDate,
        setSearchInfo,
        searchInfo,
        tabNameInSolved,
        setCountNotSolved,
        setCountSolved,
        tabNameInPayment,
        setCountPaid,
        setCountRejected,
        setPayingInfo,
        setCountSuspended,
        dateRange
    } = props
    const [showButtonDelete, setShowButtonDelete] = useState(true)
    const getPaymentGlobalTabName = useSelector(getGlobalPaymentTabNameData)
    const [showButtonEdit, setShowButtonEdit] = useState(true)
    const [showButtonPaying, setShowButtonPaying] = useState(true)
    const dispatch = useDispatch();
    const searchData = useSelector(getAdminSearchData);
    const payingInfo = useSelector(getPayingInfo);
    const payingLoading = useSelector(getPayingDataLoading);
    const adminLoading = useSelector(getAdminDataLoading)
    const globalDateRange = useSelector(getGlobalDateRangeInfo);
    const globalSearchTextInfo = useSelector(getGlobalSearchTextInfo)

    const deleteData = (data) => {
        let pageNum = dataAll.current_page;
        if (dataAll.data.length - 1 == 0) {
            pageNum = pageNum - 1
        }
        if ((table == 'User' || table === '' || table === undefined) && Object.keys(userDate).length > 0) {
            // data.push(userDate)
            data.userDate = userDate
        }
        if (table == 'User' || table === '' || table === undefined) {
            data.payingInfo = payingInfo;
            data.globalDateRange = globalDateRange;
            data.globalSearchTextInfo = globalSearchTextInfo;
        }
        if (searchData.length > 0) {
            data.searchData = searchData
        }
        if ((table === 'ContactUs' || table === 'Customer')) {
            data.tabNameInSolved = tabNameInSolved
        }
        if (table === 'Payment') {
            data.table = getPaymentGlobalTabName === 'Paid' ? "Payment" : "FailedPayment";
        }
        if (payingInfo) {
            data.payingInfo = payingInfo
        }
        Swal.fire({
            title: 'Are you sure?',
            text: 'This field will be removed and it will not be possible to restore',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Accept',
            cancelButtonText: 'Close'
        }).then((result) => {
            if (result.isConfirmed) {
                if (table == 'ContactUs' || table == 'Customer' || table == 'Payment') {
                    axios.post(API_BASE_URL + '/api/deleteDoubleTableData?page=' + pageNum, data)
                        .then(response => {
                            if (table == 'ContactUs' || table == 'Customer') {
                                setDataAll(response.data)
                                tabNameInSolved === "Not Solved" ? setCountNotSolved(prev => prev - 1) :
                                    setCountSolved(prev => prev - 1)
                            } else {
                                setDataAll(response.data);

                                getPaymentGlobalTabName === "Paid" ? setCountPaid(prev => prev - 1) :
                                    setCountRejected(prev => prev - 1)
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    axios.post(API_BASE_URL + '/api/deleteTableData?page=' + pageNum, data)
                        .then(response => {
                            setDataAll(response.data)
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
                Swal.fire({
                    icon: 'error',
                    title: 'Field deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }
    const deleteForm = (data) => {
        let pageNum = dataAll.current_page;
        if (dataAll.data.length - 1 == 0) {
            pageNum = pageNum - 1
        }
        Swal.fire({
            title: 'Are you sure?',
            text: 'This form will be removed and it will not be possible to restore',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Accept',
            cancelButtonText: 'Close'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_BASE_URL + '/api/deleteTableData?page=' + pageNum, data)
                    .then(response => {
                        setDataAll(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    })

                Swal.fire({
                    icon: 'error',
                    title: 'Form deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }
    const deleteUser = (data) => {
        let pageNum = dataAll.current_page;
        if (dataAll.data.length - 1 == 0) {
            pageNum = pageNum - 1
        }
        Swal.fire({
            title: 'Are you sure?',
            text: 'This user will be removed and it will not be possible to restore',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Accept',
            cancelButtonText: 'Close'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_BASE_URL + '/api/deleteTableData?page=' + pageNum, data)
                    .then(response => {
                        setDataAll(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    })

                Swal.fire({
                    icon: 'error',
                    title: 'User deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }
    const makeThePayment = (e, Id) => {
        e.preventDefault()
        let data = {};
        data.userId = Id;
        data.searchInfo = '';
        if (searchInfo) {
            data.searchInfo = searchInfo;
        }
        let pageNum = dataAll.current_page;
        if (dataAll.data.length - 1 == 0) {
            pageNum = pageNum - 1
        }
        Swal.fire({
            title: 'Are you sure?',
            text: 'By clicking here you will pay for the subscription of this user',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Accept',
            cancelButtonText: 'Close'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(API_BASE_URL + '/api/makeThePayment?page=' + pageNum, data)
                    .then(response => {
                        setDataAll(response.data)
                        setCountSuspended(prev => prev - 1)
                    })
                    .catch(error => {
                        console.log(error);
                    })
                Swal.fire({
                    icon: 'success',
                    title: 'has been paid',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }
    const getCreatedAtFormate = ($data) => {
        return (moment($data).format("DD/MM/YYYY HH:mm:ss"))
    }
    useEffect(() => {
        if (table === "User" || table === "ContactUs" || table === "FormResponse" || table === "Customer" || table === "Payment" || table === "Suggestions") {
            setShowButtonDelete(true)
        } else {
            setShowButtonDelete(false)
        }
        if (table === "User" || table === "ContactUs" || table === "Form" || table === "Customer") {
            setShowButtonEdit(true)
        } else {
            setShowButtonEdit(false)
        }
        if (table === "Payment") {
            setShowButtonPaying(true)
        } else {
            setShowButtonPaying(false)
        }

    }, [table])
    const editData = (data) => {
        setModal(true)
        const tableData = dataAll.data.filter(elem => elem._id === data.field)[0]
        setModalData(tableData)
        setTableData(data)
    }
    const solvedProblem = (e, id) => {
        e.preventDefault()
        let pageNum = dataAll.current_page
        if (dataAll.data.length - 1 == 0) {
            pageNum = pageNum - 1
        }
        let data = {};
        data.id = id;
        data.searchInfo = searchInfo;
        data.table = table;
        axios.post(API_BASE_URL + '/api/solvedProblem?page=' + pageNum, data)
            .then(response => {
                if (table === "ContactUs") {
                    dispatch(getSolvedMessages({page: 1}))
                    dispatch(setNotSolvedMessages(response.data))
                } else {
                    dispatch(getSolvedCustomers({page: 1}))
                    dispatch(setNotSolvedCustomers(response.data))
                }
            })
            .catch(error => {
            })
    }
    return (
        <>
            <div className="d-flex">
                {
                    <div>
                        <SearchAdmin
                            tabNameInPayment={getPaymentGlobalTabName}
                            dataAll={dataAll}
                            tabNameInSolved={tabNameInSolved}
                            setSearchInfo={setSearchInfo}
                            dataNow={dataNow}
                            dispatchName={dispatchName}
                            table={table}
                            setDataAll={setDataAll}
                            dateRange={dateRange}
                            userDate={userDate}
                            setDateRange={setDateRange}
                            setUserDate={setUserDate}
                        />
                    </div>
                }
                {
                    table === 'User'
                        ? <div className="dropdown export-dropdown d-flex align-items-center datepicker-dropdown mb-4">
                            <div className="px-3 InputFromTo response-daypicker">
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        if (update) {
                                            dataRangeFunction(update)
                                            // setDateRange(update);
                                        }
                                    }}
                                    placeholderText="Select users date range"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        : table === 'Payment'
                            ? <div className="dropdown export-dropdown d-flex align-items-center datepicker-dropdown mb-4">
                                <div className="px-3 InputFromTo response-daypicker">

                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            if (update) {
                                                dataRangeFunction(update)
                                            }
                                        }}
                                        placeholderText="Select payment date range"
                                        isClearable={true}
                                    />

                                </div>
                            </div>
                            : null
                }

                {
                    table === 'User'
                        ?
                        <AdminUserAssort
                            table={table}
                            setDateRange={setDateRange}
                            dateRange={dateRange}
                            setUserDate={setUserDate}
                            setDataAll={setDataAll}
                            userDate={userDate}
                            searchData={searchData}
                            addData={addData}
                        />
                        : null
                }

            </div>
            {
                adminLoading
                    ? <div className="row group-cards">
                        <div className="col-xl-6 mb-4 form-content">
                            <div
                                className="card rounded-12 shadow-dark-80 border border-gray-200 h-100 px-4 py-4 align-items-center">
                                <Loader/>
                            </div>
                        </div>
                    </div>
                    :
                    <table className="table">
                        <thead>
                        <tr>
                            {
                                tabName ?
                                    Object.keys(tabName).map((nameTab, idx) => (
                                        table === "User" ?
                                            nameTab === 'Forms' ? null
                                                : nameTab === 'Email' ? null
                                                    : nameTab === 'Deleted' ? null
                                                        : nameTab === 'AvailableForms' ? <th key={idx}
                                                                                             scope="col">{nameTab === 'Blocked' ? 'Status' : 'Forms'}</th>
                                                            : <th key={idx}
                                                                  scope="col">{nameTab === 'Blocked' ? 'Status' : nameTab}</th> :

                                            table === "Payment" ? getPaymentGlobalTabName === "Rejected" ? nameTab === "Plan" ?
                                                        <th key={idx} scope="col"></th>
                                                        : <th key={idx}
                                                              scope="col">{nameTab === 'Blocked' ? 'Status' : nameTab}</th>
                                                    : <th key={idx}
                                                          scope="col">{nameTab === 'Blocked' ? 'Status' : nameTab}</th>
                                                : <th key={idx}
                                                      scope="col">{nameTab === 'Blocked' ? 'Status' : nameTab}</th>


                                    ))
                                    : null
                            }
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataAll.data &&
                            dataAll.data.map((data, indx) => (
                                <tr key={indx}>
                                    {
                                        tabName ?
                                            Object.keys(tabName).map((nameTab, idx) => (
                                                nameTab === "Created" || nameTab === "Responsed" || nameTab === "Sended" || nameTab === "Paid" ?
                                                    <td key={idx}>
                                                        <div
                                                            className='big-text-scroll'> {getCreatedAtFormate(data[tabName[nameTab]])} </div>
                                                    </td> :
                                                    // nameTab === "Registered" || nameTab === "Sent" || nameTab === "Created" || nameTab === "Responsed"? <td key={idx}> <div className='big-text-scroll'> {getCreatedAtFormate(data[tabName[nameTab]])} </div></td>:
                                                    table === "FormResponse" ?
                                                        nameTab === "Form" ?
                                                            <td key={idx}>
                                                                {/* <div className='big-text-scroll'> {data[tabName[nameTab]]} </div> */}
                                                                <div
                                                                    className='big-text-scroll'> {data.form ? data.form[tabName[nameTab]] : ""} </div>
                                                            </td> :
                                                            nameTab === "Response" ?
                                                                <td key={idx}>
                                                                    <div className='big-text-scroll'><a
                                                                        className='response-link'
                                                                        href={APP_BASE_URL + "/response/" + data._id}
                                                                        target="_blank">{data[tabName[nameTab]]}</a>
                                                                    </div>
                                                                </td> :
                                                                nameTab === "Responsed" ?
                                                                    <td key={idx}>
                                                                        <div
                                                                            className='big-text-scroll'>{data[tabName[nameTab]]} </div>
                                                                    </td> : null
                                                        :
                                                        table === "User" ?
                                                            nameTab === "Forms" ? null
                                                                : nameTab === "Popup" ?
                                                                    data?.popup ?
                                                                        <td key={idx}>
                                                                            <div
                                                                                className='big-text-scroll'>{data.popup} </div>
                                                                        </td>
                                                                        : <td key={idx}>
                                                                            <div className='big-text-scroll'>0</div>
                                                                        </td>
                                                                    : nameTab === "Responses"
                                                                        ?
                                                                        (data?.formsadmin?.length > 0
                                                                                ?
                                                                                <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data.formsadmin.reduce((aggr, val) => {
                                                                                        return aggr + val.responses.length
                                                                                    }, 0)} </div>
                                                                                </td>
                                                                                : <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'> 0
                                                                                    </div>
                                                                                </td>
                                                                        )
                                                                        : nameTab === "Verify" ? <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data[tabName[nameTab]] === "0" ? "not verified" : "verified"} </div>
                                                                            </td>
                                                                            : nameTab === "Registered" ? <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data[tabName[nameTab]] === '0' ? "not verified" : "verified"} </div>
                                                                                </td>
                                                                                : nameTab === "Blocked" ? <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'> {data[tabName[nameTab]] == 0 ? 'active' : 'suspended'} </div>
                                                                                    </td>
                                                                                    : nameTab === "Deleted" ? null
                                                                                        //: <td key={idx}> <div className='big-text-scroll'>{data[tabName[nameTab]]} </div></td>
                                                                                        : nameTab === "Email" ? null
                                                                                            : nameTab === "Fullname" ?
                                                                                                <td key={idx}>
                                                                                                    <div
                                                                                                        className='big-text-scroll'>{data[tabName[nameTab]]}<br/>
                                                                                                        <p>{data[tabName['Email']]}</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                                : nameTab === "LoginDate" ?
                                                                                                    <td key={idx}>
                                                                                                        <div
                                                                                                            className='big-text-scroll'>{data.logged_date ? data.logged_date : ''}</div>
                                                                                                    </td>
                                                                                                    : nameTab === "AvailableForms" ? data?.formsadmin ?
                                                                                                            <td key={idx}>
                                                                                                                <div
                                                                                                                    className='big-text-scroll'>{data.formsadmin.length}/{data[tabName[nameTab]]} </div>
                                                                                                            </td> :
                                                                                                            <td key={idx}>
                                                                                                                <div
                                                                                                                    className='big-text-scroll'> 0/{data[tabName[nameTab]]} </div>
                                                                                                            </td>
                                                                                                        : <td key={idx}>
                                                                                                            <div
                                                                                                                className='big-text-scroll'>{data[tabName[nameTab]]} </div>
                                                                                                        </td>
                                                            :
                                                            table === "Form" ?
                                                                nameTab === "Email" ?
                                                                    data?.useremail ?
                                                                        <td key={idx}>
                                                                            <div
                                                                                className='big-text-scroll'>{data.useremail['email']} </div>
                                                                        </td>
                                                                        : <td key={idx}>
                                                                            <div
                                                                                className='big-text-scroll'>{data[tabName[nameTab]]} </div>
                                                                        </td> :
                                                                    nameTab === "Responses" ?
                                                                        data?.responses ?
                                                                            <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data.responses.length} </div>
                                                                            </td>
                                                                            : <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data[tabName[nameTab]]} </div>
                                                                            </td> :
                                                                        nameTab === "Link" ?
                                                                            <td key={idx}>
                                                                                <div className='big-text-scroll'>
                                                                                    <a className='response-link text-decoration-none'
                                                                                       href={APP_BASE_URL + '/f-' + data[tabName[nameTab]]}
                                                                                       target="_blank">{data[tabName[nameTab]]}</a>
                                                                                </div>
                                                                            </td>
                                                                            : nameTab === "Details" ? <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data?.details?.map(detail => {
                                                                                        return detail.type + ' ';
                                                                                    })} </div>
                                                                                </td>
                                                                                : <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data[tabName[nameTab]]} </div>
                                                                                </td> :
                                                                table === 'Report' ?
                                                                    nameTab === "Link" ?
                                                                        <td key={idx}>
                                                                            <div>
                                                                                <a className='response-link text-decoration-none'
                                                                                   href={APP_BASE_URL + '/f-' + data?.link}
                                                                                   target="_blank">{data?.link}
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                        :
                                                                        nameTab === "Author" ?
                                                                            <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data?.firstname}</div>
                                                                            </td>
                                                                            :
                                                                            nameTab === "Email" ?
                                                                                <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data?.email}</div>
                                                                                </td>
                                                                                :
                                                                                <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'>{data[tabName[nameTab]]}
                                                                                    </div>
                                                                                </td>
                                                                    :
                                                                    table === 'Suggestions' ?

                                                                                nameTab === "Email" ?
                                                                                    <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'>{data?.user?.email}</div>
                                                                                    </td>
                                                                                    :
                                                                                    nameTab === "Name" ?
                                                                                    <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'>{data?.user?.firstname}</div>
                                                                                    </td>
                                                                                    :
                                                                                    nameTab === "Surname" ?
                                                                                    <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'>{data?.user?.lastname}</div>
                                                                                    </td>
                                                                                    :
                                                                        nameTab === "Email" ?
                                                                            <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data?.user?.email}</div>
                                                                            </td>
                                                                            :

                                                                            <td key={idx}>
                                                                                <div
                                                                                    className='big-text-scroll'>{data[tabName[nameTab]]}
                                                                                </div>
                                                                            </td>
                                                                        :
                                                                        table === "ContactUs" || table === "Customer" ?
                                                                            nameTab === "Solved" ?
                                                                                tabNameInSolved === "Not Solved" ?
                                                                                    <td key={idx}>
                                                                                        <button
                                                                                            onClick={e => solvedProblem(e, data._id)}>to
                                                                                            decide
                                                                                        </button>
                                                                                    </td>
                                                                                    : <td key={idx}>Solved</td> :
                                                                                    nameTab === "Name" ?
                                                                                    <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'>{data.fullName? data?.fullName?.split(" ")[0] : data?.Fname}</div>
                                                                                    </td>
                                                                                    :
                                                                                    nameTab === "Surname" ?
                                                                                    <td key={idx}>
                                                                                        <div
                                                                                            className='big-text-scroll'>{data.fullName? data?.fullName?.split(" ")[1] : data?.Lname}</div>
                                                                                    </td>
                                                                                    :
                                                                                <td key={idx}>
                                                                                     <div
                                                                                        className='big-text-scroll'> {data[tabName[nameTab]]} </div>
                                                                                </td> :
                                                                            (table === "Payment" && data.paymentuser) || (table === "Payment" && getPaymentGlobalTabName === "Suspended") ?
                                                                                getPaymentGlobalTabName === "Paid" || getPaymentGlobalTabName === "Rejected" ?
                                                                                    nameTab === "Name" ?
                                                                                        <td key={idx}>
                                                                                            <div
                                                                                                className='big-text-scroll'> {data.paymentuser['firstname']} </div>
                                                                                        </td> :
                                                                                        nameTab === "Email" ?
                                                                                            <td key={idx}>
                                                                                                <div
                                                                                                    className='big-text-scroll'> {data.paymentuser['email']} </div>
                                                                                            </td> :
                                                                                            nameTab === "Registered" ?
                                                                                                <td key={idx}>
                                                                                                    <div
                                                                                                        className='big-text-scroll'> {getCreatedAtFormate(data.paymentuser['created_at'])} </div>
                                                                                                </td> :
                                                                                                nameTab === "Amount" ?
                                                                                                    <td key={idx}>
                                                                                                        <div
                                                                                                            className='big-text-scroll'> {data[tabName[nameTab]]}$
                                                                                                        </div>
                                                                                                    </td> :
                                                                                                    nameTab === "Plan" ?
                                                                                                        <td key={idx}>
                                                                                                            <div
                                                                                                                className='big-text-scroll'> {data.paymentuser['plan']} </div>
                                                                                                        </td> :
                                                                                                        nameTab === "Created" ?
                                                                                                            <td key={idx}>
                                                                                                                <div
                                                                                                                    className='big-text-scroll'> {getCreatedAtFormate(data[tabName[nameTab]])} </div>
                                                                                                            </td> :
                                                                                                            nameTab === "Status" ?
                                                                                                                <td key={idx}>
                                                                                                                    <div
                                                                                                                        className={getPaymentGlobalTabName === 'Paid' ? 'big-text-scroll text-success' : 'big-text-scroll text-danger'}> {getPaymentGlobalTabName === 'Paid' ? 'Paid' : 'Rejected'} </div>
                                                                                                                </td>
                                                                                                                :
                                                                                                                <td key={idx}>
                                                                                                                    <div
                                                                                                                        className='big-text-scroll'> {data[tabName[nameTab]]} </div>
                                                                                                                </td> :
                                                                                    getPaymentGlobalTabName === "Suspended" ?
                                                                                        nameTab === "Suspended" ?
                                                                                            <td key={idx}>
                                                                                                <div
                                                                                                    className='big-text-scroll'> {data[tabName[nameTab]] == 0 ? 'active' : 'suspended'} </div>
                                                                                            </td> :
                                                                                            <td key={idx}>
                                                                                                <div
                                                                                                    className='big-text-scroll'> {data[tabName[nameTab]]} </div>
                                                                                            </td> :
                                                                                        <td key={idx}>
                                                                                            <div
                                                                                                className='big-text-scroll'> {data[tabName[nameTab]]} </div>
                                                                                        </td>

                                                                                : <td key={idx}>
                                                                                    <div
                                                                                        className='big-text-scroll'> {data[tabName[nameTab]]} </div>
                                                                                </td>

                                            ))
                                            : null
                                    }


                                    {
                                        table === "User" ?
                                            <td>
                                                <div className="data-buttons">
                                                    {

                                                        <MyButton onClick={() => editData({
                                                            table: table,
                                                            field: data._id
                                                        })}><i className="fa fa-edit"></i></MyButton>
                                                    }
                                                    {
                                                        showButtonDelete ? getPaymentGlobalTabName === "Suspended" ? null :
                                                            <MyButton onClick={() => deleteData({
                                                                table: table,
                                                                field: data._id
                                                            })}><i className="fa fa-trash"></i></MyButton> : null
                                                    }
                                                    {
                                                        getPaymentGlobalTabName === "Suspended" ? <MyButton
                                                            onClick={(e) => makeThePayment(e, data._id)}><FontAwesomeIcon
                                                            icon={faMoneyBill1Wave}/></MyButton> : null
                                                    }
                                                </div>
                                            </td>
                                            : null
                                    }

                                    {
                                        table === "User" ? null :
                                            <td>
                                                {table === 'Report' ?
                                                    <div>
                                                        <a href="#" role="button" data-bs-toggle="dropdown"
                                                           aria-expanded="false"
                                                           className="btn btn-dark-100 btn-icon btn-sm rounded-circle">
                                                            <svg data-name="Icons/Tabler/Notification"
                                                                 xmlns="http://www.w3.org/2000/svg" width="13.419"
                                                                 height="13.419" viewBox="0 0 13.419 13.419">
                                                                <rect data-name="Icons/Tabler/Dots background"
                                                                      width="13.419" height="13.419" fill="none"/>
                                                                <path
                                                                    d="M0,10.4a1.342,1.342,0,1,1,1.342,1.342A1.344,1.344,0,0,1,0,10.4Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,10.4ZM0,5.871A1.342,1.342,0,1,1,1.342,7.213,1.344,1.344,0,0,1,0,5.871Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,5.871ZM0,1.342A1.342,1.342,0,1,1,1.342,2.684,1.344,1.344,0,0,1,0,1.342Zm1.15,0a.192.192,0,1,0,.192-.192A.192.192,0,0,0,1.15,1.342Z"
                                                                    transform="translate(5.368 0.839)" fill="#6c757d"/>
                                                            </svg>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <button type="button" className="dropdown-item">
                                                            <span className="ms-2" onClick={() => deleteData({
                                                                table: table,
                                                                field: data?.form_id
                                                            })}>Delete Report</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type="button" className="dropdown-item">
                                                            <span className="ms-2" onClick={() => deleteForm({
                                                                table: 'form_report_response',
                                                                field: data?.form_id
                                                            })}>Delete Form</span>
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type="button" className="dropdown-item">
                                                            <span className="ms-2" onClick={() => deleteUser({
                                                                table: 'form_user',
                                                                field: data?.user_id
                                                            })}>Delete User</span>
                                                                </button>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                    :
                                                    <div className="data-buttons">
                                                        {
                                                            table === "Customer" || table === "ContactUs" || table === "Suggestions" ? null
                                                                : <MyButton onClick={() => editData({
                                                                    table: table,
                                                                    field: data._id
                                                                })}><i className="fa fa-edit"></i></MyButton>
                                                        }
                                                        {
                                                            showButtonDelete ? getPaymentGlobalTabName === "Suspended" ? null :
                                                                <MyButton onClick={() => deleteData({
                                                                    table: table,
                                                                    field: data._id
                                                                })}><i className="fa fa-trash"></i></MyButton> : null
                                                        }
                                                        {
                                                            getPaymentGlobalTabName === "Suspended" ? <MyButton
                                                                onClick={(e) => makeThePayment(e, data._id)}><FontAwesomeIcon
                                                                icon={faMoneyBill1Wave}/></MyButton> : null
                                                        }
                                                    </div>}
                                            </td>
                                    }

                                </tr>

                            ))
                        }
                        </tbody>
                    </table>
            }

            {
                dataAll.data?.length > 0
                    ?
                    dataAll.total > 10
                        ?
                        <div
                            className="d-flex align-items-center p-3 p-md-4 border-top border-gray-200 responses-table">
                            <div className="mx-auto pagination">
                                <Pagination
                                    changePage={getData}
                                    data={dataAll}
                                    buttonIcons={true}
                                />
                            </div>
                        </div>
                        : null
                    : <p className="p-4">No Data</p>
            }
        </>
    );

};

export default AdminTable;