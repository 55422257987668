import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const ResetForm = (props) => {
	const { errors, setErrors, resetData, setResetData, reset, sended, setSended, changed } = props;
	const [passwordShowCheck, setPasswordShowCheck] = useState(false)
	const [passwordShowCheckConfirm, setPasswordShowCheckConfirm] = useState(false)
	const history = useHistory()

	const toSignin = e => {
		history.push('/signin')
	}
	const passwordShow = (e) => {
		e.preventDefault()
		setPasswordShowCheck(!passwordShowCheck);
	}
	const passwordShowConfirm = (e) => {
		e.preventDefault()
		setPasswordShowCheckConfirm(!passwordShowCheckConfirm);
	}
	return (
		<form onSubmit={reset} className="pt-0 pt-md-4">
			{changed ? <p className="reset-password-succes mb-3"> 
				<svg className="img-icon sucses-forgot-svg" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
					<path d="M29.3327 14.7725V15.9992C29.331 18.8744 28.4 21.6721 26.6785 23.9749C24.9569 26.2778 22.5371 27.9625 19.7798 28.7777C17.0226 29.5929 14.0757 29.495 11.3786 28.4986C8.68159 27.5022 6.37889 25.6606 4.81396 23.2486C3.24904 20.8365 2.50574 17.9833 2.69492 15.1143C2.8841 12.2453 3.99562 9.51427 5.86372 7.3286C7.73182 5.14293 10.2564 3.61968 13.0609 2.98603C15.8655 2.35238 18.7997 2.64228 21.426 3.8125"
						stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M29.3333 5.33398L16 18.6807L12 14.6807" stroke="#16CF96" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			Your password is successfully changed. </p> : ''}
			<div className="mb-4 pb-md-2">
				<label className="form-label form-label-lg" forhtml="EmailAddress">New password</label>
				{errors && errors.hasOwnProperty('newPassword') ? <p className="Authmessage Autherror"> {errors.newPassword} </p> : null}
				<div className='passwordShow'>
					<input
						onChange={e => setResetData({ ...resetData, newPassword: e.target.value })}
						type={passwordShowCheck ? "text" : "password"}
						className="form-control form-control-xl passwordShowInput"
						placeholder="Please enter a new password"
					/>
					<button type="button" onClick={passwordShow}>
						{passwordShowCheck
							? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
							: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
						}
					</button>
				</div>
			</div>
			<div className="mb-4 pb-md-2">
				<label className="form-label form-label-lg" forhtml="EmailAddress">Confirm new password </label>
				{errors && errors.hasOwnProperty('confirmNewPassword') ? <p className="Authmessage Autherror"> {errors.confirmNewPassword} </p> : null}
				<div className='passwordShow'>
					<input
						onChange={e => setResetData({ ...resetData, confirmNewPassword: e.target.value })}
						type={passwordShowCheckConfirm ? "text" : "password"}
						className="form-control form-control-xl passwordShowInput"
						placeholder="Please confirm your password"
					/>
					<button type="button" onClick={passwordShowConfirm}>
						{passwordShowCheckConfirm
							? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
							: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
						}
					</button>
				</div>
			</div>
			<div className="d-grid">
				<button className="btn btn-xl btn-dark">Change password</button>
			</div>
			{/* <div className="my-4 d-flex pb-3">
				<a href="#" className="small text-gray-600 ms-auto mt-1 ms-auto font-weight-semibold"><u>Try a different method</u></a>
			</div> */}
			{/* <div className="border-top border-gray-200 pt-4">
				<span className="text-gray-600 small">If you’re having any trouble resetting your password, head to our <br /><a href="#" className="text-gray-600"><u>Support Page</u></a> or contact our <a href="#" className="text-gray-600"><u>Support Team</u></a></span>
			</div> */}
			<div className="border-top border-gray-200 pt-4">
				<span className="text-gray-700">Not change a password? <button type="button" onClick={toSignin} className='link-warning'>Sign In</button></span>
			</div>
		</form >
	);
};

export default ResetForm;