import React, { useLayoutEffect, useState } from 'react';
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/Input/MyInput';
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';

const SignupFormMembers = (props) => {
	const { errors, setErrors, files, setFiles, signupData, setSignupData, signup } = props;
	const params = useParams();
	const [memberInfo, setMemberInfo] = useState({})
	const [loading, setLoading] = useState(false)

	useLayoutEffect(()=>{
		let memberId = params.id;
		let data = {};
		data.memberId = memberId
		axios.post(API_BASE_URL + '/api/getSignupMemberData', data)
		.then(response => {
			setLoading(false)
			// setMemberInfo(response.data)
			setSignupData({ ...signupData, email: response.data.email})
		})
		.catch(error => {
			if(error.response.data['error']['confirm']){
				window.location.href = '/signin'
			}
			setLoading(false)
			setErrors(error.response.data['error'])
		})
    }, [])

	// useEffect(()=>{
	// 	let userId = params.id;
	// 	let data = {};
	// 	data.userId = userId
	// 	axios.post(API_BASE_URL + '/api/getSignupMemberData', data)
	// 	.then(response => {
	// 		setLoading(false)
	// 		// setMemberInfo(response.data)
	// 		setSignupData({ ...signupData, email: response.data.email})
	// 	})
	// 	.catch(error => {
	// 		setLoading(false)
	// 		setErrors(error.response.data['error'])
	// 	})
	// },[])
	const [passwordShowCheck, setPasswordShowCheck] = useState(false)
	const [passwordShowCheckConfirm, setPasswordShowCheckConfirm] = useState(false)
	const passwordShow = (e) => {
		e.preventDefault()
		setPasswordShowCheck(!passwordShowCheck);
	}
	const passwordShowConfirm = (e) => {
		e.preventDefault()
		setPasswordShowCheckConfirm(!passwordShowCheckConfirm);
	}

	return (
		<form onSubmit={signup} className="pt-2">
			<div className="row">
				<div className="col-md-6 mb-4 pb-md-2">
					{/*<label className="form-label form-label-lg" htmlFor="FirstName">First name*</label>*/}
					<input
						className={errors && errors.hasOwnProperty('firstname') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
						onChange={e => setSignupData({ ...signupData, firstname: e.target.value })}
						type="text"
						id="FirstName"
						placeholder="First name"
					/>
					{errors && errors.hasOwnProperty('firstname') ? <p className="Authmessage text-red"> {errors.firstname} </p> : null}
				</div>
				<div className="col-md-6 mb-4 pb-md-2">
					{/*<label className="form-label form-label-lg" htmlFor="LastName">Last name*</label>*/}
					<input
						onChange={e => setSignupData({ ...signupData, lastname: e.target.value })}
						type="text"
						className={errors && errors.hasOwnProperty('lastname') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
						id="LastName"
						placeholder="Last name"
					/>
					{errors && errors.hasOwnProperty('lastname') ? <p className="Authmessage text-red"> {errors.lastname} </p> : null}
				</div>
			</div>
			<div className="mb-4 pb-md-2">
				<input
					onChange={e => setSignupData({ ...signupData, email: e.target.value })}
					type="text"
					className={errors && errors.hasOwnProperty('email') ? "form-control form-control-xl borderColorRed" : "form-control form-control-xl"}
					id="EmailAddress"
					value={signupData.email || ''}
					placeholder="Email"
				/>
				{errors && errors.hasOwnProperty('email') ? <p className="Authmessage text-red"> {errors.email} </p> : null}
			</div>
			<div className="mb-4 pb-md-2">
				<div className='passwordShow'>
					<input
						onChange={e => setSignupData({ ...signupData, password: e.target.value })}
						type={passwordShowCheck ? "text" : "password"}
						className={errors && errors.hasOwnProperty('password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
						id="Password"
						placeholder="Password"
					/>
					<button type="button" onClick={passwordShow}>
						{passwordShowCheck
							? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
							: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
						}
					</button>
				</div>
				{errors && errors.hasOwnProperty('password') ? <p className="Authmessage text-red"> {errors.password} </p> : null}
			</div>
			<div className="mb-4 pb-md-2">
				{/*<label className="form-label form-label-lg" htmlFor="ConfirmPassword">Confirm password*</label>*/}
				<div className='passwordShow'>
					<input
						onChange={e => setSignupData({ ...signupData, confirm_password: e.target.value })}
						type={passwordShowCheckConfirm ? "text" : "password"}
						className={errors && errors.hasOwnProperty('confirm_password') ? "form-control form-control-xl passwordShowInput borderColorRed" : "form-control form-control-xl passwordShowInput"}
						id="ConfirmPassword"
						placeholder="Confirm password"
					/>
					<button type="button" onClick={passwordShowConfirm}>
						{passwordShowCheckConfirm
							? <span className='eyeEvent eyeShow'><i className="fas fa-eye"></i></span>
							: <span className='eyeEvent eyeHide'><i className="fas fa-eye-slash"></i></span>
						}
					</button>
				</div>
				{errors && errors.hasOwnProperty('confirm_password') ? <p className="Authmessage text-red"> {errors.confirm_password} </p> : null}
			</div>
			<div className=" d-flex pb-3">
				<div className="form-check form-check-sm mb-0">
					<input className="form-check-input dark-form-check" defaultChecked={signupData.confirm} type="checkbox" id="gridCheck" onChange={e => setSignupData({ ...signupData, confirm: e.target.checked })} />
				</div>
				<label className="form-check-label small text-gray-600 mt-0 accept-text" htmlFor="gridCheck">
					By clicking “<a href="#0" className="text-gray-600 ">Sign</a>” up I am confirming I am 16 or older and I accept the <a href="#0" className="text-gray-600"><u>Terms of Use</u></a>
				</label>
			</div>
			{errors && errors.hasOwnProperty('confirm') ? <p className="Authmessage text-red"> {errors.confirm} </p> : null}
			<div className="d-grid">
				<button className="btn btn-xl btn-warning">Sign up</button>
			</div>
		</form>
	);
};

export default SignupFormMembers;