import axios from 'axios';
import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
import { API_BASE_URL, APP_BASE_URL, Google_ClientId } from '../config';

const SignupFormGoogle = (props) => {
	const { isAuthAdmin, setIsAuthAdmin, isAuth, setIsAuth } = props
	const [errors, setErrors] = useState([]);
	const acceptedGoogle = (response) => {
		var data = {}
		// console.log(response);
		data.email = response.profileObj.email
		data.firstname = response.profileObj.givenName
		data.lastname = response.profileObj.familyName
		data.googleId = response.profileObj.googleId
		data.googleImage = response.profileObj.imageUrl
		data.token = response.tokenObj.id_token
		axios.post(API_BASE_URL + '/api/signupUserGoogle', data)
			.then(response => {
				const token = response.data.access_token;
				localStorage.setItem('auth', 'true')
				localStorage.setItem('authUser', token)
				if (localStorage.getItem('authAdmin')) {
					localStorage.removeItem('authAdmin')
					setIsAuthAdmin(false)
				}
				window.location.href = APP_BASE_URL + '/forms'
				setIsAuth(true)
				// console.log(response.data);
				// localStorage.setItem('verify', 'true')
				// localStorage.setItem('verifyEmail', signupData['email'])
				// window.location.href = APP_BASE_URL + '/verify'
			})
			.catch(error => {
				// setLoading(false)
				setErrors(error.response.data.error)
			})
	}
	const rejectedGoogle = (response) => {
		console.log(response, 'rejected');
	}
	const logoutGoogle = (response) => {
		console.log(response, 'logouted');
	}
	return (

		<div className="pt-sm-1 pt-md-3 pb-1" >
			<GoogleLogin
				className="google-button"
				clientId={Google_ClientId}
				onSuccess={acceptedGoogle}
				onFailure={rejectedGoogle}
				cookiePolicy={'single_host_origin'}
			><span>Sign up with Google</span></GoogleLogin>
			{/* <GoogleLogout
				clientId="372712064907-d4qfa26vr4fpcdiqhfpbmtefr67g1frq.apps.googleusercontent.com"
				buttonText="Logout"
				onLogoutSuccess={logoutGoogle}
				>
			</GoogleLogout> */}
			<div className="position-relative">
				<p className="mb-0">{errors && errors.hasOwnProperty('email') ? <p className="Authmessage Autherror"> {errors.email} </p> : null}</p>
			</div>
		</div>
	);
};

export default SignupFormGoogle;