import React, { useContext, useEffect, useState } from 'react';
import Bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Lodash from 'lodash';
import Highcharts from 'highcharts';
import ApexCharts from 'apexcharts';
import axios from 'axios';


const HomeContent = () => {
	//Scripts
	useEffect(() => {
		var ThemeCustomJs = document.createElement('script');
		ThemeCustomJs.src = "/js/theme-custom.js";
		document.getElementsByTagName('body')[0].appendChild(ThemeCustomJs);
	}, [])

	//Scripts End
	return (
		<div>
			{/* HomeContents				 */}
		</div>
	);
};

export default HomeContent;